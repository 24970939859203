import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  HostBinding,
  Injector,
  ViewContainerRef,
  computed,
  effect,
  input,
  model,
  untracked
} from "@angular/core";
import { Widget } from "@smallstack/typesystem";
import { cloneObject, objectsEqual } from "@smallstack/utils";
import { WidgetRegistry } from "../../services/widget.registry";
import { BaseWidgetComponent } from "../../widgets/base-widget.component";
import { SignalBaseWidgetComponent } from "../../widgets/signal-base-widget.component";

@Component({
  selector: "smallstack-widget-renderer",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class WidgetRendererComponent {
  public widget = model<Widget>(undefined);
  private changedWidget = computed<Widget>(() => this.widget(), { equal: objectsEqual });
  public additionalProviders = input<any[]>([]);
  public context = input<any>(undefined);
  private changedContext = computed(() => this.context(), { equal: objectsEqual });
  public elementClass = input<string>(undefined, { alias: "class" });

  private widgetComponentRef: ComponentRef<BaseWidgetComponent | SignalBaseWidgetComponent>;

  constructor(
    private widgetRegistry: WidgetRegistry,
    injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) {
    effect(
      async () => {
        const widget = this.changedWidget();

        if (widget === undefined || widget === null) return;
        if (typeof widget.name === "string") {
          const widgetComponent: any = await this.widgetRegistry.getWidgetComponentByName(widget.name);
          if (!widgetComponent) throw new Error("No widgetRegistryEntry found for " + JSON.stringify(widget));
          if (widgetComponent) {
            const childInjector = Injector.create({
              providers: [...untracked(() => this.additionalProviders())],
              parent: injector
            });
            if (this.widgetComponentRef) {
              console.warn("Destroying old widget component: ", this.widgetComponentRef.instance.id);
              this.widgetComponentRef.destroy();
              this.viewContainerRef.clear();
            }
            this.widgetComponentRef = this.viewContainerRef.createComponent(widgetComponent, {
              injector: childInjector
            });

            // ALL WIDGETS
            if (widget.data)
              if (this.widgetComponentRef && widget.data !== undefined && widget.data !== null) {
                this.widgetComponentRef.setInput("data", widget.data);
              }
            if (widget.name) this.widgetComponentRef.setInput("name", widget.name);
            this.widgetComponentRef.instance.cssClass = (this.elementClass() || "") + " component-" + widget.id;

            // SIGNAL WIDGETS
            if (this.widgetComponentRef.instance instanceof SignalBaseWidgetComponent) {
              this.widgetComponentRef.setInput("id", widget.id);
              if (widget.styles) this.widgetComponentRef.setInput("styles", widget.styles);
              this.widgetComponentRef.instance.data.subscribe((data: unknown) => {
                this.widget.update((widget) => {
                  const clone = cloneObject(widget);
                  clone.data = data;
                  return clone;
                });
              });
            }

            // OLD WIDGETS
            if (this.widgetComponentRef.instance instanceof BaseWidgetComponent) {
              this.widgetComponentRef.instance.id = widget.id;
              this.widgetComponentRef.instance.dataChange?.subscribe((data: unknown) => {
                this.widget.update((widget) => {
                  const clone = cloneObject(widget);
                  clone.data = data;
                  return clone;
                });
              });
              if (widget.styles) this.widgetComponentRef.instance.styles = widget.styles;
              this.widgetComponentRef.instance.meta = widget.meta;

              untracked(() => {
                const context = this.changedContext();
                this.widgetComponentRef.setInput("context", context);
              });
            }

            // Go with the flow
            this.widgetComponentRef.changeDetectorRef.detectChanges();
          } else if (this.widget?.name !== undefined) {
            throw new Error("No widget found for name: " + widget.name);
          }
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const context = this.changedContext();
        if (context && this.widgetComponentRef?.instance instanceof BaseWidgetComponent) {
          this.widgetComponentRef.setInput("context", context);
        }
      },
      { allowSignalWrites: true }
    );
  }

  @HostBinding("style.display")
  protected display = "none";
}
