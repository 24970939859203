import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DeleteLocalDataDialogWidget: WidgetRegistryEntry = {
  name: "DeleteLocalDataDialog",
  component: () =>
    import("./delete-local-data-dialog-widget.component").then((module) => module.DeleteLocalDataDialogWidgetComponent),
  configuration: {
    icon: "fa-trash",
    templateName: "Lokale Daten löschen",
    templateDescription: "Löscht alle lokal gespeicherten Daten"
  }
};
