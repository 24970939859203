import { SelectionChange, SelectionModel } from "@angular/cdk/collections";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { FileDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DateComponent, DialogPopupButtons, I18nComponent, NotificationService } from "@smallstack/i18n-components";
import {
  FilterDataType,
  FilterName,
  FilterValues,
  ListContainerComponent,
  MatSortStoreDirective,
  StoreSearchComponent
} from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { FileSizePipe } from "../../pipes/file-size.pipe";
import { CurrentUpload, FileStore } from "../../stores/file.store";
import {
  FileDetailsDialogComponent,
  FileDetailsDialogData
} from "../file-details-dialog/file-details-dialog.component";
import { FileExplorerThumbnailComponent } from "../file-explorer-thumbnail/file-explorer-thumbnail.component";

@Component({
  selector: "smallstack-file-list",
  templateUrl: "./file-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    StoreSearchComponent,
    ListContainerComponent,
    MatTableModule,
    MatSortModule,
    IconComponent,
    I18nComponent,
    MatSortStoreDirective,
    FileSizePipe,
    DateComponent,
    MatProgressBarModule,
    FileExplorerThumbnailComponent,
    LoadingElementDirective,
    MatDialogModule
  ]
})
export class FileListComponent implements OnInit, OnDestroy {
  public columns = ["icon", "name", "size", "type", "id", "actions"];

  @Input()
  public selectable: boolean | string = false;

  @Input()
  public displayViewAs: string;

  @Input()
  public fileStore: FileStore;

  /**
   * Outputs selected file
   */
  @Output()
  public selected: EventEmitter<FileDto> = new EventEmitter();

  public selection = new SelectionModel<FileDto>(false);

  public filterNames: FilterName[] = [
    { label: "Name", value: "name" },
    { label: "Dateityp", value: "type" },
    { label: "ID", value: "id" }
  ];
  public filterValues: FilterValues = {
    type: {
      config: {
        allowCustomValues: false
      },
      values: [
        { label: "PNG Bilder", value: "image/png" },
        { label: "JPEG Bilder", value: "image/jpeg" },
        { label: "JPG Bilder", value: "image/jpg" },
        { label: "PDF Dokumente", value: "application/pdf" }
      ]
    },
    id: {
      config: {
        allowCustomValues: true,
        dataType: FilterDataType.ID
      }
    }
  };

  private subscription: Subscription = new Subscription();

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.selection.changed.subscribe((change: SelectionChange<FileDto>) => {
        this.selected.emit(change.added[0]);
      })
    );
    this.fileStore.sortBy("-id");
  }

  public openDetail(file: FileDto): void {
    if (this.selectable) this.selection.toggle(file);
    else
      this.dialog.open(FileDetailsDialogComponent, {
        data: { id: file.id, fileStore: this.fileStore } as FileDetailsDialogData
      });
  }

  public remove(file: FileDto) {
    return async (): Promise<void> => {
      const answer = await this.notificationService.popup.confirmation(
        "Datei löschen",
        `Wollen Sie die Datei "${file.name}" wirklich löschen?`,
        DialogPopupButtons.cancelDelete
      );
      if (answer === true) await this.notificationService.handlePromise(this.fileStore.delete(file.id));
    };
  }

  public isUploading(itemId: string): Observable<CurrentUpload> {
    return this.fileStore.getUploadStatus(itemId).pipe(
      map((upload) => {
        if (upload && upload?.progress !== 100) return upload;
        else return undefined;
      })
    );
  }
}
