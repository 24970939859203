<div class="dialog">
  <div class="dialog-title"> QR Code </div>
  <div class="dialog-content"><img data-test="qr-code-img" [src]="url" /></div>
  <div class="dialog-actions">
    <button mat-dialog-close data-test="close-qr-code" class="btn">
      <smallstack-i18n data="@@actions.close" />
    </button>
    <button class="btn btn-primary" [matMenuTriggerFor]="menu">
      <smallstack-i18n data="@@actions.download" />
      <i class="fa-regular fa-caret-down"></i>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="download('png')">
        <smallstack-i18n data="@@components.qrcodeimage.aspng" />
      </button>
      <button mat-menu-item (click)="download('svg')">
        <smallstack-i18n data="@@components.qrcodeimage.assvg" />
      </button>
      <button mat-menu-item (click)="download('pdf')">PDF</button>
    </mat-menu>
  </div>
</div>
