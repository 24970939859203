import { setMetadata } from "@smallstack/utils";
import { WidgetConfiguration } from "../widgets/widget-configuration";

export const METADATA_WIDGET_REGISTRATION_OPTIONS = "widgetRegistrationOptions";

export function Widget(options: WidgetConfiguration) {
  return function <T extends { new (...args: any[]): any }>(constructor: T): void {
    setMetadata(constructor, METADATA_WIDGET_REGISTRATION_OPTIONS, options);
    constructor.prototype.widgetRegistrationOptions = options;
  };
}
