import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";
import { FilterBarSockets } from "./filter-bar-widget.component";

export const FilterBarWidget: WidgetRegistryEntry = {
  name: "FilterBar",
  configuration: {
    templateName: "Filter",
    templateDescription: "Zeigt alle verfügbaren Filter für den angegebenen Datentyp an.",
    icon: "search",
    isBeta: true,
    sockets: [
      {
        direction: SocketDtoDirectionEnum.Out,
        name: FilterBarSockets.SEARCH_QUERY,
        type: SocketDtoTypeEnum.Object
      }
    ],
    tags: AllWidgetTags,
    dataSchema: {
      type: "object",
      properties: {
        type: {
          title: "Datentyp",
          type: "string",
          "x-schema-form": {
            widget: "types"
          }
        },
        globalStoreSearch: {
          title: "Globale Store Suche aktiv",
          description:
            "Falls aktiv, dann wird die Filtersuche auf den globalen Store des angegebenen Datentyps angewendet.",
          type: "boolean",
          default: true
        }
      }
    }
  },
  component: () => import("./filter-bar-widget.component").then((m) => m.FilterBarWidgetComponent)
};
