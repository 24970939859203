import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ICON_THEMES } from "@smallstack/core-common";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { BooleanEquationOperator } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, TypeDialogService, Widget } from "@smallstack/widget-core";
import {
  FloatingActionButtonDialogComponent,
  FloatingActionButtonDialogData
} from "./floating-action-button-dialog/floating-action-button-dialog.component";

@Widget({
  name: "FAB",
  templateName: "Schwebender Button",
  templateDescription:
    "Ein Aktions Button, welcher immer unten rechts angezeigt wird. Ideal für Aktionen auf Mobilgeräten!",
  tags: AllWidgetTags,
  icon: "home-button",
  dataSchema: {
    type: "object",
    properties: {
      icon: {
        type: "string",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        }
      },
      iconTheme: {
        type: "string",
        title: "Icon Theme",
        enum: ICON_THEMES
      },
      iconColor: {
        type: "string",
        title: "Icon Farbe",
        "x-schema-form": {
          widget: "color"
        }
      },
      action: {
        type: "string",
        enum: ["openDialog", "openTypeEditor", "sendSocketEvent"],
        title: "Aktion",
        description: "Was soll passieren, wenn der Button gedrückt wird?"
      },
      typePath: {
        type: "string",
        "x-schema-form": {
          widget: "types",
          rules: [
            {
              action: "hide",
              if: {
                dataPath: "../action",
                operator: BooleanEquationOperator.NOT_EQUALS,
                value: "openTypeEditor"
              }
            }
          ]
        }
      } as SchemaFormPropertyOptions
    }
  }
})
@Component({
  templateUrl: "./floating-action-button-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-floating-action-button",
  styleUrls: ["./floating-action-button-widget.component.scss"]
})
export class FloatingActionButtonWidgetComponent extends BaseWidgetComponent {
  constructor(
    private matDialog: MatDialog,
    private typeDialogService: TypeDialogService
  ) {
    super();
  }

  protected buttonClicked(): void {
    switch (this.data().action) {
      case "openTypeEditor":
        void this.typeDialogService.openEditor(this.data().typePath);
        break;
      case "openDialog":
        void this.matDialog
          .open(FloatingActionButtonDialogComponent, {
            data: {
              dialogComponent: this.data().dialogComponent,
              context: this.context(),
              widgetRendererOptions: this.widgetTreeService.toWidgetRendererOptions()
            } as FloatingActionButtonDialogData,
            autoFocus: false,
            maxHeight: "95vh",
            maxWidth: "95vw"
          })
          .afterClosed()
          .subscribe((component) => {
            if (component) {
              this.data().dialogComponent = component;
              this.dataChange.emit(this.data);
            }
          });
        break;
      case "sendSocketEvent":
        void this.sendSocketData("buttonClicked");
        break;
    }
  }
}
