import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, FileDto, FileUploadRequestResponseDto, FilesApi } from "@smallstack/axios-api-client";
import { NotificationService } from "@smallstack/i18n-components";
import { ObjectStore, PageableCrudStore } from "@smallstack/store";
import { SQBuilder, SearchByFieldMatcher, TYPE_FILES, TypeSupport } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import axios from "axios";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface CurrentUpload {
  id: string;
  file: FileDto;
  progress: number;
}

@Injectable({ providedIn: "root" })
export class FileStore extends PageableCrudStore<FileDto> implements TypeSupport {
  public readonly currentUploads$$: ObjectStore<CurrentUpload> = new ObjectStore<CurrentUpload>();

  /**
   * @returns true if anything is uploading
   */
  public readonly isUploading$: Observable<boolean> = this.currentUploads$$.value$.pipe(
    map((uploads) => uploads && uploads.length !== 0)
  );

  /**
   * @returns the current loading percentage of all files as observable
   */
  public readonly uploadProgress$: Observable<number> = this.currentUploads$$.value$.pipe(
    map((uploads) => {
      if (!uploads || uploads.length === 0) return 0;
      const totalProgress = uploads.reduce((acc, upload) => acc + upload.progress, 0);
      return Math.ceil(totalProgress / uploads.length);
    })
  );

  constructor(
    protected axiosApiClient: AxiosApiClient,
    protected notificationService: NotificationService,
    protected typeService: TypeService
  ) {
    super();
    this.query$.next({ sort: "-id" });
  }

  public getType<T>(): T {
    return this.typeService.getTypeByPath(TYPE_FILES) as unknown as T;
  }

  /**
   * Creates a FileDto and uploads a file
   * @param file A standard javascript file object
   * @returns Id of created FileDto
   */
  public async uploadFile(file: File, feature?: string): Promise<string> {
    try {
      if (file.type === undefined || file.type.length < 3) {
        throw new Error('File Type must be longer than 3 characters, but got "' + file.type + '"');
      }
      const fileDto: FileDto = (
        await this.axiosApiClient
          .get(FilesApi)
          .createFile({ file: { name: file.name, type: file.type, size: file.size, feature } })
      ).data;
      const currentUpload: CurrentUpload = { progress: 0, file: fileDto, id: fileDto.id };
      this.currentUploads$$.addValue(currentUpload);
      const uploadRequest: FileUploadRequestResponseDto = (
        await this.axiosApiClient.get(FilesApi).uploadFile({ id: fileDto.id })
      ).data;
      await axios.put(uploadRequest.signedUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": `attachment; filename="${file.name}"`
        },
        onUploadProgress: (progressEvent) => {
          this.currentUploads$$.updateValue((currentUploads) => {
            const index = currentUploads.findIndex((cu) => cu.id === currentUpload.id);
            currentUploads[index].progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
            return currentUploads;
          });
        }
      });
      this.currentUploads$$.updateValue((currentUploads) => {
        const index = currentUploads.findIndex((cu) => cu.id === currentUpload.id);
        currentUploads.splice(index, 1);
        return currentUploads;
      });
      return fileDto.id;
    } catch (e) {
      void this.notificationService.showStandardErrorPopup(e);
    }
  }

  public getUploadStatus(fileId: string): Observable<CurrentUpload> {
    return this.currentUploads$$.value$.pipe(
      map((currentUploads) => {
        return currentUploads?.find((cu) => cu?.id === fileId);
      })
    );
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<any>> {
    if (!query) query = {};
    if (!query.search)
      query.search = SQBuilder.asString([{ fieldname: "feature", matcher: SearchByFieldMatcher.EXISTS, value: false }]);
    const res = await this.axiosApiClient.get(FilesApi).getFiles(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(FilesApi).getFile({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(FilesApi).deleteFile({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(FilesApi).deleteManyFiles({ ids });
    return res.data;
  }
  protected async createModel(model: FileDto): Promise<any> {
    const res = await this.axiosApiClient.get(FilesApi).createFile({ file: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<FileDto>): Promise<any> {
    const res = await this.axiosApiClient.get(FilesApi).patchFile({ id, file: model });
    return res.data;
  }
  protected async putModel(model: any): Promise<FileDto> {
    const res = await this.axiosApiClient.get(FilesApi).putFile(model.id, model);
    return res.data;
  }
}
