import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, TemplateRef, computed, contentChild, inject, input } from "@angular/core";
import { Logger } from "@smallstack/core-common";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { LocaleService } from "../../services/locale.service";
import { TranslationStore } from "../../stores/translation.store";

export type I18nData = string | InlineTranslation;

@Component({
  selector: "smallstack-i18n",
  templateUrl: "./i18n.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class I18nComponent {
  private globalTranslationStore = inject(TranslationStore, { optional: true });
  private localeService = inject(LocaleService);

  /**
   * If a string with leading `@@` is passed, this component will interpret data as translation key. If an array is passed, it will be treated as InlineTranslation. If a string is passed, it will just show the string.
   */
  public data = input<I18nData>();
  public showMissingKey = input<boolean>(true);
  public replacers = input<any>();
  public locale = input<string>();
  public translationStore = input<TranslationStore>();

  protected defaultTemplate = contentChild<TemplateRef<unknown>>(TemplateRef);
  protected hasDefaultTemplate = computed(() => this.defaultTemplate() !== undefined);

  // the final field
  protected value = computed(() => {
    const data = this.data();
    if (!data) return undefined;
    const translationStore = this.translationStore() || this.globalTranslationStore;
    if (!translationStore) {
      Logger.debug("I18nComponent", "No translation store set!");
      if (typeof data === "string") return data;
      else if (data instanceof Array) return data[0]?.value;
    } else {
      translationStore.translationsUpdated.listen();
      const locale = this.locale();
      // until translationStore.translate$ exists
      return translationStore.translate(data, {
        locale: locale ? locale : this.localeService.currentLocale$.value,
        replacers: this.replacers(),
        showMissingKey: this.hasDefaultTemplate() ? false : this.showMissingKey()
      });
    }
  });
}
