import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, TodoApi, TodoSurchargeDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class TodoSurchargesStore extends PageableCrudStore<TodoSurchargeDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super({ loadOnDeletion: true });
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<TodoSurchargeDto>> {
    const res = await this.axiosApiClient.get(TodoApi).getTodoSurcharges(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<TodoSurchargeDto> {
    const res = await this.axiosApiClient.get(TodoApi).getTodoSurcharge({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(TodoApi).deleteTodoSurcharge({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(TodoApi).deleteManyTodos({ ids });
    return res.data;
  }
  protected async createModel(model: TodoSurchargeDto): Promise<TodoSurchargeDto> {
    const res = await this.axiosApiClient.get(TodoApi).createTodoSurcharge({ todoSurcharge: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<TodoSurchargeDto>): Promise<TodoSurchargeDto> {
    const res = await this.axiosApiClient.get(TodoApi).patchTodoSurcharge({ id, todoSurcharge: model });
    return res.data;
  }
  protected async putModel(model: TodoSurchargeDto): Promise<TodoSurchargeDto> {
    const res = await this.axiosApiClient.get(TodoApi).putTodoSurcharge({ id: model.id, todoSurcharge: model });
    return res.data;
  }
}
