import { ChangeDetectionStrategy, Component, OnInit, Optional } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { StoreRegistry } from "@smallstack/common-components";
import { TextManipulationService } from "@smallstack/i18n-components";
import { PageableCrudStore } from "@smallstack/store";
import { BooleanEquationOperator, replaceVariablesInObject } from "@smallstack/utils";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  templateUrl: "./html-widget.component.html",
  styleUrls: ["./html-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlWidgetComponent extends BaseWidgetComponent implements OnInit {
  public html: string | SafeHtml;

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        html: {
          type: "string",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "html"
          },
          description:
            "Sie können ${PROPERTY_NAME} als Platzhalter verwenden, wenn Sie eine Datenquelle definiert haben."
        },
        store: {
          type: "string",
          title: "Datenquelle",
          description:
            "Falls Sie im HTML auf einen Datensatz zugreifen möchten, können Sie hier bestimmen, aus welchem Store dieser kommt.",
          "x-schema-form": {
            widget: "types"
          }
        },
        id: {
          type: "string",
          title: "ID",
          description: "Falls Sie im HTML auf einen Datensatz zugreifen möchten, können Sie hier die ID bestimmen."
        },
        param: {
          type: "string",
          title: "Route Parameter",
          description: "Anstatt einer ID können Sie auch einen Routenparameter angeben, indem sich die ID befindet."
        },
        numberGrouping: {
          type: "boolean",
          title: "Zifferngruppierung",
          description:
            "Wenn die Zifferngruppierung eingeschaltet ist, dann werden alle Zahlen mit Tausender Trennzeichen versehen",
          default: false
        },
        numberGroupingFormat: {
          title: "Zahlengruppierungsformat",
          description:
            "Geben Sie hier an, wie die Zahlen gruppiert werden: {Vorkommastellen}.{mind. Nachkommastellen}-{max. Nachkommastellen}",
          type: "string",
          default: "1.0-10",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../numberGrouping",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: true
                }
              }
            ]
          }
        }
      }
    };
  }

  constructor(
    private sanitizer: DomSanitizer,
    private storeRegistry: StoreRegistry,
    @Optional() private activatedRoute: ActivatedRoute,
    private textManipulationService: TextManipulationService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.html = await this.getProcessedTemplate();
    this.cdr.markForCheck();
  }

  private async getProcessedTemplate() {
    if (!this.data().html) return "No html defined.";
    let html = this.data().html;
    if (this.data().store) {
      const store: PageableCrudStore = this.storeRegistry.getStore(this.data().store);
      if (!store) throw new Error("Could not get store by name: " + this.data().store);
      let model: any;
      if (this.data().id) {
        model = await store.get(this.data().id);
      } else if (this.data().param && this.activatedRoute) {
        const param = this.activatedRoute.snapshot.params[this.data().param];
        if (!param) return;
        model = await store.get(param);
      } else return;
      html = replaceVariablesInObject(html, model, { removeUnmappedContent: true });
    }
    if (this.data().numberGrouping === true) {
      html = this.textManipulationService.replaceNumberGroupings(html, this.data().numberGroupingFormat);
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
