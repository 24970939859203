export interface OgpTags {
  title?: string;
  image?: string;
  type?: string;
  url?: string;
  siteName?: string;
  description?: string;
}

export interface TwitterTags {
  card: string;
  title?: string;
  description?: string;
  image?: string;
  site?: string;
  creator?: string;
}

export interface SeoConfiguration {
  description?: string;
  keywords?: Array<string>;
  robots?: string;
  ogp?: OgpTags;
  twitter?: TwitterTags;
}

export const SeoConfigurationSchema = {
  type: "object",
  properties: {
    description: {
      type: "string"
    },
    keywords: {
      type: "array",
      items: {
        type: "string"
      }
    },
    robots: {
      type: "string"
    },
    ogp: {
      type: "object",
      properties: {
        title: {
          type: "string"
        },
        image: {
          type: "string"
        },
        type: {
          type: "string"
        },
        url: {
          type: "string"
        },
        siteName: {
          type: "string"
        },
        description: {
          type: "string"
        }
      }
    },
    twitter: {
      type: "object",
      properties: {
        card: {
          type: "string"
        },
        title: {
          type: "string"
        },
        description: {
          type: "string"
        },
        image: {
          type: "string"
        },
        site: {
          type: "string"
        },
        creator: {
          type: "string"
        }
      },
      required: ["card"]
    }
  }
};
