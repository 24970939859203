<div class="form-control">
  @if (label) {
    <label class="label">
      <span class="label-text"><smallstack-i18n [data]="label" /></span>
    </label>
  }
  <div class="join">
    <input
      type="text"
      data-test="file-explorer-input"
      class="input input-bordered w-full join-item"
      [placeholder]="placeholder"
      [required]="required"
      [ngModel]="value"
      (ngModelChange)="inputChange($event)"
    />
    <smallstack-file-explorer-button [fileStore]="fileStore" (selected)="select($event)" />
  </div>
  @if (hint) {
    <label class="label">
      <span class="label-text-alt"><smallstack-i18n [data]="hint" /></span>
    </label>
  }
</div>
