import { SocketConnectionDto, WidgetDto } from "@smallstack/axios-api-client";

export const CustomerCompoundWidgetDefinition: WidgetDto = {
  name: "VerticalContainer",
  data: {
    height: "calc(100vh - 235px)",
    width: "100%",
    padding: "12px",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: "12px",
    invertedDirection: false,
    wrap: true,
    children: [
      {
        name: "BackofficeHeaderContainer",
        data: {
          headerText: [
            {
              value: "Mitarbeiter"
            }
          ],
          headerSubText: [
            {
              value: "Hier können Sie Ihre Mitarbeiter anlegen und bearbeiten."
            }
          ],
          headerIcon: "fa-users",
          headerActions: {
            id: "5941f768-f20e-4378-a272-14333d6a4214",
            name: "DataButton",
            data: {
              btnText: "Erstellen"
            },
            styles: ""
          }
        },
        id: "21938599-174a-4457-8ea7-389539f5f59e",
        styles: ""
      },
      {
        name: "CustomerBackoffice",
        id: "1c08fbe0-c64c-46bb-8ffd-333e71857fc3",
        data: {
          columnsShown: ["avatar", "email", "profile.firstName", "profile.lastName", "roles", "createdAt", "actions"]
        },
        styles: ""
      }
    ]
  },
  id: "1ba2f121-215e-4017-a7a8-c144667c1736"
};

export const CustomerCompoundConnections: SocketConnectionDto[] = [
  {
    sourceComponentId: "5941f768-f20e-4378-a272-14333d6a4214",
    sourceSocketName: "data",
    targetComponentId: "1c08fbe0-c64c-46bb-8ffd-333e71857fc3",
    targetSocketName: "createCustomer"
  }
];

// {
//   name: "DataTableV3",
//   data: {
//     listProperties: [
//       {
//         property: "primaryEmailAddress",
//         width: 10,
//         sortable: true,
//         sortingMode: "asStrings",
//         filterable: true,
//         renderer: "raw",
//         i18nPrefix: "",
//         valueIconPairArray: [],
//         mappingArray: [],
//         rendererDateFormat: "",
//         rendererStorePropertyStore: "",
//         rendererStorePropertyProperty: "",
//         googleMapsPreviewHeight: 80,
//         googleMapsPreviewWidth: 120,
//         qrCodePrefix: "",
//         dialogWidth: "600px",
//         dialogHeight: "600px",
//         dialogButtonIcon: "search",
//         dialogUrlSyncParameter: "",
//         linkLabel: "",
//         openLinkInNewWindow: false,
//         widget: "",
//         widgetContextType: "property",
//         currency: "",
//         title: [
//           {
//             value: "E-Mail Adresse"
//           }
//         ]
//       },
//       {
//         property: "displayName",
//         width: 10,
//         sortable: true,
//         sortingMode: "asStrings",
//         filterable: true,
//         renderer: "raw",
//         i18nPrefix: "",
//         valueIconPairArray: [],
//         mappingArray: [],
//         rendererDateFormat: "",
//         rendererStorePropertyStore: "",
//         rendererStorePropertyProperty: "",
//         googleMapsPreviewHeight: 80,
//         googleMapsPreviewWidth: 120,
//         qrCodePrefix: "",
//         dialogWidth: "600px",
//         dialogHeight: "600px",
//         dialogButtonIcon: "search",
//         dialogUrlSyncParameter: "",
//         linkLabel: "",
//         openLinkInNewWindow: false,
//         widget: "",
//         widgetContextType: "property",
//         currency: "",
//         title: [
//           {
//             value: "Anzeige Name"
//           }
//         ]
//       },
//       {
//         property: "profile.firstName",
//         width: 10,
//         sortable: true,
//         sortingMode: "asStrings",
//         filterable: true,
//         renderer: "raw",
//         i18nPrefix: "",
//         valueIconPairArray: [],
//         mappingArray: [],
//         rendererDateFormat: "",
//         rendererStorePropertyStore: "",
//         rendererStorePropertyProperty: "",
//         googleMapsPreviewHeight: 80,
//         googleMapsPreviewWidth: 120,
//         qrCodePrefix: "",
//         dialogWidth: "600px",
//         dialogHeight: "600px",
//         dialogButtonIcon: "search",
//         dialogUrlSyncParameter: "",
//         linkLabel: "",
//         openLinkInNewWindow: false,
//         widget: "",
//         widgetContextType: "property",
//         currency: "",
//         title: [
//           {
//             value: "Vorname"
//           }
//         ]
//       },
//       {
//         property: "profile.lastName",
//         width: 10,
//         sortable: true,
//         sortingMode: "asStrings",
//         filterable: true,
//         renderer: "raw",
//         i18nPrefix: "",
//         valueIconPairArray: [],
//         mappingArray: [],
//         rendererDateFormat: "",
//         rendererStorePropertyStore: "",
//         rendererStorePropertyProperty: "",
//         googleMapsPreviewHeight: 80,
//         googleMapsPreviewWidth: 120,
//         qrCodePrefix: "",
//         dialogWidth: "600px",
//         dialogHeight: "600px",
//         dialogButtonIcon: "search",
//         dialogUrlSyncParameter: "",
//         linkLabel: "",
//         openLinkInNewWindow: false,
//         widget: "",
//         widgetContextType: "property",
//         currency: "",
//         title: [
//           {
//             value: "Nachname"
//           }
//         ]
//       }
//     ],
//     showDetailsButton: true,
//     showEditButton: true,
//     showDeleteButton: true,
//     showCloneButton: false,
//     allowMultiDelete: true,
//     showCreateButton: true,
//     showCheckboxes: true,
//     showExportButton: true,
//     showFullTextSearch: true,
//     showFooter: true,
//     alwaysShowFilter: false,
//     typeName: "customers",
//     initialSortProperty: "displayName"
//   },
//   id: "ce98fb94-1f2c-42e1-97ed-6417db64838d",
//   styles: ""
// },
