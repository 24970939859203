import { TypeSchema } from "../typesystem/type-schema";

export interface DataTypeView {
  name: string;
  compoundWidgetId: string;
}

export const DataTypeViewSchema: TypeSchema = {
  type: "object",
  properties: {
    name: { type: "string" },
    compoundWidgetId: { type: "string" }
  },
  required: ["name", "compoundWidgetId"]
};
