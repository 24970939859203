import { mergeSchemas } from "../schema.utils";
import { ServicePermissions } from "../typesystem/service-permissions";
import { TYPE_USERS } from "../typesystem/type-names";
import { TypeSchema } from "../typesystem/type-schema";
import { AcceptedOptIn, AcceptedOptInSchema } from "./accepted-opt-in";
import { DataType } from "./data-type";
import { Model, ModelSchema } from "./model";

export interface EmailWithVerification {
  email: string;
  validated?: boolean;
  validatedAt?: number;
  isDefault?: boolean;
}

export const EmailWithVerificationSchema: TypeSchema = {
  type: "object",
  properties: {
    email: { type: "string" },
    validated: { type: "boolean" },
    validatedAt: { type: "number" },
    isDefault: { type: "boolean" }
  }
};

export interface UserConfiguration {
  key: string;
  value: any;
}

export const UserConfigurationSchema: TypeSchema = {
  type: "object",
  properties: {
    key: { type: "string" },
    value: {}
  },
  required: ["key", "value"]
};

export interface UserSso {
  azureAdOid?: string;
}

export const UserSsoSchema: TypeSchema = {
  type: "object",
  properties: {
    azureAdOid: { type: "string" }
  }
};

export interface User extends Model {
  auth?: any;
  sso?: UserSso;
  emails?: Array<EmailWithVerification>;
  /** Benutzerdaten, welche vom Benutzer selbst verändert werden können. */
  profile?: any;
  /** Benutzerkonfiguration, die zwar vom Benutzer eingesehen, aber nicht verändert werden kann. */
  configuration?: Array<UserConfiguration>;
  acceptedOptIns?: Array<AcceptedOptIn>;
  active?: boolean;
  displayName?: string;
  primaryEmailAddress?: string;
  primaryEmail?: EmailWithVerification;
}

export const UserSchema: TypeSchema = mergeSchemas(ModelSchema, {
  type: "object",
  properties: {
    auth: { type: "object" },
    sso: UserSsoSchema,
    emails: {
      type: "array",
      items: EmailWithVerificationSchema
    },
    profile: { type: "object", "x-schema-form": { hidden: true } },
    configuration: { type: "array", items: UserConfigurationSchema },
    acceptedOptIns: { type: "array", items: AcceptedOptInSchema },
    active: { type: "boolean" },
    displayName: { type: "string" },
    primaryEmailAddress: { type: "string", "x-schema-form": { hidden: true } },
    primaryEmail: EmailWithVerificationSchema
  },
  required: [
    "auth",
    "sso",
    "emails",
    "profile",
    "configuration",
    "acceptedOptIns",
    "active",
    "displayName",
    "primaryEmailAddress",
    "primaryEmail"
  ]
});

export const UserType: DataType = {
  title: [
    { value: "Benutzer", locale: "de_de" },
    { value: "User", locale: "en_us" }
  ],
  titlePlural: [
    { value: "Benutzer", locale: "de_de" },
    { value: "Users", locale: "en_us" }
  ],
  icon: "fa-user",
  path: TYPE_USERS,
  permissions: {
    post: ServicePermissions.USERS_WRITE,
    del: ServicePermissions.USERS_WRITE,
    get: ServicePermissions.ANONYMOUS, // will be determined by the requested tenant and the resulting permissions
    getMany: ServicePermissions.ANONYMOUS, // will be determined by the requested tenant and the resulting permissions
    put: ServicePermissions.USERS_WRITE,
    patch: ServicePermissions.USERS_WRITE
  },
  searchableFields: [
    { name: "profile.firstName", weight: 10 },
    { name: "profile.lastName", weight: 10 },
    { name: "emails.address", weight: 4 }
  ],
  representation: "${displayName}",
  longRepresentation:
    "Anzeigename: ${displayName}, Vorname: ${profile.firstName}, Nachname: ${profile.lastName}, E-Mail: ${primaryEmailAddress}",
  url: "${basePath}/users?detailsId=${id}",
  schema: UserSchema
};
