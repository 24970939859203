/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { format } from "date-fns";
import { de } from "date-fns/locale";
import memoize from "memoizee";
import { splitWithoutEscaped } from "./array-utils";
import { DEFAULT_GERMAN_DATE_STRING_FORMAT, humanizeDuration } from "./date-utils";
import { ArithmeticOperand, calculateArithmeticEquation } from "./equation-calculation.utils";
import { getJsonByPath } from "./json-utils";

export interface ReplaceVariablesContext {
  [key: string]: string | any;
}

export function extractTextReplacerKeys(str: string): string[] {
  return memoize((str: string): string[] => {
    const regex: RegExp = new RegExp("\\$\\{([a-z.0-9:|\\-\\.\\, \\[\\]\\=\"']*)\\}", "ig");
    let result;
    const keys: string[] = [];
    do {
      result = regex.exec(str);
      if (result !== null) {
        keys.push(result[1]);
      }
    } while (result !== null);

    return keys;
  })(str);
}

export function hasConditionals(str: string): boolean {
  const regex: RegExp = /#{if ([^}]*)}([^#]*)#{\/if}/m;
  let result;
  do {
    result = regex.exec(str);
    if (result !== null) {
      return true;
    }
  } while (result !== null);
  return false;
}

/** Replaces variables like `${world}` in strings like `Hello ${world}` with matching properties from `context`.
 *
 * @param content The string to replace variables in.
 * @param context The context to use for replacing variables.
 * @param removeUnmappedContent If `true`, removes all content that could not be mapped to a variable in `context`.
 * @returns The string with all variables replaced.
 * @see {@link ReplaceVariablesContext} for the context object.
 *
 * @example ```typescript
 * const context = { world: "World" };
 * const result = replaceVariables("Hello ${world}", context);
 * console.log(result); // "Hello World"
 * ```
 * Also works with nested properties:
 * @example ```typescript
 * const context = { world: { name: "World" } };
 * const result = replaceVariables("Hello ${world.name}", context);
 * console.log(result); // "Hello World"
 * ```
 * Also works with arrays:
 * @example ```typescript
 * const context = { world: [{ name: "World" }] };
 * const result = replaceVariables("Hello ${world.0.name}", context);
 * console.log(result); // "Hello World"
 * ```
 * Also works with recursive variables:
 * @example ```typescript
 * const context = { worlds: { earth: "World" }, name: "earth"  };
 * const result = replaceVariables("Hello ${world.${name}}", context);
 * console.log(result); // "Hello World"
 * ```
 */
export function replaceVariables(
  content: string,
  context: ReplaceVariablesContext,
  removeUnmappedContent: boolean = false
): string {
  return memoize(
    (content: string, context: ReplaceVariablesContext, removeUnmappedContent: boolean = false): string => {
      if (!content) return content;
      const containsInnerKeysRegex: RegExp = new RegExp("\\$\\{([a-z.0-9:|\\-\\.\\, \\[\\]=]*)\\$\\{", "ig");
      const containsInnerKeys = containsInnerKeysRegex.test(content);
      if (context) {
        // replace conditionals
        // eslint-disable-next-line no-useless-escape
        const conditionals = /#{if ([^}]*)}([^#]*)#{\/if}/m;
        let m;
        while ((m = conditionals.exec(content)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === conditionals.lastIndex) {
            conditionals.lastIndex++;
          }
          const conditionalBlock = m[0];
          let conditionalProp = m[1];
          let contextConditionalProp = getJsonByPath(context, conditionalProp);
          let condition =
            contextConditionalProp !== undefined && contextConditionalProp !== null && contextConditionalProp !== "";
          if (conditionalProp.startsWith("not ")) {
            conditionalProp = conditionalProp.replace("not ", "");
            contextConditionalProp = getJsonByPath(context, conditionalProp);
            condition =
              contextConditionalProp === undefined || contextConditionalProp === null || contextConditionalProp === "";
          }
          const conditionalContent = m[2];
          if (condition) content = content.replace(conditionalBlock, conditionalContent);
          else content = content.replace(conditionalBlock, "");
        }
      }

      // replace content
      const keys = extractTextReplacerKeys(content);
      for (const key of keys) {
        const pipes = key.split("|").map((p) => p.trim());
        const keyWithoutPipes = pipes.shift();
        let replacer = getJsonByPath(context, keyWithoutPipes);

        if (pipes.length > 0) {
          for (const pipe of pipes) {
            const parameters = splitWithoutEscaped(pipe, ":").map((p) => p.trim());
            switch (parameters[0]) {
              // e.g. ${32429348242 | format} or ${234872384 | format : dd.MM.yyyy}
              case "format": {
                const formatFormat = parameters[1] || DEFAULT_GERMAN_DATE_STRING_FORMAT;
                try {
                  const date = new Date(replacer);
                  replacer = format(date, formatFormat, { locale: de });
                } catch (e) {
                  //
                }
                break;
              }
              // e.g.${2,77747 | round : 2} would display 2,78
              // e.g.${2,77747 | round} would display 3
              case "round": {
                const decimalPlaces = parameters[1] ? parseInt(parameters[1]) : 0;
                replacer = parseFloat(replacer).toFixed(decimalPlaces).replace(".", ",");
                break;
              }

              // e.g. ${40 | calc : add : 2} would display 42
              case "calc": {
                const operand: ArithmeticOperand = parameters[1] as ArithmeticOperand;
                const valueA = parseFloat(replacer);
                const valueB = parseFloat(getJsonByPath(context, parameters[2]) || parameters[2]);
                if (!Number.isNaN(valueA) && !Number.isNaN(valueB))
                  replacer = calculateArithmeticEquation(valueA, operand, valueB);
                break;
              }
              case "humanizeDuration": {
                replacer = humanizeDuration(replacer, { shortFormat: true });
                break;
              }
              case "truncateToRange": {
                const min = parseFloat(parameters[1]);
                const max = parseFloat(parameters[2]);
                if (replacer === undefined || replacer === null) {
                  replacer = min;
                  break;
                }
                const number = parseFloat(replacer);
                if (number > max) replacer = max;
                if (number < min) replacer = min;
                break;
              }
              // maps values to other values, syntax: ${value | mapping:from1,from2,from3:to1,to2,to3:default}
              // e.g. ${overnightStay | mapping:true,false,undefined:ja,nein,nein:nein}
              case "mapping": {
                const value = "" + replacer;
                const from = parameters[1]?.split(",");
                const to = parameters[2]?.split(",");
                const defaultValue = parameters[3] || "";
                const index = from.indexOf(value);
                replacer = index >= 0 ? to[index] : defaultValue;
                break;
              }
              // ${array | sum} or ${array | sum:subPath}
              case "sum": {
                const path: string | undefined = parameters[1];
                const value = replacer;
                if (!Array.isArray(value)) {
                  replacer = 0;
                  break;
                }
                let sum = 0;
                if (path) {
                  for (const v of value) {
                    const subValue = getJsonByPath(v, path);
                    if (typeof subValue === "number") sum += subValue;
                    else if (typeof subValue === "string") {
                      const number = parseFloat(subValue);
                      if (!Number.isNaN(number)) sum += number;
                    }
                  }
                } else {
                  for (const v of value) {
                    if (typeof v === "number") sum += v;
                    else if (typeof v === "string") {
                      const number = parseFloat(v);
                      if (!Number.isNaN(number)) sum += number;
                    }
                  }
                }
                replacer = "" + sum;
                break;
              }
              default:
                // eslint-disable-next-line no-console
                console.error(`Unknown pipe ${pipe}`);
            }
          }
        }
        if (replacer !== undefined) {
          content = content.replace(
            new RegExp(`\\$\{${key.replace(/\[/g, "\\[").replace(/\]/g, "\\]").replace(/\|/g, "\\|")}}`, "ig"),
            replacer
          );
        }
      }

      // check if everything got replaced
      const regex: RegExp = new RegExp("\\$\\{[a-z.0-9|:\\-\\.\\, \\[\\]\\=\"']*\\}", "ig");
      const result = regex.exec(content);
      if (result !== null && removeUnmappedContent) {
        content = content.replace(regex, "");
      }
      if (result && containsInnerKeys) return replaceVariables(content, context, true);

      return content;
    }
  )(content, context, removeUnmappedContent);
}

export function replaceVariablesInObject(
  obj: any,
  context: ReplaceVariablesContext,
  options: { removeUnmappedContent?: boolean; correctTypes?: boolean } = { removeUnmappedContent: false }
): any {
  // remove " around all possible replacers
  let content = JSON.stringify(obj);
  if (options?.correctTypes === true) {
    const keys = extractTextReplacerKeys(content);
    for (const key of keys) {
      // check if key has a non-string value in context
      const value = getJsonByPath(context, key);
      if (value !== undefined && typeof value !== "string") {
        const theKey = `\\"\\$\\{${key}\\}\\"`;
        // replace surrounding "
        content = content.replace(new RegExp(theKey, "ig"), "${" + key + "}");
      }
    }
  }
  return JSON.parse(replaceVariables(content, context, options.removeUnmappedContent));
}
