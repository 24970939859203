<div class="flex flex-row justify-end">
  @if (showTemplates) {
    <mat-form-field>
      <mat-label>Vorlage anwenden</mat-label>
      <mat-select (selectionChange)="applyTemplate($event)">
        @for (template of templates | keyvalue; track template) {
          <mat-option [value]="template.key">
            {{ template.key }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>

@if (theme) {
  <mat-tab-group (selectedTabChange)="tabChange($event)" animationDuration="0">
    <mat-tab label="Einfach">
      <smallstack-type-editor
        type="themes"
        [model]="$any(theme)"
        (modelChange)="theme = $event"
        (modelChange)="themeChange.emit(theme)"
      ></smallstack-type-editor>
    </mat-tab>
    <mat-tab label="Erweitert">
      @if (editorShown) {
        <div style="overflow: hidden">
          <h2>Theme</h2>
          <smallstack-monaco-editor
            class="monaco-editor"
            [(value)]="jsonThemeAsString"
            (valueChange)="applyEditorTheme()"
          >
          </smallstack-monaco-editor>
          <h2>Custom Styles</h2>
          <smallstack-monaco-editor
            class="monaco-editor"
            language="css"
            [(value)]="cssThemeAsString"
            (valueChange)="applyEditorTheme()"
          >
          </smallstack-monaco-editor>
        </div>
      }
    </mat-tab>
  </mat-tab-group>
}

<ng-template #loading>
  <smallstack-loader loadingText="Lade Design Schema..."></smallstack-loader>
</ng-template>
