<div class="dialog" [attr.data-test]="'widget-configuration-dialog-' + widget?.name">
  <div class="dialog-title flex flex-row gap-1"
    ><smallstack-icon alias="widget" />Widget Konfiguration: {{ widget?.name }}</div
  >
  <div class="dialog-content">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <smallstack-i18n data="@@backoffice.widgets.configuration.widget" />
        </ng-template>
        <div *ngIf="isLoading; else table" class="component-loading"></div>
        <ng-template #table>
          <ng-container *ngIf="openedTab === 'table'">
            <smallstack-form
              *ngIf="schema; else noSchema"
              [schema]="schema"
              [options]="{ context: data.context }"
              [(value)]="widget.data"
            >
              <smallstack-grouped-form-table
                class="widget-configuration-table"
                [showHeader]="false"
                [showMissingFields]="false"
                [showActions]="false"
              />
            </smallstack-form>
          </ng-container>
          <ng-template #noSchema>
            <div class="text-muted" style="text-align: center; padding: 20px 10px 10px 10px" data-test="no-schema">
              <smallstack-i18n data="@@components.cms.configuration.noconfig" />
            </div>
          </ng-template>
        </ng-template>
      </mat-tab>
      <mat-tab #cssTab>
        <ng-template mat-tab-label> CSS / Styles </ng-template>
        <div class="text-muted" style="padding: 10px 0px"
          >Die Komponente verwendet die CSS Klasse ".component". Meist ist das oberste Element jedoch kein Element, was
          sich direkt stylen lässt. In diesem Fall verwenden Sie einfach ".component > *".
        </div>
        <smallstack-monaco-editor
          *ngIf="cssTab.isActive"
          editorHeight="calc(55vh - 60px)"
          language="css"
          [value]="widgetStyles"
          (valueChange)="setWidgetStyles($event)"
        />
      </mat-tab>
      <mat-tab *ngIf="dashboard">
        <ng-template mat-tab-label>
          <smallstack-i18n data="@@backoffice.widgets.configuration.visual" />
        </ng-template>
        <smallstack-form [schema]="widgetConfigurationVisualSchema" [(value)]="widgetMeta">
          <smallstack-schema-form-table [showHeader]="false" [showMissingFields]="false" [showActions]="false" />
        </smallstack-form>
        <h4>
          <smallstack-i18n data="@@backoffice.widgets.configuration.actions" />
        </h4>
        <smallstack-i18n data="@@backoffice.widgets.configuration.actionsdescription" />
        <smallstack-form [schema]="widgetConfigurationButtonSchema" [(value)]="widgetMeta">
          <smallstack-schema-form-table [showHeader]="false" [showMissingFields]="false" [showActions]="false" />
        </smallstack-form>
      </mat-tab>
      <mat-tab #monacoTab>
        <ng-template mat-tab-label
          ><smallstack-i18n data="@@backoffice.widgets.configuration.widget" />&nbsp;(JSON Editor)
        </ng-template>
        <smallstack-monaco-editor
          *ngIf="monacoTab.isActive"
          editorHeight="calc(55vh - 30px)"
          [value]="widget | stringify"
          (valueChange)="setWidget($event)"
        />
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> Aktueller Kontext </ng-template>
        <div class="text-muted"
          >Hier sehen Sie die aktuell verfügbaren Variablen für dieses Widget. In vielen Fällen können Sie diese via
          $&#10100;VARIABLENNAME&#10101; ansprechen.
        </div>
        <table *ngIf="isObject(data.context); else showRaw" class="context-table">
          <tbody>
            <tr>
              <th>Name</th>
              <th></th>
              <th>Wert</th>
              <th></th>
            </tr>
          </tbody>
          <tr *ngFor="let variable of data.context | flatten | keyvalue">
            <td>
              {{ variable.key }}
            </td>
            <td>
              <smallstack-copy-action-button [cdkCopyToClipboard]="$any(variable.key)" />
            </td>
            <td> {{ variable.value }}</td>
            <td>
              <smallstack-copy-action-button [cdkCopyToClipboard]="$any(variable.value)" />
            </td>
          </tr>
        </table>
        <ng-template #showRaw>
          <div class="flex flex-col md:flex-row">
            <pre>{{ data.context }}</pre>
            <smallstack-copy-action-button [cdkCopyToClipboard]="$any(data.context)" />
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> Globaler Speicher </ng-template>
        <div class="text-sm my-2">
          Der globale Speicher ist ein Speicher, der für alle Widgets innerhalb eines Widget Baums gleich ist. Er kann
          verwendet werden, um Daten zwischen Widgets auszutauschen.
        </div>
        <table class="context-table">
          <tbody>
            <tr>
              <th>Name</th>
              <th></th>
              <th>Wert</th>
              <th></th>
            </tr>
          </tbody>
          <tr *ngFor="let variable of data.globals | flatten | keyvalue">
            <td>
              {{ variable.key }}
            </td>
            <td>
              <smallstack-copy-action-button [cdkCopyToClipboard]="$any(variable.key)" />
            </td>
            <td> {{ variable.value }}</td>
            <td>
              <smallstack-copy-action-button [cdkCopyToClipboard]="$any(variable.value)" />
            </td>
          </tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="dialog-actions">
    <button mat-dialog-close data-test="dashboard-widget-edit-cancel" class="btn">
      <smallstack-i18n data="@@actions.cancel" />
    </button>
    <button class="btn btn-warning" [loadingFn]="resetToDefaults()">Zurücksetzen</button>
    <button data-test="save-widget-configuration-btn" class="btn btn-primary" [loadingFn]="save()">
      <smallstack-i18n data="@@actions.save" />
    </button>
  </div>
</div>
