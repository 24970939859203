import { BillingPositionDto, BillingPositionDtoTypeEnum, ConfigurationDto } from "@smallstack/axios-api-client";
import { ConfigurablePropertyService } from "@smallstack/form-shared";
import { formatCents, humanizeDuration } from "@smallstack/utils";
import { Decimal } from "decimal.js";
export interface TodoBillingPrice {
  suffix?: string;
  calculationText: string;
  value: number;
  billingPosition: BillingPositionDto;
}

export class TodoBillingService {
  constructor(private configurablePropertyService: ConfigurablePropertyService) {}

  /**
   * Returns an array of `TodoBillingPrice`s
   *
   * @param billingPositions The BillingPositions
   * @param durationInMs If set, billingPositions that have an hourly rate will be calculated based on the duration
   */
  public getBilling(
    billingPositions: BillingPositionDto[],
    configurations: ConfigurationDto[],
    durationInMs?: number
  ): TodoBillingPrice[] {
    if (!billingPositions) return [];
    const durationInHours = durationInMs ? new Decimal(durationInMs).div(1000 * 60 * 60).toDecimalPlaces(2) : undefined;
    return billingPositions.map((billingPosition) => {
      // straighten factors
      if (billingPosition.billingParties instanceof Array && billingPosition.billingParties.length > 0) {
        const total = billingPosition.billingParties.map((bp) => bp.factor).reduce((pv, cv) => pv + cv);
        billingPosition.billingParties.forEach((bp) => {
          bp.factor = new Decimal(bp.factor).div(new Decimal(total)).toDecimalPlaces(4).toNumber();
        });
      }

      // calculate price
      const price: TodoBillingPrice = {
        value: 0,
        billingPosition,
        calculationText: undefined
      };
      const billingPositionPriceValue: number = this.configurablePropertyService.getEvaluatedPropertySync(
        billingPosition.price,
        configurations
      );
      if (!billingPositionPriceValue) return price;
      switch (billingPosition.type) {
        case BillingPositionDtoTypeEnum.Fixed: {
          if (billingPosition.amount === undefined) billingPosition.amount = 1;
          price.value = new Decimal(billingPosition.amount).times(new Decimal(billingPositionPriceValue)).toNumber();
          price.calculationText = billingPosition.amount + "x " + formatCents(billingPositionPriceValue);
          break;
        }
        case BillingPositionDtoTypeEnum.HourlyRate:
          if (billingPosition.amount === undefined) billingPosition.amount = 1;
          if (durationInHours) {
            price.value = new Decimal(billingPosition.amount)
              .times(new Decimal(billingPositionPriceValue))
              .times(new Decimal(durationInHours))
              .toNumber();
            price.calculationText =
              humanizeDuration(durationInMs, { shortFormat: true }) + " á " + formatCents(billingPositionPriceValue);
            if (billingPosition.amount !== 1) price.calculationText += " x " + "Faktor " + billingPosition.amount;
          } else {
            price.value = new Decimal(billingPosition.amount).times(new Decimal(billingPositionPriceValue)).toNumber();
            price.suffix = "/ h";
            price.calculationText =
              "Auf der schlussendlichen Rechnung werden die Anzahl der Stunden mal dem Stundensatz berechnet.";
          }
          break;
        default:
          throw new Error("BillingPositionType not implemented yet: " + billingPosition.type);
      }

      // round value to nearest integer
      price.value = Math.round(price.value);
      return price;
    });
  }
}
