import { Directive, HostBinding, computed, inject } from "@angular/core";
import { WidgetDto } from "@smallstack/axios-api-client";
import { BreakpointService } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { cloneObject } from "@smallstack/utils";
import { BaseWidgetComponent } from "@smallstack/widget-core";

@Directive()
export abstract class BaseContainerWidgetComponent extends BaseWidgetComponent {
  private breakpointService = inject(BreakpointService);

  public static direction: "row" | "column" = null; // needs init, otherwise angular prod build gets stuck here

  @HostBinding("style.display")
  public containerDisplay: string = "flex";

  // eslint-disable-next-line max-lines-per-function
  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        backgroundColor: {
          type: "string",
          title: "Hintergrundfarbe",
          "x-schema-form": { widget: "color" }
        },
        fontColor: {
          type: "string",
          title: "Schriftfarbe",
          "x-schema-form": { widget: "color" }
        },
        height: {
          type: "string",
          title: "Höhe",
          default: "100%",
          description: "Die Höhe des Containers",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        width: {
          type: "string",
          title: "Breite",
          default: "100%",
          description: "Die Breite des Containers",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        padding: {
          type: "string",
          title: "Außenabstand",
          default: "12px",
          description: "Abstand des Containers zu anderen Elementen auf Ihrer Seite",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        justifyContent: {
          title: (this.direction === "row" ? "Horizontale" : "Vertikale") + " Anordnung",
          type: "string",
          default: "flex-start",
          enum: [
            "flex-start",
            "center",
            "flex-end",
            "space-between",
            "space-around",
            "space-evenly",
            "normal",
            "stretch"
          ],
          "x-schema-form": { "x-i18n-prefix": "@@components.container.flexattributes." },
          description:
            "Wie sollen Elemente " + (this.direction === "row" ? "horizontal" : "vertikal") + " angeordnet werden?"
        },
        alignItems: {
          title: (this.direction === "row" ? "Vertikale" : "Horizontale") + " Anordnung",
          type: "string",
          default: "flex-start",
          enum: ["flex-start", "center", "flex-end", "stretch", "space-between", "space-around", "space-evenly"],
          "x-schema-form": { "x-i18n-prefix": "@@components.container.flexattributes." },
          description:
            "Wie sollen Elemente " + (this.direction === "row" ? "vertikal" : "horizontal") + " angeordnet werden?"
        },
        gap: {
          type: "string",
          title: "Zwischenabstände",
          default: "12px",
          description: "Abstände zwischen allen Elementen im Container",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        invertedDirection: {
          type: "boolean",
          title: "Richtung umkehren",
          default: false,
          description: "Alle Elemente werden in umgekehrter Reihenfolge angeordnet"
        },
        wrap: {
          type: "boolean",
          title: "Umbrechen",
          default: false,
          description: "Falls der Platz nicht ausreicht, sollen Elemente umgebrochen werden?"
        },
        wrapOnMobile: {
          type: "boolean",
          title: "Umbrechen auf Mobilgeräten",
          default: false,
          description: "Falls der Platz nicht ausreicht, sollen Elemente auf Mobilgeräten umgebrochen werden?"
        },
        wrapReverse: {
          type: "boolean",
          title: "Umbrechen umkehren",
          default: false,
          description: "Sollen umgebrochene Elemente in umgekehrter Reihenfolge angezeigt werden?"
        },
        children: {
          title: "Elemente",
          type: "array",
          description: "Alle Elemente, die in diesem Container angezeigt werden",
          items: {
            type: "object",
            "x-schema-form": {
              widget: "cms"
            }
          } as SchemaFormSchema
        }
      }
    };
  }

  private computedCssStyles = computed(() => {
    const data = this.data();
    if (!data) return;

    const direction = (<typeof BaseContainerWidgetComponent>this.constructor).direction;
    const obj: CSSStyleDeclaration = {} as any;
    if (data.padding) obj.padding = data.padding;
    if (data.gap) obj.gap = data.gap;
    else obj.gap = "10px";
    if (data.height) obj.height = data.height;
    if (data.width) obj.width = data.width;
    if (data.wrap === true) obj.flexWrap = data.wrapReverse === true ? "wrap-reverse" : "wrap";
    else if (data.wrapOnMobile === true && this.breakpointService.isMobile())
      obj.flexWrap = data.wrapReverse === true ? "wrap-reverse" : "wrap";
    else obj.flexWrap = "nowrap";
    if (direction === "row") {
      if (data.invertedDirection) obj.flexDirection = "row-reverse";
      else obj.flexDirection = "row";
    } else {
      if (data.invertedDirection) obj.flexDirection = "column-reverse";
      else obj.flexDirection = "column";
    }
    if (data.justifyContent) obj.justifyContent = data.justifyContent;
    else obj.justifyContent = "flex-start";
    if (data.alignItems) obj.alignItems = data.alignItems;
    else obj.alignItems = "flex-start";

    obj.backgroundColor = data.backgroundColor;
    obj.color = data.fontColor;

    // defaults
    // obj.overflow = this.editMode ? "auto" : "hidden";
    return obj;
  });

  @HostBinding("style")
  public get cssStyles() {
    return this.computedCssStyles();
  }

  public addWidget(widget: WidgetDto): void {
    const data = this.data() || {};
    if (!(data.children instanceof Array)) data.children = [];
    data.children.push(cloneObject(widget));
    this.dataChange.emit(data);
  }

  public childDataChanged(index: number, cmsComponent: WidgetDto): void {
    const data = cloneObject(this.data() || {});
    if (!cmsComponent) data.children.splice(index, 1);
    else data.children[index] = cmsComponent;
    this.dataChange.emit(data);
  }
}
