import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const PeriodOfTimeInputWidget: WidgetRegistryEntry = {
  name: "DurationInput",
  configuration: {
    templateName: "Zeit Abstand Eingabe",
    templateDescription: "Dieses Widget erlaubt es einen Zeit Abstand einzugeben.",
    icon: "clock",
    sockets: [
      {
        direction: SocketDtoDirectionEnum.Out,
        name: "periodOfTime",
        type: SocketDtoTypeEnum.Object
      }
    ]
  },
  component: () => import("./period-of-time-input-widget.component").then((m) => m.PeriodOfTimeInputWidgetComponent)
};
