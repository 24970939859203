import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslatePipe } from "@smallstack/common-components";
import { IconComponent } from "@smallstack/theme-components";

@Component({
  selector: "smallstack-help-icon",
  templateUrl: "./help-icon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, IconComponent, TranslatePipe]
})
export class HelpIconComponent {
  @Input()
  public helpText: string;

  @Input()
  public size: string = "14";
}
