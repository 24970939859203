<mat-form-field class="h-full w-full min-h-full min-w-full">
  @if (label) {
    <mat-label>
      <smallstack-i18n [data]="label"></smallstack-i18n>
    </mat-label>
  }
  <input
    matInput
    [matDatepicker]="basicDatepicker"
    (dateChange)="dateChanged($event)"
    [value]="date"
    readonly
    (click)="basicDatepicker.open()"
    [max]="maxDate"
    [min]="minDate"
    data-test="datestring-input"
  />
  <smallstack-icon
    mat-icon-button
    alias="calendar"
    size="24"
    style="cursor: pointer; margin-right: 5px"
    (click)="basicDatepicker.open()"
    matPrefix
  ></smallstack-icon>
  <mat-datepicker #basicDatepicker></mat-datepicker>
  @if (date) {
    <smallstack-icon
      mat-icon-button
      alias="delete"
      size="24"
      style="cursor: pointer"
      (click)="deleteDate()"
      matSuffix
    ></smallstack-icon>
  }
</mat-form-field>
