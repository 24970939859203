@if (isLoading()) {
  <progress class="progress w-full"></progress>
} @else {
  @if (showFallbackForm) {
    <smallstack-form
      #form
      [schemaWithOptions]="schemaWithOptions"
      [value]="model"
      (valueChange)="modelChange.emit($event)"
    >
      <smallstack-tabbed-form-table />
    </smallstack-form>
  } @else {
    @if (compoundWidget()?.widget) {
      <smallstack-widget-tree
        [widget]="compoundWidget().widget"
        [connections]="compoundWidget().socketConnections"
        [rootSockets]="compoundWidget().rootSockets"
        [context]="model"
        [widgetRendererOptions]="{ dialogRef }"
      />
    } @else {
      <div class="component-loading"></div>
    }
  }
}
