import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  InjectionToken,
  Input,
  OnInit,
  Output,
  effect,
  inject
} from "@angular/core";
import { NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { TypeSchema } from "@smallstack/typesystem";
import { FormOptions } from "../../interfaces/form-options";
import { FormService, SetSchemaOptions } from "../../services/form.service";

export const INPUT_WIDGETS_TRANSLATION_STORE: InjectionToken<TranslationStore> = new InjectionToken(
  "InputWidgetsTranslationStore"
);

/** All smallstack forms shall be wrapped by this component */
@Component({
  selector: "smallstack-form,[smallstack-form]",
  templateUrl: "./form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormService],
  standalone: true
})
export class FormComponent implements OnInit {
  @Input()
  public set schema(schema: TypeSchema) {
    void this.formService.setSchemaWithRefs(schema);
  }

  @Input()
  public set schemaWithOptions(schemaWithOptions: { schema: TypeSchema; options: SetSchemaOptions }) {
    void this.formService.setSchemaWithRefs(schemaWithOptions.schema, schemaWithOptions.options);
  }

  /** these options are the default for every input widget and will also be available via formService.globalFormOptions$ */
  @Input()
  public set options(options: FormOptions) {
    this.formService.setGlobalOptions(options);
  }

  @Input()
  public set value(value: any) {
    this.formService.setValue(value);
  }

  @Output()
  public readonly valueChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly submitForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private notificationService: NotificationService,
    public formService: FormService
  ) {
    this.formService.translationStore = inject(INPUT_WIDGETS_TRANSLATION_STORE, { optional: true });
    if (!this.formService.translationStore) this.formService.translationStore = inject(TranslationStore);

    effect(() => {
      this.valueChange.emit(this.formService.value());
    });

    effect(() => {
      this.formService.submitClicked.listen();
      this.submitForm.emit();
    });
  }

  public ngOnInit(): void {
    // set some defaults
    this.formService.setGlobalOption("showHint", false);
    const options = this.formService.globalFormOptions();
    if (!options.validateOn) this.formService.setGlobalOption("validateOn", "blur");
  }

  public validateForm() {
    return async (): Promise<void> => {
      await this.formService.validateAllFields();
      if (this.formService.isValid() === false) {
        const validationErrors = this.formService.validationErrors();
        // eslint-disable-next-line no-console
        console.error("ValidationErrors:", validationErrors, "for value: ", this.formService.value());
        this.notificationService.showStandardErrorPopup(new Error("Bitte füllen Sie alle Pflichtfelder aus!"));
      }
    };
  }

  public validateFormAndSave<T = any>(saveFn: () => Promise<T>) {
    return async (): Promise<T> => {
      await this.formService.validateAllFields();
      if (this.formService.isValid() === false) {
        // eslint-disable-next-line no-console
        console.error("ValidationErrors:", this.formService.validationErrors());
        return Promise.reject("Bitte füllen Sie alle Pflichtfelder aus! ");
      } else return await saveFn();
    };
  }
}
