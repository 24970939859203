import { AsyncPipe, JsonPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { CompoundWidgetDto } from "@smallstack/axios-api-client";
import { ConvertToSubjectPipe, CustomizationModeStore } from "@smallstack/common-components";
import { TYPE_COMPOUND_WIDGETS, TypeSchema } from "@smallstack/typesystem";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
import { computedAsync } from "ngxtension/computed-async";
import { WidgetTreeComponent } from "../widget-tree/widget-tree.component";

export interface TypeDetailsData {
  model: any;
  schema: TypeSchema;
}

@Component({
  templateUrl: "./type-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-type-details",
  standalone: true,
  imports: [AsyncPipe, WidgetTreeComponent, JsonPipe, ConvertToSubjectPipe]
})
export class TypeDetailsComponent {
  public typePath = input<string>(undefined, { alias: "type" });
  private store = injectStore({ typePath: this.typePath });
  private compoundWidgetStore = injectStore<CompoundWidgetDto>({ typePath: TYPE_COMPOUND_WIDGETS });

  protected schema = computedAsync(async () => {
    await this.typeService.awaitLoaded();
    return this.typeService.getByPath(this.typePath())?.schema;
  });

  public model = input<unknown>(undefined);
  public modelId = input<string>(undefined);

  protected evaluatedModel = computed(() => {
    if (this.model()) return this.model();
    if (this.modelId()) return this.store.getById(this.modelId());
  });

  protected compoundWidget = computed(() => {
    const typePath = this.typePath();
    if (typePath) {
      const type = this.typeService.getByPath(typePath);
      if (type?.views) {
        const detailView = type.views?.find((view) => view.name === "view" || view.name === "details");
        if (detailView) {
          return this.compoundWidgetStore.getById(detailView.compoundWidgetId);
        }
      }
    }
    // return default detail widget
    return {
      name: "DefaultDetailsWidget",
      widget: {
        name: "DefaultDetailsView"
      }
    } as CompoundWidgetDto;
  });

  constructor(
    private typeService: TypeService,
    protected customizationModeStore: CustomizationModeStore
  ) {}
}
