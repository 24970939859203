import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AvatarImageComponent } from "./components/avatar-image/avatar-image.component";
import { IconComponent } from "./components/icon/icon.component";
import { InfoBoxComponent } from "./components/info-box/info-box.component";

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    MatRippleModule,
    MatTooltipModule,
    MatSelectModule,
    IconComponent,
    InfoBoxComponent,
    AvatarImageComponent
  ],
  exports: [AvatarImageComponent, IconComponent, InfoBoxComponent]
})
export class SmallstackThemeModule {}
