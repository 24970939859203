import Ajv from "ajv";
import addFormats from "ajv-formats";
import { typePathToClassName } from "../typesystem/type-utils";
import { SchemaService } from "./schema.service";

export class AjvFactory {
  #instance: Ajv;

  constructor(private schemaService: SchemaService) {}

  public async getInstance(): Promise<Ajv> {
    if (!this.#instance) {
      if (!this.schemaService) throw new Error("no schemaService defined");
      const allSchemas = await this.schemaService.getAllSchemas();
      const convertedSchemas: { [key: string]: any } = {};
      for (const key of Object.keys(allSchemas)) {
        const keyRef = "#/components/schemas/" + typePathToClassName(key);
        if (allSchemas[key]) convertedSchemas[keyRef] = allSchemas[key];
      }
      this.#instance = new Ajv({
        strict: false,
        strictSchema: false,
        removeAdditional: "all",
        strictNumbers: false,
        allErrors: true,
        loadSchema: async (uri: string) => {
          console.log("loadSchema: ", uri);
          const refName = uri.substring(uri.lastIndexOf("/") + 1);
          let schema = await this.schemaService.getSchemaByName(refName);
          schema = await this.schemaService.evalRefSchema(schema);
          return schema;
        },
        schemas: convertedSchemas
      });
      addFormats(this.#instance);
      // Workaround for loadSchema not working
      // console.log("in ajv: ", Object.keys(allSchemas).length);
    }
    return this.#instance;
  }
}
