import { AsyncPipe, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { LengthPipe, NgForTrackByJsonDirective } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { AbstractGroupedSchemaFormTable } from "../schema-form-table/abstract-grouped-schema-form-table";
import { SchemaFormTableComponent } from "../schema-form-table/schema-form-table.component";

@Component({
  selector: "smallstack-tabbed-form-table",
  templateUrl: "./tabbed-schema-form-table.component.html",
  styleUrls: ["./tabbed-schema-form-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    AsyncPipe,
    LengthPipe,
    SchemaFormTableComponent,
    MatTabsModule,
    I18nComponent,
    NgStyle,
    NgForTrackByJsonDirective
  ]
})
export class TabbedSchemaFormTableComponent extends AbstractGroupedSchemaFormTable {}
