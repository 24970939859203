import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CounterDto, CountersApi } from "@smallstack/axios-api-client";
import { TypeDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { CounterType, TypeSupport, toTypeDto } from "@smallstack/typesystem";

@Injectable()
export class CounterStore extends PageableCrudStore<CounterDto> implements TypeSupport {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 100, sort: "name" });
  }

  public async getType(): Promise<TypeDto> {
    return toTypeDto(CounterType);
  }

  protected async createModel(counter: CounterDto): Promise<CounterDto> {
    const res = await this.axiosApiClient.get(CountersApi).createCounter({ counter });
    return res.data;
  }
  protected async patchModel(id: string, counter: Partial<CounterDto>): Promise<CounterDto> {
    const res = await this.axiosApiClient.get(CountersApi).patchCounter({ counter, id });
    return res.data;
  }
  protected async putModel(counter: CounterDto): Promise<CounterDto> {
    const res = await this.axiosApiClient.get(CountersApi).putCounter({ counter, id: counter.id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(CountersApi).deleteCounter({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(CountersApi).deleteManyCounters({ ids });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<CounterDto>> {
    const res = await this.axiosApiClient.get(CountersApi).getCounters(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<CounterDto> {
    const res = await this.axiosApiClient.get(CountersApi).getCounter({ id });
    return res.data;
  }
}
