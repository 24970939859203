import { ChangeDetectionStrategy, Component, computed, effect } from "@angular/core";
import {
  ConfigurablePropertyDto,
  ConfigurablePropertyDtoDisplayFormatEnum,
  ConfigurablePropertyDtoTypeEnum,
  ConfigurationDto
} from "@smallstack/axios-api-client";
import { CONFIGURABLE_PROPERTY_VARIABLES_PREFIX } from "@smallstack/common-components";
import { Configuration, TYPE_CONFIGURATIONS } from "@smallstack/typesystem";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { formatCents } from "@smallstack/utils";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

interface FormattedConfiguration extends ConfigurationDto {
  formattedValue: string;
  formattedKey: string;
}

@Component({
  selector: "smallstack-configurable-property-input",
  templateUrl: "./configurable-property-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurablePropertyInputComponent extends SchemaFormBaseWidget {
  private configurationStore = injectRxEntityStore<Configuration>({
    typeDescriptor: { typePath: TYPE_CONFIGURATIONS }
  });

  protected configurationStoreValues = computed(() => {
    return this.configurationStore
      .getMany()
      .filter((configuration) => configuration.key.startsWith(CONFIGURABLE_PROPERTY_VARIABLES_PREFIX));
  });

  protected configurations = computed<FormattedConfiguration[]>(() => {
    const configurations: any[] = this.configurationStoreValues();
    if (!configurations) return [];
    const schema = this.schema();
    if (!schema) return [];
    return configurations.map((config: FormattedConfiguration) => {
      if (schema["x-schema-form"]?.displayFormat === "currency")
        config.formattedValue = formatCents(parseInt(config.value));
      else config.formattedValue = config.value;
      config.formattedKey = config.key.replace(CONFIGURABLE_PROPERTY_VARIABLES_PREFIX, "");
      return config;
    });
  });

  protected inlineCurrencyValue = computed<string>(() => {
    return "" + this.value()?.inlineValue / 100;
  });
  protected currentConfiguration = computed<FormattedConfiguration>(() => {
    const configurations: any[] = this.configurationStoreValues();
    if (!configurations) return undefined;
    const value = this.value();
    if (value === undefined) return undefined;
    return configurations.find((config) => config.key === value.configurationKey);
  });
  protected displayFormat = computed<ConfigurablePropertyDtoDisplayFormatEnum>(() => {
    return this.schema()?.["x-schema-form"]?.displayFormat;
  });
  // protected configurationMenuOpened = signal(false);

  constructor() {
    super();
    const schemaEffectRef = effect(
      () => {
        const schema = this.schema();
        if (!schema) return;
        let value: ConfigurablePropertyDto = this.value();
        if (!value) {
          value = { parser: schema["x-schema-form"].parser, type: ConfigurablePropertyDtoTypeEnum.Inline };
          this.setValue(value);
        }
        if (!value.type) {
          value.type = ConfigurablePropertyDtoTypeEnum.Inline;
          this.setValue(value);
        }
        if (value.displayFormat !== this.displayFormat()) {
          value.displayFormat = this.displayFormat();
          this.setValue(value);
        }
        if (schema["x-schema-form"]?.parser && value.parser !== schema["x-schema-form"]?.parser) {
          value.parser = schema["x-schema-form"]?.parser;
          this.setValue(value);
        }
        schemaEffectRef.destroy();
      },
      { allowSignalWrites: true }
    );
  }

  protected updateInlineCurrency(newValue: number): void {
    const currentValue: ConfigurablePropertyDto = this.value();
    currentValue.inlineValue = "" + newValue * 100;
    currentValue.configurationKey = undefined;
    currentValue.type = ConfigurablePropertyDtoTypeEnum.Inline;
    this.setValue(currentValue);
  }

  protected updateInlineValue(newValue: any): void {
    const currentValue: ConfigurablePropertyDto = this.value();
    currentValue.inlineValue = newValue;
    currentValue.configurationKey = undefined;
    currentValue.type = ConfigurablePropertyDtoTypeEnum.Inline;
    this.setValue(currentValue);
  }

  protected selectConfigurationKey(key: string): void {
    const currentValue: ConfigurablePropertyDto = this.value();
    currentValue.inlineValue = undefined;
    currentValue.type = ConfigurablePropertyDtoTypeEnum.ProjectConfiguration;
    currentValue.configurationKey = key;
    this.setValue(currentValue);
    // this.configurationMenuOpened.set(false);
  }

  protected updateType(type: ConfigurablePropertyDtoTypeEnum): void {
    const currentValue: ConfigurablePropertyDto = this.value();
    currentValue.type = type;
    this.setValue(currentValue);
  }
}
