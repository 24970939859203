import { ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { SafeHtmlPipe } from "@smallstack/common-components";
import { TranslationStore } from "@smallstack/i18n-components";
import { InlineTranslation, isInlineTranslation } from "@smallstack/i18n-shared";
import { MarkdownService } from "../../services/markdown.service";

@Component({
  selector: "smallstack-markdown-viewer",
  templateUrl: "./markdown-viewer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeHtmlPipe]
})
export class MarkdownViewerComponent {
  protected convertedMarkdown = signal(undefined);

  @Input()
  public set text(text: string | InlineTranslation) {
    if (isInlineTranslation(text) || (typeof text === "string" && text.includes("@@"))) {
      void this.markdownService.parseMarkdown(this.translationStore.translate(text)).then((parsedMarkdown) => {
        this.convertedMarkdown.set(parsedMarkdown);
      });
    } else if (typeof text === "string")
      void this.markdownService.parseMarkdown(text).then((parsedMarkdown) => {
        this.convertedMarkdown.set(parsedMarkdown);
      });
  }

  constructor(
    private translationStore: TranslationStore,
    private markdownService: MarkdownService
  ) {}
}
