import { TypeSchema } from "../typesystem/type-schema";

export enum ConfigurablePropertyType {
  Inline = "inline",
  ProjectConfiguration = "projectConfiguration"
}

export enum ConfigurablePropertyParser {
  Number = "number",
  String = "string",
  Boolean = "boolean"
}

export enum ConfigurablePropertyDisplayFormat {
  Raw = "raw",
  Currency = "currency"
}

export interface ConfigurableProperty {
  type: ConfigurablePropertyType;
  inlineValue?: string;
  configurationKey?: string;
  parser: ConfigurablePropertyParser;
  displayFormat?: ConfigurablePropertyDisplayFormat;
}

export const ConfigurablePropertySchema: TypeSchema = {
  type: "object",
  title: "ConfigurableProperty",
  properties: {
    type: {
      type: "string",
      enum: Object.values(ConfigurablePropertyType)
    },
    inlineValue: {
      type: "string"
    },
    configurationKey: {
      type: "string"
    },
    parser: {
      type: "string",
      enum: Object.values(ConfigurablePropertyParser)
    },
    displayFormat: {
      type: "string",
      enum: Object.values(ConfigurablePropertyDisplayFormat)
    }
  },
  required: ["type", "parser"],
  additionalProperties: false
};
