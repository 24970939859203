import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  GLOBAL_VARIABLE_NAME = "globalVariableName",
  ARRAY_SCHEMA = "arraySchema",
  FORM_DEFAULT_VALUES = "formDefaultValues"
}

export enum SOCKETS {
  VALUE = "value"
}

export const AddToArrayFormWidget: WidgetRegistryEntry = {
  name: "AddToArrayForm",
  configuration: {
    templateName: "Add to Array Form",
    templateDescription:
      "Ein Widget, welches ein Formular anzeigt, um ein Element zu einem Array hinzuzufügen, welches sich in einem Objekt auf dem aktuellen Kontext befindet.",
    icon: "add",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.GLOBAL_VARIABLE_NAME]: {
          type: "string",
          title: "Globale Variable Name",
          description: "Der Name der Variable, welche das Array enthält, welches erweitert werden soll."
        },
        [CONFIGURATION_KEYS.ARRAY_SCHEMA]: {
          type: "string",
          title: "Formular Schema",
          description: "Das Schema des Formulars, welches das neue Element beschreibt.",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "json"
          }
        },
        [CONFIGURATION_KEYS.FORM_DEFAULT_VALUES]: {
          type: "object",
          title: "Default Werte",
          description: "Die Default Werte für das Formular.",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "json"
          }
        }
      }
    },
    sockets: [{ direction: SocketDtoDirectionEnum.Out, name: SOCKETS.VALUE, type: SocketDtoTypeEnum.Object }]
  },
  component: () => import("./add-to-array-form-widget.component").then((m) => m.AddToArrayFormWidgetComponent)
};
