@if (data().text === undefined || data().text === "") {
  <button class="btn-action btn-primary" [loadingFn]="openDialog()" [matTooltip]="data().tooltip">
    <smallstack-icon [name]="data().icon" theme="fluency-systems-regular" class="text-primary"></smallstack-icon>
  </button>
} @else {
  <button
    class="btn btn-primary btn-sm {{ data().width }}"
    [loadingFn]="openDialog()"
    [matTooltip]="data().tooltip"
    data-test="dialog-action-btn"
  >
    <div class="flex flex-row space-x-2 items-center">
      @if (data().icon) {
        <smallstack-icon
          [name]="data().icon"
          size="md"
          themeColor="daisyTheme.primary.textColor"
          theme="fluency-systems-regular"
        ></smallstack-icon>
      }
      <span>{{ data().text }}</span>
    </div>
  </button>
}
