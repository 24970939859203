import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES } from "@smallstack/core-common";
import { WIDGET_FORM_INPUT_TYPE_DESCRIPTOR } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  TYPE_PATH = "typePath",
  DATA_OVERRIDE = "dataOverride"
}

export enum SOCKETS {
  SAVED_SUCCESSFULLY = "savedSuccessfully",
  ENTITY = "entity"
}

export const SocketSaveModelBtnWidget: WidgetRegistryEntry = {
  name: "SocketSaveModelBtn",
  configuration: {
    templateName: "Entität Speichern Button",
    templateDescription: "Ein Widget, welches einen Button anzeigt, um eine Entität zu speichern.",
    icon: "fa-floppy-disk",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.TYPE_PATH]: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_TYPE_DESCRIPTOR
          }
        },
        dataOverride: {
          type: "object",
          title: "Datenüberschreibung",
          description:
            "Überschreibt die angegebenen Daten, bevor diese an den Server gesendet werden. 'model' ist die Entität, die gespeichert wird. 'context' ist der Kontext des Widgets.",
          "x-schema-form": {
            widget: "monaco",
            language: "json"
          }
        },
        ...BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES
      }
    },
    sockets: [
      { direction: SocketDtoDirectionEnum.Out, name: SOCKETS.SAVED_SUCCESSFULLY, type: SocketDtoTypeEnum.Boolean },
      { direction: SocketDtoDirectionEnum.In, name: SOCKETS.ENTITY, type: SocketDtoTypeEnum.Object }
    ]
  },
  component: () => import("./socket-save-model-btn-widget.component").then((m) => m.SaveModelBtnWidgetComponent)
};
