import { Pipe, PipeTransform } from "@angular/core";
import { isEmptyObject } from "@smallstack/utils";

/**
 * Pipe returns true if given value is not empty, undefined or null
 */
@Pipe({ name: "nonEmptyObject", standalone: true })
export class NonEmptyObjectPipe implements PipeTransform {
  public transform(obj: unknown): boolean {
    if (obj === undefined || obj === null) return false;
    return !isEmptyObject(obj);
  }
}
