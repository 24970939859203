import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { BooleanEquationOperator } from "@smallstack/utils";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  GLOBAL_VARIABLE_NAME = "globalVariableName",
  TYPE_PATH = "typePath",
  BTN_TEXT = "btnText",
  HTTP_METHOD = "httpMethod",
  PATCH_SUB_PATH = "patchSubPath"
}

export enum SOCKETS {
  SAVED_SUCCESSFULLY = "savedSuccessfully"
}

export const SaveModelBtnWidget: WidgetRegistryEntry = {
  name: "SaveModelBtn",
  configuration: {
    templateName: "Entität Speichern Button",
    templateDescription: "Ein Widget, welches einen Button anzeigt, um eine Entität zu speichern.",
    icon: "save--v1",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.TYPE_PATH]: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        },
        [CONFIGURATION_KEYS.GLOBAL_VARIABLE_NAME]: {
          type: "string",
          title: "Variable Name",
          description: "Der Name der Variable, welche das Array enthält, welches erweitert werden soll."
        },
        [CONFIGURATION_KEYS.BTN_TEXT]: {
          type: "string",
          title: "Button Text",
          description: "Der Text, welcher auf dem Button angezeigt werden soll.",
          default: "Speichern"
        },
        [CONFIGURATION_KEYS.HTTP_METHOD]: {
          type: "string",
          title: "HTTP Methode",
          description: "Die HTTP Methode, welche für das Speichern verwendet werden soll.",
          default: "PUT",
          enum: ["PUT", "PATCH"]
        },
        [CONFIGURATION_KEYS.PATCH_SUB_PATH]: {
          type: "string",
          title: "Patch Sub Path",
          description: "Der Sub Pfad, welcher für das Patchen verwendet werden soll.",
          "x-schema-form": {
            rules: [
              {
                if: { dataPath: "../httpMethod", value: "PATCH", operator: BooleanEquationOperator.NOT_EQUALS },
                action: "hide"
              }
            ]
          }
        }
      }
    },
    sockets: [
      { direction: SocketDtoDirectionEnum.Out, name: SOCKETS.SAVED_SUCCESSFULLY, type: SocketDtoTypeEnum.Boolean }
    ]
  },
  component: () => import("./save-model-btn-widget.component").then((m) => m.SaveModelBtnWidgetComponent)
};
