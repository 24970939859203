<div class="dialog">
  <div class="dialog-title">
    <smallstack-icon alias="addWidget" />
    <smallstack-i18n data="@@backoffice.widgets.add" />
  </div>
  <div class="dialog-content">
    <div class="flex flex-col">
      <div class="join">
        <input
          type="text"
          placeholder="Widgets durchsuchen..."
          class="input input-bordered input-lg w-full join-item"
          [(ngModel)]="filterText"
          (ngModelChange)="filterWidgets()"
        />
        @if (filterText) {
          <button class="btn btn-secondary btn-lg join-item" (click)="clearSearch()">
            <smallstack-icon name="fa-trash-can" size="24" />
          </button>
        }
      </div>
      <div class="flex flex-row justify-end my-2">
        <mat-checkbox (change)="showDeprecated($event)">Veraltete Widgets anzeigen </mat-checkbox>
        <smallstack-info-box>
          <h2>Veraltete Widgets</h2>
          Von Zeit zu Zeit entwickeln wir eine neue Version für ein Widget, welche nicht mehr mit der aktuellen
          Konfiguration zusammenpasst. Damit Sie als Kunde nicht ständig auf die neueste Version aktualisieren müssen,
          behalten wir veraltete Widgets im System, bieten diese aber nicht mehr für neue Seiten an. Sollten Sie jedoch
          das Bedürfnis haben, ein altes Widget verwenden zu müssen, so können Sie dies nach wie vor tun, indem Sie
          "veraltete Widgets" anklicken. <br />
          Nach einer geraumen Zeit behalten wir uns jedoch vor, Altlasten abzuwerfen und dabei auch veraltete Widgets zu
          löschen. Hier würden wir uns selbstverständlich bei Ihnen melden und eine Migrationshilfe zur Verfügung
          stellen.
        </smallstack-info-box>
      </div>
    </div>
    <div class="flex flex-row flex-wrap gap-2 pb-4">
      @for (widgetTag of allWidgetTagsWithCounts; track widgetTag) {
        <span
          class="badge badge-lg"
          style="cursor: pointer"
          [ngClass]="{ 'badge-primary': widgetTagFilters.includes(widgetTag.name) }"
          (click)="toggleFilter(widgetTag.name)"
        >
          <smallstack-i18n data="@@backoffice.widgets.tags.{{ widgetTag.name }}.label" /> ({{ widgetTag.count }})</span
        >
      }
    </div>
    <div data-test="add-widget-dialog">
      @if (filteredWidgets.length === 0) {
        <div style="padding: 30px; text-align: center">
          <i>Keine Widgets gefunden, die Ihren Suchkriterien entsprechen</i>
        </div>
      }
      <div class="flex flex-row justify-between" [highlight]="filterText">
        <div class="widget-grid" style="flex: 1">
          <div class="smallstack-grid" style="padding: 10px">
            @for (widget of filteredWidgets; track widget) {
              <div class="smallstack-grid-card">
                <div
                  class="content flex flex-col space-y-3 center-center"
                  style="place-content: unset; justify-content: start"
                >
                  <smallstack-icon
                    themeColor="daisyTheme.primary.bgColor"
                    class="text-primary"
                    size="80"
                    [alias]="widget.evaluatedConfiguration.icon"
                  />
                  <div class="text-xl font-bold text-primary">
                    {{ widget.evaluatedConfiguration.templateName }}
                  </div>
                  <div>{{ widget.evaluatedConfiguration.templateDescription }}</div>
                  @if (widget.evaluatedConfiguration.deprecated) {
                    <div style="color: red">Veraltetes Widget</div>
                  }
                  @if (widget.evaluatedConfiguration.deprecatedMessage) {
                    <smallstack-i18n class="text-muted" [data]="widget.evaluatedConfiguration.deprecatedMessage" />
                  }
                </div>
                <div class="actions wide flex flex-row justify-center items-end">
                  <button
                    class="btn btn-block btn-primary no-animation"
                    [loadingFn]="addWidget(widget)"
                    attr.data-test="add-widget-{{ widget.name }}"
                  >
                    <smallstack-i18n data="@@actions.add" />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
        @if (detailViewWidget) {
          <div fxHide.lt-md style="padding-left: 20px">
            <div class="info-box" style="width: 300px">
              <div style="cursor: pointer" align="end" (click)="closeDetailView()">
                <smallstack-icon alias="close" size="24" />
              </div>
              <div class="content flex flex-col space-y-3">
                <div style="font-size: 16px; font-weight: 100; text-transform: uppercase">
                  {{ detailViewWidget.evaluatedConfiguration.templateName }}
                </div>
                <smallstack-icon color="primary" size="80" [alias]="detailViewWidget.evaluatedConfiguration.icon" />
                <div>{{ detailViewWidget.evaluatedConfiguration.templateDescription }}</div>
                <div style="zoom: 0.7" class="flex flex-row flex-wrap space-x-2 items-center">
                  <span>Einsatzgebiete: </span>
                  @for (widgetTag of allWidgetTagsWithCounts; track widgetTag) {
                    <span
                      class="badge"
                      [style.background-color]="
                        detailViewWidget.evaluatedConfiguration?.tags.includes(widgetTag.name) ? 'primary' : 'silver'
                      "
                    >
                      <smallstack-i18n data="@@backoffice.widgets.tags.{{ widgetTag.name }}.label"
                    /></span>
                  }
                </div>
                <br />
                <button
                  class="btn btn-block btn-primary"
                  [loadingFn]="addWidget(detailViewWidget)"
                  attr.data-test="widget-{{ detailViewWidget.name }}"
                >
                  <smallstack-i18n data="@@backoffice.widgets.add" />
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="dialog-actions">
    <button class="btn" (click)="close()">
      <smallstack-i18n data="@@actions.cancel" />
    </button>
  </div>
</div>
