<div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  [cdkConnectedOverlayDisableClose]="false"
  [cdkConnectedOverlayOpen]="overlayOpened()"
  (backdropClick)="closeOverlay()"
  (overlayOutsideClick)="closeOverlay()"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
>
  <ng-content></ng-content>
</ng-template>
