<div
  class="user-badge flex flex-row items-center"
  [style.background-color]="data().backgroundColor"
  (click)="!currentUser ? showLogin() : openMenu()"
>
  <smallstack-avatar class="m-2" size="sm" [url]="currentUser?.profile?.avatarUrl"></smallstack-avatar>
  @if (data().viewType === "normal") {
    <div class="user-labels flex flex-col items-start flex-1">
      <span class="username mb-0" [style.color]="data().fontColor">{{
        currentUser ? currentUser?.displayName : "JETZT ANMELDEN"
      }}</span>
      @if (currentUser?.displayName !== currentUser?.primaryEmailAddress) {
        <span class="mat-caption" [style.color]="data().fontColor">{{ currentUser?.primaryEmailAddress }}</span>
      }
    </div>
  }
  @if (currentUser) {
    <div class="sub-menu" [matMenuTriggerFor]="userMenu" #menuTrigger="matMenuTrigger">
      <smallstack-icon name="sort-down" size="20" color="efefef" theme="material-rounded"> </smallstack-icon>
      <mat-menu #userMenu="matMenu" class="user-menu" style="margin-top: 12px" xPosition="before">
        @if (data().menuButtons) {
          @for (menuButton of data().menuButtons; track menuButton) {
            <button mat-menu-item [routerLink]="menuButton.link">
              <smallstack-i18n [data]="menuButton.label"></smallstack-i18n>
            </button>
          }
        }
        <button mat-menu-item (click)="logout()">Abmelden</button>
      </mat-menu>
    </div>
  }
</div>
