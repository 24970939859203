import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { IconComponent } from "@smallstack/theme-components";
import { toDateString } from "@smallstack/utils";
import { parseISO } from "date-fns";

@Component({
  selector: "smallstack-datestring-editor",
  templateUrl: "./datestring-editor.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, MatDatepickerModule, I18nComponent, MatInputModule, IconComponent]
})
export class DatestringEditorComponent implements OnChanges, OnInit {
  @Input()
  public timestamp: string;

  @Input()
  public layout: "column" | "row" | "row wrap" | "column wrap" = "column";

  @Output()
  public readonly timestampChange = new EventEmitter<string>();

  @Input()
  public defaultsToNow: boolean = true;

  @Input()
  public onlyDateInFuture: boolean;

  @Input()
  public onlyDateInPast: boolean;

  @Input()
  public label: string | InlineTranslation;

  public date: Date;

  public maxDate: Date;
  public minDate: Date;

  public ngOnInit(): void {
    if (this.defaultsToNow) this.date = new Date();

    if (this.onlyDateInFuture) this.minDate = new Date();

    if (this.onlyDateInPast) this.maxDate = new Date();
  }

  public ngOnChanges(): void {
    if (this.timestamp) {
      const incomingTimestamp = parseISO(this.timestamp);
      this.date = new Date(incomingTimestamp.valueOf());
    }
  }

  public save(): void {
    this.timestamp = toDateString(this.date);
    this.timestampChange.emit(this.timestamp);
  }

  public dateChanged(changed: MatDatepickerInputEvent<Date>): void {
    this.date = changed.value;
    this.save();
  }

  public deleteDate(): void {
    this.date = null;
    this.timestampChange.emit(undefined);
  }
}
