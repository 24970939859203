import { ChangeDetectionStrategy, Component, effect } from "@angular/core";
import { RouterUtilService } from "@smallstack/common-components";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "Redirect",
  templateName: "Weiterleitung",
  templateDescription: "Diese Komponente leitet den Benutzer auf eine andere Seite weiter.",
  icon: "link--v1",
  dataSchema: {
    type: "object",
    properties: {
      url: {
        type: "string",
        title: "URL",
        description: "Die URL auf die weitergeleitet werden soll."
      }
    }
  }
})
@Component({
  selector: "smallstack-redirect-widget",
  standalone: true,
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectWidgetComponent extends BaseWidgetComponent {
  constructor(private routerUtilService: RouterUtilService) {
    super();
    effect(() => {
      const data = this.data();
      const editMode = this.widgetTreeService.editMode();
      if (data && data.url && editMode !== true) void this.routerUtilService.navigateFullUrl(data.url);
    });
  }
}
