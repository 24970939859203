import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe to get a "value" by "key" out of an object array.
 *
 * Example:
 * const dict = [{key: "test", value: "Hello World"}]
 *
 * Pipe Usage: <ng-container *ngIf="dict | objectArrayValue:'key':'test':'value':'defaultValue'>"
 */
@Pipe({ name: "objectArrayValue", standalone: true, pure: true })
export class ObjectArrayValuePipe implements PipeTransform {
  public transform(array: any[], keyName: string, keyValue: string, outputKey: string, defaultValue?: string): any {
    if (!array || !(array instanceof Array) || array.length === 0) return defaultValue;
    const foundValue = array.find((entry) => entry[keyName] === keyValue);
    if (foundValue && foundValue[outputKey] !== undefined) return foundValue[outputKey];
    else return defaultValue;
  }
}
