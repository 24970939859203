<div mat-dialog-content>
  <smallstack-widget-tree
    [(widget)]="data.cmsComponent"
    [widgetRendererOptions]="data?.widgetRendererOptions"
  ></smallstack-widget-tree>
</div>
@if (data.showCloseBtn === true) {
  <div mat-dialog-actions class="flex flex-row justify-end">
    <button (click)="close()" class="btn btn-primary">
      <smallstack-i18n data="@@actions.close"></smallstack-i18n>
    </button>
  </div>
}
