import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from "@angular/core";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { StoreRegistry } from "@smallstack/common-components";
import { PageableStore } from "@smallstack/store";
import { SearchByFieldMatcher } from "@smallstack/typesystem";

/**
 * This component can be used to reverse search linked models across all smallstack models.
 *
 * Example:
 * - You have a lot of cars stored (custom datatype). Each car has an owner (car.ownerId, an id of a user)
 * - Now you want to open a user and show all cars they own.
 * - This can be achieved by using this component, setting the store to `custom/cars`, the id to `currentUser.id` and the property to `ownerId`
 */
@Component({
  selector: "smallstack-reverse-linked-models",
  templateUrl: "./reverse-linked-models.component.html",
  styleUrls: ["./reverse-linked-models.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReverseLinkedModelsComponent implements OnChanges {
  /** The store to look for reverse linked models */
  @Input()
  public set type(type: string) {
    if (type) {
      this.#type = type;
      this.#store = this.storeRegistry.getStore<PageableStore>(type)?.clone();
      if (!this.#store) throw new Error("No store found for " + type);
    }
  }

  /** The value to search for */
  @Input()
  public value: string;

  /** The property where the given value is stored in */
  @Input()
  public foreignProperty: string;

  @Input()
  public width: "list" | "small" | "medium" | "large";

  @Input()
  public notFoundText: string = "@@components.links.reversedLinks.noneFound";

  public links: LinkedModelDto[];
  public isLoading: boolean = false;

  #store: PageableStore;
  #type: string;

  constructor(
    private storeRegistry: StoreRegistry,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnChanges(): void {
    if (this.#store !== undefined && this.foreignProperty !== undefined && this.value !== undefined) {
      this.isLoading = true;
      this.cdr.markForCheck();
      const searchStore = this.#store.createSearch({
        fieldSearches: [
          {
            fieldname: this.foreignProperty,
            value: this.value,
            caseSensitive: true,
            matcher: SearchByFieldMatcher.EQUALS
          }
        ],
        logicalOperator: "and"
      });
      void searchStore
        .load()
        .then(() => {
          this.links = searchStore.value.map((m) => ({ id: m.id, type: this.#type }));
        })
        .finally(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        });
    }
  }
}
