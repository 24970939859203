import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CalendarEntryDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { LinkedModelsComponent } from "@smallstack/link-components";
import { IconComponent } from "@smallstack/theme-components";
import { ExtensionSlotComponent } from "@smallstack/widget-core";
import { addHours, addWeeks, set } from "date-fns";
import { CalendarEntriesStore } from "../../stores/calendar-entries.store";
import { CalendarEntryFormComponent } from "../calendar-entry-form/calendar-entry-form.component";

export interface CalendarEntryFormDialogData {
  calendarEntry?: CalendarEntryDto;
  title?: string | InlineTranslation;
}

@Component({
  templateUrl: "./calendar-entry-form-dialog.component.html",
  styleUrls: ["./calendar-entry-form-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    I18nComponent,
    IconComponent,
    LinkedModelsComponent,
    ExtensionSlotComponent,
    LoadingElementDirective,
    MatDialogModule,
    CalendarEntryFormComponent,
    MatFormFieldModule,
    MatChipsModule
  ]
})
export class CalendarEntryFormDialogComponent {
  private readonly start = addWeeks(set(new Date(), { hours: 14, minutes: 0, seconds: 0, milliseconds: 0 }), 1);

  @ViewChild(CalendarEntryFormComponent)
  private calendarEntryFormComponent: CalendarEntryFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CalendarEntryFormDialogData,
    private notificationService: NotificationService,
    private calendarEntriesStore: CalendarEntriesStore,
    private dialogRef: MatDialogRef<unknown>,
    private cdr: ChangeDetectorRef
  ) {
    if (!data) data = {};
    if (!data.calendarEntry)
      data.calendarEntry = {
        content: "",
        start: this.start.valueOf(),
        end: addHours(this.start, 1).valueOf(),
        location: "",
        title: "",
        tags: []
      } as any;
  }

  public saveEntry() {
    return async (): Promise<void> => {
      await this.calendarEntryFormComponent.form.formService.validateAllFields();
      if (!this.calendarEntryFormComponent.form.formService.isValid()) return;
      await this.notificationService.handlePromise(this.calendarEntriesStore.createOrPut(this.data.calendarEntry));
      this.dialogRef.close();
    };
  }

  public deleteEntry() {
    return async (): Promise<void> => {
      await this.notificationService.handlePromise(this.calendarEntriesStore.delete(this.data.calendarEntry.id));
      this.dialogRef.close();
    };
  }

  public addTag(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    if (value) {
      this.data.calendarEntry.tags
        ? this.data.calendarEntry.tags.push(value)
        : (this.data.calendarEntry.tags = [value]);
    }

    event.chipInput?.clear();
  }

  public removeTag(tag: string): void {
    const index = this.data.calendarEntry.tags.indexOf(tag);

    if (index >= 0) {
      this.data.calendarEntry.tags.splice(index, 1);
      this.cdr.markForCheck();
    }
  }
}
