<div class="text-3xl my-2">Rechnung erstellen</div>

<smallstack-loading-state [state]="viewState().loadingState">
  <ng-template #loaded>
    @if (!invoices) {
      <div>
        Es werden Rechnungen mit den folgenden Parametern erstellt:
        <table class="table w-full">
          <tr>
            <td class="font-bold">Arbeitsprotokolle</td>
            <td>
              <span>{{ viewState().worklogCount }} ausgewählt</span>
            </td>
          </tr>
          <tr>
            <td class="font-bold">Nummernkreis</td>
            <td>
              @if (viewState().counterName) {
                <span>{{ viewState().counterName }}</span>
              } @else {
                <span class="italic">Es wird kein Nummernkreis verwendet</span>
              }
            </td>
          </tr>
          <tr>
            <td class="font-bold">Zuschläge</td>
            <td>
              @if (viewState().surcharges?.length > 0) {
                @for (surcharge of viewState().surcharges; track surcharge.id) {
                  <div><smallstack-i18n [data]="surcharge.title"></smallstack-i18n></div>
                }
              } @else {
                <span class="italic">Es werden keine Zuschläge angewendet</span>
              }
            </td>
          </tr>
          <tr>
            <td class="font-bold">Leistungszeitraum Start</td>
            <td>
              <input
                type="date"
                class="input input-bordered w-full"
                [ngModel]="invoiceContext().billingPeriodStart | date: DEFAULT_HTML5_DATE_FORMAT"
                (ngModelChange)="setBillingPeriodStart($event)"
              />
            </td>
          </tr>
          <tr>
            <td class="font-bold">Leistungszeitraum Ende</td>
            <td>
              <input
                type="date"
                class="input input-bordered w-full"
                [ngModel]="invoiceContext().billingPeriodEnd | date: DEFAULT_HTML5_DATE_FORMAT"
                (ngModelChange)="setBillingPeriodEnd($event)"
              />
            </td>
          </tr>
        </table>
      </div>
    }

    @if (invoices) {
      <div class="overflow-x-auto">
        <div class="alert alert-success my-2">Rechnungen wurden erfolgreich erstellt!</div>
        <table class="table w-full h-full">
          <!-- head -->
          <thead>
            <tr>
              <td>Empfänger</td>
              <td>Adresse</td>
              <td>Positionen</td>
            </tr>
          </thead>
          <tbody>
            @for (invoice of invoices; track invoice) {
              <tr>
                <td class="align-top font-bold text-lg"
                  ><smallstack-i18n [data]="invoice.recipient"></smallstack-i18n
                ></td>
                <td class="align-top"
                  >{{ invoice.address?.street }} {{ invoice.address?.streetNumber }}, {{ invoice.address?.zip }}
                  {{ invoice.address?.city }}</td
                >
                <td>
                  <div class="max-h-40 overflow-y-auto overflow-x-hidden">
                    <table class="table">
                      <thead>
                        <tr>
                          @for (positionHeader of invoice.positionHeaders; track positionHeader) {
                            <th>
                              <smallstack-i18n [data]="positionHeader.translation"></smallstack-i18n>
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @for (position of invoice.positions; track position) {
                          <tr>
                            @for (positionHeader of invoice.positionHeaders; track positionHeader) {
                              <td>
                                <smallstack-i18n
                                  [data]="
                                    position.values | objectArrayValue: 'key' : positionHeader.key : 'translation'
                                  "
                                ></smallstack-i18n>
                              </td>
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    <div class="flex flex-row gap-2 justify-evenly pt-5">
      @if (this.widgetTreeService.dialogRef) {
        <button (click)="this.widgetTreeService.dialogRef.close()" class="btn btn-wide btn-ghost">Schließen</button>
      }
      @if (!invoices) {
        <button
          [loadingFn]="createBill()"
          class="btn btn-wide btn-primary"
          data-test="create-invoices-btn"
          [disabled]="viewState().loadingState !== 'loaded'"
          >Rechnungen erstellen</button
        >
      }
      @if (invoices) {
        <button (click)="gotoInvoices()" class="btn btn-wide btn-primary" data-test="goto-invoices-btn"
          >Gehe zu alle Rechnungen</button
        >
      }
    </div>
  </ng-template>
</smallstack-loading-state>
