import { Inject, Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, ProjectsApi, PublicUserDto, TypeDto } from "@smallstack/axios-api-client";
import { ContextService, RESELLER_API_CLIENT } from "@smallstack/common-components";
import { PageableStore } from "@smallstack/store";
import { TypeSupport, UserType } from "@smallstack/typesystem";

@Injectable({ providedIn: "root" })
export class ProjectUserStore extends PageableStore<PublicUserDto> implements TypeSupport {
  constructor(
    @Inject(RESELLER_API_CLIENT) private axiosApiClient: AxiosApiClient,
    private contextService: ContextService
  ) {
    super();
  }

  public async getType(): Promise<TypeDto> {
    return UserType;
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<PublicUserDto>> {
    const res = await this.axiosApiClient
      .get(ProjectsApi)
      .getUsersForProject({ projectId: this.contextService.getEvaluatedContext()?.tenantId, ...query });
    return res.data;
  }

  protected async loadModelById(id: string): Promise<PublicUserDto> {
    if (!this.hasId(id)) await this.preload();
    return this.getById(id);
  }
}
