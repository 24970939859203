/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable rxjs/no-ignored-subscription */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */

import { BehaviorSubject, debounce, timer } from "rxjs";
import { sortByKey } from "./object-utils";

const currentCount: {
  [identifier: string]: { callCount: number; totalExecutionTime: number; executionTimes: number[] };
} = {};
const displayCurrentCounts: BehaviorSubject<void> = new BehaviorSubject(undefined);
displayCurrentCounts.pipe(debounce(() => timer(1000))).subscribe(() => {
  if (Object.values(currentCount).length > 0) console.table(sortByKey(currentCount));
});

export function CountMethodCalls() {
  return function (target: any) {
    for (const propertyName of Object.getOwnPropertyNames(target.prototype)) {
      if (propertyName === "constructor") continue;
      const descriptor = Object.getOwnPropertyDescriptor(target.prototype, propertyName);
      if (!descriptor) {
        continue;
      }
      const originalMethod = descriptor.value;
      const isMethod = originalMethod instanceof Function;
      if (!isMethod) {
        continue;
      }
      descriptor.value = function (...args: any[]) {
        const identifier = `${target.name}.${propertyName}`;
        const now = Date.now();
        const result = originalMethod.apply(this, args);
        const exitLog = () => {
          if (currentCount[identifier] === undefined)
            currentCount[identifier] = { callCount: 0, executionTimes: [], totalExecutionTime: 0 };
          currentCount[identifier].callCount++;
          currentCount[identifier].totalExecutionTime += Date.now() - now;
          currentCount[identifier].executionTimes.push(Date.now() - now);
          displayCurrentCounts.next();
        };
        // work around to support async functions.
        if (typeof result === "object" && typeof result.then === "function") {
          const promise = result.then(exitLog);
          // we defer responsibility to the caller of the method to handle the error.
          // but we need to catch the error otherwise we will get an unhandled error.
          // notice we return the original result not the promise with the logging call.
          if (typeof promise.catch === "function") {
            promise.catch((e: any) => e);
          }
        } else {
          exitLog();
        }
        return result;
      };
      Object.defineProperty(target.prototype, propertyName, descriptor);
    }
  };
}

/** for standalone functions */
export function CountFunctionCalls(identifier: string, fn: () => any): any {
  if (currentCount[identifier] === undefined)
    currentCount[identifier] = { callCount: 0, executionTimes: [], totalExecutionTime: 0 };
  const now = Date.now();

  const result = fn();

  const exitLog = () => {
    currentCount[identifier].callCount++;
    currentCount[identifier].totalExecutionTime += Date.now() - now;
    currentCount[identifier].executionTimes.push(Date.now() - now);
    displayCurrentCounts.next();
  };

  // work around to support async functions.
  if (typeof result === "object" && typeof result.then === "function") {
    const promise = result.then(exitLog);

    // we defer responsibility to the caller of the method to handle the error.
    // but we need to catch the error otherwise we will get an unhandled error.
    // notice we return the original result not the promise with the logging call.
    if (typeof promise.catch === "function") {
      promise.catch((e: any) => e);
    }
  } else {
    exitLog();
  }

  return result;
}
