@if (!links()) {
  <span class="component-loading"></span>
} @else {
  @if (data()?.widget) {
    @if (links()?.length > 0) {
      <smallstack-widget-child
        property="widget"
        [data]="data()"
        (dataChange)="dataChange.emit($event)"
        [context]="links()"
      ></smallstack-widget-child>
    } @else {
      @if (data().noneFoundText) {
        <smallstack-i18n [data]="data().noneFoundText"></smallstack-i18n>
      }
    }
  } @else {
    <i>Widget ist nicht konfiguriert</i>
  }
}
