import Dexie from "dexie";

export async function getAllIndexDbDatabaseNames(): Promise<string[]> {
  const databases = await indexedDB.databases();
  return databases.map((database) => database.name);
}

export async function getIndexDbDocumentCount(databaseName: string): Promise<number> {
  const db = new Dexie(databaseName);
  await db.open();
  let count = 0;
  for (const table of db.tables) {
    count += await table.count();
  }
  return count;
}

export async function deleteAllIndexDbData(): Promise<void> {
  const databaseNames = await getAllIndexDbDatabaseNames();
  for (const databaseName of databaseNames) {
    try {
      indexedDB.deleteDatabase(databaseName);
      console.log(`Deleted database ${databaseName}!`);
    } catch (e) {
      console.error(`Error deleting database ${databaseName}`, e);
    }
  }
}
