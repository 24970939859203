export interface GeoJson {
  type: "Point" | "LineString" | "Polygon" | "MultiPoint" | "MultiLineString" | "MultiPolygon";
  coordinates: Array<number>;
}

export interface AddressLike {
  street: string;
  streetNumber: string;
  country: string;
  zip: string;
  city: string;
  geoJson?: GeoJson;
}

export interface GeocoderAddressComponentLike {
  /**
   * The full text of the address component
   */
  long_name: string;
  /**
   * The abbreviated, short text of the given address component
   */
  short_name: string;
  /**
   * An array of strings denoting the type of this address component. A list
   * of valid types can be found <a
   * href="https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes">here</a>
   */
  types: string[];
}

export interface LatLng {
  lat: number;
  lng: number;
}

/** often used when the lat/lng could also be a to-be-replaced variable like `${lat}` */
export interface LatLngStrings {
  lat: string;
  lng: string;
}

export function isGeoPoint(geoJsonDto: GeoJson): boolean {
  return geoJsonDto?.type === "Point";
}

export function convertGeoPointToLatLng(geoJsonDto: GeoJson): LatLng {
  if (geoJsonDto?.type !== "Point")
    throw new Error("Only points can be converted to lat/lng objects, got this instead: " + JSON.stringify(geoJsonDto));
  return { lng: geoJsonDto.coordinates[0], lat: geoJsonDto.coordinates[1] };
}

export function convertLatLngToGeoPoint<T = GeoJson>(latLng: LatLng): T {
  return { type: "Point", coordinates: [latLng.lng, latLng.lat] } as T;
}

export function getGoogleMapsNavigationUrl(address: AddressLike): string {
  return encodeURI(
    "https://www.google.com/maps/dir/?api=1&destination=" +
      address.street?.replace(/ /g, "+") +
      "+" +
      address.streetNumber?.replace(/ /g, "+") +
      "+" +
      address.zip?.replace(/ /g, "+") +
      "+" +
      address.city?.replace(/ /g, "+")
  );
}

export function convertAddressComponentsToAddress(addressComponents: GeocoderAddressComponentLike[]): AddressLike {
  const address: AddressLike = {
    street: "",
    streetNumber: "",
    country: "",
    zip: "",
    city: ""
  };

  for (const component of addressComponents) {
    if (component.types.includes("street_number")) address.streetNumber = component.long_name;
    if (component.types.includes("route")) address.street = component.long_name;
    if (component.types.includes("country")) address.country = component.long_name;
    if (component.types.includes("postal_code")) address.zip = component.long_name;
    if (component.types.includes("locality")) address.city = component.long_name;
  }

  return address;
}
