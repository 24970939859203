<div class="flex flex-col space-y-3">
  @if (todo()) {
    @if (todo().billingPositions) {
      <div class="text-base font-bold">Auftraggeber</div>
      @for (billingPosition of todo().billingPositions; track billingPosition) {
        @for (billingParty of billingPosition.billingParties; track billingParty) {
          <smallstack-type-model [modelId]="billingParty.linkedId" [typePath]="billingParty.linkedType">
            <ng-template let-model="model" let-long="long" let-short="short">
              <div class="flex flex-row justify-between items-center">
                <div class="flex flex-col">
                  <span class="short">{{ short }}</span
                  ><span class="text-xs">{{ long }}</span>
                </div>
                @if (model?.address) {
                  <button class="btn btn-square btn-primary" (click)="openGoogleMaps(model.address)">
                    <smallstack-icon name="google-maps-new" size="24"></smallstack-icon>
                  </button>
                }
              </div>
            </ng-template>
          </smallstack-type-model>
        }
      }
    }
    @if (todo().dueDate) {
      <div class="text-base font-bold">Geplante Zeit</div>
      <span class="text-xs"><smallstack-date [timestamp]="todo().dueDate"></smallstack-date></span>
    }
    @if (isRoot) {
      <div class="text-base font-bold">Aufgaben</div>
    }
    @if (isRoot) {
      <div class="text-xs font-light">Schließen Sie alle Unteraufgaben ab, um diese Aufgabe abzuschließen.</div>
    }
    <div class="flex flex-row space-x-1 justify-between items-center h-full w-full min-h-full min-w-full">
      <div class="flex flex-row space-x-1 items-start">
        <div class="basis-8">
          @if (todo().done !== true) {
            <smallstack-icon
              size="32"
              name="unchecked-checkbox--v1"
              [loadingFn]="toggleTodo()"
              data-testid="unchecked-todo-entry"
            >
            </smallstack-icon>
          }
          @if (todo().done === true) {
            <smallstack-icon
              size="32"
              name="checked-checkbox"
              [loadingFn]="toggleTodo()"
              data-testid="checked-todo-entry"
            >
            </smallstack-icon>
          }
        </div>
        <div class="flex flex-col space-y-1">
          @if (!currentlyEditing) {
            <smallstack-i18n
              [ngStyle]="{
                'text-decoration': todo().done ? 'line-through' : '',
                cursor: currentlyEditing ? 'pointer' : 'unset',
                paddingTop: '6px'
              }"
              [data]="todo().label || 'Kein Text'"
              (click)="editable ? (currentlyEditing = true) : undefined"
              [loadingFn]="toggleTodo()"
            ></smallstack-i18n>
          }
          @if (currentlyEditing) {
            <div style="width: 100%" class="flex flex-col space-y-2 flex-auto">
              <smallstack-inline-translation-editor [(translation)]="todo().label" class="flex-auto">
              </smallstack-inline-translation-editor>
            </div>
          }
          <div class="text-xs font-light"><smallstack-i18n [data]="todo().description"></smallstack-i18n></div>
        </div>
      </div>
      @if (isRoot) {
        @if (hasStartedWorklog()) {
          <button [disabled]="todo().done" class="btn btn-square btn-primary" [loadingFn]="createOrStopWorklog()"
            ><smallstack-icon size="24" name="fa-stop"></smallstack-icon>
          </button>
        } @else {
          @if (!todo().done) {
            <button class="btn btn-square btn-primary" [loadingFn]="createOrStopWorklog()">
              <smallstack-icon size="24" name="fa-play"></smallstack-icon>
            </button>
          }
        }
      }
    </div>
    <div style="padding-left: 36px">
      @if (showChecklistEntries) {
        @for (checklistEntry of todo().checklistEntries; track checklistEntry; let index = $index) {
          <div>
            <smallstack-todo-list-entry
              [isRoot]="false"
              [todo]="checklistEntry"
              (updateFn)="saveSubEntry(index, $event)"
            ></smallstack-todo-list-entry>
          </div>
        }
      }
    </div>
  } @else {
    <div class="flex flex-col gap-4 w-full py-4">
      <div class="skeleton h-6 w-5/6"></div>
      <div class="skeleton h-6 w-full"></div>
      <div class="skeleton h-6 w-full"></div>
    </div>
  }
</div>
