import { ContentChild, Directive, inject, input, Input, TemplateRef } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { BehaviorSubject, Subscription } from "rxjs";
import { FormService } from "../../services/form.service";

@Directive()
export class AbstractSchemaFormTable {
  @ContentChild("actions")
  public actions: TemplateRef<any>;

  @Input()
  public showMissingFields: boolean = false;

  @Input()
  public showActions: boolean = false;

  @Input()
  public showHeader: boolean = false;

  @Input()
  public showReset: boolean = false;

  /** if you don't want to render the root of formService.schema, then you can define a path here */
  protected schemaPath$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  @Input()
  public set path(schemaPath: string) {
    this.schemaPath$.next(schemaPath);
  }

  protected manualCompactMode = input<boolean>(undefined, { alias: "compactMode" });
  protected manualCompactMode$ = toObservable(this.manualCompactMode);

  @Input()
  public showHint: boolean;

  protected subscription: Subscription = new Subscription();

  protected formService: FormService = inject(FormService);
}
