import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FileDto } from "@smallstack/axios-api-client";
import { IconComponent } from "@smallstack/theme-components";
import { FileSizePipe } from "../../pipes/file-size.pipe";
import {
  FilePreviewDialogComponent,
  FilePreviewDialogData
} from "../file-preview-dialog/file-preview-dialog.component";
import { getType } from "../preview.utils";

@Component({
  selector: "smallstack-file-preview",
  templateUrl: "./file-preview.component.html",
  styleUrls: ["./file-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, MatFormFieldModule, FileSizePipe]
})
export class FilePreviewComponent {
  public type: string;
  public readonly altText: string = "Preview";
  private input: FileDto;
  private retries: number = 0;
  private readonly RETRY_WAIT: number = 1000; // 1 second
  private readonly MAX_RETRIES: number = 600; // 10 minutes

  @Input()
  public showSize = true;

  @Input()
  public showName = true;

  @Input()
  public set file(f: FileDto) {
    if (f) {
      this.type = getType(f);
      this.input = f;
    }
  }

  public get file(): FileDto {
    return this.input;
  }

  constructor(private matDialog: MatDialog) {}

  public complete(event: any): void {
    (event.target as HTMLImageElement).alt = this.altText;
  }

  public async retry(event: any, file: FileDto): Promise<void> {
    const target = event.target as HTMLImageElement;
    if (this.retries < this.MAX_RETRIES) {
      target.alt = "";
      await new Promise((resolve) => setTimeout(resolve, this.RETRY_WAIT));
      this.retries++;
      target.src = file?.url;
    } else {
      target.alt = this.altText;
    }
  }

  public openFile(file: FileDto): void {
    this.matDialog.open(FilePreviewDialogComponent, {
      data: { file } as FilePreviewDialogData,
      maxHeight: "95vh",
      minWidth: "75vw",
      maxWidth: "95vw",
      autoFocus: false
    });
  }
}
