import { Injectable, computed, inject } from "@angular/core";
import { TodoDto, WorklogDto } from "@smallstack/axios-api-client";
import { ObjectReference, TYPE_USERS, TYPE_WORKLOGS } from "@smallstack/typesystem";
import { RxEntityStoreService, TypeService } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { MangoQuery } from "rxdb";

@Injectable({ providedIn: "root" })
export class MyWorklogStore {
  private userService = inject(UserService);
  private rxEntityStoreService = inject(RxEntityStoreService);
  private typeService = inject(TypeService);

  public myWorklogs = computed(() =>
    this.getStore()
      .getMany({ ownerId: this.userService.currentUserId() })
      ?.sort((a, b) => b.startTime - a.startTime)
  );

  public async stopWorklog(id: string): Promise<void> {
    const store = this.getStore();
    const worklog = await store.getById$(id);
    if (worklog) {
      worklog.endTime = Date.now();
      await store.updateEntity(worklog);
    } else throw new Error(`Worklog with id ${id} not found.`);
  }

  public updateWorklog(worklog: WorklogDto): Promise<WorklogDto> {
    const store = this.getStore();
    return store.updateEntity(worklog);
  }

  public patchWorklog(id: string, changes: Partial<WorklogDto>): Promise<WorklogDto> {
    const store = this.getStore();
    return store.patchEntity(id, changes, { direct: true });
  }

  public createWorklog(worklog: WorklogDto): Promise<WorklogDto> {
    const store = this.getStore();
    return store.createEntity(worklog);
  }

  public deleteWorklog(id: string): Promise<void> {
    const store = this.getStore();
    return store.deleteEntity(id);
  }

  public queryWorklogs(query: MangoQuery): WorklogDto[] {
    return this.getStore().query(query);
  }

  public async stopWorklogsForTodo(id: string): Promise<void> {
    const applicableWorklogs = this.myWorklogs().filter((worklog) => worklog.todoRefId === id);
    if (Array.isArray(applicableWorklogs) && applicableWorklogs.length > 0)
      for (const worklog of applicableWorklogs) {
        await this.stopWorklog(worklog.id);
      }
  }

  public async stopAllWorklogs(): Promise<void> {
    const worklogs = this.myWorklogs();
    if (Array.isArray(worklogs) && worklogs.length > 0)
      for (const worklog of worklogs) {
        if (worklog.endTime === undefined) await this.stopWorklog(worklog.id);
      }
  }

  public async createWorklogFromTodo(todo: TodoDto): Promise<WorklogDto> {
    const links: ObjectReference[] = [];
    if (todo.billingPositions) {
      for (const billingPosition of todo.billingPositions) {
        if (billingPosition.billingParties) {
          for (const billingParty of billingPosition.billingParties) {
            links.push({
              id: billingParty.linkedId,
              type: billingParty.linkedType,
              updater: "query",
              cachedLabel: this.typeService.getRepresentation(
                { typePath: billingParty.linkedType },
                await this.rxEntityStoreService
                  .getStore({ typeDescriptor: { typePath: billingParty.linkedType } })
                  .getById$(billingParty.linkedId)
              )
            });
          }
        }
      }
    }

    return {
      label: todo.label,
      owner: {
        cachedLabel: [{ value: this.userService.currentUser().user.displayName }],
        updater: "query",
        id: this.userService.currentUser().user.id,
        type: TYPE_USERS
      },
      links,
      startTime: Date.now(),
      todoRefId: todo.id
    } as WorklogDto;
  }

  private getStore() {
    return this.rxEntityStoreService.forType<WorklogDto>(TYPE_WORKLOGS);
  }
}
