import { DataTypeStorageTarget } from "../models";
import { ICON_WORKFLOW } from "./icon-mappings";
import { ServicePermissions } from "./service-permissions";
import { SmallstackType, SmallstackTypeTag } from "./type";
import {
  TYPE_APPLICATIONS,
  TYPE_AUDIT_LOGS,
  TYPE_CALENDARS,
  TYPE_CALENDAR_ENTRIES,
  TYPE_CHECKIN_APPS,
  TYPE_CHECKIN_EVENTS,
  TYPE_CHECKIN_PRINTER_CONFIGURATIONS,
  TYPE_CHECKIN_VISITORS,
  TYPE_CMS_PAGES,
  TYPE_COMPOUND_WIDGETS,
  TYPE_CONFIGURATIONS,
  TYPE_CONTACTS,
  TYPE_CONTACTS_CUSTOM_FIELDS,
  TYPE_CORPORATIONS,
  TYPE_COUNTERS,
  TYPE_COUNTRIES,
  TYPE_CRONJOBS,
  TYPE_CUSTOMERS,
  TYPE_CUSTOM_BACKOFFICES,
  TYPE_CUSTOM_FIELDS,
  TYPE_DASHBOARDS,
  TYPE_DATA_TEMPLATES,
  TYPE_DEVICES,
  TYPE_EMAILS,
  TYPE_EMAIL_TEMPLATES,
  TYPE_EXTENSION_SLOTS,
  TYPE_FILES,
  TYPE_FORMS,
  TYPE_ICONS,
  TYPE_INVOICES,
  TYPE_LANGUAGES,
  TYPE_LOCALES,
  TYPE_MY_WORKLOGS,
  TYPE_NOTES,
  TYPE_OPT_INS,
  TYPE_ORDERS,
  TYPE_PDF_TEMPLATES,
  TYPE_PRICE_ZONES,
  TYPE_PRODUCTS,
  TYPE_PROJECTS,
  TYPE_REPLACEMENTS,
  TYPE_ROLES,
  TYPE_SERVICE_TOKENS,
  TYPE_SHOPPING_CARTS,
  TYPE_SUBSCRIPTIONS,
  TYPE_SUPPLIERS,
  TYPE_TASKS,
  TYPE_TODOS,
  TYPE_TODO_SURCHARGES,
  TYPE_TODO_TEMPLATES,
  TYPE_TRANSLATIONS,
  TYPE_TYPES,
  TYPE_USERS,
  TYPE_WORKFLOWS,
  TYPE_WORKLOGS
} from "./type-names";

export const LegacyUserType: SmallstackType = {
  title: [
    { value: "Benutzer", locale: "de_de" },
    { value: "User", locale: "en_us" }
  ],
  titlePlural: [
    { value: "Benutzer", locale: "de_de" },
    { value: "Users", locale: "en_us" }
  ],
  icon: "contact-card",
  path: TYPE_USERS,
  permissions: {
    create: ServicePermissions.USERS_WRITE,
    delete: ServicePermissions.USERS_WRITE,
    read: ServicePermissions.ANONYMOUS, // will be determined by the requested tenant and the resulting permissions
    readAll: ServicePermissions.ANONYMOUS, // will be determined by the requested tenant and the resulting permissions
    update: ServicePermissions.USERS_WRITE
  },
  searchableFields: [
    { name: "profile.firstName", weight: 10 },
    { name: "profile.lastName", weight: 10 },
    { name: "emails.address", weight: 4 }
  ],
  toStringRepresentation: "${displayName}",
  toLongRepresentation:
    "Anzeigename: ${displayName}, Vorname: ${profile.firstName}, Nachname: ${profile.lastName}, E-Mail: ${primaryEmailAddress}",
  url: "${basePath}/users?detailsId=${id}",
  tags: []
};

export const LegacyFriendType: SmallstackType = {
  icon: "user-group-man-woman--v3",
  path: "friends",
  searchableFields: [
    { name: "id", weight: 10 },
    { name: "displayName", weight: 10 },
    { name: "status", weight: 5 },
    { name: "email", weight: 8 }
  ],
  toStringRepresentation: "${displayName}",
  toLongRepresentation: "Anzeigename: ${displayName}, E-Mail: ${email}, Freundschaftsstatus: ${status}",
  url: "${basePath}/friends?detailsId=${id}",
  tags: [],
  storage: {
    pull: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: "/friends"
      }
    }
  }
};

export const ResellerType: SmallstackType = {
  icon: "reseller",
  path: "resellers",
  searchableFields: [
    { name: "id", weight: 10 },
    { name: "name", weight: 10 },
    { name: "title", weight: 10 },
    { name: "domains", weight: 5 }
  ],
  tags: [],
  toStringRepresentation: "${title}",
  permissions: {
    create: ServicePermissions.RESELLER_WRITE,
    delete: ServicePermissions.RESELLER_WRITE,
    update: ServicePermissions.RESELLER_WRITE,
    read: ServicePermissions.RESELLER_READ,
    readAll: ServicePermissions.RESELLER_READ
  }
};

export const UserGroupType: SmallstackType = {
  icon: "groups",
  path: "user-groups",
  searchableFields: [{ name: "name.value", weight: 10 }],
  tags: [],
  toStringRepresentation: "${name.value}",
  permissions: {
    create: ServicePermissions.USER_GROUPS_WRITE,
    delete: ServicePermissions.USER_GROUPS_WRITE,
    update: ServicePermissions.USER_GROUPS_WRITE,
    read: ServicePermissions.USER_GROUPS_READ,
    readAll: ServicePermissions.USER_GROUPS_READ
  }
};

export const CustomerType: SmallstackType = {
  ...LegacyUserType,
  title: [
    { value: "Kunde", locale: "de_de" },
    { value: "Customer", locale: "en_us" }
  ],
  path: TYPE_CUSTOMERS
};

export const ReplacementType: SmallstackType = {
  icon: "swap--v1",
  path: TYPE_REPLACEMENTS,
  toStringRepresentation: "${label}",
  searchableFields: [
    {
      name: "label",
      weight: 10
    },
    {
      name: "start",
      weight: 4
    },
    {
      name: "end",
      weight: 4
    }
  ],
  tags: []
};

export const ServiceTokenType: SmallstackType = {
  icon: "key",
  path: TYPE_SERVICE_TOKENS,
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.TENANT_ADMIN,
    readAll: ServicePermissions.TENANT_ADMIN,
    update: ServicePermissions.TENANT_ADMIN
  },
  searchableFields: [{ name: "label", weight: 10 }],
  toStringRepresentation: "${label}",
  tags: []
};

export const ProjectType: SmallstackType = {
  icon: "web-design",
  path: TYPE_PROJECTS,
  permissions: {
    create: ServicePermissions.TENANT_READ,
    delete: ServicePermissions.TENANT_READ,
    read: ServicePermissions.TENANT_READ,
    readAll: ServicePermissions.TENANT_READ,
    update: ServicePermissions.TENANT_READ
  },
  searchableFields: [
    { name: "name", weight: 10 },
    { name: "path", weight: 6 }
  ],
  toStringRepresentation: "${name}",
  tags: []
};

export const DataTemplateType: SmallstackType = {
  icon: "database-restore",
  path: TYPE_DATA_TEMPLATES,
  permissions: {
    create: ServicePermissions.DATA_TEMPLATES_WRITE,
    delete: ServicePermissions.DATA_TEMPLATES_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.DATA_TEMPLATES_WRITE
  },
  searchableFields: [{ name: "label", weight: 10 }],
  toStringRepresentation: "${label}",
  tags: []
};

export const SubscriptionType: SmallstackType = {
  icon: "renew-subscription",
  path: TYPE_SUBSCRIPTIONS,
  permissions: {
    create: ServicePermissions.SUBSCRIPTIONS_WRITE,
    delete: ServicePermissions.SUBSCRIPTIONS_WRITE,
    update: ServicePermissions.SUBSCRIPTIONS_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS
  },
  searchableFields: [
    { name: "name", weight: 10 },
    { name: "description", weight: 8 }
  ],
  toStringRepresentation: "${name}",
  tags: []
};

export const CalendarType: SmallstackType = {
  icon: "calendar--v1",
  path: TYPE_CALENDARS,
  permissions: {
    create: ServicePermissions.CALENDAR_WRITE,
    delete: ServicePermissions.CALENDAR_WRITE,
    read: ServicePermissions.CALENDAR_READ,
    readAll: ServicePermissions.CALENDAR_READ,
    update: ServicePermissions.CALENDAR_WRITE
  },
  searchableFields: [
    {
      name: "name",
      weight: 10
    }
  ],
  toStringRepresentation: "${name}",
  tags: []
};

export const CorporationType: SmallstackType = {
  title: [{ value: "Firma" }],
  titlePlural: [{ value: "Firmen" }],
  icon: "fa-buildings",
  path: TYPE_CORPORATIONS,
  tags: [SmallstackTypeTag.COMMON],
  toStringRepresentation: "${name}",
  permissions: {
    create: ServicePermissions.CORPORATIONS_WRITE,
    delete: ServicePermissions.CORPORATIONS_WRITE,
    read: ServicePermissions.CORPORATIONS_READ,
    readAll: ServicePermissions.CORPORATIONS_READ,
    update: ServicePermissions.CORPORATIONS_WRITE
  },
  searchableFields: [
    { name: "name", weight: 10 },
    { name: "description", weight: 8 },
    { name: "industry", weight: 8 },
    { name: "website", weight: 4 },
    { name: "address.street", weight: 8 },
    { name: "address.streetNumber", weight: 8 },
    { name: "address.zip", weight: 8 },
    { name: "address.city", weight: 8 },
    { name: "address.county", weight: 4 },
    { name: "address.country", weight: 8 },
    { name: "email", weight: 6 },
    { name: "phone", weight: 6 },
    { name: "customFields", weight: 2 }
  ],
  url: "${basePath}/custom/property-managements?simpleSearch=${id}"
};

export const CustomFieldSmallstackType: SmallstackType = {
  icon: "support",
  path: TYPE_CUSTOM_FIELDS,
  permissions: {
    create: ServicePermissions.CUSTOM_FIELD_WRITE,
    read: ServicePermissions.CUSTOM_FIELD_READ,
    readAll: ServicePermissions.CUSTOM_FIELD_READ,
    update: ServicePermissions.CUSTOM_FIELD_WRITE,
    delete: ServicePermissions.CUSTOM_FIELD_WRITE
  },
  searchableFields: [{ name: "title", weight: 10 }],
  tags: [],
  toStringRepresentation: "${title}",
  url: "${basePath}/custom/custom-fields"
};

export const ExtensionSlotType: SmallstackType = {
  icon: "puzzle",
  path: TYPE_EXTENSION_SLOTS,
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    update: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.TENANT_READ,
    readAll: ServicePermissions.TENANT_READ
  },
  tags: [],
  toStringRepresentation: "${name}",
  searchableFields: [{ name: "name", weight: 10 }]
};

export const CalendarEntryType: SmallstackType = {
  icon: "overtime",
  path: TYPE_CALENDAR_ENTRIES,
  permissions: {
    create: ServicePermissions.CALENDAR_WRITE,
    delete: ServicePermissions.CALENDAR_WRITE,
    read: ServicePermissions.CALENDAR_READ,
    readAll: ServicePermissions.CALENDAR_READ,
    update: ServicePermissions.CALENDAR_WRITE
  },
  searchableFields: [
    {
      name: "name",
      weight: 10
    }
  ],
  toStringRepresentation: "${name}",
  tags: []
};
export const ContactType: SmallstackType = {
  title: [
    { value: "Kontakt", locale: "de_de" },
    { value: "Contact", locale: "en_us" }
  ],
  titlePlural: [
    { value: "Kontakte", locale: "de_de" },
    { value: "Contacts", locale: "en_us" }
  ],
  icon: "contact-card",
  path: TYPE_CONTACTS,
  permissions: {
    create: ServicePermissions.CONTACTS_WRITE,
    delete: ServicePermissions.CONTACTS_WRITE,
    read: ServicePermissions.CONTACTS_READ,
    readAll: ServicePermissions.CONTACTS_READ,
    update: ServicePermissions.CONTACTS_WRITE
  },
  searchableFields: [
    { name: "firstName", weight: 10 },
    { name: "lastName", weight: 10 },
    { name: "birthday", weight: 2 },
    { name: "address.street", weight: 2 },
    { name: "address.streetNumber", weight: 2 },
    { name: "address.country", weight: 2 },
    { name: "address.state", weight: 2 },
    { name: "address.zip", weight: 2 },
    { name: "address.city", weight: 2 },
    { name: "email", weight: 9 },
    { name: "phone", weight: 2 },
    { name: "mobile", weight: 2 },
    { name: "company", weight: 8 },
    { name: "companyPosition", weight: 2 },
    { name: "companyPhone", weight: 2 },
    { name: "customFields", weight: 2 }
  ],
  toStringRepresentation: "${firstName}#{if lastName} ${lastName}#{/if}#{if company} ${company}#{/if}",
  url: "${basePath}/contacts/list?openDetailsId=${id}",
  toLongRepresentation:
    "#{if firstName}Vorname: ${firstName} #{/if}#{if lastName}Nachname: ${lastName} #{/if}#{if email}E-Mail: ${email} #{/if}#{if phone}Telefon: ${phone}#{/if}#{if company}Firma: ${company} #{/if}#{if companyPosition}Position: ${companyPosition} #{/if}#{if companyPhone}Firmen-Telefon: ${companyPhone}#{/if}",
  tags: [SmallstackTypeTag.COMMON],
  linkableTypes: [TYPE_CALENDAR_ENTRIES, TYPE_TODO_TEMPLATES]
};

export const ContactCustomFieldType: SmallstackType = {
  icon: "support",
  path: TYPE_CONTACTS_CUSTOM_FIELDS,
  permissions: {
    create: ServicePermissions.CONTACTS_ADMIN,
    read: ServicePermissions.CONTACTS_READ,
    readAll: ServicePermissions.CONTACTS_READ,
    update: ServicePermissions.CONTACTS_ADMIN,
    delete: ServicePermissions.CONTACTS_ADMIN
  },
  tags: [],
  toStringRepresentation: "${title}",
  searchableFields: [{ name: "title", weight: 10 }]
};

export const RoleType: SmallstackType = {
  icon: "conference-call",
  path: TYPE_ROLES,
  tags: [],
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.TENANT_READ,
    readAll: ServicePermissions.TENANT_READ,
    update: ServicePermissions.TENANT_ADMIN
  },
  toStringRepresentation: "${name}",
  searchableFields: [
    { name: "name", weight: 10 },
    { name: "description", weight: 8 }
  ]
};

export const TodoType: SmallstackType = {
  icon: "todo-list--v1",
  path: TYPE_TODOS,
  permissions: {
    create: ServicePermissions.TODO_WRITE,
    delete: ServicePermissions.TODO_WRITE,
    read: ServicePermissions.TODO_READ,
    readAll: ServicePermissions.TODO_READ,
    update: ServicePermissions.TODO_WRITE
  },
  ownerPermissions: {
    create: ServicePermissions.TODO_OWNER_WRITE,
    delete: ServicePermissions.TODO_OWNER_WRITE,
    read: ServicePermissions.TODO_OWNER_READ,
    readAll: ServicePermissions.TODO_OWNER_READ,
    update: ServicePermissions.TODO_OWNER_WRITE
  },
  ownershipEnabled: true,
  ownershipField: { name: "ownerIds", isArray: true },
  searchableFields: [
    { name: "label", weight: 10 },
    { name: "description", weight: 8 }
  ],
  toStringRepresentation: "${label.0.value}",
  toLongRepresentation: "${description.0.value}",
  url: "${basePath}/custom/todos?simpleSearch=${id}",
  tags: [SmallstackTypeTag.COMMON],
  linkableTypes: [TYPE_CALENDAR_ENTRIES, TYPE_CONTACTS, TYPE_CUSTOMERS, TYPE_USERS, TYPE_CORPORATIONS]
  // storage: {
  //   pull: {
  //     target: DataTypeStorageTarget.RestEndpoint,
  //     options: {
  //       url: "/todos"
  //     }
  //   },
  //   push: {
  //     target: DataTypeStorageTarget.RestEndpoint,
  //     options: {
  //       url: "/todos"
  //     }
  //   }
  // }
};

export const TodoTemplateType: SmallstackType = {
  icon: "todo-list--v1",
  path: TYPE_TODO_TEMPLATES,
  permissions: {
    create: ServicePermissions.TODO_TEMPLATE_WRITE,
    delete: ServicePermissions.TODO_TEMPLATE_WRITE,
    read: ServicePermissions.TODO_TEMPLATE_READ,
    readAll: ServicePermissions.TODO_TEMPLATE_READ,
    update: ServicePermissions.TODO_TEMPLATE_WRITE
  },
  searchableFields: [
    { name: "label", weight: 10 },
    { name: "description", weight: 8 }
  ],
  toStringRepresentation: "${label.0.value}",
  toLongRepresentation: "${description.0.value}",
  url: "${basePath}/custom/todo-templates?simpleSearch=${id}",
  tags: []
};

export const TodoSurchargeType: SmallstackType = {
  icon: "initiate-money-transfer",
  path: TYPE_TODO_SURCHARGES,
  permissions: {
    create: ServicePermissions.TODO_SURCHARGES_WRITE,
    delete: ServicePermissions.TODO_SURCHARGES_WRITE,
    read: ServicePermissions.TODO_SURCHARGES_READ,
    readAll: ServicePermissions.TODO_SURCHARGES_READ,
    update: ServicePermissions.TODO_SURCHARGES_WRITE
  },
  searchableFields: [
    { name: "title", weight: 10 },
    { name: "surchargePercentage", weight: 8 },
    { name: "type", weight: 8 },
    { name: "dateRangeSurchargeStartTime", weight: 8 },
    { name: "dateRangeSurchargeEndTime", weight: 8 },
    { name: "bankHolidaySurchargeCounty", weight: 8 }
  ],
  toStringRepresentation: "${title}",
  url: "${basePath}/custom/todo-surcharges?simpleSearch=${id}",
  tags: []
};

export const FileType: SmallstackType = {
  icon: "opened-folder",
  path: TYPE_FILES,
  permissions: {
    create: ServicePermissions.FILES_WRITE,
    delete: ServicePermissions.FILES_WRITE,
    read: ServicePermissions.FILES_READ,
    readAll: ServicePermissions.FILES_READ,
    update: ServicePermissions.FILES_WRITE
  },
  searchableFields: [{ name: "name", weight: 10 }],
  toStringRepresentation: "${name}",
  url: "${basePath}/files",
  tags: [SmallstackTypeTag.COMMON]
};

export const WorkflowType: SmallstackType = {
  icon: ICON_WORKFLOW,
  path: TYPE_WORKFLOWS,
  permissions: {
    create: ServicePermissions.WORKFLOW_WRITE,
    delete: ServicePermissions.WORKFLOW_WRITE,
    read: ServicePermissions.WORKFLOW_READ,
    readAll: ServicePermissions.WORKFLOW_READ,
    update: ServicePermissions.WORKFLOW_WRITE
  },
  searchableFields: [
    { name: "label", weight: 10 },
    { name: "name", weight: 2 },
    { name: "description", weight: 10 }
  ],
  toStringRepresentation: "${label}",
  url: "${basePath}/workflow/${id}",
  tags: []
};

export const ProductType: SmallstackType = {
  icon: "new-product",
  path: TYPE_PRODUCTS,
  permissions: {
    create: ServicePermissions.PRODUCTS_WRITE,
    delete: ServicePermissions.PRODUCTS_WRITE,
    read: ServicePermissions.PRODUCTS_READ,
    readAll: ServicePermissions.PRODUCTS_READ,
    update: ServicePermissions.PRODUCTS_WRITE
  },
  searchableFields: [
    { name: "title", weight: 10 },
    { name: "shortTitle", weight: 9 },
    { name: "description", weight: 10 },
    { name: "shortDescription", weight: 9 }
  ],
  toStringRepresentation: "${title}",
  url: "${basePath}/products/${id}",
  tags: []
};

export const IconType: SmallstackType = {
  icon: "image",
  path: TYPE_ICONS,
  searchableFields: [
    { name: "name", weight: 10 },
    { name: "theme", weight: 4 }
  ],
  tags: [],
  toStringRepresentation: "${name}",
  toLongRepresentation: "${name} (Theme: ${theme})",
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.TENANT_ADMIN
  }
};

export const CmsPageType: SmallstackType = {
  icon: "template",
  path: TYPE_CMS_PAGES,
  permissions: {
    create: ServicePermissions.CMS_WRITE,
    delete: ServicePermissions.CMS_WRITE,
    read: ServicePermissions.CMS_READ,
    readAll: ServicePermissions.CMS_READ,
    update: ServicePermissions.CMS_WRITE
  },
  searchableFields: [
    { name: "name", weight: 4 },
    { name: "title", weight: 10 },
    { name: "tags", weight: 9 }
  ],
  toStringRepresentation: "${title}",
  url: "${basePath}/products/${id}",
  tags: []
};

export const ApplicationType: SmallstackType = {
  icon: "module",
  path: TYPE_APPLICATIONS,
  permissions: {
    create: ServicePermissions.APPLICATIONS_WRITE,
    delete: ServicePermissions.APPLICATIONS_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.APPLICATIONS_WRITE
  },
  searchableFields: [{ name: "title", weight: 10 }],
  toStringRepresentation: "${title}",
  url: "${basePath}/applications/${id}",
  tags: []
};

export const OptinType: SmallstackType = {
  icon: "keyhole-shield",
  path: TYPE_OPT_INS,
  permissions: {
    create: ServicePermissions.OPT_IN_WRITE,
    delete: ServicePermissions.OPT_IN_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.OPT_IN_WRITE
  },
  searchableFields: [
    { name: "title", weight: 10 },
    { name: "text", weight: 10 }
  ],
  toStringRepresentation: "${title}",
  url: "${basePath}/optin",
  tags: []
};

export const AuditLogType: SmallstackType = {
  icon: "overview-pages-4",
  path: TYPE_AUDIT_LOGS,
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.AUDIT_READ,
    readAll: ServicePermissions.AUDIT_READ,
    update: ServicePermissions.TENANT_ADMIN
  },
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const ConfigurationType: SmallstackType = {
  icon: "gear",
  path: TYPE_CONFIGURATIONS,
  permissions: {
    create: ServicePermissions.CONFIGURATION_WRITE,
    delete: ServicePermissions.CONFIGURATION_WRITE,
    read: ServicePermissions.CONFIGURATION_READ,
    readAll: ServicePermissions.CONFIGURATION_READ,
    update: ServicePermissions.CONFIGURATION_WRITE
  },
  searchableFields: [
    { name: "key", weight: 10 },
    { name: "value", weight: 10 }
  ],
  toStringRepresentation: "${key}",
  url: "${basePath}/configurations",
  tags: []
};

export const DeviceType: SmallstackType = {
  icon: "multiple-devices",
  path: TYPE_DEVICES,
  permissions: {
    create: ServicePermissions.DEVICES_WRITE,
    delete: ServicePermissions.DEVICES_WRITE,
    read: ServicePermissions.DEVICES_READ,
    readAll: ServicePermissions.DEVICES_READ,
    update: ServicePermissions.DEVICES_WRITE
  },
  searchableFields: [{ name: "name", weight: 10 }],
  toStringRepresentation: "${name}",
  url: "${basePath}/devices",
  tags: []
};

export const CustomBackofficeType: SmallstackType = {
  icon: "web",
  path: TYPE_CUSTOM_BACKOFFICES,
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.TENANT_ADMIN
  },
  searchableFields: [{ name: "title", weight: 10 }],
  toStringRepresentation: "${title}",
  tags: [SmallstackTypeTag.ADMIN]
};

export const SupplierType: SmallstackType = {
  icon: "supplier",
  path: TYPE_SUPPLIERS,
  permissions: {
    create: ServicePermissions.SUPPLIERS_WRITE,
    delete: ServicePermissions.SUPPLIERS_WRITE,
    read: ServicePermissions.SUPPLIERS_READ,
    readAll: ServicePermissions.SUPPLIERS_READ,
    update: ServicePermissions.SUPPLIERS_WRITE
  },
  searchableFields: [
    { name: "title", weight: 10 },
    { name: "code", weight: 1 }
  ],
  toStringRepresentation: "${title}",
  tags: []
};

export const ShoppingCartType: SmallstackType = {
  icon: "shopping-cart--v1",
  path: TYPE_SHOPPING_CARTS,
  permissions: {
    create: ServicePermissions.SHOPPING_CARTS_WRITE,
    delete: ServicePermissions.SHOPPING_CARTS_WRITE,
    read: ServicePermissions.SHOPPING_CARTS_READ,
    readAll: ServicePermissions.SHOPPING_CARTS_READ,
    update: ServicePermissions.SHOPPING_CARTS_WRITE
  },
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const PriceZoneType: SmallstackType = {
  icon: "price-tag--v1",
  path: TYPE_PRICE_ZONES,
  permissions: {
    create: ServicePermissions.PRODUCTS_WRITE,
    delete: ServicePermissions.PRODUCTS_WRITE,
    read: ServicePermissions.PRODUCTS_READ,
    readAll: ServicePermissions.PRODUCTS_READ,
    update: ServicePermissions.PRODUCTS_WRITE
  },
  tags: [],
  toStringRepresentation: "${title}",
  toLongRepresentation: "ISO: ${currency}",
  searchableFields: [
    { name: "currency", weight: 10 },
    { name: "title", weight: 10 }
  ]
};

export const OrderType: SmallstackType = {
  icon: "purchase-order",
  path: TYPE_ORDERS,
  permissions: {
    create: ServicePermissions.ORDERS_WRITE,
    delete: ServicePermissions.ORDERS_WRITE,
    read: ServicePermissions.ORDERS_READ,
    readAll: ServicePermissions.ORDERS_READ,
    update: ServicePermissions.ORDERS_WRITE
  },
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const TaskType: SmallstackType = {
  icon: "tasks--v1",
  path: TYPE_TASKS,
  permissions: {
    create: ServicePermissions.TASKS_WRITE,
    delete: ServicePermissions.TASKS_WRITE,
    read: ServicePermissions.TASKS_READ,
    readAll: ServicePermissions.TASKS_READ,
    update: ServicePermissions.TASKS_WRITE
  },
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const DashboardType: SmallstackType = {
  icon: "tasks--v1",
  path: TYPE_DASHBOARDS,
  tags: [],
  toStringRepresentation: "${title}",
  searchableFields: []
};

export const CronjobType: SmallstackType = {
  icon: "refresh--v1",
  path: TYPE_CRONJOBS,
  permissions: {
    create: ServicePermissions.CRONJOB_WRITE,
    delete: ServicePermissions.CRONJOB_WRITE,
    read: ServicePermissions.CRONJOB_READ,
    readAll: ServicePermissions.CRONJOB_READ,
    update: ServicePermissions.CRONJOB_WRITE
  },
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const LocaleType: SmallstackType = {
  icon: "europe",
  path: TYPE_LOCALES,
  permissions: {
    create: ServicePermissions.INTERNATIONALIZATION_WRITE,
    delete: ServicePermissions.INTERNATIONALIZATION_WRITE,
    read: ServicePermissions.INTERNATIONALIZATION_READ,
    readAll: ServicePermissions.INTERNATIONALIZATION_READ,
    update: ServicePermissions.INTERNATIONALIZATION_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${iso}",
  searchableFields: [{ name: "iso", weight: 10 }],
  storage: {
    pull: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: "/locales"
      }
    },
    push: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: "/locales"
      }
    }
  }
};

export const CountryType: SmallstackType = {
  icon: "europe",
  path: TYPE_COUNTRIES,
  permissions: {
    create: ServicePermissions.INTERNATIONALIZATION_WRITE,
    delete: ServicePermissions.INTERNATIONALIZATION_WRITE,
    read: ServicePermissions.INTERNATIONALIZATION_READ,
    readAll: ServicePermissions.INTERNATIONALIZATION_READ,
    update: ServicePermissions.INTERNATIONALIZATION_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${iso}",
  searchableFields: [{ name: "iso", weight: 10 }]
};

export const LanguageType: SmallstackType = {
  icon: "europe",
  path: TYPE_LANGUAGES,
  permissions: {
    create: ServicePermissions.INTERNATIONALIZATION_WRITE,
    delete: ServicePermissions.INTERNATIONALIZATION_WRITE,
    read: ServicePermissions.INTERNATIONALIZATION_READ,
    readAll: ServicePermissions.INTERNATIONALIZATION_READ,
    update: ServicePermissions.INTERNATIONALIZATION_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${iso}",
  searchableFields: [{ name: "iso", weight: 10 }]
};

export const TranslationType: SmallstackType = {
  icon: "europe",
  path: TYPE_TRANSLATIONS,
  permissions: {
    create: ServicePermissions.INTERNATIONALIZATION_WRITE,
    delete: ServicePermissions.INTERNATIONALIZATION_WRITE,
    read: ServicePermissions.INTERNATIONALIZATION_READ,
    readAll: ServicePermissions.INTERNATIONALIZATION_READ,
    update: ServicePermissions.INTERNATIONALIZATION_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${key}",
  searchableFields: [{ name: "key", weight: 10 }]
};

export const EmailType: SmallstackType = {
  icon: "secured-letter",
  path: TYPE_EMAILS,
  permissions: {
    create: ServicePermissions.EMAILS_WRITE,
    delete: ServicePermissions.EMAILS_WRITE,
    read: ServicePermissions.EMAILS_READ,
    readAll: ServicePermissions.EMAILS_READ,
    update: ServicePermissions.EMAILS_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${id}",
  searchableFields: [
    { name: "recipients", weight: 10 },
    { name: "subject", weight: 10 }
  ]
};

export const EmailTemplateType: SmallstackType = {
  icon: "secured-letter",
  path: TYPE_EMAIL_TEMPLATES,
  permissions: {
    create: ServicePermissions.EMAILTEMPLATES_WRITE,
    delete: ServicePermissions.EMAILTEMPLATES_WRITE,
    read: ServicePermissions.EMAILTEMPLATES_READ,
    readAll: ServicePermissions.EMAILTEMPLATES_READ,
    update: ServicePermissions.EMAILTEMPLATES_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const FormType: SmallstackType = {
  icon: "form",
  path: TYPE_FORMS,
  permissions: {
    create: ServicePermissions.FORMS_WRITE,
    delete: ServicePermissions.FORMS_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.FORMS_WRITE
  },
  tags: [SmallstackTypeTag.ADMIN],
  searchableFields: [
    {
      name: "title.value",
      weight: 10
    }
  ],
  toStringRepresentation: "${title}",
  url: "${basePath}/forms"
};

export const WorklogType: SmallstackType = {
  icon: "work",
  path: TYPE_WORKLOGS,
  permissions: {
    create: ServicePermissions.WORKLOG_WRITE,
    delete: ServicePermissions.WORKLOG_WRITE,
    read: ServicePermissions.WORKLOG_READ,
    readAll: ServicePermissions.WORKLOG_READ,
    update: ServicePermissions.WORKLOG_WRITE
  },
  tags: [SmallstackTypeTag.COMMON],
  searchableFields: [
    {
      name: "label",
      weight: 10
    }
  ],
  toStringRepresentation: "${label}"
};

export const MyWorklogType: SmallstackType = {
  ...WorklogType,
  path: TYPE_MY_WORKLOGS,
  permissions: {
    create: ServicePermissions.WORKLOG_OWNER_WRITE,
    delete: ServicePermissions.WORKLOG_OWNER_WRITE,
    read: ServicePermissions.WORKLOG_OWNER_READ,
    readAll: ServicePermissions.WORKLOG_OWNER_READ,
    update: ServicePermissions.WORKLOG_OWNER_WRITE
  }
};

export const CheckinVisitorType: SmallstackType = {
  icon: "queue",
  path: TYPE_CHECKIN_VISITORS,
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const CheckinVisitorEventType: SmallstackType = {
  icon: "planner",
  path: TYPE_CHECKIN_EVENTS,
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const CheckinAppType: SmallstackType = {
  icon: "app-symbol",
  path: TYPE_CHECKIN_APPS,
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const PrinterConfigurationType: SmallstackType = {
  icon: "print",
  path: TYPE_CHECKIN_PRINTER_CONFIGURATIONS,
  tags: [],
  toStringRepresentation: "${id}",
  searchableFields: []
};

export const PdfTemplateType: SmallstackType = {
  icon: "pdf",
  path: TYPE_PDF_TEMPLATES,
  permissions: {
    create: ServicePermissions.PDF_TEMPLATE_WRITE,
    delete: ServicePermissions.PDF_TEMPLATE_WRITE,
    read: ServicePermissions.PDF_TEMPLATE_READ,
    readAll: ServicePermissions.PDF_TEMPLATE_READ,
    update: ServicePermissions.PDF_TEMPLATE_WRITE
  },
  tags: [],
  toStringRepresentation: "${title}",
  toLongRepresentation: "",
  searchableFields: [{ name: "title", weight: 10 }]
};

export const NoteType: SmallstackType = {
  icon: "note",
  path: TYPE_NOTES,
  permissions: {
    create: ServicePermissions.TENANT_READ,
    delete: ServicePermissions.TENANT_READ,
    update: ServicePermissions.TENANT_READ,
    read: ServicePermissions.TENANT_READ,
    readAll: ServicePermissions.TENANT_READ
  },
  tags: [SmallstackTypeTag.COMMON],
  searchableFields: [
    {
      name: "content",
      weight: 10
    }
  ],
  toStringRepresentation: "${content}"
};

export const CompoundWidgetType: SmallstackType = {
  icon: "fa-cube",
  path: TYPE_COMPOUND_WIDGETS,
  permissions: {
    create: ServicePermissions.CMS_WRITE,
    delete: ServicePermissions.CMS_WRITE,
    update: ServicePermissions.CMS_WRITE,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS
  },
  tags: [],
  toStringRepresentation: "${name}",
  searchableFields: [{ name: "name", weight: 10 }]
};

export const CounterType: SmallstackType = {
  icon: "counter",
  path: TYPE_COUNTERS,
  tags: [],
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    update: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.TENANT_READ,
    readAll: ServicePermissions.TENANT_READ
  },
  toStringRepresentation: "${name}",
  searchableFields: [{ name: "name", weight: 10 }]
};

export const InvoiceType: SmallstackType = {
  icon: "bill",
  path: TYPE_INVOICES,
  tags: [],
  permissions: {
    create: ServicePermissions.INVOICE_WRITE,
    delete: ServicePermissions.INVOICE_WRITE,
    update: ServicePermissions.INVOICE_WRITE,
    read: ServicePermissions.INVOICE_READ,
    readAll: ServicePermissions.INVOICE_READ
  },
  searchableFields: [
    { name: "number", weight: 10 },
    { name: "address.street", weight: 8 },
    { name: "address.streetNumber", weight: 8 },
    { name: "address.country", weight: 8 },
    { name: "address.state", weight: 8 },
    { name: "address.county", weight: 8 },
    { name: "address.zip", weight: 8 },
    { name: "address.city", weight: 8 },
    { name: "dateOfInvoice", weight: 8 },
    { name: "positions.label", weight: 9 },
    { name: "positions.type", weight: 9 },
    { name: "positions.amount", weight: 9 },
    { name: "positions.price.value", weight: 9 }
  ],
  toStringRepresentation: "${number}",
  url: "${basePath}/custom/invoices?detailId=${id}"
};

export const TypeType: SmallstackType = {
  icon: "fa-database",
  path: TYPE_TYPES,
  searchableFields: [
    { name: "path", weight: 10 },
    { name: "title", weight: 10 }
  ],
  tags: [],
  toStringRepresentation: "${path}",
  permissions: {
    create: ServicePermissions.TENANT_ADMIN,
    delete: ServicePermissions.TENANT_ADMIN,
    read: ServicePermissions.ANONYMOUS,
    readAll: ServicePermissions.ANONYMOUS,
    update: ServicePermissions.TENANT_ADMIN
  }
};

/**
 * @deprecated Use the individual types from `@smallstack/typesystem`
 *
 *  A collection of all statically known smallstack types
 */
export const StaticTypes: SmallstackType[] = [
  ApplicationType,
  AuditLogType,
  CalendarEntryType,
  CalendarType,
  CheckinAppType,
  CheckinVisitorEventType,
  CheckinVisitorType,
  CmsPageType,
  CompoundWidgetType,
  ConfigurationType,
  ContactCustomFieldType,
  ContactType,
  CorporationType,
  CounterType,
  CountryType,
  CronjobType,
  CustomBackofficeType,
  CustomerType,
  CustomFieldSmallstackType,
  DashboardType,
  DataTemplateType,
  DeviceType,
  EmailTemplateType,
  EmailType,
  ExtensionSlotType,
  FileType,
  FormType,
  LegacyFriendType,
  InvoiceType,
  LanguageType,
  LocaleType,
  MyWorklogType,
  NoteType,
  OptinType,
  OrderType,
  PdfTemplateType,
  PriceZoneType,
  PrinterConfigurationType,
  ProductType,
  ProjectType,
  ResellerType,
  ReplacementType,
  RoleType,
  ServiceTokenType,
  ShoppingCartType,
  SubscriptionType,
  SupplierType,
  TaskType,
  TodoSurchargeType,
  TodoTemplateType,
  TodoType,
  TranslationType,
  TypeType,
  UserGroupType,
  LegacyUserType,
  WorkflowType,
  WorklogType
];
