import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, EmailApi, EmailTemplateDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class EmailTemplateStore extends PageableCrudStore<EmailTemplateDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<EmailTemplateDto>> {
    const res = await this.axiosApiClient.get(EmailApi).getEmailTemplates(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<EmailTemplateDto> {
    const res = await this.axiosApiClient.get(EmailApi).getEmailTemplate({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(EmailApi).deleteEmailTemplate({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(EmailApi).deleteManyEmailTemplates({ ids });
    return res.data;
  }
  protected async createModel(emailTemplate: EmailTemplateDto): Promise<EmailTemplateDto> {
    const res = await this.axiosApiClient.get(EmailApi).createEmailTemplate({ emailTemplate });
    return res.data;
  }
  protected async patchModel(id: string, emailTemplate: Partial<EmailTemplateDto>): Promise<EmailTemplateDto> {
    const res = await this.axiosApiClient.get(EmailApi).patchEmailTemplate({ id, emailTemplate });
    return res.data;
  }
  protected async putModel(emailTemplate: EmailTemplateDto): Promise<EmailTemplateDto> {
    const res = await this.axiosApiClient.get(EmailApi).putEmailTemplate({ id: emailTemplate.id, emailTemplate });
    return res.data;
  }
}
