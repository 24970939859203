import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, effect, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  AxiosApiClient,
  CounterDto,
  InvoiceDto,
  InvoicesApi,
  TodoSurchargeDto,
  WorklogDto
} from "@smallstack/axios-api-client";
import { LoadingElementDirective, ObjectArrayValuePipe, RouterUtilService } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { LoadingStateComponent, SkeletonComponent } from "@smallstack/rx-components";
import { TYPE_COUNTERS, TYPE_TODO_SURCHARGES, TYPE_WORKLOGS, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { LoadingState, injectStore } from "@smallstack/typesystem-client";
import { DEFAULT_GERMAN_HUMAN_DATE_FORMAT, DEFAULT_HTML5_DATE_FORMAT, getJsonByPath } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { computedAsync } from "ngxtension/computed-async";

interface BillingInvoiceContext {
  billingPeriod?: string;
  billingPeriodStart?: number;
  billingPeriodEnd?: number;
}

interface ViewState {
  loadingState: LoadingState;
  worklogCount?: number;
  worklogs?: WorklogDto[];
  surcharges?: TodoSurchargeDto[];
  counterName?: string;
}

@Widget({
  name: "CreateInvoices",
  templateName: "Rechnung erstellen",
  templateDescription: "Erstellen Sie eine Rechnung basierend auf Arbeitsprotokollen, Zuschlägen und Rechnungsvorlagen",
  icon: "bill",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        type: "string",
        title: "Kontext Variable mit Protokoll Ids",
        default: ""
      },
      counterId: {
        type: "string",
        title: "Nummernkreis verwenden",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          type: TYPE_COUNTERS
        },
        description: "Im PDF Template kann der Nummernkreis dann als ${sequenceNumber} verwendet werden"
      },
      surchargeIds: {
        type: "array",
        title: "Zuschläge",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          type: TYPE_TODO_SURCHARGES,
          multiple: true
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-create-invoices-widget",
  standalone: true,
  templateUrl: "./create-invoices-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LoadingElementDirective,
    I18nComponent,
    ObjectArrayValuePipe,
    SkeletonComponent,
    LoadingStateComponent
  ]
})
export class CreateInvoicesWidgetComponent extends BaseWidgetComponent {
  protected worklogStore = injectStore<WorklogDto>({ typePath: TYPE_WORKLOGS });
  protected surchargeStore = injectStore<TodoSurchargeDto>({ typePath: TYPE_TODO_SURCHARGES });
  protected counterStore = injectStore<CounterDto>({ typePath: TYPE_COUNTERS });

  protected DEFAULT_HTML5_DATE_FORMAT = DEFAULT_HTML5_DATE_FORMAT;
  protected invoices: InvoiceDto[];

  protected viewState = computedAsync<ViewState>(
    async () => {
      const data = this.data();
      const context = this.context();
      if (
        !data ||
        !this.worklogStore.query.isSuccess() ||
        !this.surchargeStore.query.isSuccess() ||
        !this.counterStore.query.isSuccess()
      )
        return { loadingState: "loading" };

      const viewState: ViewState = {
        loadingState: "loaded"
      };

      // counterName
      if (data?.counterId) viewState.counterName = this.counterStore.getById(data.counterId)?.name;

      // worklogs
      const worklogIds: string[] = getJsonByPath(context, data?.contextVariable || "");
      if (!worklogIds) viewState.worklogs = [];
      viewState.worklogs = worklogIds.map((wid) => this.worklogStore.getById(wid));
      viewState.worklogCount = viewState.worklogs.length;

      // surcharges
      if (data.surchargeIds)
        viewState.surcharges = data.surchargeIds.map((id: string) => this.surchargeStore.getById(id));

      return viewState;
    },
    { initialValue: { loadingState: "loading" } }
  );

  protected invoiceContext = signal<BillingInvoiceContext>({});

  constructor(
    private axiosApiClient: AxiosApiClient,
    private routerUtilService: RouterUtilService
  ) {
    super();

    effect(
      () => {
        const viewState = this.viewState();
        if (viewState.loadingState !== "loaded") return;

        const worklogs = viewState.worklogs;
        if (worklogs instanceof Array && worklogs.length > 0) {
          const firstWorklogDate = worklogs.map((w) => w.startTime).reduce((a, b) => Math.min(a, b));
          const lastWorklogDate = worklogs.map((w) => w.endTime).reduce((a, b) => Math.max(a, b));
          this.invoiceContext.set({
            billingPeriodStart: startOfDay(firstWorklogDate).valueOf(),
            billingPeriodEnd: endOfDay(lastWorklogDate).valueOf()
          });
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected createBill() {
    return async (): Promise<void> => {
      const invoiceContext = this.invoiceContext();
      if (invoiceContext.billingPeriodStart === invoiceContext.billingPeriodEnd)
        invoiceContext.billingPeriod = format(invoiceContext.billingPeriodStart, DEFAULT_GERMAN_HUMAN_DATE_FORMAT);
      else
        invoiceContext.billingPeriod =
          format(invoiceContext.billingPeriodStart, DEFAULT_GERMAN_HUMAN_DATE_FORMAT) +
          " - " +
          format(invoiceContext.billingPeriodEnd, DEFAULT_GERMAN_HUMAN_DATE_FORMAT);

      this.invoices = await this.axiosApiClient
        .get(InvoicesApi)
        .createInvoiceFromWorklogs({
          createFromWorklogs: {
            worklogIds: getJsonByPath(this.context(), this.data().contextVariable || ""),
            counterId: this.data().counterId,
            surchargeIds: this.data().surchargeIds,
            context: invoiceContext
          }
        })
        .then((res) => res.data);
      this.cdr.markForCheck();
    };
  }

  protected gotoInvoices(): Promise<void> {
    return this.routerUtilService.navigateFullUrl("custom/invoices");
  }

  protected setBillingPeriodStart(date: string): void {
    this.invoiceContext.update((invoiceContext) => {
      invoiceContext.billingPeriodStart = startOfDay(parseISO(date)).valueOf();
      return invoiceContext;
    });
  }

  protected setBillingPeriodEnd(date: string): void {
    this.invoiceContext.update((invoiceContext) => {
      invoiceContext.billingPeriodEnd = endOfDay(parseISO(date)).valueOf();
      return invoiceContext;
    });
  }
}
