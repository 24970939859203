import { BooleanEquationOperator } from "@smallstack/utils";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const LinkViewWidget: WidgetRegistryEntry = {
  name: "LinkView",
  configuration: {
    icon: "link--v1",
    templateName: "Verknüpfung Standard-UI",
    templateDescription: "Zeigt eine Verknüpfung an",
    tags: AllWidgetTags,
    dataSchema: {
      type: "object",
      properties: {
        layout: {
          title: "Layout",
          default: "row",
          enum: ["row", "col"]
        },
        showIcon: {
          title: "Zeige Icon",
          default: true,
          type: "boolean"
        },
        showType: {
          title: "Zeige Datentyp",
          default: true,
          type: "boolean"
        },
        iconSize: {
          type: "string",
          enum: [8, 16, 24, 32, 48, 64, 96, 128],
          title: "Icon Größe",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../showIcon",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: true
                }
              }
            ]
          }
        }
      }
    }
  },
  component: () => import("./link-view-widget.component").then((m) => m.LinkViewWidgetComponent)
};
