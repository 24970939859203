import { Store } from "./store";

export interface BrowserStorageStoreOptions {
  /**
   * The storage key
   */
  key: string;

  /**
   * The parser to use, defaults to "string"
   */
  parser?: "string" | "number" | "json" | "boolean";

  /**
   * if you want to set a default value, will be stored initially
   */
  default?: any;
}

/**
 * Autoloading store based on the browsers local/session storage which synchronizes automatically across browser tabs and sessions
 */
export class BrowserStorageStore<T> extends Store<T> {
  constructor(
    private options: BrowserStorageStoreOptions,
    private storage: Storage
  ) {
    super(options.default);

    if (options.parser === undefined) options.parser = "string";

    // load initial value
    const initialValue: string = storage.getItem(options.key);
    if (initialValue !== null && initialValue !== undefined) this.setValue(this.parseValue(initialValue));
    else if (this.options.default) this.setValue(this.options.default);

    // react to changes from outside
    this.value$.subscribe((val) => {
      if (val === undefined) storage.removeItem(this.options.key);
      else storage.setItem(this.options.key, this.stringifyValue(val));
    });

    // react to changes from other tabs
    if (typeof window !== "undefined")
      window.addEventListener("storage", (storageEvent: StorageEvent) => {
        if (storageEvent.storageArea === this.storage && storageEvent.key === this.options.key)
          if (storageEvent.newValue === null) this.setValue(this.options.default);
          else this.setValue(this.parseValue(storageEvent.newValue));
      });
  }

  private parseValue(val: any) {
    switch (this.options.parser) {
      case "json":
        return JSON.parse(val);
      case "number":
        return parseInt(val);
      case "boolean":
        return val === "true";
      case "string":
      default:
        return val;
    }
  }

  private stringifyValue(val: any): string {
    switch (this.options.parser) {
      case "json":
        return JSON.stringify(val);
      case "number":
        return "" + val;
      case "boolean":
        return "" + val;
      case "string":
      default:
        return val;
    }
  }
}
