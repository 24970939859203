@if (widgetTreeService.editMode() !== true) {
  @if (isDefined) {
    <smallstack-widget-child
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
      property="trueComponent"
    ></smallstack-widget-child>
  } @else {
    <smallstack-widget-child
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
      property="falseComponent"
    ></smallstack-widget-child>
  }
} @else {
  <div class="mat-outline">
    <mat-label>true component</mat-label>
    <smallstack-widget-child
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
      property="trueComponent"
    ></smallstack-widget-child>
  </div>
  <div class="mat-outline">
    <mat-label>false component</mat-label>
    <smallstack-widget-child
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
      property="falseComponent"
    ></smallstack-widget-child>
  </div>
}
