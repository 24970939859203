/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy, computed, inject, signal, untracked } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { UntypedFormGroup } from "@angular/forms";
import { Logger } from "@smallstack/core-common";
import { TranslationStore } from "@smallstack/i18n-components";
import { AjvFactory, TypeSchema } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import {
  REGEX_CONFIGURATION_KEY,
  REGEX_HTTPS_URL,
  calculateBooleanEquation,
  cloneObject,
  convertDotNotationPathToJsonSchemaPath,
  distinctUntilChangedObj,
  getJsonByPath,
  getLastPropertyName,
  getParentJson,
  getRelativePath,
  getSchemaDefaults,
  getSchemaPropertiesKeys,
  getSchemaPropertiesKeysForValue,
  isNonEmptyString,
  mergeObjects,
  objectsEqual,
  onlyUnique,
  removeNullish,
  setJsonProperty
} from "@smallstack/utils";
import Ajv from "ajv";
import { createNotifier } from "ngxtension/create-notifier";
import { Observable, Subscription, combineLatest, map, of } from "rxjs";
import { Memoize } from "typescript-memoize";
import { FormOptions } from "../interfaces/form-options";
import { InputWidgetValidationError } from "../interfaces/form-validation";

export interface FormObjectValue {
  [key: string]: FormValue;
}

export interface SetSchemaOptions {
  /** defaults to true, set to false if you wish to not using defaults by default ;-) */
  useDefaultsAsValue?: boolean;
}

// TODO: remove any
export type FormValue = boolean | number | string | undefined | FormObjectValue | any;

/**
 * A service that gets injected into every each smallstack form
 */
// @CountMethodCalls()
@Injectable()
export class FormService implements OnDestroy {
  private typeService = inject(TypeService);

  /** as long as no schema is set, this field is false. Can be used to not show form fields until schema is initialized */
  public isReady = signal(false);

  /** Holds the current value of the form */
  readonly #value = signal<FormValue>({}, { equal: objectsEqual });
  readonly #defaultValue = signal<FormValue>({});
  readonly #validationErrors = signal<{ [path: string]: InputWidgetValidationError[] }>({});
  readonly #validatedFields = signal<string[]>([], { equal: objectsEqual });
  readonly #globalFormOptions = signal<FormOptions>({}, { equal: objectsEqual });
  readonly #schema = signal<TypeSchema>(undefined, { equal: objectsEqual });

  public schema = this.#schema.asReadonly();
  /** @deprecated */
  public schema$: Observable<TypeSchema> = toObservable(this.schema);

  readonly #validatedOnce = signal(false);

  public value = this.#value.asReadonly();
  /** @deprecated */
  public value$ = toObservable(this.#value);

  /** @deprecated */
  public validationErrors$ = toObservable(this.#validationErrors);
  public validationErrors = this.#validationErrors.asReadonly();

  public validatedFields = this.#validatedFields.asReadonly();

  /** @deprecated */
  public globalFormOptions$ = toObservable(this.#globalFormOptions);
  public globalFormOptions = this.#globalFormOptions.asReadonly();

  public translationStore: TranslationStore;

  public isValid = computed(() => {
    // // do not validate if not validated once, otherwise this would mark all fields as dirty immediately
    // if (this.#validatedOnce() === false) return undefined;
    return Object.keys(this.#validationErrors()).length === 0;
  });

  public submitClicked = createNotifier();

  public hiddenFields = computed(() => {
    const start = Date.now();
    const hiddenFields: string[] = [];

    const schema = this.schema();
    const value = this.value();

    for (const path of getSchemaPropertiesKeysForValue(schema, value)) {
      const schemaForPath: TypeSchema = getJsonByPath(schema, convertDotNotationPathToJsonSchemaPath(path));
      if (schemaForPath && schemaForPath["x-schema-form"]?.rules instanceof Array) {
        for (const rule of schemaForPath["x-schema-form"].rules) {
          const rulePath = rule.if.dataPath.startsWith(".")
            ? getRelativePath(path, rule.if.dataPath)
            : rule.if.dataPath;

          if (rule.action === "hide") {
            if (
              calculateBooleanEquation({
                operator: rule.if.operator,
                valueA: rule.if.value,
                valueB: getJsonByPath(value, rulePath)
              })
            )
              hiddenFields.push(path);
          }
        }
      }
    }
    const hiddenFieldsTime = Date.now() - start;
    if (hiddenFieldsTime > 10) console.warn("hiddenFields() took " + hiddenFieldsTime + "ms");
    return hiddenFields;
  });

  /** @deprecated */
  public hiddenFields$ = toObservable(this.hiddenFields);

  /** @deprecated Use the FormService directly, without ReactiveForms from Angular */
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  #subscription = new Subscription();

  constructor(private ajvFactory: AjvFactory) {}

  public ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  /** Submits the form */
  public async submit(): Promise<void> {
    await this.validateAllFields();
    if (this.isValid()) this.submitClicked.notify();
  }

  public setGlobalOption(key: keyof FormOptions, value: any): void {
    untracked(() => {
      this.#globalFormOptions.update((current) => ({ ...current, [key]: value }));
    });
  }

  public setGlobalOptions(options: FormOptions): void {
    this.#globalFormOptions.set(options);
  }

  /** @deprecated */
  public getValidationStatusForPath$(path: string): Observable<InputWidgetValidationError[]> {
    return this.validationErrors$.pipe(map((status) => (status ? status[path] : undefined)));
  }

  public getValidationStatusForPath(path: string): InputWidgetValidationError[] {
    return this.validationErrors()[path];
  }

  public hasBeenValidated(path: string): boolean {
    return this.#validatedFields()?.includes(path);
  }

  /** @deprecated */
  public getValue(): FormValue {
    return cloneObject(this.#value());
  }

  /** Returns a snapshot of a specific path of the current value */
  public getValueByPath(path: string): FormValue {
    return getJsonByPath(this.#value(), path);
  }

  /** @deprecated */
  public getValueByPath$(path: string): Observable<FormValue> {
    if (!path) return of(undefined);
    return this.value$.pipe(
      map((val) => getJsonByPath(val, path)),
      distinctUntilChangedObj()
    );
  }

  public setValue(value: FormValue): void {
    this.#value.set(mergeObjects(this.#defaultValue(), value, { mergeArrayMethod: "replace" }));
  }

  /**
   * Sets a form value by path
   * @param path The path of the value, supports dot notation (e.g. "address.street"). Leave empty to replace the root value
   * @param value The value you want to set.
   */
  public setValueByPath(path: string, value: FormValue): void {
    this.#value.update((currentValue) => setJsonProperty(currentValue || {}, path, value));
  }

  /** Returns the schema for the given path. Supports dot notation and `array.0` qualifiers */
  public getSchemaForPath(path: string): TypeSchema {
    if (path === undefined) throw new Error("no path given while asking for schemaForPath");
    if (path.includes("/") || path.includes("\\")) throw new Error("Path contains invalid characters: " + path);

    const schema = this.#schema();
    if (!schema) return;

    const schemaForPath: TypeSchema = getJsonByPath(schema, convertDotNotationPathToJsonSchemaPath(path));

    if (!schemaForPath) {
      // Logger.error("FormService", "No schema found for path: " + path + " in schema: " + safeStringify(schema));
      return undefined;
    }

    // evaluate rules
    return this.evaluateRules(schemaForPath, path, this.#value());
  }

  /** @deprecated */
  public getSchemaForPath$(path: string): Observable<TypeSchema> {
    if (path === undefined) throw new Error("no path given while asking for schemaForPath$");
    if (path.includes("/") || path.includes("\\")) throw new Error("Path contains invalid characters: " + path);

    return combineLatest([this.schema$, this.value$]).pipe(
      map(() => {
        return this.getSchemaForPath(path);
      })
    );
  }

  /** @deprecated */
  public getSchema(): TypeSchema {
    return this.#schema();
  }

  public async setSchemaWithRefs(schema: TypeSchema, options: SetSchemaOptions = {}): Promise<void> {
    if (schema === undefined) throw new Error("no schema given while setting schema");
    schema = this.typeService.tidySchema(schema);
    schema = await this.typeService.evalRefSchema(schema, { recursive: true });
    this.setSchema(schema, options);
  }

  public setSchema(schema: TypeSchema, options: SetSchemaOptions = {}): void {
    if (schema === undefined) throw new Error("no schema given while setting schema");

    // check if $ref's are resolved
    if (JSON.stringify(schema).includes("$ref"))
      throw new Error("Schema contains $refs, please use setSchemaWithRefs or resolve them beforehand!");

    schema = this.typeService.tidySchema(schema);
    this.#schema.set(schema);
    if (options?.useDefaultsAsValue !== false) {
      const defaults = getSchemaDefaults(schema, false);
      this.#defaultValue.set(defaults);
      const currentValue = this.#value();
      this.setValue({ ...defaults, ...currentValue });
    }

    this.isReady.set(true);
  }

  public isRequired(path: string): boolean {
    // get schema related to path
    const pathSchema = getParentJson(this.#schema(), convertDotNotationPathToJsonSchemaPath(path), 2);
    return pathSchema?.required?.includes(getLastPropertyName(path)) === true;
  }

  /** @deprecated */
  public isRequired$(path: string): Observable<boolean> {
    return this.schema$.pipe(
      map((schema) => {
        // get schema related to path
        const pathSchema = getParentJson(schema, convertDotNotationPathToJsonSchemaPath(path), 2);
        return pathSchema?.required?.includes(getLastPropertyName(path)) === true;
      })
    );
  }

  public async validateAllFields(): Promise<void> {
    const ajv = await this.ajvFactory.getInstance();
    const validationErrors = this.getValidationErrors(ajv);
    this.#validationErrors.set(validationErrors);
    // set all fields as validated
    this.#validatedFields.set(getSchemaPropertiesKeys(this.#schema()));
    this.#validatedOnce.set(true);
  }

  public async validateByPath(path: string): Promise<void> {
    const ajv = await this.ajvFactory.getInstance();
    const validationErrors = this.getValidationErrors(ajv);

    // remove the ones that were solved (but do not touch others)
    const cleanedErrors: { [path: string]: InputWidgetValidationError[] } = {};
    for (const validationErrorPath in this.#validationErrors()) {
      // if error still exists, move it over to the cleaned ones
      if (validationErrors[validationErrorPath])
        cleanedErrors[validationErrorPath] = this.#validationErrors()[validationErrorPath];
    }

    this.#validationErrors.set(cleanedErrors);

    // filter out given path
    const validationErrorsByPath = validationErrors[path];
    const currentValidationErrors = this.#validationErrors();
    currentValidationErrors[path] = validationErrorsByPath;
    this.#validationErrors.set(currentValidationErrors);
    const currentValidatedFields = this.#validatedFields().filter((cvf) => !cvf.startsWith(path));
    this.#validatedFields.set(onlyUnique([...currentValidatedFields, path]));
  }

  /**
   * @returns a schema for the given form group code. If code is omitted, the properties without any group will be returned
   */
  public getFormGroup(code?: string): TypeSchema {
    const schema = cloneObject(this.schema());
    if (schema?.properties) {
      for (const schemaKey in schema.properties) {
        if (schema.properties[schemaKey]["x-schema-form"]?.group !== code) {
          delete schema.properties[schemaKey];
        }
      }
    }
    return schema;
  }

  public getFormGroups(): string[] {
    const schema = cloneObject(this.schema());
    const groups: string[] = [];
    if (schema?.properties) {
      for (const schemaKey in schema.properties) {
        if (schema.properties[schemaKey]["x-schema-form"]?.group !== undefined)
          groups.push(schema.properties[schemaKey]["x-schema-form"]?.group);
      }
    }
    return onlyUnique(groups);
  }

  @Memoize()
  private getSchemaPathFromAjvError(ajvError: any): string {
    if (ajvError.instancePath === "" && ajvError.params.missingProperty) return ajvError.params.missingProperty;
    if (ajvError.keyword === "minItems" || ajvError.keyword === "maxItems")
      return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "minLength" || ajvError.keyword === "maxLength")
      return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "required") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "enum") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "type") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "if") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "additionalProperties") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "pattern") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    if (ajvError.keyword === "format") return ajvError.instancePath.substring(1).replace(/\//g, ".");
    throw new Error("Could not get schema path from ajv error: " + JSON.stringify(ajvError));
  }

  public evaluateRules(subSchema: TypeSchema, currentPath: string, formValue: unknown): TypeSchema {
    if (!subSchema || !Array.isArray(subSchema["x-schema-form"]?.rules)) return subSchema;

    // since we are modifying the schema, we need to clone it
    let schema = cloneObject(subSchema);
    for (const rule of schema["x-schema-form"].rules) {
      const rulePath = rule.if.dataPath.startsWith(".")
        ? getRelativePath(currentPath, rule.if.dataPath)
        : rule.if.dataPath;

      if (rule.action === "extendSchema") {
        if (
          calculateBooleanEquation({
            operator: rule.if.operator,
            valueA: rule.if.value,
            valueB: getJsonByPath(formValue, rulePath)
          })
        ) {
          const newSchema = cloneObject(rule.schema);
          if (newSchema["x-schema-form"]?.rules) delete newSchema["x-schema-form"].rules;
          schema = { ...schema, ...newSchema };
        }
      }
    }
    return schema;
  }

  // @synchronized // otherwise, doing validation via save btn would be faster than typing into input fields and validating fields during tests.
  // eslint-disable-next-line max-lines-per-function
  private getValidationErrors(ajv: Ajv): { [path: string]: InputWidgetValidationError[] } {
    ajv.removeKeyword("allOf");
    const value = cloneObject(this.value());
    const evaluatedSchema = this.getSchemaForPath("");
    const validate = ajv.compile(evaluatedSchema);
    validate(value);
    if (validate.errors?.length > 0) {
      const inputWidgetErrors: { [path: string]: InputWidgetValidationError[] } = {};
      for (const ajvError of validate.errors) {
        const path = this.getSchemaPathFromAjvError(ajvError);
        if (!inputWidgetErrors[path]) inputWidgetErrors[path] = [];
        switch (ajvError.keyword) {
          case "required":
            if (isNonEmptyString(ajvError.instancePath)) {
              if (!inputWidgetErrors[`${path}.${ajvError.params.missingProperty}`])
                inputWidgetErrors[`${path}.${ajvError.params.missingProperty}`] = [];
              inputWidgetErrors[`${path}.${ajvError.params.missingProperty}`].push({
                error: { key: "@@errors.validations.required" }
              });
            } else inputWidgetErrors[path].push({ error: { key: "@@errors.validations.required" } });
            break;
          case "minItems":
            inputWidgetErrors[path].push({
              error: { key: "@@errors.validations.minItems", replacers: { min: ajvError.params.limit } }
            });
            break;
          case "maxItems":
            inputWidgetErrors[path].push({
              error: { key: "@@errors.validations.maxItems", replacers: { max: ajvError.params.limit } }
            });
            break;
          case "minLength":
            inputWidgetErrors[path].push({
              error: { key: "@@errors.validations.minLength", replacers: { requiredLength: ajvError.params.limit } }
            });
            break;
          case "maxLength":
            inputWidgetErrors[path].push({
              error: { key: "@@errors.validations.maxLength", replacers: { maxLength: ajvError.params.limit } }
            });
            break;
          case "type":
            inputWidgetErrors[path].push({
              error: {
                key: "@@errors.validations.type",
                replacers: { type: "@@types.primitives." + ajvError.params.type }
              }
            });
            break;
          case "enum": {
            const pathSchema = this.getSchemaForPath(path);
            if (pathSchema["x-schema-form"]?.customValuesAllowed !== true)
              inputWidgetErrors[path].push({
                error: {
                  key: "@@errors.validations.enum",
                  replacers: { enumValues: ajvError.params.allowedValues?.join(", ") }
                }
              });
            break;
          }
          case "pattern": {
            let patternName: string;
            switch (ajvError.params.pattern) {
              case REGEX_HTTPS_URL.source:
                patternName = "URL (muss mit http beginnen)";
                break;
              case REGEX_CONFIGURATION_KEY.source:
                patternName = "Nur Buchstaben und Punkte. Muss mit Buchstaben anfangen und aufhören.";
                break;
              default:
                patternName = ajvError.params.pattern;
            }
            inputWidgetErrors[path].push({
              error: {
                key: "@@errors.validations.pattern",
                replacers: { requiredPattern: patternName }
              }
            });
            break;
          }
          case "format": {
            if (ajvError.params.format === "email")
              inputWidgetErrors[path].push({
                error: {
                  key: "@@errors.validations.isemail"
                }
              });
            else
              inputWidgetErrors[path].push({
                error: {
                  key: "@@errors.validations.wrongFormat",
                  replacers: { format: ajvError.params.format }
                }
              });
            break;
          }
          default:
            Logger.warning("FormService", "No translation found for: " + ajvError.message, ajvError);
            inputWidgetErrors[path].push({ error: { key: ajvError.message } });
            break;
        }
      }
      return removeNullish(inputWidgetErrors);
    }
    return {};
  }
}
