import { SeoConfiguration, SeoConfigurationSchema } from "./seo-configuration";
import { SocketConnection, SocketConnectionSchema } from "./socket-connection";
import { Widget, WidgetSchema } from "./widget";

export interface ApplicationContent {
  path: string;
  content: Widget;
  socketConnections?: Array<SocketConnection>;
  authenticationRequired?: boolean;
  layout?: string;
  seo?: SeoConfiguration;
}

export const ApplicationContentSchema = {
  type: "object",
  properties: {
    path: {
      type: "string"
    },
    content: WidgetSchema,
    socketConnections: {
      additionalProperties: false,
      type: "array",
      items: SocketConnectionSchema
    },
    authenticationRequired: {
      type: "boolean"
    },
    layout: {
      type: "string"
    },
    seo: SeoConfigurationSchema
  },
  required: ["path", "content"]
};
