import { TypeSchema } from "../typesystem/type-schema";
import { DataTypePermission, DataTypePermissionSchema } from "./data-type-permission";
import { DataTypeStorageDefinition, DataTypeStorageDefinitionSchema } from "./data-type-storage-definition";
import { DataTypeVariant, DataTypeVariantSchema } from "./data-type-variant";
import { DataTypeView, DataTypeViewSchema } from "./data-type-view";
import { InnerTranslation, createInlineTranslationSchema } from "./inner-translation";
import { Model } from "./model";
import { SearchableTypeField, SearchableTypeFieldSchema } from "./searchable-type-field";

export interface TypeOwnershipField {
  name: string;
  isArray?: boolean;
}

export interface DataType extends Model {
  title: Array<InnerTranslation>;
  titlePlural?: Array<InnerTranslation>;
  path: string;
  icon?: string;
  schema?: any;
  isSystemType?: boolean;
  permissions?: DataTypePermission;
  ownerPermissions?: DataTypePermission;
  views?: Array<DataTypeView>;
  url?: string;
  representation?: string;
  longRepresentation?: string;
  searchableFields?: Array<SearchableTypeField>;
  variants?: Array<DataTypeVariant>;
  autoIncrementProperty?: string;
  ownershipEnabled?: boolean;
  ownershipField?: TypeOwnershipField;
  /** Determines where the data is stored */
  storage?: DataTypeStorageDefinition;
}

export const DataTypeSchema: TypeSchema = {
  type: "object",
  properties: {
    title: createInlineTranslationSchema({ title: "Titel", maxLength: 256, minLength: 3 }),
    titlePlural: createInlineTranslationSchema({ title: "Titel Mehrzahl", maxLength: 256, minLength: 3 }),
    path: { type: "string" },
    icon: { type: "string" },
    schema: { type: "object", "x-schema-form": { widget: "monaco" } },
    isSystemType: { type: "boolean" },
    permissions: {
      type: "object",
      additionalProperties: DataTypePermissionSchema,
      "x-schema-form": { widget: "monaco" }
    },
    ownerPermissions: {
      type: "object",
      additionalProperties: DataTypePermissionSchema,
      "x-schema-form": { widget: "monaco" }
    },
    views: { type: "array", items: DataTypeViewSchema },
    url: { type: "string" },
    representation: { type: "string" },
    longRepresentation: { type: "string" },
    searchableFields: { type: "array", items: SearchableTypeFieldSchema },
    variants: { type: "array", items: DataTypeVariantSchema },
    autoIncrementProperty: { type: "string" },
    ownershipEnabled: { type: "boolean" },
    storage: DataTypeStorageDefinitionSchema
  },
  required: ["path"]
};
