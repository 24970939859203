export interface OnRegisterCallback {
  callback: (injectable: any) => void;
  callOnlyOnce: boolean;
}

declare let global: any;

/**
 * A very simple IOC
 * @class IOC
 *
 */
export class IOC {
  private container: { [id: string]: any } = {};
  private onRegisterCallbacks: { [id: string]: OnRegisterCallback[] } = {};

  // if more than one container will be created, this is useful for comparing or checking which one is being used
  private id: string = "ioc-" + Math.random() * 99999;

  public static register<T>(id: string, value: T, forceRegister: boolean = false): T {
    return IOC.instance().register(id, value, forceRegister);
  }

  constructor(private debugMode: boolean = false) {
    if (debugMode) {
      setTimeout(() => {
        let unresolvedReferences: string = "";
        Object.keys(this.onRegisterCallbacks).forEach((id: string) => {
          if (!this.container[id]) {
            if (unresolvedReferences !== "") unresolvedReferences += ", ";
            unresolvedReferences += id;
          }
        });
        if (unresolvedReferences !== "")
          console.error(
            "[IOC] After 1s the following references could not be resolved : " + unresolvedReferences,
            this.id
          );
      }, 1000);
    }
  }

  /**
   * Registers a service/factory
   *
   * @param {string} id The identifier of the service/factory
   * @param {object} value The instance/constructor of the service/factory
   * @param {boolean} forceRegister Already registered instances aren't overwritten by default, here you can force that
   */
  public register<T>(id: string, value: T, forceRegister: boolean = false): T {
    if (this.container[id] && !forceRegister) {
      if (this.debugMode)
        console.debug(`[IOC] Ignoring re-registering of ${id} since there is already an instance!`, this.id);
      return this.get(id);
    }
    this.container[id] = value;
    if (this.debugMode) console.debug(`[IOC] registered Object with ID: ${id}, Type:${typeof value}`, this.id);
    if (this.onRegisterCallbacks[id]) {
      this.onRegisterCallbacks[id].forEach((onRegisterCallback: OnRegisterCallback) => {
        onRegisterCallback.callback(value);
      });
      this.onRegisterCallbacks[id] = this.onRegisterCallbacks[id].filter(
        (onRegisterCallback: OnRegisterCallback) => onRegisterCallback.callOnlyOnce !== true
      );
    }
    return value;
  }

  /**
   * Gets a service/factory by id
   *
   * @param {string} id The identifier of the service/factory to get
   * @return {object} The instance/constructor of the service/factory
   */
  public get<T>(id: string): T {
    if (this.isRegistered(id)) return this.container[id] as T;
    else throw new Error(`Could not find an ioc instance for id : '${id}'!`);
  }

  /**
   * Gets a service/factory by id
   *
   * @param {string} id The identifier of the service/factory to get
   * @return {object} The instance/constructor of the service/factory
   */
  public static get<T>(id: string): T {
    return IOC.instance().get<T>(id);
  }

  /**
   * Checks for availability of a service/factory
   *
   * @param {string} id The identifier of the service/factory to get
   * @return {boolean} True if something is registered, otherwise false
   */
  public isRegistered(id: string): boolean {
    return this.container[id] !== undefined;
  }

  public static isRegistered(id: string): boolean {
    return IOC.instance().isRegistered(id);
  }

  public static instance(): IOC {
    let root: any;
    if (typeof global !== "undefined") root = global;
    else if (typeof window !== "undefined") root = window;
    else throw new Error("no 'window' or 'global' object found!");
    if (root.smallstack_ioc === undefined) root.smallstack_ioc = new IOC();
    return root.smallstack_ioc;
  }

  public onRegister(id: string, callback: (injectable: any) => void, callOnlyOnce: boolean = false) {
    if (this.isRegistered(id)) callback(this.get(id));
    if (!this.onRegisterCallbacks[id]) this.onRegisterCallbacks[id] = [];
    this.onRegisterCallbacks[id].push({ callback, callOnlyOnce });
  }

  public static onRegister(id: string, callback: (injectable: any) => void, callOnlyOnce: boolean = false) {
    IOC.instance().onRegister(id, callback, callOnlyOnce);
  }

  public getIOCID(): string {
    return this.id;
  }

  /**
   * Removes all registered instances
   */
  public clear() {
    this.container = [];
  }

  public static clear() {
    IOC.instance().clear();
  }
}
