import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonConfiguration } from "@smallstack/common-components";
import { I18nComponent } from "../i18n/i18n.component";

@Component({
  selector: "smallstack-notification-dialog",
  templateUrl: "./notification-dialog.component.html",
  styleUrls: ["./notification-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, I18nComponent]
})
export class NotificationDialogComponent {
  public confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data === undefined) data = {};
    if (data.buttons === undefined)
      data.buttons = [
        { classes: "mat-flat-button mat-primary", label: "@@actions.dialogDismiss", value: true }
      ] as MatButtonConfiguration[];
    else {
      const buttons: MatButtonConfiguration[] = data.buttons;
      data.buttons = buttons.map((btn) => {
        let classes = btn.classes;
        if (!classes) classes = "btn " + (btn.color ? " btn-" + btn.color : "");
        return {
          classes,
          label: btn.label,
          value: btn.value
        };
      });
    }
  }
}
