import { Injectable } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { MsalPublicClientApplicationService, cookieExists, deleteCookie } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { BehaviorSubject, map } from "rxjs";
import { UserService } from "./user.service";

export interface MsalLoginOptions {
  scopes?: string[];
  redirectUri?: string;
  authTenantId?: string;
  tenantId?: string;
  resellerId?: string;
}

@Injectable({ providedIn: "root" })
export class MsalLoginService {
  #redirectCode$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  #currentAccount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentAccount$ = this.#currentAccount$.asObservable();
  public get currentAccount() {
    return this.#currentAccount$.getValue();
  }

  public isLoggingIn$ = this.#redirectCode$.asObservable().pipe(map((code) => !!code));

  constructor(
    private notificationService: NotificationService,
    private msalPublicClientApplicationService: MsalPublicClientApplicationService,
    private router: Router,
    private axiosApiClient: AxiosApiClient,
    private userService: UserService
  ) {}

  /**
   * This method needs to be called as soon as possible. It subscribes to router events and handles the hashtag fragment redirect of microsoft
   */
  public watchLoginRedirect(): void {
    this.router.events.subscribe((event: RoutesRecognized) => {
      if (event.url?.includes("#code=")) this.#redirectCode$.next("#" + event.url.split("#")[1]);
    });
  }

  public async handleLoginRedirect(options: MsalLoginOptions): Promise<boolean> {
    if (this.#redirectCode$.value) {
      const msalInstance = await this.msalPublicClientApplicationService.getPublicClientApplication(options.tenantId);
      if (!msalInstance) {
        this.notificationService.showStandardErrorPopup(new Error("Microsoft Login is not configured!"));
        return;
      }
      const authResult = await msalInstance.handleRedirectPromise(this.#redirectCode$.value);
      msalInstance.setActiveAccount(authResult.account);
      if (authResult) {
        this.#currentAccount$.next(authResult.account);
        try {
          // login with microsoft token and get smallstack token
          const currentUserDto = await this.axiosApiClient
            .withConfiguration({
              authTenantId: options.authTenantId,
              resellerId: options.resellerId,
              tenantId: options.tenantId
            })
            .get(UsersApi)
            .loginWithMicrosoftToken({ azureLoginRequest: { token: authResult.accessToken } })
            .then((res) => res.data);
          this.userService.setCurrentUser(currentUserDto);
          this.#redirectCode$.next(undefined);
          return true;
        } catch (e) {
          this.notificationService.showStandardErrorPopup(e);
        }
      }
    }
    return false;
  }

  public async login(options: MsalLoginOptions): Promise<void> {
    sessionStorage.removeItem("msal.interaction.status");
    if (cookieExists("msal.interaction.status")) deleteCookie("msal.interaction.status");
    const msalInstance = await this.msalPublicClientApplicationService.getPublicClientApplication(
      options.tenantId,
      options.redirectUri
    );
    if (!msalInstance) {
      this.notificationService.showStandardErrorPopup(new Error("Microsoft Login is not configured!"));
      return;
    }
    await msalInstance.loginRedirect({
      scopes: options?.scopes,
      prompt: "select_account"
    });
  }
}
