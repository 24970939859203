export enum ServicePermissions {
  ANONYMOUS = "anonymous",
  APPLICATIONS_WRITE = "applications.write",
  AUDIT_READ = "audit.read",
  BOOKED_SUBSCRIPTION_WRITE = "bookedsubscriptions.write",
  CALENDAR_READ = "calendar.read",
  CALENDAR_WRITE = "calendar.write",
  CMS_READ = "cms.read",
  CMS_WRITE = "cms.write",
  CONFIGURATION_READ = "configuration.read",
  CONFIGURATION_WRITE = "configuration.write",
  CONTACTS_READ = "contacts.read",
  CONTACTS_WRITE = "contacts.write",
  CONTACTS_ADMIN = "contacts.admin",
  CRONJOB_READ = "cronjob.read",
  CRONJOB_WRITE = "cronjob.write",
  CUSTOM_FIELD_WRITE = "customfields.write",
  CUSTOM_FIELD_READ = "customfields.read",
  DATA_READ = "data.read",
  DATA_TEMPLATES_WRITE = "datatemplates.write",
  DATA_WRITE = "data.write",
  DATATYPES_WRITE = "datatypes.write",
  DEVICES_READ = "devices.read",
  DEVICES_WRITE = "devices.write",
  EMAILS_READ = "emails.read",
  EMAILS_SEND = "emails.send",
  EMAILS_WRITE = "emails.write",
  EMAILTEMPLATES_READ = "emailtemplates.read",
  EMAILTEMPLATES_WRITE = "emailtemplates.write",
  EXPORTERS_EXECUTE = "exporters.execute",
  EXPORTERS_READ = "exporters.read",
  FILES_READ = "files.read",
  FILES_WRITE = "files.write",
  FORMS_WRITE = "forms.write",
  IMPORTERS_EXECUTE = "importers.execute",
  IMPORTERS_READ = "importers.read",
  INTERNATIONALIZATION_READ = "internationalization.read",
  INTERNATIONALIZATION_WRITE = "internationalization.write",
  INVOICE_WRITE = "invoice.write",
  INVOICE_READ = "invoice.read",
  MAILING_LIST_READ = "mailinglist.read",
  MAILING_LIST_WRITE = "mailinglist.write",
  MAILING_CHANNEL_READ = "mailingchannel.read",
  MAILING_CHANNEL_WRITE = "mailingchannel.write",
  MAILING_DELIVERABLE_READ = "mailingdeliverable.read",
  MAILING_DELIVERABLE_WRITE = "mailingdeliverable.write",
  MESSAGES_READ = "messages.read",
  MESSAGES_WRITE = "messages.write",
  OPT_IN_WRITE = "optin.write",
  ORDERS_READ = "orders.read",
  ORDERS_WRITE = "orders.write",
  PDF_TEMPLATE_READ = "pdftemplates.read",
  PDF_TEMPLATE_WRITE = "pdftemplates.write",
  PERMISSION_READ = "permission.read",
  PRODUCTS_READ = "products.read",
  PRODUCTS_WRITE = "products.write",
  SHOPPING_CARTS_READ = "shoppingcarts.read",
  SHOPPING_CARTS_WRITE = "shoppingcarts.write",
  SUPPLIERS_READ = "suppliers.read",
  SUPPLIERS_WRITE = "suppliers.write",
  SUBSCRIPTIONS_WRITE = "subscriptions.write",
  TASKS_READ = "tasks.read",
  TASKS_WRITE = "tasks.write",
  TASKS_EXECUTE = "tasks.execute",
  TENANT_ADMIN = "tenant.admin",
  TENANT_READ = "tenant.read",
  TENANT_WRITE = "tenant.write",
  USERS_READ = "users.read",
  USERS_WRITE = "users.write",
  WORKFLOW_EXECUTE = "workflow.execute",
  WORKFLOW_READ = "workflow.read",
  WORKFLOW_WRITE = "workflow.write",
  RESELLERS_ADMIN = "resellers.admin",
  RESELLER_WRITE = "reseller.write",
  RESELLER_READ = "reseller.read",
  TODO_WRITE = "todo.write",
  TODO_READ = "todo.read",
  TODO_OWNER_WRITE = "todo.owner.write",
  TODO_OWNER_READ = "todo.owner.read",
  TODO_TEMPLATE_WRITE = "todotemplate.write",
  TODO_TEMPLATE_READ = "todotemplate.read",
  TODO_SURCHARGES_WRITE = "todosurcharges.write",
  TODO_SURCHARGES_READ = "todosurcharges.read",
  TODO_TEMPLATE_OWNER_WRITE = "todotemplate.owner.write",
  TODO_TEMPLATE_OWNER_READ = "todotemplate.owner.read",
  WORKLOG_READ = "worklog.read",
  WORKLOG_WRITE = "worklog.write",
  WORKLOG_OWNER_READ = "worklog.owner.read",
  WORKLOG_OWNER_WRITE = "worklog.owner.write",
  CORPORATIONS_ADMIN = "corporations.admin",
  CORPORATIONS_WRITE = "corporations.write",
  CORPORATIONS_READ = "corporations.read",
  USER_GROUPS_WRITE = "usergroups.write",
  USER_GROUPS_READ = "usergroups.read"
}
