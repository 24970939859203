import { Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class BasePathService {
  public basePath$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public basePath = toSignal(this.basePath$, {
    rejectErrors: true
  });

  /**
   * removes the basePath from the given path
   */
  public removeBasePath(path: string): string {
    const reducedBasePath = path?.replace(this.basePath$.value, "");
    return this.trimSlashes(reducedBasePath);
  }

  /**
   * prefixes given path with the basePath
   */
  public addBasePath(path: string): string {
    path = this.trimSlashes(path);
    if (path.startsWith(this.basePath$.value)) return path;
    return this.basePath$.value + "/" + path;
  }

  public setBasePath(path: string): void {
    this.basePath$.next(path);
  }

  private trimSlashes(path: string) {
    if (path.startsWith("/")) path = path.substr(1);
    if (path.endsWith("/")) path = path.substr(0, path.length - 1);
    return path.trim();
  }
}
