import "reflect-metadata";

export function setMetadata<T>(target: unknown, name: string, value: T): void {
  Reflect.defineMetadata("custom:annotations:" + name, value, target, "custom:annotations:" + name);
}

export function getMetadata<T>(target: unknown, name: string): T {
  const keys: any[] = Reflect.getMetadataKeys(target, "custom:annotations:" + name);
  const decorators = keys
    .filter((key) => key.toString().startsWith("custom:annotations:"))
    .reduce((values, key) => {
      const currValues = Reflect.getMetadata(key, target, "custom:annotations:" + name);
      return values.concat(currValues);
    }, []);

  return decorators[0];
}
