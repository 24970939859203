import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AxiosApiClient, FilesApi } from "@smallstack/axios-api-client";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { SQBuilder, SearchQuery } from "@smallstack/typesystem";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "slider-widget",
  templateUrl: "./slider-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SliderWidgetComponent extends BaseWidgetComponent implements OnInit {
  public license: string;
  public licenseUrl: string;

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        width: {
          type: "string",
          title: "Breite",
          default: "100%",
          description: "px, %"
        },
        height: {
          type: "string",
          title: "Höhe",
          description: "px"
        },
        autoHeight: {
          type: "boolean",
          title: "Auto-Höhe",
          description:
            "Wenn eingeschaltet, passt sich der Slider-Container automatische der Höhe des jeweiligen Bildes an"
        },
        effect: {
          type: "string",
          title: "Anzeigemodus",
          description: "Derzeit auswählbar zwischen Fade- und Slide-Effekt",
          enum: ["slide", "fade"]
        },
        images: {
          type: "array",
          title: "Bilder",
          items: {
            type: "object",
            required: ["url"],
            properties: {
              imageUrl: {
                type: "string",
                title: "URL",
                "x-schema-form": {
                  widget: "fileurl"
                }
              } as SchemaFormSchema,
              imageLink: {
                type: "string",
                title: "Link"
              },
              alternative: {
                type: "string",
                title: "Alternativtext",
                description:
                  "Dieser Text soll die Grafik beschreiben. Kann die Grafik aus bestimmten Gründen nicht angezeigt werden, so erscheint alternativ dieser Text.",
                default: ""
              }
            }
          }
        },
        duration: {
          type: "number",
          title: "Millisekunden bis zum nächsten Bild",
          description: "z.B. 5000 = 5 Sekunden",
          default: 5000
        }
      }
    };
  }

  public ngOnInit(): void {
    const search: SearchQuery = {
      fieldSearches: [{ fieldname: "url", value: this.data().images[0].imageUrl }],
      logicalOperator: "and"
    };

    void this.axiosApiClient
      .get(FilesApi)
      .getFiles({ search: SQBuilder.toBase64String(search) })
      .then((file) => {
        if (file) {
          this.license = file.data.elements[0].license;
          this.licenseUrl = file.data.elements[0].licenseUrl;
        }
        this.cdr.markForCheck();
      });
  }

  public openLink(image: any): void {
    if (image.imageLink) {
      window.open(image.imageLink, "_blank");
    }
  }
}
