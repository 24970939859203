import { Pipe, PipeTransform } from "@angular/core";
import { ContactDto } from "@smallstack/axios-api-client";

/** shows "firstName lastName" or, if they're not available, the company name */
@Pipe({ name: "displayName" })
export class DisplayNamePipe implements PipeTransform {
  public transform(value: ContactDto): string {
    if (!value) return;
    const str: string[] = [];
    // if (value.salutation) str.push(value.salutation);
    if (value.firstName) str.push(value.firstName);
    if (value.lastName) str.push(value.lastName);
    if (str.length === 0) str.push(value.company);
    return str.join(" ");
  }
}
