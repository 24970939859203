import { OverlayModule } from "@angular/cdk/overlay";
import { ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
@Component({
  templateUrl: "./overlay.component.html",
  selector: "smallstack-overlay",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OverlayModule]
})
export class OverlayComponent {
  protected overlayOpened = signal(false);

  @Input()
  public hasBackdrop: boolean = false;

  public openOverlay(): void {
    this.overlayOpened.set(true);
  }

  public closeOverlay(): void {
    this.overlayOpened.set(false);
  }

  public toggleOverlay(): void {
    this.overlayOpened.set(!this.overlayOpened());
  }
}
