/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ConfigurablePropertyDto
 */
export interface ConfigurablePropertyDto {
    /**
     * 
     * @type {string}
     * @memberof ConfigurablePropertyDto
     */
    type: ConfigurablePropertyDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurablePropertyDto
     */
    inlineValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurablePropertyDto
     */
    configurationKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurablePropertyDto
     */
    parser: ConfigurablePropertyDtoParserEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurablePropertyDto
     */
    displayFormat?: ConfigurablePropertyDtoDisplayFormatEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConfigurablePropertyDtoTypeEnum {
    Inline = 'inline',
    ProjectConfiguration = 'projectConfiguration'
}
/**
    * @export
    * @enum {string}
    */
export enum ConfigurablePropertyDtoParserEnum {
    Number = 'number',
    String = 'string',
    Boolean = 'boolean'
}
/**
    * @export
    * @enum {string}
    */
export enum ConfigurablePropertyDtoDisplayFormatEnum {
    Raw = 'raw',
    Currency = 'currency'
}



