import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { I18nComponent } from "@smallstack/i18n-components";

@Component({
  templateUrl: "./add-schema-entry.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, I18nComponent, MatSelectModule]
})
export class AddSchemaEntryComponent {
  public schema: SchemaFormSchema = { type: "string" };
  public key: string;

  constructor(
    private matDialogRef: MatDialogRef<AddSchemaEntryComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  public addProperty(): void {
    this.schema.title = this.key;
    if (this.schema.type === undefined) this.schema.type = "string";
    this.schema["x-schema-form"] = { dataDrivenSchema: true };
    this.matDialogRef.close({ key: this.key, schema: this.schema });
    this.cdr.markForCheck();
  }
}
