@switch (state()) {
  @case ("init") {
    @if (initTemplate()) {
      <ng-container *ngTemplateOutlet="initTemplate()"></ng-container>
    } @else {
      Initialisiere...
    }
  }
  @case ("loading") {
    @if (loadingTemplate()) {
      <ng-container *ngTemplateOutlet="loadingTemplate()"></ng-container>
    } @else {
      Lade...
    }
  }
  @case ("loaded") {
    @if (loadedTemplate()) {
      <ng-container *ngTemplateOutlet="loadedTemplate()"></ng-container>
    } @else {
      <ng-content></ng-content>
    }
  }
  @case ("error") {
    @if (errorTemplate()) {
      <ng-container *ngTemplateOutlet="errorTemplate()"></ng-container>
    } @else {
      <div class="text-error">Unknown error</div>
    }
  }
  @default {
    <div class="text-error">ERROR: Unknown state: {{ state() }}</div>
  }
}
