import { Injectable } from "@angular/core";
import { AxiosApiClient, ConfigurationDto, ConfigurationsApi } from "@smallstack/axios-api-client";
import { NotificationService } from "@smallstack/i18n-components";
import { TodoConfiguration, TodoConfigurationService, TODO_CONFIGURATION_KEY } from "@smallstack/todo-shared";
import { SQBuilder } from "@smallstack/typesystem";
import { synchronized } from "synchronized-ts";

@Injectable({ providedIn: "root" })
export class ProjectTodoConfigurationService extends TodoConfigurationService {
  private storedConfig: ConfigurationDto;
  constructor(
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService
  ) {
    super();
  }

  @synchronized
  public override async getConfiguration(): Promise<TodoConfiguration> {
    if (!this.storedConfig) {
      this.storedConfig = await this.axiosApiClient
        .get(ConfigurationsApi)
        .getConfigurations({ search: SQBuilder.asString([{ fieldname: "key", value: TODO_CONFIGURATION_KEY }]) })
        .then((res) => res.data.elements[0]);
    }

    if (this.storedConfig) {
      try {
        return JSON.parse(this.storedConfig.value);
      } catch (e) {
        this.notificationService.showStandardErrorPopup(
          e,
          "Fehler während dem Einlesen der Aufgaben Konfiguration. Standardwerte werden verwendet."
        );
        return super.getConfiguration();
      }
    } else return super.getConfiguration();
  }
}
