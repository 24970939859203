import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TypeDto } from "@smallstack/axios-api-client";
import { BetaBorderComponent, LoadingElementDirective, StoreRegistry } from "@smallstack/common-components";
import { PageableCrudStore } from "@smallstack/store";
import { LoaderComponent } from "@smallstack/store-components";
import { SearchByFieldMatcher, SearchQuery, TypeSchema } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { filterNullish, flattenJson, isEmptyObject, isNonEmptyString, removeRequired } from "@smallstack/utils";
import { BaseWidgetComponent, FormService, SmallstackFormCoreModule } from "@smallstack/widget-core";
import { BehaviorSubject, debounce, interval } from "rxjs";

export enum FilterBarSockets {
  SEARCH_QUERY = "searchQuery"
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-filter-bar-widget",
  templateUrl: "./filter-bar-widget.component.html",
  styleUrls: ["./filter-bar-widget.component.scss"],
  providers: [{ provide: FormService, useValue: undefined }],
  standalone: true,
  imports: [CommonModule, BetaBorderComponent, LoaderComponent, SmallstackFormCoreModule, LoadingElementDirective]
})
export class FilterBarWidgetComponent extends BaseWidgetComponent {
  protected searchQuery: SearchQuery = {
    fieldSearches: [],
    logicalOperator: "or"
  };
  protected isSearching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected filterOnType$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  protected type: TypeDto;
  protected searchSchema: TypeSchema;
  protected filterValue: any;

  constructor(
    private typeRegistry: TypeService,
    private storeRegistry: StoreRegistry
  ) {
    super();
    this.subscription.add(
      this.data$.pipe(filterNullish({ filterEmptyObjects: true })).subscribe(async (data) => {
        this.isLoading$.next(true);
        if (!isNonEmptyString(data.type)) throw new Error("no store configured");
        this.type = await this.typeRegistry.getTypeByPath(data.type);
        this.searchSchema = removeRequired(this.type.schema);
        this.isLoading$.next(false);
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.filterOnType$
        .pipe(
          filterNullish({ filterEmptyObjects: true }),
          debounce(() => interval(800))
        )
        .subscribe(() => this.filter()())
    );
  }

  protected reset(): void {
    this.searchQuery = { fieldSearches: [], logicalOperator: "and" };
    this.filterValue = {};
    void this.sendSocketData(FilterBarSockets.SEARCH_QUERY, undefined);
    if (this.data()?.globalStoreSearch === true) {
      const store = this.storeRegistry.getStore<PageableCrudStore>(this.data().type);
      if (!store) throw new Error("No store found for type " + this.data().type);
      void store.search(this.searchQuery);
    }
  }

  protected filter() {
    return async (): Promise<void> => {
      this.searchQuery = { fieldSearches: [], logicalOperator: "and" };
      if (!isEmptyObject(this.filterValue)) {
        const flattenedFilterValue = flattenJson(this.filterValue, { flattenArrays: false });
        for (const key in flattenedFilterValue) {
          if (isNonEmptyString(flattenedFilterValue[key]))
            this.searchQuery.fieldSearches.push({
              fieldname: key,
              value: flattenedFilterValue[key],
              matcher: SearchByFieldMatcher.INCLUDES
            });
          else if (flattenedFilterValue[key] instanceof Array && flattenedFilterValue[key].length > 0) {
            this.searchQuery.fieldSearches.push({
              fieldname: key,
              value: flattenedFilterValue[key],
              matcher: SearchByFieldMatcher.IN
            });
          }
        }
      }
      await this.sendSocketData(FilterBarSockets.SEARCH_QUERY, this.searchQuery);
      if (this.data()?.globalStoreSearch === true) {
        const store = this.storeRegistry.getStore<PageableCrudStore>(this.data().type);
        if (!store) throw new Error("No store found for type " + this.data().type);
        await store.search(this.searchQuery);
      }
    };
  }
}
