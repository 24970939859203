import { Directive, OnDestroy, OnInit, effect } from "@angular/core";
import { FormControl, UntypedFormControl, ValidationErrors } from "@angular/forms";
import { isEmptyString } from "@smallstack/utils";
import { distinctUntilChanged } from "rxjs";
import { SchemaFormBaseWidget } from "./schema-form-base-widget.component";

/**
 * This base class creates and maintains a formcontrol
 *
 * @deprecated Use the FormService directly, without ReactiveForms from Angular
 */
@Directive()
export abstract class ReactiveFormBasedInputWidgetComponent extends SchemaFormBaseWidget implements OnDestroy, OnInit {
  public get formControl(): UntypedFormControl {
    if (this.path() === undefined) throw new Error("path is undefined");
    const formControl = this.formService.formGroup.get(this.getFormControlName()) as UntypedFormControl;
    if (!formControl) throw new Error("no form control found for path " + this.getFormControlName());
    return formControl;
  }

  constructor() {
    super();
    effect(() => {
      this.formControl.setValue(this.formService.getValueByPath(this.path()));
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    // create a form control and register it with a unique name at the formService.formGroup
    const formControl = new FormControl();
    this.formService.formGroup.addControl(this.getFormControlName(), formControl);

    // if formControl changes occur, sync them to the formService and trigger validateOnChange
    this.subscription.add(
      formControl.valueChanges.pipe(distinctUntilChanged()).subscribe(async (val) => {
        if (isEmptyString(val)) val = undefined;
        this.setValue(val);
        await this.validateOnChange();
      })
    );

    // if validationErrors occur, set field to error
    this.subscription.add(
      this.formService.getValidationStatusForPath$(this.path()).subscribe((errors) => {
        if (errors?.length > 0) {
          formControl.setErrors(errors);
          formControl.markAsTouched();
          formControl.markAsDirty();
        } else formControl.setErrors(null);
        formControl.updateValueAndValidity({ emitEvent: true });
      })
    );
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formService.formGroup.removeControl(this.getFormControlName());
  }

  protected get errors(): ValidationErrors {
    if (this.path() === undefined) throw new Error("name is undefined");
    return this.formService.formGroup.get(this.path()).errors;
  }

  protected hasErrors(): boolean {
    return (this.formControl.dirty || this.formControl.touched) && this.errors !== null;
  }

  protected getFormControlName(): string {
    return this.path().replace(/\./g, "-");
  }
}
