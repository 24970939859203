@if (duplicateDetection() && duplicates()?.length > 0 && hide() !== true) {
  <div class="rounded border-2 border-warning p-2" data-test="duplicates-search-container">
    <div style="padding: 10px 0px" class="flex flex-row justify-between items-center"
      ><i><smallstack-i18n [data]="duplicateDetection().foundLabel"> </smallstack-i18n></i
      ><button (click)="close()" class="btn btn-primary btn-sm">
        <smallstack-i18n data="@@actions.close"></smallstack-i18n>
      </button>
    </div>
    @for (duplicate of duplicates(); track duplicate.id) {
      <div class="p-1 even:bg-slate-300 flex flex-row space-x-3 justify-between items-center">
        <smallstack-extension-slot
          [name]="extensionSlotName()"
          [highlight]="currentSearchValues()"
          [editMode]="customizationModeStore.value$ | async"
          [context]="duplicate"
        ></smallstack-extension-slot>
        <div style="width: 200px" class="flex flex-col space-y-3">
          @if (duplicateDetection().clickActions?.fill) {
            <button (click)="fillIn(duplicate)" class="btn btn-sm btn-secondary"
              ><smallstack-i18n data="@@components.duplicateSearch.fillinduplicate"></smallstack-i18n
            ></button>
          }
          <button (click)="openLink(duplicateDetection(), duplicate)" class="btn btn-sm btn-secondary"
            ><smallstack-i18n data="@@components.duplicateSearch.openDuplicate"></smallstack-i18n
          ></button>
        </div>
      </div>
    }
    <div class="text-help text-center">Es werden nur die ersten 10 Elemente angezeigt.</div>
  </div>
}
