import { TYPE_MESSAGE_THREADS } from "../typesystem/type-names";
import { TypeSchema } from "../typesystem/type-schema";
import { DataType } from "./data-type";
import { DataTypeStorageTarget } from "./data-type-storage";
import { Model } from "./model";

export interface MessageThreadParticipant {
  access: "read" | "write";
  userId: string;
}

export interface MessageThread extends Model {
  participants: MessageThreadParticipant[];
  isSystemThread: boolean;
}

export const MessageThreadSchema: TypeSchema = {
  type: "object",
  properties: {
    participants: {
      type: "array",
      items: {
        type: "object",
        properties: {
          access: { type: "string", enum: ["read", "write"] },
          userId: { type: "string" }
        },
        required: ["access", "userId"]
      }
    },
    isSystemThread: { type: "boolean" }
  },
  required: ["participants", "isSystemThread"]
};

export const MessageThreadType: DataType = {
  icon: "chat--v1",
  path: TYPE_MESSAGE_THREADS,
  searchableFields: [],
  title: [
    {
      value: "Nachrichten Kanal",
      locale: "de_de"
    },
    {
      value: "Message Thread",
      locale: "en_us"
    }
  ],
  titlePlural: [
    {
      value: "Nachrichten Kanäle",
      locale: "de_de"
    },
    {
      value: "Message Threads",
      locale: "en_us"
    }
  ],
  storage: {
    pull: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: TYPE_MESSAGE_THREADS
      }
    }
  },
  schema: MessageThreadSchema
};
