<div class="grid" [style.grid-template-columns]="'repeat(auto-fill, minmax(' + gridCellWidth() + ', 1fr))'">
  @for (element of elements(); track element) {
    <a (click)="getLink(element.id)" [ngStyle]="{ cursor: data().link ? 'pointer' : 'unset' }">
      <smallstack-widget-child
        [data]="data()"
        (dataChange)="dataChange.emit($event)"
        property="template"
        [context]="element"
      ></smallstack-widget-child>
    </a>
  }
</div>
