/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, HostBinding, inject } from "@angular/core";
import { AxiosApiClient, OrdersApi, TenantDataApi, WorkflowsApi } from "@smallstack/axios-api-client";
import { CountUpOptions } from "@smallstack/common-components";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import {
  CUSTOM_TYPE_PATH_PREFIX,
  F_CHECKIN,
  F_CUSTOM,
  SQBuilder,
  SearchByFieldMatcher,
  T_CHECKIN_VISITOR,
  T_CHECKIN_VISIT_EVENT,
  convertSearchQueryToSelector,
  normalizeTypePath
} from "@smallstack/typesystem";
import { RxEntityStoreService } from "@smallstack/typesystem-client";
import { ArithmeticOperand, calculateArithmeticEquation, isNonEmptyString } from "@smallstack/utils";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";
import { computedAsync } from "ngxtension/computed-async";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-count-widget",
  templateUrl: "./count-widget.component.html",
  styleUrls: ["./count-widget.component.scss"]
})
export class CountWidgetComponent extends BaseWidgetComponent {
  private axiosApiClient = inject(AxiosApiClient);
  private rxEntityStoreService = inject(RxEntityStoreService);
  public enableCountUp: boolean = true;
  public errorMessage: string = undefined;
  public options: CountUpOptions = {
    separator: ".",
    decimal: ",",
    startVal: 0,
    formattingFn: (num: number) => {
      if (this.data().formatAsCurrency)
        return new Intl.NumberFormat("de", {
          style: "currency",
          currency: "EUR",
          compactDisplay: "short",
          notation: "compact"
        })
          .format(num / 100)
          .replace("\xa0", " ");
      else
        return new Intl.NumberFormat("de", {
          compactDisplay: "short",
          notation: "compact",
          unitDisplay: "short"
        }).format(num);
    }
  };

  @HostBinding("style.display")
  public display = "contents";

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        count: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        } as SchemaFormPropertyOptions,
        searchQuery: {
          type: "object",
          title: "Suche",
          "x-schema-form": {
            widget: "searchquery"
          }
        },
        title: { type: "string", title: "Text unter dem Wert" },
        enableCountUp: { type: "boolean", default: true, title: "Animation der Zahlen?" },
        formatAsCurrency: {
          type: "boolean",
          default: false,
          title: "Als Währung formatieren",
          description: "Der Wert muss in Cent vorliegen."
        },
        sumValue: {
          title: "Eigenschaft aufsummieren",
          description:
            "Geben Sie hier eine Eigenschaft an, die Sie gerne aufsummieren würden. Dieser Gesamtwert wird dann anstelle der Anzahl angezeigt.",
          type: "string",
          "x-schema-form": {
            widget: "SchemaPropertySelector",
            typeNameProperty: "count"
          }
        } as SchemaFormPropertyOptions,
        equations: {
          title: "Mathematische Operationen",
          description: "Addieren/subtrahieren Sie hier Werte von der aufsummierten Eigenschaft",
          type: "array",
          items: {
            type: "object",
            properties: {
              operand: {
                type: "string",
                title: "Operant",
                enum: ["add", "subtract", "multiply", "divide"]
              },
              other: {
                type: "string",
                title: "Aufsummierter Wert",
                "x-schema-form": {
                  widget: "SchemaPropertySelector",
                  typeNameProperty: "count"
                }
              } as SchemaFormPropertyOptions
            }
          }
        }
      }
    };
  }

  protected countValue = computedAsync(
    async () => {
      const data = this.data();
      if (!data) return;
      const search: string = data?.searchQuery ? SQBuilder.toBase64String(data?.searchQuery) : undefined;
      this.enableCountUp = data?.enableCountUp;
      if (data?.count) data.count = normalizeTypePath(data.count);
      if (data?.count?.startsWith(CUSTOM_TYPE_PATH_PREFIX)) {
        const type = data?.count.replace(CUSTOM_TYPE_PATH_PREFIX, "");
        if (isNonEmptyString(data.sumValue)) {
          const dataEntryCount = (
            await this.axiosApiClient
              .get(TenantDataApi)
              .getDataSum({ feature: F_CUSTOM, type, page: 1, size: 1, search, property: data.sumValue })
          ).data?.sum;

          if (data.equations instanceof Array) {
            for (const equation of data.equations as Array<{ operand: ArithmeticOperand; other: string }>) {
              const otherCount = await this.axiosApiClient
                .get(TenantDataApi)
                .getDataSum({ feature: F_CUSTOM, type, page: 1, size: 1, search, property: equation.other })
                .then((res) => res.data.sum);
              return calculateArithmeticEquation(dataEntryCount, equation.operand, otherCount);
            }
          }
          return dataEntryCount;
        } else {
          const dataEntryCount = (
            await this.axiosApiClient.get(TenantDataApi).getData({ feature: F_CUSTOM, type, page: 1, size: 1, search })
          ).data?.totalElements;
          return dataEntryCount;
        }
      }

      switch (this.data().count) {
        case "checkin.users":
          return (
            await this.axiosApiClient
              .get(TenantDataApi)
              .getData({ feature: F_CHECKIN, type: T_CHECKIN_VISITOR, size: 1, search })
          ).data?.totalElements;

        case "visits_lastweek": {
          const lastWeekVisits = SQBuilder.asString(
            [
              { fieldname: "id", value: -(1000 * 60 * 60 * 24 * 7), matcher: SearchByFieldMatcher.AFTER_RELATIVE_TIME },
              { fieldname: "type", value: "in", matcher: SearchByFieldMatcher.EQUALS }
            ],
            "and"
          );
          return (
            await this.axiosApiClient
              .get(TenantDataApi)
              .getData({ feature: F_CHECKIN, type: T_CHECKIN_VISIT_EVENT, search: lastWeekVisits, size: 1 })
          ).data?.totalElements;
        }
        case "visits_lastmonth": {
          const lastMonthVisits = SQBuilder.asString(
            [
              {
                fieldname: "id",
                value: -(1000 * 60 * 60 * 24 * 30),
                matcher: SearchByFieldMatcher.AFTER_RELATIVE_TIME
              },
              { fieldname: "type", value: "in", matcher: SearchByFieldMatcher.EQUALS }
            ],
            "and"
          );
          return (
            await this.axiosApiClient
              .get(TenantDataApi)
              .getData({ feature: F_CHECKIN, type: T_CHECKIN_VISIT_EVENT, search: lastMonthVisits, size: 1 })
          ).data?.totalElements;
        }

        case "workflows":
          return (await this.axiosApiClient.get(WorkflowsApi).getWorkflowsCount({ search })).data;
        case "orders":
          return (await this.axiosApiClient.get(OrdersApi).getOrdersCount({ search })).data;
        default: {
          const typeCrud = this.rxEntityStoreService.forType(this.data().count);
          if (typeCrud) return typeCrud.getMany(convertSearchQueryToSelector(search)).length;
          else this.errorMessage = "Typ " + this.data().count + " konnte nicht zusammengezählt werden.";
        }
      }
    },
    { initialValue: 0 }
  );
}
