import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { AllWidgetTags, DISPLAY_AS_CARD, WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  ARRAY_GLOBAL_VARIABLE = "arrayGlobalVariable"
}

export const ArrayGlobalsTableWidget: WidgetRegistryEntry = {
  name: "ArrayGlobalsTable",
  configuration: {
    templateName: "Array Tabelle",
    templateDescription: "Erstellt eine Tabelle aus Array-Daten vom globalen Speicher",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.ARRAY_GLOBAL_VARIABLE]: {
          type: "string",
          title: "Daten Variable",
          description: "Geben Sie hier an, in welcher globalen Variable sich die Daten befinden."
        },
        valuesConfiguration: {
          type: "array",
          title: "Werte Konfiguration",
          items: {
            type: "object",
            properties: {
              label: { type: "string", title: "Titel" },
              value: { type: "string", title: "Wert" },
              formatter: {
                type: "string",
                title: "Formatierung",
                "x-schema-form": {
                  inputWidget: WIDGET_FORM_INPUT_SELECT,
                  values: [
                    {
                      label: "Text",
                      value: "text"
                    },
                    {
                      label: "Datum",
                      value: "date"
                    },
                    {
                      label: "Datum + Uhrzeit",
                      value: "dateTime"
                    },
                    {
                      label: "Währung (Euro)",
                      value: "currency"
                    },
                    {
                      label: "Währung (Cents)",
                      value: "currencyCents"
                    }
                  ]
                }
              } as SchemaFormPropertyOptions,
              prefix: {
                type: "string",
                title: "Text vor dem Wert"
              },
              suffix: {
                type: "string",
                title: "Text nach dem Wert",
                description: "Beispiele: € oder kWh"
              }
            }
          }
        },
        sortBy: {
          title: "Sortieren",
          type: "string"
        },
        compact: {
          type: "boolean",
          title: "Kompakte Darstellung"
        },
        ...DISPLAY_AS_CARD
      }
    },
    tags: AllWidgetTags,
    icon: "grid"
  },
  component: () => import("./array-globals-table-widget.component").then((m) => m.ArrayGlobalsTableWidgetComponent)
};
