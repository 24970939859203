<div class="dialog">
  <div class="dialog-title flex flex-row justify-between gap-4">
    <smallstack-i18n [data]="data.title" />
    <span class="cursor-pointer" mat-dialog-close data-test="close-btn">
      <i class="fa-regular fa-xmark fa-lg"></i>
    </span>
  </div>
  <div class="dialog-content p-2">
    <smallstack-type-details [type]="data.typeDescriptor.typePath" [modelId]="data.modelId" />
  </div>
  <div class="dialog-actions">
    <button class="btn btn-primary" data-test="open-editor" (click)="openEditor()">
      <i class="fa-regular fa-edit"></i>
    </button>
    @if (data.url | nonEmptystring) {
      <button data-test="open-new-window" class="btn btn-primary max-md:hidden" (click)="open(true)"
        >In neuem Fenster öffnen</button
      >
    }
    @if (data.url | nonEmptystring) {
      <button data-test="open" class="btn btn-primary" (click)="open(false)">Öffnen</button>
    }
  </div>
</div>
