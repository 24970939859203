import { PwaIcon, PwaIconSchema } from "./pwa-icon";

export interface PwaConfiguration {
  name: string;
  shortName: string;
  themeColor: string;
  backgroundColor: string;
  favicon: string;
  icons: Array<PwaIcon>;
}

export const PwaConfigurationSchema = {
  type: "object",
  properties: {
    name: {
      type: "string"
    },
    shortName: {
      type: "string"
    },
    themeColor: {
      type: "string"
    },
    backgroundColor: {
      type: "string"
    },
    favicon: {
      type: "string"
    },
    icons: {
      type: "array",
      items: PwaIconSchema
    }
  },
  required: ["name", "shortName", "themeColor", "backgroundColor", "favicon", "icons"]
};
