import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { filterNullish, isEmptyObject } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "DataProducer",
  templateName: "Daten Producer",
  templateDescription: "Produziert Daten und sendet diese an andere Widgets",
  tags: AllWidgetTags,
  icon: "deployment",
  sockets: [{ direction: SocketDtoDirectionEnum.Out, name: "data", type: SocketDtoTypeEnum.Object }],
  dataSchema: {
    type: "object",
    properties: {
      data: {
        title: "Daten",
        description: "Die Daten, welche geschickt werden sollen",
        "x-schema-form": {
          widget: "monaco"
        },
        type: "object",
        default: {}
      }
    }
  }
})
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
  selector: "smallstack-data-producer-widget"
})
export class DataProducerWidgetComponent extends BaseWidgetComponent implements OnInit {
  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.data$.pipe(filterNullish({ filterEmptyObjects: true })).subscribe(async (data) => {
        if (!isEmptyObject(data.data)) await this.sendSocketData("data", data.data);
      })
    );
  }
}
