import { MatPaginatorIntl } from "@angular/material/paginator";
import { LocaleService, TranslationStore } from "@smallstack/i18n-components";

export function PaginatorTranslation(
  translationStore: TranslationStore,
  localeService: LocaleService
): MatPaginatorIntl {
  const paginatorTranslationIntl = new MatPaginatorIntl();

  function translations() {
    paginatorTranslationIntl.itemsPerPageLabel =
      translationStore.translateByKey("pager.itemsperpagelabel") || "pro Seite";
    paginatorTranslationIntl.firstPageLabel = translationStore.translateByKey("pager.firstpagelabel") || "Erste Seite";
    paginatorTranslationIntl.nextPageLabel = translationStore.translateByKey("pager.nextpagelabel") || "Nächste Seite";
    paginatorTranslationIntl.previousPageLabel =
      translationStore.translateByKey("pager.previouspagelabel") || "Vorherige Seite";
    paginatorTranslationIntl.lastPageLabel = translationStore.translateByKey("pager.lastpagelabel") || "Letzte Seite";

    const of = translationStore.translateByKey("pager.of") || "von";

    paginatorTranslationIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) return "0 " + of + " " + length;
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return startIndex + 1 + " – " + endIndex + " " + of + " " + length;
    };
  }

  void localeService.currentLocale$.subscribe(() => {
    translations();
    paginatorTranslationIntl.changes.next();
  });

  return paginatorTranslationIntl;
}
