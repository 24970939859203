<div mat-dialog-content>
  @if (data?.file) {
    @switch (type) {
      @case ("picture") {
        <img class="image" [src]="data.file.url" />
      }
      @case ("pdf") {
        <smallstack-pdf-viewer [src]="data.file.url" />
      }
      @default {
        <iframe [src]="data.file.url"></iframe>
      }
    }
  } @else {
    <mat-label class="text-muted">Datei nicht gefunden</mat-label>
  }
</div>
<div mat-dialog-actions class="flex flex-row justify-end">
  <button mat-dialog-close class="btn btn-primary">
    <smallstack-i18n data="@@actions.close" />
  </button>
</div>
