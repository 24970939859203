/* eslint-disable @typescript-eslint/member-ordering */
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { Logger } from "@smallstack/core-common";
import { LogicalOperator, SQBuilder, SearchByField, SearchByFieldMatcher, SearchQuery } from "@smallstack/typesystem";
import { BehaviorSubject, Observable, combineLatest, from } from "rxjs";
import { first, map, timeout } from "rxjs/operators";
import { Mutex, MutexLibrary } from "synchronized-ts";
import { ObjectStore, StoreIdentifierType } from "./object-store";
import { StoreState } from "./store";

export interface PageableStoreOptions {
  /**
   * Initial value for the store, will be available immediately
   */
  initialValue?: any;

  /**
   * Timeout (in milliseconds) for all loading operations
   */
  timeout?: number;
}

export interface StoreLoadOptions {
  /**
   * if set to true, no loading events will be fired
   */
  silently?: boolean;
}

export interface StorePreloadOptions extends StoreLoadOptions {
  /** If store once got preloaded, it will not trigger the preload again when calling preload. Set force to true if you would still like to preload again! */
  force?: boolean;
}

export interface Pagination {
  totalElements: number;
  totalPages: number;
}

export type CreateSearchOptions = {
  sort?: string;
  page?: number;
  size?: number;
};

const synchronizeGetById = new MutexLibrary<any>();

export abstract class PageableStore<T extends { id?: StoreIdentifierType } = any> extends ObjectStore<T> {
  // paging state
  public readonly pagination$ = new BehaviorSubject<Pagination>({ totalElements: 0, totalPages: 1 });
  public readonly query$ = new BehaviorSubject<ApiQueryRequest>({ page: 1, size: 10 });
  protected readonly currentPageIds$ = new BehaviorSubject<StoreIdentifierType[]>([]);

  public readonly currentSearch$: BehaviorSubject<SearchByField[]> = new BehaviorSubject([]);

  public readonly currentPage$ = combineLatest([this.currentPageIds$, this.value$]).pipe(
    map(([pageIds]) => pageIds.map((pageId) => this.getById(pageId)))
  );

  private preloadMutex = new Mutex();
  public preloaded = false;

  constructor(protected options: PageableStoreOptions = {}) {
    super(options?.initialValue);

    // init with defaults
    if (!options.timeout) options.timeout = 30000;
    this.query$.next({ page: 1, size: 10 });
  }

  public override async reset(
    options: { keepSort: boolean; keepSearch: boolean } = { keepSearch: true, keepSort: true }
  ): Promise<void> {
    this.preloaded = false;
    const currentQuery: ApiQueryRequest = this.query$.value;
    this.query$.next({
      page: 1,
      size: 10,
      search: options?.keepSearch ? currentQuery?.search : undefined,
      sort: options?.keepSort ? currentQuery?.sort : undefined
    });
    if (options.keepSearch === false) this.currentSearch$.next([]);
    await super.reset();
  }

  public async reload(): Promise<void> {
    await this.reset();
    return this.load();
  }

  public async reloadOne(id: StoreIdentifierType): Promise<T> {
    const model = await this.get(id, true);
    if (!model) {
      Logger.error("PageableStore", "reloadOne", "model not found by id: " + id);
      return undefined;
    }
    this.addValue(model);
    return model;
  }

  public async load(options: StoreLoadOptions = { silently: false }): Promise<void> {
    // lock another loading if store is already loading
    if (this.state === StoreState.LOADING) await this.waitForLoaded();
    if (options?.silently !== true) {
      this.setState(StoreState.LOADING);
      this.setLoading();
    }
    try {
      if (!this.options.timeout) this.options.timeout = 30000;
      const page: Page<T> = await from(this.loadModels(this.query$.value))
        .pipe(timeout(this.options.timeout), first())
        .toPromise();
      if (page && page.elements instanceof Array) {
        this.addValues(page.elements);
        this.pagination$.next({
          totalElements: page.totalElements,
          totalPages: page.totalPages
        });
        this.currentPageIds$.next(page.elements.map((elem) => elem.id));
      }
      this.setState(StoreState.LOADED);
    } catch (e) {
      this.setError(e);
      if (this.state === StoreState.LOADING) this.setState(StoreState.INITIAL);
    }
  }

  public override setValue(value: T[] | Promise<T[]>, setLoaded?: boolean): void {
    super.setValue(value, setLoaded);
    if (value instanceof Array) {
      const query = this.query$.value;
      query.size = query.size || value.length || 10;
      query.page = query.page || 1;
      this.query$.next(query);
      this.pagination$.next({
        totalElements: value.length,
        totalPages: 1
      });
      this.currentPageIds$.next(value.map((elem) => elem.id).slice(0, query.size));
    }
  }

  /**
   * Either returns a copy of the model from store or, if not found, from http
   */
  public async get(id: StoreIdentifierType, forceHttp = false): Promise<T> {
    if (id === undefined) {
      throw new Error("id cannot be undefined");
    }
    return synchronizeGetById.by(id as any).sync(async () => {
      try {
        let modelById: T = this.getById(id); // get local value
        if (modelById && !forceHttp) return modelById;
        modelById = await this.loadModels({
          search: SQBuilder.asString([{ fieldname: "_id", value: id, matcher: SearchByFieldMatcher.EQUALS }])
        }).then((page) => page.elements[0]);
        if (modelById) this.addValue(modelById);
        return modelById;
      } catch (e) {
        // not found
        Logger.error("PageableStore", "Error while getting model by id: " + id, e);
      }
    });
  }

  /**
   * This is a very expensive method and should only be used if there is absolutely no other way
   */
  public async preload(options?: StorePreloadOptions): Promise<void> {
    await this.preloadMutex.sync(async () => {
      if (this.preloaded === true && options?.force !== true) return;
      let page: number = 1;
      let totalPages: number;
      do {
        const current = this.query$.value;
        current.page = page;
        current.size = 1000;
        this.query$.next(current);
        await this.load(options);
        page++;
        totalPages = this.pagination$.value.totalPages;
      } while (page <= totalPages);
      // set defaults again
      this.query$.next({ ...this.query$.value, page: 1, size: 10 });
      this.preloaded = true;
    });
  }

  /**
   * This is a very expensive method and should only be used if there is absolutely no other way
   */
  public preload$(options?: StorePreloadOptions): Observable<T[]> {
    return new Observable((subscriber) => {
      void this.preloadMutex.sync(async () => {
        if (this.preloaded === true && options?.force !== true) {
          subscriber.add(this.value$.subscribe((data) => subscriber.next(data)));
          return;
        }
        let page: number = 1;
        let totalPages: number;
        do {
          const current = this.query$.value;
          current.page = page;
          current.size = 1000;
          this.query$.next(current);
          await this.load(options);
          page++;
          totalPages = this.pagination$.value.totalPages;
        } while (page <= totalPages);
        // set defaults again
        this.query$.next({ ...this.query$.value, page: 1, size: 10 });
        this.preloaded = true;
        subscriber.add(this.value$.subscribe((data) => subscriber.next(data)));
      });
    });
  }

  public setPageSize(pageSize: number): void {
    Logger.debug("PageableStore", "Setting page size: " + pageSize);
    const current = this.query$.value;
    current.size = pageSize;
    this.query$.next(current);
  }

  public setPage(pageNumber: number): void {
    Logger.debug("PageableStore", "Setting page: " + pageNumber);
    const current = this.query$.value;
    current.page = pageNumber;
    this.query$.next(current);
  }

  public sortBy(property: string): void {
    const current = this.query$.value;
    current.sort = property;
    current.page = 1;
    this.query$.next(current);
  }

  /** Clones the current store, so mutations won't affect the original store */
  public clone(): PageableStore<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const outer = this;
    class InnerPageableStore extends PageableStore<T> {
      constructor() {
        super();
      }

      protected loadModels(query: ApiQueryRequest): Promise<Page<T>> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return outer.loadModels(query);
      }
    }

    return new InnerPageableStore();
  }

  public createSearch(
    searchQuery: SearchQuery,
    searchOptions: CreateSearchOptions = {},
    autoload: boolean = true
  ): PageableStore<T> {
    if (!searchOptions.page) searchOptions.page = 1;
    if (!searchOptions.size) searchOptions.size = this.query$.value?.size || 10;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const outer = this;
    class InnerPageableStore extends PageableStore<T> {
      constructor() {
        super();
        this.query$.next({
          search: SQBuilder.toBase64String(searchQuery),
          ...searchOptions
        });
        if (autoload) void this.load();
      }

      protected loadModels(query: ApiQueryRequest): Promise<Page<T>> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return outer.loadModels(query);
      }
    }

    return new InnerPageableStore();
  }

  public async searchByFields(searchByFields: SearchByField[], logicalOperator: LogicalOperator = "or"): Promise<void> {
    let currentQuery: ApiQueryRequest = this.query$.value;
    if (currentQuery === undefined) currentQuery = {};
    currentQuery.page = 1;
    currentQuery.search = SQBuilder.asString(searchByFields, logicalOperator);
    this.currentSearch$.next(searchByFields);
    this.query$.next(currentQuery);
    await this.load();
  }

  public async search(searchQuery: SearchQuery): Promise<void> {
    const query = this.query$.value;
    query.page = 1;
    query.search = SQBuilder.toBase64String(searchQuery);
    this.query$.next(query);
    await this.load();
  }

  /** Performs a query via http, does not store the result in the store. Can be used for dynamic searches. */
  public async query(query: SearchQuery): Promise<T[]> {
    const searchStore = this.createSearch(query);
    await searchStore.load();
    return searchStore.value;
  }

  /**
   *
   * @param willBeOneMore if you already know that this request will return one more than you currently have (e.g. cause you just created one), set this to true, otherwise the newly created item might land on a new last page and we're showing the second last here
   */
  public async gotoLastPage(willBeOneMore: boolean = false): Promise<void> {
    const currentQuery = this.query$.value;
    const currentPagination = this.pagination$.value;
    currentQuery.page = currentPagination.totalPages;
    if (willBeOneMore && currentPagination.totalElements / currentPagination.totalPages === currentQuery.size)
      currentQuery.page++;
    this.query$.next(currentQuery);
    await this.load();
  }

  // for crud interface
  public async getMany(query: ApiQueryRequest): Promise<Page<T>> {
    return this.loadModels(query);
  }

  protected abstract loadModels(query: ApiQueryRequest): Promise<Page<T>>;
}
