@if (todoTemplates$ | async; as todoTemplates) {
  <div class="flex flex-col space-y-1">
    @for (todoTemplate of todoTemplates; track todoTemplate) {
      <smallstack-widget-child
        [data]="data()"
        property="template"
        (dataChange)="dataChange.emit($event)"
        [context]="todoTemplate"
      >
      </smallstack-widget-child>
    }
  </div>
} @else {
  <smallstack-i18n [data]="data()?.emptyMessage"></smallstack-i18n>
}

<ng-template #emptyMessage>
  <smallstack-i18n [data]="data()?.emptyMessage"></smallstack-i18n>
</ng-template>
