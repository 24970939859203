import { RGB } from "color-convert/conversions";

export function pickTextColorBasedOnBgColor(
  bgHexColor: string,
  lightColor: string = "#ffffff",
  darkColor: string = "#000000"
): string {
  const color = bgHexColor.charAt(0) === "#" ? bgHexColor.substring(1, 7) : bgHexColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}

export function convertRgbStringToRgb(rgb: string): RGB {
  const regexArray = /rgb\(([0-9]{1,3}),([0-9]{1,3}),([0-9]{1,3})\)/.exec(rgb.replace(/ /g, ""));
  if (regexArray) return [parseInt(regexArray[1]), parseInt(regexArray[2]), parseInt(regexArray[3])];
  return undefined;
}
