import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslationStore } from "@smallstack/i18n-components";
import { InlineTranslation, TypeDescriptor, TypeSchema } from "@smallstack/typesystem";
import { RxEntityStoreService, TypeService } from "@smallstack/typesystem-client";
import {
  TypeDetailsDialogComponent,
  TypeDetailsDialogData
} from "../components/type-details-dialog/type-details-dialog.component";
import {
  TypeEditorDialogComponent,
  TypeEditorDialogData
} from "../components/type-editor-dialog/type-editor-dialog.component";

export interface OpenTypeDetailsDialogOptions {
  /** Will override the default title of the editor dialog */
  title?: string | InlineTranslation;

  /** Will override the default icon of the editor dialog, which is the default icon of the type */
  icon?: string;

  /** Will override the default model of the editor dialog, which is an empty model */
  model?: any;

  /** Will override the default model of the editor dialog, which is an empty model */
  modelId?: string;
}

export interface OpenTypeEditorDialogOptions extends OpenTypeDetailsDialogOptions {
  /** Schema for the editor dialog. If empty, full type schema will be used */
  schema?: TypeSchema;

  /** If set to true, then a delete button will be shown */
  showDeleteButton?: boolean;
}

@Injectable({ providedIn: "root" })
export class TypeDialogService {
  protected rxEntityStoreService = inject(RxEntityStoreService);

  constructor(
    private matDialog: MatDialog,
    private typeService: TypeService,
    private translationStore: TranslationStore
  ) {}

  /** Opens the related editor and returns the edited/created model */
  public async openEditor<T = any>(
    typeDescriptor: TypeDescriptor | string,
    options: OpenTypeEditorDialogOptions = {}
  ): Promise<T> {
    await this.typeService.awaitLoaded();
    if (typeof typeDescriptor === "string") typeDescriptor = { typePath: typeDescriptor };
    const typePath = typeDescriptor.typePath;
    if (options.modelId && !options.model)
      options.model = await this.rxEntityStoreService.forType(typePath).getById(options.modelId);

    const type = this.typeService.getByPath(typePath);
    if (!type) throw new Error("Type not found: " + typePath);
    return new Promise((resolve) => {
      void this.matDialog
        .open(TypeEditorDialogComponent, {
          data: {
            title:
              options?.title ||
              this.translationStore.translate(type?.title) +
                " " +
                (options.model === undefined ? "anlegen" : "bearbeiten"),
            model: options.model,
            typeDescriptor,
            schema: options?.schema,
            showDeleteButton: options?.showDeleteButton
          } as TypeEditorDialogData,
          maxWidth: "95vw",
          maxHeight: "95vh",
          minWidth: "40vw",
          autoFocus: false,
          closeOnNavigation: true
        })
        .afterClosed()
        .subscribe((model) => resolve(model));
    });
  }

  public async openDetail(
    typeDescriptor: TypeDescriptor | string,
    options: OpenTypeDetailsDialogOptions = {}
  ): Promise<any> {
    if (typeof typeDescriptor === "string") typeDescriptor = { typePath: typeDescriptor };
    if (!options.modelId && options.model) options.modelId = options.model.id;
    let title = options.title;
    if (!title) {
      if (!options.model && options.modelId)
        options.model = await this.rxEntityStoreService.forType(typeDescriptor.typePath).getById$(options.modelId);
      if (options.model) title = this.typeService.getRepresentation(typeDescriptor, options.model);
    }
    this.matDialog.open(TypeDetailsDialogComponent, {
      data: {
        modelId: options.modelId,
        typeDescriptor,
        title,
        openEditor: () => this.openEditor(typeDescriptor, options),
        url: await this.typeService.getUrl(typeDescriptor, false)
      } as TypeDetailsDialogData,
      autoFocus: false,
      maxHeight: "80vh"
    });
  }
}
