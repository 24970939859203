import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: "./qr-code-dialog.component.html",
  styleUrls: ["./qr-code-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QRCodeDialogComponent {
  public url: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.url = this.getUrl("png");
  }

  public download(type: string): void {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", this.getUrl(type), true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = "qrcode." + type;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  private getUrl(type: string): string {
    return `https://qr-fn.smallstack.cloud/?code=${this.data}&type=${type}&size=20`;
  }
}
