import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe returns true if given value is not empty, undefined or null
 */
@Pipe({ name: "length", standalone: true })
export class LengthPipe implements PipeTransform {
  public transform(obj: any[], length?: number | number[]): boolean | number {
    if (length === undefined) return obj?.length;
    else if (obj) {
      if (!(length instanceof Array)) length = [length];
      for (const lengthEntry of length) if (obj.length === lengthEntry) return true;
    }
    return false;
  }
}
