<div [ngClass]="{ maximized: maximized() }" class="flex flex-col space-y-3" data-testid="worklog-timer-content">
  <div class="worklog-entry flex flex-row space-x-3 justify-between h-full w-full min-h-full min-w-full">
    <smallstack-icon name="time" size="36" class="basis-full"></smallstack-icon>
    <div style="padding-top: 4px" class="flex flex-col min-w-fit">
      <smallstack-i18n class="font-bold" [data]="label()"></smallstack-i18n>
      <div class="text-slate-400">{{
        currentDeltaTime() | humanizeDuration: { showSeconds: true, shortFormat: true }
      }}</div>
    </div>
    <smallstack-icon
      name="stop-squared"
      size="36"
      (click)="stopped.emit()"
      style="cursor: pointer"
      class="basis-full"
      data-testid="worklog-timer-stop-btn"
    ></smallstack-icon>
  </div>

  @if (maximized() && todo()) {
    <div style="text-align: left">
      <div class="text-base font-bold">Beschreibung</div>
      <div class="text-sm pb-3"><smallstack-i18n [data]="todo().description"></smallstack-i18n></div>
      <smallstack-todo-list-entry
        [isRoot]="true"
        [todo]="todo()"
        (updateFn)="updateFn($event)"
      ></smallstack-todo-list-entry>
    </div>
  }
</div>
