import { Pipe, PipeTransform } from "@angular/core";
import Decimal from "decimal.js";

/** Pipe to use decimal.js */
@Pipe({ name: "decimalJs", standalone: true })
export class DecimalJsPipe implements PipeTransform {
  public transform(val: number, action: "multiply", value: number): number {
    switch (action) {
      case "multiply":
        return new Decimal(val).mul(new Decimal(value)).toNumber();
      default:
        throw new Error("Action " + action + " not yet implemented");
    }
  }
}
