@if (store?.hasError$ | async) {
  <div class="list-container-error flex flex-row justify-between">
    <smallstack-i18n [data]="storeError$ | async"></smallstack-i18n>
    <button (click)="store?.acknowledgeError()" class="btn btn-warning">
      <smallstack-i18n data="@@actions.ok" style="text-transform: uppercase"></smallstack-i18n>
    </button>
  </div>
}

@if (paginatorPosition === PAGINATOR_POSITION.TOP || paginatorPosition === PAGINATOR_POSITION.BOTH) {
  <ng-container *ngTemplateOutlet="pager"></ng-container>
}
<smallstack-loader [loading]="store?.isLoading$ | async" style="color: #898989"></smallstack-loader>
@if (showEmpty && (store?.empty$ | async) && (store?.isLoaded$ | async)) {
  <div>
    @if (hasUserDefinedEmptyContent()) {
      <div>
        <ng-container *ngTemplateOutlet="empty"></ng-container>
      </div>
    } @else {
      <div class="list-container-empty flex flex-col" data-test="empty-list-container">
        <smallstack-icon name="empty-box"></smallstack-icon>
        <smallstack-i18n data="@@components.list.emptymessage"></smallstack-i18n>
      </div>
    }
  </div>
}

<div #legacyNgContent (cdkObserveContent)="projectContentChanged()">
  @if ((store?.isLoaded$ | async) && (!(store?.empty$ | async) || !showEmpty)) {
    <ng-content></ng-content>
  }
</div>
@if ((store?.isLoaded$ | async) && (!(store?.empty$ | async) || !showEmpty)) {
  <ng-container *ngTemplateOutlet="content"></ng-container>
}

<div class="flex flex-row justify-between">
  <div class="flex flex-row items-center">
    <ng-container *ngTemplateOutlet="listActions"></ng-container>
  </div>
  @if (paginatorPosition === PAGINATOR_POSITION.BOTTOM || paginatorPosition === PAGINATOR_POSITION.BOTH) {
    <ng-container *ngTemplateOutlet="pager"></ng-container>
  }
</div>

<ng-template #pager>
  @if (showPager && isPageable) {
    <div
      class="list-paginator no-user-select no-print flex flex-row justify-end items-center"
      data-test="list-container-paginator"
    >
      <mat-paginator
        [length]="totalElements$ | async"
        [disabled]="store?.isLoading$ | async"
        [pageSize]="size$ | async"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex$ | async"
        (page)="changePage($event)"
        [hidePageSize]="isMobile"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
      <button
        color="primary"
        (click)="store?.load()"
        [disabled]="store?.isLoading$ | async"
        data-test="list-container-refresh"
      >
        <smallstack-icon
          name="available-updates--v1"
          size="22"
          theme="material"
          color="757575"
          [ngClass]="{ 'animate-spin': (store?.isLoading$ | async) }"
          class="top-1 relative"
        >
        </smallstack-icon>
      </button>
    </div>
  }
</ng-template>
