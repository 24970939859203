import { Component, Injectable, Type } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface MultiDialogOpenOptions {
  /** Will be mapped to the component before first change detection */
  inputs?: { [inputName: string]: any };
}

@Injectable({ providedIn: "root" })
export class MultiDialogService {
  #currentDialogs$: BehaviorSubject<Type<unknown>[]> = new BehaviorSubject<Type<unknown>[]>([]);
  public currentDialogs$ = this.#currentDialogs$.asObservable();

  public openDialog(component: Type<unknown>, options?: MultiDialogOpenOptions) {
    this.#currentDialogs$.next([...this.#currentDialogs$.value, component]);
  }

  public setDialogRootComponent(comp: Component) {
    // this.#dialogComponentRoot = comp;
  }
}
