<h1 mat-dialog-title>
  <smallstack-icon alias="calendar" size="24" />
  <smallstack-i18n [data]="data?.title" />
</h1>
<mat-dialog-content>
  <p>
    <smallstack-calendar-entry-form [(calendarEntry)]="data.calendarEntry" />
  </p>

  <div class="category flex flex-row space-x-2 items-center">
    <div class="basis-full">
      <smallstack-i18n data="@@components.calendar.entryform.category" />
    </div>
    <mat-form-field style="width: 100%">
      <mat-chip-grid #chipList>
        @for (tag of data.calendarEntry.tags; track tag) {
          <mat-chip-row (removed)="removeTag(tag)">
            {{ tag }}
            <button matChipRemove>
              <smallstack-icon size="22" name="cancel" />
            </button>
          </mat-chip-row>
        }
        <input
          placeholder="+ Kategorie"
          data-test="tag-input"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="[13]"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-grid>
    </mat-form-field>
  </div>
  <div class="category flex flex-row space-x-2 items-center">
    <div class="basis-full">
      <smallstack-i18n data="@@components.links.title" />
    </div>
    <div class="flex flex-col space-y-2 flex-auto">
      <smallstack-linked-models [editable]="true" [width]="'small'" [(links)]="data.calendarEntry.links" />
      <smallstack-extension-slot name="calendar-reverse-links" />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (data?.calendarEntry?.id) {
    <button data-test="delete-calendar-entry-btn" class="btn btn-warning" [loadingFn]="deleteEntry()">
      <smallstack-i18n data="@@actions.delete" />
    </button>
  }
  <button mat-dialog-close class="btn">
    <smallstack-i18n data="@@actions.cancel" />
  </button>
  <button data-test="save-calendar-entry-btn" class="btn btn-primary" [loadingFn]="saveEntry()">
    <smallstack-i18n data="@@actions.save" />
  </button>
</mat-dialog-actions>
