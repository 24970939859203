import { DataType, MessageThreadType, MessageType, UserType } from "../models";
import { FriendType } from "../models/friend";
import { TYPE_FRIENDS, TYPE_MESSAGES, TYPE_MESSAGE_THREADS, TYPE_USERS } from "../typesystem/type-names";

export function getSystemType(typePath: string): DataType {
  switch (typePath) {
    case TYPE_MESSAGES:
      return MessageType;
    case TYPE_MESSAGE_THREADS:
      return MessageThreadType;
    case TYPE_USERS:
      return UserType;
    case TYPE_FRIENDS:
      return FriendType;
  }
}

export function isSystemType(typePath: string): boolean {
  return [TYPE_MESSAGES, TYPE_MESSAGE_THREADS, TYPE_USERS, TYPE_FRIENDS].includes(typePath);
}
