<dialog class="modal" #dialog>
  <div class="modal-box">
    <div class="stack">
      <ng-container #componentHost></ng-container>
    </div>
  </div>
  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>
