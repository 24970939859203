import { Pipe, PipeTransform } from "@angular/core";
import { isNonEmptyString } from "@smallstack/utils";

/**
 * Pipe returns true if given value is not empty, undefined or null
 */
@Pipe({ name: "nonEmptystring", standalone: true })
export class NonEmptyStringPipe implements PipeTransform {
  public transform(val: any): boolean {
    return isNonEmptyString(val);
  }
}
