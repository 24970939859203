import { Pipe, PipeTransform } from "@angular/core";
import { flattenJson, getJsonByPath } from "@smallstack/utils";

/**
 * Pipe to flatten a json object and, additionally, to extract one property
 */
@Pipe({ name: "flatten", standalone: true })
export class FlattenPipe implements PipeTransform {
  public transform(val: unknown, prop?: string): any {
    if (prop) return getJsonByPath(val, prop);
    return flattenJson(val);
  }
}
