import { PageRequest } from "./page-request";

// Pagination
export const DEFAULT_PAGE_SIZE: number = 25;
export const MAX_PAGE_SIZE: number = 1000;

export interface Page<T = unknown> {
  hasNext: boolean;
  hasPrevious: boolean;
  totalElements: number;
  totalPages: number;
  pageRequest: PageRequest;
  elements: T[];
}

export function convertPageElements<T, TT>(p: Page<T>, convert: (t: T) => TT): Page<TT> {
  const r: Page<TT> = {} as Page<TT>;
  r.hasNext = p.hasNext;
  r.hasPrevious = p.hasPrevious;
  r.totalElements = p.totalElements;
  r.totalPages = p.totalPages;
  r.elements = p.elements.map(convert);
  r.pageRequest = p.pageRequest;
  return r;
}

export function createPageFrom<T>(pageElements: T[], totalElements?: number, pageRequest?: PageRequest): Page<T> {
  if (!totalElements) totalElements = pageElements.length;
  if (pageRequest) {
    const p: Page<T> = { elements: pageElements } as any;
    p.totalPages = Math.ceil(totalElements / pageRequest.pageSize);
    if (p.totalPages < 1) p.totalPages = 1;
    p.hasNext = pageRequest.pageNumber * pageRequest.pageSize <= totalElements;
    p.hasPrevious = pageRequest.pageNumber !== 1;
    p.totalElements = totalElements;
    p.pageRequest = pageRequest;
    return p;
  } else {
    const p: Page<T> = { elements: pageElements } as any;
    p.hasNext = false;
    p.hasPrevious = false;
    p.totalElements = p.elements.length;
    p.totalPages = 1;
    p.pageRequest = { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE };
    return p;
  }
}

export function isPage(page: any): page is Page<any> {
  return (
    page !== undefined &&
    page !== null &&
    page.elements !== undefined &&
    page.elements !== null &&
    page.totalElements !== undefined &&
    page.totalElements !== null
  );
}

export async function loadAllPages<T>(apiCallFn: (page: number) => Promise<Page<T>>): Promise<T[]> {
  let page = 1;
  let responsePage: Page<T>;
  const models: T[] = [];
  do {
    responsePage = await apiCallFn(page);
    if (responsePage?.elements instanceof Array) models.push(...responsePage.elements);
    page++;
  } while (responsePage.hasNext);
  return models;
}
