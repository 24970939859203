import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Injector,
  TemplateRef,
  computed,
  inject,
  input,
  signal
} from "@angular/core";
import { ContextService } from "@smallstack/common-components";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { LoadingStateComponent } from "../loading-state/loading-state.component";

/**
 * Displays reactive data within a template.
 * Usage:
 *  ```
 *  <smallstack-rx-models type="customers">
 *    <ng-template let-models="models">
 *      {{ models | json }}
 *    </ng-template>
 *  </smallstack-rx-models>
 *  ```
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-rx-models",
  templateUrl: "./rx-models.component.html",
  standalone: true,
  imports: [CommonModule, LoadingStateComponent]
})
export class RxModelsComponent {
  private injector = inject(Injector);

  public type = input.required<string>();

  protected store = computed(() => {
    if (this.type())
      return injectRxEntityStore(
        { typeDescriptor: { typePath: this.type() }, tenantId: this.contextService.context().tenantId },
        this.injector
      );
  });
  protected models = computed(() => this.store()?.getMany());
  protected message = signal<string>(undefined);
  protected contextService = inject(ContextService);

  @ContentChild(TemplateRef)
  public templateModel: TemplateRef<any>;
}
