@if (isLoading) {
  <span class="component-loading"></span>
} @else {
  @if (!widget) {
    <div class="smallstack-widget-all-pointer-events flex flex-row justify-center items-center">
      <smallstack-add-widget data-test="add-new-widget-btn" (addWidget)="setWidget($event)" />
    </div>
  } @else {
    <div
      class="widget-editor-frame smallstack-widget-all-pointer-events"
      attr.data-test="widget-{{ widget?.name }}"
      [ngClass]="{ root: isRootWidget }"
    >
      <div class="widget-editor-toolbox flex flex-col" data-test="widget-toolbox">
        <div class="flex flex-row justify-between">
          <span class="widget-title">
            <smallstack-icon alias="widget" />
            {{ widget?.name }}
            @if (isRootWidget) {
              <span>[ROOT]</span>
            }
          </span>
          <div class="flex flex-row gap-3 justify-center items-center pr-2">
            @if (widgetConfiguration?.deprecated) {
              <smallstack-icon
                class="cursor-pointer"
                name="uninstalling-updates"
                themeColor="daisyTheme.neutral.textColor"
                size="20"
                (click)="showDeprecation = !showDeprecation"
              />
            }
            <smallstack-icon
              name="fa-trash-can"
              data-test="delete-widget-btn"
              class="text-error cursor-pointer"
              size="20"
              (click)="removeRootComponent()"
            />
            <smallstack-icon
              name="fa-pen-to-square"
              class="cursor-pointer"
              themeColor="daisyTheme.neutral.textColor"
              data-test="edit-widget-btn"
              size="20"
              (click)="editWidget()"
            />
            @if (isRootWidget) {
              <smallstack-icon
                name="fa-satellite-dish"
                class="cursor-pointer"
                themeColor="daisyTheme.neutral.textColor"
                size="20"
                data-test="connection-btn"
                (click)="openConnectionsDialog.emit()"
              />
            }
          </div>
        </div>
        @if (showDeprecation && widgetConfiguration?.deprecated) {
          <div class="deprecated-message"
            ><b class="text-lg">Neue Version verfügbar</b><br />
            Für dieses Widget gibt es eine neuere Version. Um weiterhin volle Kompatibilität gewährleisten zu können,
            wurde die von Ihnen verwendete Version eingefroren. Sobald Sie bereit sind, können Sie auf die neue Version
            aktualisieren. Bitte beachten Sie: Alle Widget-Versionen bleiben für immer erhalten, Sie müssen also nicht
            migrieren!
            @if (widgetConfiguration.deprecatedMessage) {
              <div
                ><p class="mt-2"
                  ><span class="font-semibold">Aktualisierungshinweise</span><br />
                  @if (widgetConfiguration.deprecatedMessage) {
                    <smallstack-i18n style="color: black" [data]="widgetConfiguration.deprecatedMessage" />
                  }</p
              ></div>
            }
            @if (widgetConfiguration.deprecatedActions) {
              <div style="padding-top: 5px" class="flex flex-row space-x-2 justify-end">
                @for (action of widgetConfiguration.deprecatedActions; track action) {
                  <button class="migration-button btn" [loadingFn]="migrateWidget(action)">
                    <smallstack-i18n [data]="action.buttonTitle" />
                  </button>
                }
              </div>
            }
          </div>
        }
      </div>
      @if (widget?.name) {
        <smallstack-widget-renderer
          data-test="cms-widget-content"
          class="h-full"
          [widget]="widget"
          [context]="context()"
          [additionalProviders]="additionalProviders"
          (widgetChange)="widgetChange.emit($event)"
        />
      }
    </div>
  }
}
