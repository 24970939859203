import { ChangeDetectionStrategy, Component, Provider } from "@angular/core";
import { FormService, SchemaFormBaseWidget, WidgetTreeService } from "@smallstack/widget-core";

@Component({
  templateUrl: "./cms-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetTreeService]
})
export class CmsFormInputComponent extends SchemaFormBaseWidget {
  protected additionalProviders: Provider[] = [{ provide: FormService, useValue: undefined }];

  constructor(widgetTreeService: WidgetTreeService) {
    super();
    widgetTreeService.editMode.set(true);
  }
}
