import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";

@Component({
  selector: "smallstack-copy-action-button,smallstack-copy-button",
  templateUrl: "./copy-button.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent]
})
export class CopyButtonComponent {
  @Input() public disabled: boolean = false;
  constructor(
    private notificationService: NotificationService,
    private translationStore: TranslationStore
  ) {}

  public openNotification(): void {
    this.notificationService.notification.success(
      this.translationStore.translateByKey("components.actionbutton.clipboard")
    );
  }
}
