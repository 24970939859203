<div
  class="bg-primary z-50 w-full overflow-hidden print:hidden"
  [ngStyle]="{ height: (height$ | async) + 'px' }"
  [ngClass]="{ fixed: !widgetTreeService.editMode() }"
>
  <div class="text-end opacity-25">
    <smallstack-icon [name]="data().icon" size="184" [ngStyle]="{ top: (iconTop$ | async) + 'px' }"></smallstack-icon>
  </div>
  <h1
    class="text-3xl text-primary-content m-4 font-semibold whitespace-nowrap"
    [ngStyle]="{
      position: 'absolute',
      bottom: widgetTreeService.editMode() ? 'unset' : '0px',
      top: !widgetTreeService.editMode() ? 'unset' : '110px'
    }"
    >{{ text$ | async }}</h1
  >
</div>
@if (!widgetTreeService.editMode()) {
  <div
    class="shrinking-padder print:hidden"
    [ngClass]="{ 'h-36': (manualShrunk$ | async) === false, 'h-16': (manualShrunk$ | async) === true }"
  ></div>
}
<h1 class="text-3xl text-primary m-4 font-semibold whitespace-nowrap hidden print:block">{{ text$ | async }}</h1>
