import { inject, Injectable, Injector, Signal, signal } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { RootSocketDto, WidgetDto } from "@smallstack/axios-api-client";
import { SocketConnection } from "@smallstack/core-common";
import {
  getJsonByPath,
  objectsEqual,
  replaceJsonSubObjectByKeyValue,
  safeStringify,
  setJsonProperty
} from "@smallstack/utils";
import { WidgetComponent } from "../interfaces/widget-component";
import { isSocketAwareWidget } from "../widgets/socket-aware-widget";
import { AllWidgetTags, WidgetTag } from "../widgets/widget.tags";
import { StatefulLazyEventBus } from "./stateful-lazy-event-bus";

export interface WidgetRendererOptions {
  editMode?: boolean;
  widgetTags?: string[];

  /**
   * If the current widget tree is displayed inside a dialog, then this reference will be set. Can be used to close the dialog.
   */
  dialogRef?: MatDialogRef<unknown>;
}

/**
 * A class that gets injected into every widget tree and is only available to its widget children
 */
@Injectable()
export class WidgetTreeService {
  public readonly editMode = signal(false);
  public widgetTags: string[] = [...AllWidgetTags, WidgetTag.ICON, WidgetTag.CONTAINER];

  public rootWidget = signal<WidgetDto>(undefined, { equal: objectsEqual });

  /**
   * Contains all widgets in this widget tree
   */
  public widgets: { [id: string]: WidgetComponent } = {};

  public rootSockets: RootSocketDto[];

  /** @deprecated */
  public socketConnections: SocketConnection[] = [];

  #statefulLazyEventBus = new StatefulLazyEventBus(inject(Injector));

  public publishWidgetEvent<T>(widgetId: string, eventName: string, computedSignal: Signal<T>): void {
    const eventKey = this.createEventNameKey(widgetId, eventName);
    this.#statefulLazyEventBus.publish(eventKey, computedSignal);
  }

  public subscribeToWidgetEvent<T>(widgetId: string, eventName: string): Signal<T> {
    const eventKey = this.createEventNameKey(widgetId, eventName);
    return this.#statefulLazyEventBus.subscribe(eventKey);
  }

  private createEventNameKey(widgetId: string, eventName: string): string {
    return `${widgetId}:${eventName}`;
  }

  #globals = signal<{ [key: string]: any }>({});

  /**
   * @deprecated Please use widget events instead
   *
   * Globals is a store of objects that is available for all widgets in the same widget tree.
   * It can be used for bi-directional communication which does not apply for the widget context for example.
   */
  public globals = this.#globals.asReadonly();

  /**
   * If the current widget tree is displayed inside a dialog, then this reference will be set. Can be used to close the dialog.
   */
  public dialogRef: MatDialogRef<unknown>;

  private afterRegistrationHooks: {
    [componentId: string]: Array<(widget: WidgetComponent) => void | Promise<void>>;
  } = {};

  /**
   * Can be used to apply options from e.g. parent component renderer service
   */
  public applyOptions(options: WidgetRendererOptions): void {
    if (options) {
      if (options.dialogRef) this.dialogRef = options.dialogRef;
      if (options.editMode !== undefined) this.editMode.set(options.editMode);
      if (options.widgetTags) this.widgetTags = options.widgetTags;
    }
  }

  public toWidgetRendererOptions(): WidgetRendererOptions {
    return {
      dialogRef: this.dialogRef,
      editMode: this.editMode(),
      widgetTags: this.widgetTags
    };
  }

  /**
   * Send data in the name of the widget with id `widgetId`, via a socket to other connected widgets, returns a promise which should be awaited if possible
   *
   * @param sourceWidgetId The id of the widget, will be used to identify the widget in the component tree
   * @param sourceSocketName The out socket of your sending widget you wish to communicate over, e.g. "text"
   * @param data The data to send via that socket. Can be empty.
   */
  public async sendSocketData(sourceWidgetId: string, sourceSocketName: string, data?: unknown): Promise<any> {
    this.sendDebugLog(() => "Sending data: " + safeStringify({ sourceWidgetId, sourceSocketName, data }));
    this.sendDebugLog(() => " ⮩ Connections: " + safeStringify(this.socketConnections));
    this.sendDebugLog(
      () =>
        " ⮩ RegisteredWidgetIds: " +
        Object.keys(this.widgets)
          .map((id) => `${id} -> ${this.widgets[id].name}`)
          .join(", ")
    );
    // find all related connections
    if (this.socketConnections instanceof Array) {
      const socketConnections: SocketConnection[] = this.socketConnections.filter(
        (sc) => sc.sourceComponentId === sourceWidgetId && sc.sourceSocketName === sourceSocketName
      );
      if (socketConnections instanceof Array && socketConnections.length > 0) {
        const results = [];
        for (const socketConnection of socketConnections) {
          results.push(
            await this.sendToWidget(socketConnection.targetComponentId, socketConnection.targetSocketName, data)
          );
        }
        return results;
      } else return undefined;
    }
  }

  public registerWidget(id: string, widget: WidgetComponent): void {
    this.widgets[id] = widget;
    if (this.afterRegistrationHooks[id]) for (const fn of this.afterRegistrationHooks[id]) void fn(widget);
  }

  public getWidgetById(id: string): WidgetComponent {
    return this.widgets[id];
  }

  public async sendToWidget(targetWidgetId: string, targetSocketName: string, data: any): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve) => {
      const widget: WidgetComponent = this.getWidgetById(targetWidgetId);
      if (!widget) {
        this.sendDebugLog(
          () => " ⮩ Widget not found by id: " + targetWidgetId + ", parking socketData until it is registered!"
        );
        this.addAfterRegistrationHook(targetWidgetId, async (widgetNowRegistered: WidgetComponent) => {
          resolve(this.handleSendToWidget(widgetNowRegistered, targetSocketName, data));
        });
      } else resolve(await this.handleSendToWidget(widget, targetSocketName, data));
    });
  }

  public getGlobal(key: string): any {
    return getJsonByPath(this.globals(), key);
  }

  public setGlobal(key: string, value: any): void {
    this.#globals.update((globals) => setJsonProperty(globals, key, value));
  }

  public setGlobals(value: any): void {
    this.#globals.set(value);
  }

  public replaceWidget(id: string, widgetTemplate: WidgetDto): void {
    if (!id) this.rootWidget.set(widgetTemplate);
    else this.rootWidget.set(replaceJsonSubObjectByKeyValue(this.rootWidget(), "id", id, widgetTemplate));
  }

  private async handleSendToWidget(widgetNowRegistered: WidgetComponent, targetSocketName: string, data: any) {
    if (isSocketAwareWidget(widgetNowRegistered)) {
      this.sendDebugLog(
        () => " ⮩ To: " + JSON.stringify({ widgetId: widgetNowRegistered.id, socketName: targetSocketName })
      );
      return widgetNowRegistered.handleSocketData(targetSocketName, data);
    }
  }

  private addAfterRegistrationHook(
    widgetId: string,
    fn: (widgetNowRegistered: WidgetComponent) => void | Promise<void>
  ) {
    if (this.afterRegistrationHooks[widgetId] === undefined) this.afterRegistrationHooks[widgetId] = [];
    this.afterRegistrationHooks[widgetId].push(fn);
  }

  private sendDebugLog(fn: () => string): void {
    // eslint-disable-next-line no-restricted-syntax, no-console
    // console.debug("[WidgetTreeService] " + fn());
  }
}
