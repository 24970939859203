import { cloneObject, getJsonByPath, removeLastPropertyName } from "./json-utils";

/**
 * Converts `FirstName` to `First Name` and `firstName` to `first Name`
 */
export function camelCaseToText(str: string): string {
  if (typeof str !== "string") return undefined;

  // put a space before each capital letter but skip the first letter
  return str.charAt(0) + str.substring(1).replace(/[A-Z]/g, (m) => " " + m);
}

export function escapeString(str: string): string {
  return str
    ?.replace(/\n/g, "\\n")
    .replace(/&/g, "\\u0026")
    .replace(/\r/g, "\\r")
    .replace(/\t/g, "\\t")
    .replace(/\f/g, "\\f")
    .replace(/\\/g, "\\")
    .replace(/"/g, '\\"')
    .replace(/'f/g, "\\'");
}

/** Lowercases only the first letter. Useful if you need to convert UpperPascalCase to lowerPascalCase */
export function lowerCaseFirst(str: string): string {
  if (typeof str !== "string") return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

/** Uppercases only the first letter. Useful if you need to convert lowerPascalCase to UpperPascalCase */
export function upperCaseFirst(str: string): string {
  if (typeof str !== "string") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/** Converts "HelloWorld" to "hello_world" */
export function camelCaseToSnakeCase(str: string): string {
  return lowerCaseFirst(str).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/** Converts "hello-world" or "hello_world" to "HelloWorld" */
export function snakeCaseToCamelCase(str: string, lowerCamelCase = false): string {
  const camelCase = str.replace(/([-_][a-zA-Z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
  if (lowerCamelCase) return lowerCaseFirst(camelCase);
  return upperCaseFirst(camelCase);
}

/** returns true if given string is typeof string & not undefined & not null & not '' */
export function isNonEmptyString(str: unknown): boolean {
  return typeof str === "string" && str !== "" && str !== null && str !== undefined && str.trim() !== "";
}

/** returns true if given string is typeof string and equals to "" */
export function isEmptyString(str: unknown): boolean {
  return typeof str === "string" && str === "";
}

export function escapeRegex(str: string): string {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function getTimeAsCharacters(timestamp = Date.now()): string {
  return timestamp
    .toString()
    .split("")
    .map((n) => (Number(n) + 10).toString(36))
    .join("");
}

export function fileNameWithoutExtension(fileName: string): string {
  if (!fileName || !fileName.includes(".")) return fileName;
  return removeLastPropertyName(fileName);
}

/** converts TodoTemplate to todo-template */
export function dasherize(str: string): string {
  return str.replace(/[A-Z]/g, (char: string, index: number) => {
    return (index !== 0 ? "-" : "") + char.toLowerCase();
  });
}

/** returns a random string */
export function randomString(length = 10): string {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function stringEndsWith(str: string, suffix: string): boolean {
  if (!str) return false;
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function createUrlConformIdFromInput(input: string, maxLength?: number): string {
  if (input === undefined) return "";

  // umlauts
  input = input.replace(/[üÜ]/g, "ue");
  input = input.replace(/[äÄ]/g, "ae");
  input = input.replace(/[öÖ]/g, "oe");
  input = input.replace(/[ß]/g, "ss");

  // spaces
  input = input.replace(/ /g, "_");

  input = input.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase();
  if (maxLength) return input.substring(0, maxLength);
  return input;
}

export function createFilenameConformString(
  filename: string,
  options: { defaultCharacter: string } = { defaultCharacter: "_" }
): string {
  if (filename === undefined) return;
  filename = filename.replace(/[ü]/g, "ue");
  filename = filename.replace(/[Ü]/g, "Ue");
  filename = filename.replace(/[ä]/g, "ae");
  filename = filename.replace(/[ä]/g, "Ae");
  filename = filename.replace(/[ö]/g, "oe");
  filename = filename.replace(/[Ö]/g, "Oe");
  filename = filename.replace(/[ß]/g, "ss");
  filename = filename.replace(/\s\s/g, " ");
  filename = filename.replace(/\s\./g, ".");
  filename = filename.replace(/[^a-zA-Z0-9_\-.]/g, options?.defaultCharacter || "_");
  return filename.trim();
}

/** returns a text like "hello hello hi hi (+434 Zeichen)" */
export function truncateString(
  value: string,
  options: { maxLength: number; showCount?: boolean } = { maxLength: 100 }
): string {
  if (typeof value !== "string") return value;
  try {
    if (value.length > options.maxLength) {
      const truncatedString = value.substring(0, options.maxLength);
      if (options.showCount) return `${truncatedString} (+${value.length - options.maxLength} Zeichen)`;
      else return truncatedString + "...";
    } else return value;
  } catch (e) {
    return value;
  }
}

export function countStringOccurrences(str: string, search: string): number {
  return str.split(search).length - 1;
}

export function sortStringWithNumbers(
  objects: any[],
  options?: { subProperty?: string; direction?: "asc" | "desc" }
): any[] {
  if (!objects || objects.length === 0) return objects;
  if (!options) options = {};
  if (!options.direction) options.direction = "asc";
  // eslint-disable-next-line complexity
  return cloneObject(objects).sort((objA, objB) => {
    const a = options?.subProperty === undefined ? objA : getJsonByPath(objA, options.subProperty);
    const b = options?.subProperty === undefined ? objB : getJsonByPath(objB, options.subProperty);
    return sortStringWithNumberComparator(a, b, options.direction);
  });
}

// eslint-disable-next-line complexity
export function sortStringWithNumberComparator(a: string | number, b: string | number, direction = "desc"): number {
  if (a === b) return 0;
  if (a === undefined || a === null || a === "") return 1;
  if (b === undefined || b === null || b === "") return -1;
  if (typeof a === "number" && typeof b === "number") {
    if (direction === "asc") return a - b;
    else return b - a;
  }
  if (typeof a !== "string") a = "" + a;
  if (typeof b !== "string") b = "" + b;
  const aStartsWithString = a.charCodeAt(0) > 57 || a.charCodeAt(0) < 48;
  const bStartsWithString = b.charCodeAt(0) > 57 || b.charCodeAt(0) < 48;
  if (aStartsWithString && !bStartsWithString) return direction === "asc" ? 1 : -1;
  if (!aStartsWithString && bStartsWithString) return direction === "asc" ? -1 : 1;
  if (aStartsWithString && bStartsWithString) {
    if (direction === "asc") return a.localeCompare(b);
    else return b.localeCompare(a);
  }
  const al = a.charCodeAt(a.length - 1);
  const bl = b.charCodeAt(b.length - 1);
  const result =
    parseInt(a) * 10000 +
    (al < 58 ? 0 : al < 91 ? al + 32.5 : al) -
    (parseInt(b) * 10000 + (bl < 58 ? 0 : bl < 91 ? bl + 32.5 : bl));
  if (direction === "asc") return result;
  else return -result;
}
