/**
 * Features & Types
 *
 * Both are PascalCased, singular and simple words that explain the feature/type to the best of our knowledge and belief, without dashes or dots.
 * - Feature: Think of a feature as a name for the feature: pim, customData, workflow, checkin, email etc.
 * - Type: Examples: cms-pages, calendars, files, projects
 */

export const TYPE_APPLICATIONS = "applications";
export const TYPE_AUDIT_LOGS = "audit-logs";
export const TYPE_BOOKED_SUBSCRIPTIONS = "booked-subscriptions";
export const TYPE_CALENDAR_ENTRIES = "calendar-entries";
export const TYPE_CALENDARS = "calendars";
export const TYPE_CHECKIN_APPS = "checkin-apps";
export const TYPE_CHECKIN_EVENTS = "checkin-events";
export const TYPE_CHECKIN_PRINTER_CONFIGURATIONS = "checkin-printer-configurations";
export const TYPE_CHECKIN_VISITORS = "checkin-visitors";
export const TYPE_CMS_PAGES = "cms-pages";
export const TYPE_COMPANIES = "companies";
export const TYPE_COMPOUND_WIDGETS = "compound-widgets";
export const TYPE_CONFIGURATIONS = "configurations";
export const TYPE_CONTACTS = "contacts";
export const TYPE_CONTACTS_CUSTOM_FIELDS = "contact-custom-fields";
export const TYPE_CORPORATIONS = "corporations";
export const TYPE_COUNTERS = "counters";
export const TYPE_COUNTRIES = "countries";
export const TYPE_CRONJOBS = "cronjobs";
export const TYPE_CUSTOM_BACKOFFICES = "custom-backoffices";
export const TYPE_CUSTOM_FIELD_VALUE = "custom-field-value";
export const TYPE_CUSTOM_FIELDS = "custom-fields";
/** @deprecated use TYPE_USERS and get your tenantId straight */
export const TYPE_CUSTOMERS = "customers";
export const TYPE_DASHBOARDS = "dashboards";
export const TYPE_DATA_TEMPLATES = "data-templates";
export const TYPE_DEVICES = "devices";
export const TYPE_EMAIL_TEMPLATES = "email-templates";
export const TYPE_EMAILS = "emails";
export const TYPE_EXTENSION_SLOTS = "extension-slots";
export const TYPE_FILES = "files";
export const TYPE_FORMS = "forms";
export const TYPE_FRIENDS = "friends";
export const TYPE_ICONS = "icons";
export const TYPE_INVOICES = "invoices";
export const TYPE_LANGUAGES = "languages";
export const TYPE_LOCALES = "locales";
export const TYPE_MAILING_CHANNELS = "mailing-channels";
export const TYPE_MAILING_LISTS = "mailing-lists";
export const TYPE_MESSAGES = "messages";
export const TYPE_MESSAGE_THREADS = "message-threads";
/** @deprecated use TYPE_TODOS */
export const TYPE_MY_TODOS = "todo/mine";
/** @deprecated use TYPE_WORKLOGS */
export const TYPE_MY_WORKLOGS = "worklogs/mine";
export const TYPE_NOTES = "notes";
export const TYPE_OPT_INS = "opt-ins";
export const TYPE_ORDERS = "orders";
export const TYPE_PDF_TEMPLATES = "pdf-templates";
export const TYPE_PRICE_ZONES = "price-zones";
export const TYPE_PRODUCTS = "products";
export const TYPE_PROJECTS = "projects";
export const TYPE_REPLACEMENTS = "replacements";
export const TYPE_ROLES = "roles";
export const TYPE_SERVICE_TOKENS = "service-tokens";
export const TYPE_SHOPPING_CARTS = "shopping-carts";
export const TYPE_SUBSCRIPTIONS = "subscriptions";
export const TYPE_SUPPLIERS = "suppliers";
export const TYPE_TASKS = "tasks";
export const TYPE_TODO_SURCHARGES = "todo-surcharges";
export const TYPE_TODO_TEMPLATES = "todo-templates";
export const TYPE_TODOS = "todos";
export const TYPE_TRANSLATIONS = "translations";
export const TYPE_TYPES = "types";
export const TYPE_USER_GROUPS = "user-groups";
export const TYPE_USERS = "users";
export const TYPE_WORKFLOWS = "workflows";
export const TYPE_WORKLOGS = "worklogs";

// custom data types
/** @deprecated Use CUSTOM_TYPE_PATH_PREFIX */
export const CUSTOM_TYPE_PREFIX = "custom_";
export const CUSTOM_TYPE_PATH_PREFIX = "custom/";

export const ALL_TYPE_NAMES = [
  TYPE_APPLICATIONS,
  TYPE_AUDIT_LOGS,
  TYPE_BOOKED_SUBSCRIPTIONS,
  TYPE_CALENDAR_ENTRIES,
  TYPE_CALENDARS,
  TYPE_CHECKIN_APPS,
  TYPE_CHECKIN_EVENTS,
  TYPE_CHECKIN_PRINTER_CONFIGURATIONS,
  TYPE_CHECKIN_VISITORS,
  TYPE_CMS_PAGES,
  TYPE_COMPANIES,
  TYPE_COMPOUND_WIDGETS,
  TYPE_CONFIGURATIONS,
  TYPE_CONTACTS_CUSTOM_FIELDS,
  TYPE_CONTACTS,
  TYPE_CORPORATIONS,
  TYPE_COUNTERS,
  TYPE_COUNTRIES,
  TYPE_CRONJOBS,
  TYPE_CUSTOM_BACKOFFICES,
  TYPE_CUSTOM_FIELD_VALUE,
  TYPE_CUSTOM_FIELDS,
  TYPE_DASHBOARDS,
  TYPE_DATA_TEMPLATES,
  TYPE_DEVICES,
  TYPE_EMAIL_TEMPLATES,
  TYPE_EMAILS,
  TYPE_EXTENSION_SLOTS,
  TYPE_FILES,
  TYPE_FORMS,
  TYPE_FRIENDS,
  TYPE_ICONS,
  TYPE_INVOICES,
  TYPE_LANGUAGES,
  TYPE_LOCALES,
  TYPE_MAILING_CHANNELS,
  TYPE_MAILING_LISTS,
  TYPE_MESSAGES,
  TYPE_NOTES,
  TYPE_OPT_INS,
  TYPE_ORDERS,
  TYPE_PDF_TEMPLATES,
  TYPE_PRICE_ZONES,
  TYPE_PRODUCTS,
  TYPE_PROJECTS,
  TYPE_REPLACEMENTS,
  TYPE_ROLES,
  TYPE_SERVICE_TOKENS,
  TYPE_SHOPPING_CARTS,
  TYPE_SUBSCRIPTIONS,
  TYPE_SUPPLIERS,
  TYPE_TASKS,
  TYPE_TODO_SURCHARGES,
  TYPE_TODO_TEMPLATES,
  TYPE_TODOS,
  TYPE_TRANSLATIONS,
  TYPE_USER_GROUPS,
  TYPE_USERS,
  TYPE_WORKFLOWS,
  TYPE_WORKLOGS
];

export const F_CUSTOM: string = "custom";
export const F_CHECKIN: string = "checkin";
/** @deprecated use the TYPE_ constants instead */
export const T_CHECKIN_VISITOR: string = "visitor";
/** @deprecated use the TYPE_ constants instead */
export const T_CHECKIN_VISIT_EVENT: string = "visitorevent";
/** @deprecated use the TYPE_ constants instead */
export const T_CHECKIN_PRINTER_CONFIGURATIONS: string = "printerconfigurations";
export const F_FORM: string = "forms";
export const F_CMS: string = "cms";
/** @deprecated use the TYPE_ constants instead */
export const T_CMS_PAGE: string = "cmspages";
export const F_APPLICATION = "applications";
/** @deprecated use the TYPE_ constants instead */
export const T_APPLICATION = "application";
export const F_USER = "user";
/** @deprecated use the TYPE_ constants instead */
export const T_USERS = "users";
/** @deprecated use the TYPE_ constants instead */
export const T_CUSTOMERS = "customers";
export const F_OPTIN = "optin";
/** @deprecated use the TYPE_ constants instead */
export const T_OPTIN = "optin";
export const F_CONFIGURATION = "configuration";
/** @deprecated use the TYPE_ constants instead */
export const T_CONFIGURATION = "configuration";
export const F_WORKFLOW = "workflow";
/** @deprecated use the TYPE_ constants instead */
export const T_WORKFLOW = "workflows";
export const F_DEVICE = "devices";
/** @deprecated use the TYPE_ constants instead */
export const T_DEVICE = "devices";
export const F_PDF_TEMPLATES = "pdftemplates";
export const F_PIM = "products";
/** @deprecated use T_PRODUCTS */
/** @deprecated use the TYPE_ constants instead */
export const T_PIM = "products";
/** @deprecated use the TYPE_ constants instead */
export const T_PRODUCTS = "products";
export const F_CUSTOM_BACKOFFICE = "custombackoffices";
/** @deprecated use the TYPE_ constants instead */
export const T_CUSTOM_BACKOFFICE = "custombackoffices";
export const F_SUPPLIER = "suppliers";
/** @deprecated use the TYPE_ constants instead */
export const T_SUPPLIER = "supplier";
export const F_SHOPPING_CART = "shoppingcarts";
/** @deprecated use the TYPE_ constants instead */
export const T_SHOPPING_CART = "shoppingcarts";
export const F_PRICEZONE = "pricezones";
/** @deprecated use the TYPE_ constants instead */
export const T_PRICEZONE = "pricezones";
export const F_ORDER = "orders";
/** @deprecated use the TYPE_ constants instead */
export const T_ORDER = "orders";
export const F_MESSAGE = "messages";
/** @deprecated use the TYPE_ constants instead */
export const T_MESSAGE = "messages";
export const F_MAILING = "mailing";
/** @deprecated use the TYPE_ constants instead */
export const T_MAILING_LISTS = "mailinglists";
/** @deprecated use the TYPE_ constants instead */
export const T_MAILING_CHANNELS = "mailingchannels";
export const F_TASK = "tasks";
/** @deprecated use the TYPE_ constants instead */
export const T_TASK = "task";
export const F_DASHBOARD = "dashboards";
/** @deprecated use the TYPE_ constants instead */
export const T_DASHBOARD = "dashboard";
export const F_CRONJOB = "cronjobs";
/** @deprecated use the TYPE_ constants instead */
export const T_CRONJOB = "cronjob";
export const F_DATATYPE = "datatypes";
/** @deprecated use the TYPE_ constants instead */
export const T_DATATYPE = "datatypes";
export const F_I18N = "internationalization";
/** @deprecated use the TYPE_ constants instead */
export const T_LOCALE = "locale";
/** @deprecated use the TYPE_ constants instead */
export const T_COUNTRY = "country";
/** @deprecated use the TYPE_ constants instead */
export const T_LANGUAGE = "language";
/** @deprecated use the TYPE_ constants instead */
export const T_TRANSLATION = "translations";
export const F_EMAIL = "email";
/** @deprecated use the TYPE_ constants instead */
export const T_EMAIL = "emails";
/** @deprecated use the TYPE_ constants instead */
export const T_EMAIL_TEMPLATE = "emailtemplates";
export const F_IMPEX = "impex";
export const F_CALENDAR = "calendar";
/** @deprecated use the TYPE_ constants instead */
export const T_CALENDARS = "calendars";
/** @deprecated use the TYPE_ constants instead */
export const T_CALENDAR_ENTRIES = "calendar_entries";
export const F_FILE = "files";
/** @deprecated use the TYPE_ constants instead */
export const T_FILE = "files";
export const F_EXTENSION = "extension";
/** @deprecated use the TYPE_ constants instead */
export const T_EXTENSION_SLOTS = "extensionslots";
export const F_CONTACT = "contact";
/** @deprecated use the TYPE_ constants instead */
export const T_CONTACT = "contacts";
/** @deprecated use the TYPE_ constants instead */
export const T_TODOS = "todos";
/** @deprecated use the TYPE_ constants instead */
export const T_TODO_TEMPLATES = "todoTemplates";
/** @deprecated use the TYPE_ constants instead */
export const T_SUBSCRIPTIONS = "subscriptions";
/** @deprecated use the TYPE_ constants instead */
export const T_PDF_TEMPLATES = "pdfTemplates";
/** @deprecated use the TYPE_ constants instead */
export const T_WORKLOGS = "worklogs";
