import { Injector, Signal, WritableSignal, effect, runInInjectionContext, signal, untracked } from "@angular/core";

/**
 * A stateful lazy event bus that allows to publish events before subscribing to them. If the event is published after the subscription, the value will be updated.
 */
export class StatefulLazyEventBus {
  private eventBus: {
    [key: string]: { value: WritableSignal<any>; subscribers: number; producerSignal?: Signal<any> };
  } = {};

  constructor(private injector: Injector) {}

  public clear(): void {
    this.eventBus = {};
  }

  public publish<T>(eventName: string, computedSignal: Signal<T>): void {
    if (!this.eventBus[eventName]) this.eventBus[eventName] = { value: signal(undefined), subscribers: 0 };

    // update the value if there are already subscribers
    if (this.eventBus[eventName].subscribers > 0) {
      untracked(() => {
        runInInjectionContext(this.injector, () => {
          effect(
            () => {
              const value = computedSignal();
              this.eventBus[eventName].value.set(value);
            },
            { allowSignalWrites: true }
          );
        });
      });
    } else {
      this.eventBus[eventName].producerSignal = computedSignal;
    }
  }

  public subscribe<T>(eventName: string): T {
    if (!this.eventBus[eventName]) this.eventBus[eventName] = { value: signal(undefined), subscribers: 0 };
    this.eventBus[eventName].subscribers++;

    // if this is the first subscriber, we need to subscribe to the producer signal
    if (this.eventBus[eventName].subscribers === 1 && this.eventBus[eventName].producerSignal) {
      untracked(() => {
        runInInjectionContext(this.injector, () => {
          effect(
            () => {
              const value = this.eventBus[eventName].producerSignal();
              this.eventBus[eventName].value.set(value);
            },
            { allowSignalWrites: true }
          );
        });
      });
    }

    return this.eventBus[eventName].value.asReadonly()();
  }
}
