/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_TYPES = "types";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_DATE_TIME = "DateTime";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_FILE_URL = "fileurl";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_GOOGLE_MAPS = "googlemaps";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_HTML_SIZING = "htmlsizing";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_ICON = "icon";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_MONACO = "monaco";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_SLIDER = "Slider";
/** @deprecated use new constants form \@smallstack/core-common instead (WIDGET_FILTER_INPUT & WIDGET_FORM_INPUT) */
export const FORM_INPUT_STORES = "stores";
