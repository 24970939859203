export function convertSortStringToNumberDictionary(sort: string): { [key: string]: number } {
  let direction = 1;
  if (sort.startsWith("-")) {
    direction = -1;
    sort = sort.substring(1);
  }
  return { [sort]: direction };
}

export function convertSortStringToAscDescDictionary(sort: string): { [key: string]: "asc" | "desc" } {
  let direction: "asc" | "desc" = "asc";
  if (sort.startsWith("-")) {
    direction = "desc";
    sort = sort.substring(1);
  }
  return { [sort]: direction };
}

export function convertSortStringToAscDesc(sort: string): { key: string; direction: "asc" | "desc" } {
  let direction: "asc" | "desc" = "asc";
  if (sort.startsWith("-")) {
    direction = "desc";
    sort = sort.substring(1);
  }
  return { key: sort, direction };
}

export function convertToSortString(key: string, direction: "asc" | "desc"): string {
  return `${direction === "desc" ? "-" : ""}${key}`;
}
