@if (file) {
  <div>
    <div class="preview">
      <smallstack-file-preview [file]="file" />
    </div>
    <h3 data-test="file-details-name">{{ file.name }}</h3>
    <table style="text-align: left" class="mat-caption align-left">
      <tr>
        <th>
          <smallstack-i18n data="@@backoffice.files.filetype" />
        </th>
        <td class="pad-left">{{ file.type }}</td>
      </tr>
      <tr>
        <th>
          <smallstack-i18n data="@@backoffice.files.createdat" />
        </th>
        <td class="pad-left"><smallstack-date format="medium" [timestamp]="file.createdAt" /></td>
      </tr>
      <tr>
        <th>
          <smallstack-i18n data="@@backoffice.files.lastmodified" />
        </th>
        <td class="pad-left"><smallstack-date format="medium" [timestamp]="file.lastModifiedAt" /></td>
      </tr>
      <tr>
        <th>
          <smallstack-i18n data="@@backoffice.files.license" />
        </th>
        <td class="pad-left" data-test="file-license">{{ file.license }}</td>
      </tr>
      <tr>
        <th>
          <smallstack-i18n data="@@backoffice.files.licenseurl" />
        </th>
        <td class="pad-left" data-test="file-license-url">{{ file.licenseUrl }}</td>
      </tr>
    </table>
    <!-- <h4 style="margin-bottom: 0;">Tags</h4>
    <mat-form-field class="tags">
      <mat-chip-list #chipList aria-label="Tag selection">
        <mat-chip *ngFor="let tag of tags$ | async" [selectable]="true" [removable]="true" (removed)="remove(tag)">
          {{tag}}
          <smallstack-icon alias="cancel" size="24" matChipRemove></smallstack-icon>
        </mat-chip>
        <input #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="add($event)" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
          {{tag}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->
    <div style="margin-top: 20px" class="flex flex-col space-y-4 justify-center items-stretch">
      <a target="_blank" data-test="file-download-link" class="btn btn-outline" [href]="file.url">
        <smallstack-i18n data="@@backoffice.files.openoriginal" />
      </a>
      <button data-test="file-details-copy-url-btn" class="btn btn-outline" [cdkCopyToClipboard]="file?.url">
        <smallstack-i18n data="@@backoffice.files.copydownloadurl" />
      </button>
      <button data-test="file-details-qrcode-btn" class="btn btn-outline" (click)="openQRCode()">
        <smallstack-i18n data="@@backoffice.files.showqr" />
      </button>
      <!-- <button (click)="removeFile(file.id)" data-test="file-details-delete-btn" class="btn btn-warning btn-outline">
      <smallstack-i18n data="@@backoffice.files.deletefile"></smallstack-i18n>
    </button> -->
    </div>
    <!-- <div style="padding-top: 100px" class="flex flex-col justify-center items-stretch">
  <mat-form-field>
    <mat-label>
      <smallstack-i18n data="@@backoffice.files.license"></smallstack-i18n>
    </mat-label>
    <div class="flex flex-row">
      <input matInput [(ngModel)]="license" [disabled]="keyInputLock" data-test="file-license-input" />
      <button class="action-button btn btn-outline" (click)="unlockKeyInput()">
        <smallstack-icon
          [alias]="!keyInputLock ? 'unlock' : 'lock'"
          size="24"
          [color]="!keyInputLock ? 'primary' : 'warn'"
        ></smallstack-icon>
      </button>
    </div>
  </mat-form-field>
  <mat-form-field>
    <mat-label>
      <smallstack-i18n data="@@backoffice.files.licenseurl"></smallstack-i18n>
    </mat-label>
    <div class="flex flex-row">
      <input matInput [(ngModel)]="licenseUrl" [disabled]="keyInputLock" data-test="file-license-url-input" />
      <button class="action-button btn btn-outline" (click)="unlockKeyInput()">
        <smallstack-icon
          [alias]="!keyInputLock ? 'unlock' : 'lock'"
          size="24"
          [color]="!keyInputLock ? 'primary' : 'warn'"
        ></smallstack-icon>
      </button>
    </div>
  </mat-form-field>
</div>
<button [loadingFn]="save(file.id)" data-test="save-file-details-btn" class="btn btn-outline">
  <smallstack-i18n data="@@actions.save"></smallstack-i18n>
</button> -->
  </div>
}

<div class="flex flex-row justify-end mt-4">
  <button class="btn btn-primary" mat-dialog-close>Schließen</button>
</div>
