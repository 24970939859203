@if (isFontAwesomeIcon()) {
  <span data-test="icon"
    ><i
      class="svg-font-awesome {{ iconName() }} fa-fw"
      style="font-size: {{ realSize }}px;--fa-fw-width:{{ realSize }}px"
    ></i
  ></span>
} @else {
  @if (iconName() && theme) {
    @if (showOnlineIcon) {
      <img
        data-test="icon"
        src="https://img.icons8.com/{{ theme }}/{{ size }}/{{ iconName() }}.png"
        [ngStyle]="{
          height: realSize + 'px',
          width: realSize + 'px'
        }"
      />
    } @else {
      @if (theme === "color" || theme === "fluency") {
        <img
          data-test="icon"
          src="assets/icons/{{ theme }}/{{ iconName() }}.svg"
          [ngStyle]="{
            height: realSize + 'px',
            width: realSize + 'px'
          }"
          [ngClass]="{ 'animate-spin': spinning }"
          (error)="onError($event)"
        />
      } @else {
        <div
          data-test="icon"
          style="height:100%;width:100%;-webkit-mask-size: cover;mask-size: cover;-webkit-mask-image: url(assets/icons/{{
            theme
          }}/{{ iconName() }}.svg); mask-image: url(assets/icons/{{ theme }}/{{ iconName() }}.svg);background-color: {{
            color || '#000000'
          }};"
          [ngClass]="{ 'animate-spin': spinning }"
          (error)="onError($event)"
        ></div>
      }
    }

    @if (action) {
      <smallstack-icon
        class="smallstack-sub-icon"
        [name]="action"
        [size]="'' + +realSize * actionSize"
        [theme]="theme"
        [ngStyle]="{
          height: +realSize * actionSize + 'px',
          width: +realSize * actionSize + 'px',
          'border-color': '#' + color
        }"
        [ngClass]="{ 'animate-spin': actionSpinning }"
      />
    }

    @if (badge !== undefined && badge > 0) {
      <div class="indicator absolute top-0 left-0" [ngStyle]="{ height: realSize + 'px', width: realSize + 'px' }">
        <span class="indicator-item badge badge-sm badge-primary p-1" [ngClass]="'badge-' + badgeColor">{{
          calculatedBadge
        }}</span>
      </div>
    }
  }
}
