import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FileDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { FilePreviewComponent } from "../file-preview/file-preview.component";

export type ThumbSize = "s" | "m" | "l";

@Component({
  selector: "smallstack-file-explorer-thumbnail",
  templateUrl: "./file-explorer-thumbnail.component.html",
  styleUrls: ["./file-explorer-thumbnail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FilePreviewComponent, IconComponent, I18nComponent]
})
export class FileExplorerThumbnailComponent {
  @Input() public buttonLabelKey: string;
  @Input() public file: FileDto;
  @Output() public clicked = new EventEmitter<any>();

  public select(): void {
    this.clicked.emit();
  }
}
