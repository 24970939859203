/** HelloWorld */
export const REGEX_PASCAL_CASE: RegExp = /^([A-Z][a-z]+)+$/;

/** HelloWorld123 */
export const REGEX_PASCAL_CASE_WITH_NUMBERS: RegExp = /^([A-Z][a-z0-9]+)+$/;

/** helloWorld */
export const REGEX_CAMEL_CASE: RegExp = /(^[a-z]|[A-Z])[a-z]*/;

/** html-tags */
export const REGEX_HTML_TAG: RegExp = /^[a-z]+[-a-z]*[a-z]$/;

export const REGEX_DATE_STRING = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;

/** 24 hour format, e.g. 14:25 */
export const REGEX_24_HOUR_STRING = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

export const REGEX_HTTPS_URL =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const REGEX_CONFIGURATION_KEY = /^(?!\.)[a-zA-Z0-9.]*[^.]$/;
