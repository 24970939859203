import { Pipe, PipeTransform } from "@angular/core";
import { isInlineTranslation, isInnerTranslation } from "@smallstack/i18n-shared";
import { createUrlConformIdFromInput } from "@smallstack/utils";

/**
 * makes a string url conform, can also be used for data-test strings in cypress/playwright
 */
@Pipe({ name: "urlconform", standalone: true })
export class UrlConformPipe implements PipeTransform {
  public transform(value: any): string {
    if (typeof value === "string") return createUrlConformIdFromInput(value);
    if (isInlineTranslation(value)) return createUrlConformIdFromInput(value[0].value);
    if (isInnerTranslation(value)) return createUrlConformIdFromInput(value.value);
    else return value;
  }
}
