import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES, Logger } from "@smallstack/core-common";
import { DialogPopupButtons, NotificationService } from "@smallstack/i18n-components";
import { RxEntityStoreService } from "@smallstack/typesystem-client";
import { getJsonByPath } from "@smallstack/utils";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { ButtonComponent } from "../../button/button.component";

@Widget({
  name: "DeleteEntityBtn",
  templateName: "Datensatz löschen Button",
  templateDescription: "Zeigt einen Button, mit welchem einen Datensatz gelöscht werden kann.",
  icon: "trash",
  dataSchema: {
    type: "object",
    properties: {
      type: {
        type: "string",
        title: "Datentyp",
        "x-schema-form": {
          widget: "types"
        }
      },
      entityId: {
        type: "string",
        title: "Kontext Variable der ID der Entität"
      },
      ...BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES
    }
  }
})
@Component({
  selector: "smallstack-delete-entity-btn-widget",
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: "./delete-entity-btn-widget.component.html",
  styleUrls: ["./delete-entity-btn-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteEntityBtnWidgetComponent extends BaseWidgetComponent {
  private rxEntityStoreService = inject(RxEntityStoreService);

  constructor(private notificationService: NotificationService) {
    super();
  }

  protected deleteEntity() {
    return async (): Promise<void> => {
      const entityId = getJsonByPath(this.context(), this.data().entityId);
      if (entityId) {
        if (this.data().confirmation === true) {
          const answer = await this.notificationService.popup.confirmation(
            "Löschen?",
            this.data().confirmationText,
            DialogPopupButtons.yesNo
          );
          if (answer === true) await this.deleteEntityForReal(entityId);
        } else await this.deleteEntityForReal(entityId);
      } else Logger.error("DeleteEntityBtnWidget", "No entityId found!");
    };
  }

  private async deleteEntityForReal(id: string): Promise<void> {
    const type = this.rxEntityStoreService.forType(this.data().type);
    await type.deleteEntity(id);
  }
}
