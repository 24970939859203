/* eslint-disable @typescript-eslint/member-ordering */
import { isPlatformBrowser } from "@angular/common";
import { Injectable, PLATFORM_ID, WritableSignal, computed, inject, signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { AxiosApiClient, CurrentUserDto, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { LocalStorageService, deleteAllIndexDbData, getUrlParameterByName } from "@smallstack/client-common";
import { ContextService, EnvironmentKeys, EnvironmentService, RouterUtilService } from "@smallstack/common-components";
import { AxiosInterceptorService, Logger } from "@smallstack/core-common";
import { NotificationService } from "@smallstack/i18n-components";
import { cloneObject, distinctUntilChangedObj, getJsonByPath, setJsonProperty } from "@smallstack/utils";
import { AxiosResponse } from "axios";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";

export enum ProfileProperty {
  LAST_COMPANY_ID = "backoffice.lastCompanyId",
  LAST_PROJECT_ID = "backoffice.lastProjectId",
  ADVANCED_MODE = "backoffice.advancedMode",
  NAVIGATION_FAVORITES = "backoffice.navigationFavorites",
  COMPANY_FAVORITES = "backoffice.companyFavorites",
  PROJECT_FAVORITES = "backoffice.projectFavorites",
  ICON_THEME = "backoffice.iconTheme",
  NAVIGATION_BAR_WIDGET = "backoffice.navigationBarWidget",
  PREVIEW_FEATURES = "backoffice.previewFeatures",
  HELP_INACTIVE_IN_BACKOFFICE = "backoffice.helpInactiveInBackoffice",
  FULL_TRACING_ENABLED = "fullTracingEnabled"
}

@Injectable({ providedIn: "root" })
export class UserService {
  #currentUser: WritableSignal<CurrentUserDto> = signal(undefined);

  public currentUser = this.#currentUser.asReadonly();
  public currentUserId = computed(() => this.currentUser()?.user?.id);

  /** @deprecated use the signal version */
  public currentUser$: Observable<CurrentUserDto> = toObservable(this.#currentUser);
  /** @deprecated use the signal version */
  public currentUserId$: Observable<string> = toObservable(this.currentUserId);

  /** gets fired whenever the user logs out */
  public onLogout$: Subject<void> = new Subject();

  // for sentry
  private replayAdded: boolean;

  public async setLoginToken(token: string, forceReload = false): Promise<void> {
    this.setCurrentUser({ token, ...this.#currentUser() });
    if (forceReload || !this.#currentUser()?.user) await this.reloadUser();
  }

  public getLoginToken(): string {
    return this.#currentUser()?.token;
  }

  /** @deprecated use signal */
  public getCurrentUserSnapshot(): UserDto {
    return this.#currentUser()?.user;
  }
  /** @deprecated use signal */
  public getCurrentUserIdSnapshot(): string {
    return this.#currentUser()?.user?.id;
  }

  public reAuthRequested$ = new BehaviorSubject(false);

  protected router = inject(Router);
  private notificationService: NotificationService = inject(NotificationService);
  private contextService = inject(ContextService);
  private localStorageService = inject(LocalStorageService);
  private environmentService = inject(EnvironmentService);
  private routerUtilService = inject(RouterUtilService);
  private platformId = inject(PLATFORM_ID);

  constructor(private axiosApiClient: AxiosApiClient) {
    // subscribe to authToken query parameter
    if (isPlatformBrowser(this.platformId)) {
      const authToken = getUrlParameterByName("authToken", window.location.href);
      if (authToken) {
        void this.setLoginToken(authToken, true).then(() => {
          void this.routerUtilService.removeQueryParameter("authToken");
        });
      }
    }

    // TODO: Use offline collection
    // load from local storage
    const localStorageUser: string = this.localStorageService.getItem("current-user");
    if (localStorageUser !== null) void this.setCurrentUser(JSON.parse(localStorageUser));
    else void this.setCurrentUser(undefined);

    // install axios interceptors
    const responseHandler = async (response: AxiosResponse) => {
      if (
        response?.status === 401 &&
        response.config.url.startsWith(this.environmentService.get(EnvironmentKeys.API_URL))
      ) {
        await this.logout();
      }
      return response;
    };
    AxiosInterceptorService.addResponseInterceptor(responseHandler, async (error) => {
      await responseHandler(error.response);
      return Promise.reject(error);
    });
  }

  public async logout(options?: { reloadAfterLogout?: boolean }): Promise<void> {
    try {
      AxiosInterceptorService.cancelAllRequests();
      await this.clearCurrentUser();
    } catch (e) {
      Logger.error("UserService", "Error while logging out: ", e);
      throw e;
    }
    this.onLogout$.next();
    AxiosInterceptorService.refreshAbortSignal();
    if (options?.reloadAfterLogout === true) {
      window.location.reload();
    }
  }

  public async reloadUser(): Promise<void> {
    // check user by getting the current user
    await this.axiosApiClient
      .get(UsersApi)
      .getMyUser()
      .then(async (userResponse: AxiosResponse<UserDto>) => {
        if (userResponse.status === 200) this.setCurrentUser({ token: this.getLoginToken(), user: userResponse.data });
        else await this.logout();
      })
      .catch((e) => {
        this.notificationService.notification.error("Sitzung abgelaufen");
        Logger.error("UserService", "Error while logging in with current token: ", e);
        return this.logout();
      });
  }

  public setCurrentUser(currentUser: CurrentUserDto): void {
    if (currentUser) this.localStorageService.setItem("current-user", JSON.stringify(currentUser));
    else this.localStorageService.removeItem("current-user");

    // for legacy reason (e.g. web components from customers rely on this local storage property)
    if (currentUser?.token) this.localStorageService.setItem("login_token", currentUser.token);
    else this.localStorageService.removeItem("login_token");
    this.contextService.updateContext({ userId: currentUser?.user?.id, token: currentUser?.token });
    this.#currentUser.set(currentUser);
    if (currentUser?.user?.profile?.fullTracingEnabled === true) {
      void import("@sentry/angular-ivy").then((angularSentryModule) => {
        angularSentryModule.setUser({
          id: currentUser?.user?.id,
          username: currentUser?.user?.displayName,
          email: currentUser?.user?.primaryEmailAddress
        });
        if (this.replayAdded !== true) {
          void import("@sentry/replay").then((module) => {
            angularSentryModule
              .getCurrentHub()
              ?.getClient()
              ?.addIntegration(
                new module.Replay({
                  maskAllInputs: false,
                  maskAllText: false,
                  networkDetailAllowUrls: [this.environmentService.get(EnvironmentKeys.API_URL)]
                })
              );
            this.replayAdded = true;
            Logger.debug("UserService", "Added Sentry.Replay() integration");
          });
        }
      });
    }
  }

  public async setProfileProperty(profileProperty: ProfileProperty, value: any): Promise<void> {
    const currentUser: CurrentUserDto = this.#currentUser();
    // check if property was updated to a new value
    if (getJsonByPath(currentUser?.user?.profile, profileProperty) === value) return;
    let profile = cloneObject({ ...currentUser?.user?.profile });
    profile = setJsonProperty(profile, profileProperty, value);
    // TODO: Use offline collection
    const updatedUser = (await this.axiosApiClient.get(UsersApi).updateMyProfile({ profile })).data;
    this.setCurrentUser({ token: this.getLoginToken(), user: updatedUser });
  }

  public async patchProfile(partialProfile: { [key: string]: any }): Promise<void> {
    const currentUser: CurrentUserDto = this.#currentUser();
    // check if property was updated to a new value
    let profile = cloneObject({ ...currentUser?.user?.profile });
    profile = { ...profile, ...partialProfile };
    const updatedUser = (await this.axiosApiClient.get(UsersApi).updateMyProfile({ profile })).data;
    this.setCurrentUser({ token: this.getLoginToken(), user: updatedUser });
  }

  public getProfileProperty(profileProperty: ProfileProperty): any {
    return getJsonByPath(this.#currentUser()?.user?.profile, profileProperty);
  }

  public getProfileProperty$<T = any>(propertyName: ProfileProperty): Observable<T> {
    return this.currentUser$.pipe(
      map((userProfile) => getJsonByPath(userProfile?.user?.profile, propertyName) || undefined),
      // Filter all values if they did not change
      distinctUntilChangedObj()
    );
  }

  protected async clearCurrentUser(): Promise<void> {
    this.localStorageService.clear();
    await this.clearOfflineData();
    this.#currentUser.set(undefined);
    Logger.debug("UserService", "Cleared current user");
  }

  private async clearOfflineData() {
    // clear indexDB
    await deleteAllIndexDbData();
    Logger.info("UserService", "Cleared IndexDB data!");
  }
}
