import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { getJsonByPath, isTruthyValue } from "@smallstack/utils";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import { combineLatest } from "rxjs";

@Component({
  templateUrl: "./defined-container-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefinedContainerWidgetComponent extends BaseWidgetComponent {
  public isDefined: boolean = false;

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        contextProperty: {
          type: "string",
          title: "Kontext Variable",
          description:
            "Kontext Variable, auf deren Basis entschieden wird, welche der unten aufgelisteten Komponenten angezeigt wird."
        },
        trueComponent: {
          title: "Objekt definiert",
          description: "Komponente welche angezeigt wird, wenn die Eigenschaft vorhanden ist.",
          type: "object",
          "x-schema-form": { widget: "cms" }
        },
        falseComponent: {
          title: "Objekt nicht definiert",
          description: "Komponente welche angezeigt wird, falls die Eigenschaft nicht definiert ist.",
          type: "object",
          "x-schema-form": { widget: "cms" }
        }
      }
    };
  }

  constructor() {
    super();
    this.subscription.add(
      combineLatest([this.context$, this.data$]).subscribe(([context, data]) => {
        if (data?.contextProperty) {
          const contextProperty = getJsonByPath(context, data.contextProperty);
          this.isDefined = isTruthyValue(contextProperty);
          this.cdr.markForCheck();
        }
      })
    );
  }
}
