import { isNonEmptyString } from "@smallstack/utils";

export function createCookie(name: string, value: string, days?: number): void {
  let expires: string;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toISOString();
  } else expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function readCookie(name: string): string | undefined {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      const content = c.substring(nameEQ.length, c.length);
      if (isNonEmptyString(content)) return content;
      else return undefined;
    }
  }
  return undefined;
}

export function deleteCookie(name: string): void {
  createCookie(name, "", -1);
}

export function cookieExists(name: string): boolean {
  return readCookie(name) !== undefined;
}
