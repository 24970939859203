import { Injectable, Injector, inject } from "@angular/core";
import { ContextService } from "@smallstack/common-components";
import { TypeDescriptor } from "@smallstack/typesystem";
import { StoreEntity, StoreInitOptions } from "../stores/entity.store";
import { RxEntityStore } from "../stores/rx-entity.store";

@Injectable({ providedIn: "root" })
export class RxEntityStoreService {
  #cache = new Map<string, RxEntityStore<any>>();
  #contextService = inject(ContextService);
  #injector = inject(Injector);

  public getStore<Entity extends StoreEntity>(options: StoreInitOptions): RxEntityStore<Entity> {
    if (!options) throw new Error("options are required");
    if (!options.typeDescriptor) throw new Error("typeDescriptor is required");
    if (!options.tenantId) options.tenantId = this.#contextService.context().tenantId;
    const key = `${options.tenantId}/${options.typeDescriptor.typePath}`;
    if (!this.#cache.has(key)) {
      this.#cache.set(key, new RxEntityStore(this.#injector, options));
    }
    return this.#cache.get(key);
  }

  public forType<Entity>(type: TypeDescriptor | string): RxEntityStore<Entity> {
    if (typeof type === "string") type = { typePath: type };
    return this.getStore<Entity>({
      typeDescriptor: type,
      tenantId: this.#contextService.context().tenantId
    });
  }
}
