import { Pipe, PipeTransform } from "@angular/core";
import { TypeService } from "../services/type.service";

/** Returns the icon for the given type */
@Pipe({ name: "typeIcon", standalone: true })
export class TypeIconPipe implements PipeTransform {
  constructor(private typeService: TypeService) {}

  public async transform(typePath: string): Promise<string> {
    const type = await this.typeService.getTypeByPath(typePath);
    if (!type) throw new Error("No type found for " + typePath);
    return type.icon;
  }
}
