<div class="flex flex-col md:flex-row flex-wrap space-x-25 justify-evenly items-center">
  @for (block of data()?.blocks; track block) {
    <div class="flex flex-col space-y-3 items-center basis-full">
      <smallstack-icon [name]="block.icon" [theme]="data().theme" [size]="data().iconSize"></smallstack-icon>
      <smallstack-i18n [data]="block.header" [ngStyle]="{ 'font-size': data().headerSize + 'px' }"> </smallstack-i18n>
      <smallstack-i18n
        [data]="block.text"
        [ngStyle]="{ 'font-size': data().textSize + 'px' }"
        style="text-align: justify; text-align-last: center"
      >
      </smallstack-i18n>
    </div>
  }
</div>
