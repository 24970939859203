import { FORM_INPUT_ICON } from "@smallstack/form-shared";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  CONTEXT_VARIABLE_NAME = "contextVariableName",
  ICON = "icon"
}

export const UnreadIndicatorWidget: WidgetRegistryEntry = {
  name: "UnreadIndicator",
  configuration: {
    templateName: "Ungelesene Einträge",
    templateDescription: "Zeigt die Anzahl ungelesener Einträge an",
    icon: "envelope-number",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.CONTEXT_VARIABLE_NAME]: {
          type: "string",
          title: "Kontext Variable",
          description:
            "Geben Sie hier den Namen der Kontext Variable an, die das Array mit möglichen ungelesenen Einträgen enthält."
        },
        [CONFIGURATION_KEYS.ICON]: {
          type: "string",
          title: "Icon",
          description: "Geben Sie hier das Icon an, welches angezeigt werden soll.",
          "x-schema-form": {
            widget: FORM_INPUT_ICON
          }
        }
      }
    }
  },
  component: () => import("./unread-indicator-widget.component").then((m) => m.UnreadIndicatorWidgetComponent)
};
