import { Injectable } from "@angular/core";
import { AxiosApiClient, CalendarApi, CalendarEntryDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class CalendarEntriesStore extends PageableCrudStore<CalendarEntryDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<CalendarEntryDto>> {
    const res = await this.axiosApiClient.get(CalendarApi).getCalendarEntries(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<CalendarEntryDto> {
    const res = await this.axiosApiClient.get(CalendarApi).getCalendarEntry({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(CalendarApi).deleteCalendarEntry({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(CalendarApi).deleteManyCalendarEntries({ ids });
    return res.data;
  }
  protected async createModel(model: CalendarEntryDto): Promise<CalendarEntryDto> {
    const res = await this.axiosApiClient.get(CalendarApi).createCalendarEntry({ calendarEntry: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<CalendarEntryDto>): Promise<CalendarEntryDto> {
    const res = await this.axiosApiClient.get(CalendarApi).patchCalendarEntry({ id, calendarEntry: model });
    return res.data;
  }
  protected async putModel(model: CalendarEntryDto): Promise<CalendarEntryDto> {
    const res = await this.axiosApiClient.get(CalendarApi).putCalendarEntry({ id: model.id, calendarEntry: model });
    return res.data;
  }
}
