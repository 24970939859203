import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { WidgetDto } from "@smallstack/axios-api-client";
import { HighlightDirective, LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { IconComponent, InfoBoxComponent } from "@smallstack/theme-components";
import { TypeSchema } from "@smallstack/typesystem";
import { cloneObject, getPromiseProviderValue, getSchemaDefaults, isNonEmptyString } from "@smallstack/utils";
import Fuse from "fuse.js";
import { Subscription } from "rxjs";
import { LazyLoadingWidgetConfiguration, WidgetRegistry, WidgetRegistryEntry } from "../../../services/widget.registry";
import { WidgetTag } from "../../../widgets/widget.tags";

export interface AddNewWidgetDialogData {
  allowedWidgetTags: string[];
  saveFn: (newWidget: WidgetDto) => Promise<void>;
}

export interface WidgetTagWithCount {
  name: string;
  count: number;
}

type WidgetRegistryEntryWithEvaluatedConfig = WidgetRegistryEntry & {
  evaluatedConfiguration: LazyLoadingWidgetConfiguration;
};

@Component({
  selector: "smallstack-add-new-widget-dialog",
  templateUrl: "./add-new-widget-dialog.component.html",
  styleUrls: ["./add-new-widget-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nComponent,
    LoadingElementDirective,
    HighlightDirective,
    IconComponent,
    InfoBoxComponent,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule
  ]
})
export class AddNewWidgetDialogComponent implements OnInit, OnDestroy {
  public allWidgets: Array<WidgetRegistryEntryWithEvaluatedConfig>;
  public filteredWidgets: Array<WidgetRegistryEntryWithEvaluatedConfig> = [];
  public allWidgetTagsWithCounts: WidgetTagWithCount[];
  public widgetTagFilters: string[];
  public filterText: string;
  public detailViewWidget: WidgetRegistryEntryWithEvaluatedConfig;
  public showDeprecatedWidgets: boolean = false;

  private subscription = new Subscription();

  constructor(
    public matDialogRef: MatDialogRef<AddNewWidgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddNewWidgetDialogData,
    private widgetRegistry: WidgetRegistry,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
    this.widgetTagFilters = cloneObject(data.allowedWidgetTags) || [
      WidgetTag.APPLICATION,
      WidgetTag.CBO,
      WidgetTag.CMS,
      WidgetTag.ICON,
      WidgetTag.CONTAINER
    ];
  }

  public ngOnInit(): void {
    this.allWidgetTagsWithCounts = [];
    void this.loadWidgets();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public showDeprecated(changeEvent: MatCheckboxChange): void {
    this.showDeprecatedWidgets = changeEvent.checked;
    void this.filterWidgets();
  }

  public async toggleFilter(widgetTag: string): Promise<void> {
    if (this.widgetTagFilters.includes(widgetTag))
      this.widgetTagFilters.splice(this.widgetTagFilters.indexOf(widgetTag), 1);
    else {
      // if (this.data.allowedWidgetTags && !this.data.allowedWidgetTags.includes(widgetTag)) {
      //   const result = await this.notificationService.popup.confirmation(
      //     "Nicht empfohlener Tag",
      //     "Es wird nicht empfohlen, diesen Tag im aktuellen Bereich zu verwenden. Bitte verwenden Sie Widgets aus nicht empfohlenen Tags nur, wenn Sie wissen, was Sie tun. Die Benutzerfreundlichkeit des Backoffices könnte dadurch in Mitleidenschaft gezogen werden.",
      //     [
      //       { label: "@@actions.cancel", value: false },
      //       { label: "@@actions.accept", value: true, color: "warn" }
      //     ]
      //   );
      //   if (result === true) this.widgetTagFilters.push(widgetTag);
      // } else
      this.widgetTagFilters.push(widgetTag);
    }
    await this.filterWidgets();
    this.cdr.markForCheck();
  }

  public async filterWidgets(): Promise<void> {
    this.filteredWidgets = [];

    // filter out switches
    const filteredWidgetsLocal = this.allWidgets.filter((widget) => {
      if (!this.appliesToWidgetTagFilter(widget.evaluatedConfiguration.tags)) return false;
      if (widget.evaluatedConfiguration.deprecated === true && this.showDeprecatedWidgets !== true) return false;
      return true;
    });

    // filter by text search
    if (isNonEmptyString(this.filterText)) {
      const fuse = new Fuse(filteredWidgetsLocal, {
        keys: [
          { name: "evaluatedConfiguration.templateName", weight: 10 },
          { name: "evaluatedConfiguration.templateDescription", weight: 8 },
          { name: "name", weight: 3 }
        ]
      });
      this.filteredWidgets = fuse.search(this.filterText).map((s) => s.item);
    } else {
      // else just sort
      this.filteredWidgets = filteredWidgetsLocal.sort((a, b) => {
        return a.evaluatedConfiguration.templateName?.localeCompare(b?.evaluatedConfiguration.templateName);
      });
    }
    this.cdr.markForCheck();
  }

  public addWidget(item: WidgetRegistryEntryWithEvaluatedConfig) {
    return async (): Promise<void> => {
      const newWidget: WidgetRegistryEntryWithEvaluatedConfig = cloneObject(item);
      const schema = newWidget.evaluatedConfiguration?.dataSchema
        ? await getPromiseProviderValue(newWidget.evaluatedConfiguration.dataSchema)
        : undefined;
      const defaults: TypeSchema = schema ? getSchemaDefaults(schema) : {};
      await this.data.saveFn({
        name: newWidget.name,
        data: { ...defaults, ...newWidget.evaluatedConfiguration?.data },
        meta: newWidget.evaluatedConfiguration?.meta
      } as WidgetDto);
      this.matDialogRef.close();
    };
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public showDetailView(widget: WidgetRegistryEntryWithEvaluatedConfig): void {
    this.detailViewWidget = widget;
  }

  public closeDetailView(): void {
    this.detailViewWidget = undefined;
  }

  public clearSearch(): void {
    this.filterText = undefined;
    void this.filterWidgets();
  }

  private async loadWidgets() {
    this.allWidgets = await Promise.all(
      this.widgetRegistry.getAllWidgets().map(async (entry) => {
        const evaluatedConfiguration = (await this.widgetRegistry.getWidgetConfigurationByName(entry.name)) || {};
        return { ...entry, evaluatedConfiguration };
      })
    );
    for (const widget of this.allWidgets)
      if (widget.evaluatedConfiguration.tags instanceof Array)
        for (const widgetTag of widget.evaluatedConfiguration.tags) {
          if (
            this.allWidgetTagsWithCounts.find((widgetTagsWithCount) => widgetTagsWithCount.name === widgetTag) ===
            undefined
          )
            this.allWidgetTagsWithCounts.push({ name: widgetTag, count: 0 });
          this.allWidgetTagsWithCounts.find((widgetTagsWithCount) => widgetTagsWithCount.name === widgetTag).count++;
        }
    await this.filterWidgets();
  }

  private appliesToWidgetTagFilter(tags: string[]): boolean {
    if (tags instanceof Array)
      for (const filteredTag of this.widgetTagFilters) {
        if (tags.includes(filteredTag)) return true;
      }
    return true;
  }
}
