@if (store) {
  <smallstack-list-container [store]="store">
    <div class="grid">
      @for (element of store.currentPage$ | async; track element) {
        <a
          (click)="getLink(element.id)"
          queryParamsHandling="merge"
          [ngStyle]="{ cursor: data().link ? 'pointer' : 'unset' }"
        >
          <div [innerHTML]="getProcessedTemplate(element)"></div>
        </a>
      }
    </div>
  </smallstack-list-container>
}
