export interface ApplicationDomain {
  domain: string;
  txtVerified?: boolean;
  cnameVerified?: boolean;
}

export const ApplicationDomainSchema = {
  type: "object",
  properties: {
    domain: {
      type: "string"
    },
    txtVerified: {
      type: "boolean"
    },
    cnameVerified: {
      type: "boolean"
    }
  },
  required: ["domain"]
};
