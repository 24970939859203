/* eslint-disable complexity */
import { Injectable } from "@angular/core";
import { AjaxError } from "rxjs/ajax";

@Injectable({ providedIn: "root" })
export class ErrorMessageHandler {
  public handleMessage(message: any): string {
    if (!message) return undefined;
    if (typeof message === "string") return message;
    if (message.rejection) return this.handleMessage(message.rejection);

    // axios error?
    if (typeof message.response === "object") {
      // check valid objects
      for (const prop of ["body", "data", "error"]) {
        if (!message.response[prop]) continue;
        if (typeof message.response[prop] === "string") {
          try {
            message.response[prop] = JSON.parse(message.response[prop]);
          } catch (e) {
            //
          }
          if (
            typeof message.response[prop].statusCode === "number" ||
            typeof message.response[prop].status === "number"
          )
            return this.handleObject(message.response[prop]);
        }
        if (message.response[prop] instanceof Blob) {
          return this.handleObject(message.response[prop].text());
        }
        if (typeof message.response[prop] === "object") {
          if (
            typeof message.response[prop].statusCode === "number" ||
            typeof message.response[prop].status === "number"
          )
            return this.handleObject(message.response[prop]);
        }
        if (typeof message.response[prop] === "string") return message.response[prop];
      }
      return this.handleObject(message.response);
    }

    if (message instanceof AjaxError && typeof message.response === "object")
      return this.handleObject(message.response);
    if (message.error instanceof AjaxError && typeof message.error.response === "object")
      return this.handleObject(message.error.response);

    if (message instanceof Error) {
      if (typeof message.message === "object") return this.handleObject(message.message);
      if (typeof message.message === "string") {
        try {
          message.message = JSON.parse(message.message);
          return this.handleObject(message.message);
        } catch (e) {
          // can happen
        }
      }

      return `${message.message}`;
    }

    // try json first
    try {
      return this.handleObject(JSON.parse(message));
    } catch (e) {
      // continue
    }

    // an array with any kind of errors
    if (message instanceof Array) return this.handleArray(message);

    // an object with any kind of error
    if (typeof message === "object") return this.handleObject(message);
    return message;
  }

  private handleObject(obj: any): string {
    // axios error?
    if (obj.data?.message instanceof Array) return this.handleArray(obj.data.message);
    if (obj.message instanceof Array) return this.handleArray(obj.message);

    if (typeof obj === "string" || typeof obj === "number") return "" + obj;

    if (obj.i18nKey) return "@@errors." + obj.i18nKey;

    if (obj.supportCode) return `@@errors.${obj.code}`;

    if (typeof obj.response === "object" && typeof obj.response.error === "string")
      return this.handleObject(obj.response);

    if (obj.statusCode) {
      if (obj.message) {
        let msg: string | string[] = obj.message;
        if (msg instanceof Array) msg = this.handleArray(msg);
        return msg;
      } else return `@@errors.http.${obj.statusCode}`;
    }

    if (typeof obj.error === "object") return this.handleObject(obj.error);

    if (obj.statusText) return obj.statusText;

    if (obj.responseText) return obj.responseText;

    if (typeof obj.message === "string") return obj.message;

    if (obj.error) return obj.error;

    if (obj.errors instanceof Array) return this.handleArray(obj.errors);

    if (typeof obj.reason === "object") return this.handleObject(obj.reason);

    if (obj.reason) return obj.reason;

    if (typeof obj.details === "object") return this.handleObject(obj.details);

    if (obj.details) return obj.details;

    if (typeof obj.detail === "object") return this.handleObject(obj.detail);

    if (obj.detail) return obj.detail;

    if (obj.key && obj.replacers) return obj;

    // JSON Standard Error?
    if (obj.code) {
      let text: string;
      if (typeof obj.code === "string") text = "@@errors." + obj.code;
      else text = JSON.stringify(obj.code);
      if (obj.source && obj.source.attributes instanceof Array) text += ": " + obj.source.attributes.join(", ");
      return text;
    }

    // Validation Error?
    if (obj.target && obj.property && obj.constraints) {
      const constraints: string[] = [];
      for (const constraint in obj.constraints)
        if (obj.constraints[constraint]) {
          constraints.push("@@errors.validations." + constraint.toLowerCase());
        }
      return "@@errors.validations.validation_failed_message " + obj.property + ": \n - " + constraints.join("\n - ");
    }

    if (obj.data?.response?.message)
      return `@@errors.validations.validation_failed_message ${obj.data.response.message}`;

    return "@@errors.validations.validation_failed_message " + JSON.stringify(obj);
  }

  private handleArray(arr: any[]): string {
    return arr.map((val: any) => this.handleMessage(val)).join("\n");
  }
}
