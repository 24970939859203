import { IOC } from "./IOC";

export type LoggerLevel = "info" | "debug" | "error" | "warn";

export class Logger {
  public static instance(): Logger {
    if (IOC.isRegistered("logger")) return IOC.get("logger");
    const logger: Logger = new Logger();
    IOC.register("logger", logger);
    return logger;
  }

  public static on(level: LoggerLevel, callback: (moduleName: string, message: string, stacktrace?: any) => void) {
    return Logger.instance().on(level, callback);
  }

  public static log(level: LoggerLevel, moduleName: string, message: string, stacktrace?: any) {
    return Logger.instance().log(level, moduleName, message, stacktrace);
  }

  public static info(moduleName: string, message: string, stacktrace?: any) {
    return Logger.instance().info(moduleName, message, stacktrace);
  }

  public static warning(moduleName: string, message: string, stacktrace?: any) {
    return Logger.instance().warning(moduleName, message, stacktrace);
  }

  public static error(moduleName: string, message: string, stacktrace?: any) {
    return Logger.instance().error(moduleName, message, stacktrace);
  }

  public static debug(moduleName: string, message: string, stacktrace?: any) {
    return Logger.instance().debug(moduleName, message, stacktrace);
  }

  public static loggingModule(level: LoggerLevel, moduleName: string) {
    return Logger.instance().loggingModule(level, moduleName);
  }

  public static printSmallstackLogo() {
    return Logger.instance().printSmallstackLogo();
  }

  public static addDebugModule(debugModuleName: string) {
    return Logger.instance().addDebugModule(debugModuleName);
  }

  public static getDebugModules(): string[] {
    return Logger.instance().getDebugModules();
  }

  public static resetDebugModules() {
    return Logger.instance().resetDebugModules();
  }

  public static getLoggerMaxLength(): number {
    return Logger.instance().getLoggerMaxLength();
  }

  public static setLoggerMaxLength(maxLength: number) {
    return Logger.instance().setLoggerMaxLength(maxLength);
  }

  public loggerDebugModules: string[] = [];
  public loggerMaxLength: number = 10;
  public loggerCallbacks: { [level: string]: Array<(moduleName: string, message: string, stacktrace?: any) => void> } =
    {};

  public on(level: LoggerLevel, callback: (moduleName: string, message: string, stacktrace?: any) => void) {
    if (this.loggerCallbacks[level] === undefined) this.loggerCallbacks[level] = [];
    this.loggerCallbacks[level].push(callback);
  }

  public log(level: LoggerLevel, moduleName: string, message: string, stacktrace?: any) {
    if (this.loggingModule(level, moduleName)) {
      if (this.loggerCallbacks[level] instanceof Array) {
        for (const cb of this.loggerCallbacks[level]) {
          cb(moduleName, message, stacktrace);
        }
      }
      if (moduleName.length > this.getLoggerMaxLength()) this.setLoggerMaxLength(moduleName.length);
      const delta = this.getLoggerMaxLength() - moduleName.length;
      if (delta > 0) {
        for (let i = 0; i < delta; i++) {
          moduleName = moduleName + " ";
        }
      }

      const consoleLevel: string = level; //=== "debug" ? "log" : level;
      if (typeof console !== "undefined" && typeof (console as any)[consoleLevel] === "function") {
        if (stacktrace) (console as any)[consoleLevel](`${moduleName} ${message}`, stacktrace);
        else (console as any)[consoleLevel](`${moduleName} ${message}`);
      }
    }
  }

  public info(moduleName: string, message: string, stacktrace?: any) {
    this.log("info", moduleName, message, stacktrace);
  }

  public warning(moduleName: string, message: string, stacktrace?: any) {
    this.log("warn", moduleName, message, stacktrace);
  }

  public error(moduleName: string, message: string, stacktrace?: any) {
    this.log("error", moduleName, message, stacktrace);
  }

  public debug(moduleName: string, message: string, stacktrace?: any) {
    this.log("debug", moduleName, message, stacktrace);
  }

  public loggingModule(level: LoggerLevel, moduleName: string) {
    if (level === "debug" && this.getDebugModules().indexOf(moduleName) === -1) return false;
    return true;
  }

  // tslint:disable:no-console
  public printSmallstackLogo() {
    console.log(" ");
    console.log("                        _  _       _                 _    ");
    console.log(" ___  _ __ ___    __ _ | || | ___ | |_   __ _   ___ | | __");
    console.log("/ __|| '_ ` _ \\  / _` || || |/ __|| __| / _` | / __|| |/ /");
    console.log("\\__ \\| | | | | || (_| || || |\\__ \\| |_ | (_| || (__ |   < ");
    console.log("|___/|_| |_| |_| \\__,_||_||_||___/ \\__| \\__,_| \\___||_|\\_\\");
    console.log("visit us on https://smallstack.com!");
    console.log(" ");
  }

  public addDebugModule(debugModuleName: string) {
    this.loggerDebugModules.push(debugModuleName);
  }

  public getDebugModules(): string[] {
    return this.loggerDebugModules;
  }

  public resetDebugModules() {
    this.loggerDebugModules = [];
  }

  public getLoggerMaxLength(): number {
    return this.loggerMaxLength;
  }

  public setLoggerMaxLength(maxLength: number) {
    this.loggerMaxLength = maxLength;
  }
}
