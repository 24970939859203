<div class="form-control w-full" attr.data-test="schema-form-widget-input-{{ path() }}">
  @if (options()?.showLabel !== false) {
    <label class="label">
      <span class="label-text">
        <smallstack-form-field-title [path]="path()" [showRequired]="true" />
      </span>
    </label>
  }
  <ng-content />
  @if (schema()?.description && options()?.showHint !== false && options()?.useInfoBox !== true) {
    <label class="label">
      <span class="label-text-alt">{{ schema()?.description }}</span></label
    >
  }
  @if (validationErrorText()) {
    <label class="label">
      <span class="label-text-alt text-error" data-test="validation-error-text">{{ validationErrorText() }} </span>
    </label>
  }
</div>
@if (schema()?.description && options()?.showHint !== false && options()?.useInfoBox === true) {
  <div matSuffix>
    <smallstack-icon
      name="question-mark"
      theme="ios-glyphs"
      color="AAAAAA"
      size="16"
      style="cursor: help; padding-bottom: 8px"
      [matTooltip]="schema()?.description"
    />
  </div>
}
