import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AxiosApiClient, DataTypeDto, TenantDataApi } from "@smallstack/axios-api-client";
import { DataStore } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { NotificationService } from "@smallstack/i18n-components";
import { PageableCrudStore, PageableStore } from "@smallstack/store";
import { F_CUSTOM } from "@smallstack/typesystem";
import { BaseWidgetComponent } from "@smallstack/widget-core";

@Component({
  templateUrl: "./create-data-widget.component.html",
  styleUrls: ["./create-data-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateDataWidgetComponent extends BaseWidgetComponent implements OnInit {
  public store: PageableCrudStore | PageableStore;
  public dataType: DataTypeDto;
  public schema: SchemaFormSchema;
  public model: any;
  public loading: boolean;
  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        dataType: {
          title: "DataType",
          type: "string",
          "x-schema-form": {
            widget: "storeselect",
            store: "dataTypes",
            property: "path"
          }
        } as SchemaFormSchema
      }
    };
  }

  constructor(
    private axiosApiClient: AxiosApiClient,
    private dataStore: DataStore,
    private notificationService: NotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    void this.init();
  }

  private async init() {
    this.loading = true;
    if (this.data().dataType) {
      this.dataType = await this.axiosApiClient
        .get(TenantDataApi)
        .getDataType({ id: this.data().dataType })
        .then((res) => res.data);
      if (this.dataType) {
        this.store = this.dataStore.for(F_CUSTOM, this.dataType.path);
        this.schema = this.dataType.schema;
        this.cdr.markForCheck();
      }
    }
    this.loading = false;
    this.cdr.markForCheck();
  }

  public createNewData() {
    return async (): Promise<void> => {
      if (this.model && this.store instanceof PageableCrudStore) {
        await this.notificationService.handlePromise(this.store.create(this.model));
        await this.store.load();
        this.model = {};
        this.cdr.markForCheck();
      }
    };
  }
}
