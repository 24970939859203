import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const CONFIG_ONLY_MINE = "onlyMine";
export const CONFIG_LINKED_ID_FILTER = "linkedIdFilter";

export const TodoAgendaWidget: WidgetRegistryEntry = {
  name: "TodoAgenda",
  configuration: {
    icon: "task",
    tags: AllWidgetTags,
    templateName: "Aufgaben Agenda",
    templateDescription: "Zeigt eine chronologische Agenda aller Aufgaben an",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIG_ONLY_MINE]: {
          title: "Zeige nur zugewiesene?",
          type: "boolean",
          description:
            "Falls aktiv, dann werden nur Aufgaben in der Agenda gezeigt, welche dem angemeldeten Benutzer oder niemandem zugewiesen sind."
        },
        [CONFIG_LINKED_ID_FILTER]: {
          title: "Zeige nur für verknüpftes Objekt?",
          type: "string",
          description:
            "Falls aktiv, dann werden nur Aufgaben in der Agenda gezeigt, welche mit dem verknüpften Objekt verknüpft sind."
        }
      }
    }
  },
  component: () => import("./todo-agenda-widget.component").then((m) => m.TodoAgendaWidgetComponent)
};
