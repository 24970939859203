import { TypeSchema } from "../typesystem/type-schema";

export enum DataTypeStorageTarget {
  /** Synchronizes via rx-data endpoint at GCP */
  RxDataGCP = "rx-data-gcp",
  /** Synchronizes via a custom REST Endpoint that supports the CRUD interface */
  RestEndpoint = "rest"
}

export interface DataTypeStorage {
  target: DataTypeStorageTarget;
  options?: { apiUrl?: string };
}

export const DataTypeStorageSchema: TypeSchema = {
  type: "object",
  properties: {
    target: { type: "string", enum: Object.values(DataTypeStorageTarget) },
    options: {
      type: "object",
      properties: {
        apiUrl: { type: "string" }
      }
    }
  }
};
