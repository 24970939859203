import { ChangeDetectionStrategy, Component, computed, signal } from "@angular/core";
import { AxiosApiClient, TodoApi, TodoDto, TodoTemplateDto } from "@smallstack/axios-api-client";
import { RouterUtilService, ScrollTopService } from "@smallstack/common-components";
import { ICON_THEMES } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { TYPE_TODOS, TYPE_TODO_TEMPLATES } from "@smallstack/typesystem";
import { UserService } from "@smallstack/user-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "UpcomingTodos",
  icon: "to-do",
  tags: AllWidgetTags,
  templateName: "Anstehende Aufgaben",
  templateDescription: "Zeigt anstehende Aufgaben, Aufgaben Vorlagen oder beides an.",
  dataSchema: {
    type: "object",
    properties: {
      selection: {
        type: "array",
        title: "Welche Aufgaben?",
        description: "Wählen Sie hier, welche anstehenden Aufgaben angezeigt werden sollen",
        default: ["todos", "todoTemplates"],
        items: {
          type: "string",
          enum: ["todos", "todoTemplates"]
        }
      },
      onlyToday: {
        type: "boolean",
        title: "Nur heutige?",
        description: "Falls ausgewählt, dann werden nur anstehende Aufgaben am heutigen Tag angezeigt",
        default: false
      },
      onlyMine: {
        type: "boolean",
        title: "Nur eigene",
        description: "Zeigt, falls ausgewählt, nur die eigenen Aufgaben an"
      },
      showTodoTitle: {
        type: "boolean",
        title: "Zeigt 'Laufende Aufgaben'",
        default: true
      },
      showTodoDescription: {
        type: "boolean",
        title: "Beschreibung 'Laufende Aufgaben'",
        description:
          "Falls ausgewählt, wird eine kleine Beschreibung unter der Überschrift 'Laufende Aufgaben' angezeigt",
        default: true
      },
      showTemplateTitle: {
        type: "boolean",
        title: "Zeigt 'Geplante Aufgaben'",
        default: true
      },
      showTemplateDescription: {
        type: "boolean",
        title: "Beschreibung 'Geplante Aufgaben'",
        description:
          "Falls ausgewählt, wird eine kleine Beschreibung unter der Überschrift 'Geplante Aufgaben' angezeigt",
        default: true
      },
      showMoreLink: {
        title: "Link zu allen Aufgaben zeigen",
        description:
          "Falls aktiviert, wird ein Link unterhalb der Daten angezeigt, der zu einer Unterseite führen kann, in dem alle Aufgaben und Vorlagen angezeigt werden.",
        default: true,
        type: "boolean"
      },
      link: {
        type: "string",
        title: "Link",
        description: "Geben Sie hier den Pfad der Seite an, auf die Link verweisen soll."
      },
      label: {
        type: "string",
        title: "Link Label",
        description: "Geben Sie hier den Text an, der als Link dargestellt werden soll."
      },
      icon: {
        type: "string",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        }
      } as SchemaFormSchema,
      iconTheme: {
        type: "string",
        title: "Icon Theme",
        enum: ICON_THEMES,
        default: "color"
      },
      iconSize: {
        type: "string",
        title: "Icon Größe",
        description: "Größe der Tab Icons"
      },
      iconColor: {
        type: "string",
        title: "Icon Farbe",
        description: "Farbe der Tab Icons, falls als Theme nicht 'color' oder 'fluency' ausgewählt ist.",
        "x-schema-form": {
          widget: "color"
        },
        default: "#FFFFFF"
      } as SchemaFormSchema
    }
  }
})
@Component({
  templateUrl: "./upcoming-todos-widget.component.html",
  styleUrls: ["./upcoming-todos-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingTodosWidgetComponent extends BaseWidgetComponent {
  private todoStore = injectRxEntityStore<TodoDto>({ typeDescriptor: { typePath: TYPE_TODOS } });
  private todoTemplatesStore = injectRxEntityStore<TodoTemplateDto>({
    typeDescriptor: { typePath: TYPE_TODO_TEMPLATES }
  });

  protected isLoading = signal<boolean>(true);
  protected showTodos = computed(() => this.data()?.selection?.includes("todos"));
  protected showTodoTemplates = computed(() => this.data()?.selection?.includes("todoTemplates"));

  protected todos = computed<TodoDto[]>(() => {
    if (this.showTodos()) {
      return this.todoStore.getByProperty("done", false);
    }
    return [];
  });
  protected todoTemplates = computed<TodoTemplateDto[]>(() => {
    if (this.showTodoTemplates()) {
      return this.todoTemplatesStore.getMany().filter((todoTemplate) => {
        return (
          todoTemplate.ownerIds?.includes(this.userService.currentUserId()) ||
          todoTemplate.ownerIds === undefined ||
          todoTemplate.ownerIds.length === 0
        );
      });
    }
    return [];
  });

  constructor(
    protected axiosApiClient: AxiosApiClient,
    public scrollTopService: ScrollTopService,
    private routerUtilService: RouterUtilService,
    private userService: UserService
  ) {
    super();
  }

  public toggleTodo(todo: TodoDto) {
    return async (): Promise<void> => {
      const updatedTodo = await this.axiosApiClient
        .withConfigFromContext(todo)
        .get(TodoApi)
        .patchMyTodo({ id: todo.id, todo: { done: !todo.done } });
      todo.done = updatedTodo.data.done;
      this.cdr.markForCheck();
    };
  }

  public async updateFn(todo: TodoDto): Promise<void> {
    await this.todoStore.updateEntity(todo);
  }

  public createdTodo(newTodo: TodoDto): void {
    // if (this.todos === undefined) this.todos = [];
    // this.todos.push(newTodo);
    this.scrollTopService.scrollTop();
  }

  public openLink(): void {
    if (this.data()?.link && !this.widgetTreeService.editMode()) {
      void this.routerUtilService.navigateFullUrl(this.data()?.link);
    }
  }
}
