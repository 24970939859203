import { BreakpointObserver } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { ErrorMessageHandler, PAGINATOR_POSITION } from "@smallstack/common-components";
import { I18nComponent, LocaleService, TranslationStore } from "@smallstack/i18n-components";
import { PageableCrudStore, PageableStore, StoreState } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoaderComponent } from "../loader/loader.component";
import { PaginatorTranslation } from "./paginator-translation";

export const LIST_CONTAINER_PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 500, 1000];

export function paginatorTranslation(
  translationStore: TranslationStore,
  localeService: LocaleService
): MatPaginatorIntl {
  return PaginatorTranslation(translationStore, localeService);
}

/**
 * @example
 * <smallstack-list-container></smallstack-list-container>
 */
@Component({
  selector: "smallstack-list-container",
  templateUrl: "./list-container.component.html",
  styleUrls: ["./list-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, I18nComponent, LoaderComponent, IconComponent, MatPaginatorModule],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: paginatorTranslation,
      deps: [TranslationStore, LocaleService]
    }
  ]
})
export class ListContainerComponent implements OnInit, OnChanges {
  public PAGINATOR_POSITION = PAGINATOR_POSITION; // Make enum accessible in template
  public StoreState = StoreState;

  @Input()
  public paginatorPosition?: PAGINATOR_POSITION = PAGINATOR_POSITION.BOTTOM;

  @Input()
  public showPager: boolean = true;

  @Input()
  public set pageSize(pageSize: number) {
    if (pageSize === undefined) return;
    const reload = this.store.query$.value.size !== pageSize;
    this.store.setPageSize(pageSize);
    if (reload) void this.store.load();
  }

  @Input()
  public pageSizeOptions: number[] = LIST_CONTAINER_PAGE_SIZE_OPTIONS;

  @Input()
  public set showEmpty(val: any) {
    this.showEmptyInternal = val || val === "true";
  }

  public get showEmpty(): any {
    return this.showEmptyInternal;
  }

  public isPageable: boolean;
  public isMobile: boolean;

  @Input()
  public store: PageableCrudStore | PageableStore;

  @Output()
  public readonly pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  public totalElements$: Observable<number>;
  public size$: Observable<number>;
  public pageIndex$: Observable<number>;

  public storeError$: Observable<string>;

  @ContentChild("empty", { static: false })
  public empty: TemplateRef<unknown>;

  @ContentChild("content", { static: false })
  public content: TemplateRef<unknown>;

  @ContentChild("listActions", { static: false })
  public listActions: TemplateRef<unknown>;

  @ViewChild("legacyNgContent")
  private legacyNgContent: ElementRef;

  private showEmptyInternal: boolean = true;

  constructor(
    breakpointObserver: BreakpointObserver,
    private errorMessageHandler: ErrorMessageHandler
  ) {
    this.isMobile = breakpointObserver.isMatched("(max-width: 599px)");
    // this.routerUtilService.initializeListRouting(this.store);
  }

  public projectContentChanged(): void {
    if (this.legacyNgContent?.nativeElement?.children.length > 0) {
      // tslint:disable-next-line: no-console
      for (const child of this.legacyNgContent.nativeElement.children)
        console.error("Found invalid ng-content element: ", child);
      throw new Error(
        "Please use <ng-template #content>...</ng-template> inside <smallstack-list-container> instead of ng-content! Using ng-content in <smallstack-list-container> will be deprecated soon and therefor will result in an empty component!"
      );
    }
  }

  public ngOnInit(): void {
    this.load();
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.store?.currentValue) {
      if (this.store.state === StoreState.INITIAL) await this.store.load();
      if (this.store.state === StoreState.LOADED) this.load();
    }
  }

  private load(): void {
    this.isPageable = this.store instanceof PageableCrudStore || this.store instanceof PageableStore;
    this.totalElements$ = this.store?.pagination$.pipe(map((v) => v.totalElements));
    this.size$ = this.store?.query$.pipe(map((v) => v.size));
    this.pageIndex$ = this.store?.query$.pipe(map((v) => v.page - 1));
    this.storeError$ = this.store?.error$.pipe(
      map((error) => {
        return this.errorMessageHandler.handleMessage(error);
      })
    );
  }

  public hasUserDefinedEmptyContent(): boolean {
    return this.empty !== undefined;
  }

  public async changePage(page: PageEvent): Promise<void> {
    if (this.store instanceof PageableCrudStore || this.store instanceof PageableStore) {
      const currentQuery = this.store?.query$?.value;
      this.store.query$.next({
        page: page.pageIndex + 1,
        size: +page.pageSize,
        search: currentQuery?.search,
        sort: currentQuery?.sort
      });
      // await this.routerUtilService.addMultipleQueryParams([
      // { key: "pageSize", value: page.pageSize.toString() },
      // { key: "pageIndex", value: (page.pageIndex + 1).toString() }
      // ]);
      this.pageSizeChange.emit(page.pageSize);
      await this.store.load();
    }
  }
}
