import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, filter } from "rxjs";

/** Registers elements that are scrollable and triggers them when the user wants to scroll up again */
@Injectable({ providedIn: "root" })
export class ScrollTopService {
  #scrollTopTrigger$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  public currentScrollPosition$ = new BehaviorSubject<number>(0);
  public scrollTopTriggered$ = this.#scrollTopTrigger$.asObservable();

  constructor(
    router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    // scroll top on navigate
    void router.events?.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.scrollTop();
    });
  }

  public scrollTo(offsetTop: any): void {
    if (typeof window !== "undefined") window.scrollTo({ top: offsetTop, left: 0 });
    this.document.body.scrollTop = offsetTop;
    this.currentScrollPosition$.next(offsetTop);
  }

  public scrollTop(): void {
    // scroll body first
    if (typeof window !== "undefined") window.scrollTo({ top: 0, left: 0 });
    this.document.body.scrollTop = 0;

    // then trigger all subscribers
    this.#scrollTopTrigger$.next();
  }
}
