<div class="mat-outline">
  @if (schedulingRule) {
    <div class="flex flex-col gap-1">
      <div class="mat-outline">
        <mat-label>Termin</mat-label>
        @if (schedulingRule?.pattern !== "always") {
          <div class="w-full flex flex-col md:flex-row gap-1">
            <div class="form-control">
              <label class="label">
                <span class="label-text">Beginn</span>
              </label>
              <input
                class="input input-bordered"
                type="datetime-local"
                [(ngModel)]="startTime"
                (ngModelChange)="syncLocalToSchedulingRule()"
              />
            </div>
            <div class="form-control">
              <div class="label">
                <div class="label-text">Ende</div>
              </div>
              <input
                class="input input-bordered"
                type="datetime-local"
                [(ngModel)]="endTime"
                (ngModelChange)="syncLocalToSchedulingRule()"
              />
            </div>
          </div>
        } @else {
          <div class="form-control">
            <div class="label">
              <div class="label-text">Dauer in Stunden</div>
            </div>
            <input
              class="input input-bordered"
              type="number"
              [ngModel]="schedulingRule.duration / 3600000"
              (ngModelChange)="schedulingRule.duration = $event * 3600000"
            />
          </div>
        }
      </div>
      <div class="mat-outline">
        <mat-label>Serienmuster</mat-label>
        <div class="w-full flex flex-col md:flex-row gap-1">
          <mat-form-field [appearance]="options()?.appearance">
            <mat-select [(ngModel)]="schedulingRule.pattern" (selectionChange)="patternUpdated()">
              @for (pattern of schedulingRulePattern; track pattern) {
                <mat-option [value]="pattern">
                  <smallstack-i18n data="@@todo.schedulingRules.pattern.{{ pattern }}"></smallstack-i18n
                ></mat-option>
              }
            </mat-select>
          </mat-form-field>
          @if (
            schedulingRule.pattern === "weekly" ||
            schedulingRule.pattern === "daily" ||
            schedulingRule.pattern === "monthly" ||
            schedulingRule.pattern === "yearly"
          ) {
            <mat-form-field [appearance]="options()?.appearance">
              @if (schedulingRule.pattern === "daily") {
                <mat-label>Jeden x-ten Tag</mat-label>
              }
              @if (schedulingRule.pattern === "weekly") {
                <mat-label>Jede x-te Woche</mat-label>
              }
              @if (schedulingRule.pattern === "monthly") {
                <mat-label>Jeden x-ten Monat</mat-label>
              }
              @if (schedulingRule.pattern === "yearly") {
                <mat-label>Jedes x-te Jahr</mat-label>
              }
              <input matInput type="number" min="1" [(ngModel)]="schedulingRule.each" (change)="updatePreviewDates()" />
            </mat-form-field>
          }
          @if (schedulingRule.pattern === "weekly") {
            <mat-form-field [appearance]="options()?.appearance">
              <mat-label>Wochentage</mat-label>
              <mat-select
                [(ngModel)]="schedulingRule.patternIncludes"
                multiple
                (selectionChange)="updatePreviewDates()"
              >
                @for (
                  weekday of [
                    { name: "Montag", day: 1 },
                    { name: "Dienstag", day: 2 },
                    { name: "Mittwoch", day: 3 },
                    { name: "Donnerstag", day: 4 },
                    { name: "Freitag", day: 5 },
                    { name: "Samstag", day: 6 },
                    { name: "Sonntag", day: 7 }
                  ];
                  track weekday
                ) {
                  <mat-option [value]="weekday.day">{{ weekday.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
      </div>
      @if (schedulingRule?.pattern !== "once" && schedulingRule?.pattern !== "always") {
        <div class="mat-outline">
          <mat-label>Seriendauer</mat-label>
          <div class="w-full flex flex-col md:flex-row gap-1">
            <div class="form-control">
              <label class="label">
                <span class="label-text">Von</span>
              </label>
              <input
                class="input input-bordered"
                type="datetime-local"
                [(ngModel)]="scheduleStart"
                (ngModelChange)="syncLocalToSchedulingRule()"
              />
            </div>
            <div class="form-control">
              <label class="label">
                <span class="label-text">Bis</span>
              </label>
              <input
                class="input input-bordered"
                type="datetime-local"
                [(ngModel)]="scheduleEnd"
                (ngModelChange)="syncLocalToSchedulingRule()"
              />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-lg py-2">Termin Vorschau</div>
            <div class="flex flex-row flex-wrap gap-2">
              @for (nextDate of nextDates; track nextDate) {
                <smallstack-date
                  [timestamp]="nextDate"
                  format="EEEE dd.MM.yyyy HH:mm"
                  class="border border-gray-300 rounded-md p-2 w-52 border-l-4"
                  [ngClass]="{
                    'border-l-red-300 ': (nextDate | date: 'c') === '1',
                    'border-l-orange-300': (nextDate | date: 'c') === '2',
                    'border-l-yellow-300': (nextDate | date: 'c') === '3',
                    'border-l-lime-300': (nextDate | date: 'c') === '4',
                    'border-l-teal-300': (nextDate | date: 'c') === '5',
                    'border-l-blue-300': (nextDate | date: 'c') === '6',
                    'border-l-violet-300': (nextDate | date: 'c') === '0'
                  }"
                ></smallstack-date>
              }
              @if (!schedulingRule.scheduleEnd) {
                <div class="border border-gray-300 rounded-md p-2 w-52 border-l-4"> ... und weitere </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>
