import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AxiosApiClient, CalendarApi, CalendarEntryDto } from "@smallstack/axios-api-client";
import { CalendarEntryFormDialogComponent, CalendarEntryFormDialogData } from "@smallstack/calendar-components";
import { Widget, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "MyCalendarEvents",
  templateName: "Meine Kalender Einträge",
  icon: "calendar",
  templateDescription: "Zeigt alle Einträge in allen Kalendern an, an welchen Sie teilnehmen.",
  tags: [WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD],
  meta: { widgetIcon: "calendar", widgetTitle: [{ value: "Meine Termine" }] }
})
@Component({
  templateUrl: "./my-calendar-events.component.html",
  styleUrls: ["./my-calendar-events.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyCalendarEventsComponent {
  public events: CalendarEntryDto[];

  constructor(
    private axiosApiClient: AxiosApiClient,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {
    void this.load();
  }

  public async load(): Promise<void> {
    this.events = await this.axiosApiClient
      .get(CalendarApi)
      .getParticipatingEvents({ includePastEvents: false })
      .then((res) => res.data.sort((eventA, eventB) => eventA.start - eventB.start));
    this.cdr.markForCheck();
  }

  public openEntry(calendarEntry: CalendarEntryDto): void {
    this.matDialog.open(CalendarEntryFormDialogComponent, { data: { calendarEntry } as CalendarEntryFormDialogData });
  }
}
