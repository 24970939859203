import { Model, ModelSchema } from "./model";

export enum ConfigurationScope {
  Public = "public",
  Server = "server",
  System = "system"
}

export interface Configuration extends Model {
  key: string;
  description?: string;
  value: string;
  defaultValue?: string;
  scope: ConfigurationScope;
}

export const ConfigurationSchema = {
  type: "object",
  properties: {
    ...ModelSchema.properties,
    key: { type: "string" },
    description: { type: "string" },
    value: { type: "string" },
    defaultValue: { type: "string" },
    scope: { type: "string", enum: Object.values(ConfigurationScope) }
  },
  required: ["key", "value", "scope"]
};
