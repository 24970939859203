import { ChangeDetectionStrategy, Component, effect, signal } from "@angular/core";
import { AxiosApiClient, TodoApi, TodoDto } from "@smallstack/axios-api-client";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { BooleanEquationOperator, getJsonByPath, isNonEmptyString } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "TodoList",
  templateName: "Aufgaben Liste",
  icon: "todo-list--v1",
  templateDescription: "Zeigt Aufgaben an.",
  tags: AllWidgetTags,
  meta: { widgetIcon: "todo-list--v1", widgetTitle: [{ value: "Meine Aufgaben" }] },
  dataSchema: {
    type: "object",
    properties: {
      whichTodos: {
        type: "string",
        enum: ["myTodos", "allProjectTodos", "contextTodos"],
        default: "myTodos"
      },
      contextVariable: {
        type: "string",
        title: "Kontext Variable",
        "x-schema-form": {
          widget: "ContextVariable",
          rules: [
            {
              action: "hide",
              if: {
                dataPath: "../whichTodos",
                operator: BooleanEquationOperator.NOT_EQUALS,
                value: "contextTodos"
              }
            }
          ]
        }
      } as SchemaFormPropertyOptions,
      editingEnabled: {
        type: "boolean",
        title: "Bearbeiten aktiviert",
        default: false
      },
      checkingEnabled: {
        type: "boolean",
        title: "Abhaken aktiviert",
        default: true
      }
    }
  }
})
@Component({
  templateUrl: "./todo-list-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoListWidgetComponent extends BaseWidgetComponent {
  public todos = signal<TodoDto[]>([]);

  constructor(private axiosApiClient: AxiosApiClient) {
    super();

    effect(
      async () => {
        const data = this.data();
        if (data) {
          switch (data?.whichTodos) {
            case "contextTodos": {
              const context = this.context() || {};
              if (isNonEmptyString(data.contextVariable)) this.todos.set(getJsonByPath(context, data.contextVariable));
              else this.todos.set(context);
              break;
            }
            case "myTodos":
            case "allProjectTodos":
              await this.axiosApiClient
                .get(TodoApi)
                .getMyGlobalTodos()
                .then((response) => {
                  this.todos.set(response.data);
                });
              break;
          }
        }
      },
      { allowSignalWrites: true }
    );
  }
}
