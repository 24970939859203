import { TypeSchema } from "../typesystem/type-schema";

export interface ViewWidgetConfiguration {
  padding?: string;
  margin?: string;
  height?: string;
  width?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
  // customClass?: string;
  // customStyles?: string;
}

export const ViewWidgetConfigurationSchema: TypeSchema = {
  title: "Anzeige Eigenschaften",
  description: "Definieren Sie hier, wie das Widget angezeigt werden soll.",
  type: "object",
  properties: {
    padding: {
      type: "string",
      title: "Innenabstand",
      "x-schema-form": {
        inputWidget: "HtmlSizingInput"
      }
    },
    margin: {
      type: "string",
      title: "Außenabstand",
      "x-schema-form": {
        inputWidget: "HtmlSizingInput"
      }
    },
    height: {
      type: "string",
      title: "Höhe",
      "x-schema-form": {
        inputWidget: "HtmlSizingInput"
      }
    },
    width: {
      type: "string",
      title: "Breite",
      "x-schema-form": {
        inputWidget: "HtmlSizingInput"
      }
    },
    backgroundColor: {
      type: "string",
      title: "Hintergrundfarbe",
      "x-schema-form": {
        inputWidget: "ColorInput"
      }
    },
    color: {
      type: "string",
      title: "Text Farbe",
      "x-schema-form": {
        inputWidget: "ColorInput"
      }
    },
    fontSize: {
      type: "string",
      title: "Schriftgröße",
      "x-schema-form": {
        inputWidget: "HtmlSizingInput"
      }
    }
    // customClass: {
    //   type: "string",
    //   title: "Eigene CSS Klasse",
    //   description: "Eigene CSS Klassen können im Design der Applikation definiert werden"
    // },
    // customStyles: {
    //   type: "string",
    //   title: "Eigene CSS Styles"
    // }
  }
};
