export interface WidgetEventSubscription {
  /** The key of the event to subscribe to (e.g. widgetId123:text) */
  widgetId: string;
  eventName: string;
  /** The target property name, ideally a member of the widgets data schema. Will be written to the widget's data signal. */
  targetProperty: string;
}

export interface Widget {
  id?: string;
  name: string;
  data?: any;
  eventSubscriptions?: WidgetEventSubscription[];
  styles?: string;
  meta?: any;
}

export const WidgetEventSubscriptionSchema = {
  type: "object",
  properties: {
    widgetId: {
      type: "string"
    },
    eventName: {
      type: "string"
    },
    targetProperty: {
      type: "string"
    }
  },
  required: ["widgetId", "eventName", "targetProperty"]
};

export const WidgetSchema = {
  type: "object",
  properties: {
    id: {
      type: "string"
    },
    name: {
      type: "string",
      pattern: "^([A-Z][a-z0-9]+)+$"
    },
    data: {
      type: "object",
      additionalProperties: false,
      "x-schema-form": {
        widget: "monaco"
      }
    },
    eventSubscriptions: {
      type: "array",
      items: WidgetEventSubscriptionSchema
    },
    styles: {
      type: "string",
      "x-schema-form": {
        widget: "monaco"
      }
    },
    meta: {
      type: "object",
      additionalProperties: false,
      "x-schema-form": {
        widget: "monaco"
      }
    }
  },
  required: ["name"]
};
