/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ConfigurablePropertyDto,
  ConfigurablePropertyDtoTypeEnum,
  ConfigurationDto
} from "@smallstack/axios-api-client";

export interface ConfigurationService {
  getValue(key: string): Promise<string>;
}

export class ConfigurablePropertyService {
  constructor(private configurationService: ConfigurationService) {}

  public async getEvaluatedProperty<T extends string | number | boolean>(
    property: ConfigurablePropertyDto
  ): Promise<T> {
    if (!property) return undefined;
    let value = "";
    if (property.type === ConfigurablePropertyDtoTypeEnum.Inline) value = property.inlineValue;
    else if (property.type === ConfigurablePropertyDtoTypeEnum.ProjectConfiguration) {
      const configuration = await this.configurationService.getValue(property.configurationKey);
      if (!configuration) return undefined;
      value = configuration;
    }
    switch (property.parser) {
      case "boolean":
        // @ts-ignore
        return value === "true";
      case "number":
        // @ts-ignore
        return parseFloat(value);
      default:
        // @ts-ignore
        return "" + value;
    }
  }

  public getEvaluatedPropertySync<T extends string | number | boolean>(
    property: ConfigurablePropertyDto,
    configurations: ConfigurationDto[]
  ): T {
    if (!property) return undefined;
    let value = "";
    if (property.type === ConfigurablePropertyDtoTypeEnum.Inline) value = property.inlineValue;
    else if (property.type === ConfigurablePropertyDtoTypeEnum.ProjectConfiguration) {
      const configuration = configurations.find((config) => config.key === property.configurationKey)?.value;
      if (!configuration) return undefined;
      value = configuration;
    }
    switch (property.parser) {
      case "boolean":
        // @ts-ignore
        return value === "true";
      case "number":
        // @ts-ignore
        return parseFloat(value);
      default:
        // @ts-ignore
        return "" + value;
    }
  }
}
