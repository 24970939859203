import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { isNonEmptyString } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "WorklogBilling",
  templateName: "Worklog Abrechnung",
  templateDescription: "Zeigt den Preis für ein Worklog an",
  dataSchema: {
    type: "object",
    properties: {
      contextVariableName: {
        type: "string",
        title: "Kontext Variable",
        description: "In welcher das Worklog steckt",
        "x-schema-form": { widget: "ContextVariable" }
      }
    }
  },
  tags: AllWidgetTags,
  icon: "banknotes"
})
@Component({
  selector: "smallstack-worklog-billing-widget",
  templateUrl: "./worklog-billing-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogBillingWidgetComponent extends BaseWidgetComponent {
  protected worklog = computed(() => {
    const context = this.context();
    const data = this.data();
    if (context) {
      if (data && isNonEmptyString(data.contextVariableName)) return context[data.contextVariableName];
      else return context;
    }
    return undefined;
  });
}
