import { ChangeDetectionStrategy, Component, computed, effect, signal } from "@angular/core";
import { AxiosApiClient, ConfigurationDto, UsersApi } from "@smallstack/axios-api-client";
import { BackofficeConfigurationKey, defaultUserProfileSchema } from "@smallstack/core-common";
import { NotificationService } from "@smallstack/i18n-components";
import { TYPE_CONFIGURATIONS } from "@smallstack/typesystem";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { isJsonString } from "@smallstack/utils";
import { BaseWidgetComponent, FormService } from "@smallstack/widget-core";

@Component({
  selector: "customer-profile-widget",
  templateUrl: "./customer-profile-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormService, useValue: undefined }]
})
export class CustomerProfileWidgetComponent extends BaseWidgetComponent {
  private configurationStore = injectRxEntityStore<ConfigurationDto>({
    typeDescriptor: { typePath: TYPE_CONFIGURATIONS }
  });

  public userProfileSchema = computed(() => {
    if (this.configurationStore.loadingState() !== "loaded") return;
    const configuredSchema = this.configurationStore.getOneByProperty(
      "key",
      BackofficeConfigurationKey.USER_PROFILE_SCHEMA
    )?.value;
    if (configuredSchema && isJsonString(configuredSchema)) return JSON.parse(configuredSchema);
    return defaultUserProfileSchema;
  });

  public customerProfile = signal<unknown>(undefined);

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private axiosApiClient: AxiosApiClient
  ) {
    super();

    effect(
      () => {
        this.customerProfile.set(this.userService.currentUser()?.user?.profile);
      },
      { allowSignalWrites: true }
    );
  }

  public saveUserProfile() {
    return async (): Promise<void> => {
      await this.notificationService.handlePromise(
        this.axiosApiClient.get(UsersApi).updateMyProfile({ profile: this.customerProfile() })
      );
    };
  }
}
