import { Injectable, Injector, computed, inject } from "@angular/core";
import { WorklogLike } from "@smallstack/core-common";
import { Model, TypeDescriptor } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { startOfDay } from "date-fns/startOfDay";

export interface GroupedPerDay {
  timestamp: number;
  items: Array<WorklogLike & Model>;
}

export class WorklogLikeService {
  public store = injectStore<WorklogLike & Model>({ typePath: this.typeDescriptor.typePath, eagerRefetch: true });

  constructor(
    private typeDescriptor: TypeDescriptor,
    private injector: Injector
  ) {}

  public groupedPerDay = computed<GroupedPerDay[]>(() => {
    const timeTrackingEntities: WorklogLike[] = this.store.find({ sort: [{ startTime: "desc" }] });
    if (!timeTrackingEntities) return [];
    const result: { [key: string]: WorklogLike[] } = {};
    timeTrackingEntities.forEach((doc) => {
      const date = new Date(doc.startTime);
      const key = "" + startOfDay(date).valueOf();
      if (!result[key]) result[key] = [];
      result[key].push(doc);
    });
    return Object.keys(result).map((key: string) => {
      return {
        timestamp: parseInt(key),
        items: result[key]
      };
    });
  });

  public hasRunningTimer = computed<boolean>(() => {
    const worklogs = this.store.find({
      selector: {
        startTime: { $exists: true },
        endTime: { $exists: false },
        ownerId: this.injector.get(UserService).currentUserId()
      }
    });
    return worklogs?.length > 0;
  });

  public currentRunningWorklog = computed<WorklogLike | undefined>(() => {
    const worklogs = this.store.find({
      selector: {
        startTime: { $exists: true },
        endTime: { $exists: false },
        ownerId: this.injector.get(UserService).currentUserId()
      }
    });
    return worklogs?.[0];
  });

  public async stopWorklogLike(id: string): Promise<void> {
    await this.store.patch(id, { endTime: Date.now() });
  }
}

@Injectable({ providedIn: "root" })
export class WorklogLikeServiceFactory {
  private cache: Map<string, WorklogLikeService> = new Map();

  private injector = inject(Injector);

  public forType(typeDescriptor: TypeDescriptor): WorklogLikeService {
    if (!typeDescriptor) throw new Error("TypeDescriptor is required");
    const key = JSON.stringify(typeDescriptor);
    if (!this.cache.has(key)) {
      this.cache.set(key, new WorklogLikeService(typeDescriptor, this.injector));
    }
    return this.cache.get(key);
  }
}
