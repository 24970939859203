import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ContentChild, TemplateRef, computed, inject, input } from "@angular/core";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { RxEntityStoreService, TypeService } from "@smallstack/typesystem-client";

@Component({
  selector: "smallstack-type-model",
  templateUrl: "./type-model.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class TypeModelComponent {
  private typeService = inject(TypeService);
  protected rxEntityStoreService = inject(RxEntityStoreService);

  @ContentChild(TemplateRef)
  public templateModel: TemplateRef<unknown>;

  public modelId = input.required<string>();
  public typePath = input.required<string>();
  public displayShort = input<boolean>(true);
  public displayLong = input<boolean>(true);

  protected model = computed(() => {
    const typePath = this.typePath();
    const modelId = this.modelId();
    if (!typePath || !modelId) return;
    const model = this.rxEntityStoreService.forType(typePath).getById(modelId);
    if (!model) return undefined; //throw new Error("No model found for " + modelId + " and type " + typePath);
    return model;
  });
  protected short = computed<string | InlineTranslation>(() => {
    const model = this.model();
    if (model) return this.typeService.getRepresentation({ typePath: this.typePath() }, model as any);
  });
  protected long = computed<string | InlineTranslation>(() => {
    const model = this.model();
    if (model) return this.typeService.getLongRepresentation({ typePath: this.typePath() }, model as any);
  });
}
