<div class="flex flex-col">
  <mat-form-field>
    <input #searchInput matInput placeholder="Suche" (keyup)="searchChanged($event)" />
    <button mat-icon-button matSuffix (click)="clearSearch(); searchInput.value = ''">
      <smallstack-icon alias="clear" size="24" />
    </button>
  </mat-form-field>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="contact-persons-container">
  <table>
    <thead>
      <tr>
        <th>Nachname</th>
        <th>Vorname</th>
        <th>E-Mail</th>
      </tr>
    </thead>
    <ng-container *cdkVirtualFor="let contactPerson of filteredContactPersons">
      <tr class="row" (click)="selectContactPerson(contactPerson)">
        <td>{{ contactPerson.lastName }}</td>
        <td>{{ contactPerson.firstName }}</td>
        <td>{{ contactPerson.email }} </td>
      </tr>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>
<div class="justify-end">
  <button mat-dialog-close class="btn btn-primary">
    <smallstack-i18n data="@@actions.close" />
  </button>
</div>
