/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AvailableWorkflowBlockDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
// @ts-ignore
import { WorkflowDto } from '../models';
// @ts-ignore
import { WorkflowExecutionDto } from '../models';
// @ts-ignore
import { WorkflowExecutionPageDto } from '../models';
// @ts-ignore
import { WorkflowExecutionSearchResultPageDto } from '../models';
// @ts-ignore
import { WorkflowPageDto } from '../models';
// @ts-ignore
import { WorkflowSearchResultPageDto } from '../models';
// @ts-ignore
import { WorkflowTriggerTemplateDto } from '../models';
/**
 * WorkflowsApi - axios parameter creator
 * @export
 */
export const WorkflowsApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (workflow: WorkflowDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflow' is not null or undefined
            if (workflow === null || workflow === undefined) {
                throw new RequiredError('workflow','Required parameter workflow was null or undefined when calling createWorkflow.');
            }
            const localVarPath = `/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflow !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflow !== undefined ? workflow : {})
                : (workflow || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowExecution: async (workflowExecution: WorkflowExecutionDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowExecution' is not null or undefined
            if (workflowExecution === null || workflowExecution === undefined) {
                throw new RequiredError('workflowExecution','Required parameter workflowExecution was null or undefined when calling createWorkflowExecution.');
            }
            const localVarPath = `/workflowexecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflowExecution !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflowExecution !== undefined ? workflowExecution : {})
                : (workflowExecution || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyWorkflowExecutions: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyWorkflowExecutions.');
            }
            const localVarPath = `/workflowexecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyWorkflows: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyWorkflows.');
            }
            const localVarPath = `/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorkflow.');
            }
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowExecution: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorkflowExecution.');
            }
            const localVarPath = `/workflowexecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflow: async (workflowId: string, data: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling executeWorkflow.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling executeWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}/execute`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflowSync: async (workflowId: string, data: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling executeWorkflowSync.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling executeWorkflowSync.');
            }
            const localVarPath = `/workflows/{workflowId}/execute-sync`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventName 
         * @param {any} workflowData 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fireWorkflowEvent: async (eventName: string, workflowData: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventName' is not null or undefined
            if (eventName === null || eventName === undefined) {
                throw new RequiredError('eventName','Required parameter eventName was null or undefined when calling fireWorkflowEvent.');
            }
            // verify required parameter 'workflowData' is not null or undefined
            if (workflowData === null || workflowData === undefined) {
                throw new RequiredError('workflowData','Required parameter workflowData was null or undefined when calling fireWorkflowEvent.');
            }
            const localVarPath = `/workflows/events/{eventName}/fire`
                .replace(`{${"eventName"}}`, encodeURIComponent(String(eventName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflowData !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflowData !== undefined ? workflowData : {})
                : (workflowData || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBlocks: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflows/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkflow.');
            }
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowEvents: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflows/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecution: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkflowExecution.');
            }
            const localVarPath = `/workflowexecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecutions: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflowexecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecutionsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflowexecutions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowTriggerTemplates: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflows/triggertemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workflows/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflow: async (id: string, workflow: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchWorkflow.');
            }
            // verify required parameter 'workflow' is not null or undefined
            if (workflow === null || workflow === undefined) {
                throw new RequiredError('workflow','Required parameter workflow was null or undefined when calling patchWorkflow.');
            }
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflow !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflow !== undefined ? workflow : {})
                : (workflow || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflowExecution: async (id: string, workflowExecution: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchWorkflowExecution.');
            }
            // verify required parameter 'workflowExecution' is not null or undefined
            if (workflowExecution === null || workflowExecution === undefined) {
                throw new RequiredError('workflowExecution','Required parameter workflowExecution was null or undefined when calling patchWorkflowExecution.');
            }
            const localVarPath = `/workflowexecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflowExecution !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflowExecution !== undefined ? workflowExecution : {})
                : (workflowExecution || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performWorkflowExecutionSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performWorkflowExecutionSearch.');
            }
            const localVarPath = `/workflowexecutions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performWorkflowSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performWorkflowSearch.');
            }
            const localVarPath = `/workflows/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflow: async (id: string, workflow: WorkflowDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putWorkflow.');
            }
            // verify required parameter 'workflow' is not null or undefined
            if (workflow === null || workflow === undefined) {
                throw new RequiredError('workflow','Required parameter workflow was null or undefined when calling putWorkflow.');
            }
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflow !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflow !== undefined ? workflow : {})
                : (workflow || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflowExecution: async (id: string, workflowExecution: WorkflowExecutionDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putWorkflowExecution.');
            }
            // verify required parameter 'workflowExecution' is not null or undefined
            if (workflowExecution === null || workflowExecution === undefined) {
                throw new RequiredError('workflowExecution','Required parameter workflowExecution was null or undefined when calling putWorkflowExecution.');
            }
            const localVarPath = `/workflowexecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workflowExecution !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workflowExecution !== undefined ? workflowExecution : {})
                : (workflowExecution || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a WorkflowExecution exists.
         * @summary Check if WorkflowExecution exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowExecutionExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling workflowExecutionExists.');
            }
            const localVarPath = `/workflowexecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a Workflow exists.
         * @summary Check if Workflow exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling workflowExists.');
            }
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowsApi - functional programming interface
 * @export
 */
export const WorkflowsApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(workflow: WorkflowDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).createWorkflow(workflow, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowExecution(workflowExecution: WorkflowExecutionDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).createWorkflowExecution(workflowExecution, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyWorkflowExecutions(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).deleteManyWorkflowExecutions(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyWorkflows(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).deleteManyWorkflows(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflow(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).deleteWorkflow(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowExecution(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).deleteWorkflowExecution(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeWorkflow(workflowId: string, data: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).executeWorkflow(workflowId, data, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeWorkflowSync(workflowId: string, data: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).executeWorkflowSync(workflowId, data, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eventName 
         * @param {any} workflowData 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fireWorkflowEvent(eventName: string, workflowData: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).fireWorkflowEvent(eventName, workflowData, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableBlocks(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableWorkflowBlockDto>>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getAvailableBlocks(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflow(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflow(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowEvents(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowEvents(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowExecution(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowExecution(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowExecutions(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionPageDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowExecutions(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowExecutionsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowExecutionsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowTriggerTemplates(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowTriggerTemplateDto>>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowTriggerTemplates(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflows(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowPageDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflows(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).getWorkflowsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchWorkflow(id: string, workflow: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).patchWorkflow(id, workflow, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchWorkflowExecution(id: string, workflowExecution: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).patchWorkflowExecution(id, workflowExecution, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performWorkflowExecutionSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionSearchResultPageDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).performWorkflowExecutionSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performWorkflowSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowSearchResultPageDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).performWorkflowSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkflow(id: string, workflow: WorkflowDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).putWorkflow(id, workflow, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkflowExecution(id: string, workflowExecution: WorkflowExecutionDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowExecutionDto>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).putWorkflowExecution(id, workflowExecution, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a WorkflowExecution exists.
         * @summary Check if WorkflowExecution exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowExecutionExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).workflowExecutionExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a Workflow exists.
         * @summary Check if Workflow exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkflowsApiAxiosParamCreator(axiosClientConfiguration).workflowExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowsApi - factory interface
 * @export
 */
export const WorkflowsApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(workflow: WorkflowDto, translate?: string, options?: any): AxiosPromise<WorkflowDto> {
            return WorkflowsApiFp(axiosClientConfiguration).createWorkflow(workflow, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowExecution(workflowExecution: WorkflowExecutionDto, translate?: string, options?: any): AxiosPromise<WorkflowExecutionDto> {
            return WorkflowsApiFp(axiosClientConfiguration).createWorkflowExecution(workflowExecution, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyWorkflowExecutions(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).deleteManyWorkflowExecutions(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyWorkflows(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).deleteManyWorkflows(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).deleteWorkflow(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowExecution(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).deleteWorkflowExecution(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflow(workflowId: string, data: any, translate?: string, options?: any): AxiosPromise<any> {
            return WorkflowsApiFp(axiosClientConfiguration).executeWorkflow(workflowId, data, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {any} data 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflowSync(workflowId: string, data: any, translate?: string, options?: any): AxiosPromise<any> {
            return WorkflowsApiFp(axiosClientConfiguration).executeWorkflowSync(workflowId, data, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventName 
         * @param {any} workflowData 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fireWorkflowEvent(eventName: string, workflowData: any, translate?: string, options?: any): AxiosPromise<any> {
            return WorkflowsApiFp(axiosClientConfiguration).fireWorkflowEvent(eventName, workflowData, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBlocks(translate?: string, options?: any): AxiosPromise<Array<AvailableWorkflowBlockDto>> {
            return WorkflowsApiFp(axiosClientConfiguration).getAvailableBlocks(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow(id: string, translate?: string, options?: any): AxiosPromise<WorkflowDto> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflow(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowEvents(translate?: string, options?: any): AxiosPromise<Array<string>> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowEvents(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecution(id: string, translate?: string, options?: any): AxiosPromise<WorkflowExecutionDto> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowExecution(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecutions(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<WorkflowExecutionPageDto> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowExecutions(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowExecutionsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowExecutionsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowTriggerTemplates(translate?: string, options?: any): AxiosPromise<Array<WorkflowTriggerTemplateDto>> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowTriggerTemplates(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<WorkflowPageDto> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflows(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return WorkflowsApiFp(axiosClientConfiguration).getWorkflowsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflow(id: string, workflow: any, translate?: string, options?: any): AxiosPromise<WorkflowDto> {
            return WorkflowsApiFp(axiosClientConfiguration).patchWorkflow(id, workflow, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflowExecution(id: string, workflowExecution: any, translate?: string, options?: any): AxiosPromise<WorkflowExecutionDto> {
            return WorkflowsApiFp(axiosClientConfiguration).patchWorkflowExecution(id, workflowExecution, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performWorkflowExecutionSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<WorkflowExecutionSearchResultPageDto> {
            return WorkflowsApiFp(axiosClientConfiguration).performWorkflowExecutionSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performWorkflowSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<WorkflowSearchResultPageDto> {
            return WorkflowsApiFp(axiosClientConfiguration).performWorkflowSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowDto} workflow 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflow(id: string, workflow: WorkflowDto, translate?: string, options?: any): AxiosPromise<WorkflowDto> {
            return WorkflowsApiFp(axiosClientConfiguration).putWorkflow(id, workflow, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkflowExecutionDto} workflowExecution 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflowExecution(id: string, workflowExecution: WorkflowExecutionDto, translate?: string, options?: any): AxiosPromise<WorkflowExecutionDto> {
            return WorkflowsApiFp(axiosClientConfiguration).putWorkflowExecution(id, workflowExecution, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a WorkflowExecution exists.
         * @summary Check if WorkflowExecution exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowExecutionExists(id: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).workflowExecutionExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a Workflow exists.
         * @summary Check if Workflow exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowExists(id: string, options?: any): AxiosPromise<void> {
            return WorkflowsApiFp(axiosClientConfiguration).workflowExists(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiCreateWorkflowRequest
 */
export interface WorkflowsApiCreateWorkflowRequest {
    /**
     * 
     * @type {WorkflowDto}
     * @memberof WorkflowsApiCreateWorkflow
     */
    readonly workflow: WorkflowDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiCreateWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for createWorkflowExecution operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiCreateWorkflowExecutionRequest
 */
export interface WorkflowsApiCreateWorkflowExecutionRequest {
    /**
     * 
     * @type {WorkflowExecutionDto}
     * @memberof WorkflowsApiCreateWorkflowExecution
     */
    readonly workflowExecution: WorkflowExecutionDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiCreateWorkflowExecution
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyWorkflowExecutions operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiDeleteManyWorkflowExecutionsRequest
 */
export interface WorkflowsApiDeleteManyWorkflowExecutionsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowsApiDeleteManyWorkflowExecutions
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteManyWorkflowExecutions
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyWorkflows operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiDeleteManyWorkflowsRequest
 */
export interface WorkflowsApiDeleteManyWorkflowsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowsApiDeleteManyWorkflows
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteManyWorkflows
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiDeleteWorkflowRequest
 */
export interface WorkflowsApiDeleteWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteWorkflow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteWorkflowExecution operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiDeleteWorkflowExecutionRequest
 */
export interface WorkflowsApiDeleteWorkflowExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteWorkflowExecution
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiDeleteWorkflowExecution
     */
    readonly translate?: string
}

/**
 * Request parameters for executeWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteWorkflowRequest
 */
export interface WorkflowsApiExecuteWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiExecuteWorkflow
     */
    readonly workflowId: string

    /**
     * 
     * @type {any}
     * @memberof WorkflowsApiExecuteWorkflow
     */
    readonly data: any

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiExecuteWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for executeWorkflowSync operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteWorkflowSyncRequest
 */
export interface WorkflowsApiExecuteWorkflowSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiExecuteWorkflowSync
     */
    readonly workflowId: string

    /**
     * 
     * @type {any}
     * @memberof WorkflowsApiExecuteWorkflowSync
     */
    readonly data: any

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiExecuteWorkflowSync
     */
    readonly translate?: string
}

/**
 * Request parameters for fireWorkflowEvent operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiFireWorkflowEventRequest
 */
export interface WorkflowsApiFireWorkflowEventRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiFireWorkflowEvent
     */
    readonly eventName: string

    /**
     * 
     * @type {any}
     * @memberof WorkflowsApiFireWorkflowEvent
     */
    readonly workflowData: any

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiFireWorkflowEvent
     */
    readonly translate?: string
}

/**
 * Request parameters for getAvailableBlocks operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetAvailableBlocksRequest
 */
export interface WorkflowsApiGetAvailableBlocksRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetAvailableBlocks
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowRequest
 */
export interface WorkflowsApiGetWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflowEvents operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowEventsRequest
 */
export interface WorkflowsApiGetWorkflowEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowEvents
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflowExecution operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowExecutionRequest
 */
export interface WorkflowsApiGetWorkflowExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecution
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecution
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflowExecutions operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowExecutionsRequest
 */
export interface WorkflowsApiGetWorkflowExecutionsRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutions
     */
    readonly search?: string
}

/**
 * Request parameters for getWorkflowExecutionsCount operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowExecutionsCountRequest
 */
export interface WorkflowsApiGetWorkflowExecutionsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowExecutionsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflowTriggerTemplates operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowTriggerTemplatesRequest
 */
export interface WorkflowsApiGetWorkflowTriggerTemplatesRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowTriggerTemplates
     */
    readonly translate?: string
}

/**
 * Request parameters for getWorkflows operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowsRequest
 */
export interface WorkflowsApiGetWorkflowsRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflows
     */
    readonly search?: string
}

/**
 * Request parameters for getWorkflowsCount operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowsCountRequest
 */
export interface WorkflowsApiGetWorkflowsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiGetWorkflowsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for patchWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPatchWorkflowRequest
 */
export interface WorkflowsApiPatchWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPatchWorkflow
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof WorkflowsApiPatchWorkflow
     */
    readonly workflow: any

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPatchWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for patchWorkflowExecution operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPatchWorkflowExecutionRequest
 */
export interface WorkflowsApiPatchWorkflowExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPatchWorkflowExecution
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof WorkflowsApiPatchWorkflowExecution
     */
    readonly workflowExecution: any

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPatchWorkflowExecution
     */
    readonly translate?: string
}

/**
 * Request parameters for performWorkflowExecutionSearch operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPerformWorkflowExecutionSearchRequest
 */
export interface WorkflowsApiPerformWorkflowExecutionSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof WorkflowsApiPerformWorkflowExecutionSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPerformWorkflowExecutionSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performWorkflowSearch operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPerformWorkflowSearchRequest
 */
export interface WorkflowsApiPerformWorkflowSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof WorkflowsApiPerformWorkflowSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPerformWorkflowSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putWorkflow operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPutWorkflowRequest
 */
export interface WorkflowsApiPutWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPutWorkflow
     */
    readonly id: string

    /**
     * 
     * @type {WorkflowDto}
     * @memberof WorkflowsApiPutWorkflow
     */
    readonly workflow: WorkflowDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPutWorkflow
     */
    readonly translate?: string
}

/**
 * Request parameters for putWorkflowExecution operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiPutWorkflowExecutionRequest
 */
export interface WorkflowsApiPutWorkflowExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPutWorkflowExecution
     */
    readonly id: string

    /**
     * 
     * @type {WorkflowExecutionDto}
     * @memberof WorkflowsApiPutWorkflowExecution
     */
    readonly workflowExecution: WorkflowExecutionDto

    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiPutWorkflowExecution
     */
    readonly translate?: string
}

/**
 * Request parameters for workflowExecutionExists operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowExecutionExistsRequest
 */
export interface WorkflowsApiWorkflowExecutionExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiWorkflowExecutionExists
     */
    readonly id: string
}

/**
 * Request parameters for workflowExists operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowExistsRequest
 */
export interface WorkflowsApiWorkflowExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkflowsApiWorkflowExists
     */
    readonly id: string
}

/**
 * WorkflowsApi - object-oriented interface
 * @export
 * @class WorkflowsApi
 * @extends {BaseAPI}
 */
export class WorkflowsApi extends BaseAPI {
    /**
     * 
     * @param {WorkflowsApiCreateWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public createWorkflow(requestParameters: WorkflowsApiCreateWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).createWorkflow(requestParameters.workflow, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiCreateWorkflowExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public createWorkflowExecution(requestParameters: WorkflowsApiCreateWorkflowExecutionRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).createWorkflowExecution(requestParameters.workflowExecution, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiDeleteManyWorkflowExecutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public deleteManyWorkflowExecutions(requestParameters: WorkflowsApiDeleteManyWorkflowExecutionsRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).deleteManyWorkflowExecutions(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiDeleteManyWorkflowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public deleteManyWorkflows(requestParameters: WorkflowsApiDeleteManyWorkflowsRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).deleteManyWorkflows(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiDeleteWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public deleteWorkflow(requestParameters: WorkflowsApiDeleteWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).deleteWorkflow(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiDeleteWorkflowExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public deleteWorkflowExecution(requestParameters: WorkflowsApiDeleteWorkflowExecutionRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).deleteWorkflowExecution(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiExecuteWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public executeWorkflow(requestParameters: WorkflowsApiExecuteWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).executeWorkflow(requestParameters.workflowId, requestParameters.data, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiExecuteWorkflowSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public executeWorkflowSync(requestParameters: WorkflowsApiExecuteWorkflowSyncRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).executeWorkflowSync(requestParameters.workflowId, requestParameters.data, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiFireWorkflowEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public fireWorkflowEvent(requestParameters: WorkflowsApiFireWorkflowEventRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).fireWorkflowEvent(requestParameters.eventName, requestParameters.workflowData, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetAvailableBlocksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getAvailableBlocks(requestParameters: WorkflowsApiGetAvailableBlocksRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getAvailableBlocks(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflow(requestParameters: WorkflowsApiGetWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflow(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowEvents(requestParameters: WorkflowsApiGetWorkflowEventsRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowEvents(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowExecution(requestParameters: WorkflowsApiGetWorkflowExecutionRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowExecution(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowExecutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowExecutions(requestParameters: WorkflowsApiGetWorkflowExecutionsRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowExecutions(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowExecutionsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowExecutionsCount(requestParameters: WorkflowsApiGetWorkflowExecutionsCountRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowExecutionsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowTriggerTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowTriggerTemplates(requestParameters: WorkflowsApiGetWorkflowTriggerTemplatesRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowTriggerTemplates(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflows(requestParameters: WorkflowsApiGetWorkflowsRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflows(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiGetWorkflowsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public getWorkflowsCount(requestParameters: WorkflowsApiGetWorkflowsCountRequest = {}, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).getWorkflowsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPatchWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public patchWorkflow(requestParameters: WorkflowsApiPatchWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).patchWorkflow(requestParameters.id, requestParameters.workflow, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPatchWorkflowExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public patchWorkflowExecution(requestParameters: WorkflowsApiPatchWorkflowExecutionRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).patchWorkflowExecution(requestParameters.id, requestParameters.workflowExecution, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPerformWorkflowExecutionSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public performWorkflowExecutionSearch(requestParameters: WorkflowsApiPerformWorkflowExecutionSearchRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).performWorkflowExecutionSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPerformWorkflowSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public performWorkflowSearch(requestParameters: WorkflowsApiPerformWorkflowSearchRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).performWorkflowSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPutWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public putWorkflow(requestParameters: WorkflowsApiPutWorkflowRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).putWorkflow(requestParameters.id, requestParameters.workflow, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowsApiPutWorkflowExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public putWorkflowExecution(requestParameters: WorkflowsApiPutWorkflowExecutionRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).putWorkflowExecution(requestParameters.id, requestParameters.workflowExecution, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a WorkflowExecution exists.
     * @summary Check if WorkflowExecution exists
     * @param {WorkflowsApiWorkflowExecutionExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public workflowExecutionExists(requestParameters: WorkflowsApiWorkflowExecutionExistsRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).workflowExecutionExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a Workflow exists.
     * @summary Check if Workflow exists
     * @param {WorkflowsApiWorkflowExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public workflowExists(requestParameters: WorkflowsApiWorkflowExistsRequest, options?: any) {
        return WorkflowsApiFp(this.axiosClientConfiguration).workflowExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
