/* eslint-disable max-lines-per-function */
import { Injectable, Injector, Signal, inject, isDevMode, untracked } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import * as Sentry from "@sentry/browser";
import { deleteAllIndexDbData } from "@smallstack/client-common";
import { ContextService, EnvironmentKeys, EnvironmentService } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { NotificationService } from "@smallstack/i18n-components";
import {
  DataType,
  DataTypeStorageTarget,
  TypeDescriptor,
  TypeSchema,
  convertTypePathToApiPath
} from "@smallstack/typesystem";
import { removePropertyRecursively } from "@smallstack/utils";
import axios from "axios";
import {
  RxCollection,
  RxDatabase,
  RxReactivityFactory,
  addRxPlugin,
  createRxDatabase,
  lastOfArray
} from "rxdb/plugins/core";
import { RxReplicationState, replicateRxCollection } from "rxdb/plugins/replication";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { Mutex, MutexLibrary, synchronized } from "synchronized-ts";
import { TypeService } from "../services/type.service";
import { createAxiosHeaders } from "../utils/axios-header.utils";
import { StoreInitOptions } from "./entity.store";

if (isDevMode()) {
  void import("rxdb/plugins/dev-mode").then((module) => {
    module.disableWarnings();
    addRxPlugin(module.RxDBDevModePlugin);
  });
}

export function createReactivityFactory(injector: Injector): RxReactivityFactory<Signal<any>> {
  return {
    fromObservable(observable$, initialValue: any) {
      return untracked(() =>
        toSignal(observable$, {
          initialValue,
          injector,
          rejectErrors: true
        })
      );
    }
  };
}

// Logger.addDebugModule("RxEntityStoreDatabase");

@Injectable({ providedIn: "root" })
export class RxEntityStoreDatabase {
  #environmentService = inject(EnvironmentService);
  #contextService = inject(ContextService);
  #typeService = inject(TypeService);
  #replicationStates: { [collectionName: string]: RxReplicationState<any, any> } = {};
  #syncedDatabaseCreation = new Mutex<any>();
  #syncedCollectionCreation = new MutexLibrary<any>();
  #notificationService = inject(NotificationService);

  protected database: RxDatabase;
  protected injector = inject(Injector);

  @synchronized
  public async getDatabase(): Promise<RxDatabase> {
    if (this.database) return this.database;
    return this.#syncedDatabaseCreation.sync(async () => {
      if (!this.database)
        this.database = await createRxDatabase({
          name: "cloud",
          storage: getRxStorageDexie(),
          reactivity: createReactivityFactory(this.injector)
        });
      return this.database;
    });
  }

  public async createCollection(options: StoreInitOptions): Promise<RxCollection> {
    // at least types need to be loaded first
    await this.#typeService.awaitLoaded(options.tenantId);

    // shortcut: if the collection already exists, return it
    const collectionName = this.getCollectionName(options.tenantId, options.typeDescriptor);
    const database = await this.getDatabase();
    if (database[collectionName]) return database[collectionName];

    return this.#syncedCollectionCreation.by(collectionName as any).sync(async () => {
      // recheck if the collection was created in the meantime
      if (database[collectionName]) return database[collectionName];

      // get type & schema
      const type = this.#typeService.getByPath(options.typeDescriptor.typePath);
      if (!type) throw new Error("Type not found for path " + options.typeDescriptor.typePath);
      const schema = this.#typeService.getByPath(options.typeDescriptor.typePath, {
        tenantId: options.tenantId
      })?.schema;
      if (!schema) {
        Logger.error(
          "createRxDataGcpCollection",
          "[" + collectionName + "]   |-- Schema not found for type " + options.typeDescriptor.typePath
        );
        throw new Error("Schema not found for type " + options.typeDescriptor.typePath);
      }

      // create the collection
      const collection = await this.createRxCollectionWithSchema(database, collectionName, schema);

      // create replication state based on storage type
      this.#replicationStates[collectionName] = await this.createReplicationState(collection, type, options);

      // return collection
      return collection;
    });
  }

  private async createReplicationState(
    collection: RxCollection,
    type: DataType,
    storeInitOptions: StoreInitOptions
  ): Promise<RxReplicationState<any, any>> {
    // add defaults
    if (!type.storage) type.storage = {};
    if (!type.storage.pull)
      type.storage.pull = {
        target: DataTypeStorageTarget.RxDataGCP
      };
    if (!type.storage.push)
      type.storage.push = {
        target: DataTypeStorageTarget.RxDataGCP
      };

    const replicationState = replicateRxCollection<{ id: string }, { lastUpdatedAt: number; id: string }>({
      collection,
      replicationIdentifier: "cloud-solution-rx-data-gcp-" + collection.name,
      pull:
        type.storage?.pull === undefined
          ? undefined
          : {
              handler: async (checkpointOrNull, batchSize) => {
                try {
                  const lastUpdatedAt = checkpointOrNull ? checkpointOrNull.lastUpdatedAt : 0;
                  const id = checkpointOrNull ? checkpointOrNull.id : undefined;
                  switch (type.storage.pull.target) {
                    case DataTypeStorageTarget.RxDataGCP: {
                      const apiUrl = this.#environmentService.get(EnvironmentKeys.API_URL);
                      const url = `${apiUrl}/rx-data/pull?lastUpdatedAt=${lastUpdatedAt}&id=${id}&limit=${batchSize}&typePath=${storeInitOptions.typeDescriptor.typePath}&tenantId=${storeInitOptions.tenantId}`;
                      Logger.debug("createRxDataGcpCollection", "[" + collection.name + "]   |-- querying : " + url);
                      const response = await axios(url, {
                        headers: createAxiosHeaders(this.#contextService.context())
                      });
                      const data = response.data;
                      Logger.debug(
                        "createRxDataGcpCollection",
                        "[" + collection.name + "]   |-- done, response: ",
                        data
                      );
                      return {
                        documents: data.documents,
                        checkpoint: data.checkpoint
                      };
                    }
                    case DataTypeStorageTarget.RestEndpoint: {
                      let url: string = type.storage.pull?.options.apiUrl;
                      if (!url)
                        url =
                          this.#environmentService.get(EnvironmentKeys.API_URL) +
                          "/" +
                          convertTypePathToApiPath(type.path);
                      if (!url.startsWith("http")) {
                        if (!url.startsWith("/")) url = "/" + url;
                        url = this.#environmentService.get(EnvironmentKeys.API_URL) + url;
                      }

                      if (lastUpdatedAt > 0) url += "?lastUpdatedAt=" + lastUpdatedAt;

                      Logger.debug("createRestCollection", "[" + collection.name + "]   |-- querying : GET " + url);

                      const models = (
                        await axios.get(url, { headers: createAxiosHeaders(this.#contextService.context()) })
                      ).data;

                      let docs: any[] = [];
                      if (Array.isArray(models)) docs = models;
                      else if (Array.isArray(models.elements)) docs = models.elements;
                      else throw new Error("Could not find elements in response: " + JSON.stringify(models));

                      docs = docs.sort((a, b) => a.lastUpdatedAt - b.lastUpdatedAt);

                      return {
                        documents: docs,
                        checkpoint: { id: lastOfArray(docs)?.id, lastUpdatedAt: lastOfArray(docs)?.lastUpdatedAt }
                      };
                    }
                    default:
                      throw new Error("storage pull target not implemented yet:" + type.storage.pull.target);
                  }
                } catch (e) {
                  this.#notificationService.showErrorNotification(e);
                  Sentry.captureException(e);
                  void replicationState.cancel();
                  throw e;
                }
              },
              batchSize: 1000
            },
      push:
        type.storage.push === undefined
          ? undefined
          : {
              handler: async (changeRows) => {
                try {
                  switch (type.storage.push.target) {
                    case DataTypeStorageTarget.RxDataGCP: {
                      const apiUrl = this.#environmentService.get(EnvironmentKeys.API_URL);
                      const rawResponse = await axios(
                        `${apiUrl}/rx-data/push?typePath=${storeInitOptions.typeDescriptor.typePath}&tenantId=${storeInitOptions.tenantId}`,
                        {
                          method: "PUT",
                          headers: createAxiosHeaders(this.#contextService.context()),
                          data: JSON.stringify(changeRows)
                        }
                      );
                      return rawResponse.data;
                    }
                    case DataTypeStorageTarget.RestEndpoint: {
                      let url: string = type.storage.push?.options.apiUrl;
                      if (!url)
                        url =
                          this.#environmentService.get(EnvironmentKeys.API_URL) +
                          "/" +
                          convertTypePathToApiPath(type.path);
                      if (!url.startsWith("http")) {
                        if (!url.startsWith("/")) url = "/" + url;
                        url = this.#environmentService.get(EnvironmentKeys.API_URL) + url;
                      }

                      const models: any[] = [];
                      for (const changeRow of changeRows) {
                        if (!changeRow.assumedMasterState)
                          models.push(
                            await axios
                              .post(url, changeRow.newDocumentState, {
                                headers: createAxiosHeaders(this.#contextService.context())
                              })
                              .then((response) => response.data)
                          );
                        else
                          models.push(
                            await axios
                              .put(url + "/" + changeRow.newDocumentState.id, changeRow.newDocumentState, {
                                headers: createAxiosHeaders(this.#contextService.context())
                              })
                              .then((response) => response.data)
                          );
                      }
                      return models;
                    }
                    default:
                      throw new Error("storage push target not implemented yet:" + type.storage.push.target);
                  }
                } catch (e) {
                  this.#notificationService.showErrorNotification(e);
                  Sentry.captureException(e);
                  void replicationState.cancel();
                  throw e;
                }
              }
            },
      autoStart: true,
      live: true,
      waitForLeadership: false
    });

    void replicationState.error$.subscribe((error) => {
      // this.#notificationService.notification.error("Error while syncing data", error.message);
      Logger.error("RxEntityStoreDatabase", "[" + collection.name + "] Error while syncing data", error);
      Sentry.captureException(error);
      this.#notificationService.showErrorNotification(error.message);
    });

    // wait for initial state
    await replicationState.awaitInitialReplication();

    return replicationState;
  }

  private async createRxCollectionWithSchema(
    database: RxDatabase,
    collectionName: string,
    schema: TypeSchema
  ): Promise<RxCollection> {
    let schemaProps = removePropertyRecursively(schema.properties, "x-schema-form");
    schemaProps = removePropertyRecursively(schemaProps, "x-schema-type");
    schemaProps = removePropertyRecursively(schemaProps, "x-type-info");
    schemaProps = removePropertyRecursively(schemaProps, "$ref");
    schemaProps = removePropertyRecursively(schemaProps, "default");

    try {
      await database.addCollections({
        [collectionName]: {
          schema: {
            version: 0,
            primaryKey: "id",
            type: "object",
            properties: {
              ...schemaProps,
              id: {
                type: "string",
                maxLength: 100
              }
            },
            additionalProperties: true as any
          },
          autoMigrate: true
          // conflictHandler: (i: RxConflictHandlerInput<any>) => this.createConflictHandler(i)
        }
      });
    } catch (e) {
      Logger.error("createRxCollectionWithSchema", "[" + collectionName + "]   |-- Error while creating collection", e);
      // TODO: That is way too destructive and should be handled more gracefully
      if (e.message?.includes("DB6") || e.message?.includes("DM5")) {
        await database.remove();
        await deleteAllIndexDbData();
        window.location.reload();
        return;
      }
      throw e;
    }
    return database[collectionName];
  }

  private getCollectionName(tenantId: string, typeDescriptor: TypeDescriptor) {
    return "tenants/" + tenantId + "/" + typeDescriptor.typePath.replace(/\//g, "-").toLowerCase();
  }

  public getReplicationState(typeDescriptor: TypeDescriptor, tenantId: string): RxReplicationState<any, any> {
    const collectionName = this.getCollectionName(tenantId, typeDescriptor);
    return this.#replicationStates[collectionName];
  }
}
