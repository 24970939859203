@if (userProfileSchema()) {
  <div class="my-4 flex flex-col gap-3">
    @if (customerProfile()) {
      <smallstack-form
        [value]="customerProfile()"
        (valueChange)="customerProfile.set($event)"
        [schema]="userProfileSchema()"
      >
        <smallstack-schema-form-table
          #schemaFormTableComponent
          [showActions]="false"
          [showHeader]="false"
          [showMissingFields]="false"
        >
        </smallstack-schema-form-table>
      </smallstack-form>
    }
    <button class="btn btn-primary btn-sm btn-block" type="submit" color="primary" [loadingFn]="saveUserProfile()">
      <smallstack-i18n data="@@actions.save" style="text-transform: uppercase"></smallstack-i18n>
    </button>
  </div>
} @else {
  <smallstack-loader></smallstack-loader>
}
