import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonConfiguration } from "@smallstack/common-components";
import { NotificationDialogComponent } from "../../components/notification-dialog/notification-dialog.component";
import { Notifier } from "./notifier";

export const DialogPopupButtons = {
  cancelDelete: [
    {
      label: "@@actions.cancel",
      value: false,
      classes: "btn"
    },
    {
      label: "@@actions.delete",
      value: true,
      classes: "btn btn-error text-white"
    }
  ] as MatButtonConfiguration[],
  yesNo: [
    {
      label: "@@actions.no",
      value: false,
      classes: "btn"
    },
    {
      label: "@@actions.yes",
      value: true,
      classes: "btn btn-primary"
    }
  ] as MatButtonConfiguration[]
};

@Injectable({ providedIn: "root" })
export class MaterialDialogNotifier implements Notifier {
  constructor(private matDialog: MatDialog) {}

  public info(title: string, message?: string, onClose?: () => void): void {
    this.openDialog(title, message, onClose, "info");
  }

  public success(title: string, message?: string, onClose?: () => void): void {
    this.openDialog(title, message, onClose, "info");
  }

  public debug(title: string, message?: string, onClose?: () => void): void {
    this.openDialog(title, message, onClose, "info");
  }

  public warn(title: string, message?: string, onClose?: () => void): void {
    this.openDialog(title, message, onClose, "info");
  }

  public error(title: string, message?: string, onClose?: () => void): void {
    this.openDialog(title, message, onClose, "error");
  }

  public async confirmation(
    title: string,
    message: string,
    buttons: MatButtonConfiguration[],
    callback?: (answer: any) => void
  ): Promise<any> {
    if (typeof callback === "function") this.openDialog(title, message, callback, "info", { buttons });
    else return this.openDialogPromise(title, message, "info", { buttons });
  }

  private openDialog(
    title: string,
    message: string,
    onClose: (result?: any) => void,
    cssClass: string,
    additionalDialogData: any = {}
  ) {
    this.matDialog.closeAll();
    additionalDialogData.title = title;
    additionalDialogData.message = message;
    this.matDialog
      .open(NotificationDialogComponent, {
        panelClass: cssClass,
        data: additionalDialogData,
        disableClose: false,
        autoFocus: false,
        closeOnNavigation: true,
        maxWidth: "60vw"
      })
      .beforeClosed()
      .subscribe(onClose);
  }
  private openDialogPromise(title: string, message: string, cssClass: string, additionalDialogData: any = {}) {
    return new Promise((resolve) => {
      additionalDialogData.title = title;
      additionalDialogData.message = message;
      this.matDialog
        .open(NotificationDialogComponent, {
          panelClass: cssClass,
          data: additionalDialogData,
          disableClose: false,
          autoFocus: false,
          closeOnNavigation: true,
          maxWidth: "60vw"
        })
        .beforeClosed()
        .subscribe(resolve);
    });
  }
}
