import { ThemeDto } from "@smallstack/axios-api-client";

export const templates: { [name: string]: ThemeDto } = {
  default: {
    daisyTheme: {
      primary: {
        bgColor: "#4b6bfb",
        textColor: "#ffffff",
        focusColor: "#3655df"
      },
      secondary: {
        bgColor: "#7b92b2",
        textColor: "#ffffff",
        focusColor: "#6683ac"
      },
      accent: {
        bgColor: "#67cba0",
        textColor: "#1f2937",
        focusColor: "#45b987"
      },
      neutral: {
        bgColor: "#ffffff",
        textColor: "#1f2937",
        focusColor: "#ebebeb"
      },
      base100: "#ffffff",
      base200: "#f2f2f2",
      base300: "#e5e6e6",
      baseContent: "#1f2937",
      info: {
        bgColor: "#3abff8",
        textColor: "#1f2937"
      },
      success: {
        textColor: "#1f2937",
        bgColor: "#36d399"
      },
      warning: {
        bgColor: "#fbbd23",
        textColor: "#1f2937"
      },
      error: {
        bgColor: "#f87272",
        textColor: "#1f2937"
      }
    }
  }
};
