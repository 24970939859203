import { ReplaceVariablesContext } from "@smallstack/utils";

/** a thing that can be translated */
export interface Translatable {
  key: string;
  replacers?: ReplaceVariablesContext;
}

export function isTranslatable(obj: any): obj is Translatable {
  if (typeof obj !== "object") return false;
  if (obj?.key !== undefined) return true;
  return false;
}
