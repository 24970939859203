import { Injectable, RendererFactory2 } from "@angular/core";
import { MatButtonConfiguration } from "@smallstack/common-components";
import { TranslationStore } from "../../stores/translation.store";
import { Notifier } from "./notifier";

@Injectable({ providedIn: "root" })
export class DaisyUiSnackbarNotifier implements Notifier {
  private snackBar: HTMLDivElement;
  private renderer = this.rendererFactory.createRenderer(null, null);
  private timeout: NodeJS.Timeout;

  constructor(
    private translationStore: TranslationStore,
    private rendererFactory: RendererFactory2
  ) {}

  public info(title: string, message?: string, onClose?: () => void): void {
    void this.openDialog(title, message, onClose, "info");
  }

  public success(title: string, message?: string, onClose?: () => void): void {
    void this.openDialog(title, message, onClose, "success");
  }

  public debug(title: string, message?: string, onClose?: () => void): void {
    void this.openDialog(title, message, onClose, "info");
  }

  public warn(title: string, message?: string, onClose?: () => void): void {
    void this.openDialog(title, message, onClose, "warning");
  }

  public error(title: string, message?: string, onClose?: () => void): void {
    void this.openDialog(title, message, onClose, "error");
  }

  public async confirmation(
    title: string,
    message: string,
    buttons: MatButtonConfiguration[],
    callback?: (answer: any) => void
  ): Promise<any> {
    return new Promise<boolean>((resolve, reject) => {
      void this.openDialog(
        title,
        message,
        (dismissed: boolean) => {
          if (dismissed) {
            if (typeof callback === "function") callback(true);
            resolve(true);
          } else {
            if (typeof callback === "function") callback(false);
            resolve(false);
          }
        },
        "info",
        buttons instanceof Array ? buttons[0]?.label : undefined
      );
    });
  }

  private openDialog(
    title: string,
    message: string,
    onClose: (dismissed: boolean) => void,
    cssClass: string,
    buttonText: string = "@@actions.ok"
  ): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    title = this.translationStore.translate(title, { showMissingKey: true });
    message = this.translationStore.translate(message, { showMissingKey: true });

    if (!this.snackBar) {
      this.snackBar = this.renderer.createElement("div");
      this.snackBar.setAttribute("id", "daisy-snackbar");
      this.snackBar.style.position = "absolute";
      this.snackBar.style.zIndex = "10000";
      this.snackBar.style.bottom = "0px";
      this.snackBar.style.right = "0px";
      this.snackBar.classList.add(
        "toast",
        "pointer",
        "w-full",
        "md:w-auto",
        "mb-16",
        "md:mb-0",
        "transition-all",
        "duration-300",
        "ease-in-out"
      );
      this.snackBar.addEventListener("click", () => this.close());
      this.renderer.appendChild(document.body, this.snackBar);
    }

    this.snackBar.innerHTML = `<div class="alert alert-${cssClass || "success"} ${
      cssClass || "success"
    }-content p-2 rounded drop-shadow-lg flex flex-col gap-0 items-start justify-start"><div class="font-semibold text-sm">${
      title || ""
    }</div><div class="whitespace-normal text-sm">${message || ""}</div></div>`;
    this.timeout = setTimeout(() => {
      this.close();
    }, 2500);
  }

  private close(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = undefined;
    this.snackBar.innerHTML = "";
  }
}
