import { JsonPipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  effect,
  model
} from "@angular/core";

@Component({
  selector: "smallstack-monaco-editor",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./monaco-editor.component.html",
  standalone: true,
  imports: [JsonPipe]
})
export class MonacoEditorComponent implements AfterViewInit {
  @Input() public options: monaco.editor.IEditorOptions & monaco.editor.IGlobalEditorOptions;

  public value = model<any>();
  @Input()
  public language: string = "json";

  @Input()
  public editorHeight: string;

  @Output()
  public readonly loaded: EventEmitter<monaco.editor.IStandaloneCodeEditor | monaco.editor.IEditor> =
    new EventEmitter();

  @ViewChild("componentHost", { static: true, read: ViewContainerRef })
  public componentHost: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  constructor() {
    effect(() => {
      const value = this.value();
      if (this.componentRef) {
        this.componentRef.setInput("value", value);
      }
    });
  }

  public ngAfterViewInit(): void {
    void import("./real-monaco-editor/real-monaco-editor.component").then((m) => {
      this.componentRef = this.componentHost.createComponent(m.RealMonacoEditorComponent);
      this.componentRef.setInput("value", this.value());
      this.componentRef.setInput("language", this.language);
      this.componentRef.setInput("editorHeight", this.editorHeight);
      this.componentRef.instance.valueChange.subscribe((val: any) => this.value.set(val));
      this.componentRef.instance.loaded.subscribe((val: any) => this.loaded.emit(val));
      this.componentRef.changeDetectorRef.detectChanges();
    });
  }
}
