import { MangoQuery, MangoQuerySelector } from "rxdb";
import { removeNullish } from "./rx-utils";
import { replaceVariablesInObject } from "./text-replacer";

export function convertToMangoSelector(filter: Array<{ [prop: string]: any }>, context?: any): MangoQuerySelector<any> {
  if (!Array.isArray(filter)) return undefined;
  let selector: MangoQuerySelector<any> = { $and: filter };
  selector = removeNullish(selector, { removeEmptyStrings: true, removeEmptyObjects: true });
  selector = replaceVariablesInObject(selector, { currentDate: Date.now(), ...context }, { correctTypes: true });
  // extract $regex and convert into real regex if value is a string
  // walkObject(selector, (key: string, value: any) => {
  //   if (key === "$regex" && typeof value === "string") return new RegExp(value.split("/")[1], value.split("/")[2]);
  //   return value;
  // });
  return selector;
}

export function createRxCollectionName(tenantId: string, path: string): string {
  return tenantId ? tenantId + "/" + path : path;
}

export function mergeMangoQueries(mangoQueryA: MangoQuery, mangoQueryB: MangoQuery): MangoQuery {
  if (!mangoQueryA) return mangoQueryB;
  if (!mangoQueryB) return mangoQueryA;

  const resultingQuery: MangoQuery = {};

  // merge selector
  if (mangoQueryA.selector && mangoQueryB.selector) {
    resultingQuery.selector = {
      $and: [mangoQueryA.selector, mangoQueryB.selector]
    };
  }
  if (mangoQueryA.selector && !mangoQueryB.selector) {
    resultingQuery.selector = mangoQueryA.selector;
  }
  if (!mangoQueryA.selector && mangoQueryB.selector) {
    resultingQuery.selector = mangoQueryB.selector;
  }

  // merge sort
  if (mangoQueryA.sort && mangoQueryB.sort) {
    resultingQuery.sort = [...mangoQueryA.sort, ...mangoQueryB.sort];
  }
  if (mangoQueryA.sort && !mangoQueryB.sort) {
    resultingQuery.sort = mangoQueryA.sort;
  }
  if (!mangoQueryA.sort && mangoQueryB.sort) {
    resultingQuery.sort = mangoQueryB.sort;
  }

  // merge limit
  if (mangoQueryB.limit !== undefined) resultingQuery.limit = mangoQueryB.limit;
  else resultingQuery.limit = mangoQueryA.limit;

  // merge skip
  if (mangoQueryB.skip !== undefined) resultingQuery.skip = mangoQueryB.skip;
  else resultingQuery.skip = mangoQueryA.skip;

  // merge index
  resultingQuery.index = [];
  if (mangoQueryA.index) resultingQuery.index.push(...mangoQueryA.index);
  if (mangoQueryB.index) resultingQuery.index.push(...mangoQueryB.index);
  return removeNullish(resultingQuery, { removeEmptyObjects: true, removeEmptyStrings: true, removeEmptyArrays: true });
}
