import { Injectable } from "@angular/core";
import { AxiosApiClient, InternationalizationApi, LocaleDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class LocaleStore extends PageableCrudStore<LocaleDto> {
  constructor(private apiClient: AxiosApiClient) {
    super();
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<LocaleDto>> {
    const res = await this.apiClient.get(InternationalizationApi).getLocales(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<LocaleDto> {
    const res = await this.apiClient.get(InternationalizationApi).getLocale({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.apiClient.get(InternationalizationApi).deleteLocale({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.apiClient.get(InternationalizationApi).deleteManyLocales({ ids });
    return res.data;
  }
  protected async createModel(model: LocaleDto): Promise<LocaleDto> {
    const res = await this.apiClient.get(InternationalizationApi).createLocale({ locale: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<LocaleDto>): Promise<LocaleDto> {
    const res = await this.apiClient.get(InternationalizationApi).patchLocale({ id, locale: model });
    return res.data;
  }
  protected async putModel(model: LocaleDto): Promise<LocaleDto> {
    const res = await this.apiClient.get(InternationalizationApi).putLocale({ id: model.id, locale: model });
    return res.data;
  }
}
