import { Injectable, Optional } from "@angular/core";
import { AxiosApiClient, InternationalizationApi, LocaleDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { BehaviorSubject } from "rxjs";
import { loadAngularLocaleDataAsync } from "../utils/angular-loader";
import { LocalStorage } from "@smallstack/client-common";

@Injectable({ providedIn: "root" })
export class LocaleService {
  public availableLocales$: BehaviorSubject<LocaleDto[]> = new BehaviorSubject<LocaleDto[]>(undefined);
  public currentLocale$: BehaviorSubject<string> = new BehaviorSubject<string>("de_de");
  public fallbackChains: { [key: string]: string[] } = {
    de_de: ["de_de", "en_us"]
  };

  private fallbackLocale = "de_de";

  constructor(@Optional() private axiosApiClient: AxiosApiClient) {}

  public getCurrentLocale(): string {
    if (this.currentLocale$.value !== undefined) return this.currentLocale$.value;
    return this.fallbackLocale;
  }

  public async setCurrentLocale(locale: string): Promise<void> {
    // check locale first
    if (!new RegExp("^[a-z]{2}_[a-z]{2}$").test(locale))
      return Promise.reject(new Error("not a valid locale: " + locale));
    await loadAngularLocaleDataAsync(locale);
    LocalStorage.setItem("locale", locale);
    if (this.currentLocale$ === undefined) return Promise.reject(new Error("currentLocale$ is still undefined"));
    this.currentLocale$.next(locale);
  }

  public getDefaultLocale(): string {
    const allLocales: LocaleDto[] = this.availableLocales$.value;
    return (
      (allLocales &&
        allLocales
          .filter((locale: LocaleDto) => locale.active === true)
          .sort((localeA: LocaleDto, localeB: LocaleDto) => localeA.index - localeB.index)[0]?.iso) ||
      this.fallbackLocale
    );
  }

  public async getAllLocales(): Promise<LocaleDto[]> {
    if (this.axiosApiClient) {
      const i18nApi = this.axiosApiClient.get(InternationalizationApi);
      if (this.availableLocales$.value === undefined) {
        const localesPage = (await i18nApi.getLocales({ size: 1000 })).data;
        this.availableLocales$.next(localesPage.elements);
      }
      if (this.availableLocales$.value instanceof Array) {
        const fallbackChains = (await i18nApi.getLocaleFallbackChains({})).data;
        for (const item of fallbackChains) this.fallbackChains[item.locale] = item.fallbackChain;
      }
    } else {
      Logger.warning(
        "LocaleService",
        "Please provide an axiosApiClient in your Angular Module if you want to get all locales via localeService.getAllLocales!"
      );
    }
    return this.availableLocales$.value;
  }

  public getFallbackChain(locale: string): string[] {
    return this.fallbackChains[locale];
  }

  public addFallbackChain(locale: string, chain: string[]): void {
    if (!chain || !locale) return;
    if (chain[0] !== locale) throw new Error("Locale chains should always start with the locale itself");
    this.fallbackChains[locale] = chain;
  }

  public async detectLocale(): Promise<void> {
    const allLocales: LocaleDto[] = this.availableLocales$.value;

    if (!allLocales) throw new Error("please call localeService.getAllLocales() first!");

    // check local storage
    const lsLang: string = LocalStorage.getItem("locale");

    if (lsLang && typeof lsLang === "string") {
      if (allLocales.find((l) => l.iso === lsLang) !== undefined) {
        await this.setCurrentLocale(lsLang);
        return;
      }
    }

    // browser locale
    if (typeof navigator !== "undefined" && "language" in navigator) {
      let language: string = navigator.language;

      if (!language) return;

      language = language.replace("-", "_").toLocaleLowerCase();

      let locale: string;
      if (isBrowserLocale(language)) locale = language;
      else if (isBrowserLanguage(language)) locale = this.getLocaleForLanguage(language);

      if (allLocales.find((l) => l.iso === locale)) {
        await this.setCurrentLocale(locale);
        return;
      }
    }

    const defaultLocale = this.getDefaultLocale();
    if (!defaultLocale) throw new Error("no default locale found!");
    await this.setCurrentLocale(defaultLocale);
  }

  public getLocaleForLanguage(language: string): string {
    // TODO: how...
    switch (language) {
      case "en":
        return "en_us";
      default:
        return language + "_" + language;
    }
  }
}

function isBrowserLocale(value: string): boolean {
  return /[a-z]{2}_[a-z]{2}/.test(value);
}

function isBrowserLanguage(value: string): boolean {
  return /[a-z]{2}/.test(value);
}
