import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

/** @deprecated Use <smallstack-avatar> instead! */
@Component({
  selector: "smallstack-avatar-image",
  templateUrl: "./avatar-image.component.html",
  styleUrls: ["./avatar-image.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class AvatarImageComponent {
  @Input()
  public imageSrc: string;

  @Input()
  public label: string;

  @Input()
  public panelClass:
    | "none"
    | "rounded tiny"
    | "rounded small"
    | "rounded medium"
    | "in-card-box"
    | "in-context-switcher"
    | "in-last-company-widget"
    | "in-navigation-header"
    | "in-login-status"
    | "in-contacts-dialog"
    | "in-contacts-list" = "in-card-box";
}
