import { Injectable } from "@angular/core";

export class InMemoryStorage implements Storage {
  private storage: { [key: string]: string } = {};
  [name: string]: any;
  get length() {
    return Object.keys(this.storage).length;
  }
  clear(): void {
    this.storage = {};
  }
  getItem(key: string): string | null {
    return this.storage[key] || null;
  }
  key(index: number): string | null {
    return this.storage[index] || null;
  }
  removeItem(key: string): void {
    delete this.storage[key];
  }
  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }
}

export const LocalStorage = typeof window !== "undefined" ? window.localStorage : new InMemoryStorage();
export const SessionStorage = typeof window !== "undefined" ? window.sessionStorage : new InMemoryStorage();

class StorageService {
  private prefix: string = "";

  constructor(private storageAdapter: Storage) {}

  public clear(): void {
    for (const key in this.storageAdapter) {
      if (key.startsWith(this.prefix)) this.storageAdapter.removeItem(key);
    }
  }
  public getItem(key: string): string | null {
    return this.storageAdapter.getItem(this.prefix + key);
  }
  public removeItem(key: string): void {
    this.storageAdapter.removeItem(this.prefix + key);
  }
  public setItem(key: string, value: string): void {
    this.storageAdapter.setItem(this.prefix + key, value);
  }
  public setPrefix(prefix: string): void {
    this.prefix = prefix || "";
  }
}

@Injectable({ providedIn: "root" })
export class LocalStorageService extends StorageService {
  constructor() {
    super(LocalStorage);
  }
}

@Injectable({ providedIn: "root" })
export class SessionStorageService extends StorageService {
  constructor() {
    super(SessionStorage);
  }
}
