import { Logger } from "@smallstack/core-common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Store } from "./store";

export type StoreIdentifierType = string | number;

export class ObjectStore<T extends { id?: StoreIdentifierType } = any> extends Store<T[]> {
  constructor(protected override initialValue?: any[]) {
    super(initialValue);
  }

  public get empty$(): Observable<boolean> {
    return this.value$.pipe(map((value) => value === undefined || value.length === 0));
  }

  /**
   * Returns a model from local store or undefined if not found
   */
  public getById(id: StoreIdentifierType): T {
    return this.value?.find((m) => m.id === id);
  }

  public hasId(id: StoreIdentifierType): boolean {
    return this.getById(id) !== undefined;
  }

  public addValue(model: T): void {
    this.addValues([model]);
  }

  public addValues(models: T[]): void {
    super.updateValue((previousValues) => {
      if (previousValues === undefined) previousValues = [];
      for (const model of models) {
        if (model === undefined) {
          Logger.error("ObjectStore", "addValues", "model is undefined");
          continue;
        }
        if (model.id === undefined) {
          previousValues.push(model);
        } else if (previousValues.find((m) => m.id === model.id) === undefined) previousValues.push(model);
        // add new
        else previousValues[previousValues.findIndex((m) => m.id === model.id)] = model; // refresh existing
      }
      return previousValues;
    }, false);
  }
}
