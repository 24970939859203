import { ChangeDetectionStrategy, Component, HostBinding, Input, computed, inject, input, model } from "@angular/core";
import { WidgetDto } from "@smallstack/axios-api-client";
import { getJsonByPath, removeJsonByPath, setJsonProperty } from "@smallstack/utils";
import { v4 } from "uuid";
import { WidgetTreeService } from "../../services/widget-tree.service";
import { AddWidgetComponent } from "../add-widget/add-widget.component";
import { WidgetEditorRendererComponent } from "../widget-editor-renderer/widget-editor-renderer.component";
import { WidgetRendererComponent } from "../widget-renderer/widget-renderer.component";

@Component({
  selector: "smallstack-widget-child",
  templateUrl: "./widget-child.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WidgetEditorRendererComponent, AddWidgetComponent, WidgetRendererComponent]
})
export class WidgetChildComponent {
  protected widgetTreeService = inject(WidgetTreeService, { optional: true });

  /** Pass the parent component data here. Will be modified when the child widget gets modified */
  public data = model.required();

  public context = input<any>();

  /** Property Name, e.g. "footerComponent" */
  public property = input<string>();

  protected child = computed(() => {
    const data = this.data();
    if (!data) return undefined;
    return getJsonByPath(data, this.property());
  });

  @Input()
  public widgetTags: string[];

  @HostBinding("style.display")
  public display: string = "contents";

  public dataChanged(data: any): void {
    this.data.set(setJsonProperty(this.data(), this.property() + ".data", data));
  }

  public removeWidget(): void {
    this.data.set(removeJsonByPath(this.data(), this.property()));
  }

  public addWidget(widget: WidgetDto): void {
    this.data.set(
      setJsonProperty(this.data(), this.property(), {
        id: v4(),
        name: widget.name,
        data: widget.data,
        styles: widget.styles
      })
    );
  }

  public componentChange(cmsComponent: WidgetDto): void {
    if (cmsComponent === undefined) this.removeWidget();
    else this.data.set(setJsonProperty(this.data(), this.property(), cmsComponent));
  }
}
