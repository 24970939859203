import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LengthPipe, TranslatePipe } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { AddSchemaEntryComponent } from "./components/add-schema-entry/add-schema-entry.component";
import { FormControlDummyComponent } from "./components/form-control-dummy/form-control-dummy.component";
import { FormControlErrorComponent } from "./components/form-control-error/form-control-error.component";
import { FormFieldTitleComponent } from "./components/form-field-title/form-field-title.component";
import { FormInputContainerComponent } from "./components/form-input-container/form-input-container.component";
import { FormComponent } from "./components/form/form.component";
import { GroupedSchemaFormTableComponent } from "./components/grouped-schema-form-table/grouped-schema-form-table.component";
import { SchemaFormLayoutCustomComponent } from "./components/schema-form-custom-layout/schema-form-custom-layout.component";
import { SchemaFormPlaceholderDirective } from "./components/schema-form-custom-layout/schema-form-placeholder.directive";
import { SchemaFormLayoutComponent } from "./components/schema-form-layout/schema-form-layout.component";
import { SchemaFormTableComponent } from "./components/schema-form-table/schema-form-table.component";
import { SchemaFormWidgetComponent } from "./components/schema-form-widget/schema-form-widget.component";
import { TabbedSchemaFormTableComponent } from "./components/tabbed-schema-form-table/tabbed-schema-form-table.component";

/**
 * @deprecated use standalone components itself
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatTabsModule,
    ReactiveFormsModule,
    SmallstackThemeModule,
    SchemaFormWidgetComponent,
    LengthPipe,
    TranslatePipe,
    I18nComponent,
    AddSchemaEntryComponent,
    FormControlDummyComponent,
    FormControlErrorComponent,
    SchemaFormLayoutCustomComponent,
    SchemaFormLayoutComponent,
    SchemaFormTableComponent,
    FormFieldTitleComponent,
    SchemaFormPlaceholderDirective,
    FormComponent,
    GroupedSchemaFormTableComponent,
    TabbedSchemaFormTableComponent,
    FormInputContainerComponent
  ],
  exports: [
    AddSchemaEntryComponent,
    FormControlDummyComponent,
    FormControlErrorComponent,
    SchemaFormLayoutCustomComponent,
    SchemaFormLayoutComponent,
    SchemaFormTableComponent,
    SchemaFormWidgetComponent,
    FormFieldTitleComponent,
    SchemaFormPlaceholderDirective,
    FormComponent,
    GroupedSchemaFormTableComponent,
    TabbedSchemaFormTableComponent,
    FormInputContainerComponent
  ]
})
export class SmallstackFormCoreModule {}
