import { BooleanEquationOperator } from "@smallstack/utils";
import { DataTypeSchema, MessageSchema, MessageThreadSchema, SearchableTypeFieldSchema } from "../models";
import { TypeSchema } from "./type-schema";
import { TYPE_MESSAGES, TYPE_MESSAGE_THREADS } from "./type-names";


export const StaticSchemas: {[typeName: string]: TypeSchema} = {
  "swagger-sorting-properties": {
    "type": "object",
    "properties": {
      "property": {
        "type": "string"
      },
      "direction": {
        "type": "string",
        "enum": [
          "ascending",
          "descending"
        ]
      }
    },
    "required": [
      "property",
      "direction"
    ]
  },
  "swagger-page-requests": {
    "type": "object",
    "properties": {
      "pageNumber": {
        "type": "number"
      },
      "pageSize": {
        "type": "number"
      },
      "sorting": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SwaggerSortingProperty"
        }
      }
    },
    "required": [
      "pageNumber",
      "pageSize",
      "sorting"
    ]
  },
  "model-context-objects": {
    "type": "object",
    "properties": {
      "serial": {
        "type": "number",
        "description": "The serial can be used to check if the current local object was changed in the database since it was read the last time. On every successful write, the serial number will be increased by 1"
      },
      "tenantId": {
        "type": "string"
      },
      "resellerId": {
        "type": "string"
      }
    }
  },
  "booked-subscriptions": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "subscriptionId": {
        "type": "string"
      },
      "targetId": {
        "type": "string"
      },
      "cancelledAt": {
        "type": "number"
      },
      "trialEndsAt": {
        "type": "number"
      }
    },
    "required": [
      "subscriptionId",
      "targetId"
    ]
  },
  "booked-subscriptions-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/BookedSubscription"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-booked-subscription-dtos": {
    "type": "object",
    "properties": {
      "subscriptionId": {
        "type": "string"
      }
    },
    "required": [
      "subscriptionId"
    ]
  },
  "company-invoices": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "contents": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "projectId": {
        "type": "string"
      },
      "createdAt": {
        "type": "number"
      },
      "dueDate": {
        "type": "number"
      },
      "invoiceNumber": {
        "type": "string"
      },
      "invoiceLink": {
        "type": "string"
      },
      "paymentLink": {
        "type": "string"
      },
      "status": {
        "type": "string",
        "enum": [
          "draft",
          "open",
          "paid",
          "cancelled",
          "unknown"
        ]
      },
      "total": {
        "type": "number"
      }
    },
    "required": [
      "id",
      "projectId",
      "createdAt",
      "invoiceNumber",
      "invoiceLink",
      "status",
      "total"
    ]
  },
  "company-invoices-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CompanyInvoice"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "geo-jsons": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "Point",
          "LineString",
          "Polygon",
          "MultiPoint",
          "MultiLineString",
          "MultiPolygon"
        ]
      },
      "coordinates": {
        "type": "array",
        "items": {
          "type": "number"
        }
      }
    }
  },
  "addresses": {
    "type": "object",
    "properties": {
      "street": {
        "type": "string",
        "title": "Straße"
      },
      "streetNumber": {
        "type": "string",
        "title": "Hausnummer"
      },
      "name": {
        "type": "string"
      },
      "addition": {
        "type": "string",
        "title": "Adress-Zusatz"
      },
      "country": {
        "type": "string",
        "title": "Land"
      },
      "state": {
        "type": "string",
        "title": "Bundesland"
      },
      "county": {
        "type": "string",
        "title": "Region"
      },
      "zip": {
        "type": "string",
        "title": "PLZ"
      },
      "city": {
        "type": "string",
        "title": "Stadt"
      },
      "geoJson": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "$ref": "#/components/schemas/GeoJson"
      }
    },
    "required": [
      "street",
      "streetNumber",
      "country",
      "zip",
      "city"
    ]
  },
  "companies": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "creatorId": {
        "type": "string"
      },
      "logoUrl": {
        "type": "string"
      },
      "taxNumber": {
        "type": "string",
        "pattern": "(DE)?[0-9]{9}"
      },
      "billingAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "billingEmail": {
        "type": "string",
        "title": "E-Mail Adresse"
      }
    },
    "required": [
      "path",
      "name",
      "billingEmail"
    ]
  },
  "company-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Company"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "public-users": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "email": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "displayName": {
        "type": "string"
      },
      "avatarUrl": {
        "type": "string"
      }
    },
    "required": [
      "id",
      "email",
      "displayName"
    ]
  },
  "company-user-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PublicUser"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-companies": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "billingEmail": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "logoUrl": {
        "type": "string"
      },
      "taxNumber": {
        "type": "string",
        "pattern": "(DE)?[0-9]{9}"
      },
      "billingAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      }
    },
    "required": [
      "path",
      "name"
    ]
  },
  "update-companies": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "billingEmail": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "logoUrl": {
        "type": "string"
      },
      "taxNumber": {
        "type": "string",
        "pattern": "(DE)?[0-9]{9}"
      },
      "billingAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      }
    }
  },
  "invitation-subjects": {
    "type": "object",
    "properties": {
      "targetId": {
        "type": "string"
      },
      "targetName": {
        "type": "string"
      },
      "targetType": {
        "type": "string",
        "enum": [
          "project",
          "company",
          "reseller",
          "user"
        ]
      },
      "roleIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "targetId",
      "targetName",
      "targetType"
    ]
  },
  "invitations": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "active": {
        "type": "boolean"
      },
      "inviterUserId": {
        "type": "string"
      },
      "inviterName": {
        "type": "string"
      },
      "inviterEmailAddress": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "targetEmailAddress": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "tenants": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InvitationSubject"
        }
      }
    },
    "required": [
      "inviterUserId",
      "inviterName",
      "inviterEmailAddress",
      "targetEmailAddress"
    ]
  },
  "invitations-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Invitation"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "inner-translations": {
    "type": "object",
    "properties": {
      "locale": {
        "type": "string",
        "pattern": "^[a-z]{2}_[a-z]{2}$"
      },
      "value": {
        "type": "string"
      }
    },
    "required": [
      "value"
    ]
  },
  "navigation-entries": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "item",
          "divider"
        ]
      },
      "label": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "icon": {
        "type": "string"
      },
      "badge": {
        "type": "number"
      },
      "index": {
        "type": "number"
      },
      "visible": {
        "type": "boolean"
      },
      "path": {
        "type": "string"
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "requiredPermission": {
        "type": "string"
      },
      "meta": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "type"
    ]
  },
  "projects": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "companyId": {
        "type": "string"
      },
      "creatorId": {
        "type": "string"
      },
      "logoUrl": {
        "type": "string"
      },
      "navigation": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/NavigationEntry"
        }
      }
    },
    "required": [
      "path",
      "name",
      "companyId"
    ]
  },
  "project-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Project"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "update-credit-cards": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "stored-credit-cards": {
    "type": "object",
    "properties": {
      "cardHolder": {
        "type": "string"
      },
      "last4": {
        "type": "string"
      },
      "brand": {
        "type": "string"
      },
      "expMonth": {
        "type": "number"
      },
      "expYear": {
        "type": "number"
      }
    },
    "required": [
      "cardHolder",
      "last4",
      "brand",
      "expMonth",
      "expYear"
    ]
  },
  "configurations": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "key": {
        "type": "string",
        "title": "Schlüssel",
        "pattern": "^(?!\\.)[a-zA-Z0-9.]*[^.]$"
      },
      "description": {
        "type": "string",
        "title": "Beschreibung"
      },
      "value": {
        "type": "string",
        "title": "Wert"
      },
      "defaultValue": {
        "type": "string",
        "title": "Standardwert"
      },
      "scope": {
        "type": "string",
        "enum": [
          "public",
          "server",
          "system"
        ],
        "title": "Umgebung"
      }
    },
    "required": [
      "key",
      "value",
      "scope"
    ]
  },
  "configuration-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Configuration"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "update-configurations": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "key": {
        "type": "string",
        "title": "Schlüssel",
        "pattern": "^(?!\\.)[a-zA-Z0-9.]*[^.]$"
      },
      "description": {
        "type": "string",
        "title": "Beschreibung"
      },
      "value": {
        "type": "string",
        "title": "Wert"
      },
      "defaultValue": {
        "type": "string",
        "title": "Standardwert"
      },
      "scope": {
        "type": "string",
        "enum": [
          "public",
          "server",
          "system"
        ],
        "title": "Umgebung"
      }
    }
  },
  "countries": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "index": {
        "type": "number"
      },
      "iso": {
        "type": "string",
        "pattern": "[a-z]{2}"
      },
      "active": {
        "type": "boolean"
      }
    },
    "required": [
      "iso"
    ]
  },
  "country-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Country"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "search-requests": {
    "type": "object",
    "properties": {
      "text": {
        "type": "string"
      },
      "pageSize": {
        "type": "number"
      },
      "pageNumber": {
        "type": "number"
      }
    }
  },
  "country-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Country"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "objects": {
    "type": "object",
    "properties": {}
  },
  "cronjobs": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "label": {
        "type": "string"
      },
      "url": {
        "type": "string"
      },
      "body": {
        "type": "object",
        "additionalProperties": false
      },
      "method": {
        "type": "string",
        "enum": [
          "get",
          "post"
        ]
      },
      "crontab": {
        "type": "string"
      },
      "headers": {
        "type": "object",
        "additionalProperties": false
      },
      "serviceTokenId": {
        "type": "string",
        "description": "If set, then the service token will be injected as authorization header when the cronjob runs.",
        "title": "Service Token ID"
      }
    },
    "required": [
      "label",
      "url",
      "method",
      "crontab"
    ]
  },
  "cronjob-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Cronjob"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "cronjob-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Cronjob"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "widgets": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "name": {
        "type": "string",
        "pattern": "^([A-Z][a-z0-9]+)+$"
      },
      "data": {
        "type": "object",
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "monaco"
        }
      },
      "styles": {
        "type": "string",
        "x-schema-form": {
          "widget": "monaco"
        }
      },
      "meta": {
        "type": "object",
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "monaco"
        }
      }
    },
    "required": [
      "name"
    ]
  },
  "socket-connections": {
    "type": "object",
    "properties": {
      "sourceComponentId": {
        "type": "string"
      },
      "sourceSocketName": {
        "type": "string"
      },
      "targetComponentId": {
        "type": "string"
      },
      "targetSocketName": {
        "type": "string"
      }
    },
    "required": [
      "sourceComponentId",
      "sourceSocketName",
      "targetComponentId",
      "targetSocketName"
    ]
  },
  "custom-backoffices": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "icon": {
        "type": "string"
      },
      "mountPoint": {
        "type": "string",
        "pattern": "^[a-z]+(-[a-z]+)*$"
      },
      "cmsComponent": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      },
      "socketConnections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SocketConnection"
        }
      },
      "permission": {
        "type": "string"
      }
    },
    "required": [
      "title"
    ]
  },
  "custom-backoffice-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomBackoffice"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "custom-backoffice-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomBackoffice"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "data-models": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "context": {
        "type": "object",
        "additionalProperties": false
      },
      "ownerId": {
        "type": "string"
      }
    }
  },
  "data-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataModel"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "data-sum-response-dtos": {
    "type": "object",
    "properties": {
      "sum": {
        "type": "number"
      }
    },
    "required": [
      "sum"
    ]
  },
  "data": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "type": {
        "type": "string"
      },
      "feature": {
        "type": "string"
      },
      "model": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "type",
      "feature",
      "model"
    ]
  },
  "http-permission-entities": {
    "type": "object",
    "properties": {
      "getPermission": {
        "type": "string"
      },
      "getAllPermission": {
        "type": "string"
      },
      "putPermission": {
        "type": "string"
      },
      "patchPermission": {
        "type": "string"
      },
      "postPermission": {
        "type": "string"
      },
      "deletePermission": {
        "type": "string"
      }
    }
  },
  "data-type-configurations": {
    "type": "object",
    "properties": {
      "autoIncrementProperty": {
        "type": "string",
        "pattern": "^[^0-9][a-zA-Z0-9$_]+$"
      },
      "ownershipEnabled": {
        "type": "boolean"
      },
      "validate": {
        "type": "boolean"
      },
      "icon": {
        "type": "string",
        "x-schema-form": {
          "widget": "icon"
        }
      },
      "toStringRepresentation": {
        "type": "string"
      },
      "longRepresentation": {
        "type": "string"
      },
      "url": {
        "type": "string"
      }
    }
  },
  "searchable-fields": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "weight": {
        "type": "number"
      }
    },
    "required": [
      "name",
      "weight"
    ]
  },
  "type-variants": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "titlePlural": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "schema": {
        "type": "object",
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "monaco"
        }
      }
    },
    "required": [
      "name",
      "title"
    ]
  },
  "data-types": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "path": {
        "type": "string",
        "title": "Pfad",
        "description": "Will be used as path when accessing data via api and for references in e.g. widgets. Should be lowercased, can contain dashes",
        "pattern": "[a-z-]"
      },
      "schema": {
        "type": "object",
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "monaco"
        }
      },
      "actions": {
        "type": "array",
        "items": {
          "type": "string",
          "pattern": "[a-z]"
        }
      },
      "httpMethodPermissions": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/HttpPermissionEntity"
          }
        ]
      },
      "ownerPermissions": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/HttpPermissionEntity"
          }
        ]
      },
      "configuration": {
        "additionalProperties": false,
        "title": "Konfiguration",
        "allOf": [
          {
            "$ref": "#/components/schemas/DataTypeConfiguration"
          }
        ]
      },
      "searchableFields": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SearchableField"
        }
      },
      "variants": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TypeVariant"
        }
      }
    },
    "required": [
      "title",
      "path"
    ]
  },
  "data-type-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataType"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "data-type-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataType"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "claim-devices": {
    "type": "object",
    "properties": {
      "pairingPin": {
        "type": "string"
      },
      "deviceName": {
        "type": "string"
      }
    },
    "required": [
      "pairingPin",
      "deviceName"
    ]
  },
  "device-configuration-properties": {
    "type": "object",
    "properties": {
      "fullscreen": {
        "type": "boolean"
      }
    },
    "required": [
      "fullscreen"
    ]
  },
  "device-configuration-contents": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "webview",
          "application",
          "checkinOld"
        ]
      },
      "data": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "type"
    ]
  },
  "printer-formats": {
    "type": "object",
    "properties": {
      "formId": {
        "type": "string"
      },
      "zpl": {
        "type": "string"
      },
      "labelWidth": {
        "type": "number"
      },
      "labelHeight": {
        "type": "number"
      },
      "printDensity": {
        "type": "number"
      }
    },
    "required": [
      "zpl",
      "labelWidth",
      "labelHeight",
      "printDensity"
    ]
  },
  "printer-configurations": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "configName": {
        "type": "string"
      },
      "formats": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PrinterFormat"
        }
      }
    },
    "required": [
      "configName",
      "formats"
    ]
  },
  "device-configurations": {
    "type": "object",
    "properties": {
      "properties": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DeviceConfigurationProperties"
          }
        ]
      },
      "content": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DeviceConfigurationContent"
          }
        ]
      },
      "printer": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/PrinterConfiguration"
          }
        ]
      }
    }
  },
  "devices": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "uuid": {
        "type": "string"
      },
      "pairingPin": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "lastContact": {
        "type": "number"
      },
      "configuration": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DeviceConfiguration"
          }
        ]
      }
    },
    "required": [
      "name"
    ]
  },
  "registered-device-requests": {
    "type": "object",
    "properties": {
      "uuid": {
        "type": "string"
      },
      "pairingPin": {
        "type": "string"
      }
    },
    "required": [
      "uuid"
    ]
  },
  "registration-details": {
    "type": "object",
    "properties": {
      "uuid": {
        "type": "string"
      },
      "pairingPin": {
        "type": "string"
      },
      "status": {
        "type": "string",
        "enum": [
          "registrationNeeded",
          "registrationDone"
        ]
      },
      "targetTenantId": {
        "type": "string"
      },
      "targetResellerId": {
        "type": "string"
      }
    },
    "required": [
      "uuid",
      "status"
    ]
  },
  "device-statuses": {
    "type": "object",
    "properties": {
      "registrationDetails": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/RegistrationDetails"
          }
        ]
      },
      "device": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Device"
          }
        ]
      }
    },
    "required": [
      "registrationDetails"
    ]
  },
  "device-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Device"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "device-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Device"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "buffers": {
    "type": "object",
    "properties": {}
  },
  "email-attachments": {
    "type": "object",
    "properties": {
      "data": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Buffer"
          }
        ]
      },
      "filename": {
        "type": "string"
      },
      "contentType": {
        "type": "string"
      }
    },
    "required": [
      "data",
      "filename",
      "contentType"
    ]
  },
  "send-email-options": {
    "type": "object",
    "properties": {
      "parameters": {
        "type": "object",
        "additionalProperties": false
      },
      "from": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "attachments": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailAttachment"
        }
      }
    }
  },
  "email-recipients": {
    "type": "object",
    "properties": {
      "to": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailRecipient"
        }
      },
      "cc": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailRecipient"
        }
      },
      "bcc": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailRecipient"
        }
      }
    }
  },
  "email-status-entries": {
    "type": "object",
    "properties": {
      "status": {
        "type": "string"
      },
      "message": {
        "type": "string"
      },
      "date": {
        "type": "number"
      }
    },
    "required": [
      "status",
      "date"
    ]
  },
  "emails": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "subject": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "content": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "from": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "htmlMail": {
        "type": "boolean"
      },
      "recipients": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/EmailRecipients"
          }
        ]
      },
      "logs": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailStatusEntry"
        }
      }
    },
    "required": [
      "subject",
      "content",
      "from",
      "htmlMail",
      "recipients"
    ]
  },
  "email-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Email"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "email-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Email"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "email-templates": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "content": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "subject": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "from": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "htmlMail": {
        "type": "boolean"
      }
    },
    "required": [
      "name",
      "content",
      "subject"
    ]
  },
  "email-template-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "email-template-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/EmailTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "sources": {
    "type": "object",
    "properties": {}
  },
  "converters": {
    "type": "object",
    "properties": {}
  },
  "exporter-entity-pages": {
    "type": "object",
    "properties": {
      "sources": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Source"
        }
      },
      "converters": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Converter"
        }
      }
    },
    "required": [
      "sources",
      "converters"
    ]
  },
  "export-requests": {
    "type": "object",
    "properties": {
      "sourceName": {
        "type": "string"
      },
      "sourceOptions": {
        "type": "object",
        "additionalProperties": false
      },
      "converterName": {
        "type": "string"
      },
      "converterOptions": {
        "type": "object",
        "additionalProperties": false
      },
      "impexOptions": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "sourceName",
      "converterName"
    ]
  },
  "task-statuses": {
    "type": "object",
    "properties": {
      "date": {
        "type": "number"
      },
      "state": {
        "type": "string",
        "enum": [
          "created",
          "running",
          "failed",
          "completed"
        ]
      },
      "message": {
        "type": "string"
      }
    },
    "required": [
      "date",
      "state"
    ]
  },
  "task-logs": {
    "type": "object",
    "properties": {
      "timestamp": {
        "type": "number"
      },
      "text": {
        "type": "string"
      },
      "extra": {
        "type": "string"
      },
      "level": {
        "type": "string",
        "enum": [
          "info",
          "debug",
          "warn",
          "error"
        ]
      }
    },
    "required": [
      "timestamp",
      "text",
      "level"
    ]
  },
  "tasks": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "status": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TaskStatus"
        }
      },
      "currentStatus": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/TaskStatus"
          }
        ]
      },
      "statusPercentage": {
        "type": "number"
      },
      "type": {
        "type": "string",
        "enum": [
          "export",
          "workflow",
          "http",
          "file-deletion",
          "project-deletion",
          "mailing-delivery",
          "test",
          "project-creation-sample-data"
        ]
      },
      "ownerId": {
        "type": "string"
      },
      "payload": {
        "type": "object",
        "additionalProperties": false
      },
      "logs": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TaskLog"
        }
      }
    },
    "required": [
      "type"
    ]
  },
  "features": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "name": {
        "type": "string",
        "pattern": "[a-z]"
      },
      "groupName": {
        "type": "string",
        "pattern": "[a-z]"
      },
      "limitNames": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "version": {
        "type": "number"
      }
    },
    "required": [
      "id",
      "name",
      "version"
    ]
  },
  "importers": {
    "type": "object",
    "properties": {}
  },
  "import-readers": {
    "type": "object",
    "properties": {}
  },
  "importer-entity-pages": {
    "type": "object",
    "properties": {
      "importers": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Importer"
        }
      },
      "readers": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ImportReader"
        }
      }
    },
    "required": [
      "importers",
      "readers"
    ]
  },
  "import-requests": {
    "type": "object",
    "properties": {
      "importerName": {
        "type": "string"
      },
      "importerOptions": {
        "type": "object",
        "additionalProperties": false
      },
      "readerName": {
        "type": "string"
      },
      "readerOptions": {
        "type": "object",
        "additionalProperties": false
      },
      "base64Input": {
        "type": "string"
      }
    },
    "required": [
      "importerName",
      "readerName",
      "base64Input"
    ]
  },
  "import-results": {
    "type": "object",
    "properties": {
      "importCount": {
        "type": "number"
      }
    },
    "required": [
      "importCount"
    ]
  },
  "create-invitation-subjects": {
    "type": "object",
    "properties": {
      "targetId": {
        "type": "string"
      },
      "roleIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "targetId"
    ]
  },
  "create-invitations": {
    "type": "object",
    "properties": {
      "targetEmailAddress": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "tenants": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CreateInvitationSubject"
        }
      }
    },
    "required": [
      "targetEmailAddress",
      "tenants"
    ]
  },
  "languages": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "index": {
        "type": "number"
      },
      "iso": {
        "type": "string"
      },
      "active": {
        "type": "boolean"
      }
    },
    "required": [
      "iso"
    ]
  },
  "language-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Language"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "language-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Language"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "locale-fallback-chains": {
    "type": "object",
    "properties": {
      "locale": {
        "type": "string"
      },
      "fallbackChain": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "locale",
      "fallbackChain"
    ]
  },
  "locales": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "index": {
        "type": "number"
      },
      "iso": {
        "type": "string",
        "pattern": "^[a-z]{2}_[a-z]{2}$"
      },
      "fallback": {
        "type": "string",
        "pattern": "^[a-z]{2}_[a-z]{2}$"
      },
      "active": {
        "type": "boolean"
      }
    },
    "required": [
      "iso"
    ]
  },
  "locale-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Locale"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "locale-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Locale"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "user-auths": {
    "type": "object",
    "properties": {}
  },
  "user-ssos": {
    "type": "object",
    "properties": {
      "azureAdOid": {
        "type": "string"
      }
    }
  },
  "user-emails": {
    "type": "object",
    "properties": {
      "address": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "validated": {
        "type": "boolean",
        "title": "Validiert?"
      },
      "validatedAt": {
        "type": "number",
        "title": "Datum der Validierung"
      },
      "isDefault": {
        "type": "boolean",
        "title": "Standard Adresse?"
      }
    },
    "required": [
      "address"
    ]
  },
  "user-profiles": {
    "type": "object",
    "properties": {}
  },
  "user-configurations": {
    "type": "object",
    "properties": {
      "key": {
        "type": "string",
        "title": "Schlüssel"
      },
      "value": {
        "type": "object",
        "additionalProperties": false,
        "title": "Wert"
      }
    },
    "required": [
      "key",
      "value"
    ]
  },
  "accepted-opt-ins": {
    "type": "object",
    "properties": {
      "optInId": {
        "type": "string"
      },
      "acceptedAt": {
        "type": "number"
      }
    },
    "required": [
      "optInId",
      "acceptedAt"
    ]
  },
  "users": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "auth": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/UserAuth"
          }
        ]
      },
      "sso": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/UserSso"
          }
        ]
      },
      "emails": {
        "additionalProperties": false,
        "title": "E-Mail Adressen",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserEmail"
        }
      },
      "profile": {
        "additionalProperties": false,
        "description": "Benutzerdaten, welche vom Benutzer selbst verändert werden können.",
        "title": "Benutzer Profil",
        "allOf": [
          {
            "$ref": "#/components/schemas/UserProfile"
          }
        ]
      },
      "configuration": {
        "additionalProperties": false,
        "description": "Benutzerkonfiguration, die zwar vom Benutzer eingesehen, aber nicht verändert werden kann.",
        "title": "Konfiguration",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserConfiguration"
        }
      },
      "acceptedOptIns": {
        "additionalProperties": false,
        "title": "Akzeptierte Opt-Ins",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/AcceptedOptIn"
        }
      },
      "active": {
        "type": "boolean",
        "readOnly": true,
        "title": "Benutzer aktiv?"
      },
      "displayName": {
        "type": "string",
        "readOnly": true,
        "title": "Anzeigename"
      },
      "primaryEmailAddress": {
        "type": "string",
        "readOnly": true,
        "title": "Primäre Email Adresse"
      },
      "primaryEmail": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/UserEmail"
          }
        ]
      }
    }
  },
  "register-users": {
    "type": "object",
    "properties": {
      "email": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "username": {
        "type": "string",
        "pattern": "[a-zA-Z0-9\\\\.].*"
      },
      "password": {
        "type": "string"
      },
      "inviteToken": {
        "type": "string"
      },
      "optInIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "redirectUrl": {
        "type": "string"
      }
    }
  },
  "current-users": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      },
      "user": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/User"
          }
        ]
      }
    },
    "required": [
      "token",
      "user"
    ]
  },
  "patch-user-dtos": {
    "type": "object",
    "properties": {
      "firstName": {
        "type": "string"
      },
      "lastName": {
        "type": "string"
      },
      "profile": {
        "type": "object",
        "additionalProperties": false
      }
    }
  },
  "login-user-dtos": {
    "type": "object",
    "properties": {
      "email": {
        "type": "string"
      },
      "password": {
        "type": "string"
      }
    },
    "required": [
      "email",
      "password"
    ]
  },
  "email-password-logins": {
    "type": "object",
    "properties": {
      "email": {
        "type": "string"
      },
      "password": {
        "type": "string"
      }
    },
    "required": [
      "email",
      "password"
    ]
  },
  "username-password-logins": {
    "type": "object",
    "properties": {
      "username": {
        "type": "string",
        "pattern": "[a-zA-Z0-9\\\\.].*"
      },
      "password": {
        "type": "string"
      }
    },
    "required": [
      "password"
    ]
  },
  "new-password-dtos": {
    "type": "object",
    "properties": {
      "password": {
        "type": "string"
      }
    },
    "required": [
      "password"
    ]
  },
  "reset-password-bodies": {
    "type": "object",
    "properties": {
      "email": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "redirectUrl": {
        "type": "string"
      }
    },
    "required": [
      "email"
    ]
  },
  "reset-password-with-token-bodies": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      },
      "password": {
        "type": "string"
      }
    },
    "required": [
      "token",
      "password"
    ]
  },
  "reset-password-with-token-responses": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "azure-login-requests": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "azure-merge-requests": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "user-jwts": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string"
      },
      "token": {
        "type": "string"
      }
    },
    "required": [
      "type",
      "token"
    ]
  },
  "my-permissions": {
    "type": "object",
    "properties": {
      "targetType": {
        "type": "string",
        "enum": [
          "project",
          "company",
          "reseller",
          "user"
        ]
      },
      "targetId": {
        "type": "string"
      },
      "permissionName": {
        "type": "string",
        "pattern": "[a-z.]"
      }
    },
    "required": [
      "targetType",
      "targetId",
      "permissionName"
    ]
  },
  "order-positions": {
    "type": "object",
    "properties": {
      "product": {
        "type": "object",
        "additionalProperties": false
      },
      "amount": {
        "type": "number"
      },
      "addedAt": {
        "type": "number"
      }
    },
    "required": [
      "product",
      "amount",
      "addedAt"
    ]
  },
  "order-statuses": {
    "type": "object",
    "properties": {
      "date": {
        "type": "number"
      },
      "status": {
        "type": "string",
        "enum": [
          "placed",
          "progressing",
          "delivering",
          "delivered",
          "canceled"
        ]
      }
    },
    "required": [
      "date",
      "status"
    ]
  },
  "orders": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "orderPositions": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/OrderPosition"
        }
      },
      "orderDate": {
        "type": "number"
      },
      "status": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/OrderStatus"
        }
      },
      "deliveryAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "billingAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "ownerId": {
        "type": "string"
      }
    },
    "required": [
      "orderPositions",
      "orderDate",
      "status",
      "deliveryAddress",
      "billingAddress"
    ]
  },
  "order-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Order"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "order-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Order"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "price-zones": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "currency": {
        "type": "string",
        "pattern": "[A-Z]{3}"
      },
      "title": {
        "type": "string"
      }
    },
    "required": [
      "currency",
      "title"
    ]
  },
  "price-zone-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PriceZone"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "price-zone-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PriceZone"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "prices": {
    "type": "object",
    "properties": {
      "value": {
        "type": "number",
        "title": "Wert",
        "x-schema-form": {
          "widget": "Price"
        }
      },
      "priceZoneId": {
        "type": "string",
        "title": "Preiszone",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "price-zones",
          "multiple": false
        }
      }
    },
    "required": [
      "value",
      "priceZoneId"
    ]
  },
  "product-option-values": {
    "type": "object",
    "properties": {
      "prices": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Price"
        }
      },
      "value": {
        "type": "string"
      }
    },
    "required": [
      "value"
    ]
  },
  "product-options": {
    "type": "object",
    "properties": {
      "key": {
        "type": "string",
        "pattern": "[a-zA-Z0-9]"
      },
      "values": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ProductOptionValue"
        }
      },
      "editable": {
        "type": "boolean"
      }
    },
    "required": [
      "key",
      "values"
    ]
  },
  "product-variants": {
    "type": "object",
    "properties": {
      "options": {
        "type": "object",
        "additionalProperties": false
      },
      "prices": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Price"
        }
      }
    },
    "required": [
      "options"
    ]
  },
  "product-images": {
    "type": "object",
    "properties": {
      "fileId": {
        "type": "string"
      },
      "fileName": {
        "type": "string"
      },
      "fileUrl": {
        "type": "string"
      },
      "index": {
        "type": "number"
      },
      "isDefault": {
        "type": "boolean"
      }
    },
    "required": [
      "fileId",
      "fileName",
      "fileUrl"
    ]
  },
  "product-metadata": {
    "type": "object",
    "properties": {}
  },
  "product-suppliers": {
    "type": "object",
    "properties": {
      "supplierId": {
        "type": "string"
      },
      "articleIdentifier": {
        "type": "string"
      }
    },
    "required": [
      "supplierId"
    ]
  },
  "products": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "shortTitle": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "shortDescription": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "prices": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Price"
        }
      },
      "options": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ProductOption"
        }
      },
      "variants": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ProductVariant"
        }
      },
      "images": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ProductImage"
        }
      },
      "active": {
        "type": "boolean"
      },
      "metadata": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/ProductMetadata"
          }
        ]
      },
      "supplier": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/ProductSupplier"
          }
        ]
      },
      "orderable": {
        "type": "boolean",
        "readOnly": true,
        "description": "For a product to be orderable, it has to be active and a supplier must be set."
      }
    },
    "required": [
      "title"
    ]
  },
  "product-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Product"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "product-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Product"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "data-template-id-execution-configurations": {
    "type": "object",
    "properties": {
      "dataTemplateId": {
        "type": "string"
      },
      "applySampledata": {
        "type": "boolean"
      }
    },
    "required": [
      "dataTemplateId"
    ]
  },
  "create-projects": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "companyId": {
        "type": "string"
      },
      "dataTemplateIdExecutionConfigurations": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataTemplateIdExecutionConfiguration"
        }
      }
    },
    "required": [
      "path",
      "name",
      "companyId"
    ]
  },
  "update-projects": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "companyId": {
        "type": "string"
      },
      "dataTemplateIdExecutionConfigurations": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataTemplateIdExecutionConfiguration"
        }
      },
      "navigation": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/NavigationEntry"
        }
      },
      "logoUrl": {
        "type": "string"
      }
    }
  },
  "project-user-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PublicUser"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "object-i-ds": {
    "type": "object",
    "properties": {}
  },
  "material-colors": {
    "type": "object",
    "properties": {
      "primary": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "accent": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "warn": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      }
    },
    "required": [
      "primary",
      "accent",
      "warn"
    ]
  },
  "daisy-theme-focus-colors": {
    "type": "object",
    "properties": {
      "bgColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "textColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "focusColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      }
    },
    "required": [
      "bgColor",
      "textColor",
      "focusColor"
    ]
  },
  "daisy-theme-colors": {
    "type": "object",
    "properties": {
      "bgColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "textColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      }
    },
    "required": [
      "bgColor",
      "textColor"
    ]
  },
  "daisy-themes": {
    "type": "object",
    "properties": {
      "primary": {
        "additionalProperties": false,
        "title": "Primäre Farbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeFocusColor"
          }
        ]
      },
      "secondary": {
        "additionalProperties": false,
        "title": "Sekundäre Farbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeFocusColor"
          }
        ]
      },
      "accent": {
        "additionalProperties": false,
        "title": "Akzent Farbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeFocusColor"
          }
        ]
      },
      "neutral": {
        "additionalProperties": false,
        "title": "Neutrale Farbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeFocusColor"
          }
        ]
      },
      "base100": {
        "type": "string",
        "title": "Basis Farbe 100",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "base200": {
        "type": "string",
        "title": "Basis Farbe 200",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "base300": {
        "type": "string",
        "title": "Basis Farbe 300",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "baseContent": {
        "type": "string",
        "title": "Basis Inhaltsfarbe",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "info": {
        "additionalProperties": false,
        "title": "Infofarbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeColor"
          }
        ]
      },
      "success": {
        "additionalProperties": false,
        "title": "Erfolgsfarbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeColor"
          }
        ]
      },
      "warning": {
        "additionalProperties": false,
        "title": "Warnfarbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeColor"
          }
        ]
      },
      "error": {
        "additionalProperties": false,
        "title": "Fehlerfarbe",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyThemeColor"
          }
        ]
      }
    },
    "required": [
      "primary",
      "secondary",
      "accent",
      "neutral",
      "base100",
      "base200",
      "base300",
      "baseContent",
      "info",
      "success",
      "warning",
      "error"
    ]
  },
  "toolbar-theme-colors": {
    "type": "object",
    "properties": {
      "background": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "text": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      }
    },
    "required": [
      "background",
      "text"
    ]
  },
  "toolbar-themes": {
    "type": "object",
    "properties": {
      "colors": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/ToolbarThemeColors"
          }
        ]
      },
      "height": {
        "type": "number"
      }
    },
    "required": [
      "colors"
    ]
  },
  "header-theme-colors": {
    "type": "object",
    "properties": {
      "background": {
        "type": "string"
      },
      "text": {
        "type": "string"
      }
    },
    "required": [
      "background",
      "text"
    ]
  },
  "gradient-colors": {
    "type": "object",
    "properties": {
      "color": {
        "type": "string"
      },
      "percentage": {
        "type": "number"
      }
    },
    "required": [
      "color",
      "percentage"
    ]
  },
  "theme-gradients": {
    "type": "object",
    "properties": {
      "angle": {
        "type": "number"
      },
      "colors": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/GradientColor"
        }
      }
    },
    "required": [
      "angle",
      "colors"
    ]
  },
  "header-themes": {
    "type": "object",
    "properties": {
      "colors": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/HeaderThemeColors"
          }
        ]
      },
      "gradient": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/ThemeGradient"
          }
        ]
      }
    },
    "required": [
      "colors"
    ]
  },
  "themes": {
    "type": "object",
    "properties": {
      "colors": {
        "additionalProperties": false,
        "title": "Farben (veraltet)",
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/MaterialColors"
          }
        ]
      },
      "daisyTheme": {
        "additionalProperties": false,
        "title": "Farben",
        "allOf": [
          {
            "$ref": "#/components/schemas/DaisyTheme"
          }
        ]
      },
      "toolbar": {
        "additionalProperties": false,
        "description": "Bitte ab sofort Komponenten direkt stylen",
        "title": "Toolbar Theme (veraltet)",
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ToolbarTheme"
          }
        ]
      },
      "header": {
        "additionalProperties": false,
        "description": "Bitte ab sofort Komponenten direkt stylen",
        "title": "Header theme (veraltet)",
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/HeaderTheme"
          }
        ]
      },
      "customStyles": {
        "type": "string",
        "title": "Eigene Styles",
        "x-schema-form": {
          "widget": "monaco",
          "monacoLanguage": "css"
        }
      }
    },
    "required": [
      "daisyTheme"
    ]
  },
  "cooperate-identities": {
    "type": "object",
    "properties": {
      "theme": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Theme"
          }
        ]
      },
      "logoUrl": {
        "type": "string"
      }
    },
    "required": [
      "theme",
      "logoUrl"
    ]
  },
  "resellers": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "title": {
        "type": "string"
      },
      "domains": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "cooperateIdentity": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/CooperateIdentity"
          }
        ]
      }
    },
    "required": [
      "name",
      "title"
    ]
  },
  "reseller-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Reseller"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "update-resellers": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "name": {
        "type": "string"
      },
      "title": {
        "type": "string"
      },
      "domains": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "cooperateIdentity": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/CooperateIdentity"
          }
        ]
      }
    }
  },
  "user-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/User"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "api-versions": {
    "type": "object",
    "properties": {
      "api": {
        "type": "string"
      }
    },
    "required": [
      "api"
    ]
  },
  "api-stats": {
    "type": "object",
    "properties": {
      "projectCount": {
        "type": "number"
      },
      "companyCount": {
        "type": "number"
      },
      "userCount": {
        "type": "number"
      }
    },
    "required": [
      "projectCount",
      "companyCount",
      "userCount"
    ]
  },
  "shopping-cart-positions": {
    "type": "object",
    "properties": {
      "productId": {
        "type": "string"
      },
      "amount": {
        "type": "number"
      },
      "addedAt": {
        "type": "number"
      }
    },
    "required": [
      "productId",
      "amount",
      "addedAt"
    ]
  },
  "shopping-carts": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "orderPositions": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ShoppingCartPosition"
        }
      },
      "ownerId": {
        "type": "string"
      }
    },
    "required": [
      "orderPositions"
    ]
  },
  "place-orders": {
    "type": "object",
    "properties": {
      "deliveryAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "billingAddress": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      }
    },
    "required": [
      "deliveryAddress"
    ]
  },
  "shopping-cart-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ShoppingCart"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "shopping-cart-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ShoppingCart"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "add-subscription-feature-dtos": {
    "type": "object",
    "properties": {
      "feature": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Feature"
          }
        ]
      }
    },
    "required": [
      "feature"
    ]
  },
  "subscription-features": {
    "type": "object",
    "properties": {
      "feature": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Feature"
          }
        ]
      },
      "configuration": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "feature"
    ]
  },
  "subscriptions": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "billingPeriod": {
        "type": "string",
        "enum": [
          "monthly",
          "yearly"
        ]
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "features": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SubscriptionFeature"
        }
      },
      "bookable": {
        "type": "boolean"
      },
      "price": {
        "type": "number"
      },
      "trialDays": {
        "type": "number"
      },
      "companyIdRestrictions": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "name",
      "billingPeriod",
      "price",
      "trialDays"
    ]
  },
  "subscription-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Subscription"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "subscription-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Subscription"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "suppliers": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "type": "string"
      },
      "code": {
        "type": "string",
        "pattern": "[a-z]{1,45}"
      },
      "orderPlacementServiceUrl": {
        "type": "string",
        "description": "If set, this url will be triggered during the order placement process. If the user orders products that apply to this supplier, the Order Placement Service will POST an unsaved order with all applicable orderPositions to this url. The external order placement service has to persist the order itself and should return it. It should also take care of updating the status."
      },
      "orderPlacementServiceAuthHeader": {
        "type": "string",
        "description": "If your placement service needs an auth header, you can set it here."
      },
      "metadata": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "title",
      "code"
    ]
  },
  "supplier-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Supplier"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "supplier-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Supplier"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "translations": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "key": {
        "type": "string"
      },
      "values": {
        "type": "object"
      }
    },
    "required": [
      "key",
      "values"
    ]
  },
  "translation-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Translation"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "translation-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Translation"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "set-password-bodies": {
    "type": "object",
    "properties": {
      "password": {
        "type": "string",
        "title": "Passwort"
      },
      "userId": {
        "type": "string",
        "title": "User Id"
      }
    },
    "required": [
      "password",
      "userId"
    ]
  },
  "create-users": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "auth": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/UserAuth"
          }
        ]
      },
      "sso": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/UserSso"
          }
        ]
      },
      "emails": {
        "additionalProperties": false,
        "title": "E-Mail Adressen",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserEmail"
        }
      },
      "profile": {
        "additionalProperties": false,
        "description": "Benutzerdaten, welche vom Benutzer selbst verändert werden können.",
        "title": "Benutzer Profil",
        "allOf": [
          {
            "$ref": "#/components/schemas/UserProfile"
          }
        ]
      },
      "configuration": {
        "additionalProperties": false,
        "description": "Benutzerkonfiguration, die zwar vom Benutzer eingesehen, aber nicht verändert werden kann.",
        "title": "Konfiguration",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserConfiguration"
        }
      },
      "acceptedOptIns": {
        "additionalProperties": false,
        "title": "Akzeptierte Opt-Ins",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/AcceptedOptIn"
        }
      },
      "active": {
        "type": "boolean",
        "readOnly": true,
        "title": "Benutzer aktiv?"
      },
      "displayName": {
        "type": "string",
        "readOnly": true,
        "title": "Anzeigename"
      },
      "primaryEmailAddress": {
        "type": "string",
        "readOnly": true,
        "title": "Primäre Email Adresse"
      },
      "primaryEmail": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/UserEmail"
          }
        ]
      },
      "welcomeMailTemplateId": {
        "type": "string"
      },
      "username": {
        "type": "string"
      }
    }
  },
  "user-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/User"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "email-verification-dtos": {
    "type": "object",
    "properties": {
      "emailAddress": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "callbackUrl": {
        "type": "string"
      },
      "redirectUrl": {
        "type": "string"
      }
    },
    "required": [
      "emailAddress",
      "redirectUrl"
    ]
  },
  "ogp-tags": {
    "type": "object",
    "properties": {
      "title": {
        "type": "string",
        "title": "Title"
      },
      "image": {
        "type": "string",
        "title": "Image",
        "x-schema-form": {
          "widget": "fileurl"
        }
      },
      "type": {
        "type": "string",
        "title": "Type",
        "default": "website"
      },
      "url": {
        "type": "string",
        "title": "Url"
      },
      "siteName": {
        "type": "string",
        "title": "Site Name"
      },
      "description": {
        "type": "string",
        "title": "Description"
      }
    }
  },
  "twitter-tags": {
    "type": "object",
    "properties": {
      "card": {
        "type": "string",
        "title": "Card",
        "default": "summary_large_image"
      },
      "title": {
        "type": "string",
        "title": "Title"
      },
      "description": {
        "type": "string",
        "title": "Description"
      },
      "image": {
        "type": "string",
        "title": "Image",
        "x-schema-form": {
          "widget": "fileurl"
        }
      },
      "site": {
        "type": "string",
        "title": "Site"
      },
      "creator": {
        "type": "string",
        "title": "Creator"
      }
    },
    "required": [
      "card"
    ]
  },
  "seo-configurations": {
    "type": "object",
    "properties": {
      "description": {
        "type": "string",
        "title": "Seiten Beschreibung",
        "x-schema-form": {
          "widget": "textarea"
        }
      },
      "keywords": {
        "title": "Keywords",
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "robots": {
        "type": "string",
        "title": "Robots Anweisungen",
        "default": "index, follow, max-snippet:[120], max-image-preview:[large]",
        "x-schema-form": {
          "widget": "textarea"
        }
      },
      "ogp": {
        "additionalProperties": false,
        "title": "OGP Tags",
        "allOf": [
          {
            "$ref": "#/components/schemas/OgpTags"
          }
        ]
      },
      "twitter": {
        "additionalProperties": false,
        "title": "Twitter Tags",
        "allOf": [
          {
            "$ref": "#/components/schemas/TwitterTags"
          }
        ]
      }
    }
  },
  "application-contents": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "content": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      },
      "socketConnections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SocketConnection"
        }
      },
      "authenticationRequired": {
        "type": "boolean"
      },
      "layout": {
        "type": "string"
      },
      "seo": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/SeoConfiguration"
          }
        ]
      }
    },
    "required": [
      "path",
      "content"
    ]
  },
  "application-layouts": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "widget": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      }
    },
    "required": [
      "name",
      "widget"
    ]
  },
  "application-domains": {
    "type": "object",
    "properties": {
      "domain": {
        "type": "string"
      },
      "txtVerified": {
        "type": "boolean"
      },
      "cnameVerified": {
        "type": "boolean"
      }
    },
    "required": [
      "domain"
    ]
  },
  "pwa-icons": {
    "type": "object",
    "properties": {
      "size": {
        "type": "number"
      },
      "url": {
        "type": "string",
        "x-schema-form": {
          "widget": "fileurl"
        }
      }
    },
    "required": [
      "size",
      "url"
    ]
  },
  "pwa-configurations": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "shortName": {
        "type": "string"
      },
      "themeColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "backgroundColor": {
        "type": "string",
        "x-schema-form": {
          "widget": "color"
        }
      },
      "favicon": {
        "type": "string",
        "x-schema-form": {
          "widget": "fileurl"
        }
      },
      "icons": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PwaIcon"
        }
      }
    },
    "required": [
      "name",
      "shortName",
      "themeColor",
      "backgroundColor",
      "favicon",
      "icons"
    ]
  },
  "applications": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "content": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ApplicationContent"
        }
      },
      "layouts": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ApplicationLayout"
        }
      },
      "domains": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ApplicationDomain"
        }
      },
      "theme": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Theme"
          }
        ]
      },
      "configuration": {
        "type": "object",
        "additionalProperties": false
      },
      "pwaConfiguration": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/PwaConfiguration"
          }
        ]
      }
    },
    "required": [
      "title",
      "theme"
    ]
  },
  "add-domains": {
    "type": "object",
    "properties": {
      "domain": {
        "type": "string"
      }
    },
    "required": [
      "domain"
    ]
  },
  "dns-record-check-results": {
    "type": "object",
    "properties": {
      "status": {
        "type": "string"
      },
      "message": {
        "type": "string"
      }
    },
    "required": [
      "status",
      "message"
    ]
  },
  "dns-verify-results": {
    "type": "object",
    "properties": {
      "application": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Application"
          }
        ]
      },
      "txtResult": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DnsRecordCheckResult"
          }
        ]
      },
      "cnameResult": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DnsRecordCheckResult"
          }
        ]
      }
    },
    "required": [
      "application",
      "txtResult",
      "cnameResult"
    ]
  },
  "create-applications": {
    "type": "object",
    "properties": {
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      }
    },
    "required": [
      "title"
    ]
  },
  "application-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Application"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "application-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Application"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-service-tokens": {
    "type": "object",
    "properties": {
      "permissions": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "label": {
        "type": "string"
      }
    },
    "required": [
      "permissions"
    ]
  },
  "service-tokens": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "token": {
        "type": "string"
      },
      "label": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "service-token-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ServiceToken"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "service-token-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ServiceToken"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "audit-log-records": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "ownerId": {
        "type": "string"
      },
      "ownerType": {
        "type": "string",
        "enum": [
          "user",
          "serviceToken"
        ]
      },
      "feature": {
        "type": "string"
      },
      "event": {
        "type": "string",
        "enum": [
          "create",
          "update",
          "delete"
        ]
      },
      "targetId": {
        "type": "string"
      },
      "diff": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "type": "object"
        }
      }
    },
    "required": [
      "targetId",
      "diff"
    ]
  },
  "audit-log-record-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/AuditLogRecord"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "linked-models": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "type": {
        "type": "string"
      }
    },
    "required": [
      "id",
      "type"
    ]
  },
  "calendar-entries": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "creatorId": {
        "type": "string"
      },
      "attendeeIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "calendarId": {
        "type": "string"
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "content": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "location": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "start": {
        "type": "number"
      },
      "end": {
        "type": "number"
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "meta": {
        "type": "object",
        "additionalProperties": false
      },
      "links": {
        "additionalProperties": false,
        "x-schema-form": {
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      }
    },
    "required": [
      "calendarId",
      "title",
      "start",
      "end"
    ]
  },
  "calendar-entries-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CalendarEntry"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "foreign-store-configs": {
    "type": "object",
    "properties": {
      "dataType": {
        "type": "string",
        "description": "The name of the data type"
      },
      "dataFeature": {
        "type": "string",
        "description": "The name of the data feature"
      },
      "storeQuery": {
        "type": "string",
        "description": "Filter query for the foreign store"
      },
      "startDateProperty": {
        "type": "string",
        "description": "The property name of the foreign property that holds the start date"
      },
      "endDateProperty": {
        "type": "string",
        "description": "The property name of the foreign property that holds the end date"
      },
      "titleProperty": {
        "type": "string",
        "description": "The property name of the foreign property that holds the title"
      }
    },
    "required": [
      "dataType",
      "dataFeature",
      "startDateProperty",
      "endDateProperty",
      "titleProperty"
    ]
  },
  "calendar-dynamics": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "foreignStore"
        ]
      },
      "foreignStoreConfig": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/ForeignStoreConfig"
          }
        ]
      }
    },
    "required": [
      "type"
    ]
  },
  "calendars": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "color": {
        "type": "string"
      },
      "code": {
        "type": "string"
      },
      "dynamics": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/CalendarDynamics"
          }
        ]
      }
    },
    "required": [
      "name"
    ]
  },
  "calendar-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Calendar"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "calendar-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Calendar"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "calendar-entry-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CalendarEntry"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "calendar-entry-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CalendarEntry"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "roles": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "targetType": {
        "type": "string",
        "enum": [
          "project",
          "company",
          "reseller",
          "user"
        ]
      },
      "name": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "permissions": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "userIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "targetType",
      "name",
      "permissions",
      "userIds"
    ]
  },
  "create-roles": {
    "type": "object",
    "properties": {
      "name": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "permissions": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "userIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "name",
      "permissions",
      "userIds"
    ]
  },
  "role-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Role"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "role-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Role"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "import-scripts": {
    "type": "object",
    "properties": {
      "operation": {
        "type": "string",
        "enum": [
          "insert",
          "insertBulk",
          "update",
          "patch"
        ],
        "description": "Define wether this is an insert, update, upsert or remove operation"
      },
      "type": {
        "type": "string",
        "description": "Set the smallstack type, e.h. 'user'"
      },
      "model": {
        "type": "object",
        "additionalProperties": false,
        "description": "The model for the operation, can include placeholders like {ownerId: \"$PREVIOUSLY_INSERT_CAR_ID\"}, can be empty for remove operation"
      },
      "async": {
        "type": "boolean"
      },
      "query": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "operation",
      "type"
    ]
  },
  "data-templates": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "code": {
        "type": "string",
        "description": "Can be used to identify a data template by a simple string"
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "category": {
        "type": "string",
        "enum": [
          "workflow",
          "project"
        ]
      },
      "importScripts": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ImportScript"
        }
      },
      "sampledata": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ImportScript"
        }
      }
    },
    "required": [
      "name",
      "code",
      "category",
      "importScripts"
    ]
  },
  "data-template-execution-configurations": {
    "type": "object",
    "properties": {
      "dataTemplate": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DataTemplate"
          }
        ]
      },
      "applySampledata": {
        "type": "boolean"
      }
    },
    "required": [
      "dataTemplate"
    ]
  },
  "cms-page-metadata": {
    "type": "object",
    "properties": {}
  },
  "cms-pages": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "rootComponent": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      },
      "data": {
        "type": "object",
        "additionalProperties": false
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "metadata": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/CmsPageMetadata"
          }
        ]
      },
      "socketConnections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SocketConnection"
        }
      }
    }
  },
  "cms-page-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CmsPage"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "cms-page-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CmsPage"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "root-sockets": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": [
          "number",
          "numberarray",
          "string",
          "stringarray",
          "boolean",
          "booleanarray",
          "object",
          "objectarray",
          "undefined"
        ]
      },
      "direction": {
        "type": "string",
        "enum": [
          "in"
        ]
      }
    },
    "required": [
      "name",
      "type",
      "direction"
    ]
  },
  "compound-widgets": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "widget": {
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "widget"
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      },
      "socketConnections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SocketConnection"
        }
      },
      "rootSockets": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/RootSocket"
        }
      }
    },
    "required": [
      "name",
      "widget"
    ]
  },
  "compound-widget-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CompoundWidget"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "compound-widget-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CompoundWidget"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "dashboards": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "widgets": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Widget"
        }
      }
    },
    "required": [
      "title",
      "widgets"
    ]
  },
  "dashboard-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Dashboard"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "dashboard-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Dashboard"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "files": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "size": {
        "type": "number"
      },
      "lastModifiedAt": {
        "type": "number"
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "url": {
        "type": "string"
      },
      "feature": {
        "type": "string"
      },
      "license": {
        "type": "string"
      },
      "licenseUrl": {
        "type": "string"
      },
      "isDirectory": {
        "type": "boolean"
      },
      "parent": {
        "type": "string"
      }
    },
    "required": [
      "name"
    ]
  },
  "unsplash-file-search-options": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string"
      },
      "paginatorPageIndex": {
        "type": "number"
      },
      "paginatorPageSize": {
        "type": "number"
      }
    },
    "required": [
      "query"
    ]
  },
  "unsplash-files": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "width": {
        "type": "number"
      },
      "height": {
        "type": "number"
      },
      "description": {
        "type": "string"
      },
      "alt_description": {
        "type": "string"
      },
      "user": {
        "type": "object",
        "additionalProperties": false
      },
      "links": {
        "type": "object",
        "additionalProperties": false
      },
      "urls": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "id",
      "width",
      "height",
      "description",
      "alt_description",
      "user",
      "links",
      "urls"
    ]
  },
  "unsplash-search-responses": {
    "type": "object",
    "properties": {
      "results": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UnsplashFile"
        }
      },
      "total": {
        "type": "number"
      },
      "total_pages": {
        "type": "number"
      }
    },
    "required": [
      "results",
      "total",
      "total_pages"
    ]
  },
  "file-upload-request-responses": {
    "type": "object",
    "properties": {
      "signedUrl": {
        "type": "string"
      }
    },
    "required": [
      "signedUrl"
    ]
  },
  "file-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/File"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "file-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/File"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "invoice-position-headers": {
    "type": "object",
    "properties": {
      "key": {
        "type": "string"
      },
      "translation": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      }
    },
    "required": [
      "key",
      "translation"
    ]
  },
  "invoice-position-values": {
    "type": "object",
    "properties": {
      "key": {
        "type": "string"
      },
      "translation": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "value": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "key",
      "translation",
      "value"
    ]
  },
  "invoice-positions": {
    "type": "object",
    "properties": {
      "position": {
        "type": "number"
      },
      "values": {
        "additionalProperties": false,
        "description": "Must match the positionHeader keys",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InvoicePositionValue"
        }
      },
      "netto": {
        "type": "number"
      }
    },
    "required": [
      "position",
      "values",
      "netto"
    ]
  },
  "invoices": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "recipient": {
        "title": "Empfänger",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "address": {
        "additionalProperties": false,
        "title": "Rechnungsadresse",
        "x-schema-form": {
          "widget": "googlemaps",
          "asModal": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "counterId": {
        "type": "string",
        "title": "Nummernkreis",
        "x-schema-form": {
          "widget": "storeselect",
          "store": "counters",
          "property": "name"
        }
      },
      "number": {
        "type": "string",
        "title": "Rechnungsnummer",
        "description": "Falls leer, dann wird automatisch der Nummernkreis beim Abschließen der Rechnung verwendet"
      },
      "isDraft": {
        "type": "boolean",
        "default": true,
        "description": "Solange die Rechnung ein Entwurf ist, kann sie jederzeit bearbeitet werden.",
        "title": "Entwurf",
        "x-schema-form": {
          "hidden": true
        }
      },
      "dateOfInvoice": {
        "type": "string",
        "title": "Rechnungsdatum",
        "description": "Falls leer, dann wird das Rechnungsdatum automatisch beim Abschließen der Rechnung gesetzt",
        "pattern": "[0-9]{4}-[0-9]{2}-[0-9]{2}",
        "x-schema-form": {
          "widget": "datestring"
        }
      },
      "positionHeaders": {
        "additionalProperties": false,
        "title": "Spaltennamen",
        "default": [],
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InvoicePositionHeader"
        }
      },
      "positions": {
        "additionalProperties": false,
        "title": "Positionen",
        "default": [],
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InvoicePosition"
        }
      },
      "totalNetto": {
        "type": "number",
        "title": "Netto (total)"
      },
      "customContext": {
        "type": "object",
        "additionalProperties": false,
        "description": "Diese Variablen können beispielsweise in der Rechnungsvorlage verwendet werden. Bei Rechnungserzeugung (beispielsweise als PDF) werden diese dann entsprechend eingesetzt.",
        "title": "Zusätzlicher Kontext",
        "x-schema-form": {
          "widget": "monaco"
        }
      }
    }
  },
  "create-from-worklogs": {
    "type": "object",
    "properties": {
      "counterId": {
        "type": "string"
      },
      "surchargeIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "worklogIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "context": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "worklogIds"
    ]
  },
  "invoice-export-configurations": {
    "type": "object",
    "properties": {
      "invoiceIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "asPdf": {
        "type": "boolean"
      },
      "pdfTemplateId": {
        "type": "string"
      },
      "asWodisXml": {
        "type": "boolean"
      }
    },
    "required": [
      "invoiceIds"
    ]
  },
  "invoice-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Invoice"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "invoice-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Invoice"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "counters": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string",
        "title": "Name"
      },
      "code": {
        "type": "string",
        "title": "Code",
        "pattern": "(^[a-z]|[A-Z])[a-z]*"
      },
      "prefix": {
        "type": "string",
        "title": "Präfix"
      },
      "suffix": {
        "type": "string",
        "title": "Suffix"
      },
      "sequence": {
        "type": "number",
        "title": "Nächster Wert",
        "default": 1
      }
    },
    "required": [
      "name",
      "sequence"
    ]
  },
  "counter-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Counter"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "counter-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Counter"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "pdf-template-page-elements": {
    "type": "object",
    "properties": {
      "positionX": {
        "type": "number"
      },
      "positionY": {
        "type": "number"
      },
      "width": {
        "type": "number"
      },
      "height": {
        "type": "number"
      },
      "type": {
        "type": "string",
        "enum": [
          "text",
          "image",
          "content",
          "barcode"
        ]
      },
      "data": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "positionX",
      "positionY",
      "width",
      "height",
      "type"
    ]
  },
  "pdf-template-backgrounds": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "pdf"
        ],
        "title": "Typ"
      },
      "fileUrl": {
        "type": "string",
        "title": "File Url"
      }
    },
    "required": [
      "type",
      "fileUrl"
    ]
  },
  "pdf-template-content-pages": {
    "type": "object",
    "properties": {
      "elements": {
        "additionalProperties": false,
        "title": "Defines all elements on a pdf page",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PdfTemplatePageElement"
        }
      },
      "background": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/PdfTemplateBackground"
          }
        ]
      }
    },
    "required": [
      "elements"
    ]
  },
  "pdf-templates": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "frontPage": {
        "additionalProperties": false,
        "title": "First page of each pdf",
        "allOf": [
          {
            "$ref": "#/components/schemas/PdfTemplateContentPage"
          }
        ]
      },
      "regularPage": {
        "additionalProperties": false,
        "title": "Any other page of the pdf",
        "allOf": [
          {
            "$ref": "#/components/schemas/PdfTemplateContentPage"
          }
        ]
      }
    },
    "required": [
      "title",
      "frontPage",
      "regularPage"
    ]
  },
  "pdf-template-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PdfTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "pdf-template-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/PdfTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "configurable-properties": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "inline",
          "projectConfiguration"
        ]
      },
      "inlineValue": {
        "type": "string"
      },
      "configurationKey": {
        "type": "string"
      },
      "parser": {
        "type": "string",
        "enum": [
          "number",
          "string",
          "boolean"
        ]
      },
      "displayFormat": {
        "type": "string",
        "enum": [
          "raw",
          "currency"
        ]
      }
    },
    "required": [
      "type",
      "parser"
    ]
  },
  "billing-parties": {
    "type": "object",
    "properties": {
      "linkedType": {
        "type": "string",
        "title": "Verlinkter Datentyp",
        "x-schema-form": {
          "widget": "types"
        }
      },
      "linkedId": {
        "type": "string",
        "title": "Verlinkter Datensatz",
        "x-schema-form": {
          "widget": "AutocompleteTypeSearch",
          "typeProperty": "../linkedType"
        }
      },
      "factor": {
        "type": "number",
        "title": "Faktor",
        "default": 1
      }
    },
    "required": [
      "linkedType",
      "linkedId",
      "factor"
    ]
  },
  "custom-field-values": {
    "type": "object",
    "properties": {
      "value": {
        "type": "string",
        "title": "Wert"
      },
      "title": {
        "title": "Beschreibung des Wertes",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "customFieldId": {
        "type": "string",
        "title": "Benutzerdefiniertes Feld",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "type": "custom-fields"
        }
      }
    },
    "required": [
      "customFieldId"
    ]
  },
  "billing-positions": {
    "type": "object",
    "properties": {
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "type": {
        "type": "string",
        "enum": [
          "fixed",
          "hourlyRate"
        ],
        "title": "Abrechnungstyp",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "viewWidget": "SelectView",
          "values": [
            {
              "label": "Stundensatz",
              "value": "hourlyRate"
            },
            {
              "label": "Fixkosten",
              "value": "fixed"
            }
          ]
        }
      },
      "amount": {
        "type": "number",
        "title": "Stückzahl / Faktor",
        "description": "Bei Fixkosten können Sie hier die Stückzahl definieren, beim Stundensatz den Faktor.",
        "default": 1
      },
      "price": {
        "additionalProperties": false,
        "title": "Preis",
        "x-schema-form": {
          "widget": "ConfigurableProperty",
          "displayFormat": "currency",
          "parser": "number"
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ConfigurableProperty"
          }
        ]
      },
      "billingParties": {
        "additionalProperties": false,
        "title": "Rechnungsparteien",
        "default": [],
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/BillingParty"
        }
      },
      "addSurcharges": {
        "type": "boolean",
        "default": true,
        "description": "Falls ja, dann werden definierte Zuschläge wie Nacht- oder Feiertagszuschlag berechnet.",
        "title": "Zuschläge berechnen?"
      },
      "showSubTasksOnBill": {
        "type": "boolean",
        "default": false,
        "description": "Falls ja, dann werden die Unteraufgaben dieser Aufgabe als Teil der Rechnungsposition auf der Rechnung angezeigt.",
        "title": "Unteraufgaben auf Rechnung anzeigen?"
      },
      "customFields": {
        "additionalProperties": false,
        "title": "Benutzerdefinierte Felder",
        "x-schema-form": {
          "inputWidget": "CustomFieldsInput"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomFieldValue"
        }
      }
    },
    "required": [
      "label",
      "type",
      "price"
    ]
  },
  "todo-entries": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "title": "Beschreibung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "ownerIds": {
        "title": "Zugewiesene Personen",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": true
        },
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "dueDate": {
        "type": "number",
        "title": "Fälligkeitsdatum",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "done": {
        "type": "boolean",
        "title": "Erledigt"
      },
      "links": {
        "additionalProperties": false,
        "title": "Verlinkungen",
        "x-schema-form": {
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      }
    },
    "required": [
      "label"
    ]
  },
  "todo-reminders": {
    "type": "object",
    "properties": {
      "reminderTime": {
        "type": "number",
        "title": "Zeitpunkt",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "message": {
        "title": "Nachricht",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter",
          "textarea": true
        },
        "default": [
          {
            "value": "Fällige Aufgabe"
          }
        ],
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "reminderActive": {
        "type": "boolean",
        "default": true,
        "title": "aktiv"
      }
    },
    "required": [
      "reminderTime",
      "message"
    ]
  },
  "todos": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "title": "Beschreibung",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter",
          "textarea": true
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "icon": {
        "type": "string",
        "title": "Icon",
        "description": "An icon string from icons8",
        "x-schema-form": {
          "group": "Details",
          "hidden": true
        }
      },
      "ownerIds": {
        "title": "Zugewiesene Personen",
        "x-schema-form": {
          "group": "Personen & Zeiterfassung",
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": true,
          "showAddBtn": false
        },
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "links": {
        "additionalProperties": false,
        "title": "Verlinkungen",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      },
      "billingPositions": {
        "additionalProperties": false,
        "title": "Abrechnungspositionen",
        "x-schema-form": {
          "group": "Abrechnung"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/BillingPosition"
        }
      },
      "priority": {
        "type": "number",
        "title": "Priorität",
        "description": "Hohe Zahl -> hohe Priorität",
        "x-schema-form": {
          "group": "Details"
        }
      },
      "status": {
        "type": "string",
        "title": "Bearbeitungsstatus",
        "x-schema-form": {
          "group": "Details"
        }
      },
      "done": {
        "type": "boolean",
        "title": "Erledigt",
        "x-schema-form": {
          "group": "Details"
        }
      },
      "checklistEntries": {
        "additionalProperties": false,
        "title": "Unteraufgaben",
        "x-schema-form": {
          "group": "Details"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoEntry"
        }
      },
      "startTime": {
        "type": "number",
        "title": "Startzeit",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView",
          "group": "Personen & Zeiterfassung"
        }
      },
      "dueDate": {
        "type": "number",
        "title": "Ablaufdatum",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView",
          "group": "Personen & Zeiterfassung"
        }
      },
      "reminders": {
        "additionalProperties": false,
        "title": "Erinnerungen",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoReminder"
        }
      },
      "todoTemplateRefId": {
        "type": "string",
        "description": "Falls die Aufgabe aus einer Vorlage erstellt wurde, wird hier die Referenz gespeichert.",
        "title": "Verknüpfte Aufgaben Vorlage",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "todo-templates",
          "multiple": false,
          "showAddBtn": false
        }
      }
    },
    "required": [
      "label"
    ]
  },
  "todo-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Todo"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "todo-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Todo"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "todo-template-entries": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "title": "Beschreibung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "ownerIds": {
        "title": "Zugewiesene Personen",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": true
        },
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "links": {
        "additionalProperties": false,
        "title": "Verlinkungen",
        "x-schema-form": {
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      }
    },
    "required": [
      "label"
    ]
  },
  "scheduling-rules": {
    "type": "object",
    "properties": {
      "pattern": {
        "type": "string",
        "enum": [
          "once",
          "always",
          "daily",
          "businessdays",
          "weekly"
        ],
        "description": "Describe if this scheduling rule should be apply for daily, weekly, monthly or yearly"
      },
      "each": {
        "type": "number",
        "description": "Set the number on how often the scheduled shall occur again: For every week, enter '1', for every second week enter '2' and so on"
      },
      "patternIncludes": {
        "description": "If you want to exclude days/weeks/months from your schedule, 1 leading",
        "type": "array",
        "items": {
          "type": "number"
        }
      },
      "startTime": {
        "type": "number",
        "description": "Sets a time when the scheduling shall start"
      },
      "endTime": {
        "type": "number",
        "description": "Sets a time when the scheduling shall end. If not set, scheduling will be done forever"
      },
      "hour": {
        "type": "number",
        "description": "Sets the hour of the day"
      },
      "minute": {
        "type": "number",
        "description": "Sets the minute of the hour"
      }
    },
    "required": [
      "pattern",
      "startTime"
    ]
  },
  "todo-templates": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "title": "Beschreibung",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter",
          "textarea": true
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "icon": {
        "type": "string",
        "title": "Icon",
        "description": "An icon string from icons8",
        "x-schema-form": {
          "group": "Details",
          "hidden": true
        }
      },
      "ownerIds": {
        "title": "Zugewiesene Personen",
        "x-schema-form": {
          "group": "Personen & Zeiterfassung",
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": true,
          "showAddBtn": false
        },
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "links": {
        "additionalProperties": false,
        "title": "Verlinkungen",
        "x-schema-form": {
          "group": "Details",
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      },
      "billingPositions": {
        "additionalProperties": false,
        "title": "Abrechnungspositionen",
        "x-schema-form": {
          "group": "Abrechnung"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/BillingPosition"
        }
      },
      "priority": {
        "type": "number",
        "title": "Priorität",
        "description": "Hohe Zahl -> hohe Priorität",
        "x-schema-form": {
          "group": "Details"
        }
      },
      "checklistEntries": {
        "additionalProperties": false,
        "title": "Unteraufgaben",
        "x-schema-form": {
          "group": "Unteraufgaben"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoTemplateEntry"
        }
      },
      "schedulingRule": {
        "additionalProperties": false,
        "title": "Erstellungsregeln",
        "x-schema-form": {
          "widget": "SchedulingRule",
          "group": "Personen & Zeiterfassung"
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/SchedulingRule"
          }
        ]
      }
    },
    "required": [
      "label"
    ]
  },
  "todo-template-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "todo-template-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoTemplate"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "todo-surcharge-date-range-times": {
    "type": "object",
    "properties": {
      "weekday": {
        "type": "number",
        "title": "Wochentag",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "values": [
            {
              "value": 1,
              "label": [
                {
                  "value": "Montag"
                }
              ]
            },
            {
              "value": 2,
              "label": [
                {
                  "value": "Dienstag"
                }
              ]
            },
            {
              "value": 3,
              "label": [
                {
                  "value": "Mittwoch"
                }
              ]
            },
            {
              "value": 4,
              "label": [
                {
                  "value": "Donnerstag"
                }
              ]
            },
            {
              "value": 5,
              "label": [
                {
                  "value": "Freitag"
                }
              ]
            },
            {
              "value": 6,
              "label": [
                {
                  "value": "Samstag"
                }
              ]
            },
            {
              "value": 7,
              "label": [
                {
                  "value": "Sonntag"
                }
              ]
            }
          ]
        }
      },
      "time": {
        "type": "string",
        "pattern": "^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$",
        "x-schema-form": {
          "widget": "time"
        }
      }
    },
    "required": [
      "weekday",
      "time"
    ]
  },
  "todo-surcharge-rules": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "bankHolidays",
          "dateRange"
        ],
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "values": [
            {
              "label": "Feiertage",
              "value": "bankHolidays"
            },
            {
              "label": "Zeitspanne",
              "value": "dateRange"
            }
          ]
        }
      },
      "bankHolidaySurchargeCounty": {
        "type": "string",
        "title": "Bundesland für Feiertage",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "values": [
            {
              "label": "Deutschland (Alle Feiertage für alle Bundesländer)",
              "value": "DE"
            },
            {
              "label": "Baden-Württemberg",
              "value": "BW"
            },
            {
              "label": "Bayern",
              "value": "BY"
            },
            {
              "label": "Berlin",
              "value": "BE"
            },
            {
              "label": "Brandenburg",
              "value": "BB"
            },
            {
              "label": "Bremen",
              "value": "HB"
            },
            {
              "label": "Hamburg",
              "value": "HH"
            },
            {
              "label": "Hessen",
              "value": "HE"
            },
            {
              "label": "Niedersachsen",
              "value": "NI"
            },
            {
              "label": "Mecklenburg-Vorpommern",
              "value": "MV"
            },
            {
              "label": "Nordrhein-Westfalen",
              "value": "NW"
            },
            {
              "label": "Rheinland-Pfalz",
              "value": "RP"
            },
            {
              "label": "Saarland",
              "value": "SL"
            },
            {
              "label": "Sachsen",
              "value": "SN"
            },
            {
              "label": "Sachsen-Anhalt",
              "value": "ST"
            },
            {
              "label": "Schleswig-Holstein",
              "value": "SH"
            },
            {
              "label": "Thüringen",
              "value": "TH"
            }
          ],
          "rules": [
            {
              "action": "hide",
              "if": {
                "dataPath": "../type",
                "operator": BooleanEquationOperator.NOT_EQUALS,
                "value": "bankHolidays"
              }
            }
          ]
        }
      },
      "dateRangeStart": {
        "additionalProperties": false,
        "title": "Anfang",
        "x-schema-form": {
          "rules": [
            {
              "action": "hide",
              "if": {
                "dataPath": "../type",
                "operator": BooleanEquationOperator.NOT_EQUALS,
                "value": "dateRange"
              }
            }
          ]
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/TodoSurchargeDateRangeTime"
          }
        ]
      },
      "dateRangeEnd": {
        "additionalProperties": false,
        "title": "Ende",
        "x-schema-form": {
          "rules": [
            {
              "action": "hide",
              "if": {
                "dataPath": "../type",
                "operator": BooleanEquationOperator.NOT_EQUALS,
                "value": "dateRange"
              }
            }
          ]
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/TodoSurchargeDateRangeTime"
          }
        ]
      }
    },
    "required": [
      "type"
    ]
  },
  "todo-surcharges": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "title": "Titel",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "surchargePercentage": {
        "type": "number",
        "title": "Prozentsatz"
      },
      "rules": {
        "additionalProperties": false,
        "description": "Falls eine der Regeln zutrifft, wird der Zuschlag angewandt.",
        "title": "Regeln",
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoSurchargeRule"
        }
      }
    },
    "required": [
      "title",
      "surchargePercentage"
    ]
  },
  "todo-surcharge-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoSurcharge"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "todo-surcharge-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/TodoSurcharge"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "worklogs": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "ownerId": {
        "type": "string",
        "title": "Besitzer",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": false
        }
      },
      "label": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "startTime": {
        "type": "number",
        "title": "Start",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "endTime": {
        "type": "number",
        "title": "Ende",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "sealed": {
        "type": "boolean",
        "title": "Gesperrt"
      },
      "tags": {
        "title": "Tags",
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "todoRefId": {
        "type": "string",
        "description": "Referenz auf die Aufgabe, die bearbeitet wurde.",
        "title": "Verknüpfte Aufgabe",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "todos",
          "multiple": false,
          "showAddBtn": false,
          "showDetails": true
        }
      },
      "duration": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      }
    },
    "required": [
      "ownerId",
      "label",
      "todoRefId"
    ]
  },
  "worklog-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Worklog"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "worklog-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Worklog"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "user-groups": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "title": "Name",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "userIds": {
        "title": "Gruppen Mitglieder",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "customers",
          "multiple": true,
          "showAddBtn": false
        },
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "name",
      "userIds"
    ]
  },
  "user-group-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserGroup"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "user-group-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/UserGroup"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "friends": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "email": {
        "type": "string",
        "title": "E-Mail Adresse"
      },
      "displayName": {
        "type": "string"
      },
      "avatarUrl": {
        "type": "string"
      },
      "status": {
        "type": "string",
        "enum": [
          "unknown",
          "viaProject",
          "viaCompany",
          "pending",
          "accepted",
          "declined"
        ],
        "default": "unknown"
      }
    },
    "required": [
      "id",
      "email",
      "displayName",
      "status"
    ]
  },
  "inline-custom-field-values": {
    "type": "object",
    "properties": {
      "value": {
        "type": "string",
        "title": "Wert"
      },
      "title": {
        "title": "Beschreibung des Wertes",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      }
    }
  },
  "custom-fields": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "title": "Bezeichnung",
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "key": {
        "type": "string",
        "title": "Schlüssel"
      },
      "type": {
        "type": "string",
        "enum": [
          "string",
          "number",
          "boolean",
          "date",
          "dateString"
        ],
        "title": "Anzeigetyp",
        "description": "Wie soll das Feld angezeigt werden?",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "values": [
            {
              "value": "string",
              "label": [
                {
                  "value": "Zeichenkette"
                },
                {
                  "locale": "en_us",
                  "value": "String"
                }
              ]
            },
            {
              "value": "number",
              "label": [
                {
                  "value": "Nummer"
                },
                {
                  "locale": "en_us",
                  "value": "Number"
                }
              ]
            },
            {
              "value": "date",
              "label": [
                {
                  "value": "Datum & Uhrzeit"
                },
                {
                  "locale": "en_us",
                  "value": "Date & Time"
                }
              ]
            },
            {
              "value": "dateString",
              "label": [
                {
                  "value": "Datum"
                },
                {
                  "locale": "en_us",
                  "value": "Date"
                }
              ]
            },
            {
              "value": "boolean",
              "label": [
                {
                  "value": "Ja/Nein"
                },
                {
                  "locale": "en_us",
                  "value": "Yes/No"
                }
              ]
            }
          ]
        }
      },
      "predefinedValue": {
        "additionalProperties": false,
        "title": "Vordefinierter Wert",
        "allOf": [
          {
            "$ref": "#/components/schemas/InlineCustomFieldValue"
          }
        ]
      }
    },
    "required": [
      "title",
      "key",
      "type"
    ]
  },
  "custom-field-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomField"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "custom-field-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomField"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "corporations": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string",
        "title": "Name"
      },
      "description": {
        "type": "string",
        "title": "Beschreibung",
        "x-schema-form": {
          "widget": "textarea"
        }
      },
      "industry": {
        "type": "string",
        "title": "Branche"
      },
      "website": {
        "type": "string",
        "title": "Webseite",
        "pattern": "^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$"
      },
      "address": {
        "additionalProperties": false,
        "title": "Adresse",
        "x-schema-form": {
          "widget": "googlemaps"
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "logoUrl": {
        "type": "string",
        "title": "Logo",
        "x-schema-form": {
          "widget": "fileurl",
          "viewWidget": "ImageView"
        }
      },
      "email": {
        "type": "string",
        "title": "E-Mail",
        "format": "email",
      },
      "phone": {
        "type": "string",
        "title": "Telefonnummer"
      },
      "links": {
        "additionalProperties": false,
        "x-schema-form": {
          "inputWidget": "LinksInput",
          "viewWidget": "LinksView"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/LinkedModel"
        }
      },
      "customFields": {
        "additionalProperties": false,
        "title": "Benutzerdefinierte Felder",
        "x-schema-form": {
          "inputWidget": "CustomFieldsInput"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CustomFieldValue"
        }
      }
    },
    "required": [
      "name"
    ]
  },
  "corporation-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Corporation"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "corporation-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Corporation"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-notes": {
    "type": "object",
    "properties": {
      "content": {
        "type": "string"
      },
      "visibility": {
        "type": "string",
        "enum": [
          "public",
          "private"
        ]
      },
      "target": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/LinkedModel"
          }
        ]
      }
    },
    "required": [
      "content",
      "visibility"
    ]
  },
  "notes": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "content": {
        "type": "string"
      },
      "visibility": {
        "type": "string",
        "enum": [
          "public",
          "private"
        ]
      },
      "ownerId": {
        "type": "string"
      },
      "target": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/LinkedModel"
          }
        ]
      }
    },
    "required": [
      "content",
      "visibility",
      "ownerId"
    ]
  },
  "note-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Note"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "note-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Note"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "opt-ins": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "text": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "url": {
        "type": "string"
      },
      "required": {
        "type": "boolean"
      },
      "active": {
        "type": "boolean"
      }
    },
    "required": [
      "title",
      "required",
      "active"
    ]
  },
  "opt-in-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/OptIn"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "opt-in-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/OptIn"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "schedule-tasks": {
    "type": "object",
    "properties": {
      "taskType": {
        "type": "string",
        "enum": [
          "export",
          "workflow",
          "http",
          "file-deletion",
          "project-deletion",
          "mailing-delivery",
          "test",
          "project-creation-sample-data"
        ]
      },
      "payload": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "taskType",
      "payload"
    ]
  },
  "create-tasks": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "export",
          "workflow",
          "http",
          "file-deletion",
          "project-deletion",
          "mailing-delivery",
          "test",
          "project-creation-sample-data"
        ]
      },
      "payload": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "type"
    ]
  },
  "task-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Task"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "task-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Task"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-mailing-list-entries": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "identifier": {
        "type": "string"
      },
      "ignoreOptIn": {
        "type": "boolean"
      }
    },
    "required": [
      "type",
      "identifier"
    ]
  },
  "remove-mailing-list-entries": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "identifier": {
        "type": "string"
      },
      "ignoreOptOut": {
        "type": "boolean"
      }
    },
    "required": [
      "type",
      "identifier"
    ]
  },
  "send-mailing-list-contents": {
    "type": "object",
    "properties": {
      "content": {
        "type": "string"
      },
      "subject": {
        "type": "string"
      }
    },
    "required": [
      "content"
    ]
  },
  "create-mailing-lists": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "channelIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "name"
    ]
  },
  "mailing-list-entries": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "identifier": {
        "type": "string"
      },
      "optInToken": {
        "type": "string"
      },
      "optOutToken": {
        "type": "string"
      },
      "isActive": {
        "type": "boolean"
      }
    },
    "required": [
      "type",
      "identifier"
    ]
  },
  "mailing-lists": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "entries": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingListEntry"
        }
      },
      "channelIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      }
    },
    "required": [
      "name"
    ]
  },
  "mailing-list-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingList"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "mailing-list-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingList"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "create-mailing-channels": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "configuration": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "name",
      "type"
    ]
  },
  "mailing-channels": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "capabilities": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "html",
            "text",
            "markdown"
          ]
        }
      },
      "configuration": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "name",
      "type"
    ]
  },
  "mailing-channel-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingChannel"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "mailing-channel-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingChannel"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "mailing-list-recipients": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "sms",
          "email"
        ]
      },
      "identifier": {
        "type": "string"
      }
    },
    "required": [
      "type",
      "identifier"
    ]
  },
  "mailing-deliverables": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "mailingListId": {
        "type": "string"
      },
      "subject": {
        "type": "string"
      },
      "content": {
        "type": "string"
      },
      "recipients": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingListRecipient"
        }
      }
    },
    "required": [
      "mailingListId",
      "content",
      "recipients"
    ]
  },
  "mailing-deliverable-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingDeliverable"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "mailing-deliverable-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/MailingDeliverable"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "opt-in-out-confirmation-bodies": {
    "type": "object",
    "properties": {
      "token": {
        "type": "string"
      }
    },
    "required": [
      "token"
    ]
  },
  "sockets": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": [
          "number",
          "numberarray",
          "string",
          "stringarray",
          "boolean",
          "booleanarray",
          "object",
          "objectarray",
          "undefined"
        ]
      },
      "direction": {
        "type": "string",
        "enum": [
          "in",
          "out"
        ]
      }
    },
    "required": [
      "name",
      "type",
      "direction"
    ]
  },
  "available-workflow-blocks": {
    "type": "object",
    "properties": {
      "definitionId": {
        "type": "string"
      },
      "configurationSchema": {
        "type": "object",
        "additionalProperties": false
      },
      "sockets": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Socket"
        }
      }
    },
    "required": [
      "definitionId"
    ]
  },
  "workflow-triggers": {
    "type": "object",
    "properties": {
      "eventName": {
        "type": "string",
        "pattern": "[a-z.]{1,1024}"
      },
      "payload": {
        "type": "object",
        "additionalProperties": false
      }
    },
    "required": [
      "eventName"
    ]
  },
  "workflow-trigger-templates": {
    "type": "object",
    "properties": {
      "trigger": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/WorkflowTrigger"
          }
        ]
      },
      "label": {
        "type": "string"
      },
      "description": {
        "type": "string"
      }
    },
    "required": [
      "trigger",
      "label"
    ]
  },
  "workflow-block-visuals": {
    "type": "object",
    "properties": {
      "top": {
        "type": "number"
      },
      "left": {
        "type": "number"
      }
    }
  },
  "workflow-blocks": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "definitionId": {
        "type": "string"
      },
      "label": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "configuration": {
        "type": "object",
        "additionalProperties": false
      },
      "visuals": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/WorkflowBlockVisuals"
          }
        ]
      }
    },
    "required": [
      "id",
      "definitionId"
    ]
  },
  "workflow-connections": {
    "type": "object",
    "properties": {
      "sourceBlockId": {
        "type": "string"
      },
      "sourceSocketName": {
        "type": "string"
      },
      "targetBlockId": {
        "type": "string"
      },
      "targetSocketName": {
        "type": "string"
      }
    },
    "required": [
      "sourceBlockId",
      "sourceSocketName",
      "targetBlockId",
      "targetSocketName"
    ]
  },
  "notification-configurations": {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
        "enum": [
          "email"
        ]
      },
      "targets": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "content": {
        "type": "string"
      }
    },
    "required": [
      "type",
      "targets",
      "content"
    ]
  },
  "workflows": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "triggers": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/WorkflowTrigger"
        }
      },
      "blocks": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/WorkflowBlock"
        }
      },
      "connections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/WorkflowConnection"
        }
      },
      "label": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "description": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "name": {
        "type": "string"
      },
      "active": {
        "type": "boolean"
      },
      "activeFrom": {
        "type": "number"
      },
      "activeUntil": {
        "type": "number"
      },
      "systemWorkflow": {
        "type": "boolean"
      },
      "notificationConfig": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/NotificationConfiguration"
        }
      },
      "failedNotificationConfig": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/NotificationConfiguration"
        }
      }
    }
  },
  "workflow-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Workflow"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "workflow-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Workflow"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "functions": {
    "type": "object",
    "properties": {}
  },
  "workflow-executions": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "workflowId": {
        "type": "string"
      },
      "startDate": {
        "type": "number"
      },
      "endDate": {
        "type": "number"
      },
      "status": {
        "type": "string",
        "enum": [
          "started",
          "running",
          "finishedSuccessfully",
          "finishedWithErrors"
        ]
      },
      "data": {
        "type": "string"
      },
      "logs": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "type": "object"
        }
      },
      "initialData": {
        "type": "object",
        "additionalProperties": false
      },
      "hasErrors": {
        "readOnly": true,
        "allOf": [
          {
            "$ref": "#/components/schemas/Function"
          }
        ]
      }
    },
    "required": [
      "workflowId",
      "startDate",
      "status",
      "logs"
    ]
  },
  "workflow-execution-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/WorkflowExecution"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "workflow-execution-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/WorkflowExecution"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "extension-slots": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "name": {
        "type": "string"
      },
      "component": {
        "additionalProperties": false,
        "x-schema-form": {
          "widget": "widget"
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/Widget"
          }
        ]
      },
      "socketConnections": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/SocketConnection"
        }
      }
    },
    "required": [
      "name",
      "component"
    ]
  },
  "extension-slot-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ExtensionSlot"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "extension-slot-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ExtensionSlot"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "contact-custom-field-values": {
    "type": "object",
    "properties": {
      "customFieldId": {
        "type": "string",
        "title": "Benutzerdefiniertes Feld",
        "x-schema-form": {
          "widget": "storeselect",
          "store": "ContactCustomField",
          "property": "title"
        }
      },
      "value": {
        "type": "string",
        "title": "Wert"
      }
    },
    "required": [
      "customFieldId",
      "value"
    ]
  },
  "contact-metadata": {
    "type": "object",
    "properties": {
      "sharepointContactId": {
        "type": "string"
      }
    }
  },
  "contacts": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "type": "string",
        "title": "Titel"
      },
      "salutation": {
        "type": "string",
        "title": "Anrede"
      },
      "firstName": {
        "type": "string",
        "title": "Vorname"
      },
      "lastName": {
        "type": "string",
        "title": "Nachname"
      },
      "birthday": {
        "type": "string",
        "title": "Geburtstag",
        "x-schema-form": {
          "inputWidget": "DateStringInput"
        }
      },
      "address": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "avatarUrl": {
        "type": "string",
        "title": "Profilbild"
      },
      "email": {
        "type": "string",
        "title": "E-Mail"
      },
      "phone": {
        "type": "string",
        "title": "Telefonnummer"
      },
      "mobile": {
        "type": "string",
        "title": "Mobil"
      },
      "company": {
        "type": "string",
        "title": "Firma"
      },
      "companyPosition": {
        "type": "string",
        "title": "Position"
      },
      "companyPhone": {
        "type": "string",
        "title": "Firmentelefon"
      },
      "companyAddress": {
        "additionalProperties": false,
        "title": "Firmenadresse",
        "allOf": [
          {
            "$ref": "#/components/schemas/Address"
          }
        ]
      },
      "customFields": {
        "additionalProperties": false,
        "title": "Benutzerdefinierte Felder",
        "x-schema-form": {
          "widget": "CustomContactFields"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ContactCustomFieldValue"
        }
      },
      "metadata": {
        "additionalProperties": false,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ContactMetadata"
          }
        ]
      },
      "publicAccess": {
        "type": "boolean",
        "title": "Öffentlicher Zugriff"
      }
    }
  },
  "contact-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Contact"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "contact-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Contact"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "contact-custom-fields": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "title": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "type": {
        "type": "string",
        "enum": [
          "string",
          "number",
          "boolean",
          "date",
          "datestring"
        ]
      }
    },
    "required": [
      "title",
      "type"
    ]
  },
  "contact-custom-field-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ContactCustomField"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "contact-custom-field-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ContactCustomField"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "immo-scout24-responses": {
    "type": "object",
    "properties": {}
  },
  "data-template-code-execution-configurations": {
    "type": "object",
    "properties": {
      "dataTemplateCode": {
        "type": "string"
      },
      "applySampledata": {
        "type": "boolean"
      }
    },
    "required": [
      "dataTemplateCode"
    ]
  },
  "create-demo-requests": {
    "type": "object",
    "properties": {
      "demoUserEmail": {
        "type": "string",
        "description": "If provided, then a demo user with that email will be created. Otherwise, you must be logged in to create a demo environment"
      },
      "dataTemplateCodeExecutionConfiguration": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/DataTemplateCodeExecutionConfiguration"
          }
        ]
      }
    },
    "required": [
      "dataTemplateCodeExecutionConfiguration"
    ]
  },
  "create-demo-responses": {
    "type": "object",
    "properties": {
      "redirectUrl": {
        "type": "string"
      },
      "currentUser": {
        "additionalProperties": false,
        "description": "Will be set when the demo request actually created a demo user",
        "allOf": [
          {
            "$ref": "#/components/schemas/CurrentUser"
          }
        ]
      },
      "projectId": {
        "type": "string"
      },
      "companyId": {
        "type": "string"
      },
      "resellerId": {
        "type": "string"
      }
    },
    "required": [
      "redirectUrl",
      "projectId",
      "companyId",
      "resellerId"
    ]
  },
  "tenant-statistics": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "tenantId": {
        "type": "string"
      },
      "date": {
        "type": "number"
      },
      "executionTime": {
        "type": "number"
      },
      "httpMethod": {
        "type": "string"
      },
      "path": {
        "type": "string"
      },
      "queryParams": {
        "type": "string"
      }
    },
    "required": [
      "tenantId",
      "date",
      "executionTime",
      "httpMethod",
      "path",
      "queryParams"
    ]
  },
  "replaced-properties": {
    "type": "object",
    "properties": {
      "propertyName": {
        "type": "string"
      },
      "newValue": {
        "type": "object",
        "additionalProperties": false
      },
      "targetId": {
        "type": "string"
      },
      "targetType": {
        "type": "string"
      }
    },
    "required": [
      "propertyName",
      "newValue",
      "targetId",
      "targetType"
    ]
  },
  "replacements": {
    "type": "object",
    "properties": {
      "lastUpdatedAt": {
        "type": "number",
        "description": "Describes the date of the last update",
        "x-schema-form": {
          "hidden": true
        }
      },
      "_deleted": {
        "type": "boolean",
        "description": "Describes wether the entity was soft deleted or not",
        "x-schema-form": {
          "hidden": true
        }
      },
      "id": {
        "type": "string",
        "maxLength": 100,
        "x-schema-form": {
          "hidden": true
        }
      },
      "createdAt": {
        "type": "number",
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        }
      },
      "context": {
        "readOnly": true,
        "x-schema-form": {
          "hidden": true
        },
        "allOf": [
          {
            "$ref": "#/components/schemas/ModelContextObject"
          }
        ]
      },
      "label": {
        "x-schema-form": {
          "inputWidget": "I18nInput",
          "filterWidget": "I18nFilter"
        },
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/InnerTranslation"
        }
      },
      "start": {
        "type": "number",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "end": {
        "type": "number",
        "x-schema-form": {
          "inputWidget": "DateTimeInput",
          "filterWidget": "DateTimeFilter",
          "viewWidget": "DateTimeView"
        }
      },
      "replacers": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/ReplacedProperty"
        }
      }
    },
    "required": [
      "label",
      "start",
      "end",
      "replacers"
    ]
  },
  "type-permissions": {
    "type": "object",
    "properties": {
      "get": {
        "type": "string"
      },
      "getMany": {
        "type": "string"
      },
      "put": {
        "type": "string"
      },
      "patch": {
        "type": "string"
      },
      "post": {
        "type": "string"
      },
      "del": {
        "type": "string"
      }
    }
  },
  "type-views": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string",
        "title": "Name der Anzeigekomponente",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "values": [
            {
              "label": "Editor",
              "value": "editor"
            },
            {
              "label": "Anzeige",
              "value": "view"
            },
            {
              "label": "Vorschau",
              "value": "preview"
            }
          ]
        }
      },
      "compoundWidgetId": {
        "type": "string",
        "title": "Verlinktes Widget",
        "x-schema-form": {
          "inputWidget": "SelectInput",
          "filterWidget": "SelectFilter",
          "viewWidget": "SelectView",
          "type": "compound-widgets",
          "multiple": false
        }
      }
    },
    "required": [
      "name",
      "compoundWidgetId"
    ]
  },
  "searchable-type-fields": SearchableTypeFieldSchema,
  "types": DataTypeSchema,
  "type-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": DataTypeSchema
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "type-search-result-pages": {
    "type": "object",
    "properties": {
      "hasNext": {
        "type": "boolean"
      },
      "hasPrevious": {
        "type": "boolean"
      },
      "totalElements": {
        "type": "number"
      },
      "totalPages": {
        "type": "number"
      },
      "pageRequest": {
        "$ref": "#/components/schemas/SwaggerPageRequest"
      },
      "elements": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/Type"
        }
      }
    },
    "required": [
      "hasNext",
      "hasPrevious",
      "totalElements",
      "totalPages",
      "pageRequest",
      "elements"
    ]
  },
  "email-deliveries": {
    "type": "object",
    "properties": {
      "subject": {
        "type": "string"
      },
      "content": {
        "type": "string"
      },
      "from": {
        "type": "string"
      },
      "htmlMail": {
        "type": "string"
      },
      "to": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "cc": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "bcc": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "attachments": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "type": "object"
        }
      }
    },
    "required": [
      "subject",
      "content",
      "from",
      "htmlMail",
      "attachments"
    ]
  },
  "create-system-messages": {
    "type": "object",
    "properties": {
      "text": {
        "type": "string"
      },
      "toIds": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "links": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "type": "object"
        }
      }
    },
    "required": [
      "text",
      "toIds"
    ]
  },
  "json-patches": {
    "type": "object",
    "properties": {
      "path": {
        "type": "string"
      },
      "op": {
        "type": "string",
        "enum": [
          "add",
          "remove",
          "replace",
          "move",
          "copy"
        ]
      },
      "value": {
        "type": "object",
        "additionalProperties": false
      },
      "from": {
        "type": "string"
      }
    },
    "required": [
      "path",
      "op"
    ]
  },
  "checkin-visitor-files": {
    "type": "object",
    "properties": {
      "createdAt": {
        "type": "number"
      },
      "fileId": {
        "type": "string"
      },
      "propertyName": {
        "type": "string"
      }
    },
    "required": [
      "createdAt",
      "fileId",
      "propertyName"
    ]
  },
  "checkin-visitor-events": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "createdAt": {
        "type": "number"
      },
      "type": {
        "type": "string"
      },
      "visitorId": {
        "type": "string"
      },
      "appId": {
        "type": "string"
      },
      "formId": {
        "type": "string"
      }
    },
    "required": [
      "type",
      "visitorId"
    ]
  },
  "checkin-visitors": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "fields": {
        "type": "object",
        "additionalProperties": false
      },
      "files": {
        "additionalProperties": false,
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/CheckinVisitorFile"
        }
      },
      "latestEvent": {
        "additionalProperties": false,
        "allOf": [
          {
            "$ref": "#/components/schemas/CheckinVisitorEvent"
          }
        ]
      },
      "deleteOn": {
        "type": "number"
      },
      "deleteOnPreference": {
        "type": "number"
      }
    },
    "required": [
      "fields"
    ]
  },
  "scheduling-rule-v2s": {
    "type": "object",
    "properties": {
      "pattern": {
        "type": "string",
        "enum": [
          "once",
          "always",
          "daily",
          "weekly",
          "monthly",
          "yearly"
        ],
        "description": "Describe if this scheduling rule should be apply for daily, weekly, monthly or yearly"
      },
      "startTime": {
        "type": "number",
        "description": "Sets a time when the event shall start"
      },
      "duration": {
        "type": "number",
        "description": "Sets the duration for the event",
        "default": 3600000
      },
      "each": {
        "type": "number",
        "description": "Set the number on how often the scheduled shall occur again: For every week, enter '1', for every second week enter '2' and so on",
        "default": 1
      },
      "patternIncludes": {
        "description": "If you want to exclude days/weeks/months from your schedule, 1 leading",
        "type": "array",
        "items": {
          "type": "number"
        }
      },
      "scheduleStart": {
        "type": "number",
        "description": "Sets the scheduling start"
      },
      "scheduleEnd": {
        "type": "number",
        "description": "Sets the scheduling end"
      }
    },
    "required": [
      "pattern"
    ]
  },
  [TYPE_MESSAGES]: MessageSchema,
  [TYPE_MESSAGE_THREADS]: MessageThreadSchema,
}