import {
  DataType,
  DataTypePermission,
  DataTypeStorageDefinition,
  DataTypeVariant,
  TypeOwnershipField
} from "../models";
import { InlineTranslation } from "../models/inner-translation";

export interface SearchableFieldDefinition {
  name: string;
  weight: number;
}

export interface SmallstackTypePermissions {
  create?: string;
  read?: string;
  readAll?: string;
  update?: string;
  delete?: string;
}

export function convertDataTypePermissions(permissions: DataTypePermission): SmallstackTypePermissions {
  return {
    create: permissions.post,
    read: permissions.get,
    readAll: permissions.getMany,
    delete: permissions.del,
    update: permissions.put || permissions.patch
  };
}

export enum SmallstackTypeTag {
  /** common types that end users would use */
  COMMON = "common",
  /** administrative types only for admins */
  ADMIN = "admin"
}

/**
 * @deprecated Use `Type` instead
 *
 * A smallstack type represents an entity in the smallstack eco system and offers the (collection-)name,
 * icon and permissions
 */
export interface SmallstackType {
  /** The name of the type, should be all in lowercase and plural, can contain dashes e.g. "data-templates" */
  path: string;

  icon: string;

  permissions?: SmallstackTypePermissions;
  ownerPermissions?: SmallstackTypePermissions;

  /**
   * Used for computing the toString output. Please use `${PROP_NAME}` as property placeholders.
   * Example: `${fistname} ${lastname}`
   */
  toStringRepresentation: string | ((model: any) => string);

  /**
   * Used for computing the bit longer output. Please use `${PROP_NAME}` as property placeholders.
   * Example: `${firstname} ${lastname}`, should include all searchable fields since this will be displayed
   * within search results, and responsible parts will be highlighted
   */
  toLongRepresentation?: string | ((model: any) => string);

  /**
   * Used to search after models of this type, e.g. in autocomplete components
   * - name: e.g. `firstName`
   * - weight: between 0 and 10. Search Results will be ordered accordingly (at one point in the future)
   */
  searchableFields: Array<SearchableFieldDefinition>;

  /**
   * Link to the related model:
   *  - ${id} can be used as placeholder for the id
   *  - ${basePath} will be replaced with the current base path url
   */
  url?: string;

  tags: SmallstackTypeTag[];

  /** leave empty to link to all types, can be overwritten by projects via type configurations */
  linkableTypes?: string[];

  variants?: DataTypeVariant[];

  title?: InlineTranslation;
  titlePlural?: InlineTranslation;
  storage?: DataTypeStorageDefinition;
  ownershipEnabled?: boolean;
  ownershipField?: TypeOwnershipField;
}

export function toTypeDto(type: SmallstackType, isSystemType = true): DataType {
  return {
    title: type.title,
    titlePlural: type.titlePlural,
    path: type.path,
    icon: type.icon,
    permissions: {
      post: type.permissions?.create,
      get: type.permissions?.read,
      getMany: type.permissions?.readAll,
      patch: type.permissions?.update,
      put: type.permissions?.update,
      del: type.permissions?.delete
    },
    representation: type.toStringRepresentation as string,
    longRepresentation: type.toLongRepresentation as string,
    searchableFields: type.searchableFields,
    url: type.url,
    variants: type.variants,
    isSystemType
  };
}
