@for (extension of extensions(); track extension.id) {
  <smallstack-widget-tree
    [widget]="extension.component"
    [widgetRendererOptions]="{ editMode: editMode$ | async }"
    [context]="context()"
    [globals]="globals()"
    [(connections)]="extension.socketConnections"
    (widgetChange)="cmsComponentChange(extension, $event)"
  />
}

@if ((editMode$ | async) === false && (extensions() === undefined || extensions().length === 0) && emptyMessage) {
  <div class="m-2">
    <div class="alert">
      {{ emptyMessage }}
    </div>
  </div>
}
@if ((editMode$ | async) && (deprecated === false || extensions()?.length > 0 || hasChanges())) {
  <div
    class="border-dashed border-2 border-gray-400 text-center m-1 p-2 flex flex-col gap-2 justify-center place-items-center"
  >
    @if (deprecated !== true) {
      <button class="btn btn-primary btn-sm btn-wide p-1" data-test="add-extension-slot" (click)="createNewSlot()">
        Erweiterung hinzufügen
      </button>
    }
    @if (deprecated === true) {
      <div class="deprecated-message">
        Dieser Extension Slot ist veraltet und wird in naher Zukunft gelöscht. Bitte verwenden Sie einen anderen Slot.
      </div>
    }
    <div class="flex flex-row justify-center gap-2">
      <button class="btn btn-sm btn-warning" [loadingFn]="discard()">
        <smallstack-i18n data="@@actions.reset" />
      </button>
      <button class="btn btn-sm btn-primary" [loadingFn]="saveSlot()">
        <smallstack-i18n data="@@actions.save" />
      </button>
    </div>
  </div>
}
