import { TypeSchema } from "../typesystem/type-schema";

export type ForeignKeyChain = [
  {
    type: string;
    foreignPropertyName: string;
  }
];

export interface SearchableTypeField {
  /** the property name to search or, if a custom filter schema is being used, a nice unique name */
  name: string;
  /** the weight of the search, lower means not so important, should be an integer between 0 and 10 */
  weight: number;

  /** if set, then this schema will be used to create filter/search components instead of the model schema + name from above */
  filterSchema?: TypeSchema;
}

export const SearchableTypeFieldSchema: TypeSchema = {
  type: "object",
  properties: {
    name: { type: "string" },
    weight: { type: "number" },
    filterSchema: { type: "object" }
  },
  required: ["name", "weight"]
};
