import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { RootSocketDtoDirectionEnum, SocketConnectionDto, SocketDtoDirectionEnum } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { onlyUnique } from "@smallstack/utils";
import { WidgetTreeService } from "../../services/widget-tree.service";
import { WidgetRegistry } from "../../services/widget.registry";

export interface ConnectionDialogData {
  connections: SocketConnectionDto[];
  widgetTreeService: WidgetTreeService;
}

export interface SocketExplanation {
  widgetId: string;
  widgetName: string;
  socketName: string;
}

@Component({
  selector: "smallstack-connection-dialog",
  templateUrl: "./connection-dialog.component.html",
  styleUrls: ["./connection-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, I18nComponent, MatDialogModule, MatFormFieldModule, MatSelectModule]
})
export class ConnectionDialogComponent implements OnInit {
  public isLoading: boolean = false;
  public availableInSockets: Array<SocketExplanation> = [];
  public availableOutSockets: Array<SocketExplanation> = [];
  public newConnection: SocketConnectionDto = {} as any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConnectionDialogData,
    private matDialogRef: MatDialogRef<ConnectionDialogComponent>,
    private widgetRegistry: WidgetRegistry,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    void this.loadConnections(this.data);
  }

  private async loadConnections(data: ConnectionDialogData) {
    this.isLoading = true;
    this.cdr.markForCheck();
    if (!data.connections || !(data.connections instanceof Array)) data.connections = [];
    for (const widgetId in this.data.widgetTreeService.widgets) {
      const widgetConfiguration = await this.widgetRegistry.getWidgetConfigurationByName(
        this.data.widgetTreeService.widgets[widgetId].name
      );
      if (widgetConfiguration.sockets instanceof Array)
        for (const socket of widgetConfiguration.sockets) {
          if (socket.direction === SocketDtoDirectionEnum.In)
            this.availableInSockets.push({
              widgetId,
              widgetName: this.data.widgetTreeService.widgets[widgetId].name,
              socketName: socket.name
            });
          if (socket.direction === SocketDtoDirectionEnum.Out)
            this.availableOutSockets.push({
              widgetId,
              widgetName: this.data.widgetTreeService.widgets[widgetId].name,
              socketName: socket.name
            });
        }
    }
    if (this.data.widgetTreeService.rootSockets instanceof Array) {
      for (const rootSocket of this.data.widgetTreeService.rootSockets) {
        // take root sockets and reverse direction (so that incoming data via root sockets can be caught as Root.outgoing)
        if (rootSocket.direction === RootSocketDtoDirectionEnum.In)
          this.availableOutSockets.push({
            widgetId: "ROOT",
            widgetName: "ROOT",
            socketName: rootSocket.name
          });
        // if (rootSocket.direction === RootSocketDtoDirectionEnum.Out)
        //   this.availableInSockets.push({
        //     widgetId: "ROOT",
        //     widgetName: "ROOT",
        //     socketName: rootSocket.name
        //   });
      }
    }
    this.availableInSockets = onlyUnique(this.availableInSockets);
    this.availableOutSockets = onlyUnique(this.availableOutSockets);

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  public changeSourceSocket(socket: SocketExplanation): void {
    this.newConnection.sourceComponentId = socket.widgetId;
    this.newConnection.sourceSocketName = socket.socketName;
  }

  public changeTargetSocket(socket: SocketExplanation): void {
    this.newConnection.targetComponentId = socket.widgetId;
    this.newConnection.targetSocketName = socket.socketName;
  }

  public createConnection(): void {
    this.data.connections.push(this.newConnection);
  }

  public removeConnection(index: number): void {
    this.data.connections.splice(index, 1);
  }

  public close(): void {
    this.matDialogRef.close(this.data.connections);
  }
}
