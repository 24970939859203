<div
  class="card card-compact card-bordered shadow-lg bg-white"
  [ngClass]="{ 'overflow-hidden': height !== undefined, 'cursor-pointer': link !== undefined }"
  [ngStyle]="{ height }"
  (click)="followLink()"
>
  <div class="card-body">
    @if (title || icon) {
      <div class="flex flex-row gap-2 items-center mb-2">
        <smallstack-icon [name]="icon" size="28"></smallstack-icon>
        <div class="flex flex-col w-full">
          <div class="flex flex-row justify-between items-center w-full">
            <h6 class="card-title"> <smallstack-i18n [data]="title"></smallstack-i18n> </h6>
            <ng-content select="[card-header-right]"></ng-content>
          </div>
          @if (subTitle) {
            <div class="text-xs"><smallstack-i18n [data]="subTitle"></smallstack-i18n></div>
          }
        </div>
      </div>
    }
    <ng-content></ng-content>
  </div>
</div>
