@if (store()) {
  <smallstack-list-container [store]="store()" [pageSize]="data().pageSize">
    <ng-template #content>
      <smallstack-widget-child
        [data]="data()"
        (dataChange)="dataChange.emit($event)"
        [context]="store().currentPage$ | async"
        property="child"
      ></smallstack-widget-child>
    </ng-template>
    <ng-template #empty>
      <div>Keine Daten gefunden</div>
      <smallstack-widget-child
        [data]="data()"
        (dataChange)="dataChange.emit($event)"
        [context]="store().currentPage$ | async"
        property="child"
      ></smallstack-widget-child>
    </ng-template>
  </smallstack-list-container>
} @else {
  <div class="flex flex-col gap-4">
    <div class="skeleton h-12 w-full"></div>
    <div class="skeleton h-12 w-full"></div>
    <div class="skeleton h-12 w-full"></div>
  </div>
}
