import { LazyElementsModule } from "@angular-extensions/elements";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { WebComponentWidgetComponent } from "./components/widgets/web-component-widget/web-component-widget.component";

const components = [WebComponentWidgetComponent];

@NgModule({
  imports: [CommonModule, LazyElementsModule],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SmallstackCustomElementsSchemaModule {}
