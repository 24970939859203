import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "IconTabs",
  templateName: "Icon Tabs",
  templateDescription: "Zeigt eine Tab Anzeige ohne Text, dafür aber mit Icons",
  icon: "bottom-navigation-toolbar",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      tabs: {
        type: "array",
        items: {
          type: "object",
          properties: {
            icon: {
              type: "string",
              title: "Icon",
              "x-schema-form": { widget: "icon" }
            } as SchemaFormPropertyOptions,
            component: {
              type: "object",
              "x-schema-form": { widget: "widget" }
            } as SchemaFormPropertyOptions
          }
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-icon-tabs-widget",
  templateUrl: "./icon-tabs-widget.component.html",
  styleUrls: ["./icon-tabs-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTabsWidgetComponent extends BaseWidgetComponent {
  protected currentTab = signal(0);
}
