import { BaseEnvironment } from "./base";
import { CloudSharedEnvironment } from "./cloud-shared-environment";

export const CloudSharedProductionEnvironment: CloudSharedEnvironment = {
  ...BaseEnvironment,
  name: "production",
  apiUrl: "https://api.smallstack.com",
  applicationsProxyUrl: "proxy.smallstack.app",
  applicationsProxyIp: "35.198.76.42",
  applicationsPreviewUrlSuffix: "preview.smallstack.app",
  applicationsConnectUrl: "https://connect.smallstack.app",
  checkinUrl: "https://checkin.smallstack.com",
  backofficeUrl: "https://cloud.smallstack.com",
  sentryDns: "https://57efa28c0efe450a90084184ba15eb5b@o255762.ingest.sentry.io/5500103",
  fcmPublicToken: "BMumXBq3E0VhwoUvpT5lTn1Ozjg49SEjrmiggkbamog87L6JUENk4nqg7KFTasd0IL-yGkxWM56GBtnmp1E43co",
  firebaseConfig: {
    apiKey: "AIzaSyAoAXQVcFGvJ3uBP2PXpKnfc79enXFIxqU",
    authDomain: "smallstack-cloud.firebaseapp.com",
    databaseURL: "https://smallstack-cloud.firebaseio.com",
    projectId: "smallstack-cloud",
    storageBucket: "smallstack-cloud.appspot.com",
    messagingSenderId: "314349805194",
    appId: "1:314349805194:web:22befbec83f7303f885773",
    measurementId: "G-4MN4SGZNCZ"
  }
};
