import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs";
import { ThemeDto } from "@smallstack/axios-api-client";
import { MonacoEditorComponent } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { LoaderComponent } from "@smallstack/store-components";
import { ThemeService } from "@smallstack/theme-components";
import { TypeService } from "@smallstack/typesystem-client";
import { cloneObject, filterNullish } from "@smallstack/utils";
import { SmallstackFormCoreModule, TypeEditorComponent } from "@smallstack/widget-core";
import { BehaviorSubject, Subscription, concat } from "rxjs";
import { debounceTime, take, tap } from "rxjs/operators";
import { templates } from "./templates";

@Component({
  selector: "smallstack-theme-editor",
  templateUrl: "./theme-editor.component.html",
  styleUrls: ["./theme-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSelectModule,
    SmallstackFormCoreModule,
    LoaderComponent,
    TypeEditorComponent,
    MonacoEditorComponent
  ]
})
export class ThemeEditorComponent implements OnInit, OnDestroy {
  @Input()
  public theme: ThemeDto;

  @Input()
  public showTemplates: boolean = true;

  @Output()
  public readonly themeChange: EventEmitter<ThemeDto> = new EventEmitter<ThemeDto>();

  public jsonThemeAsString: string;
  public cssThemeAsString: string;

  public editorShown = false;

  protected templates = templates;

  private $debounceEmitter: BehaviorSubject<ThemeDto> = new BehaviorSubject(undefined);
  private subscription: Subscription = new Subscription();

  constructor(
    private themeService: ThemeService,
    private typeService: TypeService,
    cdr: ChangeDetectorRef
  ) {
    this.subscription.add(
      concat(this.$debounceEmitter.pipe(take(1)), this.$debounceEmitter.pipe(debounceTime(50)))
        .pipe(
          filterNullish(),
          tap((theme: ThemeDto) => {
            this.themeChange.emit(theme);
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    if (this.theme === undefined) this.theme = this.themeService.getDefaultTheme();
  }

  public applyTemplate(event: MatSelectChange): void {
    this.theme = templates[event.value];
    this.updateEditorData();
    this.emitChange();
  }

  public tabChange(event: MatTabChangeEvent): void {
    this.editorShown = event.index === 1;
    if (event.index === 0) this.theme = { ...JSON.parse(this.jsonThemeAsString), customStyles: this.cssThemeAsString };
    else this.updateEditorData();
  }

  public emitChange(): void {
    this.$debounceEmitter.next(this.theme);
  }

  public applyEditorTheme(): void {
    try {
      this.theme = { ...JSON.parse(this.jsonThemeAsString), customStyles: this.cssThemeAsString };
      this.emitChange();
    } catch (e) {
      Logger.error("ThemeEditorComponent", e);
    }
  }

  private updateEditorData() {
    const themeWithoutStyle = cloneObject(this.theme);
    delete themeWithoutStyle.customStyles;
    this.jsonThemeAsString = JSON.stringify(themeWithoutStyle, undefined, 2);
    this.cssThemeAsString = this.theme.customStyles;
  }
}
