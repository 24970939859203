Swiper Widget not available right now!
<!-- <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css" />
<swiper
  [slidesPerView]="1"
  [effect]="data?.effect"
  [fadeEffect]="{ crossFade: true }"
  [pagination]="{ clickable: true }"
  [navigation]="data.images.length > 1"
  [autoplay]="{ delay: data?.duration, disableOnInteraction: false }"
  [autoHeight]="data?.autoHeight"
  [loop]="data.images.length > 1"
>
  <ng-template swiperSlide *ngFor="let image of data.images">
    <img
      [src]="image.imageUrl"
      [alt]="image?.alternative"
      [ngStyle]="{ width: data?.width, height: data?.height, cursor: image.imageLink ? 'pointer' : '' }"
      (click)="openLink(image)"
    />
    <br />
    <ng-container *ngIf="license">
      <div *ngIf="licenseUrl; else noUrl">
        <a [href]="licenseUrl" target="_blank" style="cursor: pointer">{{ license }}</a>
      </div>
      <ng-template #noUrl>
        {{ license }}
      </ng-template>
    </ng-container>
  </ng-template>
</swiper> -->
