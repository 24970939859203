@if (store) {
  <smallstack-list-container [store]="store" [showPager]="data().showPager" [pageSize]="data().pageSize">
    <div class="grid" [style.grid-template-columns]="'repeat(auto-fill, minmax(' + data().gridCellWidth + ', 1fr))'">
      @for (element of store.currentPage$ | async; track element) {
        <a
          (click)="getLink(element.id)"
          queryParamsHandling="merge"
          [ngStyle]="{ cursor: data().link ? 'pointer' : 'unset' }"
        >
          <smallstack-widget-child
            [data]="data()"
            (dataChange)="dataChange.emit(data)"
            property="template"
            [context]="{ element: element }"
          ></smallstack-widget-child>
        </a>
      }
    </div>
  </smallstack-list-container>
  @if ((store.currentPage$ | async).length > 0 && data()?.showMoreLink && data()?.link && data()?.label) {
    <ng-container #link>
      <a
        (click)="openMoreLink()"
        queryParamsHandling="merge"
        [ngStyle]="{ cursor: data().link ? 'pointer' : 'unset' }"
        fxFlexAlign="end"
        style="margin-top: 12px"
        class="flex flex-row space-x-1 justify-end items-center"
      >
        <span style="font-size: 14px; font-weight: 600">{{ data()?.label }}</span>
        <smallstack-icon
          [name]="data()?.icon"
          [color]="data()?.iconColor ? data()?.iconColor : '#FFFFFF'"
          [theme]="data()?.iconTheme"
          [size]="data()?.iconSize ? data()?.iconSize : '24'"
        ></smallstack-icon>
      </a>
    </ng-container>
  }
} @else {
  Kein Store geladen
}
