import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { TodoDto } from "@smallstack/axios-api-client";
import { isNonEmptyString } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "TodoBilling",
  templateName: "Aufgaben Abrechnung",
  templateDescription: "Zeigt den Preis für eine Aufgabe oder eine Aufgabenvorlage an",
  dataSchema: {
    type: "object",
    properties: {
      contextVariableName: {
        type: "string",
        title: "Kontext Variable",
        description: "In welcher die Aufgabe steckt",
        "x-schema-form": { widget: "ContextVariable" }
      }
    }
  },
  tags: AllWidgetTags,
  icon: "banknotes"
})
@Component({
  selector: "smallstack-todo-billing-widget",
  templateUrl: "./todo-billing-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoBillingWidgetComponent extends BaseWidgetComponent {
  public todo = computed<TodoDto>(() => {
    const context = this.context();
    const data = this.data();
    if (!context) return undefined;
    if (!data) return undefined;
    if (isNonEmptyString(data.contextVariableName)) return context[data.contextVariableName];
    else return context;
  });
}
