import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef
} from "@angular/core";
import { ObjectStore, PageableCrudStore, PageableStore, Store } from "@smallstack/store";
import { LoaderComponent } from "../loader/loader.component";

/**
 * Displays store data within a template.
 * Usage: https://gitlab.com/smallstack/projects/cloud-backoffice/snippets/1954603
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-store-model",
  templateUrl: "./store-model.component.html",
  styleUrls: ["./store-model.component.scss"],
  standalone: true,
  imports: [CommonModule, LoaderComponent]
})
export class StoreModelComponent implements OnChanges {
  @Input()
  public store: Store | ObjectStore | PageableCrudStore;

  @Input()
  public modelId: string;

  @Input()
  public notFound: string;

  public model: any;
  public notFoundMsg: string;
  @ContentChild(TemplateRef)
  public templateModel: TemplateRef<any>;

  constructor(private cdr: ChangeDetectorRef) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.store) {
      if (changes.modelId) {
        if (this.modelId)
          if (this.store instanceof PageableStore) this.model = await this.store.get(this.modelId);
          else if (this.store instanceof ObjectStore) this.model = await this.store.getById(this.modelId);
        if (!this.model)
          if (this.notFound) this.notFoundMsg = this.notFound;
          else this.notFoundMsg = `Id ${this.modelId} not found/loaded`;
        this.cdr.markForCheck();
      }
    } else {
      throw new Error("No store given for store-model component");
    }
  }
}
