<div class="center-center w-full h-full p-2">
  <div class="flex flex-col gap-2">
    @if (countValue === undefined && !errorMessage) {
      <div>
        <smallstack-loader></smallstack-loader>
      </div>
    }
    @if (countValue() !== undefined) {
      <div class="count-box">
        @if (enableCountUp) {
          <h1 class="count-number" [countUp]="countValue()" [options]="options"> 0 </h1>
        }
        @if (!enableCountUp) {
          <h1 class="count-number">
            {{ countValue() }}
          </h1>
        }
      </div>
    }
    @if (errorMessage) {
      <div class="box-warning">
        <h2>
          {{ errorMessage }}
        </h2>
      </div>
    }
    <p class="text-muted text-center text-pretty">
      {{ data().title }}
    </p>
  </div>
</div>
