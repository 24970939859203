import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class OnlineStatusService {
  #onlineStatus = signal(false);
  public isOnline = this.#onlineStatus.asReadonly();

  constructor(@Inject(PLATFORM_ID) platformId: any) {
    // subscribe to online status of browser
    if (isPlatformBrowser(platformId))
      if ("onLine" in navigator) {
        this.#onlineStatus.set(navigator.onLine);
        window.addEventListener("online", () => {
          this.#onlineStatus.set(true);
        });
        window.addEventListener("offline", () => {
          this.#onlineStatus.set(false);
        });
      } else if (isPlatformServer(platformId)) this.#onlineStatus.set(true);
      else throw new Error("Unsupported platform: " + platformId);
  }
}
