import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { TypeSchema } from "@smallstack/typesystem";
import {
  BooleanEquationOperator,
  calculateProperty,
  getJsonByPath,
  isEmptyObject,
  PropertyRule
} from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { Observable, combineLatest, map } from "rxjs";

@Widget({
  name: "Signal",
  templateName: "Signal Lampe",
  templateDescription: "Zeigt eine Signal Lampe an, basierend auf konfigurierbaren Gegebenheiten",
  tags: AllWidgetTags,
  icon: "light-on--v1",
  dataSchema: {
    type: "object",
    properties: {
      rules: {
        type: "array",
        title: "Regeln",
        description: "Vergeben Sie hier einige Regeln. Die erste Regel, die zutrifft, wird verwendet",
        items: {
          type: "object",
          properties: {
            propertyName: {
              title: "Eigenschaft",
              type: "string"
            },
            operator: {
              title: "Operation",
              type: "string",
              enum: Object.values(BooleanEquationOperator)
            },
            valueType: {
              title: "Wert",
              type: "string",
              "x-schema-form": {
                inputWidget: WIDGET_FORM_INPUT_SELECT,
                values: [
                  {
                    value: "dateNow",
                    label: [{ value: "Aktueller Zeit" }]
                  }
                ]
              }
            } as TypeSchema,
            color: {
              title: "Farbe",
              type: "string",
              "x-schema-form": { widget: "color" }
            } as TypeSchema
          }
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-signal-widget",
  templateUrl: "./signal-widget.component.html",
  styleUrls: ["./signal-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalWidgetComponent extends BaseWidgetComponent {
  protected backgroundColor$: Observable<string> = combineLatest([this.context$, this.data$]).pipe(
    map(([context, data]) => {
      if (!isEmptyObject(context) && !isEmptyObject(data) && data.rules instanceof Array) {
        const propertyRules: PropertyRule[] = data.rules.map((rule: any) => {
          return {
            valueA: getJsonByPath(context, rule.propertyName),
            valueB: rule.valueType === "dateNow" ? Date.now() : undefined,
            operator: rule.operator,
            result: rule.color
          } as PropertyRule;
        });
        return calculateProperty(propertyRules);
      }
    })
  );
}
