import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { DataType, TYPE_TYPES } from "@smallstack/typesystem";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
@Component({
  selector: "smallstack-model-to-string",
  templateUrl: "./model-to-string.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [I18nComponent]
})
export class ModelToStringComponent {
  public typePath = input<string>(undefined);
  public modelId = input<string>(undefined);

  private typeService = inject(TypeService);
  private dataTypeStore = injectStore<DataType>({ typePath: TYPE_TYPES });
  private store = injectStore({ typePath: this.typePath });

  protected text = computed(() => {
    if (this.store.query.status() !== "success") return undefined;
    const model = this.store.getById(this.modelId());
    const type = this.dataTypeStore.getOneByProperty("path", this.typePath());
    if (!type || !model) return undefined;
    return this.typeService.getRepresentation(type, model);
  });
}
