import { Injectable } from "@angular/core";
import { MyPermissionDto } from "@smallstack/axios-api-client";
import { ServicePermissions } from "@smallstack/typesystem";

@Injectable({ providedIn: "root" })
export class PermissionService {
  public hasPermission(permissions: MyPermissionDto[], permission: string, targetId: string): boolean {
    if (!permissions || !(permissions instanceof Array) || permissions.length === 0) return false;
    return (
      permissions.find(
        (perm) =>
          (perm.permissionName === permission || perm.permissionName === ServicePermissions.TENANT_ADMIN) &&
          perm.targetId === targetId
      ) !== undefined
    );
  }
}
