import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { SmallstackTypeTag } from "@smallstack/typesystem";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "links-form-input",
  templateUrl: "./links-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinksFormInputComponent extends SchemaFormBaseWidget {
  protected typeTags = computed<SmallstackTypeTag[]>(() => {
    const schema = this.schema();
    if (schema?.["x-schema-form"]?.typeTags instanceof Array) return schema["x-schema-form"].typeTags;
  });

  protected linkableTypes = computed<string[]>(() => {
    const schema = this.schema();
    if (schema?.["x-schema-form"]?.linkableTypes instanceof Array) return schema["x-schema-form"].linkableTypes;
  });
}
