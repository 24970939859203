import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { I18nComponent } from "@smallstack/i18n-components";
import { normalizeTypePath } from "@smallstack/typesystem";
import { RxEntityStoreService } from "@smallstack/typesystem-client";
import { getJsonByPath, isEmptyObject } from "@smallstack/utils";
import { BaseWidgetComponent, WidgetChildComponent } from "@smallstack/widget-core";

export interface ExpandedLink {
  typeName: string;
  model: any;
}

interface ForeignTypeConfiguration {
  type: string;
  foreignKey: string;
}

@Component({
  selector: "smallstack-links-widget",
  templateUrl: "./links-widget.component.html",
  styleUrls: ["./links-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WidgetChildComponent, I18nComponent]
})
export class LinksWidgetComponent extends BaseWidgetComponent {
  private rxEntityStoreService = inject(RxEntityStoreService);

  protected links = computed(() => {
    const context = this.context();
    const data = this.data();
    if (!isEmptyObject(context) && !isEmptyObject(data)) {
      if (data.linkType === "inwards") return this.calculateInwardLinks(context, data);
      else return this.calculateOutwardLinks(context, data);
    }
    return [];
  });

  private calculateInwardLinks(context: { [key: string]: unknown }, data: any): ExpandedLink[] {
    // cycle through foreign types
    const foreignTypes: ForeignTypeConfiguration[] = data.foreignTypes;
    if (!foreignTypes || !(foreignTypes instanceof Array)) return;
    const allModels: ExpandedLink[] = [];
    for (const foreignType of foreignTypes) {
      const models = this.getModels(
        foreignType.type,
        foreignType.foreignKey,
        getJsonByPath(context, data.inwardsContextProperty)
      ).map((model) => {
        return { model, typeName: foreignType.type } as ExpandedLink;
      });
      allModels.push(...models);
    }
    return allModels;
  }

  private calculateOutwardLinks(context: { [key: string]: unknown }, data: any): ExpandedLink[] {
    const outwardsLinksPropertyValue: LinkedModelDto[] = getJsonByPath(
      context,
      data.outwardsContextProperty
    ) as LinkedModelDto[];
    if (
      !outwardsLinksPropertyValue ||
      !(outwardsLinksPropertyValue instanceof Array) ||
      outwardsLinksPropertyValue.length === 0
    )
      return [];

    // expand
    const allModels: ExpandedLink[] = [];
    for (const outwardsLink of outwardsLinksPropertyValue) {
      const straightenedTypePath = normalizeTypePath(outwardsLink.type);
      if (
        data.outwardsTypeFilter instanceof Array &&
        data.outwardsTypeFilter.length > 0 &&
        !data.outwardsTypeFilter.map((otf: string) => normalizeTypePath(otf)).includes(straightenedTypePath)
      )
        continue;
      const model = this.rxEntityStoreService.forType({ typePath: straightenedTypePath }).getById(outwardsLink.id);
      if (!model) return;
      allModels.push({ model, typeName: outwardsLink.type } as ExpandedLink);
    }
    if (allModels.length === 0) return [];
    return allModels;
  }

  private getModels(typeName: string, key: string, value: string): any[] {
    if (key === undefined) {
      Logger.error("LinksWidgetComponent", "No key given!");
      return [];
    }
    if (value === undefined) {
      Logger.error("LinksWidgetComponent", "No value given!");
      return [];
    }
    return this.rxEntityStoreService.forType({ typePath: typeName }).getMany({ [key]: value });
  }
}
