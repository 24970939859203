/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SchedulingRuleDto
 */
export interface SchedulingRuleDto {
    /**
     * Describe if this scheduling rule should be apply for daily, weekly, monthly or yearly
     * @type {string}
     * @memberof SchedulingRuleDto
     */
    pattern: SchedulingRuleDtoPatternEnum;
    /**
     * Set the number on how often the scheduled shall occur again: For every week, enter \'1\', for every second week enter \'2\' and so on
     * @type {number}
     * @memberof SchedulingRuleDto
     */
    each?: number;
    /**
     * If you want to exclude days/weeks/months from your schedule, 1 leading
     * @type {Array<number>}
     * @memberof SchedulingRuleDto
     */
    patternIncludes?: Array<number>;
    /**
     * Sets a time when the scheduling shall start
     * @type {number}
     * @memberof SchedulingRuleDto
     */
    startTime: number;
    /**
     * Sets a time when the scheduling shall end. If not set, scheduling will be done forever
     * @type {number}
     * @memberof SchedulingRuleDto
     */
    endTime?: number;
    /**
     * Sets the hour of the day
     * @type {number}
     * @memberof SchedulingRuleDto
     */
    hour?: number;
    /**
     * Sets the minute of the hour
     * @type {number}
     * @memberof SchedulingRuleDto
     */
    minute?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SchedulingRuleDtoPatternEnum {
    Once = 'once',
    Always = 'always',
    Daily = 'daily',
    Businessdays = 'businessdays',
    Weekly = 'weekly'
}



