/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SchedulingRuleV2Dto
 */
export interface SchedulingRuleV2Dto {
    /**
     * Describe if this scheduling rule should be apply for daily, weekly, monthly or yearly
     * @type {string}
     * @memberof SchedulingRuleV2Dto
     */
    pattern: SchedulingRuleV2DtoPatternEnum;
    /**
     * Sets a time when the event shall start
     * @type {number}
     * @memberof SchedulingRuleV2Dto
     */
    startTime?: number;
    /**
     * Sets the duration for the event
     * @type {number}
     * @memberof SchedulingRuleV2Dto
     */
    duration?: number;
    /**
     * Set the number on how often the scheduled shall occur again: For every week, enter \'1\', for every second week enter \'2\' and so on
     * @type {number}
     * @memberof SchedulingRuleV2Dto
     */
    each?: number;
    /**
     * If you want to exclude days/weeks/months from your schedule, 1 leading
     * @type {Array<number>}
     * @memberof SchedulingRuleV2Dto
     */
    patternIncludes?: Array<number>;
    /**
     * Sets the scheduling start
     * @type {number}
     * @memberof SchedulingRuleV2Dto
     */
    scheduleStart?: number;
    /**
     * Sets the scheduling end
     * @type {number}
     * @memberof SchedulingRuleV2Dto
     */
    scheduleEnd?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SchedulingRuleV2DtoPatternEnum {
    Once = 'once',
    Always = 'always',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly'
}



