import { Injectable } from "@angular/core";
import { AxiosApiClient, CMSApi, CmsPageDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable()
export class CmsPagesStore extends PageableCrudStore<CmsPageDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 100, sort: "-id" });
  }

  protected async createModel(cmsPage: CmsPageDto): Promise<CmsPageDto> {
    const res = await this.axiosApiClient.get(CMSApi).createCmsPage({ cmsPage });
    return res.data;
  }
  protected async patchModel(id: string, cmsPage: Partial<CmsPageDto>): Promise<CmsPageDto> {
    const res = await this.axiosApiClient.get(CMSApi).patchCmsPage({ cmsPage, id });
    return res.data;
  }
  protected async putModel(cmsPage: CmsPageDto): Promise<CmsPageDto> {
    const res = await this.axiosApiClient.get(CMSApi).putCmsPage({ cmsPage, id: cmsPage.id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(CMSApi).deleteCmsPage({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(CMSApi).deleteManyCmsPages({ ids });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<CmsPageDto>> {
    const res = await this.axiosApiClient.get(CMSApi).getCmsPages(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<CmsPageDto> {
    const res = await this.axiosApiClient.get(CMSApi).getCmsPage({ id });
    return res.data;
  }
}
