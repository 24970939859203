@if (videoUrl) {
  <iframe
    [width]="data().width"
    [height]="data().height"
    [src]="videoUrl"
    title="video player"
    allow="autoplay"
    allowfullscreen
    frameborder="0"
    data-test="video-player-widget"
  ></iframe>
}
