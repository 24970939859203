// TODO: Would be nice to have a way to generate this file

export const FunctionNames = {
  features: {
    messaging: {
      sendMessage: "features-messaging-sendMessage",
      markThreadAsRead: "features-messaging-markThreadAsRead",
      getOrCreate1on1Thread: "features-messaging-getOrCreate1on1Thread",
      getOrCreateSystemThread: "features-messaging-getOrCreateSystemThread",
      sendSystemMessage: "features-messaging-sendSystemMessage"
    }
  }
};
