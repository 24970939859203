<div style="padding: 10px" class="flex flex-col">
  @for (event of events; track event) {
    <div class="calendar-entry-box flex flex-row space-x-1 justify-between">
      <div class="flex flex-row space-x-2 items-start">
        <smallstack-icon [name]="'calendarEntries' | typeIcon | async" size="16"></smallstack-icon>
        <div class="flex flex-col">
          <div class="flex flex-row">
            <smallstack-date [timestamp]="event.start" format="medium"></smallstack-date>
          </div>
          <smallstack-i18n [data]="event.title" class="text-muted"></smallstack-i18n>
          <div class="text-muted" style="white-space: nowrap">
            Dauer: <smallstack-duration [start]="event.start" [end]="event.end"></smallstack-duration>
          </div>
          @if (event.location) {
            <div class="text-muted">
              <smallstack-i18n [data]="event.location"></smallstack-i18n>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
