@if (models(); as models) {
  <div class="form-control">
    <label class="label">
      <span class="label-text">{{ label }}</span>
    </label>
    <select class="select select-bordered" ngModel (ngModelChange)="valueChange.emit($event)">
      <option selected></option>
      @for (model of models; track model) {
        <option [value]="model.value">{{ model.label }}</option>
      }
    </select>
  </div>
}
@if (!models() && !notFound) {
  <span class="component-loading"></span>
}
@if (notFound && !notFoundTemplate && showNotFound) {
  {{ notFoundText }}
}
