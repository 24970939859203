<smallstack-loading-state [state]="store()?.loadingState()">
  <ng-template #loading>
    <div class="flex flex-col gap-4 w-full p-1">
      <div class="skeleton h-2 w-12"></div>
    </div>
  </ng-template>
  <ng-template #error>
    <div class="center-center p-4 text-help">{{ message() }}</div>
  </ng-template>
  <ng-template #loaded>
    <smallstack-i18n data-test="store-property-value" [data]="modelProperty()"></smallstack-i18n>
  </ng-template>
</smallstack-loading-state>
