import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AxiosApiClient, CalendarApi, CalendarEntryDto } from "@smallstack/axios-api-client";
import { SQBuilder, SearchByFieldMatcher } from "@smallstack/typesystem";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "events-widget",
  templateUrl: "./events-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public totalCalendarEntriesCount: number;
  public calendarEntries: CalendarEntryDto[];
  public loadingDone: boolean = false;
  public displayedColumns = ["date", "title"];

  public static getConfiguration(): WidgetConfigurationSchema {
    const choices = ["1", "3", "5", "10", "15", "20", "25"];
    return {
      type: "object",
      properties: {
        calendarIds: {
          type: "array",
          title: "@@dashboard.widgets.calendar.whichcalendar",
          "x-schema-form": {
            widget: "storeselect",
            store: "calendars",
            property: "name",
            multiple: true
          }
        },
        onlyToday: {
          title: "Nur die heutigen Termine",
          description: "Wenn Sie diese Option deaktivieren werden auch die Termine in der Zukunft angezeigt.",
          type: "boolean",
          default: true
        },
        showLocation: {
          title: "Soll der Terminort angezeigt werden?",
          type: "boolean",
          default: false
        },
        showDetails: {
          title: "Sollen die Termindetails angezeigt werden?",
          type: "boolean",
          default: false
        },
        entries: { type: "string", enum: choices, title: "@@dashboard.widgets.event.numberentries", default: "3" }
      }
    };
  }

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public ngOnInit(): void {
    void this.loadEvents();
  }

  public async loadEvents(): Promise<void> {
    const lastMidnight = new Date().setHours(0, 0, 0, 0);

    await this.axiosApiClient
      .get(CalendarApi)
      .getCalendarEntries({
        size: 100,
        search: SQBuilder.asString(
          [
            {
              fieldname: "calendarId",
              value: this.data()?.calendarIds,
              matcher: SearchByFieldMatcher.IN
            },
            {
              fieldname: "end",
              value: lastMidnight,
              matcher: SearchByFieldMatcher.GREATER_THAN_EQUALS
            }
          ],
          "and"
        )
      })
      .then((res) => {
        let elements: CalendarEntryDto[];
        const nextMidnight = new Date().setHours(23, 59, 59, 999);
        if (this.data()?.onlyToday) elements = res.data.elements.filter((value) => value.end <= nextMidnight);
        else elements = res.data.elements;

        elements.sort((a, b) => Number(a.start) - Number(b.start));
        this.calendarEntries = elements.slice(0, this.data()?.entries);
        this.totalCalendarEntriesCount = elements.length - this.data()?.entries;
        this.cdr.markForCheck();
      });
    this.loadingDone = true;
  }
}
