import { Injectable } from "@angular/core";
import { AxiosApiClient, InvoicesApi, InvoiceDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class InvoiceStore extends PageableCrudStore<InvoiceDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super({ loadOnDeletion: true });
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<InvoiceDto>> {
    const res = await this.axiosApiClient.get(InvoicesApi).getInvoices(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<InvoiceDto> {
    const res = await this.axiosApiClient.get(InvoicesApi).getInvoice({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(InvoicesApi).deleteInvoice({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(InvoicesApi).deleteManyInvoices({ ids });
    return res.data;
  }
  protected async createModel(invoice: InvoiceDto): Promise<InvoiceDto> {
    const res = await this.axiosApiClient.get(InvoicesApi).createInvoice({ invoice });
    return res.data;
  }
  protected async patchModel(id: string, invoice: Partial<InvoiceDto>): Promise<InvoiceDto> {
    const res = await this.axiosApiClient.get(InvoicesApi).patchInvoice({ id, invoice });
    return res.data;
  }
  protected async putModel(invoice: InvoiceDto): Promise<InvoiceDto> {
    const res = await this.axiosApiClient.get(InvoicesApi).putInvoice({ id: invoice.id, invoice });
    return res.data;
  }
}
