import { Directive, Host, Input, OnInit, Self } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { ApiQueryRequest } from "@smallstack/api-shared";
import { PageableCrudStore, PageableStore } from "@smallstack/store";

@Directive({
  selector: "[smallstackMatSortStore]",
  standalone: true
})
export class MatSortStoreDirective implements OnInit {
  @Input()
  public smallstackMatSortStore: PageableStore | PageableCrudStore;

  constructor(@Host() @Self() public matSort: MatSort) {}

  public ngOnInit(): void {
    if (this.smallstackMatSortStore) {
      this.matSort.sortChange.subscribe(async (change: any) => {
        switch (change.direction) {
          case "asc":
            this.smallstackMatSortStore.sortBy(change.active);
            break;
          case "desc":
            this.smallstackMatSortStore.sortBy("-" + change.active);
            break;
          default: {
            const query = this.smallstackMatSortStore.query$.value;
            query.sort = undefined;
            this.smallstackMatSortStore.query$.next(query);
          }
        }
        await this.smallstackMatSortStore.load();
      });

      this.evalApiRequest(this.smallstackMatSortStore.query$.value);
    }
  }

  private evalApiRequest(apiQueryRequest: ApiQueryRequest) {
    if (apiQueryRequest?.sort) {
      let sort = apiQueryRequest.sort;
      if (sort) {
        const direction = sort.startsWith("-") ? "desc" : "asc";
        if (sort.startsWith("-")) sort = sort.substring(1);
        if (sort === "_id") sort = "id";
        this.matSort.direction = direction;
        this.matSort.active = sort;
      }
    }
  }
}
