<div
  class="filter-container no-print flex flex-row basis-16 no-animation"
  [ngClass]="{ 'popups-open-to-top': openPopupsToTop }"
>
  @if (showModeSwitcher && !breakpointService.isMobile()) {
    <div class="toggle-select flex flex-col">
      <button
        (click)="changeMode('simple')"
        style="border-top-left-radius: 4px; height: 50%"
        [ngClass]="mode === 'simple' ? 'btn-primary' : 'btn-neutral'"
        data-test="store-search-simple-mode"
        class="btn btn-xs"
      >
        <smallstack-i18n data="@@components.storesearch.mode.simple"> </smallstack-i18n>
      </button>
      <button
        (click)="changeMode('advanced')"
        style="border-bottom-left-radius: 4px; height: 50%"
        [ngClass]="mode === 'advanced' ? 'btn-primary' : 'btn-neutral'"
        data-test="store-search-advanced-mode"
        class="btn btn-xs"
      >
        <smallstack-i18n data="@@components.storesearch.mode.advanced"> </smallstack-i18n>
      </button>
    </div>
  }
  <div
    class="filter-input-bar flex flex-row items-center basis-full"
    [class]="menuMode === true ? 'no-bottom-rounded-corners' : ''"
  >
    @if (mode === "simple") {
      <div style="padding: 10px; width: 100%">
        <input
          [(ngModel)]="fullTextSearch"
          (keydown.enter)="load()"
          placeholder="{{ translationLabelFilterNames }}"
          data-test="store-search-simple-input"
          class="w-full"
        />
      </div>
    }
    @if (mode === "advanced") {
      <div class="flex flex-row flex-wrap w-full items-center">
        @for (filter of searchQuery?.fieldSearches; track filter; let i = $index) {
          <div class="flex flex-row items-center">
            <div class="advanced-filter-entry flex flex-row items-center">
              <!-- PROPERTY -->
              <div
                class="search-filter"
                style="cursor: pointer"
                (click)="currentFilterIndex = i; currentPopup = 'name'"
                data-test="store-search-fieldname"
              >
                <span class="search-filter-label">Feld</span>
                <smallstack-i18n [data]="getFieldName(filter.fieldname)"></smallstack-i18n>
                <div
                  class="search-filter-popup"
                  [ngClass]="{ active: currentFilterIndex === i && currentPopup === 'name' }"
                >
                  @for (option of filterNames; track option) {
                    <div
                      class="entry"
                      (click)="setCurrentFieldname($event, option.value)"
                      attr.data-test="store-search-fieldname-{{ option.label }}"
                    >
                      <smallstack-i18n [data]="option.label"></smallstack-i18n>
                    </div>
                  }
                </div>
              </div>
              <!-- METHOD -->
              @if (filter.fieldname) {
                <div
                  class="search-filter"
                  style="cursor: pointer"
                  (click)="currentFilterIndex = i; currentPopup = 'matcher'"
                  data-test="store-search-matcher"
                >
                  <span class="search-filter-label">Methode</span>
                  @if (filter.matcher) {
                    <smallstack-i18n
                      data="@@components.search.matcher.{{ filter.matcher }}"
                      data-test="store-search-matcher-label"
                      style="white-space: nowrap"
                    >
                    </smallstack-i18n>
                  }
                  <div
                    class="search-filter-popup"
                    [ngClass]="{ active: currentFilterIndex === i && currentPopup === 'matcher' }"
                  >
                    @for (availableMatcher of applicableMatches(filter); track availableMatcher) {
                      <div
                        class="entry"
                        (click)="setCurrentMatcher($event, availableMatcher)"
                        attr.data-test="store-search-matcher-{{ availableMatcher }}"
                      >
                        <smallstack-i18n data="@@components.search.matcher.{{ availableMatcher }}"> </smallstack-i18n>
                      </div>
                    }
                  </div>
                </div>
              }
              <!-- VALUE -->
              @if (filter.matcher) {
                <div class="search-filter" (click)="currentFilterIndex = i; currentPopup = 'value'">
                  <span class="search-filter-label">Wert</span>
                  @if (filterValues && filterValues[filter.fieldname]?.storeValues) {
                    <div
                      style="height: 16px; cursor: pointer"
                      [loadingFn]="storeSelect.openMenuSync()"
                      class="flex flex-row justify-end items-center"
                    >
                      <smallstack-store-select
                        #storeSelect
                        [storeName]="filterValues[filter.fieldname]?.storeValues.storeName"
                        [showData]="true"
                        [showSelectButton]="false"
                        [showCreationButton]="false"
                        [property]="filterValues[filter.fieldname].storeValues.storeDisplayProperty"
                        (selectedIdChange)="filter.value = $any($event); inputChange(); load()"
                      ></smallstack-store-select>
                    </div>
                  } @else {
                    @if (
                      filterValues &&
                      filterValues[filter.fieldname] &&
                      filterValues[filter.fieldname].config?.allowCustomValues === false
                    ) {
                      <span>
                        {{ getFilterValue(filter.fieldname, filter.value) }}
                      </span>
                    } @else {
                      @if (filter.matcher !== SearchByFieldMatcher.IN_RELATIVE_MONTH) {
                        <input
                          smallstack-autofocus
                          [type]="
                            filterValues && filterValues[filter.fieldname]?.config?.dataType === 'number'
                              ? 'number'
                              : 'text'
                          "
                          data-test="store-search-input"
                          [(ngModel)]="filter.value"
                          (keydown.enter)="load()"
                        />
                      } @else {
                        <mat-select [(ngModel)]="filter.value">
                          <mat-option [value]="-6">Vor 6 Monaten</mat-option>
                          <mat-option [value]="-5">Vor 5 Monaten</mat-option>
                          <mat-option [value]="-4">Vor 4 Monaten</mat-option>
                          <mat-option [value]="-3">Vor 3 Monaten</mat-option>
                          <mat-option [value]="-2">Vor 2 Monaten</mat-option>
                          <mat-option [value]="-1">Letzter Monat</mat-option>
                          <mat-option [value]="0">Aktueller Monat</mat-option>
                          <mat-option [value]="1">Nächster Monat</mat-option>
                          <mat-option [value]="2">In 2 Monaten</mat-option>
                          <mat-option [value]="3">In 3 Monaten</mat-option>
                          <mat-option [value]="4">In 4 Monaten</mat-option>
                          <mat-option [value]="5">In 5 Monaten</mat-option>
                          <mat-option [value]="6">In 6 Monaten</mat-option>
                          <mat-option [value]="6">In 6 Monaten</mat-option>
                        </mat-select>
                      }
                    }
                    @if (filterValues && filterValues[filter.fieldname] && filterValues[filter.fieldname].values) {
                      <div
                        class="search-filter-popup"
                        [ngClass]="{ active: currentFilterIndex === i && currentPopup === 'value' }"
                      >
                        @for (filterValue of filterValues[filter.fieldname].values; track filterValue) {
                          <div class="entry" (click)="setFilterValue($event, filterValue.value); load()"
                            >{{ filterValue.label }}
                          </div>
                        }
                      </div>
                    }
                  }
                </div>
              }
              <smallstack-icon
                name="minus"
                size="16"
                style="cursor: pointer"
                (click)="removeFilter(i)"
                theme="fluency-systems-regular"
                themeColor="daisyTheme.error.text"
              ></smallstack-icon>
            </div>
            @if (i !== searchQuery.fieldSearches.length - 1) {
              <div class="logical-operator">{{ searchQuery.logicalOperator === "or" ? "oder" : "und" }} </div>
            }
          </div>
        }
        <button (click)="createNewFilter()" data-test="store-search-add-filter" class="m-1">
          <smallstack-icon
            name="add"
            size="24"
            theme="fluency-systems-regular"
            themeColor="daisyTheme.error.text"
          ></smallstack-icon>
        </button>
      </div>
    }
    @if (mode === "advanced" && showLogicalOperatorSwitcher && fixedAdvancedSearchLogicalOperator === "none") {
      <div class="toggle-select flex flex-col" style="height: calc(100% + 2px)">
        <button
          data-test="store-search-logical-operator-or"
          (click)="searchQuery.logicalOperator = 'or'; load()"
          [ngClass]="searchQuery?.logicalOperator === 'or' ? 'btn-primary' : 'btn-neutral'"
          style="height: 50%"
          class="btn btn-xs"
        >
          <smallstack-i18n data="@@components.storesearch.comperator.or"> </smallstack-i18n>
        </button>
        <button
          data-test="store-search-logical-operator-and"
          (click)="searchQuery.logicalOperator = 'and'; load()"
          [ngClass]="searchQuery?.logicalOperator === 'and' ? 'btn-primary' : 'btn-neutral'"
          style="height: 50%"
          class="btn btn-xs"
        >
          <smallstack-i18n data="@@components.storesearch.comperator.and"> </smallstack-i18n>
        </button>
      </div>
    }
    @if ((fullTextSearch | nonEmptystring) || searchQuery?.fieldSearches?.length !== 0) {
      <button
        class="filter-secondary-button basis-16 btn btn-neutral"
        (click)="reset()"
        data-test="store-search-delete"
      >
        <smallstack-icon alias="delete" size="24" theme="fluency-systems-regular"></smallstack-icon>
      </button>
    }
    <button
      (click)="load()"
      class="filter-button basis-16 btn btn-primary"
      data-test="store-search-execute"
      [class]="menuMode === true ? 'no-bottom-rounded-corners' : ''"
    >
      <smallstack-icon
        alias="search"
        size="24"
        theme="fluency-systems-regular"
        themeColor="daisyTheme.primary.textColor"
      ></smallstack-icon>
    </button>
  </div>
</div>
