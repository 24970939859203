import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BooleanEquationOperator, getUrlParameterByName } from "@smallstack/utils";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  templateUrl: "./video-player-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerWidgetComponent extends BaseWidgetComponent implements OnChanges, OnInit {
  public videoUrl: SafeUrl;

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        source: {
          type: "string",
          title: "Url des Videos"
        },
        width: {
          type: "string",
          title: "Breite des Videos (%, px)"
        },
        height: {
          type: "string",
          title: "Höhe des Videos (%, px)"
        },
        platform: {
          type: "string",
          title: "Plattform",
          default: "YouTube",
          enum: ["YouTube", "Vimeo"]
        },
        autoplay: {
          type: "boolean",
          title: "Autoplay",
          description:
            "Video automatisch abspielen. Dies kann von manchen Browsern oder Anwendungen wie Adblocker blockiert werden."
        },
        loop: {
          type: "boolean",
          title: "Wiedergabe in einer Schleife"
        },
        hiddencontrols: {
          type: "boolean",
          title: "Versteckte Kontrollen",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../platform",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: "YouTube"
                }
              }
            ]
          }
        }
      }
    };
  }

  constructor(private sanitizer: DomSanitizer) {
    super();
  }
  public ngOnInit(): void {
    this.videoUrl = this.getSourceUrl(this.data()?.source);
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.videoUrl = this.getSourceUrl(this.data()?.source);
    this.cdr.markForCheck();
  }

  public getSourceUrl(url: string): SafeUrl {
    if (!url) {
      return undefined;
    }
    let split;
    const parsedUrl = new URL(url);
    if (this.data()?.platform === "YouTube") {
      split = getUrlParameterByName("v", url);
      if (!split) split = parsedUrl.searchParams;
      url = "https://www.youtube.com/embed/" + split;
    }
    if (this.data()?.platform === "Vimeo") {
      url = "https://player.vimeo.com/video" + parsedUrl.pathname;
    }
    if (this.data()?.autoplay) url += "?autoplay=1";
    if (this.data()?.loop)
      this.data()?.autoplay ? (url += "&loop=1&playlist=" + split) : (url += "?loop=1&playlist=" + split);
    if (this.data()?.hiddencontrols)
      this.data()?.loop || this.data()?.autoplay ? (url += "&controls=0") : (url += "?controls=0");
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
