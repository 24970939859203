import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TypeDto } from "@smallstack/axios-api-client";
import { OverlayComponent, RouterUtilService, UrlResolverService } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { PageableStore } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { SmallstackType } from "@smallstack/typesystem";
import { TypeIconPipe, TypePipe } from "@smallstack/typesystem-client";
import { replaceVariables } from "@smallstack/utils";
import { BaseWidgetComponent, TypeDialogService, WidgetTreeComponent } from "@smallstack/widget-core";
import { take } from "rxjs";
import { ModelToStringComponent } from "../../model-to-string/model-to-string.component";

export interface RepresentationLinks extends SmallstackType {
  store: PageableStore;
  id: string;
}

@Component({
  selector: "smallstack-link-view-widget",
  templateUrl: "./link-view-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TypePipe,
    TypeIconPipe,
    IconComponent,
    I18nComponent,
    ModelToStringComponent,
    MatTooltipModule,
    OverlayComponent,
    WidgetTreeComponent
  ]
})
export class LinkViewWidgetComponent extends BaseWidgetComponent {
  constructor(
    private matDialog: MatDialog,
    private routerUtilService: RouterUtilService,
    private urlResolverService: UrlResolverService,
    private typeDialogService: TypeDialogService
  ) {
    super();
  }

  public async navigate($event: Event, type: SmallstackType, openInNewWindow = false): Promise<void> {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.matDialog.closeAll();
    if (type.url) {
      let url = type.url;
      url = replaceVariables(type.url, {
        id: this.context()?.model?.id,
        ...(await this.urlResolverService.getMap())
      });
      if (openInNewWindow) {
        window.open(url, "_blank");
      } else {
        this.matDialog.closeAll();
        void this.matDialog.afterAllClosed.pipe(take(1)).subscribe(async () => {
          await this.routerUtilService.navigateFullUrl(url, false);
        });
      }
    }
  }

  public openDetails(type: TypeDto, model: any): void {
    void this.typeDialogService.openDetail(type.path, { model });
  }

  public getIconSize(): string {
    switch (this.data().width) {
      case "tiny":
        return "16";
      case "list":
      case "small":
        return "32";
      case "medium":
        return "48";
      case "large":
        return "64";
    }
  }
}
