import { ChangeDetectorRef, computed, Directive, effect, inject, input, OnDestroy, signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { TranslationStore } from "@smallstack/i18n-components";
import { TypeSchema } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { cloneObject, convertDotNotationPathToJsonSchemaPath, setJsonProperty } from "@smallstack/utils";
import { computedAsync } from "ngxtension/computed-async";
import { Observable, Subscription } from "rxjs";
import { FormOptions } from "../../interfaces/form-options";
import { FormService } from "../../services/form.service";

/** @deprecated use FormOptions */
export type SchemaFormOptions = FormOptions;

/**
 * This is the base class for all form based widgets (aka input widgets)
 */
@Directive()
export class SchemaFormBase<T = any> implements OnDestroy {
  public formService: FormService = inject(FormService);
  public translationStore = inject(TranslationStore);
  public cdr = inject(ChangeDetectorRef);

  public widgetSpecificOptions = input<FormOptions>({}, { alias: "options" });

  /** @deprecated */
  public widgetSpecificOptions$ = toObservable(this.widgetSpecificOptions);

  public options = computed(() => {
    const globalFormOptions = this.formService.globalFormOptions();
    const specificOptions = this.widgetSpecificOptions();
    return { ...globalFormOptions, ...specificOptions };
  });
  /** @deprecated */
  protected options$ = toObservable(this.options);

  public path = input<string>(undefined);

  protected typeService = inject(TypeService);

  public validationErrors = computed(() => {
    return this.formService.getValidationStatusForPath(this.path());
  });
  public hasValidationErrors = computed(() => this.validationErrors()?.length > 0);
  public validationErrorText = computed(() => {
    const errors = this.validationErrors();
    if (!errors) return undefined;
    return errors
      .map((inputWidgetError) =>
        this.translationStore.translate(inputWidgetError.error.key, {
          replacers: inputWidgetError.error.replacers,
          showMissingKey: true,
          showFallback: true
        })
      )
      .join(", ");
  });

  /**The schema for the current input widget */
  public schema = computedAsync(() => {
    const path = this.path();
    if (!path) return undefined;
    const schema = this.formService.getSchemaForPath(path);
    return this.typeService.evalRefSchema(schema);
  });

  /** @deprecated */
  public schema$: Observable<TypeSchema> = toObservable(this.schema);

  public value = signal<T>(undefined);

  public value$: Observable<T> = toObservable(this.value);

  /** This subscription will be closed automatically onDestroy */
  protected subscription: Subscription = new Subscription();

  constructor() {
    effect(
      () => {
        const path = this.path();
        const value = this.formService.getValueByPath(path);
        const schema = this.formService.getSchemaForPath(path);
        if (!path) this.value.set(undefined);
        else {
          if (value !== undefined) this.value.set(value);
          else if (schema?.default !== undefined) {
            // set the value in model
            this.setValue(schema.default as T);
            this.value.set(schema.default as T);
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // console.log("Destroyed widget for path " + this.path + ", also removing value for this path!");
    // this.formService.setValueByPath(this.path, undefined);
  }

  /** sets the value for the path of the current widget */
  public setValue(val: T): void {
    this.formService.setValueByPath(this.path(), cloneObject(val));
  }

  /**
   * @deprecated use value() instead
   * gets a snapshot of the value for the current widget
   * */
  public getValue(): T {
    return this.value();
  }

  /** Manually triggers the validation for this input widget */
  public async validate(): Promise<void> {
    await this.formService.validateByPath(this.path());
  }

  /** Triggers the validation for this input widget if options.validateOn === "onBlur" */
  public async validateOnBlur(): Promise<void> {
    const options = this.options();
    if (options?.validateOn === "blur") await this.formService.validateByPath(this.path());
  }

  /** Triggers the validation for this input widget if options.validateOn === "onBlur" */
  public async validateOnChange(): Promise<void> {
    const options = this.options();
    if (options?.validateOn === "change" || this.formService.hasBeenValidated(this.path()))
      await this.formService.validateByPath(this.path());
  }

  /** Use with caution! This will change the schema of the current input widget */
  public overrideSchema(newSchema: SchemaFormPropertyOptions): void {
    const currentPath = convertDotNotationPathToJsonSchemaPath(this.path());
    const currentFormSchema = this.formService.schema();
    const changedSchema = setJsonProperty(currentFormSchema, currentPath, newSchema);
    this.formService.setSchema(changedSchema);
  }
}
