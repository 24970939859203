import axios, { AxiosInstance, AxiosInterceptorOptions, AxiosResponse, InternalAxiosRequestConfig } from "axios";

// un-generic copies from axios package
interface AxiosRequestInterceptor {
  onFulfilled?: (
    value: InternalAxiosRequestConfig<any>
  ) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>;
  onRejected?: (error: any) => any;
  options?: AxiosInterceptorOptions;
}
interface AxiosResponsesInterceptor {
  onFulfilled?: (value: AxiosResponse<any, any>) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>;
  onRejected?: (error: any) => any;
  options?: AxiosInterceptorOptions;
}

/**
 * This service helps to manage global axios interceptors as well as abort signals
 */

export class AxiosInterceptorService {
  static #axiosInstances: AxiosInstance[] = [];
  static #requestInterceptors: Array<AxiosRequestInterceptor> = [];
  static #responseInterceptors: Array<AxiosResponsesInterceptor> = [];
  static #abortSignal = new AbortController();

  public static createInstance(): AxiosInstance {
    const axiosInstance = axios.create();
    this.#requestInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected, interceptor.options);
    });
    this.#axiosInstances.push(axiosInstance);
    return axiosInstance;
  }

  public static getAbortSignal(): AbortSignal {
    return this.#abortSignal.signal;
  }

  public static cancelAllRequests(): void {
    this.#abortSignal.abort();
  }

  public static refreshAbortSignal(): void {
    this.#abortSignal = new AbortController();
  }

  public static addInstance(axiosInstance: AxiosInstance): void {
    this.#requestInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected, interceptor.options);
    });
    this.#responseInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected, interceptor.options);
    });
    this.#axiosInstances.push(axiosInstance);
  }

  public static addRequestInterceptor(
    onFulfilled?: (
      value: InternalAxiosRequestConfig<any>
    ) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
    onRejected?: (error: any) => any,
    options?: AxiosInterceptorOptions
  ): void {
    this.#requestInterceptors.push({ onFulfilled, onRejected, options });
    this.#axiosInstances.forEach((axiosInstance) => {
      axiosInstance.interceptors.request.use(onFulfilled, onRejected, options);
    });
  }

  public static addResponseInterceptor(
    onFulfilled?: (value: AxiosResponse<any, any>) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>,
    onRejected?: (error: any) => any,
    options?: AxiosInterceptorOptions
  ): void {
    this.#responseInterceptors.push({ onFulfilled, onRejected, options });
    this.#axiosInstances.forEach((axiosInstance) => {
      axiosInstance.interceptors.response.use(onFulfilled, onRejected, options);
    });
  }
}

AxiosInterceptorService.addInstance(axios);
