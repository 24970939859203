import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "LinkedModels",
  templateName: "Verknüpfungen",
  templateDescription: "Zeigt Verknüpfungen vom aktuellen zu anderen Objekten an",
  tags: AllWidgetTags,
  dataSchema: {
    properties: {
      linksContextName: {
        title: "Links Variable",
        type: "string",
        description:
          "Legen Sie hier fest, welche Eigenschaft des aktuellen Kontexts als Wert für die Links verwendet werden soll"
      },
      width: {
        title: "Breite",
        description: "Breite der angezeigten Verlinkungen",
        type: "string",
        enum: ["list", "small", "medium", "large"],
        default: "medium"
      }
    }
  },
  icon: "external-link-squared",
  deprecated: true,
  deprecatedMessage: [
    { value: "Bitte verwenden Sie das neue Verlinkungswidget, welches beide Richtungen unterstützt!" }
  ]
})
@Component({
  selector: "smallstack-linked-models-widget",
  templateUrl: "./linked-models-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedModelsWidgetComponent extends BaseWidgetComponent {}
