import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoadingElementDirective, UrlConformPipe } from "@smallstack/common-components";
import { ButtonConfiguration, calculateButtonClasses } from "@smallstack/core-common";
import { DialogPopupButtons, I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { filterNullish } from "@smallstack/utils";
import { BehaviorSubject, Observable, map } from "rxjs";

@Component({
  selector: "smallstack-button",
  templateUrl: "./button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, LoadingElementDirective, IconComponent, I18nComponent, MatTooltipModule, UrlConformPipe]
})
export class ButtonComponent {
  protected buttonConfiguration$: BehaviorSubject<ButtonConfiguration> = new BehaviorSubject(undefined);
  protected classes$: Observable<string> = this.buttonConfiguration$.pipe(
    filterNullish(),
    map((buttonConfiguration) => calculateButtonClasses(buttonConfiguration) + " flex flex-row gap-2")
  );

  /** Will be forwarded to the `loadingFn` */
  @Input()
  public clickFn: () => Promise<void>;

  @Input()
  public set buttonConfiguration(buttonConfiguration: ButtonConfiguration) {
    this.buttonConfiguration$.next(buttonConfiguration);
  }

  @HostBinding("style.display")
  public display: string = "contents";

  constructor(private notificationService: NotificationService) {}

  protected buttonClick() {
    return async (): Promise<void> => {
      const btnConfiguration = this.buttonConfiguration$.value;
      if (btnConfiguration?.showBtnConfirmation === true) {
        const answer = await this.notificationService.popup.confirmation(
          btnConfiguration?.btnConfirmationTitle || "Sind Sie sicher?",
          btnConfiguration?.btnConfirmationText || "Sind Sie sich sicher, dass Sie diese Aktion ausführen möchten?",
          DialogPopupButtons.yesNo
        );
        if (answer !== true) return;
      }
      await this.clickFn();
    };
  }
}
