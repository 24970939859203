@if (!storeEntries) {
  <div>
    <smallstack-loader></smallstack-loader>
  </div>
}
@if (storeEntries) {
  <div>
    @if (data()?.template) {
      <div>
        @for (entry of storeEntries; track entry) {
          <smallstack-widget-tree [widget]="data()?.template" [context]="entry"></smallstack-widget-tree>
        }
      </div>
    } @else {
      Bitte definieren Sie eine Eintrag Vorlage.
    }
  </div>
}
