import { Buffer } from "buffer";

export function dataUrltoArrayBuffer(dataUrl: string): ArrayBuffer {
  const arr = dataUrl.split(",");
  const bstr = Buffer.from(arr[1], "base64").toString();
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return u8arr;
}

export function dataUrlToBlob(dataUrl: string): Blob {
  const dataURLPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/;

  // parse the dataURL components as per RFC 2397
  const matches = dataUrl.match(dataURLPattern);
  if (!matches) {
    throw new Error("invalid dataURI");
  }

  // default to text/plain;charset=utf-8
  const mediaType = matches[2] ? matches[1] : "text/plain" + (matches[3] || ";charset=utf-8");

  const isBase64 = !!matches[4];
  const dataString = dataUrl.slice(matches[0].length);
  const byteString = isBase64
    ? // convert base64 to raw binary data held in a string
      atob(dataString)
    : // convert base64/URLEncoded data component to raw binary
      decodeURIComponent(dataString);

  const array = [];
  for (let i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: mediaType });
}

export function blobToDataUrl(blob: Blob, callback: (dataUrl: string) => void): void {
  const a = new FileReader();
  a.onload = (e: any) => {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
}

export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = function (event) {
      resolve(event.target.result as any);
    };
    fileReader.readAsArrayBuffer(blob);
  });
}

export interface MimeBuffer extends Buffer {
  type: string;
  typeFull: string;
  charset: string;
}

/**
 * Returns a `Buffer` instance from the given data URI `uri`.
 *
 * @param {String} uri Data URI to turn into a Buffer instance
 * @returns {Buffer} Buffer instance from Data URI
 * @api public
 */
export function dataUriToBuffer(uri: string): MimeBuffer {
  if (!/^data:/i.test(uri)) {
    throw new TypeError('`uri` does not appear to be a Data URI (must begin with "data:")');
  }

  // strip newlines
  uri = uri.replace(/\r?\n/g, "");

  // split the URI up into the "metadata" and the "data" portions
  const firstComma = uri.indexOf(",");
  if (firstComma === -1 || firstComma <= 4) {
    throw new TypeError("malformed data: URI");
  }

  // remove the "data:" scheme and parse the metadata
  const meta = uri.substring(5, firstComma).split(";");

  let charset = "";
  let base64 = false;
  const type = meta[0] || "text/plain";
  let typeFull = type;
  for (let i = 1; i < meta.length; i++) {
    if (meta[i] === "base64") {
      base64 = true;
    } else {
      typeFull += `;${meta[i]}`;
      if (meta[i].indexOf("charset=") === 0) {
        charset = meta[i].substring(8);
      }
    }
  }
  // defaults to US-ASCII only if type is not provided
  if (!meta[0] && !charset.length) {
    typeFull += ";charset=US-ASCII";
    charset = "US-ASCII";
  }

  // get the encoded data portion and decode URI-encoded chars
  const encoding = base64 ? "base64" : "ascii";
  const data = unescape(uri.substring(firstComma + 1));
  const buffer = Buffer.from(data, encoding) as MimeBuffer;

  // set `.type` and `.typeFull` properties to MIME type
  buffer.type = type;
  buffer.typeFull = typeFull;

  // set the `.charset` property
  buffer.charset = charset;

  return buffer;
}
