import { Inject, Injectable, InjectionToken, inject, isDevMode, signal } from "@angular/core";
import { getValueFromStringProviderSync } from "@smallstack/utils";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Context } from "./context.service";
import { EnvironmentKeys, EnvironmentService } from "./environment.service";

export const PushNotificationConfiguration: InjectionToken<{ context: Context }> = new InjectionToken(
  "PushNotificationConfiguration"
);

@Injectable({ providedIn: "root" })
export class PushNotificationService {
  private environmentService = inject(EnvironmentService);
  private messaging = getMessaging(initializeApp(this.environmentService.get(EnvironmentKeys.FIREBASE_CONFIG)));
  public pushNotificationsQueue = signal<any[]>([]);
  public latestPushNotification = signal<any | undefined>(undefined);
  public pushPermissionBlocked = signal<boolean>(false);

  constructor(@Inject(PushNotificationConfiguration) private pushNotificationConfiguration: { context: Context }) {
    // register service worker
    if ("serviceWorker" in navigator) {
      const serviceWorkerPath = isDevMode()
        ? "/assets/push/firebase-messaging-sw-local.js"
        : "/assets/push/firebase-messaging-sw.js";
      void navigator.serviceWorker
        .register(serviceWorkerPath, {
          scope: "/assets/push/firebase-cloud-messaging-push-scope"
        })
        .then((registration) => {
          void this.registerDevice(registration);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("ServiceWorker registration failed: ", err);
        });
    }

    onMessage(this.messaging, (payload) => {
      this.pushNotificationsQueue.update((queue) => [...queue, payload]);
      this.latestPushNotification.set(payload);
    });

    // TODO: start checking if a new token is needed, if so, register it
  }

  protected async registerDevice(serviceWorkerRegistration: ServiceWorkerRegistration): Promise<void> {
    const vapidKey = this.environmentService.get<string>(EnvironmentKeys.FCM_PUBLIC_TOKEN);
    this.addText("Using vapidKey: " + vapidKey);
    getToken(this.messaging, { vapidKey, serviceWorkerRegistration })
      .then(async (currentToken) => {
        if (currentToken) {
          this.addText("Registration token available: " + currentToken);
          // Send the token to your server and update the UI if necessary
          await axios.post(
            this.environmentService.get<string>(EnvironmentKeys.API_URL) + "/me/fcm/register",
            { fcmToken: currentToken },
            { headers: this.createAxiosHeaders() }
          );
          // ...
        } else {
          // Show permission request UI
          this.addText("No registration token available. Request permission to generate one.");
          await this.requestPushPermission();
        }
      })
      .catch((err) => {
        this.addText("An error occurred while retrieving token: " + err);
        if (err.code === "messaging/permission-blocked") {
          this.pushPermissionBlocked.set(true);
        }
        // ...
      });
  }

  protected async sendTestPush(): Promise<void> {
    await axios.post(this.environmentService.get<string>(EnvironmentKeys.API_URL) + "/me/fcm/test", undefined, {
      headers: this.createAxiosHeaders()
    });
  }

  private addText(text: string) {
    // eslint-disable-next-line no-console
    console.log("PushNotificationService", text);
  }

  public async requestPushPermission(): Promise<void> {
    this.addText("Requesting permission...");
    await Notification.requestPermission().then((permission) => {
      if (permission === "granted") this.addText("Notification permission granted.");
    });
  }

  private createAxiosHeaders(): any {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-tenant-id": getValueFromStringProviderSync(this.pushNotificationConfiguration.context.tenantId),
      "x-reseller-id": getValueFromStringProviderSync(this.pushNotificationConfiguration.context.resellerId),
      "x-auth-tenant-id": getValueFromStringProviderSync(this.pushNotificationConfiguration.context.authTenantId),
      Authorization: "Bearer " + getValueFromStringProviderSync(this.pushNotificationConfiguration.context.token)
    };
  }
}
