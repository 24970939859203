import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
  effect,
  input
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RootSocketDto, WidgetDto } from "@smallstack/axios-api-client";
import { SocketConnection } from "@smallstack/core-common";
import { WidgetRendererOptions, WidgetTreeService } from "../../services/widget-tree.service";
import { ConnectionDialogComponent, ConnectionDialogData } from "../connection-dialog/connection-dialog.component";
import { WidgetEditorRendererComponent } from "../widget-editor-renderer/widget-editor-renderer.component";
import { WidgetRendererComponent } from "../widget-renderer/widget-renderer.component";

/**
 * The `Widget Tree Component` renders the given {@link #widget} and all of its possible children. It sets up a fresh instance of the {@link WidgetTreeService} which provides the given {@link #tags} and {@link #context} to all widgets of the widget tree. If you set the {@link #editMode} to `true`, all widgets in the tree will be rendered by the {@link WidgetEditorRendererComponent}, otherwise by the {@link WidgetRendererComponent}. The {@link WidgetEditorRendererComponent} wraps all components into a frame with delete and edit buttons at the top. No storing is done by default. Instead, {@link widgetChange} is triggered for every change
 *
 * <img src="https://mermaid.ink/img/eyJjb2RlIjoiY2xhc3NEaWFncmFtXG4gICAgQ29tcG9uZW50UmVuZGVyZXIgPHwtLSBXaWRnZXRSZW5kZXJlclxuICAgIENvbXBvbmVudFJlbmRlcmVyIDx8LS0gV2lkZ2V0RWRpdG9yUmVuZGVyZXJcbiAgICBjbGFzcyBDb21wb25lbnRSZW5kZXJlciB7XG4gICAgICAgICtjb21wb25lbnRSZW5kZXJlclNlcnZpY2U6IENvbXBvbmVudFJlbmRlcmVyU2VydmljZTtcbiAgICAgICAgK2VkaXRNb2RlOiBib29sZWFuO1xuICAgICAgICArY21zQ29tcG9uZW50OiBDbXNDb21wb25lbnREdG87XG4gICAgICAgICtjb250ZXh0OiBhbnk7XG4gICAgfVxuICAgICIsIm1lcm1haWQiOnt9LCJ1cGRhdGVFZGl0b3IiOmZhbHNlfQ">
 */
@Component({
  selector: "smallstack-widget-tree",
  templateUrl: "./widget-tree.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [WidgetTreeService],
  styleUrls: ["./widget-tree.component.global.scss"],
  standalone: true,
  imports: [WidgetEditorRendererComponent, WidgetRendererComponent]
})
export class WidgetTreeComponent {
  /**
   * Is being used to create new instance of WidgetTreeService
   */
  public widgetRendererOptions = input<WidgetRendererOptions>();

  /**
   * The component to show or to modify
   */
  @Input()
  public set widget(widget: WidgetDto) {
    this.widgetTreeService.rootWidget.set(widget);
  }

  public context = input<any>();

  @Input()
  public set globals(globals: any) {
    this.widgetTreeService.setGlobals(globals);
  }

  @Input()
  public set connections(connections: SocketConnection[]) {
    this.widgetTreeService.socketConnections = connections;
  }

  @Output()
  public readonly connectionsChange: EventEmitter<SocketConnection[]> = new EventEmitter();

  @Output()
  public readonly widgetChange: EventEmitter<WidgetDto> = new EventEmitter();

  @Input()
  public set rootSockets(sockets: RootSocketDto[]) {
    this.widgetTreeService.rootSockets = sockets;
  }

  @HostBinding("style.display")
  public display: string = "contents";

  constructor(
    public widgetTreeService: WidgetTreeService,
    private matDialog: MatDialog
  ) {
    effect(
      () => {
        const widgetRendererOptions = this.widgetRendererOptions();
        if (widgetRendererOptions) this.widgetTreeService.applyOptions(widgetRendererOptions);
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const changedWidget = this.widgetTreeService.rootWidget();
      this.widgetChange.emit(changedWidget);
    });
  }

  public openConnectionScreen(): void {
    void this.matDialog
      .open(ConnectionDialogComponent, {
        autoFocus: false,
        data: {
          connections: this.widgetTreeService.socketConnections,
          widgetTreeService: this.widgetTreeService
        } as ConnectionDialogData
      })
      .afterClosed()
      .subscribe((connections) => {
        this.widgetTreeService.socketConnections = connections;
        this.connectionsChange.emit(connections);
      });
  }

  /** Fires a socket event on a widget, will be forwarded to all connected sockets */
  public async fireSocketEvent(sourceWidgetId: string, sourceSocketName: string, data: any): Promise<any> {
    return this.widgetTreeService.sendSocketData(sourceWidgetId, sourceSocketName, data);
  }

  /** Sends a socket event directly to one specific widget */
  public async sendToWidget(targetWidgetId: string, targetSocketName: string, data: any): Promise<void> {
    await this.widgetTreeService.sendToWidget(targetWidgetId, targetSocketName, data);
  }
}
