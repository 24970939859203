import { TypeSchema } from "../typesystem/type-schema";

export interface AcceptedOptIn {
  optInId: string;
  acceptedAt: number;
}

export const AcceptedOptInSchema: TypeSchema = {
  type: "object",
  properties: {
    optInId: {
      type: "string"
    },
    acceptedAt: {
      type: "number"
    }
  },
  required: ["optInId", "acceptedAt"]
};
