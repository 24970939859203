import { ChangeDetectionStrategy, Component, ViewChild, inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TodoDto, WorklogDto } from "@smallstack/axios-api-client";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { NotificationService } from "@smallstack/i18n-components";
import { TYPE_TODOS, TYPE_WORKLOGS, TypeSchema, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { RxEntityStoreService } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { BooleanEquationOperator } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, FormComponent, Widget } from "@smallstack/widget-core";
import { ProjectTodoConfigurationService } from "../../../services/project-todo-configuration.service";
import { MyWorklogStore } from "../../../stores/my-worklog.store";

@Widget({
  name: "CreateSimpleTodo",
  templateName: "Vereinfachte Aufgabe erstellen",
  templateDescription: "Zeigt einen Dialog, in welchem eine vereinfachte Aufgabe angelegt werden kann.",
  icon: "task",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      addTags: {
        title: "Tags",
        description: "Diese Tags werden bei der Erstellung automatisch an das Arbeitsprotokoll gehängt.",
        type: "array",
        items: {
          type: "string",
          title: "Tag"
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-create-simple-todo-widget",
  templateUrl: "./create-simple-todo-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSimpleTodoWidgetComponent extends BaseWidgetComponent {
  @ViewChild(FormComponent)
  protected formComponent: FormComponent;

  protected value: {
    title: string;
    description: string;
    startTime?: number;
    endTime?: number;
    facilityId: string;
    useTimer: boolean;
  };
  protected schema: TypeSchema = {
    type: "object",
    properties: {
      facilityId: {
        title: "Gebäude / Anlage",
        type: "string",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          type: "custom/facilities"
        }
      },
      title: {
        title: "Tätigkeit",
        type: "string"
      },
      description: {
        title: "Beschreibung",
        type: "string",
        "x-schema-form": { widget: "textarea" }
      },
      useTimer: {
        title: "Stoppuhr verwenden",
        type: "boolean",
        default: true
      },
      startTime: {
        title: "Startzeit",
        type: "number",
        "x-schema-form": {
          widget: "datetime",
          defaultsToNow: true,
          rules: [
            { action: "hide", if: { dataPath: "useTimer", operator: BooleanEquationOperator.EQUALS, value: true } }
          ]
        }
      } as SchemaFormPropertyOptions,
      endTime: {
        title: "Ende",
        type: "number",
        "x-schema-form": {
          widget: "datetime",
          defaultsToNow: true,
          rules: [
            { action: "hide", if: { dataPath: "useTimer", operator: BooleanEquationOperator.EQUALS, value: true } }
          ]
        }
      } as SchemaFormPropertyOptions
    },
    required: ["facilityId", "title"]
  };

  private rxEntityStoreService = inject(RxEntityStoreService);
  private myWorklogStore = inject(MyWorklogStore);

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private matDialogRef: MatDialogRef<CreateSimpleTodoWidgetComponent>,
    private todoConfigurationService: ProjectTodoConfigurationService
  ) {
    super();
  }

  protected saveEntities() {
    return async (): Promise<void> => {
      // validate
      return this.notificationService.handlePromise(
        this.formComponent.validateFormAndSave(async () => {
          const todoConfiguration = await this.todoConfigurationService.getConfiguration();
          const billingPosition = todoConfiguration.billingDefaultPosition;
          if (!billingPosition || !billingPosition.type || !billingPosition.price || !billingPosition.label)
            throw new Error(
              "Keine Standard Rechnungsposition definiert. Bitte geben Sie dies an einen Projektadministrator weiter."
            );

          // create todo
          billingPosition.label = [{ value: this.value.title }];
          if (!billingPosition.billingParties) billingPosition.billingParties = [];
          billingPosition.billingParties.push({
            factor: 1,
            linkedType: "custom/facilities",
            linkedId: this.value.facilityId
          });

          const todo = await this.rxEntityStoreService.forType<TodoDto>(TYPE_TODOS).createEntity({
            label: this.value.title ? [{ value: this.value.title }] : undefined,
            description: this.value.description ? [{ value: this.value.description }] : undefined,
            ownerIds: [this.userService.currentUserId()],
            billingPositions: [billingPosition],
            startTime: Date.now()
          });

          // create worklog
          const worklog = await this.myWorklogStore.createWorklogFromTodo(todo);
          worklog.tags = this.data().addTags;
          worklog.label = this.value.title ? [{ value: this.value.title }] : worklog.label;

          if (this.value.useTimer === false) {
            worklog.startTime = this.value.startTime;
            worklog.endTime = this.value.endTime;
            await this.rxEntityStoreService.forType<WorklogDto>(TYPE_WORKLOGS).createEntity(worklog);
          } else await this.rxEntityStoreService.forType<WorklogDto>(TYPE_WORKLOGS).createEntity(worklog);
          this.matDialogRef.close();
        })(),
        { rethrowException: false }
      );
    };
  }
}
