/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateFromWorklogsDto } from '../models';
// @ts-ignore
import { InvoiceDto } from '../models';
// @ts-ignore
import { InvoiceExportConfigurationDto } from '../models';
// @ts-ignore
import { InvoicePageDto } from '../models';
// @ts-ignore
import { InvoiceSearchResultPageDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {InvoiceExportConfigurationDto} exportConfiguration 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export: async (exportConfiguration: InvoiceExportConfigurationDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportConfiguration' is not null or undefined
            if (exportConfiguration === null || exportConfiguration === undefined) {
                throw new RequiredError('exportConfiguration','Required parameter exportConfiguration was null or undefined when calling _export.');
            }
            const localVarPath = `/invoices/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportConfiguration !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportConfiguration !== undefined ? exportConfiguration : {})
                : (exportConfiguration || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: async (invoice: InvoiceDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoice' is not null or undefined
            if (invoice === null || invoice === undefined) {
                throw new RequiredError('invoice','Required parameter invoice was null or undefined when calling createInvoice.');
            }
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof invoice !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(invoice !== undefined ? invoice : {})
                : (invoice || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFromWorklogsDto} createFromWorklogs 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromWorklogs: async (createFromWorklogs: CreateFromWorklogsDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFromWorklogs' is not null or undefined
            if (createFromWorklogs === null || createFromWorklogs === undefined) {
                throw new RequiredError('createFromWorklogs','Required parameter createFromWorklogs was null or undefined when calling createInvoiceFromWorklogs.');
            }
            const localVarPath = `/invoices/worklogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createFromWorklogs !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createFromWorklogs !== undefined ? createFromWorklogs : {})
                : (createFromWorklogs || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteInvoice.');
            }
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyInvoices: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyInvoices.');
            }
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInvoice.');
            }
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a Invoice exists.
         * @summary Check if Invoice exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceExists.');
            }
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoice: async (id: string, invoice: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchInvoice.');
            }
            // verify required parameter 'invoice' is not null or undefined
            if (invoice === null || invoice === undefined) {
                throw new RequiredError('invoice','Required parameter invoice was null or undefined when calling patchInvoice.');
            }
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof invoice !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(invoice !== undefined ? invoice : {})
                : (invoice || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performInvoiceSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performInvoiceSearch.');
            }
            const localVarPath = `/invoices/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInvoice: async (id: string, invoice: InvoiceDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putInvoice.');
            }
            // verify required parameter 'invoice' is not null or undefined
            if (invoice === null || invoice === undefined) {
                throw new RequiredError('invoice','Required parameter invoice was null or undefined when calling putInvoice.');
            }
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof invoice !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(invoice !== undefined ? invoice : {})
                : (invoice || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sealInvoice: async (invoiceId: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling sealInvoice.');
            }
            const localVarPath = `/invoices/{invoiceId}/seal`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsealInvoice: async (invoiceId: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling unsealInvoice.');
            }
            const localVarPath = `/invoices/{invoiceId}/unseal`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {InvoiceExportConfigurationDto} exportConfiguration 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _export(exportConfiguration: InvoiceExportConfigurationDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration)._export(exportConfiguration, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoice(invoice: InvoiceDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).createInvoice(invoice, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateFromWorklogsDto} createFromWorklogs 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceFromWorklogs(createFromWorklogs: CreateFromWorklogsDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).createInvoiceFromWorklogs(createFromWorklogs, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoice(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).deleteInvoice(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyInvoices(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).deleteManyInvoices(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoice(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).getInvoice(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePageDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).getInvoices(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).getInvoicesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a Invoice exists.
         * @summary Check if Invoice exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).invoiceExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInvoice(id: string, invoice: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).patchInvoice(id, invoice, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performInvoiceSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSearchResultPageDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).performInvoiceSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInvoice(id: string, invoice: InvoiceDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).putInvoice(id, invoice, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sealInvoice(invoiceId: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).sealInvoice(invoiceId, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsealInvoice(invoiceId: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(axiosClientConfiguration).unsealInvoice(invoiceId, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {InvoiceExportConfigurationDto} exportConfiguration 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export(exportConfiguration: InvoiceExportConfigurationDto, translate?: string, options?: any): AxiosPromise<any> {
            return InvoicesApiFp(axiosClientConfiguration)._export(exportConfiguration, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice(invoice: InvoiceDto, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).createInvoice(invoice, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFromWorklogsDto} createFromWorklogs 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromWorklogs(createFromWorklogs: CreateFromWorklogsDto, translate?: string, options?: any): AxiosPromise<Array<InvoiceDto>> {
            return InvoicesApiFp(axiosClientConfiguration).createInvoiceFromWorklogs(createFromWorklogs, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(axiosClientConfiguration).deleteInvoice(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyInvoices(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(axiosClientConfiguration).deleteManyInvoices(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice(id: string, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).getInvoice(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<InvoicePageDto> {
            return InvoicesApiFp(axiosClientConfiguration).getInvoices(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return InvoicesApiFp(axiosClientConfiguration).getInvoicesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a Invoice exists.
         * @summary Check if Invoice exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceExists(id: string, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(axiosClientConfiguration).invoiceExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoice(id: string, invoice: any, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).patchInvoice(id, invoice, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performInvoiceSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<InvoiceSearchResultPageDto> {
            return InvoicesApiFp(axiosClientConfiguration).performInvoiceSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceDto} invoice 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInvoice(id: string, invoice: InvoiceDto, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).putInvoice(id, invoice, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sealInvoice(invoiceId: string, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).sealInvoice(invoiceId, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsealInvoice(invoiceId: string, translate?: string, options?: any): AxiosPromise<InvoiceDto> {
            return InvoicesApiFp(axiosClientConfiguration).unsealInvoice(invoiceId, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _export operation in InvoicesApi.
 * @export
 * @interface InvoicesApiExportRequest
 */
export interface InvoicesApiExportRequest {
    /**
     * 
     * @type {InvoiceExportConfigurationDto}
     * @memberof InvoicesApiExport
     */
    readonly exportConfiguration: InvoiceExportConfigurationDto

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiExport
     */
    readonly translate?: string
}

/**
 * Request parameters for createInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiCreateInvoiceRequest
 */
export interface InvoicesApiCreateInvoiceRequest {
    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoicesApiCreateInvoice
     */
    readonly invoice: InvoiceDto

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiCreateInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for createInvoiceFromWorklogs operation in InvoicesApi.
 * @export
 * @interface InvoicesApiCreateInvoiceFromWorklogsRequest
 */
export interface InvoicesApiCreateInvoiceFromWorklogsRequest {
    /**
     * 
     * @type {CreateFromWorklogsDto}
     * @memberof InvoicesApiCreateInvoiceFromWorklogs
     */
    readonly createFromWorklogs: CreateFromWorklogsDto

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiCreateInvoiceFromWorklogs
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiDeleteInvoiceRequest
 */
export interface InvoicesApiDeleteInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiDeleteInvoice
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiDeleteInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyInvoices operation in InvoicesApi.
 * @export
 * @interface InvoicesApiDeleteManyInvoicesRequest
 */
export interface InvoicesApiDeleteManyInvoicesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoicesApiDeleteManyInvoices
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiDeleteManyInvoices
     */
    readonly translate?: string
}

/**
 * Request parameters for getInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiGetInvoiceRequest
 */
export interface InvoicesApiGetInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoice
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for getInvoices operation in InvoicesApi.
 * @export
 * @interface InvoicesApiGetInvoicesRequest
 */
export interface InvoicesApiGetInvoicesRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoicesApiGetInvoices
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InvoicesApiGetInvoices
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoices
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof InvoicesApiGetInvoices
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoices
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoices
     */
    readonly search?: string
}

/**
 * Request parameters for getInvoicesCount operation in InvoicesApi.
 * @export
 * @interface InvoicesApiGetInvoicesCountRequest
 */
export interface InvoicesApiGetInvoicesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiGetInvoicesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for invoiceExists operation in InvoicesApi.
 * @export
 * @interface InvoicesApiInvoiceExistsRequest
 */
export interface InvoicesApiInvoiceExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiInvoiceExists
     */
    readonly id: string
}

/**
 * Request parameters for patchInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiPatchInvoiceRequest
 */
export interface InvoicesApiPatchInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiPatchInvoice
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof InvoicesApiPatchInvoice
     */
    readonly invoice: any

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiPatchInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for performInvoiceSearch operation in InvoicesApi.
 * @export
 * @interface InvoicesApiPerformInvoiceSearchRequest
 */
export interface InvoicesApiPerformInvoiceSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof InvoicesApiPerformInvoiceSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiPerformInvoiceSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiPutInvoiceRequest
 */
export interface InvoicesApiPutInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiPutInvoice
     */
    readonly id: string

    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoicesApiPutInvoice
     */
    readonly invoice: InvoiceDto

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiPutInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for sealInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiSealInvoiceRequest
 */
export interface InvoicesApiSealInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiSealInvoice
     */
    readonly invoiceId: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiSealInvoice
     */
    readonly translate?: string
}

/**
 * Request parameters for unsealInvoice operation in InvoicesApi.
 * @export
 * @interface InvoicesApiUnsealInvoiceRequest
 */
export interface InvoicesApiUnsealInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoicesApiUnsealInvoice
     */
    readonly invoiceId: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiUnsealInvoice
     */
    readonly translate?: string
}

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @param {InvoicesApiExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public _export(requestParameters: InvoicesApiExportRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration)._export(requestParameters.exportConfiguration, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiCreateInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public createInvoice(requestParameters: InvoicesApiCreateInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).createInvoice(requestParameters.invoice, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiCreateInvoiceFromWorklogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public createInvoiceFromWorklogs(requestParameters: InvoicesApiCreateInvoiceFromWorklogsRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).createInvoiceFromWorklogs(requestParameters.createFromWorklogs, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiDeleteInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public deleteInvoice(requestParameters: InvoicesApiDeleteInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).deleteInvoice(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiDeleteManyInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public deleteManyInvoices(requestParameters: InvoicesApiDeleteManyInvoicesRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).deleteManyInvoices(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiGetInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoice(requestParameters: InvoicesApiGetInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).getInvoice(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiGetInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoices(requestParameters: InvoicesApiGetInvoicesRequest = {}, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).getInvoices(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiGetInvoicesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoicesCount(requestParameters: InvoicesApiGetInvoicesCountRequest = {}, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).getInvoicesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a Invoice exists.
     * @summary Check if Invoice exists
     * @param {InvoicesApiInvoiceExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoiceExists(requestParameters: InvoicesApiInvoiceExistsRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).invoiceExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiPatchInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public patchInvoice(requestParameters: InvoicesApiPatchInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).patchInvoice(requestParameters.id, requestParameters.invoice, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiPerformInvoiceSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public performInvoiceSearch(requestParameters: InvoicesApiPerformInvoiceSearchRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).performInvoiceSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiPutInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public putInvoice(requestParameters: InvoicesApiPutInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).putInvoice(requestParameters.id, requestParameters.invoice, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiSealInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public sealInvoice(requestParameters: InvoicesApiSealInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).sealInvoice(requestParameters.invoiceId, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiUnsealInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public unsealInvoice(requestParameters: InvoicesApiUnsealInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.axiosClientConfiguration).unsealInvoice(requestParameters.invoiceId, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
