import {
  Directive,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  input,
  model,
  Renderer2,
  Signal
} from "@angular/core";
import { BreakpointService, calculateResponsiveValue, ResponsiveValue } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { ViewWidgetConfiguration, WidgetEventSubscription } from "@smallstack/typesystem";
import { getJsonByPath, safeStringify, setJsonProperty } from "@smallstack/utils";
import { WidgetTreeService } from "../services/widget-tree.service";

@Directive()
export abstract class SignalBaseWidgetComponent<DATA_TYPE = any> {
  protected breakpointService = inject(BreakpointService);

  @Input()
  public set styles(styles: string) {
    this.applyStylesString(styles);
  }

  @HostBinding("class")
  public cssClass: string;

  @HostBinding("style.display")
  public display: string = "block";

  /** The ID of the widget inside a WidgetTree  */
  public id = input<string>(undefined);

  /** The name of the widget, e.g. "Text" or "Markdown" */
  public name = input<string>(undefined);
  /** Events the widget will subscribe to */
  public eventSubscriptions = input<WidgetEventSubscription[]>([]);

  public readonly data = model<DATA_TYPE>({} as DATA_TYPE);

  protected getData<T>(key: string, defaultValue?: T): T {
    return getJsonByPath(this.data(), key) || defaultValue;
  }

  protected getResponsiveData<T>(key: string, defaultValue?: T): T {
    const data: ResponsiveValue<T> = this.getData(key);
    if (data) return calculateResponsiveValue(this.breakpointService, data);
    return defaultValue;
  }

  // print details about the widget when clicked with shift + click and dev mode is enabled
  @HostListener("click", ["$event"])
  protected showDebug(event: MouseEvent): void {
    if (event.shiftKey && event.altKey && event.ctrlKey) {
      Logger.info(
        "Widget Debug",
        safeStringify({
          id: this.id(),
          name: this.name(),
          data: this.data()
        })
      );
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  constructor() {
    effect(
      () => {
        const eventSubscriptions = this.eventSubscriptions();
        if (this.widgetTreeService && Array.isArray(eventSubscriptions) && eventSubscriptions.length > 0) {
          eventSubscriptions.forEach((subscription) => {
            const eventData = this.widgetTreeService.subscribeToWidgetEvent(
              subscription.widgetId,
              subscription.eventName
            );
            if (eventData)
              this.data.update((data) => {
                if (!data) data = {} as DATA_TYPE;
                return setJsonProperty(data, subscription.targetProperty, eventData);
              });
          });
        }
      },
      { allowSignalWrites: true }
    );

    const viewConfigurationEffectRef = effect(() => {
      const data: any = this.data();
      const viewConfiguration: ViewWidgetConfiguration = data.viewConfiguration;
      if (viewConfiguration) {
        // this.cssClass = viewConfiguration.customClass;
        // this.applyStylesString(viewConfiguration.customStyles);

        if (viewConfiguration.backgroundColor)
          this.renderer.setStyle(this.element.nativeElement, "background-color", viewConfiguration.backgroundColor);
        if (viewConfiguration.color)
          this.renderer.setStyle(this.element.nativeElement, "color", viewConfiguration.color);
        if (viewConfiguration.fontSize)
          this.renderer.setStyle(this.element.nativeElement, "font-size", viewConfiguration.fontSize);
        if (viewConfiguration.height)
          this.renderer.setStyle(this.element.nativeElement, "height", viewConfiguration.height);
        if (viewConfiguration.width)
          this.renderer.setStyle(this.element.nativeElement, "width", viewConfiguration.width);
        if (viewConfiguration.margin)
          this.renderer.setStyle(this.element.nativeElement, "margin", viewConfiguration.margin);
        if (viewConfiguration.padding)
          this.renderer.setStyle(this.element.nativeElement, "padding", viewConfiguration.padding);

        // we only apply the view configuration once
        viewConfigurationEffectRef.destroy();
      }
    });
  }

  protected widgetTreeService: WidgetTreeService = inject(WidgetTreeService, { optional: true });

  protected addWidgetEventProducer(eventName: string, signal: Signal<any>): void {
    if (!this.widgetTreeService) throw new Error("WidgetTreeService is not available!");
    this.widgetTreeService.publishWidgetEvent(this.id(), eventName, signal);
  }

  private element: ElementRef = inject(ElementRef);
  private renderer: Renderer2 = inject(Renderer2);

  private applyStylesString(styles: string) {
    if (styles) {
      const styleClassElement: HTMLStyleElement = this.renderer.createElement("style");
      styleClassElement.textContent = styles.replace(/\.component/g, ".component-" + this.id());
      this.renderer.appendChild(this.element.nativeElement, styleClassElement);
    }
  }
}
