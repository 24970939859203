import { FileDto } from "@smallstack/axios-api-client";

export function getType(f: FileDto): string {
  if (isPicture(f)) return "picture";
  if (f.isDirectory) return "directory";
  switch (f.type) {
    case "application/pdf":
      return "pdf";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingm":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.oasis.opendocument.text":
      return "doc";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xls";
    default:
      return "misc";
  }
}

export function isPicture({ type }: FileDto): boolean {
  return type?.startsWith("image/");
}
