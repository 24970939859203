<div class="w-full flex justify-center">
  <div class="stats shadow" [ngClass]="{ 'stats-vertical w-full': data().showAsVertical }">
    @for (statistic of statistics$ | async; track statistic) {
      <div class="stat place-items-center">
        <div class="stat-title">{{ statistic.title }}</div>
        <div class="stat-value">{{ statistic.value }}</div>
        <div class="stat-desc">{{ statistic.description }}</div>
      </div>
    }
  </div>
</div>
