import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { WidgetDto } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { cloneObject } from "@smallstack/utils";
import { BaseWidgetComponent } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-flexbox-container-widget",
  templateUrl: "./flexbox-container-widget.component.html",
  styleUrls: ["./flexbox-container-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexboxContainerWidgetComponent extends BaseWidgetComponent {
  @HostBinding("style.display")
  public containerDisplay: string = "flex";

  // eslint-disable-next-line max-lines-per-function
  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        children: {
          title: "Elemente",
          type: "array",
          description: "Alle Elemente, die in diesem Container angezeigt werden",
          items: {
            type: "object",
            "x-schema-form": {
              widget: "cms"
            }
          } as SchemaFormSchema
        },
        direction: {
          title: "Richtung",
          type: "string",
          default: "vertical",
          enum: ["row", "row-reverse", "column", "column-reverse"],
          description: "Bestimmt, ob Elemente innerhalb des Containers horizontal oder vertikal angereiht werden"
        },
        wrap: {
          title: "Umbruch",
          type: "string",
          enum: ["nowrap", "wrap", "wrap-reverse"],
          default: "wrap",
          description:
            "Sollen Elemente z.B. horizontal in die nächste Zeile umbrechen, falls zu wenig Platz vorhanden ist?"
        },
        padding: {
          type: "string",
          title: "Außenabstand",
          default: "10px",
          description: "Abstand des Containers zu anderen Elementen auf Ihrer Seite",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        height: {
          type: "string",
          title: "Höhe",
          default: "100%",
          description: "Die Höhe des Containers",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        width: {
          type: "string",
          title: "Breite",
          default: "100%",
          description: "Die Breite des Containers",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        rowGap: {
          type: "string",
          title: "Row Gap",
          default: "10px",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        columnGap: {
          type: "string",
          title: "Column Gap",
          default: "10px",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        childrenFlex: {
          title: "Child Flex",
          type: "string",
          description:
            "Geben Sie hier an, wie sich die Größe eines Elementes basierend auf dem Inhalt anpassen kann (child flex property)"
        },
        justifyContent: {
          title: "justify-content",
          type: "string",
          default: "flex-start",
          enum: [
            "flex-start",
            "center",
            "flex-end",
            "space-between",
            "space-around",
            "space-evenly",
            "start",
            "end",
            "normal",
            "stretch",
            "safe center",
            "unsafe center",
            "inherit",
            "initial",
            "revert",
            "unset"
          ],
          description:
            "The CSS justify-content property defines how the browser distributes space between and around content items along the main-axis of a flex container."
        },
        alignItems: {
          title: "align-items",
          type: "string",
          default: "flex-start",
          enum: [
            "flex-start",
            "center",
            "flex-end",
            "stretch",
            "baseline",
            "start",
            "end",
            "normal",
            "first baseline",
            "last baseline",
            "space-between",
            "space-around",
            "space-evenly",
            "safe center",
            "unsafe center",
            "inherit",
            "initial",
            "revert",
            "unset"
          ],
          description:
            "The CSS align-items property sets the align-self value on all direct children as a group. It controls the alignment of items on the Cross Axis."
        },
        alignContent: {
          title: "align-content",
          type: "string",
          default: "flex-start",
          enum: [
            "center",
            "start",
            "end",
            "flex-start",
            "flex-end",
            "normal",
            "baseline",
            "first baseline",
            "last baseline",
            "space-between",
            "space-around",
            "space-evenly",
            "stretch",
            "safe center",
            "unsafe center",
            "inherit",
            "initial",
            "revert",
            "unset"
          ],
          description:
            "The CSS align-content property sets the distribution of space between and around content items along a flexbox's cross-axis"
        },
        justifyItems: {
          title: "justify-items",
          type: "string",
          default: "flex-start",
          enum: [
            "center",
            "start",
            "end",
            "flex-start",
            "flex-end",
            "normal",
            "baseline",
            "first baseline",
            "last baseline",
            "space-between",
            "space-around",
            "space-evenly",
            "stretch",
            "safe center",
            "unsafe center",
            "inherit",
            "initial",
            "revert",
            "unset"
          ],
          description:
            "The CSS justify-items property defines the default justify-self for all items of the box, giving them all a default way of justifying each box along the appropriate axis."
        },
        classes: {
          type: "string",
          title: "CSS Klasse"
        }
      }
    };
  }

  @HostBinding("class")
  public get classes(): string {
    const classes: string[] = ["widget-container"];
    if (this.data()?.classes)
      classes.push(
        ...this.data()
          .classes.split(" ")
          .map((c: string) => c.trim())
      );
    return classes.join(" ");
  }

  @HostBinding("style")
  public get style(): CSSStyleDeclaration {
    const obj: CSSStyleDeclaration = {} as any;
    const wrapAddition = this.data()?.wrap === true ? " wrap" : "";
    if (this.data()?.padding) obj.padding = this.data().padding;
    if (this.data()?.direction)
      obj.flexDirection = this.data().direction === "vertical" ? "column" + wrapAddition : "row" + wrapAddition;
    else obj.flexDirection = "column";
    if (this.data()?.placeContent) obj.placeContent = this.data().placeContent;
    else obj.placeContent = "flex-start";
    if (this.data()?.placeItems) obj.placeItems = this.data().placeItems;
    else obj.placeItems = "stretch";
    if (this.data()?.gap) obj.gap = this.data().gap;
    else obj.gap = "10px";
    if (this.data()?.height) obj.height = this.data().height;
    if (this.data()?.width) obj.width = this.data().width;
    return obj;
  }

  public addWidget(widget: WidgetDto): void {
    const data = this.data() || {};
    if (!(data.children instanceof Array)) data.children = [];
    this.data().children.push(cloneObject(widget));
    this.dataChange.emit(data);
  }

  public childDataChanged(index: number, cmsComponent: WidgetDto): void {
    if (!cmsComponent) this.data().children.splice(index, 1);
    else this.data().children[index].data = cmsComponent.data;
    this.dataChange.emit(this.data);
  }
}
