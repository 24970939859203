@if (data().header) {
  <h1>
    <smallstack-i18n [data]="data().header"></smallstack-i18n>
  </h1>
}
@if (data()?.group) {
  @if (!errorMessage) {
    @if (listings !== undefined) {
      @if (listings?.length) {
        <div class="listing-collection flex flex-row flex-wrap space-x-5">
          @for (listing of listings; track listing) {
            <mat-card appearance="outlined" class="listing-card">
              <mat-card-header>
                <mat-card-title style="margin-left: -16px">{{ listing.title || "#" + listing["@id"] }}</mat-card-title>
                @if (listing.address) {
                  <mat-card-subtitle style="margin-left: -16px"
                    >{{ listing.address.street || "" }}
                    {{
                      listing.address.street && listing.address.houseNumber ? listing.address.houseNumber + ", " : ""
                    }}
                    {{ listing.address.postcode || "" }} {{ listing.address.city || "" }}
                  </mat-card-subtitle>
                }
              </mat-card-header>
              <mat-card-content>
                <div class="flex flex-row space-x-5 items-start">
                  @if (
                    listing.titlePicture?.urls?.length &&
                    listing.titlePicture.urls[0].url?.length > 1 &&
                    listing.titlePicture.urls[0].url[1]["@href"]
                  ) {
                    <div>
                      <img
                        class="title-img"
                        [src]="listing.titlePicture.urls[0].url[1]['@href']"
                        [alt]="'Titelbild von Angebot ' + listing['@id']"
                      />
                    </div>
                  }
                  <div>
                    @if (listing.price?.value) {
                      <p>
                        <smallstack-i18n data="@@backoffice.widgets.immoscout24.price"></smallstack-i18n>:
                        {{ listing.price.value }} {{ listing.price.currency || "" }}
                      </p>
                    }
                    @if (listing.livingSpace) {
                      <p
                        ><smallstack-i18n data="@@backoffice.widgets.immoscout24.livingspace"></smallstack-i18n>:
                        {{ listing.livingSpace }} m<sup>2</sup></p
                      >
                    }
                    @if (listing.numberOfRooms) {
                      <p
                        ><smallstack-i18n data="@@backoffice.widgets.immoscout24.rooms"></smallstack-i18n>:
                        {{ listing.numberOfRooms }}</p
                      >
                    }
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions align="end">
                <button (click)="onOpenListing(listing['@id'])" class="btn">
                  <smallstack-i18n data="@@backoffice.widgets.immoscout24.hearmore"></smallstack-i18n>
                </button>
              </mat-card-actions>
            </mat-card>
          }
        </div>
      } @else {
        <div class="flex justify-around items-center h-full w-full min-h-full min-w-full">
          @if (data()?.noListings) {
            <p>
              <smallstack-i18n [data]="data().noListings"></smallstack-i18n>
            </p>
          } @else {
            <p>
              <smallstack-i18n data="@@backoffice.widgets.immoscout24.nolistings"></smallstack-i18n>
            </p>
          }
        </div>
      }
    } @else {
      <smallstack-loader></smallstack-loader>
    }
  } @else {
    <div class="flex flex-col justify-around items-center">
      <p>
        <smallstack-i18n data="@@backoffice.widgets.immoscout24.error"></smallstack-i18n>
      </p>
      <p>{{ errorMessage }}</p>
    </div>
  }
} @else {
  <smallstack-i18n data="@@backoffice.widgets.immoscout24.nogroup"></smallstack-i18n>
}
<ng-template #error>
  <div class="flex flex-col justify-around items-center">
    <p>
      <smallstack-i18n data="@@backoffice.widgets.immoscout24.error"></smallstack-i18n>
    </p>
    <p>{{ errorMessage }}</p>
  </div>
</ng-template>
<ng-template #noListings>
  <div class="flex justify-around items-center h-full w-full min-h-full min-w-full">
    @if (data()?.noListings) {
      <p>
        <smallstack-i18n [data]="data().noListings"></smallstack-i18n>
      </p>
    } @else {
      <p>
        <smallstack-i18n data="@@backoffice.widgets.immoscout24.nolistings"></smallstack-i18n>
      </p>
    }
  </div>
</ng-template>
