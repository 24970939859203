import { Injectable } from "@angular/core";
import { Logger } from "@smallstack/core-common";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import {
  TypeSchema,
  WIDGET_FORM_INPUT_FILE_URL,
  WIDGET_FORM_INPUT_GOOGLE_MAPS,
  WIDGET_FORM_INPUT_HTML_SIZING,
  WIDGET_FORM_INPUT_SELECT
} from "@smallstack/typesystem";
import { getMetadata, getPromiseProviderValue, upperCaseFirst } from "@smallstack/utils";
import { synchronized } from "synchronized-ts";
import { Memoize } from "typescript-memoize";
import { WidgetConfiguration } from "../widgets/widget-configuration";
import { AllWidgetTags } from "../widgets/widget.tags";

export type LazyLoadingWidgetConfiguration = Omit<WidgetConfiguration, "name">;

export interface DeprecatedWidgetEntry {
  name: string;
  component: any;
  templateName?: string;
  templateDescription?: string;
  icon?: string;
  tags?: string[];
  configuration?: TypeSchema | (() => Promise<TypeSchema>);
  data?: any;
  sockets?: any;
}
export interface WidgetRegistryEntry {
  name: string;
  configuration?: LazyLoadingWidgetConfiguration | (() => Promise<LazyLoadingWidgetConfiguration>);
  component: () => Promise<any>;
}

@Injectable({ providedIn: "root" })
export class WidgetRegistry {
  public registry: { [name: string]: WidgetRegistryEntry } = {};

  #widgetComponentCache: { [name: string]: any } = {};

  /**
   * Registers widgets that are annotated with the @Widget decorator and extend the BaseWidgetComponent
   */
  public registerWidget(widget: unknown): void {
    const widgetConfiguration = getMetadata<WidgetConfiguration>(widget, "widgetRegistrationOptions");
    if (!widgetConfiguration) throw new Error("Widget does not use the @Widget Decorator: " + JSON.stringify(widget));
    if (widgetConfiguration.tags === undefined) widgetConfiguration.tags = AllWidgetTags;
    this.registry[widgetConfiguration.name] = {
      name: widgetConfiguration.name,
      configuration: async () => widgetConfiguration,
      component: async () => widget
    };
  }

  public addWidget(widgetRegistryEntry: WidgetRegistryEntry): void {
    // show warning if widget already exists
    if (this.registry[widgetRegistryEntry.name]) {
      Logger.debug(
        "WidgetRegistry",
        "Widget with name '" + widgetRegistryEntry.name + "' already exists. Overwriting existing widget."
      );
    }

    this.registry[widgetRegistryEntry.name] = widgetRegistryEntry;
  }

  public addDeprecatedWidget(deprecatedWidgetEntry: DeprecatedWidgetEntry): void {
    this.registry[deprecatedWidgetEntry.name] = {
      name: deprecatedWidgetEntry.name,
      configuration: async () => ({
        icon: deprecatedWidgetEntry.icon,
        name: deprecatedWidgetEntry.name,
        tags: deprecatedWidgetEntry.tags,
        templateDescription: deprecatedWidgetEntry.templateDescription,
        templateName: deprecatedWidgetEntry.templateName,
        dataSchema: deprecatedWidgetEntry.configuration,
        data: deprecatedWidgetEntry.data,
        sockets: deprecatedWidgetEntry.sockets
      }),
      component: async () => deprecatedWidgetEntry.component
    };
  }

  @Memoize()
  public getWidgetByName(name: string): WidgetRegistryEntry {
    if (this.registry[name]) return this.registry[name];
    Logger.debug("WidgetRegistry", "Widget not found by name: " + name);
  }

  /** This method determines, based on the schema, which widget to use */
  @Memoize()
  public getInputWidgetBySchema(schema: SchemaFormPropertyOptions): WidgetRegistryEntry {
    if (!schema) return undefined;

    // fix some types
    if (schema["x-schema-form"]?.widget === "Select" || schema["x-schema-form"]?.widget === "TypeSearch") {
      schema["x-schema-form"].inputWidget = WIDGET_FORM_INPUT_SELECT;
      delete schema["x-schema-form"].widget;
    }
    if (schema["x-schema-form"]?.widget === "htmlsizing") {
      schema["x-schema-form"].inputWidget = WIDGET_FORM_INPUT_HTML_SIZING;
      delete schema["x-schema-form"].widget;
    }
    if (schema["x-schema-form"]?.widget === "googlemaps") {
      schema["x-schema-form"].inputWidget = WIDGET_FORM_INPUT_GOOGLE_MAPS;
      delete schema["x-schema-form"].widget;
    }
    if (schema["x-schema-form"]?.widget === "fileurl") {
      schema["x-schema-form"].inputWidget = WIDGET_FORM_INPUT_FILE_URL;
      delete schema["x-schema-form"].widget;
    }

    // try to fix old types
    if (schema["x-schema-form"]?.widget !== undefined && schema["x-schema-form"]?.inputWidget === undefined) {
      const newWidgetName = upperCaseFirst(schema["x-schema-form"]?.widget) + "Input";
      if (this.getWidgetByName(newWidgetName)) return this.getWidgetByName(newWidgetName);
    }

    // if widget is explicitly set, use that one
    if (schema["x-schema-form"]?.inputWidget) return this.getWidgetByName(schema["x-schema-form"].inputWidget);
    else if (schema["x-schema-form"]?.widget) return this.getWidgetByName(schema["x-schema-form"].widget);
    else if (schema["x-schema-form"]?.type) return this.getWidgetByName(schema["x-schema-form"].type);

    // string enum should be a string select
    if (schema.enum !== undefined && (schema.type === "string" || schema.type === undefined))
      return this.getWidgetByName(WIDGET_FORM_INPUT_SELECT);

    // string array enum should be a multi string select
    // if (
    //   schema.type === "array" &&
    //   (schema.items as JSONSchema7)?.type === "string" &&
    //   (schema.items as JSONSchema7)?.enum instanceof Array
    // )
    //   return this.getWidgetByName("multistringselect");

    // fallback: try schema.type
    if (schema.type) return this.getWidgetByName(upperCaseFirst(schema.type as string) + "Input");
    throw new Error("No suitable method found to get input widget for schema: " + JSON.stringify(schema));
  }

  /** Returns a filter widget for the given schema. If none found, it will return the input widget for the schema */
  @Memoize()
  public getFilterWidgetBySchema(schema: SchemaFormPropertyOptions): WidgetRegistryEntry {
    if (!schema) {
      Logger.error("WidgetRegistry", "No schema provided for getFilterWidgetBySchema");
      return undefined;
    }

    // if widget is explicitly set, use that one
    if (schema["x-schema-form"]?.filterWidget) return this.getWidgetByName(schema["x-schema-form"].filterWidget);

    // try inputWidget without "Input" but "Filter" at the end
    if (schema["x-schema-form"]?.inputWidget) {
      const modifiedInputWidget = this.getWidgetByName(schema["x-schema-form"]?.inputWidget.replace("Input", "Filter"));
      if (modifiedInputWidget) return modifiedInputWidget;
    }

    const filterWidget = this.getWidgetByName(upperCaseFirst(schema.type as string) + "Filter");
    if (filterWidget) return filterWidget;
    else return this.getInputWidgetBySchema(schema);
  }

  /** Returns a view widget for the given schema */
  @Memoize()
  public getViewWidgetBySchema(schema: SchemaFormPropertyOptions): WidgetRegistryEntry {
    if (!schema) {
      Logger.error("WidgetRegistry", "No schema provided for getViewWidgetBySchema");
      return undefined;
    }

    // if widget is explicitly set, use that one
    if (schema["x-schema-form"]?.viewWidget) return this.getWidgetByName(schema["x-schema-form"].viewWidget);

    // try inputWidget without "Input" but "View" at the end
    if (schema["x-schema-form"]?.inputWidget) {
      const modifiedInputWidget = this.getWidgetByName(schema["x-schema-form"]?.inputWidget.replace("Input", "View"));
      if (modifiedInputWidget) return modifiedInputWidget;
    }

    // fallback: try schema.type
    if (schema.type) {
      const viewWidget = this.getWidgetByName(upperCaseFirst(schema.type as string) + "View");
      if (viewWidget) return viewWidget;
    }
    Logger.error("WidgetRegistry", "No view widget found for the following schema: ", schema);
  }

  public getAllWidgets(): Array<WidgetRegistryEntry> {
    return Object.values(this.registry);
  }

  @Memoize()
  public async getWidgetConfigurationByName(name: string): Promise<LazyLoadingWidgetConfiguration> {
    const entry = this.getWidgetByName(name);
    if (!entry) {
      Logger.error("WidgetRegistry", "No widget found for type " + name);
      return undefined;
    }
    return getPromiseProviderValue(entry.configuration);
  }

  @synchronized
  public async getWidgetComponentByName(name: string): Promise<any> {
    if (this.#widgetComponentCache[name]) return this.#widgetComponentCache[name];
    const entry = this.getWidgetByName(name);
    if (!entry) return undefined;
    this.#widgetComponentCache[name] = await entry.component();
    return this.#widgetComponentCache[name];
  }
}
