@if (isLoading()) {
  <smallstack-loader loadingText="Aufgaben werden geladen"></smallstack-loader>
} @else {
  @if (showTodos()) {
    @if (data().showTodoTitle) {
      <h4 class="my-4">Laufende Aufgaben</h4>
    }
    @if (data().showTodoDescription) {
      <div class="text-muted"
        >Ihre aktuell zu erledigenden Aufgaben im Überblick. Diese können Sie hier abhaken, sobald Sie fertig sind.</div
      >
    }
    @if (!todos() || todos().length === 0) {
      <div>
        <div class="list-container-empty flex flex-col">
          <smallstack-icon name="task"></smallstack-icon>
          Aktuell keine laufenden Aufgaben
        </div>
      </div>
    } @else {
      <div class="flex flex-col space-y-4">
        @for (todo of todos(); track todo.id) {
          <smallstack-icon-card icon="task" [title]="todo.label" [subTitle]="todo.description">
            <smallstack-todo-list-entry
              [todo]="todo"
              [showDueDate]="true"
              (updateFn)="updateFn($event)"
            ></smallstack-todo-list-entry>
          </smallstack-icon-card>
        }
      </div>
    }
  }
  @if (showTodoTemplates()) {
    <div class="todo-templates">
      @if (data().showTemplateTitle) {
        <h4 class="my-4">Geplante Aufgaben</h4>
      }
      @if (data().showTemplateDescription) {
        <div class="text-muted" style="padding-bottom: 16px"
          >Starten Sie eine geplante Aufgabe, um Ihre Arbeitszeiten zu protokollieren. Wählen Sie hierfür einen
          passenden Termin.</div
        >
      }
      @if (!todoTemplates() || todoTemplates().length === 0) {
        <div>
          <div class="list-container-empty flex flex-col">
            <smallstack-icon name="task"></smallstack-icon>
            <smallstack-i18n data="@@components.list.emptymessage"></smallstack-i18n>
          </div>
        </div>
      } @else {
        <div class="flex flex-col space-y-4">
          @for (todoTemplate of todoTemplates(); track todoTemplate) {
            <smallstack-todo-template-list-entry
              [todoTemplate]="todoTemplate"
              (createdTodo)="createdTodo($event)"
            ></smallstack-todo-template-list-entry>
          }
        </div>
      }
    </div>
  }
  @if ((showTodos() || showTodoTemplates()) && (todos()?.length > 0 || todoTemplates()?.length > 0)) {
    <ng-container #link>
      <a
        (click)="openLink()"
        queryParamsHandling="merge"
        [ngStyle]="{ cursor: data().link ? 'pointer' : 'unset' }"
        fxFlexAlign="end"
        style="margin-top: 12px"
        class="flex flex-row space-x-1 justify-end items-center"
      >
        <span style="font-size: 14px; font-weight: 600">{{ data().label }}</span>
        <smallstack-icon
          [name]="data()?.icon"
          [color]="data()?.iconColor ? data()?.iconColor : '#FFFFFF'"
          [theme]="data()?.iconTheme"
          [size]="data()?.iconSize ? data()?.iconSize : '24'"
        ></smallstack-icon>
      </a>
    </ng-container>
  }
}
