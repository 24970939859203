@if (store() && modelId() && property()) {
  @defer {
    <smallstack-store-property
      [store]="store()"
      [modelId]="modelId()"
      [property]="property()"
      [showNotFound]="data()?.showNotFound"
    ></smallstack-store-property>
  } @placeholder {
    <div class="skeleton h-6 w-32 opacity-50"></div>
  }
} @else {
  Bitte Store, ID und Property konfigurieren.
}
