import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const PrintButtonWidget: WidgetRegistryEntry = {
  name: "PrintButton",
  configuration: {
    templateName: "Seite drucken",
    templateDescription: "Dieses Widget zeigt einen Button, mit dem die aktuelle Seite gedruckt werden kann.",
    icon: "print--v1"
  },
  component: () => import("./print-button-widget.component").then((m) => m.PrintButtonWidgetComponent)
};
