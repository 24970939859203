import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconCardComponent, SmallstackCmsModule } from "@smallstack/cms-components";
import {
  ConfigurationStore,
  DecimalJsPipe,
  HumanizeDurationPipe,
  LoadingElementDirective,
  StoreRegistry
} from "@smallstack/common-components";
import { ConfigurablePropertyService } from "@smallstack/form-shared";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackLinkModule } from "@smallstack/link-components";
import { LoaderComponent, StoreContainerComponent } from "@smallstack/store-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { TodoBillingService } from "@smallstack/todo-shared";
import {
  TYPE_INVOICES,
  TYPE_TODOS,
  TYPE_TODO_SURCHARGES,
  TYPE_TODO_TEMPLATES,
  TYPE_WORKLOGS
} from "@smallstack/typesystem";
import { TypeIconPipe } from "@smallstack/typesystem-client";
import {
  SchemaFormInputsRegistry,
  SmallstackFormCoreModule,
  SmallstackWidgetsModule,
  WidgetRegistry
} from "@smallstack/widget-core";
import { SchedulingRuleInputV2Component } from "./components/scheduling-rule-input-v2/scheduling-rule-input-v2.component";
import { SchedulingRuleInputComponent } from "./components/scheduling-rule-input/scheduling-rule-input.component";
import { TodoBillingComponent } from "./components/todo-billing/todo-billing.component";
import { TodoListDialogComponent } from "./components/todo-list-dialog/todo-list-dialog.component";
import { TodoListEntryComponent } from "./components/todo-list-entry/todo-list-entry.component";
import { TodoListComponent } from "./components/todo-list/todo-list.component";
import { TodoTemplateListEntryComponent } from "./components/todo-template-list-entry/todo-template-list-entry.component";
import { CreateInvoicesWidgetComponent } from "./components/widgets/create-invoices-widget/create-invoices-widget.component";
import { CreateSimpleTodoWidgetComponent } from "./components/widgets/create-simple-todo-widget/create-simple-todo-widget.component";
import { CreateTodoWidgetComponent } from "./components/widgets/create-todo-widget/create-todo-widget.component";
import { SignalWidgetComponent } from "./components/widgets/signal-widget/signal-widget.component";
import { TodoBillingWidgetComponent } from "./components/widgets/todo-billing-widget/todo-billing-widget.component";
import { TodoListWidgetComponent } from "./components/widgets/todo-list-widget/todo-list-widget.component";
import { TodoPriorityWidgetComponent } from "./components/widgets/todo-priority-widget/todo-priority-widget.component";
import { TodoStatusWidgetComponent } from "./components/widgets/todo-status-widget/todo-status-widget.component";
import { TodoWidgetComponent } from "./components/widgets/todo-widget/todo-widget.component";
import { UpcomingTodoTemplatesWidget } from "./components/widgets/upcoming-todo-templates-widget";
import { UpcomingTodosWidgetComponent } from "./components/widgets/upcoming-todos-widget/upcoming-todos-widget.component";
import { WorklogBillingWidgetComponent } from "./components/widgets/worklog-billing-widget/worklog-billing-widget.component";
import { WorklogListWidgetComponent } from "./components/widgets/worklog-list-widget/worklog-list-widget.component";
import { WorklogTimerWidgetComponent } from "./components/widgets/worklog-timer-widget/worklog-timer-widget.component";
import { WorklogBillingComponent } from "./components/worklog-billing/worklog-billing.component";
import { WorklogTimerComponent } from "./components/worklog-timer/worklog-timer.component";
import { InvoiceStore } from "./stores/invoice.store";
import { TodoSurchargesStore } from "./stores/todo-surcharges.store";
import { TodoTemplateStore } from "./stores/todo-template.store";
import { TodoStore } from "./stores/todo.store";
import { WorklogStore } from "./stores/worklog.store";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
    SmallstackLinkModule,
    SmallstackThemeModule,
    SmallstackI18nModule,
    SmallstackCmsModule,
    SmallstackThemeModule,
    SmallstackI18nModule,
    SmallstackFormCoreModule,
    SmallstackWidgetsModule,
    CreateInvoicesWidgetComponent,
    IconCardComponent,
    TodoListEntryComponent,
    TodoTemplateListEntryComponent,
    TodoListComponent,
    DecimalJsPipe,
    HumanizeDurationPipe,
    LoadingElementDirective,
    StoreContainerComponent,
    LoaderComponent,
    TypeIconPipe
  ],
  providers: [
    {
      provide: ConfigurablePropertyService,
      useFactory: (configurationStore: ConfigurationStore) => new ConfigurablePropertyService(configurationStore)
    },
    {
      provide: TodoBillingService,
      useFactory: (configurablePropertyService: ConfigurablePropertyService) => {
        return new TodoBillingService(configurablePropertyService);
      },
      deps: [ConfigurablePropertyService]
    }
  ],
  declarations: [
    UpcomingTodosWidgetComponent,
    WorklogTimerWidgetComponent,
    WorklogTimerComponent,
    TodoListWidgetComponent,
    TodoListDialogComponent,
    SchedulingRuleInputComponent,
    SchedulingRuleInputV2Component,
    WorklogListWidgetComponent,
    WorklogBillingComponent,
    WorklogBillingWidgetComponent,
    TodoBillingComponent,
    TodoBillingWidgetComponent,
    SignalWidgetComponent,
    TodoPriorityWidgetComponent,
    TodoStatusWidgetComponent,
    TodoWidgetComponent,
    CreateSimpleTodoWidgetComponent
  ],
  exports: [
    UpcomingTodosWidgetComponent,
    WorklogTimerWidgetComponent,
    WorklogTimerComponent,
    TodoListComponent,
    TodoListEntryComponent,
    TodoTemplateListEntryComponent,
    TodoListWidgetComponent,
    TodoListDialogComponent,
    SchedulingRuleInputComponent,
    SchedulingRuleInputV2Component,
    WorklogListWidgetComponent,
    WorklogBillingComponent,
    WorklogBillingWidgetComponent,
    TodoBillingComponent,
    TodoBillingWidgetComponent,
    SignalWidgetComponent,
    TodoPriorityWidgetComponent,
    TodoStatusWidgetComponent,
    TodoWidgetComponent,
    CreateSimpleTodoWidgetComponent
  ]
})
export class SmallstackTodoModule {
  constructor(
    widgetRegistry: WidgetRegistry,
    storeRegistry: StoreRegistry,
    schemaFormInputsRegistry: SchemaFormInputsRegistry,
    todoStore: TodoStore,
    todoTemplateStore: TodoTemplateStore,
    worklogStore: WorklogStore,
    invoiceStore: InvoiceStore,
    todoSurchargesStore: TodoSurchargesStore
  ) {
    // register stores
    storeRegistry.registerStore(TYPE_TODOS, todoStore);
    storeRegistry.registerStore(TYPE_TODO_TEMPLATES, todoTemplateStore);
    storeRegistry.registerStore(TYPE_WORKLOGS, worklogStore);
    storeRegistry.registerStore(TYPE_INVOICES, invoiceStore);
    storeRegistry.registerStore(TYPE_TODO_SURCHARGES, todoSurchargesStore);

    // register widgets
    widgetRegistry.registerWidget(UpcomingTodosWidgetComponent);
    widgetRegistry.registerWidget(WorklogTimerWidgetComponent);
    widgetRegistry.registerWidget(TodoListWidgetComponent);
    widgetRegistry.registerWidget(WorklogListWidgetComponent);
    widgetRegistry.registerWidget(WorklogBillingWidgetComponent);
    widgetRegistry.registerWidget(TodoBillingWidgetComponent);
    widgetRegistry.registerWidget(SignalWidgetComponent);
    widgetRegistry.registerWidget(TodoPriorityWidgetComponent);
    widgetRegistry.registerWidget(TodoStatusWidgetComponent);
    widgetRegistry.registerWidget(TodoWidgetComponent);
    widgetRegistry.registerWidget(CreateSimpleTodoWidgetComponent);
    widgetRegistry.registerWidget(CreateInvoicesWidgetComponent);
    widgetRegistry.registerWidget(CreateTodoWidgetComponent);
    widgetRegistry.addWidget(UpcomingTodoTemplatesWidget);

    // register form inputs
    schemaFormInputsRegistry.addWidget("SchedulingRule", SchedulingRuleInputComponent);
    schemaFormInputsRegistry.addWidget("SchedulingRuleV2", SchedulingRuleInputV2Component);
  }
}
