<div class="timeline-container">
  <div style="padding-bottom: 4px" class="top-bar flex flex-row justify-end">
    <button class="btn-action" (click)="showPrivates = !showPrivates">
      <smallstack-icon [name]="showPrivates ? 'visible' : 'invisible'"></smallstack-icon>
    </button>
    <smallstack-widget-child
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      property="moreActionsComponent"
      [context]="context()"
    ></smallstack-widget-child>
  </div>
  @if (elements$ | async; as elements) {
    <div class="line"></div>
    @for (element of elements; track element) {
      <div class="element flex flex-row">
        <div class="icon">
          <div style="position: relative">
            <smallstack-icon [name]="element.icon" size="32" class="icon-background"></smallstack-icon>
          </div>
          <smallstack-icon [name]="element.icon" size="32"></smallstack-icon>
        </div>
        <div class="content flex flex-col-reverse md:flex-row justify-between flex-auto gap-1 md:gap-0">
          <div class="flex flex-col justify-center">
            <div class="title" [ngClass]="{ small: data().highlightSender === false }"
              ><smallstack-i18n [data]="element.title"></smallstack-i18n
            ></div>
            <div class="text" [ngClass]="{ big: data().highlightSender === false }"
              ><smallstack-i18n
                [data]="element.text"
                [ngClass]="{ blurred: showPrivates === false && element.badges?.includes('private') }"
              ></smallstack-i18n
            ></div>
          </div>
          <div class="timestamp flex flex-row md:flex-col items-end" style="white-space: nowrap">
            <smallstack-date [timestamp]="element.timestamp"></smallstack-date>
            @for (badge of element.badges; track badge) {
              <div class="badge" style="zoom: 0.8">{{ badge }}</div>
            }
          </div>
        </div>
      </div>
    }
  } @else {
    <smallstack-loader></smallstack-loader>
  }
</div>
