<span
  data-test="form-title-text"
  class="whitespace-nowrap"
  [ngStyle]="{ color: isInvalid() === true ? '#D32F2F' : 'unset' }"
>
  <smallstack-i18n [data]="data()" [translationStore]="formService.translationStore" />
  @if (isRequired() && showRequired()) {
    <span style="position: relative; top: -4px; right: 2px"> *</span>
  }
</span>
