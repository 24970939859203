@if (message) {
  <div class="mailing-opt-box" [ngClass]="{ successful: successful === true, error: successful === false }">
    <div class="flex flex-col space-y-3 justify-end">
      <div class="flex flex-row space-x-3">
        @if (isLoading) {
          <smallstack-loader loadingText=""></smallstack-loader>
        }
        <smallstack-i18n [data]="message"></smallstack-i18n>
      </div>
      @if (!isLoading) {
        <button (click)="acknowledge()" class="btn btn-primary">
          <smallstack-i18n data="@@actions.close"></smallstack-i18n>
        </button>
      }
    </div>
  </div>
}
