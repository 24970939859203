import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { SmallstackTypeTag, TypeSchema } from "@smallstack/typesystem";
import { FormService } from "@smallstack/widget-core";

export interface AddNewLinkDialogData {
  link?: LinkedModelDto;
  tags?: SmallstackTypeTag[];
  linkableTypes?: string[];
  formService?: FormService;
}

@Component({
  selector: "smallstack-add-new-link",
  templateUrl: "./add-new-link.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewLinkComponent {
  public link: LinkedModelDto;
  public typeTags: SmallstackTypeTag[];

  protected schema: TypeSchema;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: AddNewLinkDialogData,
    protected cdr: ChangeDetectorRef
  ) {
    if (data?.link) this.link = data.link;
    else this.link = { id: "", type: "" };
    if (data?.tags) this.typeTags = data.tags;
    else this.typeTags = [SmallstackTypeTag.COMMON];
    this.schema = {
      type: "object",
      properties: {
        id: {
          title: "Entität",
          type: "string",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            type: this.link.type
          }
        }
      }
    };
  }

  protected updateSchema(newType: string): void {
    this.schema = {
      type: "object",
      properties: {
        id: {
          title: "Entität",
          type: "string",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            type: newType
          }
        }
      }
    };
    this.cdr.markForCheck();
  }
}
