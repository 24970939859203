/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryDto } from '../models';
// @ts-ignore
import { CountryPageDto } from '../models';
// @ts-ignore
import { CountrySearchResultPageDto } from '../models';
// @ts-ignore
import { LanguageDto } from '../models';
// @ts-ignore
import { LanguagePageDto } from '../models';
// @ts-ignore
import { LanguageSearchResultPageDto } from '../models';
// @ts-ignore
import { LocaleDto } from '../models';
// @ts-ignore
import { LocaleFallbackChainDto } from '../models';
// @ts-ignore
import { LocalePageDto } from '../models';
// @ts-ignore
import { LocaleSearchResultPageDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
// @ts-ignore
import { TranslationDto } from '../models';
// @ts-ignore
import { TranslationPageDto } from '../models';
// @ts-ignore
import { TranslationSearchResultPageDto } from '../models';
/**
 * InternationalizationApi - axios parameter creator
 * @export
 */
export const InternationalizationApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a Country exists.
         * @summary Check if Country exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling countryExists.');
            }
            const localVarPath = `/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCountry: async (country: CountryDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            if (country === null || country === undefined) {
                throw new RequiredError('country','Required parameter country was null or undefined when calling createCountry.');
            }
            const localVarPath = `/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof country !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(country !== undefined ? country : {})
                : (country || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage: async (language: LanguageDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling createLanguage.');
            }
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocale: async (locale: LocaleDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling createLocale.');
            }
            const localVarPath = `/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof locale !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(locale !== undefined ? locale : {})
                : (locale || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTranslation: async (translation: TranslationDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'translation' is not null or undefined
            if (translation === null || translation === undefined) {
                throw new RequiredError('translation','Required parameter translation was null or undefined when calling createTranslation.');
            }
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof translation !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(translation !== undefined ? translation : {})
                : (translation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCountry.');
            }
            const localVarPath = `/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocale: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLocale.');
            }
            const localVarPath = `/locales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyCountries: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyCountries.');
            }
            const localVarPath = `/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLanguages: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyLanguages.');
            }
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLocales: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyLocales.');
            }
            const localVarPath = `/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTranslations: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyTranslations.');
            }
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslation: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTranslation.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/countries/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCountry.');
            }
            const localVarPath = `/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocale: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLocale.');
            }
            const localVarPath = `/locales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocaleFallbackChain: async (locale: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling getLocaleFallbackChain.');
            }
            const localVarPath = `/locales/fallbacks/{locale}`
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocaleFallbackChains: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/locales/fallbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/locales/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTranslation.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a Language exists.
         * @summary Check if Language exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling languageExists.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a Locale exists.
         * @summary Check if Locale exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localeExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling localeExists.');
            }
            const localVarPath = `/locales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCountry: async (id: string, country: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchCountry.');
            }
            // verify required parameter 'country' is not null or undefined
            if (country === null || country === undefined) {
                throw new RequiredError('country','Required parameter country was null or undefined when calling patchCountry.');
            }
            const localVarPath = `/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof country !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(country !== undefined ? country : {})
                : (country || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLanguage: async (id: string, language: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchLanguage.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling patchLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLocale: async (id: string, locale: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchLocale.');
            }
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling patchLocale.');
            }
            const localVarPath = `/locales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof locale !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(locale !== undefined ? locale : {})
                : (locale || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTranslation: async (id: string, translation: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchTranslation.');
            }
            // verify required parameter 'translation' is not null or undefined
            if (translation === null || translation === undefined) {
                throw new RequiredError('translation','Required parameter translation was null or undefined when calling patchTranslation.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof translation !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(translation !== undefined ? translation : {})
                : (translation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performCountrySearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performCountrySearch.');
            }
            const localVarPath = `/countries/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performLanguageSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performLanguageSearch.');
            }
            const localVarPath = `/languages/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performLocaleSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performLocaleSearch.');
            }
            const localVarPath = `/locales/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performTranslationSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performTranslationSearch.');
            }
            const localVarPath = `/translations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCountry: async (id: string, country: CountryDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putCountry.');
            }
            // verify required parameter 'country' is not null or undefined
            if (country === null || country === undefined) {
                throw new RequiredError('country','Required parameter country was null or undefined when calling putCountry.');
            }
            const localVarPath = `/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof country !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(country !== undefined ? country : {})
                : (country || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLanguage: async (id: string, language: LanguageDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putLanguage.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling putLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLocale: async (id: string, locale: LocaleDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putLocale.');
            }
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling putLocale.');
            }
            const localVarPath = `/locales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof locale !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(locale !== undefined ? locale : {})
                : (locale || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTranslation: async (id: string, translation: TranslationDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putTranslation.');
            }
            // verify required parameter 'translation' is not null or undefined
            if (translation === null || translation === undefined) {
                throw new RequiredError('translation','Required parameter translation was null or undefined when calling putTranslation.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof translation !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(translation !== undefined ? translation : {})
                : (translation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a Translation exists.
         * @summary Check if Translation exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling translationExists.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternationalizationApi - functional programming interface
 * @export
 */
export const InternationalizationApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * This is a very performant method for checking if a Country exists.
         * @summary Check if Country exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).countryExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCountry(country: CountryDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).createCountry(country, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLanguage(language: LanguageDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).createLanguage(language, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocale(locale: LocaleDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).createLocale(locale, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTranslation(translation: TranslationDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).createTranslation(translation, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCountry(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteCountry(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLanguage(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteLanguage(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocale(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteLocale(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyCountries(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteManyCountries(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyLanguages(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteManyLanguages(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyLocales(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteManyLocales(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyTranslations(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteManyTranslations(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTranslation(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).deleteTranslation(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getCountries(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountriesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getCountriesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountry(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getCountry(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguage(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLanguage(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguages(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguagePageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLanguages(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguagesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLanguagesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocale(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLocale(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocaleFallbackChain(locale: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLocaleFallbackChain(locale, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocaleFallbackChains(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocaleFallbackChainDto>>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLocaleFallbackChains(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocales(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalePageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLocales(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getLocalesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslation(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getTranslation(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getTranslations(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).getTranslationsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a Language exists.
         * @summary Check if Language exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).languageExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a Locale exists.
         * @summary Check if Locale exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localeExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).localeExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCountry(id: string, country: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).patchCountry(id, country, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLanguage(id: string, language: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).patchLanguage(id, language, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLocale(id: string, locale: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).patchLocale(id, locale, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTranslation(id: string, translation: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).patchTranslation(id, translation, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performCountrySearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountrySearchResultPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).performCountrySearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performLanguageSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageSearchResultPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).performLanguageSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performLocaleSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleSearchResultPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).performLocaleSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performTranslationSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationSearchResultPageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).performTranslationSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCountry(id: string, country: CountryDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).putCountry(id, country, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLanguage(id: string, language: LanguageDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).putLanguage(id, language, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLocale(id: string, locale: LocaleDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).putLocale(id, locale, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTranslation(id: string, translation: TranslationDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDto>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).putTranslation(id, translation, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a Translation exists.
         * @summary Check if Translation exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InternationalizationApiAxiosParamCreator(axiosClientConfiguration).translationExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternationalizationApi - factory interface
 * @export
 */
export const InternationalizationApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This is a very performant method for checking if a Country exists.
         * @summary Check if Country exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryExists(id: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).countryExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCountry(country: CountryDto, translate?: string, options?: any): AxiosPromise<CountryDto> {
            return InternationalizationApiFp(axiosClientConfiguration).createCountry(country, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage(language: LanguageDto, translate?: string, options?: any): AxiosPromise<LanguageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).createLanguage(language, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocale(locale: LocaleDto, translate?: string, options?: any): AxiosPromise<LocaleDto> {
            return InternationalizationApiFp(axiosClientConfiguration).createLocale(locale, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTranslation(translation: TranslationDto, translate?: string, options?: any): AxiosPromise<TranslationDto> {
            return InternationalizationApiFp(axiosClientConfiguration).createTranslation(translation, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteCountry(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteLanguage(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocale(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteLocale(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyCountries(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteManyCountries(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLanguages(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteManyLanguages(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLocales(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteManyLocales(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTranslations(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteManyTranslations(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslation(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).deleteTranslation(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<CountryPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getCountries(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return InternationalizationApiFp(axiosClientConfiguration).getCountriesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry(id: string, translate?: string, options?: any): AxiosPromise<CountryDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getCountry(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage(id: string, translate?: string, options?: any): AxiosPromise<LanguageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getLanguage(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<LanguagePageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getLanguages(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return InternationalizationApiFp(axiosClientConfiguration).getLanguagesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocale(id: string, translate?: string, options?: any): AxiosPromise<LocaleDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getLocale(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocaleFallbackChain(locale: string, translate?: string, options?: any): AxiosPromise<Array<string>> {
            return InternationalizationApiFp(axiosClientConfiguration).getLocaleFallbackChain(locale, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocaleFallbackChains(translate?: string, options?: any): AxiosPromise<Array<LocaleFallbackChainDto>> {
            return InternationalizationApiFp(axiosClientConfiguration).getLocaleFallbackChains(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<LocalePageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getLocales(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return InternationalizationApiFp(axiosClientConfiguration).getLocalesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation(id: string, translate?: string, options?: any): AxiosPromise<TranslationDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getTranslation(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<TranslationPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).getTranslations(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return InternationalizationApiFp(axiosClientConfiguration).getTranslationsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a Language exists.
         * @summary Check if Language exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageExists(id: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).languageExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a Locale exists.
         * @summary Check if Locale exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localeExists(id: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).localeExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCountry(id: string, country: any, translate?: string, options?: any): AxiosPromise<CountryDto> {
            return InternationalizationApiFp(axiosClientConfiguration).patchCountry(id, country, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLanguage(id: string, language: any, translate?: string, options?: any): AxiosPromise<LanguageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).patchLanguage(id, language, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLocale(id: string, locale: any, translate?: string, options?: any): AxiosPromise<LocaleDto> {
            return InternationalizationApiFp(axiosClientConfiguration).patchLocale(id, locale, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTranslation(id: string, translation: any, translate?: string, options?: any): AxiosPromise<TranslationDto> {
            return InternationalizationApiFp(axiosClientConfiguration).patchTranslation(id, translation, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performCountrySearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<CountrySearchResultPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).performCountrySearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performLanguageSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<LanguageSearchResultPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).performLanguageSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performLocaleSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<LocaleSearchResultPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).performLocaleSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performTranslationSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<TranslationSearchResultPageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).performTranslationSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} country 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCountry(id: string, country: CountryDto, translate?: string, options?: any): AxiosPromise<CountryDto> {
            return InternationalizationApiFp(axiosClientConfiguration).putCountry(id, country, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {LanguageDto} language 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLanguage(id: string, language: LanguageDto, translate?: string, options?: any): AxiosPromise<LanguageDto> {
            return InternationalizationApiFp(axiosClientConfiguration).putLanguage(id, language, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {LocaleDto} locale 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLocale(id: string, locale: LocaleDto, translate?: string, options?: any): AxiosPromise<LocaleDto> {
            return InternationalizationApiFp(axiosClientConfiguration).putLocale(id, locale, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TranslationDto} translation 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTranslation(id: string, translation: TranslationDto, translate?: string, options?: any): AxiosPromise<TranslationDto> {
            return InternationalizationApiFp(axiosClientConfiguration).putTranslation(id, translation, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a Translation exists.
         * @summary Check if Translation exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationExists(id: string, options?: any): AxiosPromise<void> {
            return InternationalizationApiFp(axiosClientConfiguration).translationExists(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countryExists operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiCountryExistsRequest
 */
export interface InternationalizationApiCountryExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiCountryExists
     */
    readonly id: string
}

/**
 * Request parameters for createCountry operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiCreateCountryRequest
 */
export interface InternationalizationApiCreateCountryRequest {
    /**
     * 
     * @type {CountryDto}
     * @memberof InternationalizationApiCreateCountry
     */
    readonly country: CountryDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiCreateCountry
     */
    readonly translate?: string
}

/**
 * Request parameters for createLanguage operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiCreateLanguageRequest
 */
export interface InternationalizationApiCreateLanguageRequest {
    /**
     * 
     * @type {LanguageDto}
     * @memberof InternationalizationApiCreateLanguage
     */
    readonly language: LanguageDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiCreateLanguage
     */
    readonly translate?: string
}

/**
 * Request parameters for createLocale operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiCreateLocaleRequest
 */
export interface InternationalizationApiCreateLocaleRequest {
    /**
     * 
     * @type {LocaleDto}
     * @memberof InternationalizationApiCreateLocale
     */
    readonly locale: LocaleDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiCreateLocale
     */
    readonly translate?: string
}

/**
 * Request parameters for createTranslation operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiCreateTranslationRequest
 */
export interface InternationalizationApiCreateTranslationRequest {
    /**
     * 
     * @type {TranslationDto}
     * @memberof InternationalizationApiCreateTranslation
     */
    readonly translation: TranslationDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiCreateTranslation
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteCountry operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteCountryRequest
 */
export interface InternationalizationApiDeleteCountryRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteCountry
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteCountry
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteLanguage operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteLanguageRequest
 */
export interface InternationalizationApiDeleteLanguageRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteLanguage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteLanguage
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteLocale operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteLocaleRequest
 */
export interface InternationalizationApiDeleteLocaleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteLocale
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteLocale
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyCountries operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteManyCountriesRequest
 */
export interface InternationalizationApiDeleteManyCountriesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InternationalizationApiDeleteManyCountries
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteManyCountries
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyLanguages operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteManyLanguagesRequest
 */
export interface InternationalizationApiDeleteManyLanguagesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InternationalizationApiDeleteManyLanguages
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteManyLanguages
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyLocales operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteManyLocalesRequest
 */
export interface InternationalizationApiDeleteManyLocalesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InternationalizationApiDeleteManyLocales
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteManyLocales
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyTranslations operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteManyTranslationsRequest
 */
export interface InternationalizationApiDeleteManyTranslationsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InternationalizationApiDeleteManyTranslations
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteManyTranslations
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteTranslation operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiDeleteTranslationRequest
 */
export interface InternationalizationApiDeleteTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteTranslation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiDeleteTranslation
     */
    readonly translate?: string
}

/**
 * Request parameters for getCountries operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetCountriesRequest
 */
export interface InternationalizationApiGetCountriesRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetCountries
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetCountries
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountries
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetCountries
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountries
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountries
     */
    readonly search?: string
}

/**
 * Request parameters for getCountriesCount operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetCountriesCountRequest
 */
export interface InternationalizationApiGetCountriesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountriesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getCountry operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetCountryRequest
 */
export interface InternationalizationApiGetCountryRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountry
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetCountry
     */
    readonly translate?: string
}

/**
 * Request parameters for getLanguage operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLanguageRequest
 */
export interface InternationalizationApiGetLanguageRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguage
     */
    readonly translate?: string
}

/**
 * Request parameters for getLanguages operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLanguagesRequest
 */
export interface InternationalizationApiGetLanguagesRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguages
     */
    readonly search?: string
}

/**
 * Request parameters for getLanguagesCount operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLanguagesCountRequest
 */
export interface InternationalizationApiGetLanguagesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLanguagesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getLocale operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLocaleRequest
 */
export interface InternationalizationApiGetLocaleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocale
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocale
     */
    readonly translate?: string
}

/**
 * Request parameters for getLocaleFallbackChain operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLocaleFallbackChainRequest
 */
export interface InternationalizationApiGetLocaleFallbackChainRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocaleFallbackChain
     */
    readonly locale: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocaleFallbackChain
     */
    readonly translate?: string
}

/**
 * Request parameters for getLocaleFallbackChains operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLocaleFallbackChainsRequest
 */
export interface InternationalizationApiGetLocaleFallbackChainsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocaleFallbackChains
     */
    readonly translate?: string
}

/**
 * Request parameters for getLocales operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLocalesRequest
 */
export interface InternationalizationApiGetLocalesRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLocales
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLocales
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocales
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetLocales
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocales
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocales
     */
    readonly search?: string
}

/**
 * Request parameters for getLocalesCount operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetLocalesCountRequest
 */
export interface InternationalizationApiGetLocalesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetLocalesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getTranslation operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetTranslationRequest
 */
export interface InternationalizationApiGetTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslation
     */
    readonly translate?: string
}

/**
 * Request parameters for getTranslations operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetTranslationsRequest
 */
export interface InternationalizationApiGetTranslationsRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslations
     */
    readonly search?: string
}

/**
 * Request parameters for getTranslationsCount operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiGetTranslationsCountRequest
 */
export interface InternationalizationApiGetTranslationsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiGetTranslationsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for languageExists operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiLanguageExistsRequest
 */
export interface InternationalizationApiLanguageExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiLanguageExists
     */
    readonly id: string
}

/**
 * Request parameters for localeExists operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiLocaleExistsRequest
 */
export interface InternationalizationApiLocaleExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiLocaleExists
     */
    readonly id: string
}

/**
 * Request parameters for patchCountry operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPatchCountryRequest
 */
export interface InternationalizationApiPatchCountryRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchCountry
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof InternationalizationApiPatchCountry
     */
    readonly country: any

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchCountry
     */
    readonly translate?: string
}

/**
 * Request parameters for patchLanguage operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPatchLanguageRequest
 */
export interface InternationalizationApiPatchLanguageRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchLanguage
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof InternationalizationApiPatchLanguage
     */
    readonly language: any

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchLanguage
     */
    readonly translate?: string
}

/**
 * Request parameters for patchLocale operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPatchLocaleRequest
 */
export interface InternationalizationApiPatchLocaleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchLocale
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof InternationalizationApiPatchLocale
     */
    readonly locale: any

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchLocale
     */
    readonly translate?: string
}

/**
 * Request parameters for patchTranslation operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPatchTranslationRequest
 */
export interface InternationalizationApiPatchTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchTranslation
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof InternationalizationApiPatchTranslation
     */
    readonly translation: any

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPatchTranslation
     */
    readonly translate?: string
}

/**
 * Request parameters for performCountrySearch operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPerformCountrySearchRequest
 */
export interface InternationalizationApiPerformCountrySearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof InternationalizationApiPerformCountrySearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPerformCountrySearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performLanguageSearch operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPerformLanguageSearchRequest
 */
export interface InternationalizationApiPerformLanguageSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof InternationalizationApiPerformLanguageSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPerformLanguageSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performLocaleSearch operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPerformLocaleSearchRequest
 */
export interface InternationalizationApiPerformLocaleSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof InternationalizationApiPerformLocaleSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPerformLocaleSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performTranslationSearch operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPerformTranslationSearchRequest
 */
export interface InternationalizationApiPerformTranslationSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof InternationalizationApiPerformTranslationSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPerformTranslationSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putCountry operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPutCountryRequest
 */
export interface InternationalizationApiPutCountryRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutCountry
     */
    readonly id: string

    /**
     * 
     * @type {CountryDto}
     * @memberof InternationalizationApiPutCountry
     */
    readonly country: CountryDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutCountry
     */
    readonly translate?: string
}

/**
 * Request parameters for putLanguage operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPutLanguageRequest
 */
export interface InternationalizationApiPutLanguageRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutLanguage
     */
    readonly id: string

    /**
     * 
     * @type {LanguageDto}
     * @memberof InternationalizationApiPutLanguage
     */
    readonly language: LanguageDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutLanguage
     */
    readonly translate?: string
}

/**
 * Request parameters for putLocale operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPutLocaleRequest
 */
export interface InternationalizationApiPutLocaleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutLocale
     */
    readonly id: string

    /**
     * 
     * @type {LocaleDto}
     * @memberof InternationalizationApiPutLocale
     */
    readonly locale: LocaleDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutLocale
     */
    readonly translate?: string
}

/**
 * Request parameters for putTranslation operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiPutTranslationRequest
 */
export interface InternationalizationApiPutTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutTranslation
     */
    readonly id: string

    /**
     * 
     * @type {TranslationDto}
     * @memberof InternationalizationApiPutTranslation
     */
    readonly translation: TranslationDto

    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiPutTranslation
     */
    readonly translate?: string
}

/**
 * Request parameters for translationExists operation in InternationalizationApi.
 * @export
 * @interface InternationalizationApiTranslationExistsRequest
 */
export interface InternationalizationApiTranslationExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternationalizationApiTranslationExists
     */
    readonly id: string
}

/**
 * InternationalizationApi - object-oriented interface
 * @export
 * @class InternationalizationApi
 * @extends {BaseAPI}
 */
export class InternationalizationApi extends BaseAPI {
    /**
     * This is a very performant method for checking if a Country exists.
     * @summary Check if Country exists
     * @param {InternationalizationApiCountryExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public countryExists(requestParameters: InternationalizationApiCountryExistsRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).countryExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiCreateCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public createCountry(requestParameters: InternationalizationApiCreateCountryRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).createCountry(requestParameters.country, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiCreateLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public createLanguage(requestParameters: InternationalizationApiCreateLanguageRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).createLanguage(requestParameters.language, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiCreateLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public createLocale(requestParameters: InternationalizationApiCreateLocaleRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).createLocale(requestParameters.locale, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiCreateTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public createTranslation(requestParameters: InternationalizationApiCreateTranslationRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).createTranslation(requestParameters.translation, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteCountry(requestParameters: InternationalizationApiDeleteCountryRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteCountry(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteLanguage(requestParameters: InternationalizationApiDeleteLanguageRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteLanguage(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteLocale(requestParameters: InternationalizationApiDeleteLocaleRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteLocale(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteManyCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteManyCountries(requestParameters: InternationalizationApiDeleteManyCountriesRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteManyCountries(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteManyLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteManyLanguages(requestParameters: InternationalizationApiDeleteManyLanguagesRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteManyLanguages(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteManyLocalesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteManyLocales(requestParameters: InternationalizationApiDeleteManyLocalesRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteManyLocales(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteManyTranslationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteManyTranslations(requestParameters: InternationalizationApiDeleteManyTranslationsRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteManyTranslations(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiDeleteTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public deleteTranslation(requestParameters: InternationalizationApiDeleteTranslationRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).deleteTranslation(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getCountries(requestParameters: InternationalizationApiGetCountriesRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getCountries(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetCountriesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getCountriesCount(requestParameters: InternationalizationApiGetCountriesCountRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getCountriesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getCountry(requestParameters: InternationalizationApiGetCountryRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getCountry(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLanguage(requestParameters: InternationalizationApiGetLanguageRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLanguage(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLanguages(requestParameters: InternationalizationApiGetLanguagesRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLanguages(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLanguagesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLanguagesCount(requestParameters: InternationalizationApiGetLanguagesCountRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLanguagesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLocale(requestParameters: InternationalizationApiGetLocaleRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLocale(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLocaleFallbackChainRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLocaleFallbackChain(requestParameters: InternationalizationApiGetLocaleFallbackChainRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLocaleFallbackChain(requestParameters.locale, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLocaleFallbackChainsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLocaleFallbackChains(requestParameters: InternationalizationApiGetLocaleFallbackChainsRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLocaleFallbackChains(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLocalesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLocales(requestParameters: InternationalizationApiGetLocalesRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLocales(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetLocalesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getLocalesCount(requestParameters: InternationalizationApiGetLocalesCountRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getLocalesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getTranslation(requestParameters: InternationalizationApiGetTranslationRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getTranslation(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetTranslationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getTranslations(requestParameters: InternationalizationApiGetTranslationsRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getTranslations(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiGetTranslationsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public getTranslationsCount(requestParameters: InternationalizationApiGetTranslationsCountRequest = {}, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).getTranslationsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a Language exists.
     * @summary Check if Language exists
     * @param {InternationalizationApiLanguageExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public languageExists(requestParameters: InternationalizationApiLanguageExistsRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).languageExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a Locale exists.
     * @summary Check if Locale exists
     * @param {InternationalizationApiLocaleExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public localeExists(requestParameters: InternationalizationApiLocaleExistsRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).localeExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPatchCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public patchCountry(requestParameters: InternationalizationApiPatchCountryRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).patchCountry(requestParameters.id, requestParameters.country, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPatchLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public patchLanguage(requestParameters: InternationalizationApiPatchLanguageRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).patchLanguage(requestParameters.id, requestParameters.language, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPatchLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public patchLocale(requestParameters: InternationalizationApiPatchLocaleRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).patchLocale(requestParameters.id, requestParameters.locale, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPatchTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public patchTranslation(requestParameters: InternationalizationApiPatchTranslationRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).patchTranslation(requestParameters.id, requestParameters.translation, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPerformCountrySearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public performCountrySearch(requestParameters: InternationalizationApiPerformCountrySearchRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).performCountrySearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPerformLanguageSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public performLanguageSearch(requestParameters: InternationalizationApiPerformLanguageSearchRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).performLanguageSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPerformLocaleSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public performLocaleSearch(requestParameters: InternationalizationApiPerformLocaleSearchRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).performLocaleSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPerformTranslationSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public performTranslationSearch(requestParameters: InternationalizationApiPerformTranslationSearchRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).performTranslationSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPutCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public putCountry(requestParameters: InternationalizationApiPutCountryRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).putCountry(requestParameters.id, requestParameters.country, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPutLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public putLanguage(requestParameters: InternationalizationApiPutLanguageRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).putLanguage(requestParameters.id, requestParameters.language, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPutLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public putLocale(requestParameters: InternationalizationApiPutLocaleRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).putLocale(requestParameters.id, requestParameters.locale, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternationalizationApiPutTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public putTranslation(requestParameters: InternationalizationApiPutTranslationRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).putTranslation(requestParameters.id, requestParameters.translation, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a Translation exists.
     * @summary Check if Translation exists
     * @param {InternationalizationApiTranslationExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternationalizationApi
     */
    public translationExists(requestParameters: InternationalizationApiTranslationExistsRequest, options?: any) {
        return InternationalizationApiFp(this.axiosClientConfiguration).translationExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
