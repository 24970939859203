<div (click)="widgetClick()" class="flex flex-col justify-between items-center">
  @if (countUp == null && data().externalDataSourceUrl) {
    <div>
      <smallstack-loader></smallstack-loader>
    </div>
  }
  @if (countUp != null) {
    <div class="count-box">
      @if (enableCountUp) {
        <h1 class="content count-number" [countUp]="countUp" [options]="options"> 0 </h1>
      }
      @if (!enableCountUp) {
        <h1 class="content count-number">
          {{ countUp.toLocaleString("de-DE") }}
        </h1>
      }
      <h1 class="content count-string">
        {{ countString }}
      </h1>
    </div>
  }

  <p class="text-muted">
    {{ data()?.title }}
  </p>
</div>

@if (!data().externalDataSourceUrl && !data().title) {
  <div class="text-muted">
    <smallstack-i18n data="@@dashboard.widgets.externalcount.noconfig"></smallstack-i18n>
  </div>
}
