import { ContentObserver } from "@angular/cdk/observers";
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";
import Mark from "mark.js";
import { Subscription } from "rxjs";

let cancelAnimationId: number;

function animate({
  timing,
  draw,
  duration
}: {
  timing: (timeFraction: number) => number;
  draw: (progress: number) => void;
  duration: number;
}) {
  const start = performance.now();
  cancelAnimationId = requestAnimationFrame(function animate2(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) {
      timeFraction = 1;
    }
    // calculate the current animation state
    const progress = timing(timeFraction);
    draw(progress); // draw it
    if (timeFraction < 1) {
      cancelAnimationId = requestAnimationFrame(animate2);
    }
  });
}

@Directive({
  selector: "[highlight]",
  standalone: true
})
export class HighlightDirective implements OnInit, OnDestroy {
  @Input()
  public highlight: string | string[];
  @Input()
  public highlightConfig: Mark.MarkOptions = {
    caseSensitive: false,
    debug: false,
    separateWordSearch: true,
    exclude: [".no-highlight *"]
  };
  @Input()
  public scrollToFirstMarked: boolean = false;
  @Output()
  public readonly getInstance = new EventEmitter<Mark>();

  private markInstance: Mark;

  private subscription: Subscription = new Subscription();

  constructor(
    private contentElementRef: ElementRef,
    private renderer: Renderer2,
    private contentObserver: ContentObserver
  ) {}

  public ngOnInit(): void {
    if (!this.markInstance) {
      this.markInstance = new Mark(this.contentElementRef.nativeElement);
      this.getInstance.emit(this.markInstance);
    }
    this.hightlightText();
    if (this.scrollToFirstMarked) {
      this.scrollToFirstMarkedText();
    }
    this.subscription.add(
      this.contentObserver.observe(this.contentElementRef).subscribe((records: MutationRecord[]) => {
        if (records[0].type === "characterData") this.hightlightText();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public hightlightText(): void {
    if (this.highlight === undefined || (this.highlight instanceof Array && this.highlight.length === 0)) {
      this.markInstance.unmark();
      return;
    } else {
      this.markInstance.unmark({
        done: () => {
          this.markInstance.mark(this.highlight || "", this.highlightConfig);
        }
      });
    }
  }

  public scrollToFirstMarkedText(): void {
    const content = this.contentElementRef.nativeElement;
    const firstOffsetTop = (content.querySelector("mark") || {}).offsetTop || 0;
    this.scrollSmooth(content, firstOffsetTop);
  }

  public scrollSmooth(scrollElement: HTMLElement, firstOffsetTop: number): void {
    const renderer = this.renderer;

    if (cancelAnimationId) {
      cancelAnimationFrame(cancelAnimationId);
    }
    const currentScrollTop = scrollElement.scrollTop;
    const delta = firstOffsetTop - currentScrollTop;

    animate({
      duration: 500,
      timing(timeFraction: number) {
        return timeFraction;
      },
      draw(progress: number) {
        const nextStep = currentScrollTop + progress * delta;
        renderer.setProperty(scrollElement, "scrollTop", nextStep);
      }
    });
  }
}
