import { Injectable } from "@angular/core";
import { ErrorMessageHandler } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { isTranslatable } from "@smallstack/i18n-shared";
import { filterNullish, isNonEmptyString } from "@smallstack/utils";
import { Observable, firstValueFrom } from "rxjs";
import { TranslationStore } from "../stores/translation.store";
import { ConsoleNotifier } from "./notifiers/console.notifier";
import { DaisyUiSnackbarNotifier } from "./notifiers/daisy-ui-snackbar.notifier";
import { MaterialDialogNotifier } from "./notifiers/material-dialog.notifier";
import { Notifier } from "./notifiers/notifier";

@Injectable({ providedIn: "root" })
export class NotificationService {
  public console: Notifier;
  public popup: Notifier;
  public notification: Notifier;

  constructor(
    private errorMessageHandler: ErrorMessageHandler,
    daisyUiSnackbarNotifier: DaisyUiSnackbarNotifier,
    materialDialogNotifier: MaterialDialogNotifier,
    private translationStore: TranslationStore
  ) {
    this.console = new ConsoleNotifier();
    this.popup = materialDialogNotifier;
    this.notification = daisyUiSnackbarNotifier;
  }

  public getStandardCallback(errorMsg?: string, successMsg?: string): any {
    if (errorMsg === undefined) errorMsg = "Error";
    if (successMsg === undefined) successMsg = "Successful";

    return (error: Error, result: any) => {
      if (error !== undefined) void this.showStandardErrorPopup(error, errorMsg);
      else this.notification.success(successMsg + (result !== undefined ? ` [${result}]` : ""));
    };
  }

  public showStandardErrorPopup(error: Error, additionalText?: string, errorMsgContent?: string): void;
  public showStandardErrorPopup(error: Error, additionalText?: string): void;

  public showStandardErrorPopup(error: any, title?: string, content?: string): void {
    Logger.error("NotificationService", "An Error occurred: ", { title, error });
    if (!title) title = this.translationStore.translate("@@notifications.error.title");

    if (!content) {
      const handledErrorMessage: string = this.errorMessageHandler.handleMessage(error);
      let errorMessageString: string = this.translationStore.translate(handledErrorMessage, { showMissingKey: true });
      if (errorMessageString === undefined) errorMessageString = handledErrorMessage;
      this.popup.error(title, errorMessageString);
    } else this.popup.error(title, content);
  }

  public showErrorNotification(error: any, title?: string, content?: string): void {
    Logger.error("NotificationService", "An Error occurred: ", { title, error });
    if (!title) title = this.translationStore.translate("@@notifications.error.title");

    if (!content) {
      const handledErrorMessage: string = this.errorMessageHandler.handleMessage(error);
      let errorMessageString: string = this.translationStore.translate(handledErrorMessage, { showMissingKey: true });
      if (errorMessageString === undefined) errorMessageString = handledErrorMessage;
      this.notification.error(title, errorMessageString);
    } else this.notification.error(title, content);
  }

  public setConsoleNotifier(notifier: Notifier): void {
    this.console = notifier;
  }

  public setPopupNotifier(notifier: Notifier): void {
    this.popup = notifier;
  }

  public setNotificationNotifier(notifier: Notifier): void {
    this.notification = notifier;
  }

  public async handlePromise<T>(
    promise: Promise<T>,
    options: {
      errorMsg?: string;
      errorMsgContent?: string;
      successMsg?: string;
      rethrowException?: boolean;
    } = {}
  ): Promise<T> {
    try {
      const returnValue: T = await promise;
      let message: string;
      if (options.successMsg) message = options.successMsg;
      else message = this.translationStore.translate("@@notifications.success.title", { showMissingKey: true });
      this.notification.success(message);
      return returnValue;
    } catch (e) {
      let message: string;
      if (isNonEmptyString(options.errorMsg)) message = options.errorMsg;
      else message = this.translationStore.translate("@@notifications.error.title", { showMissingKey: true });
      message = options.errorMsg;
      if (e.isAxiosError && typeof e.response?.data?.text === "function") {
        const responseDataText = JSON.parse(await e.response.data.text());
        if (isTranslatable(responseDataText))
          this.showStandardErrorPopup(e, message, this.translationStore.translate(responseDataText));
        else this.showStandardErrorPopup(e, message, this.translationStore.translate(responseDataText?.message));
      } else this.showStandardErrorPopup(e, message, options.errorMsgContent);
      if (!options || options.rethrowException === undefined || options.rethrowException === true) throw e;
    }
  }

  public async handleObservable<T>(
    observable: Observable<T>,
    options: {
      errorMsg?: string;
      errorMsgContent?: string;
      successMsg?: string;
      rethrowException?: boolean;
    } = {}
  ): Promise<T> {
    return this.handlePromise(firstValueFrom(observable.pipe(filterNullish())), options);
  }
}
