import { Injectable } from "@angular/core";
import { getJsonByPath } from "@smallstack/utils";

export const EnvironmentKeys = {
  API_URL: "apiUrl",
  BACKOFFICE_URL: "backofficeUrl",
  VERSION: "version",
  FCM_PUBLIC_TOKEN: "fcmPublicToken",
  FIREBASE_CONFIG: "firebaseConfig"
};

/**
 * A helper service for environment variables like the Api Url
 */
@Injectable({ providedIn: "root" })
export class EnvironmentService {
  protected map: { [key: string]: string | number | boolean } = {};

  public get<T = string | number | boolean>(key: string): T {
    return getJsonByPath(this.map, key) as T;
  }

  public set(map: { [key: string]: string | number | boolean | any }): void {
    this.map = map;
  }

  public getMap(): any {
    return this.map;
  }
}
