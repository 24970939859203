import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { FileStore } from "../../stores/file.store";

@Component({
  selector: "smallstack-file-upload-button",
  templateUrl: "./file-upload-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, I18nComponent, CommonModule]
})
export class FileUploadButtonComponent {
  @ViewChild("fileInput") public fileInput: ElementRef;

  @Input()
  public fileStore: FileStore;

  constructor(private cdr: ChangeDetectorRef) {}

  public async change($event: any): Promise<void> {
    await this.fileStore.uploadFile($event.target.files[0]);

    // Need to call this here to reset the input value.
    // Otherwise the sequence upload - cancel - upload will not work since value did not change.
    this.fileInput.nativeElement.value = "";
    this.fileStore.sortBy("-id");
    await this.fileStore.load();
    this.cdr.markForCheck();
  }
}
