import { ChangeDetectionStrategy, Component } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { PageableStore } from "@smallstack/store";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  templateUrl: "./generic-list-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericListWidgetComponent extends BaseWidgetComponent {
  public store: PageableStore;
  public storeEntries: any;

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        store: {
          type: "string",
          title: "Store",
          description: "Aus welchem Store sollen die Daten geladen werden?",
          "x-schema-form": { widget: "types" }
        },
        sort: {
          type: "string",
          title: "Sortierung"
        },
        amount: {
          type: "number",
          title: "Wie viele Einträge sollen angezeigt werden?"
        },
        template: {
          title: "Eintrag Vorlage",
          description: "Bestimmen Sie, was pro Eintrag angezeigt werden soll.",
          type: "object",
          "x-schema-form": {
            widget: "cms"
          }
        }
      }
    };
  }

  constructor(private storeRegistry: StoreRegistry) {
    super();
    this.subscription.add(
      this.data$.subscribe((data) => {
        const searchOptions: any = {};

        // sort
        if (data.sort) searchOptions.sort = data.sort;

        // amount
        if (data.amount) searchOptions.size = data.amount;

        if (data.store) {
          this.store = this.storeRegistry
            .getStore<PageableStore>(this.data().store)
            .createSearch({ fieldSearches: [], logicalOperator: "and" }, searchOptions);
          this.subscription.add(
            this.store.value$.subscribe((values) => {
              if (values) {
                this.storeEntries = values;
                this.cdr.markForCheck();
              }
            })
          );
        }
      })
    );
  }
}
