import { Injectable, Injector, computed, effect, inject } from "@angular/core";
import {
  BadgeName,
  PushNotificationService,
  PwaBadgeService,
  convertMapToQueryString
} from "@smallstack/client-common";
import { ContextService, EnvironmentKeys, EnvironmentService } from "@smallstack/common-components";
import { MessageThread, SendThreadMessage, TYPE_MESSAGE_THREADS } from "@smallstack/typesystem";
import { RxEntityStore, createAxiosHeaders, isReadBy } from "@smallstack/typesystem-client";
import axios from "axios";
import { UserService } from "../services/user.service";
import { longPollingFallback } from "./long-polling-fallback";
import { MyMessagesStore } from "./my-messages.store";

@Injectable()
export class MyMessageThreadsStore extends RxEntityStore<MessageThread> {
  private contextService = inject(ContextService);
  private userService = inject(UserService);
  private environmentService = inject(EnvironmentService);
  private messagesStore = inject(MyMessagesStore);
  private pushNotificationService = inject(PushNotificationService);
  private pwaBadgeService = inject(PwaBadgeService);

  public unreadThreadIds = computed<string[]>(() => {
    return this.getMany()
      ?.filter((thread) => {
        const threadMessages = this.messagesStore.messagesForThread(thread.id);
        for (const msg of threadMessages) {
          if (!isReadBy(msg, this.userService.currentUser().user.id)) {
            return true;
          }
        }
        return false;
      })
      ?.map((thread) => thread.id);
  });

  public unreadThreadsCount = computed(() => {
    return this.unreadThreadIds()?.length;
  });

  public hasUnreadSystemMessages = computed(() => {
    const systemThreads = this.getMany()?.filter((thread) => thread.isSystemThread === true);
    return (
      systemThreads.find((thread) => {
        const threadMessages = this.messagesStore.messagesForThread(thread.id);
        if (threadMessages.length === 0) return false;
        const unreadThreadMessages = threadMessages.filter(
          (msg) => !isReadBy(msg, this.userService.currentUser().user.id)
        );
        return unreadThreadMessages.length > 0;
      }) !== undefined
    );
  });

  constructor(injector: Injector) {
    super(injector, { typeDescriptor: { typePath: TYPE_MESSAGE_THREADS } });

    // listen to push notifications
    effect(async () => {
      const latestPushNotification = this.pushNotificationService.latestPushNotification();
      console.log(
        "Received push notification in message thread store: ",
        latestPushNotification,
        "refreshing message threads"
      );
      await this.sync();
    });

    // do long polling if push notifications are disabled
    longPollingFallback(this, this.pushNotificationService);

    // set badge count
    effect(
      async () => {
        this.pwaBadgeService.getBadgeMutator(BadgeName.UnreadMessageThreads).setBadge(this.unreadThreadsCount());
      },
      { allowSignalWrites: true }
    );
  }

  public async getOrCreate1on1ThreadWith(userId: string): Promise<MessageThread> {
    const thread = await axios
      .post(
        this.environmentService.get(EnvironmentKeys.API_URL) + "/" + TYPE_MESSAGE_THREADS + "/1o1/" + userId,
        undefined,
        { headers: createAxiosHeaders(this.contextService.context()) }
      )
      .then((response) => response.data);
    await this.sync();
    return thread;
  }

  public async getOrCreateSystemThread(options?: { withId?: string; tenantId?: string }): Promise<MessageThread> {
    let url = this.environmentService.get(EnvironmentKeys.API_URL) + "/" + TYPE_MESSAGE_THREADS + "/system";
    if (options?.withId || options?.tenantId) url += "?" + convertMapToQueryString(options);
    await this.sync();
    return (await axios.post(url, undefined, { headers: createAxiosHeaders(this.contextService.context()) })).data;
  }

  public async markAsRead(threadId: string): Promise<void> {
    if (!threadId) throw new Error("threadId is required");
    await axios.put(
      this.environmentService.get(EnvironmentKeys.API_URL) +
        "/" +
        TYPE_MESSAGE_THREADS +
        "/" +
        threadId +
        "/markAsRead",
      undefined,
      { headers: createAxiosHeaders(this.contextService.context()) }
    );
    await this.messagesStore.sync();
  }

  public async sendThreadMessage(text: string, toThreadId: string): Promise<void> {
    await axios.post(
      this.environmentService.get(EnvironmentKeys.API_URL) +
        "/" +
        TYPE_MESSAGE_THREADS +
        "/" +
        toThreadId +
        "/messages",
      {
        text
      } as SendThreadMessage,
      { headers: createAxiosHeaders(this.contextService.context()) }
    );
    await this.sync();
  }
}
