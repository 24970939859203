import { Pipe, PipeTransform } from "@angular/core";

const bounds = [
  {
    lower: 0,
    label: "B"
  },
  {
    lower: 3,
    label: "KB"
  },
  {
    lower: 6,
    label: "MB"
  },
  {
    lower: 9,
    label: "GB"
  },
  {
    lower: 12,
    label: "TB"
  }
];

@Pipe({
  name: "fileSize",
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  public transform(value: number): any {
    if (value) {
      const digits = count(value);
      const tooMuch = bounds.findIndex((b) => b.lower >= digits);
      const unit = tooMuch === -1 ? bounds[bounds.length - 1] : tooMuch !== 0 ? bounds[tooMuch - 1] : bounds[0];
      const scaled = value / Math.pow(10, unit.lower);
      return `${Number.isInteger(scaled) ? scaled : scaled.toFixed(2)} ${unit.label}`;
    } else return "-";
  }
}

function count(num: number) {
  // tslint:disable-next-line:no-bitwise
  return (Math.log(num) * Math.LOG10E + 1) | 0;
}
