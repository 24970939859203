import { Pipe, PipeTransform } from "@angular/core";
import { DataType } from "@smallstack/typesystem";
import { TypeService } from "../services/type.service";

/** Returns the type for the given typePath */
@Pipe({ name: "smallstackType", standalone: true })
export class TypePipe implements PipeTransform {
  constructor(private typeService: TypeService) {}

  public transform(typePath: string): Promise<DataType> {
    return this.typeService.getTypeByPath(typePath);
  }
}
