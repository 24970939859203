import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
  input
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { I18nComponent } from "@smallstack/i18n-components";
import { RxEntityStoreService, TypeService } from "@smallstack/typesystem-client";
import { getJsonByPath } from "@smallstack/utils";
import { computedAsync } from "ngxtension/computed-async";

/**
 * Displays a select of reactive data.
 * Usage:
 *  ```
 *  <smallstack-rx-property type="customers" [modelId]="customerId" property="displayName"></smallstack-rx-property>
 *  ```
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-rx-select",
  templateUrl: "./rx-select.component.html",
  styleUrls: ["./rx-select.component.scss"],
  standalone: true,
  imports: [CommonModule, I18nComponent, FormsModule]
})
export class RxSelectComponent {
  private rxEntityStoreService = inject(RxEntityStoreService);

  public type = input.required<string>();
  public displayProperty = input.required<string>();
  public valueProperty = input.required<string>();

  @Input()
  public notFound: string;

  @Input()
  public label: string;

  @Input()
  public value: any;

  @Output()
  public readonly valueChange: EventEmitter<any> = new EventEmitter();

  public models = computedAsync(async () => {
    const type = this.type();
    if (!type) return;
    const models = this.rxEntityStoreService.forType(this.type()).getMany();
    return models?.map((model) => ({
      label: getJsonByPath(model, this.displayProperty()),
      value: getJsonByPath(model, this.valueProperty())
    }));
  });

  public notFoundMsg: string;
  @ContentChild(TemplateRef)
  public templateModel: TemplateRef<any>;

  constructor(private typeService: TypeService) {}

  @Input("notFound")
  public notFoundText: string;

  @Input()
  public showNotFound = true;

  @ContentChild(TemplateRef)
  public notFoundTemplate: TemplateRef<any>;
}
