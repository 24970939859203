import { ChangeDetectionStrategy, Component, Optional } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ApplicationBridge } from "@smallstack/application-shared";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "Iframe",
  templateName: "Iframe",
  icon: "desktop",
  templateDescription: "Zeigt eine externe Webseite an.",
  dataSchema: async () => {
    return IFrameWidgetComponent.getConfiguration();
  },
  tags: AllWidgetTags
})
@Component({
  templateUrl: "./iframe-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IFrameWidgetComponent extends BaseWidgetComponent {
  constructor(
    private sanitizer: DomSanitizer,
    @Optional() public applicationBridge: ApplicationBridge
  ) {
    super();
  }

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        url: {
          type: "string",
          title: "Website url",
          default: "https://smallstack.com"
        },
        width: {
          type: "string",
          title: "Breite",
          default: "100%",
          description: "px, pt, %, em, rem, vw"
        },
        height: {
          type: "string",
          title: "Höhe",
          default: "500px",
          description: "px, pt, %, em, rem, vh"
        }
      }
    };
  }

  public getUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data().url);
  }
}
