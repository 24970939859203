import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ResourcePlanningUserTableWidget: WidgetRegistryEntry = {
  name: "ResourcePlanningUserTable",
  configuration: {
    templateName: "Ressourcen Planung Benutzer Tabelle",
    templateDescription:
      "Dieses Widget zeigt eine Tabelle an mit allen geplanten Aufgaben für den angegebenen Benutzer.",
    icon: "table-1",
    sockets: [{ direction: SocketDtoDirectionEnum.In, name: "periodOfTime", type: SocketDtoTypeEnum.Object }],
    dataSchema: {
      type: "object",
      properties: {
        userContextVariable: {
          type: "string",
          title: "Benutzer Variable im Kontext"
        }
      }
    }
  },
  component: () => import("./resource-planning-user-table.component").then((m) => m.ResourcePlanningUserTableComponent)
};
