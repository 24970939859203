@if (isLoading) {
  <smallstack-loader loadingText="Aufgaben werden geladen"></smallstack-loader>
}

@if (editingEnabled) {
  <div style="padding: 4px" class="flex flex-row space-x-3">
    <mat-form-field class="flex-auto">
      <mat-label>Neue Aufgabe</mat-label>
      <input matInput [(ngModel)]="newTodoLabel" (keydown.enter)="progressBarBtn.click()" />
    </mat-form-field>
    <button
      [loadingFn]="createTodo()"
      style="height: 60px"
      #progressBarBtn="loadingFn"
      class="basis-full btn btn-outline"
    >
      <smallstack-icon name="plus-2-math" size="32"></smallstack-icon>
    </button>
  </div>
}
@if (!isLoading && todos?.length === 0) {
  <div class="text-muted pb-4 text-center">
    <smallstack-icon size="64" name="empty-box"> </smallstack-icon><br />
    Keine Aufgaben gefunden
  </div>
} @else {
  @if (todos | isArray) {
    @for (todo of todos; track todo; let i = $index) {
      <div class="todo-entry">
        <div class="flex flex-row items-start gap-2">
          <div class="w-6 pt-1">
            @if (todo.done !== true) {
              <smallstack-icon size="24" name="unchecked-checkbox--v1" [loadingFn]="toggleTodo(todo)">
              </smallstack-icon>
            }
            @if (todo.done === true) {
              <smallstack-icon size="24" name="checked-checkbox" [loadingFn]="toggleTodo(todo)"> </smallstack-icon>
            }
          </div>
          <div class="flex flex-col items-start flex-auto">
            @if (currentEditIndex !== i) {
              <smallstack-i18n
                [ngStyle]="{ 'text-decoration': todo.done ? 'line-through' : '' }"
                [data]="todo.label || 'Kein Text'"
                (click)="editingEnabled ? (currentEditIndex = i) : undefined"
                [ngStyle]="{ cursor: editingEnabled ? 'pointer' : 'unset' }"
                class="pt-2"
              ></smallstack-i18n>
            }
            @if (currentEditIndex === i) {
              <div class="flex flex-col flex-auto w-full">
                <smallstack-inline-translation-editor [(translation)]="todo.label" class="flex-auto">
                </smallstack-inline-translation-editor>
                <div class="flex flex-row">
                  <button [loadingFn]="deleteTodo(i)" class="basis-full btn btn-outline">
                    <smallstack-icon name="delete" size="24"></smallstack-icon>
                  </button>
                  <button [loadingFn]="saveTodo(todo)" class="basis-full btn btn-outline">
                    <smallstack-icon name="save" size="24"></smallstack-icon>
                  </button>
                </div>
              </div>
            }
            @if (showTenant) {
              <div>{{ todo.context.tenantId }}</div>
            }
          </div>
        </div>
      </div>
    }
  }
}
