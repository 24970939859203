@if (widgetTreeService.editMode()) {
  <div class="edit-container flex flex-row justify-end items-center">
    <span>{{ compoundWidget()?.name }}</span>
    <smallstack-icon
      actionsTemplate
      [name]="compoundWidgetEditMode() ? 'unlock' : 'lock'"
      size="20"
      [loadingFn]="toggleEditMode()"
      matTooltip="Eigenes Widget bearbeiten"
    ></smallstack-icon>
  </div>
}

@if (data()?.compoundWidgetId) {
  <smallstack-widget-tree
    #widgetTree
    [widget]="compoundWidget()?.widget"
    (widgetChange)="updateCompoundWidget($event)"
    [widgetRendererOptions]="widgetRendererOptions()"
    [context]="context()"
    [connections]="compoundWidget()?.socketConnections"
    (connectionsChange)="updateConnections($event)"
    [rootSockets]="compoundWidget()?.rootSockets"
  >
  </smallstack-widget-tree>
} @else {
  <span>Bitte konfigurieren Sie das Widget!</span>
}
