import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ErrorHandler,
  ViewChild
} from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import {
  InlineTranslationEditorComponent,
  LocaleService,
  LocaleStore,
  NotificationService,
  currentEditorLocale$
} from "@smallstack/i18n-components";
import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import { MarkdownEditorService } from "../../services/markdown-editor.service";

@Component({
  selector: "smallstack-markdown-editor",
  templateUrl: "./markdown-editor.component.html",
  styleUrls: ["./markdown-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownEditorComponent extends InlineTranslationEditorComponent implements AfterViewInit {
  private markdownEditor: any;
  public selectedLocaleTab: number = 0;

  @ViewChild("markdownEditor")
  public markdownEditorRef: ElementRef;

  constructor(
    localeStore: LocaleStore,
    protected markdownEditorService: MarkdownEditorService,
    notificationService: NotificationService,
    cdr: ChangeDetectorRef,
    localeService: LocaleService,
    private errorHandler: ErrorHandler
  ) {
    super(localeStore, localeService, cdr, notificationService);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.subscription.add(
      combineLatest([this.localeStore.value$, currentEditorLocale$.value$])
        .pipe(
          tap(() => {
            const foundIndex = this.locales?.findIndex((l) => l === this.currentEditorLocale);
            if (foundIndex >= 0) this.selectedLocaleTab = foundIndex;
            else this.selectedLocaleTab = 0;
            if (this.markdownEditor?.codemirror) this.updateCodeMirrorValue();
          })
        )
        .subscribe()
    );

    this.markdownEditor = await this.markdownEditorService.getMarkdownEditor(this.markdownEditorRef.nativeElement, {
      extraToolbarButtons: [
        {
          action: () => {
            void this.toggleTranslationState();
          },
          className: "fa fa-globe",
          name: "changeTranslation",
          title: "Übersetzung an- bzw. ausschalten"
        }
      ]
    });
    this.markdownEditor.codemirror.on("change", () => {
      const value: string = this.markdownEditor.codemirror.getValue();
      this.valueChanged(value);
    });

    this.updateCodeMirrorValue();
  }

  public refresh(): void {
    this.markdownEditor.codemirror.refresh();
  }

  public selectedTabChange(selectedTabChange: MatTabChangeEvent) {
    this.currentEditorLocale = this.locales[selectedTabChange.index];
    currentEditorLocale$.setValue(this.currentEditorLocale);
    if (this.markdownEditor.isPreviewActive()) void this.markdownEditorService.togglePreview(this.markdownEditor);
    this.updateCodeMirrorValue();
  }

  private updateCodeMirrorValue() {
    if (this.translation instanceof Array) {
      if (this.translation[this.currentLocaleIndex]?.value !== undefined)
        this.markdownEditor.codemirror.setValue(this.translation[this.currentLocaleIndex].value);
      else this.markdownEditor.codemirror.setValue("");
    } else {
      if (this.translation !== undefined && typeof this.translation === "string")
        this.markdownEditor.codemirror.setValue(this.translation);
      else
        try {
          this.markdownEditor.codemirror.setValue(this.translation);
        } catch (error) {
          this.errorHandler.handleError(error);
          this.markdownEditor.codemirror.setValue("");
        }
    }
    this.cdr.markForCheck();
  }
}
