import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { T_TODOS } from "@smallstack/typesystem";

export interface TodoListDialogData {
  /** Show todo items based on a link search */
  link?: LinkedModelDto;
  headerText?: string;
  explanation?: string;
}

@Component({
  templateUrl: "./todo-list-dialog.component.html",
  styleUrls: ["./todo-list-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoListDialogComponent {
  public readonly T_TODOS = T_TODOS;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TodoListDialogData) {}
}
