<smallstack-beta-border>
  @if (isLoading$ | async) {
    <smallstack-loader loadingText="Filter werden geladen"></smallstack-loader>
  } @else {
    <smallstack-form
      [schema]="searchSchema"
      [(value)]="filterValue"
      [options]="{ compactMode: true }"
      (valueChange)="filterOnType$.next($event)"
    >
      <smallstack-form-custom-layout>
        <div class="filter-bar-grid">
          @for (searchableField of type?.searchableFields; track searchableField) {
            <ng-container [schemaFormPlaceholder]="searchableField.name"></ng-container>
          }
        </div>
      </smallstack-form-custom-layout>
    </smallstack-form>
    <div style="padding: 4px 0px" class="flex flex-row space-x-3 justify-end">
      <button (click)="reset()" class="btn btn-outline">Zurücksetzen</button>
      <button [loadingFn]="filter()" class="btn btn-primary">Filtern</button>
    </div>
  }
</smallstack-beta-border>
