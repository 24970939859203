import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { isNumberString } from "@smallstack/utils";
import { LocaleService } from "./locale.service";

@Injectable({ providedIn: "root" })
export class TextManipulationService {
  constructor(private localeService: LocaleService) {}

  /** Replaces numbers with properly ./, aligned numbers */
  public replaceNumberGroupings(str: string, format: string = "1.2-2"): string {
    if (typeof str !== "string") return str;
    return str.replace(/([0-9,.]+)/g, (str) => {
      const withDots = str.replace(/,/, ".");
      if (isNumberString(withDots))
        return new DecimalPipe(this.localeService.currentLocale$.value || "de_de").transform(withDots, format);
      else return str;
    });
  }
}
