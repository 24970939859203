import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HumanizeDurationOptions, humanizeDuration } from "@smallstack/utils";

@Component({
  selector: "smallstack-humanized-time",
  templateUrl: "./humanized-time.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class HumanizedTimeComponent {
  protected durationString: string;

  #start: number;
  #end: number;
  #options: HumanizeDurationOptions;

  @Input()
  public set start(start: string | number) {
    this.#start = parseInt("" + start);
    this.evalDuration();
  }

  @Input()
  public set end(end: string | number) {
    this.#end = parseInt("" + end);
    this.evalDuration();
  }

  @Input()
  public set duration(duration: number) {
    this.#start = 0;
    this.#end = duration;
    this.evalDuration();
  }

  @Input()
  public set options(options: HumanizeDurationOptions) {
    this.#options = options;
    this.evalDuration();
  }

  public evalDuration(): void {
    this.durationString = humanizeDuration(this.#end - this.#start, this.#options);
  }
}
