import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  inject,
  input
} from "@angular/core";
import { AddressDto, TodoDto, TodoEntryDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import {
  DateComponent,
  DialogPopupButtons,
  I18nComponent,
  InlineTranslationEditorComponent,
  NotificationService
} from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { UserService } from "@smallstack/user-components";
import { cloneObject, getGoogleMapsNavigationUrl } from "@smallstack/utils";
import { TypeModelComponent } from "@smallstack/widget-core";
import { MyWorklogStore } from "../../stores/my-worklog.store";

@Component({
  selector: "smallstack-todo-list-entry",
  templateUrl: "./todo-list-entry.component.html",
  styleUrls: ["./todo-list-entry.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TypeModelComponent,
    TodoListEntryComponent,
    IconComponent,
    I18nComponent,
    LoadingElementDirective,
    DateComponent,
    InlineTranslationEditorComponent
  ]
})
export class TodoListEntryComponent {
  private myWorklogStore = inject(MyWorklogStore);

  public todo = input.required<TodoDto>();

  @Input()
  protected editable: boolean = false;

  @Output()
  protected readonly updateFn: EventEmitter<TodoDto> = new EventEmitter<TodoDto>();

  @Input()
  protected showDueDate: boolean = true;

  @Input()
  protected showChecklistEntries: boolean = true;

  @Input()
  protected isRoot = true;

  protected hasStartedWorklog = computed(
    () =>
      this.myWorklogStore.queryWorklogs({
        selector: { todoRefId: this.todo()?.id, startTime: { $exists: true }, endTime: { $exists: false } }
      })?.length > 0
  );

  protected currentlyEditing: boolean = false;

  constructor(
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  public toggleTodo() {
    return async (): Promise<void> => {
      if (!this.updateFn) return;
      if (this.todo().done === true) await this.setToUnDone();
      else await this.setToDone();
    };
  }

  public saveSubEntry(index: number, todo: TodoEntryDto): void {
    const currentTodo = cloneObject(this.todo());
    currentTodo.checklistEntries[index] = todo;
    this.updateFn.emit(currentTodo);
  }

  public openGoogleMaps(address: AddressDto): void {
    window.open(getGoogleMapsNavigationUrl(address), "_blank");
  }

  protected createOrStopWorklog() {
    return async (): Promise<void> => {
      if (this.hasStartedWorklog()) await this.myWorklogStore.stopWorklogsForTodo(this.todo().id);
      else {
        await this.myWorklogStore.stopAllWorklogs();
        const worklog = await this.myWorklogStore.createWorklogFromTodo(this.todo());
        await this.myWorklogStore.createWorklog(worklog);
      }
    };
  }

  private async setToDone(): Promise<void> {
    // check if timer is running
    if (this.hasStartedWorklog()) {
      const stopRunningWorklog = await this.notificationService.popup.confirmation(
        "Protokolle beenden",
        "Sie können die Aufgabe erst abschließen, wenn alle laufenden Arbeitsprotokolle gestoppt sind. Möchten Sie die laufenden Arbeitsprotokolle nun anhalten?",
        DialogPopupButtons.yesNo
      );
      if (stopRunningWorklog === true) {
        await this.myWorklogStore.stopWorklogsForTodo(this.todo().id);
      } else return;
    }

    // check if sub todos are done as well
    // if (
    //   this.todo.checklistEntries?.length > 0 &&
    //   this.todo.checklistEntries.find((ce) => ce.done !== true) !== undefined
    // ) {
    //   const setChecklistEntriesDone = await this.notificationService.popup.confirmation(
    //     "Unteraufgaben",
    //     "Die Aufgabe kann erst abgeschlossen werden, nachdem alle Unteraufgaben erledigt wurden. Sollen alle Unteraufgaben automatisch als erledigt markiert werden?",
    //     DialogPopupButtons.yesNo
    //   );
    //   if (setChecklistEntriesDone) {
    //     this.todo.checklistEntries.forEach((ce) => (ce.done = true));
    //     this.todo.checklistEntries = cloneObject(this.todo.checklistEntries);
    //     this.todo.done = true;
    //   }
    // } else {
    //   this.todo.done = true;
    // }
    const currentTodo = cloneObject(this.todo());
    currentTodo.done = true;
    this.updateFn.emit(currentTodo);
  }

  private async setToUnDone(): Promise<void> {
    const currentTodo = cloneObject(this.todo());
    if (
      currentTodo.checklistEntries?.length > 0 &&
      currentTodo.checklistEntries.find((ce) => ce.done === true) !== undefined
    ) {
      const setChecklistEntriesUnDone = await this.notificationService.popup.confirmation(
        "Unteraufgaben",
        "Sollen alle Unteraufgaben ebenfalls als unerledigt markiert werden?",
        DialogPopupButtons.yesNo
      );
      if (setChecklistEntriesUnDone) {
        currentTodo.checklistEntries.forEach((ce) => (ce.done = false));
        currentTodo.checklistEntries = cloneObject(currentTodo.checklistEntries);
      }
    }
    currentTodo.done = false;
    this.updateFn.emit(currentTodo);
  }
}
