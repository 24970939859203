import { TypeSchema } from "../typesystem/type-schema";

export interface DataTypePermission {
  get?: string;
  getMany?: string;
  put?: string;
  patch?: string;
  post?: string;
  del?: string;
}

// export all properties of DataTypePermission
export type DataTypePermissionName = keyof DataTypePermission;

export const DataTypePermissionSchema: TypeSchema = {
  type: "object",
  properties: {
    get: { type: "string" },
    getMany: { type: "string" },
    put: { type: "string" },
    patch: { type: "string" },
    post: { type: "string" },
    del: { type: "string" }
  }
};
