import { ApiQueryRequest } from "@smallstack/api-shared";
import * as Base64 from "base-64";
import { MangoQuery, MangoQuerySelector, MangoQuerySortPart } from "rxdb";
import * as utf8 from "utf8";

export enum SearchByFieldMatcher {
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
  EXACT_MATCH = "exactMatch",
  INCLUDES = "includes",
  NOT_INCLUDES = "notIncludes",
  EXISTS = "exists",
  /**
   * Can be used to make direct comparisons, also e.g. search in arrays:  {tags: ["tag", "nacht"]} --> fieldname: "tag", matcher: "equals", value: "tag"
   */
  EQUALS = "equals",
  /**
   * Can be used to make direct negative comparisons
   */
  NOT_EQUALS = "notEquals",
  /**
   * Can be used to match against an array of values: {tag: "hello"} --> fieldname: "tag", matcher: "in", value: ["tag", "nacht"]
   */
  IN = "in",
  GREATER_THAN = "greaterThan",
  GREATER_THAN_EQUALS = "greaterThanEquals",
  LESS_THAN = "lessThan",
  LESS_THAN_EQUALS = "lessThanEquals",
  /**
   * Can be used to compare relative dates. The given property must be lessThanEquals to Date.now() + VALUE
   */
  BEFORE_RELATIVE_TIME = "beforeRelativeTime",
  /**
   * Can be used to compare relative dates. The given property must be greaterThanEquals to Date.now() + VALUE
   */
  AFTER_RELATIVE_TIME = "afterRelativeTime",
  /** Can be used to select entities by a property that is a date and in the given relative month (0 is current month, -1 is last month, +1 is next month) */
  IN_RELATIVE_MONTH = "inRelativeMonth"
}

export type SearchByFieldValue = string | string[] | number | number[] | boolean | object;

export enum FilterDataType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
  ID = "id",
  FOREIGN_ID = "foreignId"
}

/** Replacement for FilterName + FilterValues, defines, what a store search will show as options when in advanced mode */
export interface StoreSearchFilter {
  /** The name of the property (e.g. for user.displayName it would be 'displayName') */
  property: string;

  /** Either a string or a translation key which will be shown above the field */
  label: string;

  /** If true, the user will also be allowed to enter their own values */
  allowCustomValues?: boolean;

  /** If omitted, string will be used. Defines, besides other things, which comparators will be used */
  type?: FilterDataType;

  /** manually override matchers */
  matchers?: SearchByFieldMatcher[];

  /** Predefined values, will be shown as drop down */
  values?: {
    label?: string;
    value: unknown;
  }[];
  // storeValues?: {
  //   storeName: string;
  //   storeDisplayProperty: string;
  // };
}

export interface SearchByField {
  fieldname: string;
  value: SearchByFieldValue;
  caseSensitive?: boolean;
  matcher?: SearchByFieldMatcher;
}

export type LogicalOperator = "or" | "and";

export interface SearchQuery {
  logicalOperator: LogicalOperator;
  fieldSearches: SearchByField[];
}

/**
 * @deprecated Please use RxDB & MangoQuery
 * Search Query Builder
 */
export class SQBuilder {
  public static asSearchQuery(fields: SearchByField[], logicalOperator: LogicalOperator = "or"): SearchQuery {
    return {
      fieldSearches: fields,
      logicalOperator
    };
  }

  public static asString(fields: SearchByField[], logicalOperator: LogicalOperator = "or"): string {
    return SQBuilder.toBase64String(SQBuilder.asSearchQuery(fields, logicalOperator));
  }

  public static toBase64String(searchQuery: SearchQuery): string {
    if (!searchQuery) return undefined;
    return Base64.encode(utf8.encode(JSON.stringify(searchQuery)));
  }

  public static fromBase64String(searchQueryString: string): SearchQuery {
    return JSON.parse(utf8.decode(Base64.decode(searchQueryString)));
  }
}

/** Note: converts all matchers to "equals". You should re-write your query as MangoQuery directly if you can! */
export function convertSearchQueryToSelector<Model>(searchQuery: SearchQuery | string): MangoQuerySelector<Model> {
  if (typeof searchQuery === "string") searchQuery = SQBuilder.fromBase64String(searchQuery);
  if (!searchQuery || !Array.isArray(searchQuery.fieldSearches)) return undefined;
  const selector: MangoQuerySelector<Model> = {};
  if (searchQuery.logicalOperator === "or")
    selector.$or = searchQuery.fieldSearches.map(
      (fieldSearch) => ({ [fieldSearch.fieldname]: fieldSearch.value }) as any
    );
  else
    selector.$and = searchQuery.fieldSearches.map(
      (fieldSearch) => ({ [fieldSearch.fieldname]: fieldSearch.value }) as any
    );
  return selector;
}

export function convertApiQueryToMangoQuery<Model>(query: ApiQueryRequest): MangoQuery<Model> {
  const limit = query.size || undefined;
  const skip = query.page ? (query.page - 1) * limit : undefined;
  const sortName = query.sort && query.sort.replace("-", "");
  const sortDirection = query.sort && query.sort.startsWith("-") ? "desc" : "asc";
  const sort: MangoQuerySortPart<Model>[] = query.sort
    ? [{ [sortName]: sortDirection } as MangoQuerySortPart]
    : undefined;
  return {
    limit,
    skip,
    sort,
    selector: query.search ? convertSearchQueryToSelector(SQBuilder.fromBase64String(query.search)) : undefined
  };
}
