import { Directive, HostListener, OnInit } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import { Logger } from "@smallstack/core-common";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { upperCaseFirst } from "@smallstack/utils";
import { SchemaFormBase } from "./schema-form-base.component";

@Directive()
export class SchemaFormBaseWidget<T = any> extends SchemaFormBase<T> implements OnInit {
  public label: string | InlineTranslation;
  public i18nPrefix?: string;
  public placeholder?: string;
  public get required(): boolean {
    return this.formService.isRequired(this.path());
  }

  public get errorStateMatcher(): ErrorStateMatcher {
    const hasErrors = this.hasValidationErrors();
    class InternalErrorStateMatcher implements ErrorStateMatcher {
      public isErrorState(): boolean {
        return hasErrors;
      }
    }
    return new InternalErrorStateMatcher();
  }

  @HostListener("click", ["$event"])
  public async onClick(e: MouseEvent): Promise<void> {
    if (e.shiftKey) {
      e.stopImmediatePropagation();
      e.preventDefault();
      Logger.info("SchemaFormBaseWidget", "================ SchemaFormBaseWidget DEBUG ================");
      Logger.info("SchemaFormBaseWidget", " |-- Path:    " + this.path());
      Logger.info("SchemaFormBaseWidget", " |-- Schema:  " + JSON.stringify(this.schema()));
      Logger.info("SchemaFormBaseWidget", " |-- Value:   " + JSON.stringify(this.value()));
      Logger.info("SchemaFormBaseWidget", " |-- Options: " + JSON.stringify(this.options()));
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.schema$.subscribe((schema) => {
        if (schema) {
          // i18nPrefix
          this.i18nPrefix = schema["x-i18n-prefix"] || schema["x-schema-form"]?.i18nPrefix;
          // label
          if (schema.title) {
            if (this.formService.translationStore) {
              this.label = this.formService.translationStore.translate(schema.title);
              this.cdr.markForCheck();
            } else this.label = schema.title;
          } else if (schema["x-schema-form"]?.i18nTitle) {
            this.label = schema["x-schema-form"]?.i18nTitle;
            this.cdr.markForCheck();
          } else if (this.path()) this.label = upperCaseFirst(this.path());

          // placeholder
          if (schema["x-schema-form"]?.placeholder) this.placeholder = schema["x-schema-form"]?.placeholder;
          else this.placeholder = "";
          this.cdr.markForCheck();
        }
      })
    );
  }
}
