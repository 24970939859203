/**
 * @deprecated use "cloneObject" from @smallstack/utils
 * Deep-copy any object or array
 */
export function clone<T = any>(obj: T): T {
  if (obj !== undefined && obj !== null) return JSON.parse(JSON.stringify(obj));
  return obj;
}

/**
 * False for undefined, null, [undefined], [null], []
 */
export function isTruthyValue(
  prop: string | number | boolean | string[] | number[] | boolean[] | null | undefined
): boolean {
  if (prop instanceof Array) {
    if (prop.length === 0) return false;
    if (!prop.some((val) => isTruthyValue(val))) return false;
  }
  if (prop === "") return false;
  return prop !== undefined && prop !== null;
}

/** returns a random integer */
export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}
