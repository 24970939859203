import { BooleanEquationOperator } from "@smallstack/utils";
import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import { SearchByFieldMatcher } from "../additionals/search-query";
import { InlineTranslation } from "../models/inner-translation";
import { TypeDescriptorSchema } from "../models/type-descriptor";

export interface XSchemaFormHidingRule {
  action: "hide";
  if: {
    /** absolute path starting from root, e.g. "address.street" or relative path starting with a dot, like "./renderer"
     *
     * Example:
     * If your editing an address and your applying rules for the street field, you can use the relative path "../city" to check if the city field has a certain value.
     *
     *
     */
    dataPath: string;
    /** value like "Mies-van-der-Rohe Str" */
    value: any;
    operator: BooleanEquationOperator;
  };
}

/**
 * Can be used to change fields in the current schema. x-schema-form.rules will be ignored though.
 */
export interface XSchemaFormExtendSchemaRule {
  action: "extendSchema";
  schema: TypeSchema;
  if: {
    /** absolute path starting from root, e.g. "address.street" or relative path starting with a dot, like "./renderer"
     *
     * Example:
     * If your editing an address and your applying rules for the street field, you can use the relative path "../city" to check if the city field has a certain value.
     *
     *
     */
    dataPath: string;
    /** value like "Mies-van-der-Rohe Str" */
    value: any;
    operator: BooleanEquationOperator;
  };
}

export type XSchemaFormRule = XSchemaFormHidingRule | XSchemaFormExtendSchemaRule;

export interface XSchemaFormGroupOptions {
  title?: InlineTranslation;
  compact?: boolean;
}

export interface DuplicateDetection {
  /**
   * @deprecated use `type` instead
   *
   * Name of the store
   */
  store?: string;
  type: string;
  /** Name of the property */
  property: "address";
  /** the name of the extension slot which will be used to display possible results */
  extensionSlotName: string;
  /** Comparator for comparing values, defaults to {@link SearchByFieldMatcher.INCLUDES} */
  comparator?: SearchByFieldMatcher;
  /** Label which will be shown while duplicates are being searched */
  searchingLabel?: string;
  /** Label which will be shown when duplicates were found */
  foundLabel?: string;
  /** Define actions that users can perform when they click on a duplicate */
  clickActions?: {
    fill?: boolean;
    openLink?: string;
  };
}

export enum CellRendererDefinitionParamsMode {
  /** Will replace all ${VAR} with the model as context */
  text = "text",
  /** Will ask the translation store for a translation of the value */
  i18n = "i18n",
  /** Will render the value as tiny squared image */
  image = "image",
  objectReference = "objectReference"
}

export interface TextCellRendererDefinition {
  renderMode: CellRendererDefinitionParamsMode.text;
  options: {
    text: string;
  };
}

export interface ObjectReferenceCellRendererDefinition {
  renderMode: CellRendererDefinitionParamsMode.objectReference;
}

export interface I18nCellRendererDefinition {
  renderMode: CellRendererDefinitionParamsMode.i18n;
  options?: {
    locale?: string;
  };
}

export interface ImageCellRendererDefinition {
  renderMode: CellRendererDefinitionParamsMode.image;
  options: {
    /** if not set, the value itself will be taken */
    imageSrc?: string;
  };
}

export type CellRendererDefinition =
  | TextCellRendererDefinition
  | I18nCellRendererDefinition
  | ImageCellRendererDefinition
  | ObjectReferenceCellRendererDefinition;

export const CellRendererDefinitionSchema: TypeSchema = {
  title: "Zellen Renderer",
  type: "object",
  properties: {
    renderMode: {
      title: "Render Modus",
      type: "string",
      enum: Object.values(CellRendererDefinitionParamsMode)
    },
    options: {
      type: "object",
      properties: {
        sortAs: {
          type: "string",
          title: "Sortieren als",
          enum: ["string", "number"]
        },
        filterAs: {
          type: "string",
          title: "Filtern als",
          enum: ["string", "number", "date"]
        },
        text: {
          type: "string",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../../renderMode",
                  value: "text",
                  operator: BooleanEquationOperator.NOT_EQUALS
                }
              }
            ]
          }
        },
        targetTypeDescriptor: {
          ...TypeDescriptorSchema,
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../../renderMode",
                  value: "aggregationPipeline",
                  operator: BooleanEquationOperator.NOT_EQUALS
                }
              }
            ]
          }
        }
      }
    }
  }
};

export interface XSchemaForm {
  [key: string]: any;

  /**
   * @deprecated use `inputWidget` or `filterWidget` instead
   *
   * sets the widget for this schema explicitly
   */
  widget?: string;

  /** Will be used if the widget registry gets asked for an input widget for this schema */
  inputWidget?: string;
  /** Will be used if the widget registry gets asked for a filter widget for this schema. If not set, inputWidget will be used */
  filterWidget?: string;
  /** Will be used if the widget registry gets asked for a view widget for this schema */
  viewWidget?: string;
  /** Will be used if the property gets rendered e.g. in a table or gets exported to CSV/PDF */
  cellRendererDefinition?: CellRendererDefinition;

  /**
   * certain widgets like the "i18n" widget allow to set textarea to true
   */
  textarea?: boolean;

  /**
   * if e.g. the schema-form-table got data that is not represented in the schema, it will automatically create schema entries. Those entries will have this flag set to true
   */
  dataDrivenSchema?: boolean;

  /**
   * set to a higher value for textarea widget if you need more space
   */
  "x-textarea-minrows"?: number;

  /**
   * set to "dnd" for drag and drop or "multiselect" (default) for a standard html multi select
   */
  "x-multiselect-mode"?: "dnd" | "multiselect";

  /**
   * can be used for inline translated titles (schema.title has to be typeof string). In frontends, the <smallstack-form-field-title> component can be used
   */
  i18nTitle?: InlineTranslation;

  i18nPrefix?: string;

  /**
   * If set, input will be shown as link which will open a modal for further editing. Not supported by a lot of form inputs though.
   */
  asModal?: boolean;

  /** used by widgets and other fields */
  type?: string;

  /** Set the tenantId of the type, defaults to the current tenantId */
  typeTenantId?: string;

  /** can be used to automatically search for duplicates */
  duplicateDetection?: DuplicateDetection;

  /**
   * If set, a backoffice slot for the given value will be shown below the form input
   *
   * @deprecated use extensionSlot
   */
  backofficeSlot?: string;

  /** If set, an extension slot for the given value will be shown below the form input */
  extensionSlot?: string;

  /** used for grouping schema properties together. only works on root level */
  group?: string;

  /** Define events here */
  events?: { [eventName: string]: any };

  /** A schema entry which will not automatically be shown in auto generated forms or other views */
  hidden?: boolean;

  /** set rules for hiding or disabling this element */
  rules?: XSchemaFormRule[];
}

export type TypeSchemaProperties = {
  [key: string]: TypeSchema;
};

export interface TypeSchema extends JSONSchema7 {
  "x-schema-form"?: XSchemaForm;
  "x-i18n-prefix"?: string;
  "x-type-info"?: {
    /** Indicates wether this field is searchable or not, defaults to true */
    searchable?: boolean;
    /** The weight of the search field, between 0 and 10, defaults to 10 */
    searchableWeight?: number;
    /** Indicates wether this field is searchable or not, defaults to true */
    filterable?: boolean;
  };
  properties?: TypeSchemaProperties;
  items?: JSONSchema7Definition | JSONSchema7Definition[] | undefined | { properties: TypeSchemaProperties };
}
