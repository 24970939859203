import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, computed, input } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { getJsonByPath } from "@smallstack/utils";
import { LoadingStateComponent } from "../loading-state/loading-state.component";
import { RxModelComponent } from "../rx-model/rx-model.component";

/**
 * Displays a property of a reactive dataset.
 * Usage:
 *  ```
 *  <smallstack-rx-property type="customers" [modelId]="customerId" property="displayName"></smallstack-rx-property>
 *  ```
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-rx-property",
  templateUrl: "./rx-property.component.html",
  standalone: true,
  imports: [CommonModule, I18nComponent, LoadingStateComponent]
})
export class RxPropertyComponent extends RxModelComponent {
  public property = input.required<string>();

  public modelProperty = computed(() => {
    const model = this.model();
    const property = this.property();
    if (model && property) {
      return getJsonByPath(model, property);
    }
  });

  @Input("notFound")
  public notFoundText: string;

  @Input()
  public showNotFound = true;

  @ContentChild(TemplateRef)
  public notFoundTemplate: TemplateRef<any>;
}
