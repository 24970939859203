import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  TemplateRef,
  computed,
  contentChild,
  effect,
  inject,
  input,
  output,
  signal
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { debouncedSignal } from "@smallstack/client-common";
import { I18nComponent } from "@smallstack/i18n-components";
import { DataType, SearchableTypeField, TypeDescriptor, TypeSchema, isInlineTranslation } from "@smallstack/typesystem";
import { RxEntityStoreService, TypeService } from "@smallstack/typesystem-client";
import {
  convertToMangoSelector,
  isEmptyObject,
  mergeMangoQueries,
  objectsEqual,
  removeRequired,
  replaceVariablesInObject
} from "@smallstack/utils";
import { FormComponent, FormFieldTitleComponent, WidgetTreeComponent } from "@smallstack/widget-core";
import Fuse, { FuseOptionKey } from "fuse.js";
import { computedAsync } from "ngxtension/computed-async";
import { MangoQuery } from "rxdb";
import { SkeletonComponent } from "../skeleton/skeleton.component";

@Component({
  selector: "smallstack-rx-search",
  standalone: true,
  templateUrl: "./rx-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    FormComponent,
    FormFieldTitleComponent,
    WidgetTreeComponent,
    SkeletonComponent,
    I18nComponent
  ]
})
export class RxSearchComponent {
  private typeService = inject(TypeService);
  private rxEntityStoreService = inject(RxEntityStoreService);
  private activatedRoute = inject(ActivatedRoute);

  public typeDescriptor = input<TypeDescriptor>(undefined);
  public initialQuery = input<MangoQuery>(undefined);
  public searchableTypeFields = input<SearchableTypeField[]>(undefined);
  public showFilterBtn = input<boolean>(true);

  protected filtersShown = signal<boolean>(false);

  protected template = contentChild(TemplateRef<any>);

  private readonly store = computed(
    () => {
      const typeDescriptor: TypeDescriptor = this.typeDescriptor();
      if (!typeDescriptor) return undefined;
      return this.rxEntityStoreService.getStore({ typeDescriptor });
    },
    { equal: objectsEqual }
  );

  private readonly variant = computed<DataType>(() => {
    const typeDescriptor = this.typeDescriptor();
    if (!typeDescriptor) return undefined;
    if (!this.typeService.isLoaded()) return undefined;
    const variant = this.typeService.getVariant(typeDescriptor);
    if (!variant) throw new Error("NO VARIANT FOUND FOR: " + typeDescriptor);
    return variant;
  });
  protected readonly searchTextInput = signal<string>(undefined);
  protected readonly debouncedSearchTextInput = debouncedSignal(this.searchTextInput, 800);
  protected readonly filterSearch = signal<any>(undefined);
  protected readonly debouncedFilterSearch = debouncedSignal(this.filterSearch, 800);
  protected readonly filterSchema = computedAsync(async () => {
    let schema: TypeSchema = this.variant()?.schema;
    if (schema) {
      schema = await this.typeService.evalRefSchema(schema);
      return removeRequired(schema);
    }
    return undefined;
  });
  protected readonly combinedSearchableTypeFields = computed<SearchableTypeField[]>(() => {
    if (this.searchableTypeFields()) return this.searchableTypeFields();
    const variant = this.variant();
    return variant?.searchableFields;
  });

  protected readonly modelState = computed<{ isLoading: boolean; models?: any[] }>(
    () => {
      const searchText = this.debouncedSearchTextInput();

      const store = this.store();
      if (!store || store.loadingState() !== "loaded") return { isLoading: true };
      const filter = this.debouncedFilterSearch();

      let query: MangoQuery<any> = {};
      const filterContext = {
        queryParams: this.activatedRoute.snapshot.queryParams
      };
      if (filter && !isEmptyObject(filter))
        query.selector = convertToMangoSelector(Object.values(filter), filterContext);
      if (this.initialQuery()) {
        if (!query) query = replaceVariablesInObject(this.initialQuery(), filterContext);
        else query = mergeMangoQueries(query, replaceVariablesInObject(this.initialQuery(), filterContext));
      }

      let values: any | any[] = store.query(query);

      if (!values || values.length === 0) return { isLoading: false, models: [] };

      // add fuzzy search
      if (searchText) {
        const variant = this.variant();
        if (variant?.searchableFields instanceof Array && variant.searchableFields.length > 0) {
          const allProperties: FuseOptionKey<any>[] = variant.searchableFields.map((sf: SearchableTypeField) => ({
            name: sf.name,
            weight: sf.weight,
            getFn: (obj: any) => {
              const value = obj[sf.name];
              if (isInlineTranslation(value)) return value.map((t) => t.value);
              if (value instanceof Array) return value.map((v) => "" + v);
              return "" + value;
            }
          }));

          const fuse = new Fuse(values, {
            keys: allProperties,
            isCaseSensitive: false,
            ignoreLocation: true,
            threshold: 0.3
          });
          values = fuse.search(searchText).map((s) => s.item);
        }
      }
      return { isLoading: false, models: values };
    },
    { equal: objectsEqual }
  );

  public searchResult = output<any[]>();
  public searchState = output<{ isLoading: boolean; models?: any[] }>();

  @HostBinding("style.display")
  public display = "contents";

  constructor() {
    effect(() => {
      if (this.modelState().isLoading === false) this.searchResult.emit(this.modelState().models);
    });
    effect(() => {
      this.searchState.emit(this.modelState());
    });
  }
}
