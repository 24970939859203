<div class="form-control">
  @if (showLabel !== false) {
    <label class="label">
      <span class="label-text"><smallstack-i18n [data]="label"></smallstack-i18n></span>
    </label>
  }
  <div class="join">
    <input
      class="input input-bordered w-full join-item"
      data-test="password-field-input"
      name="password"
      #password
      [type]="hidePassword ? 'password' : 'text'"
      required
      [placeholder]="placeholder"
      [(ngModel)]="passwordValue"
      (ngModelChange)="passwordChanged($event)"
    />
    <button class="btn btn-square btn-primary join-item" (click)="hidePassword = !hidePassword">
      <smallstack-icon
        [name]="hidePassword ? 'fa-eye-slash' : 'fa-eye'"
        size="24"
        themeColor="daisyTheme.primary.textColor"
      ></smallstack-icon>
    </button>
  </div>
  <mat-password-strength
    #passwordComponent
    [password]="password.value"
    [min]="8"
    [enableUpperCaseLetterRule]="true"
    [enableLowerCaseLetterRule]="true"
    [enableSpecialCharRule]="true"
    (onStrengthChanged)="onStrengthChanged()"
  >
  </mat-password-strength>
</div>

<mat-password-strength-info [passwordComponent]="passwordComponent" [enableScoreInfo]="false" #passwordStrengthInfo>
</mat-password-strength-info>
