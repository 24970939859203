import { InjectionToken } from "@angular/core";
import { Translator } from "@smallstack/i18n-shared";

/**
 * This interface describes the equal named injectable which can be used to translate things where an import of
 * @smallstack/i18n* packages is impossible (e.g. due to circular dependencies). In most cases this interface will be
 * implemented by a translation store.
 *
 * Inject via: `@Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper`
 */
export interface TranslationHelper extends Translator {
  addJsonTranslations(locales: string[], translations: any): void;
}

/**
 * Injector Token for {@link TranslationHelper}
 */
export const TRANSLATION_HELPER: InjectionToken<TranslationHelper> = new InjectionToken("TranslationHelper");
