import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AxiosApiClient, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "last-new-user-registrations-widget",
  templateUrl: "./last-new-user-registrations-widget.component.html",
  styleUrls: ["./last-new-user-registrations-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastNewUserRegistrationsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public totalUserEntriesCount: number;
  public userEntries: UserDto[];

  public displayedColumns = ["creationdate", "name"];

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public static getConfiguration(): WidgetConfigurationSchema {
    const choices = ["1", "3", "5", "10", "15", "20", "25"];
    return {
      type: "object",
      properties: {
        entries: { type: "string", enum: choices, title: "@@dashboard.widgets.event.numberentries", default: "3" }
      }
    };
  }

  public ngOnInit(): void {
    void this.getUserRegistrations();
  }

  public async getUserRegistrations(): Promise<void> {
    await this.axiosApiClient
      .get(UsersApi)
      .getUsers({ sort: "-id" })
      .then((res) => {
        const users: UserDto[] = res.data.elements;
        this.totalUserEntriesCount = res.data.totalElements;
        this.userEntries = users.slice(0, this.data().entries);
        this.cdr.markForCheck();
      });
  }
}
