import { ChangeDetectionStrategy, Component } from "@angular/core";
import { filterNullish, getJsonByPath } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { combineLatest, map } from "rxjs";

@Widget({
  name: "KeyValueTable",
  templateName: "Schlüssel/Wert Tabelle",
  templateDescription: "Zeigt ein Kontext Object als Schlüssel/Wert Tabelle an.",
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        type: "string",
        title: "Kontext Variable",
        description: "Geben Sie hier an, in welcher Kontext Variable sich das Objekt befindet."
      }
    }
  },
  tags: AllWidgetTags,
  icon: "grid"
})
@Component({
  templateUrl: "./key-value-table-widget.component.html",
  styleUrls: ["./key-value-table-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-key-value-table"
})
export class KeyValueTableWidgetComponent extends BaseWidgetComponent {
  protected content$ = combineLatest([this.context$, this.data$]).pipe(
    filterNullish({ filterEmptyObjects: true }),
    map(([context, data]) => {
      return getJsonByPath(context, data.contextVariable);
    })
  );
}
