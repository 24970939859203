import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

/**
 * A service where descent custom layout directives can register itself. This is necessary since @ContentChildren from Angular stops at templates from sub-components, meaning, a sub-sub-sub compomnent that renders a <div schemaFormPlaceholder="something"> inside its <ng-content> will never be recognized.
 * See https://stackoverflow.com/questions/52653376/angular-using-contentchildren-to-get-children-inside-another-component
 */
@Injectable()
export class SchemaFormCustomLayoutService {
  public schemaFormPlaceholders$: Subject<any> = new Subject();

  public addSchemaFormPlaceholder(comp: any): void {
    this.schemaFormPlaceholders$.next(comp);
  }
}
