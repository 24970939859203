import { CheckinVisitorDto, CheckinVisitorEventDto } from "@smallstack/axios-api-client";
import { ApplicationState } from "./application-state";

const nsBridgewindowPropertyName: string = "nsWebViewBridge";

export const ACTION_SCANBARCODE = "scanBarcode";
export const RESPONSE_SCANBARCODE_SUCCESS = "scanBarcodeSuccess";
export const RESPONSE_SCANBARCODE_ERROR = "scanBarcodeError";

export const ACTION_PRINT = "print";
export const RESPONSE_PRINT_SUCCESS = "printSuccess";
export const RESPONSE_PRINT_ERROR = "printError";

export const ACTION_SEND_APPLICATION_STATE = "sendApplicationState";
export const RESPONSE_SEND_APPLICATION_STATE_SUCCESS = "sendApplicationStateSuccess";
export const RESPONSE_SEND_APPLICATION_STATE_ERROR = "sendApplicationStateError";

export const ACTION_PRINT_FORM = "printForm";
export const RESPONSE_PRINT_FORM_SUCCESS = "printFormSuccess";
export const RESPONSE_PRINT_FORM_ERROR = "printFormError";

export const ACTION_STORE_PROPERTY = "storeProperty";
export const RESPONSE_STORE_PROPERTY_SUCCESS = "storePropertySuccess";
export const RESPONSE_STORE_PROPERTY_ERROR = "storePropertyError";

export const ACTION_LOAD_PROPERTY = "loadProperty";
export const RESPONSE_LOAD_PROPERTY_SUCCESS = "loadPropertySuccess";
export const RESPONSE_LOAD_PROPERTY_ERROR = "loadPropertyError";

export const ACTION_REMOVE_PROPERTY = "removeProperty";
export const RESPONSE_REMOVE_PROPERTY_SUCCESS = "removePropertySuccess";
export const RESPONSE_REMOVE_PROPERTY_ERROR = "removePropertyError";

// vvvv actions that will be sent the other way around (to the webview)

export const WEB_VIEW_ACTION_REMOVE_LOCAL_STORAGE_PROPERTY = "webViewRemoveLocalStorageProperty";
export const RESPONSE_WEB_VIEW_ACTION_REMOVE_LOCAL_STORAGE_PROPERTY_SUCCESS =
  "webViewRemoveLocalStoragePropertySuccess";
export const RESPONSE_WEB_VIEW_ACTION_REMOVE_LOCAL_STORAGE_PROPERTY_ERROR = "webViewRemoveLocalStoragePropertyError";

/**
 * Bridge between the native "Applications" App and a Website
 */
export class ApplicationBridge {
  private nsBridgeReady: boolean = false;

  constructor() {
    if (this.isAvailable()) this.nsBridgeReady = true;
    else {
      window.addEventListener("ns-bridge-ready", function (e: any) {
        this.nsBridgeReady = true;
      });

      void this.getNsBridge$().then((nsWebViewBridge: any) => {
        // register webview events that came from outside
        nsWebViewBridge.on(WEB_VIEW_ACTION_REMOVE_LOCAL_STORAGE_PROPERTY, (key: string) => {
          localStorage.removeItem(key);
        });
      });
    }
  }

  public getNsBridge(): any {
    if (!this.isAvailable())
      throw new Error("NSBridge not ready, please call isAvailable first to prevent this error!");
    return (window as any)[nsBridgewindowPropertyName];
  }

  /**
   * Resolves as soon as bridge is available
   */
  public getNsBridge$(): Promise<any> {
    return this.isAvailable$().then(() => {
      return this.getNsBridge();
    });
  }

  public isAvailable(): boolean {
    return (window as any)[nsBridgewindowPropertyName] !== undefined;
  }

  /**
   * Resolves as soon as bridge is available
   */
  public isAvailable$(): Promise<void> {
    if (this.nsBridgeReady === true) return Promise.resolve();
    return new Promise<void>((resolve) => {
      window.addEventListener("ns-bridge-ready", function (e: any) {
        resolve();
      });
    });
  }

  public async sendApplicationState(applicationState: ApplicationState): Promise<any> {
    return this.sendData(
      applicationState,
      ACTION_SEND_APPLICATION_STATE,
      RESPONSE_SEND_APPLICATION_STATE_SUCCESS,
      RESPONSE_SEND_APPLICATION_STATE_ERROR
    );
  }

  public async scanBarcode(): Promise<string> {
    return this.sendData(undefined, ACTION_SCANBARCODE, RESPONSE_SCANBARCODE_SUCCESS, RESPONSE_SCANBARCODE_ERROR);
  }

  public async printVisitor(visitor: CheckinVisitorDto, event?: CheckinVisitorEventDto): Promise<string> {
    const data = visitor.fields;
    data.USER_ID = visitor.id;
    return this.sendData(
      { formId: event?.formId, content: data },
      ACTION_PRINT_FORM,
      RESPONSE_PRINT_FORM_SUCCESS,
      RESPONSE_PRINT_FORM_ERROR
    );
  }

  public async getProperty(key: string): Promise<string> {
    return this.sendData(key, ACTION_LOAD_PROPERTY, RESPONSE_LOAD_PROPERTY_SUCCESS, RESPONSE_LOAD_PROPERTY_ERROR);
  }

  public async saveProperty(key: string, value: string): Promise<void> {
    return this.sendData(
      { key, value },
      ACTION_STORE_PROPERTY,
      RESPONSE_STORE_PROPERTY_SUCCESS,
      RESPONSE_STORE_PROPERTY_ERROR
    );
  }

  public async removeProperty(key: string): Promise<void> {
    return this.sendData(key, ACTION_REMOVE_PROPERTY, RESPONSE_REMOVE_PROPERTY_SUCCESS, RESPONSE_REMOVE_PROPERTY_ERROR);
  }

  private sendData(data: any, eventName: string, successEventName: string, errorEventName: string): Promise<any> {
    if (!this.isAvailable()) throw new Error("Nativescript Bridge not available!");
    return new Promise<void>((resolve, reject) => {
      (window as any)[nsBridgewindowPropertyName].on(successEventName, (data: any) => {
        resolve(data);
        this.unregisterEvents(successEventName, errorEventName);
      });
      (window as any)[nsBridgewindowPropertyName].on(errorEventName, (error: Error) => {
        reject(error);
        this.unregisterEvents(successEventName, errorEventName);
      });
      (window as any)[nsBridgewindowPropertyName].emit(eventName, data);
    });
  }

  private unregisterEvents(...events: string[]) {
    events.forEach((e) => (window as any)[nsBridgewindowPropertyName].off(e));
  }
}
