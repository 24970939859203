/* eslint-disable max-lines-per-function */
import { inject } from "@angular/core";
import { CrudV2, isPage } from "@smallstack/api-shared";
import { OnlineStatusService } from "@smallstack/client-common";
import { ApiConfiguration, ContextService } from "@smallstack/common-components";
import { AxiosInterceptorService, Logger } from "@smallstack/core-common";
import { SQBuilder, SearchByFieldMatcher } from "@smallstack/typesystem";
import { getValueFromStringProviderSync } from "@smallstack/utils";
import axios from "axios";
import {
  MangoQuery,
  MangoQueryNoLimit,
  ReplicationPullOptions,
  ReplicationPushOptions,
  RxCollection,
  RxDocument,
  RxQuery,
  lastOfArray
} from "rxdb";
import { RxReplicationState, replicateRxCollection } from "rxdb/plugins/replication";
import { BehaviorSubject } from "rxjs";
import { RestCheckpointType } from "../services/rx-collection.common";
import { OfflineCollectionModel } from "./offline-collection-model";

export interface OfflineCollectionOptions<Model> {
  pushOptions?: ReplicationPushOptions<Model>;
  pullOptions?: ReplicationPullOptions<Model, RestCheckpointType>;
  apiConfiguration: ApiConfiguration;
  apiPath: string;
  crud: CrudV2<Model>;
  rxCollection: RxCollection;
  tenantId?: string;
  live?: boolean;
  autoStart?: boolean;
}

export interface ReplicationLogEntry {
  timestamp: number;
  failed: boolean;
  action: "create" | "read" | "update" | "delete";
  message?: string;
}

export class OfflineCollection<Model extends OfflineCollectionModel = any> {
  protected rxCollection: RxCollection<Model>;
  public replicationState: RxReplicationState<Model, RestCheckpointType>;

  #replicationLog$: BehaviorSubject<ReplicationLogEntry[]> = new BehaviorSubject([]);
  public replicationLog$ = this.#replicationLog$.asObservable();

  public static async create<Model extends OfflineCollectionModel>(
    options: OfflineCollectionOptions<Model>
  ): Promise<OfflineCollection<Model>> {
    const offlineCollection = new this<Model>(options);
    await offlineCollection.startReplication();
    return offlineCollection;
  }

  private contextService = inject(ContextService);
  private onlineStatusService = inject(OnlineStatusService);

  protected constructor(public options: OfflineCollectionOptions<Model>) {
    this.rxCollection = options.rxCollection;
  }

  public async insert(model: Model): Promise<RxDocument<Model>> {
    // model.id = createObjectId();
    // const doc = await this.rxCollection.insert(model);
    // return doc.toMutableJSON();

    // go the long way
    const insertedModel = await this.options.crud.post(model);
    await this.sync();
    return this.findOne(insertedModel.id).exec();
  }

  public async update(model: Model): Promise<RxDocument<Model>> {
    // const doc = await this.rxCollection.upsert(model);
    // return doc.toMutableJSON();

    // go the long way
    const updatedModel = await this.options.crud.put(model);
    await this.sync();
    return this.findOne(updatedModel.id).exec();
  }

  /** Inserts or updates a document, depending on wether an id exists or not. If no id exists, one will be created so that local copies can be worked with */
  public async insertOrUpdate(model: Model): Promise<RxDocument<Model>> {
    if (model.id) {
      return this.update(model);
    } else {
      return this.insert(model);
    }
  }

  public upsert(model: Model): Promise<RxDocument<Model>> {
    return this.update(model);
  }

  public find(queryObj?: MangoQuery<Model>): RxQuery<Model, RxDocument<Model>[]> {
    return this.rxCollection.find(queryObj);
  }

  public findOne(queryObj?: string | MangoQueryNoLimit<Model>): RxQuery<Model, RxDocument<Model>> {
    return this.rxCollection.findOne(queryObj);
  }

  /** re syncs the local database */
  public async sync(): Promise<void> {
    this.replicationState.reSync();
    await this.replicationState.awaitInSync();
  }

  /**
   * AwaitsInSync if online, otherwise it will be skipped
   */
  public async awaitInSync(): Promise<void> {
    if (!this.onlineStatusService.isOnline()) return;
    if (this.replicationState.isStopped()) await this.replicationState.start();
    await this.replicationState.awaitInSync();
  }

  /** cleans the local collection and triggers a resync */
  public async cleanAndResync(): Promise<void> {
    await this.startReplication();
  }

  protected createReplicationState(): RxReplicationState<Model, RestCheckpointType> {
    const replicationState = replicateRxCollection<Model, RestCheckpointType>({
      collection: this.rxCollection,
      /**
       * An id for the replication to identify it
       * and so that RxDB is able to resume the replication on app reload.
       * If you replicate with a remote server, it is recommended to put the
       * server url into the replicationIdentifier.
       */
      replicationIdentifier:
        "rest-replication-to-" +
        getValueFromStringProviderSync(this.options.apiConfiguration.apiUrl + "-" + this.getTenantId()),
      /**
       * By default it will do an ongoing realtime replication.
       * By settings live: false the replication will run once until the local state
       * is in sync with the remote state, then it will cancel itself.
       * (optional), default is true.
       */
      live: this.options.live || true,
      /**
       * Time in milliseconds after when a failed backend request
       * has to be retried.
       * This time will be skipped if a offline->online switch is detected
       * via navigator.onLine
       * (optional), default is 5 seconds.
       */
      retryTime: 5 * 1000,
      /**
       * When multiInstance is true, like when you use RxDB in multiple browser tabs,
       * the replication should always run in only one of the open browser tabs.
       * If waitForLeadership is true, it will wait until the current instance is leader.
       * If waitForLeadership is false, it will start replicating, even if it is not leader.
       * [default=true]
       */
      waitForLeadership: false,
      /**
       * If this is set to false,
       * the replication will not start automatically
       * but will wait for replicationState.start() being called.
       * (optional), default is true
       */
      autoStart: this.options.autoStart || true,

      pull: this.options.pullOptions
        ? this.options.pullOptions
        : {
            /**
             * Pull handler
             */
            handler: async (lastCheckpoint: RestCheckpointType, batchSize: number) => {
              Logger.debug("OfflineCollection", "Check online status...");
              if (!this.onlineStatusService.isOnline()) throw new Error("not online");
              Logger.debug("OfflineCollection", " ⇩⇩⇩ Pulling data for " + this.options.apiPath);
              try {
                const minTimestamp = lastCheckpoint?.lastUpdatedAt !== undefined ? lastCheckpoint.lastUpdatedAt : 0;
                const page = lastCheckpoint?.page || 1;
                /**
                 * In this example we replicate with a remote REST server
                 */
                const search = SQBuilder.asString([
                  {
                    fieldname: "lastUpdatedAt",
                    matcher: SearchByFieldMatcher.GREATER_THAN,
                    value: minTimestamp
                  }
                ]);
                const apiUrl = getValueFromStringProviderSync(this.options.apiConfiguration.apiUrl);
                if (!apiUrl) throw new Error("apiUrl is not defined");
                const url =
                  apiUrl +
                  "/" +
                  this.options.apiPath +
                  "?search=" +
                  search +
                  "&sort=lastUpdatedAt&includeSoftDeleted=true&size=" +
                  batchSize +
                  "&page=" +
                  page;
                const response = await axios({
                  url,
                  headers: this.createAxiosHeaders(),
                  validateStatus: () => true,
                  signal: AxiosInterceptorService.getAbortSignal()
                });
                if (response.status !== 200)
                  throw new Error(
                    "Error while querying url: " + url + ", " + response.status + ": " + response.statusText
                  );
                const data = await response.data;
                let documentsFromRemote: Model[] = isPage(data) ? data.elements : data;
                if (!documentsFromRemote || !(documentsFromRemote instanceof Array)) documentsFromRemote = [];

                this.#replicationLog$.next([
                  ...this.#replicationLog$.value,
                  {
                    timestamp: Date.now(),
                    failed: false,
                    action: "read",
                    message:
                      "Erfolgreich synchronisiert: " +
                      this.rxCollection.name +
                      " (" +
                      documentsFromRemote.length +
                      " neue Elemente)"
                  }
                ]);

                // if result is a page and it has more entries, then we need to pull again (with the same lastUpdatedAt), otherwise we will start with page 1 again and use the lastUpdatedAt of the last element
                const nextPage = isPage(data) && data.hasNext ? page + 1 : 1;
                const lastUpdatedAt =
                  isPage(data) && data.hasNext ? minTimestamp : lastOfArray(documentsFromRemote)?.lastUpdatedAt;
                const checkpoint =
                  documentsFromRemote.length === 0
                    ? lastCheckpoint
                    : ({
                        lastUpdatedAt,
                        page: nextPage
                      } as RestCheckpointType);
                return {
                  /**
                   * Contains the pulled documents from the remote.
                   * Notice: If documentsFromRemote.length < batchSize,
                   * then RxDB assumes that there are no more un-replicated documents
                   * on the backend, so the replication will switch to 'Event observation' mode.
                   */
                  documents: documentsFromRemote,
                  /**
                   * The last checkpoint of the returned documents.
                   * On the next call to the pull handler,
                   * this checkpoint will be passed as 'lastCheckpoint'
                   */
                  checkpoint
                } as any;
              } catch (e) {
                // not trigger sync again if 401 or 403
                if (e.message === "Unauthorized" || e.message === "Forbidden" || e.message === "Not Found")
                  return {
                    documents: [],
                    checkpoint: lastCheckpoint
                  };
                Logger.error("OfflineCollection", "Error while pulling data:" + e.message, e);
                throw e;
              }
            },
            batchSize: 1000,
            /**
             * Modifies all documents after they have been pulled
             * but before they are used by RxDB.
             * (optional)
             */
            modifier: (d) => {
              return d;
            }
          }
    });

    return replicationState;
  }

  private async startReplication() {
    Logger.debug("OfflineCollection", "🔀 Starting replication for " + this.options.apiPath);
    this.replicationState = this.createReplicationState();
  }

  private createAxiosHeaders(): any {
    const resellerId = getValueFromStringProviderSync(this.contextService.getEvaluatedContext().resellerId);
    const tenantId = this.getTenantId();
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-tenant-id": tenantId ? tenantId : resellerId,
      "x-reseller-id": resellerId,
      "x-auth-tenant-id": getValueFromStringProviderSync(this.contextService.getEvaluatedContext().authTenantId),
      Authorization: "Bearer " + getValueFromStringProviderSync(this.contextService.getEvaluatedContext().token)
    };
  }

  private getTenantId(): string {
    if (this.options?.tenantId) return this.options.tenantId;
    return getValueFromStringProviderSync(this.contextService.getEvaluatedContext().tenantId);
  }
}
