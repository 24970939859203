import { TypeSchema } from "../typesystem/type-schema";

export enum GeoJsonType {
  Point = "Point",
  LineString = "LineString",
  Polygon = "Polygon",
  MultiPoint = "MultiPoint",
  MultiLineString = "MultiLineString",
  MultiPolygon = "MultiPolygon"
}

export interface GeoJson {
  type: GeoJsonType;
  coordinates: Array<number>;
}

export const GeoJsonSchema: TypeSchema = {
  type: "object",
  properties: {
    type: {
      title: "Typ",
      type: "string",
      enum: Object.values(GeoJsonType)
    },
    coordinates: {
      title: "Koordinaten",
      type: "array",
      items: {
        type: "number"
      }
    }
  },
  required: ["type", "coordinates"]
};
