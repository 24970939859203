@if (data().showIcon === true && !data().text) {
  <button class="btn btn-square btn-primary my-4 drop-shadow-lg" (click)="navigate()">
    <smallstack-icon name="google-maps-new" size="md"></smallstack-icon>
  </button>
} @else {
  <button class="btn btn-block btn-primary my-4 drop-shadow-lg" (click)="navigate()">
    <div class="flex flex-row space-x-2 items-center">
      <smallstack-icon name="fa-map-location-dot" size="24"></smallstack-icon>
      <span>{{ data().text }}</span>
    </div>
  </button>
}
