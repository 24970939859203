/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { Subscription } from "rxjs";
import { LocaleService } from "../../services/locale.service";
import { FlagComponent } from "../flag/flag.component";
import { I18nComponent } from "../i18n/i18n.component";

interface Locale {
  locale: string;
  country: string;
  language: string;
}

function splitLocale(locale: string): { language: string; country: string } {
  const [language, country] = locale.split("_");
  return { language, country };
}

@Component({
  selector: "smallstack-locale-selector",
  templateUrl: "./locale-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatMenuModule, FlagComponent, I18nComponent]
})
export class LocaleSelectorComponent implements OnDestroy {
  public splitLocales: Locale[];
  public country: string;
  public language: string;
  public locale: string = "de_de";

  @Input()
  public set locales(locales: string[]) {
    if (locales) this.splitLocales = locales.map((locale) => ({ locale, ...splitLocale(locale) }));
  }

  @Input()
  public showCountry: boolean = true;

  @Input()
  public menuClass: string;

  /**
   * can be 'full' or 'flagOnly'
   */
  @Input()
  public displayMode: string = "full";

  @Output()
  public selectedLocale: EventEmitter<string> = new EventEmitter();

  private subscription: Subscription = new Subscription();

  constructor(public localeService: LocaleService) {
    if (this.locales === undefined) {
      this.locales = this.localeService.availableLocales$.value
        ?.sort((la, lb) => la.index - lb.index)
        .map((locale) => locale.iso);
    }
    this.subscription.add(
      localeService.currentLocale$.subscribe((locale) => {
        this.locale = locale;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async selectLocale(language: string): Promise<void> {
    await this.localeService.setCurrentLocale(language);
    this.selectedLocale.emit(language);
  }
}
