import {
  TypeSchemaProperties,
  WIDGET_FILTER_INPUT_I18N,
  WIDGET_FORM_INPUT_I18N,
  WIDGET_FORM_INPUT_SELECT,
  WIDGET_VIEW_I18N,
  XSchemaFormRule
} from "@smallstack/typesystem";
import { BooleanEquationOperator } from "@smallstack/utils";

const NOT_EQUALS_BTN_RULE: XSchemaFormRule = {
  if: {
    dataPath: "../btnType",
    operator: BooleanEquationOperator.NOT_EQUALS,
    value: "btn"
  },
  action: "hide"
};
export const BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES: TypeSchemaProperties = {
  btnType: {
    type: "string",
    title: "Button Typ",
    default: "btn",
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_SELECT,
      values: [
        {
          label: "Normal",
          value: "btn"
        },
        {
          label: "Rund",
          value: "btn btn-circle"
        },
        {
          label: "Viereckig",
          value: "btn btn-square"
        },
        {
          label: "Aktionsbutton für Tabellenzeilen",
          value: "btn-action"
        },
        {
          label: "Unsichtbar (nur Inhalt sichtbar)",
          value: "btn-ghost"
        }
      ]
    }
  },
  btnText: {
    type: "object",
    title: "Button Text",
    description: "Text auf dem Button",
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_I18N,
      viewWidget: WIDGET_VIEW_I18N,
      filterWidget: WIDGET_FILTER_INPUT_I18N,
      rules: [NOT_EQUALS_BTN_RULE]
    }
  },
  btnIcon: {
    type: "string",
    title: "Icon auf dem Button"
  },
  btnColor: {
    type: "string",
    title: "Button Farbe",
    default: "btn-neutral",
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_SELECT,
      values: [
        {
          label: "Neutral",
          value: "btn-neutral"
        },
        {
          label: "Primär",
          value: "btn-primary"
        },
        {
          label: "Sekundär",
          value: "btn-secondary"
        },
        {
          label: "Akzent",
          value: "btn-accent"
        },
        {
          label: "Info",
          value: "btn-info"
        },
        {
          label: "Erfolg",
          value: "btn-success"
        },
        {
          label: "Warnung",
          value: "btn-warning"
        },
        {
          label: "Fehler",
          value: "btn-error"
        }
      ]
    }
  },
  btnOutline: {
    type: "boolean",
    title: "Button Outline",
    description: "Soll der Button als Outline angezeigt werden?",
    default: false
  },
  btnWidth: {
    title: "Button Breite",
    type: "string",
    default: "",
    description: "Breite des Buttons",
    "x-schema-form": {
      rules: [NOT_EQUALS_BTN_RULE],
      inputWidget: WIDGET_FORM_INPUT_SELECT,
      values: [
        {
          label: "Normal",
          value: ""
        },
        {
          label: "Breiter",
          value: "btn-wide"
        },
        {
          label: "Volle Breite",
          value: "btn-block"
        }
      ]
    }
  },
  btnRounding: {
    title: "Button Rundung",
    type: "string",
    default: "",
    description: "Rundung des Buttons",
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_SELECT,
      values: [
        { value: "rounded-none", label: "Keine" },
        { value: "rounded-sm", label: "Klein" },
        { value: "rounded", label: "Normal" },
        { value: "rounded-md", label: "Groß" },
        { value: "rounded-lg", label: "Größer" },
        { value: "rounded-xl", label: "Noch größer" },
        { value: "rounded-full", label: "Komplett rund" }
      ]
    }
  },
  btnSize: {
    title: "Größe",
    type: "string",
    default: "",
    description: "Größe des Buttons",
    "x-schema-form": {
      rules: [
        {
          if: {
            dataPath: "../btnType",
            operator: BooleanEquationOperator.EQUALS,
            value: "btn-action"
          },
          action: "hide"
        }
      ],
      inputWidget: WIDGET_FORM_INPUT_SELECT,
      values: [
        {
          label: "viereckig",
          value: "btn-action"
        },
        {
          label: "sehr klein",
          value: "btn-xs"
        },
        {
          label: "klein",
          value: "btn-sm"
        },
        {
          label: "normal",
          value: ""
        },
        {
          label: "groß",
          value: "btn-lg"
        }
      ]
    }
  },
  btnTooltip: {
    type: "string",
    title: "Tooltip",
    description: "Tooltip für den Button"
  },
  showBtnConfirmation: {
    type: "boolean",
    title: "Zeige Abfrage",
    description: "Soll eine Abfrage angezeigt werden, bevor der Button ausgeführt wird?",
    default: false
  },
  btnConfirmationTitle: {
    type: "array",
    title: "Titel der Abfrage",
    default: [{ value: "Sind Sie sicher?" }],
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_I18N,
      rules: [
        {
          if: {
            dataPath: "../showBtnConfirmation",
            operator: BooleanEquationOperator.EQUALS,
            value: false
          },
          action: "hide"
        }
      ]
    }
  },
  btnConfirmationText: {
    type: "array",
    title: "Text der Abfrage",
    default: [{ value: "Sind Sie sich sicher, dass Sie diese Aktion ausführen möchten?" }],
    "x-schema-form": {
      inputWidget: WIDGET_FORM_INPUT_I18N,
      rules: [
        {
          if: {
            dataPath: "../showBtnConfirmation",
            operator: BooleanEquationOperator.EQUALS,
            value: false
          },
          action: "hide"
        }
      ]
    }
  }
};

export interface ButtonConfiguration {
  btnType: "btn" | "btn btn-circle" | "btn btn-square" | "btn-action";
  btnText?: string;
  btnIcon?: string;
  btnColor?: "btn-primary" | "btn-secondary" | "btn-accent" | "btn-warning";
  btnWidth?: "" | "btn-wide" | "btn-block";
  btnOutline?: boolean;
  btnRounding?: "rounded-none" | "rounded-sm" | "rounded" | "rounded-md" | "rounded-lg" | "rounded-xl" | "rounded-full";
  btnSize: "btn-action" | "btn-xs" | "btn-sm" | "" | "btn-lg";
  showBtnConfirmation?: boolean;
  btnConfirmationTitle?: string;
  btnConfirmationText?: string;
  btnTooltip?: string;
}

export function calculateButtonClasses(data: ButtonConfiguration): string {
  let classes = data.btnType;
  if (data.btnColor) classes += " " + data.btnColor;
  if (data.btnWidth) classes += " " + data.btnWidth;
  if (data.btnSize) classes += " " + data.btnSize;
  if (data.btnRounding) classes += " " + data.btnRounding;
  if (data.btnOutline) classes += " btn-outline";
  return classes;
}
