import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, forwardRef, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FileDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { FileStore } from "../../stores/file.store";
import { FileExplorerButtonComponent } from "../file-explorer-button/file-explorer-button.component";

@Component({
  selector: "smallstack-file-explorer-input",
  templateUrl: "./file-explorer-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileExplorerInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileExplorerInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    I18nComponent,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    FileExplorerButtonComponent
  ]
})
export class FileExplorerInputComponent implements ControlValueAccessor {
  public change: any;
  // @ViewChildren(MatFormField) formFields;

  @Input() public label: string;
  @Input() public hint: string;
  @Input() public disabled: boolean;
  @Input() public value: string;
  @Input() public required: boolean;
  @Input() public fileStore: FileStore;
  @Input() public fc: UntypedFormControl;
  @Input() public placeholder: string = "";

  constructor(private cdr: ChangeDetectorRef) {}

  public select(file: FileDto): void {
    if (file) {
      this.value = file.url || "";
      this.change(this.value);
    } else this.change("");
  }

  public registerOnChange(fn: any): void {
    this.change = fn;
  }

  public registerOnTouched(fn: any): void {
    // not implemented
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj ? obj : "";
    this.cdr.markForCheck();
  }

  public inputChange(value: string): void {
    this.change(value);
  }

  public validate(control: AbstractControl) {
    return this.required ? Validators.required(control) : null;
  }
}

@Directive({
  selector: "file-explorer-input[required]"
})
export class FileExplorerRequiredDirective {
  constructor(private host: FileExplorerInputComponent) {}

  @Input()
  public set required(value: boolean) {
    this.host.required = value;
  }
}
