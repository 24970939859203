@if (groups() | length: 1) {
  <smallstack-form-table [showActions]="false" [showHeader]="false" [schema]="groups() && groups()[0].schema" />
} @else {
  <mat-tab-group animationDuration="0" [selectedIndex]="0">
    @for (group of groups(); track group.key) {
      <mat-tab>
        <ng-template mat-tab-label>
          <smallstack-i18n
            [data]="group.key"
            [ngStyle]="{ color: (group.isValid$ | async) ? 'rgba(211, 47, 47)' : 'unset' }"
          />
        </ng-template>
        <ng-template matTabContent>
          <smallstack-form-table [showActions]="false" [showHeader]="false" [schema]="group.schema" />
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
}
