<smallstack-icon alias="info" size="24" class="clickable-icon text-primary" (click)="openInfoBox()" />

<div #infoBox class="info-box-background">
  <div class="info-box" data-test="info-box">
    <span class="close" data-test="close-btn">
      <div (click)="closeInfoBox()">
        <smallstack-icon alias="close" size="20" color="444444" theme="ios-filled" />
      </div>
    </span>
    <ng-content />
  </div>
</div>
