import { TypeSchema } from "../typesystem/type-schema";

export interface BillingParty {
  linkedType: string;
  linkedId: string;
  factor: number;
}

export const BillingPartySchema: TypeSchema = {
  type: "object",
  title: "BillingParty",
  properties: {
    linkedType: {
      type: "string"
    },
    linkedId: {
      type: "string"
    },
    factor: {
      type: "number"
    }
  },
  required: ["linkedType", "linkedId", "factor"],
  additionalProperties: false
};
