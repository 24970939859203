@if (compoundWidget()) {
  <smallstack-widget-tree
    [widget]="compoundWidget().widget"
    [connections]="compoundWidget().socketConnections"
    [rootSockets]="compoundWidget().rootSockets"
    [context]="{ model: evaluatedModel(), schema: schema() }"
    [widgetRendererOptions]="{ editMode: customizationModeStore.value$ | async }"
  />
} @else {
  <div class="component-loading"></div>
}
