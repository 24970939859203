<div class="mat-outline">
  @if (value()) {
    <div class="flex flex-col gap-1">
      <div class="w-full flex flex-col md:flex-row gap-1">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Aktiv von</span>
          </label>
          <input
            class="input"
            type="datetime-local"
            [ngModel]="startTime"
            (ngModelChange)="setDate('startTime', $event)"
          />
        </div>
        <div class="form-control">
          <div class="label">
            <div class="label-text">Aktiv bis</div>
          </div>
          <input class="input" type="datetime-local" [ngModel]="endTime" (ngModelChange)="setDate('endTime', $event)" />
        </div>
      </div>
      <div class="w-full flex flex-col md:flex-row gap-1">
        <mat-form-field [appearance]="options()?.appearance">
          <mat-label>Serienmuster</mat-label>
          <mat-select [ngModel]="value()?.pattern" (selectionChange)="setProp('pattern', $event.value)">
            @for (pattern of SchedulingRulePattern; track pattern) {
              <mat-option [value]="pattern">
                <smallstack-i18n data="@@todo.schedulingRules.pattern.{{ pattern }}"></smallstack-i18n
              ></mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (value()?.pattern === "businessdays" || value()?.pattern === "weekly") {
          <mat-form-field [appearance]="options()?.appearance">
            <mat-label>Jede/Alle</mat-label>
            <input matInput type="number" min="1" [ngModel]="value()?.each" (ngModelChange)="setProp('each', $event)" />
          </mat-form-field>
        }
        @if (value()?.pattern !== "always" && value()?.pattern !== "once") {
          <mat-form-field [appearance]="options()?.appearance">
            <mat-label>Stunde</mat-label>
            <input
              matInput
              type="number"
              min="0"
              max="23"
              [ngModel]="value()?.hour"
              (ngModelChange)="setProp('hour', $event)"
            />
          </mat-form-field>
        }
        @if (value()?.pattern !== "always" && value()?.pattern !== "once") {
          <mat-form-field [appearance]="options()?.appearance">
            <mat-label>Minute</mat-label>
            <input
              matInput
              type="number"
              min="0"
              max="59"
              [ngModel]="value()?.minute"
              (ngModelChange)="setProp('minute', $event)"
            />
          </mat-form-field>
        }
        @if (value()?.pattern === "weekly") {
          <mat-form-field [appearance]="options()?.appearance">
            <mat-label>Wochentage</mat-label>
            <mat-select
              [ngModel]="value()?.patternIncludes"
              multiple
              (selectionChange)="setProp('patternIncludes', $event.value)"
            >
              @for (
                weekday of [
                  { name: "Montag", day: 1 },
                  { name: "Dienstag", day: 2 },
                  { name: "Mittwoch", day: 3 },
                  { name: "Donnerstag", day: 4 },
                  { name: "Freitag", day: 5 },
                  { name: "Samstag", day: 6 },
                  { name: "Sonntag", day: 7 }
                ];
                track weekday
              ) {
                <mat-option [value]="weekday.day">{{ weekday.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </div>
    </div>
  }
  @if (nextDates && value()?.pattern !== "always" && value()?.pattern !== "once") {
    <div class="flex flex-col">
      <h4>Vorschau der nächsten Termine:</h4>
      @if (!value()?.endTime) {
        <div class="text-help pb-2">
          Es werden beispielhaft die kommenden 10 Termine angezeigt. Vergangene Termine werden ausgeblendet.
        </div>
      }
      <div class="flex flex-row flex-wrap gap-2 max-w-3xl">
        @for (nextDate of nextDates(); track nextDate) {
          <smallstack-date
            [timestamp]="nextDate"
            format="EEEE dd.MM.yyyy HH:mm"
            class="border border-gray-300 rounded-md p-2 w-52 border-l-4"
            [ngClass]="{
              'border-l-red-500 ': (nextDate | date: 'c') === '1',
              'border-l-orange-500': (nextDate | date: 'c') === '2',
              'border-l-yellow-500': (nextDate | date: 'c') === '3',
              'border-l-lime-500': (nextDate | date: 'c') === '4',
              'border-l-teal-500': (nextDate | date: 'c') === '5',
              'border-l-blue-500': (nextDate | date: 'c') === '6',
              'border-l-violet-500': (nextDate | date: 'c') === '0'
            }"
          ></smallstack-date>
        }
      </div>
    </div>
  }
</div>
