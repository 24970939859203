{{ value() | json }}
<div class="mat-outline">
  @if (options()?.showLabel !== false) {
    <mat-label>
      <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
    </mat-label>
  }
  <smallstack-linked-models
    [links]="value()"
    (linksChange)="setValue($event)"
    [editable]="true"
    [typeTags]="typeTags()"
    [linkableTypes]="linkableTypes()"
  ></smallstack-linked-models>
</div>
