import Decimal from "decimal.js";
import { getJsonByPath } from "./json-utils";

export enum BooleanEquationOperator {
  GREATER_THAN = "greaterThan",
  GREATER = "greater",
  LESS_THAN = "lessThan",
  LESS = "less",
  EQUALS = "equals",
  NOT_EQUALS = "notEquals"
}

export interface BooleanEquation {
  valueA: any;
  valueB: any;
  operator: BooleanEquationOperator;
}

export interface PropertyRule extends BooleanEquation {
  result: any;
}

export interface ContextRule {
  propertyName: string;
  operator: BooleanEquationOperator;
  value: any;
  result: any;
}

export function calculatePropertyWithContext(contextRules: ContextRule[], context: any): any {
  return calculateProperty(
    contextRules.map((contextRule) => {
      return {
        valueA: getJsonByPath(context, contextRule.propertyName),
        valueB: contextRule.value,
        operator: contextRule.operator,
        result: contextRule.result
      };
    })
  );
}

export function calculateProperty(propertyRules: PropertyRule[]): any {
  for (const propertyRule of propertyRules) if (calculateBooleanEquation(propertyRule)) return propertyRule.result;
  return undefined;
}

export function calculateBooleanEquation(equation: BooleanEquation): boolean {
  switch (equation.operator) {
    case BooleanEquationOperator.EQUALS:
      return equation.valueA == equation.valueB;
    case BooleanEquationOperator.NOT_EQUALS:
      return equation.valueA != equation.valueB;
    case BooleanEquationOperator.GREATER:
      return equation.valueA > equation.valueB;
    case BooleanEquationOperator.GREATER_THAN:
      return equation.valueA >= equation.valueB;
    case BooleanEquationOperator.LESS:
      return equation.valueA < equation.valueB;
    case BooleanEquationOperator.LESS_THAN:
      return equation.valueA <= equation.valueB;
    default:
      throw new Error("unknown operator: " + equation.operator);
  }
}

export enum ArithmeticOperand {
  ADD = "add",
  SUBTRACT = "subtract",
  MULTIPLY = "multiply",
  DIVIDE = "divide"
}

export function calculateArithmeticEquation(a: number, operand: ArithmeticOperand, b: number): number {
  if (a === undefined || b === undefined || operand === undefined) return undefined;
  switch (operand) {
    case ArithmeticOperand.ADD:
      return new Decimal(a).add(b).toNumber();
    case ArithmeticOperand.SUBTRACT:
      return new Decimal(a).sub(b).toNumber();
    case ArithmeticOperand.MULTIPLY:
      return new Decimal(a).mul(b).toNumber();
    case ArithmeticOperand.DIVIDE:
      return b !== 0 ? new Decimal(a).div(b).toNumber() : 0;
    default:
      throw new Error("Unknown operand: " + operand);
  }
}
