<div class="grid">
  @for (entry of data().entries; track entry) {
    <div>
      <mat-card
        appearance="outlined"
        style="height: 100%"
        [ngStyle]="{
          backgroundColor: entry.backgroundColor,
          fontColor: entry.fontColor,
          boxShadow: data().shadow === false ? 'none' : 'inset'
        }"
        (click)="open(entry)"
        class="flex flex-col justify-center items-center"
      >
        <smallstack-icon [name]="entry.icon" size="64" [theme]="data().iconTheme"></smallstack-icon>
        <div>{{ entry.label }}</div>
      </mat-card>
    </div>
  }
</div>
