import { Component, ElementRef, HostListener, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { IconComponent } from "../icon/icon.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-info-box",
  templateUrl: "./info-box.component.html",
  styleUrls: ["./info-box.component.scss"],
  standalone: true,
  imports: [IconComponent]
})
export class InfoBoxComponent {
  @ViewChild("infoBox")
  public infoBox: ElementRef;

  private active = false;

  @HostListener("click")
  public clickInfoBoxAway(): void {
    if (!this.active) this.infoBox.nativeElement.style.display = "none";
    this.active = false;
  }

  public openInfoBox(): void {
    this.infoBox.nativeElement.style.display = "block";
    this.active = true;
  }

  public closeInfoBox(): void {
    this.infoBox.nativeElement.style.display = "none";
    this.active = false;
  }
}
