import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from "@angular/core";
import { WorklogDto } from "@smallstack/axios-api-client";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { MyWorklogStore } from "../../../stores/my-worklog.store";

@Widget({
  name: "WorklogTimer",
  icon: "stopwatch--v1",
  tags: AllWidgetTags,
  templateName: "Arbeitsprotokolle Stoppuhr",
  templateDescription: "Zeigt Stoppuhren für alle gestarteten Arbeitsprotokolle des aktuell angemeldeten Benutzers."
})
@Component({
  selector: "smallstack-worklog-timer-widget",
  templateUrl: "./worklog-timer-widget.component.html",
  styleUrls: ["./worklog-timer-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogTimerWidgetComponent extends BaseWidgetComponent {
  protected worklogStore = inject(MyWorklogStore);
  protected worklogs = computed<WorklogDto[]>(() =>
    this.worklogStore
      .myWorklogs()
      ?.filter((worklog) => worklog.startTime !== undefined && worklog.endTime === undefined)
  );
  protected maximized = signal(false);

  constructor() {
    super();
    effect(
      () => {
        const worklogs = this.worklogs();
        if (!worklogs || worklogs.length === 0) this.maximized.set(false);
      },
      { allowSignalWrites: true }
    );
  }

  protected async stopWorklog(worklog: WorklogDto): Promise<void> {
    worklog.endTime = Date.now();
    await this.worklogStore.updateWorklog(worklog);
    this.maximized.set(false);
  }

  protected closePanel(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.maximized.set(false);
  }
}
