/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { InnerTranslationDto } from './inner-translation-dto';

/**
 * 
 * @export
 * @interface NavigationEntryDto
 */
export interface NavigationEntryDto {
    /**
     * 
     * @type {string}
     * @memberof NavigationEntryDto
     */
    type: NavigationEntryDtoTypeEnum;
    /**
     * 
     * @type {Array<InnerTranslationDto>}
     * @memberof NavigationEntryDto
     */
    label?: Array<InnerTranslationDto>;
    /**
     * 
     * @type {string}
     * @memberof NavigationEntryDto
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof NavigationEntryDto
     */
    badge?: number;
    /**
     * 
     * @type {number}
     * @memberof NavigationEntryDto
     */
    index?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationEntryDto
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationEntryDto
     */
    path?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NavigationEntryDto
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NavigationEntryDto
     */
    requiredPermission?: string;
    /**
     * 
     * @type {any}
     * @memberof NavigationEntryDto
     */
    meta?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum NavigationEntryDtoTypeEnum {
    Item = 'item',
    Divider = 'divider'
}



