import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

const LANG_ENGLISH_ISO = "en";
const COUNTRY_GREAT_BRITAIN_ISO = "gb";
const COUNTRY_US_ISO = "us";

@Component({
  selector: "smallstack-flag",
  styleUrls: ["./flag.component.scss"],
  templateUrl: "./flag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class FlagComponent {
  public flag: string;
  public secondFlag: string;

  @Input()
  public set language(lang: string) {
    if (lang) this.flag = lang === LANG_ENGLISH_ISO ? COUNTRY_GREAT_BRITAIN_ISO : lang; // mapping needed since en is no valid country iso, hence we use gb instead to display the flag.
  }

  @Input()
  public set country(country: string) {
    if (country) this.flag = country;
  }

  @Input()
  public set locale(locale: string) {
    if (locale) {
      const [lang, country] = locale.split("_");
      if (lang === "en" && country === "us") this.country = COUNTRY_US_ISO;
      else this.language = lang;
      if (country !== "us" && lang !== country) this.secondFlag = country;
      else this.secondFlag = undefined;
    }
  }
}
