@if (loading() === true) {
  <div class="w-full h-full center-center select-none">
    <div class="loading loading-spinner text-{{ loadingColor() }} loading-{{ this.loadingSize() }}"></div>
    @if (loadingText()) {
      <smallstack-i18n
        [data]="loadingText()"
        class="text-{{ loadingSize() }} text-gray-500"
        style="font-variant: all-petite-caps"
      ></smallstack-i18n>
    }
  </div>
} @else {
  <ng-content></ng-content>
}
