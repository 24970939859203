@if (data().htmlTag && data().webComponentUrl) {
  @if (ready) {
    <ax-lazy-element
      *axLazyElementDynamic="
        data().htmlTag;
        url: data().webComponentUrl;
        loadingTemplate: loading;
        errorTemplate: error
      "
      [data]="context()"
    >
    </ax-lazy-element>
  }
  <ng-template #loading>Loading Web Component...</ng-template>
  <ng-template #error>Loading failed...</ng-template>
} @else {
  <i>Web Komponente ist noch nicht konfiguriert.</i>
}
