import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "Todo",
  templateName: "Aufgabe / Vorlage",
  icon: "task",
  templateDescription: "Zeigt eine Aufgabe oder Vorlage an.",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        type: "string",
        title: "Kontext Variable",
        description: "Geben Sie hier an, in welcher Kontext Variable sich die Aufgabe befindet."
      },
      displayAsTemplate: {
        type: "boolean",
        title: "Als Vorlage anzeigen"
      },
      editingEnabled: {
        type: "boolean",
        title: "Bearbeiten aktiviert",
        default: false
      },
      checkingEnabled: {
        type: "boolean",
        title: "Abhaken aktiviert",
        default: true
      },
      showTitle: {
        type: "boolean",
        title: "Zeige Aufgaben Titel",
        default: true
      },
      showDescription: {
        type: "boolean",
        title: "Zeige Aufgaben Beschreibung",
        default: true
      }
    }
  }
})
@Component({
  templateUrl: "./todo-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoWidgetComponent extends BaseWidgetComponent {
  protected todo = computed(() => this.getContextReplacedData("contextVariable"));
}
