import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "ReverseLinkedModels",
  templateName: "Fremde Verknüpfungen",
  templateDescription: "Zeigt Verknüpfungen an, welche andere Objekte mit dem aktuellen Objekt haben",
  tags: AllWidgetTags,
  dataSchema: {
    properties: {
      store: {
        type: "string",
        title: "Store",
        description: "Der Store, in welchem nach Objekten gesucht werden soll",
        "x-schema-form": {
          widget: "stores"
        }
      },
      foreignProperty: {
        title: "Eigenschaft",
        type: "string",
        description: "Die Eigenschaft, welche den Fremdschlüssel enthält"
      },
      valueContextName: {
        title: "Kontext Variable",
        type: "string",
        description:
          "Legen Sie hier fest, welche Eigenschaft des aktuellen Kontexts als Wert für die Fremdschlüsselsuche verwendet werden soll"
      },
      width: {
        title: "Breite",
        description: "Breite der angezeigten Verlinkungen",
        type: "string",
        enum: ["list", "small", "medium", "large"]
      },
      notFoundText: {
        title: "'Keine Links gefunden' Text",
        description: "Dieser Text wird angezeigt, falls keine Links gefunden wurden",
        type: "string",
        default: "Keine Verknüpfungen gefunden"
      }
    }
  },
  icon: "external-link",
  deprecated: true,
  deprecatedMessage: [
    { value: "Bitte verwenden Sie das neue Verlinkungswidget, welches beide Richtungen unterstützt!" }
  ]
})
@Component({
  selector: "smallstack-reverse-linked-models-widget",
  templateUrl: "./reverse-linked-models-widget.component.html",
  styleUrls: ["./reverse-linked-models-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReverseLinkedModelsWidgetComponent extends BaseWidgetComponent {}
