@if ((value$ | async)?.name) {
  <smallstack-widget-editor-renderer
    [widget]="value$ | async"
    (widgetChange)="setValue($event)"
    [additionalProviders]="additionalProviders"
  ></smallstack-widget-editor-renderer>
} @else {
  <smallstack-cms-page-add-widget
    (addWidget)="setValue($event)"
    style="zoom: 0.6"
    attr.data-test="schema-form-widget-add-new-widget-{{ path() }}"
  >
  </smallstack-cms-page-add-widget>
}
