import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { INTL_FORMATS, NamedIntlFormat, convertLocaleToIso } from "@smallstack/utils";
import { intlFormat, parseISO } from "date-fns";
import { Subscription } from "rxjs";
import { LocaleService } from "../../services/locale.service";

@Component({
  selector: "smallstack-date",
  templateUrl: "./date.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class DateComponent implements OnInit, OnDestroy {
  @Input()
  public set timestamp(timestamp: number | Date | string) {
    if (typeof timestamp === "string") timestamp = parseISO(timestamp);
    this.dateTimestamp = timestamp;
    this.init(this.localeService?.currentLocale$?.value);
  }

  @Input()
  public set format(format: NamedIntlFormat) {
    this.dateFormat = format;
    this.init(this.localeService?.currentLocale$?.value);
  }

  public formattedDate: string;
  private dateTimestamp: number | Date;
  private dateFormat: NamedIntlFormat;
  #format: NamedIntlFormat = "short";

  private subscription: Subscription = new Subscription();

  constructor(
    private localeService: LocaleService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.localeService.currentLocale$.subscribe((currentLocale) => {
        this.init(currentLocale);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private init(currentLocale: string): void {
    currentLocale = convertLocaleToIso(currentLocale);
    if (!this.dateFormat) this.dateFormat = this.#format;
    this.formattedDate =
      this.dateTimestamp && this.dateFormat && currentLocale
        ? intlFormat(this.dateTimestamp, INTL_FORMATS[this.dateFormat] || INTL_FORMATS.short, { locale: currentLocale })
        : "-";
    this.cdr.markForCheck();
  }
}
