import { Injectable } from "@angular/core";
import { BasePathService } from "@smallstack/common-components";

@Injectable({ providedIn: "root" })
export class MarkdownService {
  constructor(private basePathService: BasePathService) {}

  public async parseMarkdown(text: string): Promise<string> {
    const parseFn = await this.getParseFn();
    let parsedText = parseFn(text);
    parsedText = parsedText.replace(/href="(.*)"/g, `href="${this.basePathService.basePath$.value}$1"`);
    return parsedText;
  }

  public async getParseFn(): Promise<(text: string) => string> {
    const marked = await import("marked");
    return (text: string) => {
      let parsedText = marked.parse(text, { async: false }) as string;
      parsedText = parsedText.replace(/href="(.*)"/g, `href="${this.basePathService.basePath$.value}$1"`);
      return parsedText;
    };
  }
}
