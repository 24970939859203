@if (tableSchema()?.properties) {
  <table
    mat-table
    class="smallstack-schema-form-table"
    data-test="schema-form-table"
    [dataSource]="tableSchema().properties | keyvalue: originalOrder"
    [trackBy]="trackBy"
    [ngClass]="{ 'no-header': !showHeader }"
  >
    <ng-container matColumnDef="path">
      <th *matHeaderCellDef mat-header-cell> <smallstack-i18n data="@@components.table.field" /></th>
      <td *matCellDef="let element" mat-cell class="schema-form-table-field" [ngClass]="{ hide: compactMode() }">
        @if (!compactMode()) {
          <ng-template [ngTemplateOutlet]="fieldText" [ngTemplateOutletContext]="{ element }" />
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th *matHeaderCellDef mat-header-cell><smallstack-i18n data="@@components.table.value" /></th>
      <td
        *matCellDef="let element"
        mat-cell
        class="schema-form-table-value"
        data-test="form-table-value"
        [ngStyle]="{ padding: compactMode() ? '0px !important' : undefined }"
      >
        @if (element.value.type === "object" && compactMode()) {
          <ng-template [ngTemplateOutlet]="fieldText" [ngTemplateOutletContext]="{ element }" /> <br />
        }
        @if (element.key) {
          <smallstack-schema-form-widget
            attr.data-test="form-table-value-path-{{
              (schemaPath$ | async) ? (schemaPath$ | async) + '.' + element.key : element.key
            }}"
            [path]="(schemaPath$ | async) ? (schemaPath$ | async) + '.' + element.key : element.key"
            [options]="{ showLabel: compactMode(), showHint: compactMode() }"
          />
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd>
      <th *matHeaderCellDef mat-header-cell class="header-cell-actions">
        <smallstack-i18n data="@@nouns.actions" />
      </th>
      <td *matCellDef="let element" mat-cell class="cell-actions">
        <ng-container *ngTemplateOutlet="actions; context: { element: element }" />
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns" mat-row></tr>
  </table>
}

@if (showReset) {
  <div class="reset-button justify-end">
    <button data-test="schema-form-table-reset-button" class="btn btn-warning" (click)="resetForm()">
      <smallstack-i18n data="@@actions.reset" />
    </button>
  </div>
}
<ng-template #fieldText let-element="element">
  @if (element.key) {
    <smallstack-form-field-title
      data-test="form-field-title"
      [path]="(schemaPath$ | async) ? (schemaPath$ | async) + '.' + element.key : element.key"
      [showRequired]="true"
    />
  }

  @if (element?.value?.description) {
    @if ((formService.globalFormOptions$ | async)?.useInfoBox === true) {
      <smallstack-icon
        alias="info"
        size="12"
        class="upper-icon"
        color="a0a0a0"
        [matTooltip]="element.value.description | translate"
      />
    } @else {
      <div>
        <mat-hint class="text-muted">
          <smallstack-i18n [data]="element.value.description" />
        </mat-hint>
      </div>
    }
  }
</ng-template>
