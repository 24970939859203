import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconComponent } from "@smallstack/theme-components";
import { TypeSchema } from "@smallstack/typesystem";
import { FormOptions } from "../../interfaces/form-options";
import { FormFieldTitleComponent } from "../form-field-title/form-field-title.component";

@Component({
  selector: "smallstack-form-input-container",
  templateUrl: "./form-input-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormFieldTitleComponent, IconComponent, MatTooltipModule]
})
export class FormInputContainerComponent {
  public validationErrorText = input<string>(undefined);
  public options = input<FormOptions>(undefined);
  public schema = input<TypeSchema>(undefined);
  public path = input<string>(undefined);
}
