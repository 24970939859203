import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input, numberAttribute } from "@angular/core";

@Component({
  selector: "smallstack-skeleton",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./skeleton.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {
  public shape = input<"card-with-title" | "input" | "form" | "line">("card-with-title");
  public count = input<number, string>(1, { transform: numberAttribute });
  protected range = computed(() => Array(this.count()).fill(1));
}
