import { Page } from "@smallstack/api-shared";
import { getValueFromStringProviderSync, ONE_MINUTE, StringProvider } from "@smallstack/utils";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Configuration } from "./generated";
import { AxiosInterceptorService } from "@smallstack/core-common";

export interface ApiClientConfiguration {
  apiUrl: string;
  tenantId?: StringProvider;
  authTenantId?: StringProvider;
  resellerId?: StringProvider;
  token?: StringProvider;
  axiosConfig?: AxiosRequestConfig;
  axiosInstance?: AxiosInstance;
}

export class AxiosApiClient {
  constructor(public apiClientConfiguration: ApiClientConfiguration) {}

  /**
   * Loads all pages of an endpoint. Please provide a function that returns a @smallstack/core-common/Page
   */
  public static async loadAllPages<T>(apiCallFn: (page: number) => Promise<Page<T>>): Promise<T[]> {
    let page = 1;
    let responsePage: Page<T>;
    const models: T[] = [];
    do {
      responsePage = await apiCallFn(page);
      if (responsePage?.elements instanceof Array) models.push(...responsePage.elements);
      page++;
    } while (responsePage.hasNext);
    return models;
  }

  /**
   * Returns a new AxiosApiClient with an overwritten token. All other configuration options stay the same
   * @param token a jwt, access or service token
   */
  public withToken(token: string): AxiosApiClient {
    return new AxiosApiClient({ ...this.apiClientConfiguration, token });
  }

  /** Returns a new AxiosApiClient with an extended configuration. */
  public withConfiguration(extendedConfiguration: Partial<ApiClientConfiguration>): AxiosApiClient {
    return new AxiosApiClient({ ...this.apiClientConfiguration, ...extendedConfiguration });
  }

  /** Returns a new AxiosApiClient with an extended configuration based on a models meta data. */
  public withConfigFromContext(model: { context?: { tenantId?: string; resellerId?: string } }): AxiosApiClient {
    const newConfig: any = {};
    if (model?.context?.resellerId) newConfig.resellerId = model.context.resellerId;
    if (model.context.tenantId) newConfig.tenantId = model.context.tenantId;
    return new AxiosApiClient({
      ...this.apiClientConfiguration,
      ...newConfig
    });
  }

  public get<T>(
    Api: new (configuration: Configuration, basePath: string, axiosInstance: AxiosInstance) => T,
    axiosInstance?: AxiosInstance
  ): T {
    const configuration: Configuration = new Configuration({ basePath: this.apiClientConfiguration.apiUrl });
    let client: AxiosInstance = axiosInstance || this.apiClientConfiguration.axiosInstance;
    if (client === undefined)
      client = axios.create({
        ...this.apiClientConfiguration.axiosConfig,
        baseURL: this.apiClientConfiguration.apiUrl
      });
    AxiosInterceptorService.addInstance(client);
    if (client.defaults.baseURL === undefined) client.defaults.baseURL = this.apiClientConfiguration.apiUrl;
    client.interceptors.request.use(
      (config) => {
        if (this.apiClientConfiguration.tenantId)
          config.headers["x-tenant-id"] = getValueFromStringProviderSync(this.apiClientConfiguration.tenantId);
        if (this.apiClientConfiguration.authTenantId)
          config.headers["x-auth-tenant-id"] = getValueFromStringProviderSync(this.apiClientConfiguration.authTenantId);
        if (this.apiClientConfiguration.resellerId)
          config.headers["x-reseller-id"] = getValueFromStringProviderSync(this.apiClientConfiguration.resellerId);
        if (this.apiClientConfiguration.token)
          config.headers["Authorization"] =
            "Bearer " + getValueFromStringProviderSync(this.apiClientConfiguration.token);
        // filter null and undefined
        for (const key in config.headers)
          if (config.headers[key] === undefined || config.headers[key] === null) delete config.headers[key];
        config.timeout = ONE_MINUTE;
        config.signal = AxiosInterceptorService.getAbortSignal();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return new Api(configuration, configuration.basePath, client);
  }
}
