<div class="dialog">
  <div class="dialog-title">{{ contact | displayName }}</div>
  <div class="dialog-content">
    <div class="flex flex-col md:flex-row gap-3 md:divide-x w-full">
      @if (contact) {
        <div class="flex flex-col items-stretch">
          <div class="flex flex-col items-center">
            <div class="flex flex-row space-x-5 justify-around items-center">
              <smallstack-avatar class="m-2 mb-4 flex-auto" size="xl" [url]="contact?.avatarUrl"></smallstack-avatar>
              <ng-template #showIcon>
                @if ((contact.firstName | nonEmptystring) || (contact.lastName | nonEmptystring)) {
                  <smallstack-icon name="contact-card" size="128"></smallstack-icon>
                } @else {
                  <smallstack-icon name="organization" size="128"></smallstack-icon>
                }
              </ng-template>
            </div>
          </div>
          @if (showContact) {
            <div class="flex flex-col md:flex-row md:justify-between">
              <div class="contact-details">
                @if (contact?.title) {
                  <div class="detail-row flex flex-col justify-center">
                    <b> <smallstack-i18n data="@@components.crm.contacts.title"></smallstack-i18n></b>
                    {{ contact.title }}
                  </div>
                }
                @if (contact?.salutation) {
                  <div class="detail-row flex flex-col justify-center">
                    <b> <smallstack-i18n data="@@components.crm.contacts.salutation"></smallstack-i18n></b>
                    {{ contact.salutation }}
                  </div>
                }
                @if (contact?.birthday) {
                  <div class="detail-row flex flex-col justify-center">
                    <b> <smallstack-i18n data="@@components.crm.contacts.birthday"></smallstack-i18n></b>
                    <smallstack-date format="longDate" [timestamp]="contact?.birthday"></smallstack-date>
                  </div>
                }
                @if (contact?.address) {
                  <div class="detail-row flex flex-col justify-center">
                    <b><smallstack-i18n data="@@components.crm.contacts.address"></smallstack-i18n></b>
                    {{ contact.address.street }} {{ contact.address.streetNumber }}<br />
                    {{ contact.address.zip }} {{ contact.address.city }}
                  </div>
                }
                @if (contact?.email) {
                  <div class="detail-row flex flex-col justify-center">
                    <b><smallstack-i18n data="@@components.crm.contacts.email"></smallstack-i18n></b>
                    @if (contact?.email) {
                      <div class="flex flex-row flex-wrap space-x-3 justify-between items-center">
                        <span>{{ contact?.email }}</span>
                        <div class="flex flex-row justify-end items-center">
                          <a class="btn btn-action" href="mailto:{{ contact?.email }}">
                            <smallstack-icon name="send"></smallstack-icon>
                          </a>
                          <smallstack-copy-action-button
                            [cdkCopyToClipboard]="contact?.email"
                          ></smallstack-copy-action-button>
                        </div>
                      </div>
                    } @else {
                      <b>-</b>
                    }
                  </div>
                }
                @if (contact?.phone) {
                  <div class="detail-row flex flex-col justify-center">
                    <b><smallstack-i18n data="@@components.crm.contacts.phone"></smallstack-i18n></b>
                    @if (contact?.phone) {
                      <div class="flex flex-row flex-wrap space-x-3 justify-between items-center">
                        <span> {{ contact?.phone }}</span>
                        <div class="flex flex-row justify-end items-center">
                          <a class="btn btn-action" href="tel:{{ contact?.phone }}">
                            <smallstack-icon name="phone"></smallstack-icon>
                          </a>
                          <smallstack-copy-action-button
                            [cdkCopyToClipboard]="contact?.phone"
                          ></smallstack-copy-action-button>
                        </div>
                      </div>
                    } @else {
                      <b>-</b>
                    }
                  </div>
                }
                @if (contact?.mobile) {
                  <div class="detail-row flex flex-col justify-center">
                    <b> <smallstack-i18n data="@@components.crm.contacts.mobile"></smallstack-i18n> </b>
                    @if (contact?.mobile) {
                      <div class="flex flex-row space-x-3 justify-between items-center">
                        <span> {{ contact?.mobile }}</span>
                        <div class="flex flex-row justify-end items-center">
                          <a class="btn btn-action" href="tel:{{ contact?.mobile }}">
                            <smallstack-icon name="phone"></smallstack-icon>
                          </a>
                          <smallstack-copy-action-button
                            [cdkCopyToClipboard]="contact?.mobile"
                          ></smallstack-copy-action-button>
                        </div>
                      </div>
                    } @else {
                      <b>-</b>
                    }
                  </div>
                }
                @if (contact?.company) {
                  <div class="detail-row flex flex-col justify-center">
                    <b>Firmenname</b>
                    @if (contact?.company) {
                      <div class="flex flex-row space-x-3 items-center">
                        {{ contact?.company }}
                      </div>
                    } @else {
                      <b>-</b>
                    }
                  </div>
                }
                @if (contact?.companyAddress) {
                  <div class="detail-row flex flex-col justify-center">
                    <b>Firmenadresse</b>
                    @if (contact?.companyAddress) {
                      <div class="flex flex-row space-x-3 items-center">
                        {{ contact.companyAddress.street }} {{ contact.companyAddress.streetNumber }}<br />
                        {{ contact.companyAddress.zip }} {{ contact.companyAddress.city }}
                      </div>
                    } @else {
                      <b>-</b>
                    }
                  </div>
                }
                @if (contactCustomFieldStore.value$ | async; as allCustomFields) {
                  @if (allCustomFields.length > 0) {
                    <b>Benutzerdefinierte Felder</b>
                  }
                  @for (customField of allCustomFields; track customField) {
                    <div class="detail-row">
                      @if (contactCustomFieldIds?.includes(customField.id)) {
                        @for (contactCustomField of contact.customFields; track contactCustomField) {
                          @if (contactCustomField.customFieldId === customField.id) {
                            <div style="width: 100%" class="flex flex-row justify-between items-center">
                              <smallstack-i18n [data]="customField.title"></smallstack-i18n>
                              @switch ($any(customField?.type)) {
                                @case (FilterDataType.BOOLEAN) {
                                  <smallstack-icon
                                    [alias]="contactCustomField.value === 'true' ? 'checked-checkbox' : 'close-window'"
                                    size="24"
                                    style="top: 4px; left: 4px"
                                  ></smallstack-icon>
                                }
                                @case (FilterDataType.DATE) {
                                  <div>
                                    <smallstack-date
                                      format="longDate"
                                      [timestamp]="contactCustomField.value"
                                    ></smallstack-date
                                  ></div>
                                }
                                @default {
                                  <div>{{ contactCustomField.value || "-" }}</div>
                                }
                              }
                            </div>
                          }
                        }
                      } @else {
                        <div style="width: 100%" class="flex flex-row justify-between items-center">
                          <smallstack-i18n [data]="customField.title"></smallstack-i18n>
                          @switch ($any(customField?.type)) {
                            @case (FilterDataType.BOOLEAN) {
                              <smallstack-icon
                                alias="close-window"
                                size="24"
                                style="top: 4px; left: 4px"
                              ></smallstack-icon>
                            }
                            @default {
                              <b>-</b>
                            }
                          }
                        </div>
                      }
                    </div>
                  }
                }
              </div>
            </div>
          }
          <smallstack-extension-slot
            name="contact-details-bottom"
            [context]="{ contact: contact }"
          ></smallstack-extension-slot>
        </div>
      }
      <div class="contact-notes">
        <smallstack-extension-slot
          name="contact-details-notes-right"
          [context]="{ contact: contact }"
        ></smallstack-extension-slot>
        <smallstack-extension-slot
          name="contact-details-notes-top"
          [context]="{ contact: contact }"
          [deprecated]="true"
        ></smallstack-extension-slot>
        <smallstack-extension-slot
          name="contact-details-notes-bottom"
          [context]="{ contact: contact }"
          [deprecated]="true"
        ></smallstack-extension-slot>
      </div>
    </div>
  </div>
  <div class="dialog-actions justify-end">
    <button (click)="close()" class="btn btn-primary">
      <smallstack-i18n data="@@actions.close"></smallstack-i18n>
    </button>
  </div>
</div>
