import { Injectable, computed } from "@angular/core";
import { injectQueryClient } from "@tanstack/angular-query-experimental";

export enum SyncStatus {
  syncing = "syncing",
  hasErrors = "hasErrors",
  ready = "ready"
}

@Injectable({ providedIn: "root" })
export class SyncStatusService {
  private client = injectQueryClient();

  public syncingCount = computed(() => {
    return this.client.isFetching();
  });

  public isSyncing = computed(() => this.syncingCount() > 0);

  public syncingTypes = computed(() => {
    return this.client
      .getQueryCache()
      .findAll()
      .filter((query) => query.state.status === "pending")
      .map((query) => query.queryKey.join("/"));
  });
}
