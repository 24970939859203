import { HSL } from "color-convert/conversions";

export function convertHslToHslString(hsl: HSL): string {
  return `${hsl[0]} ${hsl[1]}% ${hsl[2]}%`;
}

export function convertHslStringToHsl(hslString: string): HSL {
  const split = hslString.split(" ");
  return [parseFloat(split[0]), parseFloat(split[1]), parseFloat(split[2])];
}
