import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterUtilService } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { IconComponent } from "@smallstack/theme-components";

@Component({
  selector: "smallstack-icon-card",
  templateUrl: "./icon-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, I18nComponent]
})
export class IconCardComponent {
  @Input()
  public icon: string;

  @Input()
  public title: string | InlineTranslation;

  @Input()
  public subTitle: string | InlineTranslation;

  @Input()
  public height: string;

  @Input()
  public link: string;

  constructor(private routerUtilService: RouterUtilService) {}

  protected followLink(): void {
    if (this.link) void this.routerUtilService.navigateFullUrl(this.link);
  }
}
