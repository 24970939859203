import { Injectable } from "@angular/core";
import { Logger } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { JSONSchema7 } from "json-schema";
import { SchemaFormBaseWidget } from "../components/schema-form-base/schema-form-base-widget.component";
import { SchemaFormBase } from "../components/schema-form-base/schema-form-base.component";

export type RegistryFormInput = typeof SchemaFormBase | typeof SchemaFormBaseWidget | unknown; // unknown because of classes with own constructors

/**
 * @deprecated use WidgetRegistry and new FormInputWidgets/FilterInputWidgets instead
 *
 * Holds UI Widget Representations used by the Schema Form to render JSON Schema Forms
 */
@Injectable({ providedIn: "root" })
export class SchemaFormInputsRegistry {
  private registry: { [name: string]: RegistryFormInput } = {};

  /**
   * Adds (or overwrites) a widget for the given name
   * @param name can be a primitive json schema type like boolean, number etc. or a custom one like 'my-widget'. You can explicitly set the widget in your schema via `schema.x-schema-form.widget` or let this registry try to find the correct one
   * @param component An angular component based on the {@link SchemaFormBaseWidget} classs
   */
  public addWidget(name: string, component: RegistryFormInput): void {
    this.registry[name] = component;
  }

  public getWidgetByName(name: string): RegistryFormInput {
    // rewrite new names to old names

    if (this.registry[name]) return this.registry[name];
    Logger.debug("SchemaFormWidgetsRegistry", "No widget found for name: " + name);
  }

  /**
   * This method determines, based on the schema, which widget to use
   */
  public getWidgetBySchema(schema: SchemaFormSchema): RegistryFormInput {
    if (!schema) return undefined;

    // if widget is explicitly set, use that one
    if (schema["x-schema-form"]?.widget) return this.getWidgetByName(schema["x-schema-form"].widget);
    else if (schema["x-schema-form"]?.inputWidget) return this.getWidgetByName(schema["x-schema-form"].inputWidget);
    else if (schema["x-schema-form"]?.type) return this.getWidgetByName(schema["x-schema-form"].type);

    // string enum should be a string select
    if (schema.enum !== undefined && (schema.type === "string" || schema.type === undefined))
      return this.getWidgetByName("Select");

    // string array enum should be a multi string select
    if (
      schema.type === "array" &&
      (schema.items as JSONSchema7)?.type === "string" &&
      (schema.items as JSONSchema7)?.enum instanceof Array
    )
      return this.getWidgetByName("multistringselect");

    // try schema.type
    return this.getWidgetByName(schema.type as string);
  }
}
