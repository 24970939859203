<div class="dialog">
  <div class="dialog-title" data-test="notification-dialog-title">
    <smallstack-i18n [data]="data.title"></smallstack-i18n>
  </div>
  <div class="dialog-content p-4" data-test="notification-dialog-message">
    <smallstack-i18n data-test="notifications-dialog-message" [data]="data.message"></smallstack-i18n>
  </div>
  <div class="dialog-actions" data-test="notification-dialog-actions">
    @for (button of data.buttons; track button) {
      <button
        class="btn"
        [class]="button.classes"
        (click)="dialogRef.close(button.value)"
        data-test="notification-dialog-btn"
      >
        <smallstack-i18n [data]="button.label" [showMissingKey]="true"></smallstack-i18n>
      </button>
    }
  </div>
</div>
