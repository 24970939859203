import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  computed,
  signal
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DialogPopupButtons, I18nComponent, NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { DataType, TypeDescriptor, TypeSchema } from "@smallstack/typesystem";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
import { TypeEditorComponent } from "../type-editor/type-editor.component";

export interface TypeEditorDialogData {
  schema: TypeSchema;
  title: string;
  subTitle?: string;
  model?: any;
  /** deprecated, use typeDescriptor */
  type?: string;
  typeDescriptor?: TypeDescriptor;
  // saveFn?: (newData: any) => Promise<void>;
  icon?: string;
  showDeleteButton?: boolean;
}

@Component({
  selector: "smallstack-type-editor-dialog",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./type-editor-dialog.component.html",
  standalone: true,
  imports: [MatDialogModule, TypeEditorComponent, I18nComponent, LoadingElementDirective, IconComponent]
})
export class TypeEditorDialogComponent {
  @ViewChild(TypeEditorComponent)
  private typeEditor: TypeEditorComponent;

  protected icon: string;
  private type = signal<DataType>(undefined);
  private typePath = computed(() => this.type()?.path);

  protected store = injectStore({ typePath: this.typePath });

  constructor(
    public dialogRef: MatDialogRef<TypeEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TypeEditorDialogData,
    private typeService: TypeService,
    private notificationService: NotificationService,
    private translationStore: TranslationStore,
    cdr: ChangeDetectorRef
  ) {
    if (data) {
      if (data?.model === undefined) data.model = {};
      const typeName = data.type ? data.type : data.typeDescriptor?.typePath;
      if (typeName)
        void typeService.getTypeByPath(typeName).then((type) => {
          if (data.schema === undefined && data.typeDescriptor?.typeVariantName)
            data.schema = type.variants.find((variant) => variant.name === data.typeDescriptor?.typeVariantName).schema;
          this.type.set(type);
          if (Object.prototype.hasOwnProperty.call(data, "icon")) this.icon = data.icon;
          else this.icon = type.icon;
          cdr.markForCheck();
        });
    }
  }

  protected validateAndSave() {
    return async (): Promise<void> => {
      // avoids hitting the save button before ngModelChange even had the chance to finish, see https://gitlab.com/smallstack/products/cloud/-/issues/1904
      return new Promise<void>((resolve) => {
        setTimeout(async () => {
          try {
            const model = await this.typeEditor.validateAndSave();
            if (model) this.dialogRef.close(model);
          } catch (e) {
            this.notificationService.popup.error("Fehler beim Speichern", e);
            throw e;
          }
          resolve();
        }, 500);
      });
    };
  }

  protected remove() {
    return async (): Promise<void> => {
      if (this.store) {
        const answer = await this.notificationService.popup.confirmation(
          "@@types." + this.typePath() + ".singular löschen",
          "Möchten Sie '" +
            this.translationStore.translate(this.typeService.getRepresentation(this.type(), this.data.model)) +
            "' wirklich löschen?",
          DialogPopupButtons.yesNo
        );
        if (answer === true) {
          await this.store.deleteById(this.data.model?.id);
          this.dialogRef.close();
        }
      }
    };
  }
}
