import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  computed,
  signal
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CompoundWidgetDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { Model, TYPE_COMPOUND_WIDGETS, TypeDescriptor, TypeSchema } from "@smallstack/typesystem";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
import { SetSchemaOptions } from "../../services/form.service";
import { FormComponent } from "../form/form.component";
import { TabbedSchemaFormTableComponent } from "../tabbed-schema-form-table/tabbed-schema-form-table.component";
import { WidgetTreeComponent } from "../widget-tree/widget-tree.component";

// export interface TypeEditorConfiguration {
//   typePath: string;
//   model?: any;
// }

@Component({
  templateUrl: "./type-editor.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-type-editor",
  standalone: true,
  imports: [WidgetTreeComponent, FormComponent, TabbedSchemaFormTableComponent]
})
export class TypeEditorComponent {
  private compoundWidgetStore = injectStore<CompoundWidgetDto>({ typePath: TYPE_COMPOUND_WIDGETS });

  @ViewChild("form")
  public form: FormComponent;

  @ViewChild(WidgetTreeComponent)
  protected widgetTreeComponent: WidgetTreeComponent;

  /** @deprecated use typeDescriptor input */
  @Input()
  public set type(type: string) {
    if (type) void this.#initEditor({ typePath: type });
  }

  @Input()
  public set typeDescriptor(typeDescriptor: TypeDescriptor) {
    if (typeDescriptor) void this.#initEditor(typeDescriptor);
  }

  @Input()
  public model: Model;

  @Input()
  public schemaOverride: TypeSchema;

  @Input()
  public dialogRef: MatDialogRef<any>;

  @Output()
  public readonly modelChange: EventEmitter<any> = new EventEmitter();

  protected isLoading = signal(false);
  protected schemaWithOptions: { schema: TypeSchema; options: SetSchemaOptions } = {
    options: { useDefaultsAsValue: true },
    schema: undefined
  };
  protected compoundWidgetId = signal<string>(undefined);
  protected compoundWidget = computed<CompoundWidgetDto>(() => {
    const compoundWidgetId = this.compoundWidgetId();
    if (!compoundWidgetId) return undefined;
    return this.compoundWidgetStore.getById(compoundWidgetId);
  });

  protected showFallbackForm = false;

  protected store = injectStore({ typePath: computed(() => this.#typeDescriptor()?.typePath) });

  #typeDescriptor = signal<TypeDescriptor>(undefined);

  constructor(private typeService: TypeService) {}

  public async validate(): Promise<boolean> {
    if (this.showFallbackForm) {
      await this.form.validateForm()();
      return this.form.formService.isValid();
    } else {
      // shall return Array<boolean>
      const result = await this.widgetTreeComponent.fireSocketEvent("root", "validate", undefined);
      if (result === undefined) return false;
      else if (result instanceof Array)
        return (
          result.find((r) => {
            if (typeof r === "boolean") return r !== true;
            return r !== undefined;
          }) === undefined
        );
      else return false;
    }
  }

  public async validateAndSave(): Promise<Model> {
    const validationResult = await this.validate();
    if (validationResult === true) {
      if (this.showFallbackForm) {
        this.model = await this.store.createOrUpdate(this.model);
      } else {
        const socketResponse = await this.widgetTreeComponent.fireSocketEvent("root", "save", undefined);
        if (socketResponse instanceof Array) this.model = socketResponse[0];
      }
      return this.model;
    } else Logger.error("TypeEditorComponent", "Validation result:" + JSON.stringify(validationResult));
  }

  async #initEditor(typeDescriptor: TypeDescriptor): Promise<void> {
    this.isLoading.set(true);
    this.#typeDescriptor.set(typeDescriptor);
    await this.typeService.awaitLoaded();
    const type = this.typeService.getByPath(this.#typeDescriptor().typePath);
    if (!type) throw new Error("no type found by path: " + this.#typeDescriptor().typePath);
    if (type?.views?.find((view) => view.name === "editor")) {
      const editorView = type.views?.find((view) => view.name === "editor");
      if (editorView) {
        this.compoundWidgetId.set(editorView.compoundWidgetId);
      } else await this.#useFallbackForm(this.#typeDescriptor().typePath);
    } else await this.#useFallbackForm(this.#typeDescriptor().typePath);
    this.isLoading.set(false);
  }

  async #useFallbackForm(typePath: string): Promise<void> {
    if (this.schemaOverride) this.schemaWithOptions.schema = this.schemaOverride;
    else this.schemaWithOptions.schema = await this.typeService.getSchemaByName(typePath);
    if (!this.schemaWithOptions.schema) throw new Error("no schema found by path: " + typePath);
    this.showFallbackForm = true;
  }
}
