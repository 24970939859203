@if (availableTypes$ | async; as availableTypes) {
  @if (availableTypes.length > 0) {
    <select class="select select-bordered" [ngModel]="typePath" (ngModelChange)="selectType($event)">
      @for (optionType of availableTypes | sort: "name"; track "path") {
        <option [value]="optionType.path"
          ><smallstack-i18n
            [data]="usePlural && optionType.titlePlural ? optionType.titlePlural : optionType.title || optionType.path"
          ></smallstack-i18n
        ></option>
      }
    </select>
  } @else {
    <div class="component-loading"> </div>
  }
}
