import { Crud } from "@smallstack/api-shared";
import { JsonPatchDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { MutexLibrary } from "synchronized-ts";
import { StoreIdentifierType } from "./object-store";
import { PageableStore, PageableStoreOptions } from "./pageable-store";

export interface PageableCrudStoreOptions extends PageableStoreOptions {
  loadOnDeletion?: boolean;
}

export enum PageableCrudStoreHooks {
  BEFORE_CREATE = "beforeCreate",
  AFTER_CREATE = "afterCreate",
  BEFORE_PUT = "beforePut",
  AFTER_PUT = "afterPut",
  BEFORE_PATCH = "beforePatch",
  AFTER_PATCH = "afterPatch",
  BEFORE_DELETE = "beforeDelete",
  AFTER_DELETE = "afterDelete"
}

const synchronizeGetById = new MutexLibrary<any>();

export abstract class PageableCrudStore<
    ModelClass extends { id?: StoreIdentifierType } = any,
    CreationModelClass = Omit<ModelClass, "id" | "context" | "createdAt" | "lastUpdatedAt">
  >
  extends PageableStore<ModelClass>
  implements Crud<ModelClass, CreationModelClass>
{
  constructor(protected override options: PageableCrudStoreOptions = { loadOnDeletion: true }) {
    super(options);
  }

  /**
   * Either returns a copy of the model from store or, if not found, from http
   */
  public override async get(id: StoreIdentifierType, forceHttp = false): Promise<ModelClass> {
    if (id === undefined) {
      throw new Error("id cannot be undefined");
    }
    return synchronizeGetById.by(id as any).sync(async () => {
      try {
        let modelById: ModelClass = this.getById(id); // get local value
        if (modelById && !forceHttp) return modelById;
        modelById = await this.loadModelById(id);
        if (modelById) this.addValue(modelById);
        return modelById;
      } catch (e) {
        Logger.error("PageableCrudStore", "Error while getting model by id: " + id, e);
      }
    });
  }

  /**
   * Creates the model via http and adds it to the store (at the end)
   */
  public async create(model: CreationModelClass): Promise<ModelClass> {
    this.executeHooks(PageableCrudStoreHooks.BEFORE_CREATE, model);
    try {
      const m = await this.createModel(model);
      this.addValue(m);
      this.executeHooks(PageableCrudStoreHooks.AFTER_CREATE, m);
      return m;
    } catch (e) {
      this.setError(e);
      throw e;
    }
  }

  /**
   * Deletes the model via http and then from the store
   */
  public async delete(id: StoreIdentifierType): Promise<void> {
    this.executeHooks(PageableCrudStoreHooks.BEFORE_DELETE, id);
    try {
      await this.deleteModelById(id);
      this.updateValue((value) => {
        value = value?.filter((v) => v.id !== id);
        const currentPageIds = this.currentPageIds$.value;
        this.currentPageIds$.next(currentPageIds.filter((cpi) => cpi !== id));
        return value;
      });
      if (this.options.loadOnDeletion) await this.load();
      this.executeHooks(PageableCrudStoreHooks.AFTER_DELETE, id);
    } catch (e) {
      this.setError(e);
      throw e;
    }
  }

  /**
   * Deletes many models via http and then from the store
   */
  public async deleteMany(ids: StoreIdentifierType[]): Promise<void> {
    for (const id of ids) this.executeHooks(PageableCrudStoreHooks.BEFORE_DELETE, id);
    try {
      await this.deleteModelsByIds(ids);
      this.updateValue((value) => {
        value = value?.filter((v) => !ids.includes(v.id));
        const currentPageIds = this.currentPageIds$.value;
        this.currentPageIds$.next(currentPageIds.filter((cpi) => !ids.includes(cpi)));
        return value;
      });
      if (this.options.loadOnDeletion) await this.load();
      for (const id of ids) await this.executeHooks(PageableCrudStoreHooks.AFTER_DELETE, id);
    } catch (e) {
      this.setError(e);
      throw e;
    }
  }

  /**
   * Patches the model via http and in store
   */
  public async patch(id: StoreIdentifierType, model: Partial<ModelClass> | Array<JsonPatchDto>): Promise<ModelClass> {
    this.executeHooks(PageableCrudStoreHooks.BEFORE_PATCH, id, model);
    try {
      const m = await this.patchModel(id, model);
      this.updateValue((value) => {
        if (!value) value = [];
        const index = value.findIndex((v) => v.id === id);
        if (index !== -1) value[index] = m;
        else value.push(m);
        return value;
      });
      this.executeHooks(PageableCrudStoreHooks.AFTER_PATCH, id, m);
      return m;
    } catch (e) {
      this.setError(e);
      throw e;
    }
  }

  /**
   * Replaces the model via http and in store
   */
  public async put(model: ModelClass): Promise<ModelClass> {
    await this.executeHooks(PageableCrudStoreHooks.BEFORE_PUT, model);
    try {
      const m = await this.putModel(model);
      this.updateValue((value) => {
        if (!value) value = [];
        const index = value.findIndex((v) => v.id === model.id);
        if (index !== -1) value[index] = m;
        else value.push(m);
        return value;
      });
      await this.executeHooks(PageableCrudStoreHooks.AFTER_PUT, m);
      return m;
    } catch (e) {
      this.setError(e);
      throw e;
    }
  }

  /**
   * If model has an id, it will get put, otherwise created
   */
  public async createOrPut(model: ModelClass | CreationModelClass): Promise<ModelClass> {
    if ((model as ModelClass).id) return this.put(model as ModelClass);
    else return this.create(model as CreationModelClass);
  }

  // Have to get implemented by the store itself
  protected abstract loadModelById(id: StoreIdentifierType): Promise<ModelClass>;
  protected abstract deleteModelById(id: StoreIdentifierType): Promise<void>;
  protected abstract deleteModelsByIds(ids: StoreIdentifierType[]): Promise<void>;
  protected abstract createModel(model: CreationModelClass): Promise<ModelClass>;
  protected abstract patchModel(id: StoreIdentifierType, model: Partial<ModelClass> | any): Promise<ModelClass>;
  protected abstract putModel(model: ModelClass): Promise<ModelClass>;
}
