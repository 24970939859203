import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CreateNoteDto, NoteDto, NotesApi, SearchRequestDto } from "@smallstack/axios-api-client";
import { PageableCrudStore, SearchSupport } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class NoteStore extends PageableCrudStore<NoteDto, CreateNoteDto> implements SearchSupport {
  constructor(private axiosApiClient: AxiosApiClient) {
    super({ loadOnDeletion: true });
  }

  public async performSearch(searchRequest: SearchRequestDto): Promise<Page<NoteDto>> {
    return this.axiosApiClient
      .get(NotesApi)
      .performNoteSearch({ searchRequest })
      .then((res) => res.data);
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<NoteDto>> {
    const res = await this.axiosApiClient.get(NotesApi).getNotes(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<NoteDto> {
    const res = await this.axiosApiClient.get(NotesApi).getNote({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(NotesApi).deleteNote({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(NotesApi).deleteManyNotes({ ids });
    return res.data;
  }
  protected async createModel(createNote: CreateNoteDto): Promise<NoteDto> {
    const res = await this.axiosApiClient.get(NotesApi).createNote({ createNote });
    return res.data;
  }
  protected async patchModel(id: string, note: Partial<NoteDto>): Promise<NoteDto> {
    const res = await this.axiosApiClient.get(NotesApi).patchNote({ note, id });
    return res.data;
  }
  protected async putModel(note: NoteDto): Promise<NoteDto> {
    const res = await this.axiosApiClient.get(NotesApi).putNote({ note, id: note.id });
    return res.data;
  }
}
