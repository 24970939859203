@if (buttonConfiguration$ | async; as buttonConfiguration) {
  <button
    [loadingFn]="buttonClick()"
    [class]="classes$ | async"
    [matTooltip]="buttonConfiguration.btnTooltip"
    [matTooltipDisabled]="!buttonConfiguration.btnTooltip"
    [matTooltipShowDelay]="1000"
    [attr.data-testid]="'button-' + (buttonConfiguration.btnText || buttonConfiguration.btnIcon | urlconform)"
  >
    @if (buttonConfiguration.btnIcon) {
      <smallstack-icon [name]="buttonConfiguration.btnIcon"></smallstack-icon>
    }
    @if (buttonConfiguration.btnText) {
      <smallstack-i18n [data]="buttonConfiguration.btnText"></smallstack-i18n>
    }
  </button>
}
