import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, SearchRequestDto, TenantDataApi } from "@smallstack/axios-api-client";
import { IOC } from "@smallstack/core-common";
import { PageableCrudStore, SearchSupport } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class DataStore {
  protected cachedStores: { [featureType: string]: PageableCrudStore } = {};

  constructor(private axiosApiClient: AxiosApiClient) {
    IOC.register("dataStore", this);
  }

  public for<T = any>(featureName: string, typePath: string): PageableCrudStore<T> {
    const storeName = this.getStoreName(featureName, typePath);
    if (this.cachedStores[storeName]) return this.cachedStores[storeName];
    return (this.cachedStores[storeName] = new DataStoreInternal(this.axiosApiClient, featureName, typePath));
  }

  private getStoreName(featureName: string, typename: string): string {
    return featureName + "_" + typename;
  }
}

class DataStoreInternal extends PageableCrudStore implements SearchSupport {
  constructor(
    private axiosApiClient: AxiosApiClient,
    private featureName: string,
    private typePath: string
  ) {
    super();
  }
  public async performSearch(searchRequest: SearchRequestDto): Promise<Page<any>> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .performSearch({ feature: this.featureName, type: this.typePath, searchRequest });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<any>> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .getData({ feature: this.featureName, type: this.typePath, ...query });
    return res.data;
  }
  protected async loadModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .getDataById({ id, feature: this.featureName, type: this.typePath });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .deleteData({ id, feature: this.featureName, type: this.typePath });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .deleteManyData({ ids, feature: this.featureName, type: this.typePath });
    return res.data;
  }
  protected async createModel(model: any): Promise<any> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .createData({ anyBody: model, feature: this.featureName, type: this.typePath });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<any>): Promise<any> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .patchData({ id, anyBody: model, feature: this.featureName, type: this.typePath });
    return res.data;
  }
  protected async putModel(model: any): Promise<any> {
    const res = await this.axiosApiClient
      .get(TenantDataApi)
      .putData({ id: model.id, anyBody: model, feature: this.featureName, type: this.typePath });
    return res.data;
  }
}
