import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { InlineTranslation, isInlineTranslation } from "@smallstack/i18n-shared";
import { StoreState } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { cloneObject } from "@smallstack/utils";
import { Subscription, combineLatest } from "rxjs";
import { first, tap } from "rxjs/operators";
import { LocaleService } from "../../services/locale.service";
import { NotificationService } from "../../services/notification.service";
import { currentEditorLocale$ } from "../../stores/current-editor-locale.store";
import { LocaleStore } from "../../stores/locale.store";
import { FlagComponent } from "../flag/flag.component";
import { I18nComponent } from "../i18n/i18n.component";

@Component({
  selector: "smallstack-inline-translation-editor,inline-translation-editor",
  templateUrl: "./inline-translation-editor.component.html",
  styleUrls: ["./inline-translation-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    I18nComponent,
    FormsModule,
    ReactiveFormsModule,
    FlagComponent,
    IconComponent,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class InlineTranslationEditorComponent implements OnInit, OnDestroy {
  // @ViewChildren(MatFormField) formFields;

  /**
   * can be used to (temporarily) disabled the translation icon
   */
  @Input()
  public disableConfigBtn: boolean = false;

  @Input("translation")
  public set translationInput(translationInput: string | InlineTranslation) {
    if (typeof translationInput === "string") {
      this.translation = [{ value: translationInput }];
      this.translationChange.emit(this.translation);
    } else if (isInlineTranslation(translationInput)) this.translation = translationInput;
    else this.translation = [{ value: "" }];
    if (this.translation instanceof Array)
      this.translation = this.translation.map((t) => {
        if (!t.locale) t.locale = undefined;
        return t;
      });
  }

  public translation: InlineTranslation;

  @Input()
  public label: string | InlineTranslation;

  @Input()
  public hint: string | InlineTranslation;

  @Input()
  public error: string;

  @Input()
  public errorStateMatcher: ErrorStateMatcher;

  @Input()
  public textarea: boolean = false;

  @Input()
  public placeholder?: string;

  @Output()
  public readonly translationChange: EventEmitter<InlineTranslation> = new EventEmitter();

  public locales: string[];
  public currentLocaleIndex: number = 0;
  public currentEditorLocale: string;

  protected subscription: Subscription = new Subscription();

  constructor(
    protected localeStore: LocaleStore,
    protected localeService: LocaleService,
    protected cdr: ChangeDetectorRef,
    protected notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    if (this.translation === undefined) this.translation = [];
    this.subscription.add(
      combineLatest([currentEditorLocale$.value$, this.localeStore.value$])
        .pipe(
          tap(([currentEditorLocale, locales]) => {
            this.currentEditorLocale = currentEditorLocale;
            if (locales) {
              this.locales = locales.map((l) => l.iso);
              if (this.translation instanceof Array) {
                this.currentLocaleIndex = this.translation.findIndex((t) => t.locale === this.currentEditorLocale);
                if (this.currentLocaleIndex === -1) {
                  if (this.isTranslatedField())
                    // goto next free one
                    this.currentLocaleIndex = this.translation.length;
                  else this.currentLocaleIndex = 0;
                }
              }
            }
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );
    void this.loadLocales();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public hasContent(locale: string): boolean {
    if (this.translation instanceof Array) {
      const content = this.translation?.find((c) => c.locale === locale);
      if (!content || !content.value) return false;
      return content.value.replace(/\s+/g, "").length > 0;
    }
  }

  public valueChanged(value: string): void {
    if (this.translation) {
      const valueLocale: string = this.isTranslatedField() ? this.currentEditorLocale : undefined;
      const index = this.translation.findIndex((it) => it.locale === valueLocale);
      if (index !== -1) this.translation[index].value = value;
      else {
        this.translation.push({ locale: valueLocale, value });
      }
      this.translationChange.emit(cloneObject(this.translation));
    }
  }

  public setCurrentEditorLocale(locale: string): void {
    currentEditorLocale$.setValue(locale);
  }

  public async toggleTranslationState(): Promise<void> {
    if (this.isTranslatedField()) {
      this.notificationService.popup.confirmation(
        "@@components.inlinetranslation.replacement.title",
        "@@components.inlinetranslation.replacement.message",
        [
          { label: "@@actions.no", color: "primary", value: false },
          { label: "@@actions.yes", color: "warn", value: true }
        ],
        (answer: boolean) => {
          if (answer === true) {
            this.translation = [{ locale: undefined, value: this.translation[this.currentLocaleIndex]?.value }];
            this.currentLocaleIndex = 0;
            this.translationChange.emit(cloneObject(this.translation));
            this.cdr.markForCheck();
          }
        }
      );
    } else {
      if (this.currentEditorLocale === undefined) this.currentEditorLocale = this.localeService.getDefaultLocale();
      this.setCurrentEditorLocale(this.currentEditorLocale);
      const currentValue: string = this.translation[0]?.value;
      this.translation = [{ locale: this.currentEditorLocale, value: currentValue }];
      this.currentLocaleIndex = this.translation.findIndex((t) => t.locale === this.currentEditorLocale);
      if (this.currentLocaleIndex === -1) this.currentLocaleIndex = this.translation.length;
      this.translationChange.emit(cloneObject(this.translation));
      this.cdr.markForCheck();
    }
  }

  public isTranslatedField(): boolean {
    return (
      this.translation instanceof Array &&
      this.translation.length > 0 &&
      this.translation.find((t) => t.locale === undefined) === undefined
    );
  }

  private async loadLocales(): Promise<void> {
    if (this.localeStore.state === StoreState.INITIAL) await this.localeStore.load();
    this.subscription.add(
      this.localeStore.value$
        .pipe(
          first((l) => l !== undefined),
          tap((locales) => (this.locales = locales?.map((l) => l.iso)))
        )
        .subscribe()
    );
  }
}
