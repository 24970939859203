import { Injectable } from "@angular/core";
import { IPublicClientApplication } from "@azure/msal-browser";
import { AxiosApiClient, ConfigurationsApi } from "@smallstack/axios-api-client";
import { SQBuilder, SearchByFieldMatcher } from "@smallstack/typesystem";
import { Memoize } from "typescript-memoize";

@Injectable({ providedIn: "root" })
export class MsalPublicClientApplicationService {
  constructor(private axiosApiClient: AxiosApiClient) {}

  @Memoize()
  public async getPublicClientApplication(tenantId: string, redirectUrl?: string): Promise<IPublicClientApplication> {
    const azureAdConfigurations = await this.axiosApiClient
      .withConfiguration({ tenantId })
      .get(ConfigurationsApi)
      .getConfigurations({
        search: SQBuilder.asString([{ fieldname: "key", value: "azuread.", matcher: SearchByFieldMatcher.STARTS_WITH }])
      })
      .then((res) => res.data.elements);
    if (!azureAdConfigurations || azureAdConfigurations.length > 2) throw new Error("No AzureAdConfiguration found!");
    const azureAdClientId = azureAdConfigurations.find((config) => config.key === "azuread.clientid")?.value;
    if (!redirectUrl) redirectUrl = window.location.origin;
    const PublicClientApplication = (await import("@azure/msal-browser")).PublicClientApplication;
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: azureAdClientId,
        redirectUri: redirectUrl
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
      }
    });
    await msalInstance.initialize();
    return msalInstance;
  }
}
