<div class="flex flex-col gap-2 p-1">
  @for (i of range(); track i) {
    @switch (shape()) {
      @case ("card-with-title") {
        <div class="flex flex-col gap-4 py-4 w-full">
          <div class="skeleton h-8 w-full"></div>
          <div class="skeleton h-32 w-full"></div>
        </div>
      }
      @case ("input") {
        <div class="skeleton h-12 w-full"></div>
      }
      @case ("form") {
        <div class="skeleton h-4 w-1/5"></div>
        <div class="skeleton h-12 w-full"></div>
      }
      @case ("line") {
        <div class="skeleton h-4 w-full"></div>
      }
    }
  }
</div>
