import { Injectable } from "@angular/core";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { UrlResolverService } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { TypeDto } from "@smallstack/axios-api-client";
import { TypeService } from "@smallstack/typesystem-client";
import { cloneObject, replaceVariables } from "@smallstack/utils";

const LOG = new Logger();

@Injectable({ providedIn: "root" })
export class LinkedModelService {
  constructor(
    private urlResolverService: UrlResolverService,
    private typeService: TypeService
  ) {}

  public async getRepresentation(linkedModel: LinkedModelDto): Promise<TypeDto> {
    const type = await this.typeService.getTypeByPath(linkedModel.type);
    if (type) {
      const clonedType = cloneObject(type);
      clonedType.representation = type.representation;
      clonedType.longRepresentation = type.longRepresentation;
      if (clonedType.url)
        clonedType.url = replaceVariables(clonedType.url, {
          id: linkedModel.id,
          ...(await this.urlResolverService.getMap())
        });
      return clonedType;
    }
    LOG.error("LinkedModelService", "Could not find a linked model representation for type " + linkedModel.type);
    return {
      title: [{ value: "Unknown" }],
      path: "unknown",
      icon: "question-mark",
      searchableFields: [],
      longRepresentation: "${id}",
      permissions: {},
      isSystemType: false
    };
  }
}
