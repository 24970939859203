@if (todo()) {
  @if (data().showTitle) {
    <smallstack-i18n class="card-title" [data]="todo().label"></smallstack-i18n>
  }
  @if (data().showDescription) {
    <smallstack-i18n [data]="todo().description"></smallstack-i18n>
  }
  @if (data().displayAsTemplate !== true) {
    <smallstack-todo-list-entry
      [ngClass]="{ 'mt-3': data().showTitle === true || data().showDescription === true }"
      [todo]="todo()"
    ></smallstack-todo-list-entry>
  } @else {
    <smallstack-todo-template-list-entry
      [ngClass]="{ 'mt-3': data().showTitle === true || data().showDescription === true }"
      [todoTemplate]="todo()"
    ></smallstack-todo-template-list-entry>
  }
}
