import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CalendarEntryDto } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { TYPE_CALENDARS } from "@smallstack/typesystem";
import { FormComponent, SchemaFormTableComponent } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-calendar-entry-form",
  templateUrl: "./calendar-entry-form.component.html",
  styleUrls: ["./calendar-entry-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SchemaFormTableComponent, FormComponent]
})
export class CalendarEntryFormComponent {
  @Input()
  public calendarEntry: CalendarEntryDto;

  @Output()
  public readonly calendarEntryChange: EventEmitter<CalendarEntryDto> = new EventEmitter();

  @ViewChild(FormComponent)
  public form: FormComponent;

  public schema: SchemaFormSchema = {
    type: "object",
    properties: {
      calendarId: {
        title: "@@components.calendar.entryform.calendarid",
        type: "string",
        "x-schema-form": {
          widget: "storeselect",
          store: TYPE_CALENDARS,
          property: "name"
        }
      },
      title: {
        title: "@@components.calendar.entryform.title",
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        }
      },
      location: {
        title: "@@components.calendar.entryform.location",
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        }
      },
      attendeeIds: {
        title: "@@components.calendar.entryform.attendeeIds",
        type: "array",
        "x-schema-form": {
          widget: "storeselect",
          store: "users",
          property: "displayName",
          multiple: true
        }
      },
      start: {
        title: "@@components.calendar.entryform.start",
        type: "number",
        "x-schema-form": {
          widget: "datetime"
        }
      } as SchemaFormSchema,
      end: {
        title: "@@components.calendar.entryform.end",
        type: "number",
        "x-schema-form": {
          widget: "datetime"
        }
      } as SchemaFormSchema,
      content: {
        title: "@@components.calendar.entryform.content",
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N,
          "x-textarea-minrows": 4,
          textarea: true
        }
      } as SchemaFormSchema
    },
    required: ["calendarId", "title", "start", "end"]
  };
}
