import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from "@angular/core";
import { AddressDto, InnerTranslationDto, TodoDto, WorklogDto } from "@smallstack/axios-api-client";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { TYPE_TODOS } from "@smallstack/typesystem";
import { getGoogleMapsNavigationUrl } from "@smallstack/utils";

@Component({
  selector: "smallstack-worklog-timer",
  templateUrl: "./worklog-timer.component.html",
  styleUrls: ["./worklog-timer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogTimerComponent {
  private todoStore = injectRxEntityStore<TodoDto>({ typeDescriptor: { typePath: TYPE_TODOS } });
  private todoRefId = signal<string>(undefined);

  public maximized = input(false);
  public worklog = input.required<WorklogDto>();
  public readonly stopped = output<void>();

  protected currentDeltaTime = signal<number>(undefined);
  protected label = computed<string | InnerTranslationDto[]>(() => this.worklog()?.label);
  protected todo = computed<TodoDto | undefined>(() => {
    const todoRefId = this.todoRefId();
    return todoRefId ? this.todoStore.getById(todoRefId) : undefined;
  });

  private timer: NodeJS.Timer;

  constructor() {
    effect(
      () => {
        this.todoRefId.set(this.worklog()?.todoRefId);
        if (this.worklog()?.startTime) {
          this.currentDeltaTime.set(Date.now() - this.worklog().startTime);
          this.startTimer();
        } else {
          this.currentDeltaTime.set(undefined);
          if (this.timer) delete this.timer;
        }
      },
      { allowSignalWrites: true }
    );
  }

  public openGoogleMaps(address: AddressDto): void {
    window.open(getGoogleMapsNavigationUrl(address), "_blank");
  }

  protected async updateFn(todo: TodoDto): Promise<void> {
    await this.todoStore.updateEntity(todo);
  }

  private startTimer() {
    if (this.timer) delete this.timer;
    this.timer = setInterval(() => {
      this.currentDeltaTime.update((current) => (current += 1000));
    }, 1000);
  }
}
