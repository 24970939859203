import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { BreakpointService } from "@smallstack/common-components";

@Component({
  selector: "smallstack-fullscreen-layout",
  templateUrl: "./fullscreen-layout.component.html",
  styleUrls: ["./fullscreen-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenLayoutComponent {
  @Input()
  public withContentBackground = true;

  @Input()
  public border: boolean = true;

  constructor(protected breakpointService: BreakpointService) {}
}
