import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { SocketConnection } from "@smallstack/core-common";
import { WidgetRendererOptions } from "../../services/widget-tree.service";
import { WidgetTreeComponent } from "../widget-tree/widget-tree.component";

export interface WidgetDialogComponentData {
  widget: WidgetDto;
  widgetRendererOptions?: WidgetRendererOptions;
  connections?: SocketConnection[];
  context?: any;
}

/** Opens a widget as dialog. If you want to show title and action buttons, please use the Dialog Widget as root widget of the dialog content */
@Component({
  selector: "smallstack-widget-dialog",
  templateUrl: "./widget-dialog.component.html",
  styleUrls: ["./widget-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WidgetTreeComponent]
})
export class WidgetDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WidgetDialogComponentData,
    public dialogRef: MatDialogRef<WidgetDialogComponent>
  ) {
    if (data?.widgetRendererOptions) data.widgetRendererOptions.dialogRef = dialogRef;
    else data.widgetRendererOptions = { dialogRef };
  }

  public close(): void {
    if (this.data.widgetRendererOptions?.editMode === true) this.dialogRef.close(this.data.widget);
    else this.dialogRef.close();
  }
}
