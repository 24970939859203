import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, input, signal } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-loader",
  templateUrl: "./loader.component.html",
  standalone: true,
  imports: [I18nComponent, NgClass]
})
export class LoaderComponent {
  protected loadingColor = signal<string>("primary");
  protected loadingSize = signal<string>("loading-md");
  protected loadingText = signal<string>("@@components.loader.loadingdata");

  public loading = input(true);

  @Input()
  public set color(color: string) {
    if (color === "primary" || color === "secondary" || color === "warning" || color === "error")
      this.loadingColor.set(color);
  }

  @Input()
  public set size(size: string) {
    if (size === "xs" || size === "sm" || size === "lg" || size === "md") this.loadingSize.set(size);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("loadingText")
  public set loadingTextFn(text: string) {
    this.loadingText.set(text);
  }
}
