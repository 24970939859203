export interface PwaIcon {
  size: number;
  url: string;
}

export const PwaIconSchema = {
  type: "object",
  properties: {
    size: {
      type: "number"
    },
    url: {
      type: "string"
    }
  },
  required: ["size", "url"]
};
