import { AfterViewInit, Directive, Input, Optional, ViewContainerRef } from "@angular/core";
import { SchemaFormCustomLayoutService } from "./schema-form-custom-layout.service";
import { Logger } from "@smallstack/core-common";

@Directive({
  selector: "[schemaFormPlaceholder]",
  standalone: true
})
export class SchemaFormPlaceholderDirective implements AfterViewInit {
  @Input()
  public schemaFormPlaceholder: string;

  constructor(
    public viewContainerRef: ViewContainerRef,
    @Optional() private schemaFormCustomLayoutService: SchemaFormCustomLayoutService
  ) {}

  public ngAfterViewInit(): void {
    if (this.schemaFormCustomLayoutService) this.schemaFormCustomLayoutService.addSchemaFormPlaceholder(this);
    else
      Logger.error(
        "SchemaFormPlaceholderDirective",
        "Please provide a SchemaFormCustomLayoutService in your component tree!"
      );
  }
}
