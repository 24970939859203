import { TypeSchema } from "../typesystem/type-schema";

export interface Model {
  id?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  _deleted?: boolean;
}

export const ModelSchema: TypeSchema = {
  type: "object",
  properties: {
    lastUpdatedAt: {
      type: "number",
      description: "Describes the date of the last update",
      "x-schema-form": {
        hidden: true
      }
    },
    _deleted: {
      type: "number",
      description: "Set to true if the entity was (soft-)deleted",
      "x-schema-form": {
        hidden: true
      }
    },
    id: {
      type: "string",
      maxLength: 100,
      "x-schema-form": {
        hidden: true
      }
    },
    createdAt: {
      type: "number",
      readOnly: true,
      "x-schema-form": {
        hidden: true
      }
    }
  }
};
