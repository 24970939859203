@if (store) {
  @if (store.isLoading$ | async) {
    <div class="flex flex-col justify-center items-center content-center">
      <smallstack-loader [loadingText]="loadingText"></smallstack-loader>
    </div>
  }
  @if (showErrorIcon) {
    @if (store.hasError$ | async) {
      <smallstack-icon
        alias="error"
        size="24"
        color="warn"
        matTooltip="{{ store.error$ | async }}"
        matTooltipShowDelay="500"
        style="cursor: pointer; vertical-align: middle; padding: 10px"
        (click)="$any(store).reload()"
      ></smallstack-icon>
    }
  }
  @if (showErrorText) {
    <span class="error-text">{{ store.error$ | async }}</span>
  }
  @if (store.isLoaded$ | async) {
    <ng-content></ng-content>
  }
  @if (content && (store?.isLoaded$ | async) && !($any(store)?.empty$ | async)) {
    <ng-container *ngTemplateOutlet="content"></ng-container>
  }
  @if (($any(store)?.empty$ | async) && !(store?.isLoading$ | async)) {
    <div>
      @if (empty) {
        <div>
          <ng-container *ngTemplateOutlet="empty"></ng-container>
        </div>
      }
    </div>
  }
}
@if (!store) {
  Error: No stateful subject given!
}
