import { ViewportRuler } from "@angular/cdk/scrolling";
import { Injectable, NgZone, computed, inject, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class BreakpointService {
  private readonly viewportRuler = inject(ViewportRuler);

  public windowWidth = signal<number>(window.innerWidth);

  public lt(width: number): boolean {
    return this.windowWidth() < width;
  }

  public lte(width: number): boolean {
    return this.windowWidth() <= width;
  }

  public gt(width: number): boolean {
    return this.windowWidth() > width;
  }

  public gte(width: number): boolean {
    return this.windowWidth() >= width;
  }

  public isMobile = computed(() => this.lte(640));
  public isTablet = computed(() => this.gt(640) && this.lte(1024));
  public isDesktop = computed(() => this.gt(1024));

  constructor(ngZone: NgZone) {
    void this.viewportRuler.change().subscribe(() => {
      ngZone.run(() => {
        this.windowWidth.set(this.viewportRuler.getViewportSize().width);
      });
    });
  }
}
