import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import {
  AxiosApiClient,
  ConfigurationDto,
  ConfigurationDtoScopeEnum,
  ConfigurationsApi,
  UpdateConfigurationDto
} from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { SQBuilder, SearchByFieldMatcher, TYPE_CONFIGURATIONS } from "@smallstack/typesystem";
import { synchronized } from "synchronized-ts";
import { Memoize } from "typescript-memoize";
import { StoreRegistry } from "../services/store.registry";

@Injectable({ providedIn: "root" })
export class ConfigurationStore extends PageableCrudStore<ConfigurationDto> {
  constructor(
    private axiosApiClient: AxiosApiClient,
    storeRegistry: StoreRegistry
  ) {
    super();
    storeRegistry.registerStore(TYPE_CONFIGURATIONS, this);
  }

  public async setConfiguration(key: string, value: string): Promise<ConfigurationDto> {
    const configuration: ConfigurationDto = await this.getConfigurationByKey(key);
    return (
      await this.axiosApiClient
        .get(ConfigurationsApi)
        .patchConfiguration({ id: configuration.id, updateConfiguration: { value } })
    ).data;
  }

  public async upsertConfiguration(
    key: string,
    value: string,
    scope?: ConfigurationDtoScopeEnum
  ): Promise<ConfigurationDto> {
    let configuration: ConfigurationDto = await this.getConfigurationByKey(key);
    if (!configuration) configuration = { key, value, scope: scope ? scope : ConfigurationDtoScopeEnum.Server };
    else {
      configuration.value = value;
      if (scope) configuration.scope = scope;
    }
    return this.createOrPut(configuration);
  }

  public async getConfiguration(key: string, withDefaultValue: boolean = true): Promise<string> {
    const configuation: ConfigurationDto = await this.getConfigurationByKey(key);
    if (!configuation) return undefined;
    if (configuation.value) return configuation.value;
    if (withDefaultValue) return configuation.defaultValue;
  }

  // to match "ConfigurationService" interface
  public async getValue(key: string): Promise<string> {
    return this.getConfiguration(key);
  }

  public async getConfigurationsStartingWith(startsWith: string): Promise<ConfigurationDto[]> {
    const searchStore = this.clone();
    await searchStore.search({
      fieldSearches: [{ fieldname: "key", value: startsWith, matcher: SearchByFieldMatcher.STARTS_WITH }],
      logicalOperator: "and"
    });
    return searchStore.value;
  }

  @synchronized
  @Memoize({ expiring: 1500 })
  public async getConfigurationByKey(key: string): Promise<ConfigurationDto> {
    let storeValue = this.value?.find((c) => c.key === key);
    if (!storeValue) {
      const configs = await this.axiosApiClient
        .get(ConfigurationsApi)
        .getConfigurations({ search: SQBuilder.asString([{ fieldname: "key", value: key }]) })
        .then((res) => res.data);
      storeValue = configs.elements[0];
      if (storeValue) this.addValue(storeValue);
    }
    return storeValue;
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<ConfigurationDto>> {
    const res = await this.axiosApiClient.get(ConfigurationsApi).getConfigurations(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<ConfigurationDto> {
    const res = await this.axiosApiClient.get(ConfigurationsApi).getConfiguration({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(ConfigurationsApi).deleteConfiguration({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(ConfigurationsApi).deleteManyConfigurations({ ids });
    return res.data;
  }
  protected async createModel(configuration: ConfigurationDto): Promise<ConfigurationDto> {
    const res = await this.axiosApiClient.get(ConfigurationsApi).createConfiguration({ configuration });
    return res.data;
  }
  protected async patchModel(id: string, configuration: UpdateConfigurationDto): Promise<ConfigurationDto> {
    const res = await this.axiosApiClient
      .get(ConfigurationsApi)
      .patchConfiguration({ updateConfiguration: configuration, id });
    return res.data;
  }
  protected async putModel(configuration: ConfigurationDto): Promise<ConfigurationDto> {
    const res = await this.axiosApiClient
      .get(ConfigurationsApi)
      .putConfiguration({ configuration, id: configuration.id });
    return res.data;
  }
}
