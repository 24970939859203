/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DataDto } from '../models';
// @ts-ignore
import { DataModelDto } from '../models';
// @ts-ignore
import { DataPageDto } from '../models';
// @ts-ignore
import { DataSumResponseDtoDto } from '../models';
// @ts-ignore
import { DataTypeDto } from '../models';
// @ts-ignore
import { DataTypePageDto } from '../models';
// @ts-ignore
import { DataTypeSearchResultPageDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
/**
 * TenantDataApi - axios parameter creator
 * @export
 */
export const TenantDataApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {any} anyBody The model you want to create
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createData: async (type: string, feature: string, anyBody: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling createData.');
            }
            // verify required parameter 'anyBody' is not null or undefined
            if (anyBody === null || anyBody === undefined) {
                throw new RequiredError('anyBody','Required parameter anyBody was null or undefined when calling createData.');
            }
            const localVarPath = `/data/{feature}/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof anyBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(anyBody !== undefined ? anyBody : {})
                : (anyBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataType: async (dataType: DataTypeDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataType' is not null or undefined
            if (dataType === null || dataType === undefined) {
                throw new RequiredError('dataType','Required parameter dataType was null or undefined when calling createDataType.');
            }
            const localVarPath = `/datatypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dataType !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dataType !== undefined ? dataType : {})
                : (dataType || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a DataType exists.
         * @summary Check if DataType exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataTypeExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dataTypeExists.');
            }
            const localVarPath = `/datatypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes data for given id
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteData: async (type: string, feature: string, id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling deleteData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling deleteData.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteData.');
            }
            const localVarPath = `/data/{feature}/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataType: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDataType.');
            }
            const localVarPath = `/datatypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes many data entries
         * @param {string} type 
         * @param {string} feature 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyData: async (type: string, feature: string, ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling deleteManyData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling deleteManyData.');
            }
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyData.');
            }
            const localVarPath = `/data/{feature}/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyDataTypes: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyDataTypes.');
            }
            const localVarPath = `/datatypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Dispatches the workflow event \'DATA_ACTION\' with the actionName as payload
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} actionName 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchDataAction: async (type: string, feature: string, id: string, actionName: string, anyBody: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling dispatchDataAction.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling dispatchDataAction.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dispatchDataAction.');
            }
            // verify required parameter 'actionName' is not null or undefined
            if (actionName === null || actionName === undefined) {
                throw new RequiredError('actionName','Required parameter actionName was null or undefined when calling dispatchDataAction.');
            }
            // verify required parameter 'anyBody' is not null or undefined
            if (anyBody === null || anyBody === undefined) {
                throw new RequiredError('anyBody','Required parameter anyBody was null or undefined when calling dispatchDataAction.');
            }
            const localVarPath = `/data/{feature}/{type}/{id}/actions/{actionName}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"actionName"}}`, encodeURIComponent(String(actionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof anyBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(anyBody !== undefined ? anyBody : {})
                : (anyBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData: async (type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling getData.');
            }
            const localVarPath = `/data/{feature}/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataById: async (type: string, feature: string, id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getDataById.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling getDataById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDataById.');
            }
            const localVarPath = `/data/{feature}/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a count of a specific custom datatype entity
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCount: async (type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getDataCount.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling getDataCount.');
            }
            const localVarPath = `/data/{feature}/{type}/count`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns summed up data given type and property
         * @param {string} type 
         * @param {string} feature 
         * @param {string} property 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSum: async (type: string, feature: string, property: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getDataSum.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling getDataSum.');
            }
            // verify required parameter 'property' is not null or undefined
            if (property === null || property === undefined) {
                throw new RequiredError('property','Required parameter property was null or undefined when calling getDataSum.');
            }
            const localVarPath = `/data/{feature}/{type}/sum`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (property !== undefined) {
                localVarQueryParameter['property'] = property;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataType: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDataType.');
            }
            const localVarPath = `/datatypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataTypes: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/datatypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataTypesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/datatypes/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchData: async (type: string, feature: string, id: string, anyBody: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling patchData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling patchData.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchData.');
            }
            // verify required parameter 'anyBody' is not null or undefined
            if (anyBody === null || anyBody === undefined) {
                throw new RequiredError('anyBody','Required parameter anyBody was null or undefined when calling patchData.');
            }
            const localVarPath = `/data/{feature}/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof anyBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(anyBody !== undefined ? anyBody : {})
                : (anyBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataType: async (id: string, dataType: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchDataType.');
            }
            // verify required parameter 'dataType' is not null or undefined
            if (dataType === null || dataType === undefined) {
                throw new RequiredError('dataType','Required parameter dataType was null or undefined when calling patchDataType.');
            }
            const localVarPath = `/datatypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dataType !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dataType !== undefined ? dataType : {})
                : (dataType || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performDataTypeSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performDataTypeSearch.');
            }
            const localVarPath = `/datatypes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs a text search across the database
         * @param {string} type 
         * @param {string} feature 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performSearch: async (type: string, feature: string, searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling performSearch.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling performSearch.');
            }
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performSearch.');
            }
            const localVarPath = `/data/{feature}/{type}/search`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replaces data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putData: async (type: string, feature: string, id: string, anyBody: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling putData.');
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling putData.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putData.');
            }
            // verify required parameter 'anyBody' is not null or undefined
            if (anyBody === null || anyBody === undefined) {
                throw new RequiredError('anyBody','Required parameter anyBody was null or undefined when calling putData.');
            }
            const localVarPath = `/data/{feature}/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof anyBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(anyBody !== undefined ? anyBody : {})
                : (anyBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDataType: async (id: string, dataType: DataTypeDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putDataType.');
            }
            // verify required parameter 'dataType' is not null or undefined
            if (dataType === null || dataType === undefined) {
                throw new RequiredError('dataType','Required parameter dataType was null or undefined when calling putDataType.');
            }
            const localVarPath = `/datatypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dataType !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dataType !== undefined ? dataType : {})
                : (dataType || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantDataApi - functional programming interface
 * @export
 */
export const TenantDataApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {any} anyBody The model you want to create
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createData(type: string, feature: string, anyBody: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataModelDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).createData(type, feature, anyBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataType(dataType: DataTypeDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypeDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).createDataType(dataType, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a DataType exists.
         * @summary Check if DataType exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataTypeExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).dataTypeExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes data for given id
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteData(type: string, feature: string, id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).deleteData(type, feature, id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataType(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).deleteDataType(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes many data entries
         * @param {string} type 
         * @param {string} feature 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyData(type: string, feature: string, ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).deleteManyData(type, feature, ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyDataTypes(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).deleteManyDataTypes(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Dispatches the workflow event \'DATA_ACTION\' with the actionName as payload
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} actionName 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dispatchDataAction(type: string, feature: string, id: string, actionName: string, anyBody: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).dispatchDataAction(type, feature, id, actionName, anyBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData(type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPageDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getData(type, feature, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataById(type: string, feature: string, id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataModelDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataById(type, feature, id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a count of a specific custom datatype entity
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCount(type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataCount(type, feature, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns summed up data given type and property
         * @param {string} type 
         * @param {string} feature 
         * @param {string} property 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSum(type: string, feature: string, property: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSumResponseDtoDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataSum(type, feature, property, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataType(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypeDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataType(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataTypes(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypePageDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataTypes(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataTypesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).getDataTypesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Patches data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchData(type: string, feature: string, id: string, anyBody: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).patchData(type, feature, id, anyBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDataType(id: string, dataType: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypeDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).patchDataType(id, dataType, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performDataTypeSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypeSearchResultPageDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).performDataTypeSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Performs a text search across the database
         * @param {string} type 
         * @param {string} feature 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performSearch(type: string, feature: string, searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPageDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).performSearch(type, feature, searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replaces data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putData(type: string, feature: string, id: string, anyBody: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).putData(type, feature, id, anyBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDataType(id: string, dataType: DataTypeDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTypeDto>> {
            const localVarAxiosArgs = await TenantDataApiAxiosParamCreator(axiosClientConfiguration).putDataType(id, dataType, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantDataApi - factory interface
 * @export
 */
export const TenantDataApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {any} anyBody The model you want to create
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createData(type: string, feature: string, anyBody: any, translate?: string, options?: any): AxiosPromise<DataModelDto> {
            return TenantDataApiFp(axiosClientConfiguration).createData(type, feature, anyBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataType(dataType: DataTypeDto, translate?: string, options?: any): AxiosPromise<DataTypeDto> {
            return TenantDataApiFp(axiosClientConfiguration).createDataType(dataType, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a DataType exists.
         * @summary Check if DataType exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataTypeExists(id: string, options?: any): AxiosPromise<void> {
            return TenantDataApiFp(axiosClientConfiguration).dataTypeExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes data for given id
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteData(type: string, feature: string, id: string, translate?: string, options?: any): AxiosPromise<void> {
            return TenantDataApiFp(axiosClientConfiguration).deleteData(type, feature, id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataType(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return TenantDataApiFp(axiosClientConfiguration).deleteDataType(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes many data entries
         * @param {string} type 
         * @param {string} feature 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyData(type: string, feature: string, ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return TenantDataApiFp(axiosClientConfiguration).deleteManyData(type, feature, ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyDataTypes(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return TenantDataApiFp(axiosClientConfiguration).deleteManyDataTypes(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Dispatches the workflow event \'DATA_ACTION\' with the actionName as payload
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} actionName 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchDataAction(type: string, feature: string, id: string, actionName: string, anyBody: any, translate?: string, options?: any): AxiosPromise<any> {
            return TenantDataApiFp(axiosClientConfiguration).dispatchDataAction(type, feature, id, actionName, anyBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData(type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<DataPageDto> {
            return TenantDataApiFp(axiosClientConfiguration).getData(type, feature, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all data for given type
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataById(type: string, feature: string, id: string, translate?: string, options?: any): AxiosPromise<DataModelDto> {
            return TenantDataApiFp(axiosClientConfiguration).getDataById(type, feature, id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a count of a specific custom datatype entity
         * @param {string} type 
         * @param {string} feature 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCount(type: string, feature: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return TenantDataApiFp(axiosClientConfiguration).getDataCount(type, feature, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns summed up data given type and property
         * @param {string} type 
         * @param {string} feature 
         * @param {string} property 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSum(type: string, feature: string, property: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<DataSumResponseDtoDto> {
            return TenantDataApiFp(axiosClientConfiguration).getDataSum(type, feature, property, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataType(id: string, translate?: string, options?: any): AxiosPromise<DataTypeDto> {
            return TenantDataApiFp(axiosClientConfiguration).getDataType(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataTypes(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<DataTypePageDto> {
            return TenantDataApiFp(axiosClientConfiguration).getDataTypes(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataTypesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return TenantDataApiFp(axiosClientConfiguration).getDataTypesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patches data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchData(type: string, feature: string, id: string, anyBody: any, translate?: string, options?: any): AxiosPromise<DataDto> {
            return TenantDataApiFp(axiosClientConfiguration).patchData(type, feature, id, anyBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataType(id: string, dataType: any, translate?: string, options?: any): AxiosPromise<DataTypeDto> {
            return TenantDataApiFp(axiosClientConfiguration).patchDataType(id, dataType, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performDataTypeSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<DataTypeSearchResultPageDto> {
            return TenantDataApiFp(axiosClientConfiguration).performDataTypeSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs a text search across the database
         * @param {string} type 
         * @param {string} feature 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performSearch(type: string, feature: string, searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<DataPageDto> {
            return TenantDataApiFp(axiosClientConfiguration).performSearch(type, feature, searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replaces data
         * @param {string} type 
         * @param {string} feature 
         * @param {string} id 
         * @param {any} anyBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putData(type: string, feature: string, id: string, anyBody: any, translate?: string, options?: any): AxiosPromise<DataDto> {
            return TenantDataApiFp(axiosClientConfiguration).putData(type, feature, id, anyBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DataTypeDto} dataType 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDataType(id: string, dataType: DataTypeDto, translate?: string, options?: any): AxiosPromise<DataTypeDto> {
            return TenantDataApiFp(axiosClientConfiguration).putDataType(id, dataType, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiCreateDataRequest
 */
export interface TenantDataApiCreateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiCreateData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiCreateData
     */
    readonly feature: string

    /**
     * The model you want to create
     * @type {any}
     * @memberof TenantDataApiCreateData
     */
    readonly anyBody: any

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiCreateData
     */
    readonly translate?: string
}

/**
 * Request parameters for createDataType operation in TenantDataApi.
 * @export
 * @interface TenantDataApiCreateDataTypeRequest
 */
export interface TenantDataApiCreateDataTypeRequest {
    /**
     * 
     * @type {DataTypeDto}
     * @memberof TenantDataApiCreateDataType
     */
    readonly dataType: DataTypeDto

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiCreateDataType
     */
    readonly translate?: string
}

/**
 * Request parameters for dataTypeExists operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDataTypeExistsRequest
 */
export interface TenantDataApiDataTypeExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDataTypeExists
     */
    readonly id: string
}

/**
 * Request parameters for deleteData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDeleteDataRequest
 */
export interface TenantDataApiDeleteDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteData
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteData
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteData
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteDataType operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDeleteDataTypeRequest
 */
export interface TenantDataApiDeleteDataTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteDataType
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteDataType
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDeleteManyDataRequest
 */
export interface TenantDataApiDeleteManyDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteManyData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteManyData
     */
    readonly feature: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDataApiDeleteManyData
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteManyData
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyDataTypes operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDeleteManyDataTypesRequest
 */
export interface TenantDataApiDeleteManyDataTypesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDataApiDeleteManyDataTypes
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDeleteManyDataTypes
     */
    readonly translate?: string
}

/**
 * Request parameters for dispatchDataAction operation in TenantDataApi.
 * @export
 * @interface TenantDataApiDispatchDataActionRequest
 */
export interface TenantDataApiDispatchDataActionRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly actionName: string

    /**
     * 
     * @type {any}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly anyBody: any

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiDispatchDataAction
     */
    readonly translate?: string
}

/**
 * Request parameters for getData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataRequest
 */
export interface TenantDataApiGetDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetData
     */
    readonly feature: string

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetData
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetData
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetData
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetData
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantDataApiGetData
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetData
     */
    readonly translate?: string
}

/**
 * Request parameters for getDataById operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataByIdRequest
 */
export interface TenantDataApiGetDataByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataById
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataById
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataById
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataById
     */
    readonly translate?: string
}

/**
 * Request parameters for getDataCount operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataCountRequest
 */
export interface TenantDataApiGetDataCountRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataCount
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataCount
     */
    readonly feature: string

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantDataApiGetDataCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getDataSum operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataSumRequest
 */
export interface TenantDataApiGetDataSumRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly property: string

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataSum
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataSum
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantDataApiGetDataSum
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataSum
     */
    readonly translate?: string
}

/**
 * Request parameters for getDataType operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataTypeRequest
 */
export interface TenantDataApiGetDataTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataType
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataType
     */
    readonly translate?: string
}

/**
 * Request parameters for getDataTypes operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataTypesRequest
 */
export interface TenantDataApiGetDataTypesRequest {
    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypes
     */
    readonly search?: string
}

/**
 * Request parameters for getDataTypesCount operation in TenantDataApi.
 * @export
 * @interface TenantDataApiGetDataTypesCountRequest
 */
export interface TenantDataApiGetDataTypesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiGetDataTypesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for patchData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPatchDataRequest
 */
export interface TenantDataApiPatchDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchData
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchData
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof TenantDataApiPatchData
     */
    readonly anyBody: any

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchData
     */
    readonly translate?: string
}

/**
 * Request parameters for patchDataType operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPatchDataTypeRequest
 */
export interface TenantDataApiPatchDataTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchDataType
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof TenantDataApiPatchDataType
     */
    readonly dataType: any

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPatchDataType
     */
    readonly translate?: string
}

/**
 * Request parameters for performDataTypeSearch operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPerformDataTypeSearchRequest
 */
export interface TenantDataApiPerformDataTypeSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof TenantDataApiPerformDataTypeSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPerformDataTypeSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performSearch operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPerformSearchRequest
 */
export interface TenantDataApiPerformSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPerformSearch
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPerformSearch
     */
    readonly feature: string

    /**
     * 
     * @type {SearchRequestDto}
     * @memberof TenantDataApiPerformSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPerformSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putData operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPutDataRequest
 */
export interface TenantDataApiPutDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutData
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutData
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutData
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof TenantDataApiPutData
     */
    readonly anyBody: any

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutData
     */
    readonly translate?: string
}

/**
 * Request parameters for putDataType operation in TenantDataApi.
 * @export
 * @interface TenantDataApiPutDataTypeRequest
 */
export interface TenantDataApiPutDataTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutDataType
     */
    readonly id: string

    /**
     * 
     * @type {DataTypeDto}
     * @memberof TenantDataApiPutDataType
     */
    readonly dataType: DataTypeDto

    /**
     * 
     * @type {string}
     * @memberof TenantDataApiPutDataType
     */
    readonly translate?: string
}

/**
 * TenantDataApi - object-oriented interface
 * @export
 * @class TenantDataApi
 * @extends {BaseAPI}
 */
export class TenantDataApi extends BaseAPI {
    /**
     * 
     * @summary Creates data for given type
     * @param {TenantDataApiCreateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public createData(requestParameters: TenantDataApiCreateDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).createData(requestParameters.type, requestParameters.feature, requestParameters.anyBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiCreateDataTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public createDataType(requestParameters: TenantDataApiCreateDataTypeRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).createDataType(requestParameters.dataType, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a DataType exists.
     * @summary Check if DataType exists
     * @param {TenantDataApiDataTypeExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public dataTypeExists(requestParameters: TenantDataApiDataTypeExistsRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).dataTypeExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes data for given id
     * @param {TenantDataApiDeleteDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public deleteData(requestParameters: TenantDataApiDeleteDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).deleteData(requestParameters.type, requestParameters.feature, requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiDeleteDataTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public deleteDataType(requestParameters: TenantDataApiDeleteDataTypeRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).deleteDataType(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes many data entries
     * @param {TenantDataApiDeleteManyDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public deleteManyData(requestParameters: TenantDataApiDeleteManyDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).deleteManyData(requestParameters.type, requestParameters.feature, requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiDeleteManyDataTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public deleteManyDataTypes(requestParameters: TenantDataApiDeleteManyDataTypesRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).deleteManyDataTypes(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dispatches the workflow event \'DATA_ACTION\' with the actionName as payload
     * @param {TenantDataApiDispatchDataActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public dispatchDataAction(requestParameters: TenantDataApiDispatchDataActionRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).dispatchDataAction(requestParameters.type, requestParameters.feature, requestParameters.id, requestParameters.actionName, requestParameters.anyBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all data for given type
     * @param {TenantDataApiGetDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getData(requestParameters: TenantDataApiGetDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getData(requestParameters.type, requestParameters.feature, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all data for given type
     * @param {TenantDataApiGetDataByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataById(requestParameters: TenantDataApiGetDataByIdRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataById(requestParameters.type, requestParameters.feature, requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a count of a specific custom datatype entity
     * @param {TenantDataApiGetDataCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataCount(requestParameters: TenantDataApiGetDataCountRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataCount(requestParameters.type, requestParameters.feature, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns summed up data given type and property
     * @param {TenantDataApiGetDataSumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataSum(requestParameters: TenantDataApiGetDataSumRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataSum(requestParameters.type, requestParameters.feature, requestParameters.property, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiGetDataTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataType(requestParameters: TenantDataApiGetDataTypeRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataType(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiGetDataTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataTypes(requestParameters: TenantDataApiGetDataTypesRequest = {}, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataTypes(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiGetDataTypesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public getDataTypesCount(requestParameters: TenantDataApiGetDataTypesCountRequest = {}, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).getDataTypesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patches data
     * @param {TenantDataApiPatchDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public patchData(requestParameters: TenantDataApiPatchDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).patchData(requestParameters.type, requestParameters.feature, requestParameters.id, requestParameters.anyBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiPatchDataTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public patchDataType(requestParameters: TenantDataApiPatchDataTypeRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).patchDataType(requestParameters.id, requestParameters.dataType, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiPerformDataTypeSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public performDataTypeSearch(requestParameters: TenantDataApiPerformDataTypeSearchRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).performDataTypeSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs a text search across the database
     * @param {TenantDataApiPerformSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public performSearch(requestParameters: TenantDataApiPerformSearchRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).performSearch(requestParameters.type, requestParameters.feature, requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replaces data
     * @param {TenantDataApiPutDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public putData(requestParameters: TenantDataApiPutDataRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).putData(requestParameters.type, requestParameters.feature, requestParameters.id, requestParameters.anyBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantDataApiPutDataTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataApi
     */
    public putDataType(requestParameters: TenantDataApiPutDataTypeRequest, options?: any) {
        return TenantDataApiFp(this.axiosClientConfiguration).putDataType(requestParameters.id, requestParameters.dataType, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
