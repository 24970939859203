<div style="width: 100%; height: {{ 65 * worklogs()?.length }}px"></div>
@if (maximized()) {
  <div class="static-container-outside" (click)="maximized.set(false)"></div>
}
@if (worklogs() !== null && worklogs()?.length !== 0) {
  <div class="static-worklog-timer-container" (click)="maximized.set(true)">
    <div class="static-worklog-timer" [ngClass]="{ maximized: maximized() }">
      @for (worklog of worklogs(); track worklog) {
        @if (worklog.startTime && !worklog.endTime) {
          <smallstack-worklog-timer
            [worklog]="worklog"
            (stopped)="stopWorklog(worklog)"
            [maximized]="maximized()"
          ></smallstack-worklog-timer>
        }
      }
      @if (maximized()) {
        <button
          class="btn btn-outline btn-primary btn-block"
          data-testid="worklog-timer-close-btn"
          (click)="closePanel($event)"
          >Schließen</button
        >
      }
    </div>
  </div>
}
