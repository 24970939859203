import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserTracing, captureException, init, instrumentAngularRouting } from "@sentry/angular-ivy";
import { AxiosApiClient, ResellerDto, ResellersApi } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import axios from "axios";
import axiosRetry from "axios-retry";
import "hammerjs";
import { AppModule } from "./app/app.module";
import { smallstackApiUrl, smallstackUrl } from "./app/app.subscriptions";
import { reseller$ } from "./app/modules/common/store/reseller.store";
import { environment } from "./environments/environment";

async function bootstrapMain() {
  if (environment.production) {
    enableProdMode();
  }
  if (environment.sentryDns) {
    init({
      dsn: environment.sentryDns,
      release: environment.version,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [
            "localhost",
            "https://sandbox-api.smallstack.com",
            "https://api.smallstack.com",
            "https://api-sandbox.smallstack.com"
          ],
          routingInstrumentation: instrumentAngularRouting
        })
      ],
      tracesSampleRate: 0.05,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1
    });
  }

  Logger.on("error", (moduleName: string, message: string, stacktrace: unknown) => {
    if (stacktrace) captureException(stacktrace);
    else {
      const loggerError: Error = new Error(message);
      loggerError.name = moduleName;
      captureException(loggerError);
    }
  });

  smallstackApiUrl.next(environment.apiUrl);
  smallstackUrl.next(window.location.protocol + "//" + window.location.host);

  // some debug debug
  Logger.info("Main", `Base URL: ${smallstackUrl.value}`);
  Logger.info("Main", `Endpoint: ${smallstackApiUrl.value}`);
  try {
    axiosRetry(axios, {
      retries: 60,
      retryDelay: (retryCount) => {
        Logger.warning("Main", "Retrying to get Reseller: #" + retryCount + "/60");
        return retryCount * 1000; // time interval between retries
      }
    });
    const reseller: ResellerDto = (
      await new AxiosApiClient({ apiUrl: smallstackApiUrl.value, axiosInstance: axios }).get(ResellersApi).getReseller()
    ).data;
    reseller$.next(reseller);
    Logger.debug("Main", `Reseller      : ${JSON.stringify(reseller)}`);
  } catch (err) {
    Logger.error("Main", "Error while getting Reseller:", err);
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      Logger.error("Main", err);
      captureException(err);
      throw err;
    });
}
void bootstrapMain();
