export type PromiseProviderFn<T> = () => T | Promise<T>;

/** PromiseProvider can be either a value, a promise returning a value, a function that returns a value or a function that returns a promise  */
export type PromiseProvider<T> = T | Promise<T> | PromiseProviderFn<T>;

export async function getPromiseProviderValue<T>(provider: PromiseProvider<T>): Promise<T> {
  if (typeof provider !== "function") return provider;
  if (typeof provider === "function") return (<any>provider)();
  return provider;
}
