import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const SocketNames = {
  openCreationDialog: "openCreationDialog",
  tableData: "tableData",
  searchQuery: "searchQuery",
  reload: "reload"
};

export const DataTableWidgetV2: WidgetRegistryEntry = {
  name: "DataTableV2",
  configuration: () => import("./data-table-v2-widget-configuration").then((m) => m.DataTableV2Configuration),
  component: () => import("./data-table-v2-widget.component").then((m) => m.DataTableV2WidgetComponent)
};
