import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { WidgetRendererOptions } from "@smallstack/widget-core";

export interface FloatingActionButtonDialogData {
  dialogComponent: WidgetDto;
  widgetRendererOptions: WidgetRendererOptions;
  context: any;
}

@Component({
  templateUrl: "./floating-action-button-dialog.component.html",
  styleUrls: ["./floating-action-button-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingActionButtonDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FloatingActionButtonDialogData,
    public dialogRef: MatDialogRef<FloatingActionButtonDialogComponent>
  ) {
    data.widgetRendererOptions.dialogRef = dialogRef;
  }

  public close(): void {
    if (this.data.widgetRendererOptions?.editMode === true) this.dialogRef.close(this.data.dialogComponent);
    else this.dialogRef.close();
  }
}
