export interface SocketConnection {
  sourceComponentId: string;
  sourceSocketName: string;
  targetComponentId: string;
  targetSocketName: string;
}

export const SocketConnectionSchema = {
  type: "object",
  properties: {
    sourceComponentId: {
      type: "string"
    },
    sourceSocketName: {
      type: "string"
    },
    targetComponentId: {
      type: "string"
    },
    targetSocketName: {
      type: "string"
    }
  },
  required: ["sourceComponentId", "sourceSocketName", "targetComponentId", "targetSocketName"]
};
