/* eslint-disable no-prototype-builtins */
import { JSONSchema7 } from "json-schema";
import { cloneObject, isEmptyObject } from "./json-utils";
import { removePropertyRecursively } from "./object-utils";

/**
 *
 * @param schema
 * @param useEmptyValues Will return "" for type:string if no default is given
 * @returns
 */
// eslint-disable-next-line complexity
export function getSchemaDefaults(schema: JSONSchema7, useEmptyValues = true): any {
  if (!schema) return undefined;
  schema = cloneObject(schema);
  if (typeof schema.default !== "undefined") return schema.default;

  switch (schema.type) {
    case "object": {
      if (!schema.properties) return undefined;
      const objectValue: any = {};
      for (const key in schema.properties) {
        if (schema.properties.hasOwnProperty(key)) {
          objectValue[key] = getSchemaDefaults(schema.properties[key] as JSONSchema7, useEmptyValues);
          if (
            useEmptyValues === false &&
            (isEmptyObject(objectValue[key]) ||
              (Array.isArray(objectValue[key]) && objectValue[key].length === 0) ||
              objectValue[key] === "" ||
              objectValue[key] === undefined ||
              objectValue[key] === null)
          ) {
            delete objectValue[key];
          }
        }
      }
      return objectValue;
    }
    case "array": {
      if (!schema.items) return undefined;
      // minimum item count
      const ct = schema.minItems || 0;
      // tuple-typed arrays
      if (schema.items.constructor === Array) {
        const values = schema.items.map(function (item) {
          return getSchemaDefaults(item as any, useEmptyValues);
        });
        // remove undefined items at the end (unless required by minItems)
        for (let i = values.length - 1; i >= 0; i--) {
          if (typeof values[i] !== "undefined") {
            break;
          }
          if (i + 1 > ct) {
            values.pop();
          }
        }
        return values;
      }
      // object-typed arrays
      const value = getSchemaDefaults(schema.items as JSONSchema7, useEmptyValues);
      if (typeof value === "undefined") {
        return [];
      } else {
        const values = [];
        for (let i = 0; i < Math.max(0, ct); i++) {
          values.push(cloneObject(value));
        }
        return values;
      }
    }
    case "string":
      if (useEmptyValues === true) return "";
      return undefined;
    case "boolean":
      if (useEmptyValues === true) return false;
      return undefined;
    case "number":
      if (useEmptyValues === true) return 0;
      return undefined;
  }
}

export function removeRequired(schema: any): any {
  return removePropertyRecursively(schema, "required");
}
