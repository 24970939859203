import { mergeSchemas } from "../schema.utils";
import { ApplicationContent, ApplicationContentSchema } from "./application-content";
import { ApplicationDomain, ApplicationDomainSchema } from "./application-domain";
import { ApplicationLayout, ApplicationLayoutSchema } from "./application-layout";
import { InnerTranslation, createInnerTranslationSchema } from "./inner-translation";
import { Model, ModelSchema } from "./model";
import { PwaConfiguration, PwaConfigurationSchema } from "./pwa-configuration";
import { Theme, ThemeSchema } from "./theme";

export interface Application extends Model {
  title: Array<InnerTranslation>;
  content?: ApplicationContent[];
  layouts?: Array<ApplicationLayout>;
  domains?: Array<ApplicationDomain>;
  theme: Theme;
  configuration?: any;
  pwaConfiguration?: PwaConfiguration;
}

export const ApplicationSchema = mergeSchemas(ModelSchema, {
  type: "object",
  properties: {
    title: {
      "x-schema-form": {
        inputWidget: "I18nInput",
        filterWidget: "I18nFilter"
      },
      type: "array",
      items: createInnerTranslationSchema({ minLength: 3 })
    },
    content: {
      additionalProperties: false,
      type: "array",
      items: ApplicationContentSchema
    },
    layouts: {
      additionalProperties: false,
      type: "array",
      items: ApplicationLayoutSchema
    },
    domains: {
      additionalProperties: false,
      type: "array",
      items: ApplicationDomainSchema
    },
    theme: {
      additionalProperties: false,
      ...ThemeSchema
    },
    configuration: {
      type: "object",
      additionalProperties: false
    },
    pwaConfiguration: {
      additionalProperties: false,
      ...PwaConfigurationSchema
    }
  },
  required: ["title", "theme"]
});
