@if (!userEntries) {
  <div>
    <smallstack-loader></smallstack-loader>
  </div>
}
@if (userEntries) {
  <div>
    <table mat-table [dataSource]="userEntries">
      <ng-container matColumnDef="creationdate">
        <td mat-cell *matCellDef="let user" style="width: 150px; padding-right: 5px; white-space: nowrap">
          <smallstack-date [timestamp]="user.createdAt"></smallstack-date>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
}

@if (userEntries?.length === 0) {
  <p class="text-muted" align="center" style="padding: 20px">
    <smallstack-i18n data="@@dashboard.widgets.lastnewuserregistrations.nousersavailable"></smallstack-i18n>
  </p>
}

@if (totalUserEntriesCount > 0) {
  <p class="text-muted" align="center">
    <smallstack-i18n
      data="@@dashboard.widgets.lastnewuserregistrations.usercounter"
      [replacers]="{ count: totalUserEntriesCount }"
    >
    </smallstack-i18n>
  </p>
}
