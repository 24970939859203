// filter widgets
export const WIDGET_FILTER_INPUT_BOOLEAN = "BooleanFilter";
export const WIDGET_FILTER_INPUT_DATE = "DateFilter";
export const WIDGET_FILTER_INPUT_DATE_STRING = "DateStringFilter";
export const WIDGET_FILTER_INPUT_DATE_TIME = "DateTimeFilter";
export const WIDGET_FILTER_INPUT_I18N = "I18nFilter";
export const WIDGET_FILTER_INPUT_NUMBER = "NumberFilter";
export const WIDGET_FILTER_INPUT_SELECT = "SelectFilter";
export const WIDGET_FILTER_INPUT_STRING = "StringFilter";
export const WIDGET_FILTER_INPUT_TIME_SPAN = "TimeSpanFilter";
export const WIDGET_FILTER_INPUT_FOREIGN_KEY_CHAIN = "ForeignKeyChainFilter";

// input widgets
export const WIDGET_FORM_INPUT_AUTO_GEOLOCATION = "AutoGeolocationInput";
export const WIDGET_FORM_INPUT_BOOLEAN = "BooleanInput";
export const WIDGET_FORM_INPUT_COLOR = "ColorInput";
export const WIDGET_FORM_INPUT_COORDINATES = "CoordinatesInput";
export const WIDGET_FORM_INPUT_CUSTOM_FIELDS = "CustomFieldsInput";
export const WIDGET_FORM_INPUT_DATE = "DateInput";
export const WIDGET_FORM_INPUT_DATE_STRING = "DateStringInput";
export const WIDGET_FORM_INPUT_DATE_TIME = "DateTimeInput";
export const WIDGET_FORM_INPUT_FILE_URL = "FileUrlInput";
export const WIDGET_FORM_INPUT_FILE_ID = "FileIdInput";
export const WIDGET_FORM_INPUT_HTML_SIZING = "HtmlSizingInput";
export const WIDGET_FORM_INPUT_GOOGLE_MAPS = "GoogleMapsInput";
export const WIDGET_FORM_INPUT_I18N = "I18nInput";
export const WIDGET_FORM_INPUT_ICON = "IconInput";
export const WIDGET_FORM_INPUT_LINKS = "LinksInput";
export const WIDGET_FORM_INPUT_MANGO_QUERY = "MangoQueryInput";
export const WIDGET_FORM_INPUT_NUMBER = "NumberInput";
export const WIDGET_FORM_INPUT_SELECT = "SelectInput";
export const WIDGET_FORM_INPUT_SIMPLE_SELECT = "SimpleSelectInput";
export const WIDGET_FORM_INPUT_STRING = "StringInput";
export const WIDGET_FORM_INPUT_TEXTAREA = "TextareaInput";
export const WIDGET_FORM_INPUT_TIME_SPAN = "TimeSpanInput";
export const WIDGET_FORM_INPUT_TYPE_DESCRIPTOR = "TypeDescriptorInput";
export const WIDGET_FORM_INPUT_WIDGET_TREE = "WidgetTreeInput";
export const WIDGET_FORM_INPUT_BREAKPOINT = "BreakpointInput";

// view widgets
export const WIDGET_VIEW_ARRAY = "ArrayView";
export const WIDGET_VIEW_BOOLEAN = "BooleanView";
export const WIDGET_VIEW_COORDINATES = "CoordinatesView";
export const WIDGET_VIEW_DATE = "DateView";
export const WIDGET_VIEW_DATE_TIME = "DateTimeView";
export const WIDGET_VIEW_I18N = "I18nView";
export const WIDGET_VIEW_IMAGE = "ImageView";
export const WIDGET_VIEW_LINKS = "LinksView";
export const WIDGET_VIEW_NUMBER = "NumberView";
export const WIDGET_VIEW_OBJECT = "ObjectView";
export const WIDGET_VIEW_SELECT = "SelectView";
export const WIDGET_VIEW_STRING = "StringView";
export const WIDGET_VIEW_TIME = "TimeView";
export const WIDGET_VIEW_TIME_SPAN = "TimeSpanView";

// normal widgets
export const WIDGET_TEXT = "Text";
export const WIDGET_IMAGE = "Image";
