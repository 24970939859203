import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { PageableCrudStore } from "@smallstack/store";
import { isNonEmptyStoreSearch } from "@smallstack/store-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { computedAsync } from "ngxtension/computed-async";

@Widget({
  name: "DataV1",
  templateName: "Daten",
  templateDescription: "Beschafft Daten und reicht diese an weitere Widgets weiter",
  tags: AllWidgetTags,
  icon: "database",
  dataSchema: {
    type: "object",
    properties: {
      dataType: {
        title: "Datentyp",
        description: "Wählen Sie hier den Datentyp aus",
        "x-schema-form": {
          widget: "types"
        }
      },
      filter: {
        title: "Filter",
        description: "Hier können Sie Ihre Daten filtern",
        type: "object",
        "x-schema-form": { widget: "searchquery" }
      },
      pageSize: {
        title: "Anzahl Datensätze",
        description: "Wie viele Datensätze sollen initial geladen werden?",
        enum: [10, 25, 50, 100],
        default: 10
      }
    }
  }
})
@Component({
  selector: "smallstack-data-widget",
  templateUrl: "./data-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataWidgetComponent extends BaseWidgetComponent {
  private storeRegistry = inject(StoreRegistry);

  protected store = computedAsync<PageableCrudStore>(
    async () => {
      const data = this.data();
      if (!data?.dataType) return undefined;
      const store = this.storeRegistry.getStore<PageableCrudStore>(data.dataType);
      if (!store) throw new Error("No store found for name " + data.dataType);
      if (data.filter && isNonEmptyStoreSearch(data.filter)) await store.search(data.filter);
      else await store.load();
      return store;
    },
    { initialValue: undefined }
  );
}
