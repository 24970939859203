import { Page } from "@smallstack/api-shared";
import {
  ContextService,
  EnvironmentKeys,
  EnvironmentService,
  createAxiosHeaders,
  getSignalOrRawValue
} from "@smallstack/client-common";
import { MAX_API_PAGE_SIZE } from "@smallstack/core-common";
import { Model, convertTypePathToApiPath } from "@smallstack/typesystem";
import { CrudService, CrudServiceOptions } from "./crud.service";

export class FetchCrudService<TModel extends Model = Model> implements CrudService {
  constructor(
    protected options: CrudServiceOptions,
    private environmentService: EnvironmentService,
    private contextService: ContextService
  ) {}

  /** Gets a model by id */
  public async get(id: string): Promise<TModel> {
    const baseUrl = this.getBaseUrl();
    const response = await fetch(baseUrl + "/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      }
    });
    return await response.json();
  }

  /** Gets all models */
  public async getAll(): Promise<TModel[]> {
    const models: TModel[] = [];

    // get first page, evaluate response and get all other pages in parallel
    const responsePage: Page<TModel> = await this.getPage(1, MAX_API_PAGE_SIZE);
    if (responsePage?.elements instanceof Array) models.push(...responsePage.elements);
    if (responsePage.totalPages === 1) return models;

    // get all other pages in parallel
    const promises: Promise<Page<TModel>>[] = [];
    const pagesTotal = responsePage.totalPages;
    for (let i = 2; i <= pagesTotal; i++) {
      promises.push(this.getPage(i, MAX_API_PAGE_SIZE));
    }
    const responsePages = await Promise.all(promises);
    for (const responsePage of responsePages) {
      if (responsePage?.elements instanceof Array) models.push(...responsePage.elements);
    }
    return models;
  }

  public async getPage(page: number, pageSize: number): Promise<Page<TModel>> {
    const baseUrl = this.getBaseUrl();
    const response = await fetch(baseUrl + `?page=${page}&size=${pageSize}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      }
    });
    return await response.json();
  }

  /** Creates a model */
  public async post(model: TModel): Promise<TModel> {
    if (!model) throw new Error("model is required");
    const baseUrl = this.getBaseUrl();
    const response = await fetch(baseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      },
      body: JSON.stringify(model)
    });
    return await response.json();
  }

  /** Deletes a model */
  public async del(id: string): Promise<void> {
    const baseUrl = this.getBaseUrl();
    await fetch(baseUrl + "/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      }
    });
  }

  /** Patches a model */
  public async patch(id: string, model: Partial<TModel>): Promise<TModel> {
    if (!id) throw new Error("id is required");
    if (!model) throw new Error("model is required");
    const baseUrl = this.getBaseUrl();
    const response = await fetch(baseUrl + "/" + id, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      },
      body: JSON.stringify(model)
    });
    return await response.json();
  }

  /** Replaces a model. Please consider using a patch instead. */
  public async put(model: TModel): Promise<TModel> {
    if (!model) throw new Error("model is required");
    const baseUrl = this.getBaseUrl();
    const response = await fetch(baseUrl + "/" + model.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...createAxiosHeaders(this.contextService.context())
      },
      body: JSON.stringify(model)
    });
    return await response.json();
  }

  /** If model has an id, then the model is updated via put, otherwise created via post */
  public postOrPut(model: TModel): Promise<TModel> {
    if (!model) throw new Error("model is required");
    if (model.id) {
      return this.put(model);
    } else {
      return this.post(model);
    }
  }

  protected getBaseUrl(): string {
    const apiPath = convertTypePathToApiPath(getSignalOrRawValue(this.options.typePath));
    return this.environmentService.get<string>(EnvironmentKeys.API_URL) + "/" + apiPath;
  }
}
