import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class IconThemeService {
  public theme$: BehaviorSubject<string> = new BehaviorSubject<string>("fluency-systems-regular");
  public size$: BehaviorSubject<number> = new BehaviorSubject<number>(48);
  public colors: { primary: string; warn: string; success: string } = {
    primary: "546e7a",
    warn: "d32f2f",
    success: "33af54"
  };

  private aliases: { [alias: string]: string } = {};

  public setAliases(aliases: { [alias: string]: string }): void {
    this.aliases = aliases;
  }

  public getAlias(alias: string): string {
    if (!this.aliases) return alias;
    if (this.aliases[alias]) return this.aliases[alias];
    return alias;
  }

  public getColor(color: string): string {
    switch (color) {
      case "primary":
      case "warn":
      case "success":
        return this.colors[color];
      default:
        return color;
    }
  }
}
