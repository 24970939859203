import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  templateUrl: "./beta-border.component.html",
  styleUrls: ["./beta-border.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-beta-border",
  standalone: true,
  imports: [MatTooltipModule]
})
export class BetaBorderComponent {}
