/* eslint-disable @angular-eslint/no-input-rename */

import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
  signal
} from "@angular/core";
import { ConvertToSubjectPipe, EXTENSION_SLOT_COMPONENT } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { TranslationStore } from "@smallstack/i18n-components";
import { TypeSchema } from "@smallstack/typesystem";
import { distinctUntilChangedObj, filterNullish } from "@smallstack/utils";
import { Observable } from "rxjs";
import { SchemaFormInputsRegistry } from "../../services/schema-form-widget-registry";
import { WidgetRegistry } from "../../services/widget.registry";
import { SchemaFormBaseWidget } from "../schema-form-base/schema-form-base-widget.component";
import { SchemaFormBase } from "../schema-form-base/schema-form-base.component";
import { WidgetRendererComponent } from "../widget-renderer/widget-renderer.component";

/**
 * A schema form widget renders a json schema form. It delegates UI rendering to widgets based on the schema form type & schema properties
 *
 * @example
 * <smallstack-schema-form-widget [schema]="myJsonSchema" [options]="{showLabel: true}"></smallstack-schema-form-widget>
 */

@Component({
  selector: "smallstack-schema-form-widget,schema-form-widget",
  templateUrl: "./schema-form-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ConvertToSubjectPipe, WidgetRendererComponent]
})
export class SchemaFormWidgetComponent extends SchemaFormBase implements OnInit {
  @ViewChild("componentHost", { static: true, read: ViewContainerRef })
  public componentHost: ViewContainerRef;

  private renderedComponent: ComponentRef<SchemaFormBaseWidget>;

  protected renderError = signal<string>(undefined);
  protected widgetName = signal<string>(undefined);

  constructor(
    private schemaFormInputsRegistry: SchemaFormInputsRegistry,
    private injector: Injector,
    @Inject(EXTENSION_SLOT_COMPONENT) private extensionSlotComponent: any,
    private widgetRegistry: WidgetRegistry
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.schema$.pipe(filterNullish(), distinctUntilChangedObj()).subscribe(async (schema) => {
        if (schema && this.renderedComponent === undefined && this.widgetName() === undefined) {
          // try new widget registry
          const widget = this.widgetRegistry.getInputWidgetBySchema(schema);
          if (widget) this.widgetName.set(widget.name);
          else {
            const component: any = this.schemaFormInputsRegistry.getWidgetBySchema(schema);
            if (component) {
              Logger.info("SchemaFormWidget", "Using deprecated form input component: " + component.name);
              this.renderDeprecatedFormWidget(component, schema);
            } else {
              // try new widget registry
              // const widget = this.widgetRegistry.getInputWidgetBySchema(schema);
              // if (widget) this.widgetName$.next(widget.name);
              // else {
              Logger.error(
                "SchemaFormWidget",
                "no widget found for type " +
                  schema.type +
                  ", x-schema-form.widget: " +
                  schema["x-schema-form"]?.widget +
                  ", x-schema-form.inputWidget: " +
                  schema["x-schema-form"]?.inputWidget +
                  ", x-schema-form.type: " +
                  schema["x-schema-form"]?.type,
                schema
              );
              this.renderError.set(
                "no widget found for type " +
                  schema?.type +
                  ", x-schema-form.widget: " +
                  schema?.["x-schema-form"]?.widget +
                  ", x-schema-form.inputWidget: " +
                  schema?.["x-schema-form"]?.inputWidget +
                  ", x-schema-form.type: " +
                  schema?.["x-schema-form"]?.type
              );
            }
          }
        }
      })
    );
  }

  private renderDeprecatedFormWidget(component: any, schema: TypeSchema) {
    const injector = Injector.create({
      providers: [
        {
          provide: TranslationStore,
          useFactory: () => {
            return this.formService.translationStore;
          },
          deps: []
        }
      ],
      parent: this.injector
    });
    // add form component
    this.renderedComponent = this.componentHost.createComponent<SchemaFormBaseWidget>(component, {
      index: 0,
      injector
    });
    this.renderedComponent.setInput("path", this.path());
    this.renderedComponent.setInput("options", this.options());
    this.renderedComponent.changeDetectorRef.detectChanges();

    // render extensionSlot
    if (schema["x-schema-form"]?.backofficeSlot || schema["x-schema-form"]?.extensionSlot) {
      const extensionSlot: ComponentRef<{ name: string; context$: Observable<any> }> =
        this.componentHost.createComponent(this.extensionSlotComponent, {
          index: 1,
          injector
        });
      extensionSlot.instance.name = schema["x-schema-form"].backofficeSlot || schema["x-schema-form"].extensionSlot;
      extensionSlot.instance.context$ = this.formService.value();
    }
  }
}
