import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FileDto } from "@smallstack/axios-api-client";
import { IconComponent } from "@smallstack/theme-components";
import { FileStore } from "../../stores/file.store";
import { FileExplorerDialogComponent } from "../file-explorer-dialog/file-explorer-dialog.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-file-explorer-button",
  templateUrl: "./file-explorer-button.component.html",
  styleUrls: ["./file-explorer-button.component.scss"],
  standalone: true,
  imports: [IconComponent]
})
export class FileExplorerButtonComponent {
  @Input()
  public fileStore: FileStore;

  @Output() public readonly selected: EventEmitter<FileDto> = new EventEmitter<FileDto>();

  constructor(private matDialog: MatDialog) {}

  public openExplorer(): void {
    void this.matDialog
      .open(FileExplorerDialogComponent, { width: "80%", data: { fileStore: this.fileStore } })
      .afterClosed()
      .subscribe((file: FileDto) => {
        this.selected.emit(file);
      });
  }
}
