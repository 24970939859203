import { BillingPositionDto } from "@smallstack/axios-api-client";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TYPE_CUSTOMERS, TYPE_USERS } from "@smallstack/typesystem";
import { ContextRule } from "@smallstack/utils";
export interface TodoPriority {
  /** This value will be saved in databases */
  priority: number;
  label: InlineTranslation;
}

export interface TodoStatus {
  /** This value will be saved in databases */
  code: string;
  label: InlineTranslation;
  /** The Todo will be seen as closed if this flag is true and the todo is currently in this status */
  isClosed?: boolean;
}

export interface TodoConfiguration {
  statuses: TodoStatus[];
  priorities: TodoPriority[];
  /** If set to false, then Todos can only be created based on TodoTemplates */
  customTodoGenerationAllowed: boolean;
  /** If not set, all fields will be visible in editors and detail views */
  visibleFields?: string[];
  /** Decide, which user group can be used as owners */
  ownerType?: typeof TYPE_USERS | typeof TYPE_CUSTOMERS;
  /** If set to true, then startTime, dueDate etc. will only display date pickers and no time pickers */
  fixedTimes?: boolean;
  /** If set to true, only future times will be accepted for new todos */
  onlyFutureDates?: boolean;
  billingPdfTemplateId?: string;
  billingRoundingMinutes?: number;
  billingDefaultPosition?: BillingPositionDto;
  /** fields that are shown on the first page of the pdf */
  billingPdfFields?: string[];
  billingPdfShowBrutto?: boolean;
  /** if set, all entries will be grouped by this field (all other fields will be summed up if number, or concatenated if string) */
  billingPdfGroupByField?: string;
  /** fields that are shown in the second table  */
  billingPdfReportFields?: string[];
  billingPdfReportShowBrutto?: boolean;
  additionalCounterIds?: string[];
  wodisXmlCalculationTax?: number;
  wodisXmlShownTax?: number;
  /** define some rules to apply custom xml content to invoice positions */
  wodisInvoicePositionExtensions?: Array<{
    title: string;
    rules: ContextRule[];
  }>;
  worklogAutoSealUntagged?: boolean;
  worklogInvoicedTag?: string;
}

export const TODO_CONFIGURATION_KEY = "todo.configuration";

export const DEFAULT_TODO_CONFIGURATION: TodoConfiguration = {
  priorities: [
    {
      priority: 1,
      label: [
        { locale: "en_us", value: "Low" },
        { locale: "de_de", value: "Niedrig" }
      ]
    },
    {
      priority: 2,
      label: [
        { locale: "en_us", value: "Medium" },
        { locale: "de_de", value: "Mittel" }
      ]
    },
    {
      priority: 3,
      label: [
        { locale: "en_us", value: "High" },
        { locale: "de_de", value: "Hoch" }
      ]
    }
  ],
  statuses: [
    {
      code: "open",
      label: [
        { locale: "en_us", value: "Open" },
        { locale: "de_de", value: "Offen" }
      ]
    },
    {
      code: "inProgress",
      label: [
        { locale: "en_us", value: "In Progress" },
        { locale: "de_de", value: "In Bearbeitung" }
      ]
    },
    {
      code: "done",
      label: [
        { locale: "en_us", value: "Done" },
        { locale: "de_de", value: "Erledigt" }
      ],
      isClosed: true
    }
  ],
  customTodoGenerationAllowed: true,
  ownerType: TYPE_USERS,
  onlyFutureDates: true,
  fixedTimes: false,
  wodisXmlCalculationTax: 19,
  wodisXmlShownTax: 19,
  billingPdfFields: ["position", "label", "time", "calculation", "factor", "value"],
  billingPdfShowBrutto: false,
  billingPdfReportFields: ["position", "label", "person", "time", "calculation", "factor", "value"],
  billingPdfReportShowBrutto: false,
  worklogInvoicedTag: "In Rechnung gestellt"
};

/** Manages the configuration for todos */
export class TodoConfigurationService {
  public async getConfiguration(): Promise<TodoConfiguration> {
    return DEFAULT_TODO_CONFIGURATION;
  }
}
