@if (data(); as dataReactive) {
  <div class="flex flex-row space-x-0 items-start h-full w-full min-h-full min-w-full">
    <div class="flex flex-col space-y-1">
      @for (tab of dataReactive?.tabs; track tab; let index = $index) {
        <div class="sm-tab" [ngClass]="{ 'sm-active-tab': currentTab() === index }" (click)="currentTab.set(index)">
          <smallstack-icon [name]="tab.icon" size="24"></smallstack-icon>
        </div>
      }
    </div>
    <div class="sm-tab-container flex-auto" [ngStyle]="{ minHeight: dataReactive?.tabs?.length * 42 + 'px' }">
      <smallstack-widget-child
        [data]="data()"
        (dataChange)="dataChange.emit($event)"
        [context]="context()"
        property="tabs.{{ currentTab() }}.component"
      ></smallstack-widget-child>
    </div>
  </div>
}
