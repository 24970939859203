import { InnerTranslation, createInnerTranslationSchema } from "./inner-translation";
import { Model, ModelSchema } from "./model";

export enum CustomFieldValueType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Date = "date",
  DateString = "dateString"
}

export interface InlineCustomFieldValue {
  value?: string;
  title?: Array<InnerTranslation>;
}

export interface CustomField extends Model {
  title: Array<InnerTranslation>;
  key: string;
  type: CustomFieldValueType;
  predefinedValue?: InlineCustomFieldValue;
}

export const CustomFieldSchema = {
  type: "object",
  properties: {
    ...ModelSchema.properties,
    title: createInnerTranslationSchema(),
    key: {
      type: "string"
    },
    type: {
      type: "string",
      enum: Object.values(CustomFieldValueType)
    },
    predefinedValue: {
      type: "object",
      properties: {
        value: {
          type: "string"
        },
        title: createInnerTranslationSchema()
      }
    }
  },
  required: ["title", "key", "type"]
};
