/* eslint-disable complexity */
/* eslint-disable no-console */
import { dasherize, snakeCaseToCamelCase } from "@smallstack/utils";
import pluralize, { singular } from "pluralize";
import { CollectionNames } from "../collection-names";
import {
  ALL_TYPE_NAMES,
  CUSTOM_TYPE_PATH_PREFIX,
  CUSTOM_TYPE_PREFIX,
  TYPE_APPLICATIONS,
  TYPE_AUDIT_LOGS,
  TYPE_CALENDARS,
  TYPE_CALENDAR_ENTRIES,
  TYPE_CHECKIN_EVENTS,
  TYPE_CHECKIN_VISITORS,
  TYPE_CMS_PAGES,
  TYPE_COMPOUND_WIDGETS,
  TYPE_CONFIGURATIONS,
  TYPE_CONTACTS,
  TYPE_CONTACTS_CUSTOM_FIELDS,
  TYPE_COUNTRIES,
  TYPE_CRONJOBS,
  TYPE_CUSTOMERS,
  TYPE_CUSTOM_BACKOFFICES,
  TYPE_CUSTOM_FIELDS,
  TYPE_DASHBOARDS,
  TYPE_DEVICES,
  TYPE_EMAILS,
  TYPE_EMAIL_TEMPLATES,
  TYPE_EXTENSION_SLOTS,
  TYPE_FILES,
  TYPE_FORMS,
  TYPE_LANGUAGES,
  TYPE_LOCALES,
  TYPE_MAILING_CHANNELS,
  TYPE_MAILING_LISTS,
  TYPE_MESSAGES,
  TYPE_MESSAGE_THREADS,
  TYPE_MY_TODOS,
  TYPE_MY_WORKLOGS,
  TYPE_OPT_INS,
  TYPE_ORDERS,
  TYPE_PDF_TEMPLATES,
  TYPE_PRICE_ZONES,
  TYPE_PRODUCTS,
  TYPE_SHOPPING_CARTS,
  TYPE_SUBSCRIPTIONS,
  TYPE_SUPPLIERS,
  TYPE_TASKS,
  TYPE_TODOS,
  TYPE_TODO_SURCHARGES,
  TYPE_TODO_TEMPLATES,
  TYPE_TRANSLATIONS,
  TYPE_TYPES,
  TYPE_USERS,
  TYPE_WORKFLOWS,
  TYPE_WORKLOGS,
  T_APPLICATION,
  T_CALENDARS,
  T_CALENDAR_ENTRIES,
  T_CMS_PAGE,
  T_CONFIGURATION,
  T_CONTACT,
  T_COUNTRY,
  T_CRONJOB,
  T_CUSTOM_BACKOFFICE,
  T_DASHBOARD,
  T_DEVICE,
  T_EMAIL,
  T_EMAIL_TEMPLATE,
  T_EXTENSION_SLOTS,
  T_FILE,
  T_LANGUAGE,
  T_LOCALE,
  T_MAILING_CHANNELS,
  T_MAILING_LISTS,
  T_MESSAGE,
  T_OPTIN,
  T_ORDER,
  T_PDF_TEMPLATES,
  T_PRICEZONE,
  T_PRODUCTS,
  T_SHOPPING_CART,
  T_SUBSCRIPTIONS,
  T_SUPPLIER,
  T_TASK,
  T_TODOS,
  T_TODO_TEMPLATES,
  T_TRANSLATION,
  T_USERS,
  T_WORKFLOW,
  T_WORKLOGS
} from "./type-names";

/** For simplicity, old names should be lowercased */
const TypeMappings: { [old: string]: string } = {
  dashboards: TYPE_DASHBOARDS,
  custombackoffice: TYPE_CUSTOM_BACKOFFICES,
  custom_backoffice: TYPE_CUSTOM_BACKOFFICES,
  companyuser: TYPE_USERS,
  projectlanguages: TYPE_LANGUAGES,
  projectlocales: TYPE_LOCALES,
  projecttranslation: TYPE_TRANSLATIONS,
  projectuser: TYPE_USERS,
  todotemplates: TYPE_TODO_TEMPLATES,
  [T_CONTACT]: TYPE_CONTACTS,
  [T_PRODUCTS]: TYPE_PRODUCTS,
  [TYPE_FORMS]: TYPE_FORMS,
  [T_CMS_PAGE]: TYPE_CMS_PAGES,
  [T_APPLICATION]: TYPE_APPLICATIONS,
  [T_USERS]: TYPE_USERS,
  [T_OPTIN]: TYPE_OPT_INS,
  [T_CONFIGURATION]: TYPE_CONFIGURATIONS,
  [T_WORKFLOW]: TYPE_WORKFLOWS,
  [T_DEVICE]: TYPE_DEVICES,
  [T_CUSTOM_BACKOFFICE]: TYPE_CUSTOM_BACKOFFICES,
  [T_SUPPLIER]: TYPE_SUPPLIERS,
  [T_SHOPPING_CART]: TYPE_SHOPPING_CARTS,
  [T_PRICEZONE]: TYPE_PRICE_ZONES,
  [T_ORDER]: TYPE_ORDERS,
  [T_MESSAGE]: TYPE_MESSAGES,
  [T_MAILING_LISTS]: TYPE_MAILING_LISTS,
  [T_MAILING_CHANNELS]: TYPE_MAILING_CHANNELS,
  [T_TASK]: TYPE_TASKS,
  [T_DASHBOARD]: TYPE_DASHBOARDS,
  [T_CRONJOB]: TYPE_CRONJOBS,
  [T_LOCALE]: TYPE_LOCALES,
  [T_COUNTRY]: TYPE_COUNTRIES,
  [T_LANGUAGE]: TYPE_LANGUAGES,
  [T_TRANSLATION]: TYPE_TRANSLATIONS,
  [T_EMAIL]: TYPE_EMAILS,
  [T_EMAIL_TEMPLATE]: TYPE_EMAIL_TEMPLATES,
  [T_CALENDARS]: TYPE_CALENDARS,
  [T_CALENDAR_ENTRIES]: TYPE_CALENDAR_ENTRIES,
  [T_FILE]: TYPE_FILES,
  [T_EXTENSION_SLOTS]: TYPE_EXTENSION_SLOTS,
  [T_TODOS]: TYPE_TODOS,
  [T_TODO_TEMPLATES]: TYPE_TODO_TEMPLATES,
  [T_SUBSCRIPTIONS]: TYPE_SUBSCRIPTIONS,
  [T_PDF_TEMPLATES]: TYPE_PDF_TEMPLATES,
  [T_WORKLOGS]: TYPE_WORKLOGS,
  [TYPE_MY_WORKLOGS]: TYPE_WORKLOGS,
  visitor: TYPE_CHECKIN_VISITORS,
  visitors: TYPE_CHECKIN_VISITORS,
  visitorevent: TYPE_CHECKIN_EVENTS,
  visitorevents: TYPE_CHECKIN_EVENTS
};

/** returns a proper type path like in libs\shared\typesystem\src\lib\typesystem\type-names.ts  */
// eslint-disable-next-line complexity
export function normalizeTypePath(typePath: string): string {
  if (!typePath) return;

  // all good if it's already a known type
  if (ALL_TYPE_NAMES.includes(typePath)) return typePath;

  // all good if it starts with the custom type prefix
  if (typePath.includes("_/")) typePath = typePath.replace(/_\//g, "_");
  if (typePath.includes("_-")) typePath = typePath.replace(/_-/g, "_");

  // special wurst for LC
  if (typePath.startsWith("custom-real")) typePath = typePath.replace("custom-", CUSTOM_TYPE_PATH_PREFIX);
  if (typePath === "CustomRealestates" || typePath === "CustomRealestate")
    return CUSTOM_TYPE_PATH_PREFIX + "realestates";

  // custom paths
  if (typePath.startsWith(CUSTOM_TYPE_PATH_PREFIX)) return typePath;

  // try custom mappings
  if (TypeMappings[typePath]) return TypeMappings[typePath];

  // try guessing
  typePath = dasherize(typePath).toLowerCase();
  typePath = pluralize(typePath);
  typePath = typePath.replace(CUSTOM_TYPE_PATH_PREFIX + "/", CUSTOM_TYPE_PATH_PREFIX);

  return typePath.replace(CUSTOM_TYPE_PREFIX, CUSTOM_TYPE_PATH_PREFIX);
}
/** Converts 'todo-templates' to TodoTemplate */
export function typePathToClassName(typePath: string): string {
  switch (typePath) {
    case "calendar-dynamics":
      return "CalendarDynamics";
    case TYPE_CUSTOMERS:
      return "User";
    case TYPE_CUSTOM_FIELDS:
      return "CustomField";
    case "contact-metadata":
      return "ContactMetadata";
    case "cms-page-metadata":
      return "CmsPageMetadata";
    case "type-configuration-options":
      return "TypeConfigurationOptions";
    case "material-colors":
      return "MaterialColors";
    case "toolbar-theme-colors":
      return "ToolbarThemeColors";
    case "header-theme-colors":
      return "HeaderThemeColors";
    case "workflow-block-visuals":
      return "WorkflowBlockVisuals";
    case "form":
      return TYPE_FORMS;
    default:
      return singular(snakeCaseToCamelCase(typePath));
  }
}

/** Convert type paths to real api paths, e.g. "customers" to "users" */
export function convertTypePathToApiPath(typePath: string): string {
  if (typePath.startsWith(CUSTOM_TYPE_PATH_PREFIX))
    return "data/custom/" + typePath.replace(CUSTOM_TYPE_PATH_PREFIX, "");
  if (typePath.startsWith(CUSTOM_TYPE_PREFIX)) return "data/custom/" + typePath.replace(CUSTOM_TYPE_PREFIX, "");
  switch (typePath) {
    case TYPE_CUSTOMERS:
      return TYPE_USERS;
    case TYPE_COMPOUND_WIDGETS:
      return "widgets/compound";
    case TYPE_CUSTOM_BACKOFFICES:
      return "custombackoffices";
    default:
      return typePath;
  }
}

export function convertCollectionNameToTypePath(collectionName: string): string {
  const convertedCollectionName = collectionName.replace(/_/g, "-");
  if (ALL_TYPE_NAMES.includes(convertedCollectionName)) return convertedCollectionName;
  if (ALL_TYPE_NAMES.includes(normalizeTypePath(convertedCollectionName)))
    return normalizeTypePath(convertedCollectionName);

  if (collectionName === "types") return TYPE_TYPES;
  if (collectionName.startsWith(CUSTOM_TYPE_PATH_PREFIX)) return collectionName;
  if (collectionName.startsWith(CUSTOM_TYPE_PREFIX))
    return collectionName.replace(CUSTOM_TYPE_PREFIX, CUSTOM_TYPE_PATH_PREFIX);

  throw new Error("collection name needs an exception in function convertCollectionNameToTypePath: " + collectionName);
}

export function convertTypePathToCollectionName(typePath: string): string {
  if (!typePath) return undefined;
  switch (typePath) {
    case TYPE_TODO_TEMPLATES:
      return CollectionNames.COLLECTION_TODO_TEMPLATES;
    case TYPE_EXTENSION_SLOTS:
      return CollectionNames.COLLECTION_EXTENSION_SLOTS;
    case TYPE_CUSTOM_FIELDS:
      return CollectionNames.COLLECTION_CUSTOM_FIELDS;
    case TYPE_COMPOUND_WIDGETS:
      return CollectionNames.COLLECTION_COMPOUND_WIDGETS;
    case TYPE_CUSTOM_BACKOFFICES:
      return CollectionNames.COLLECTION_CUSTOM_BACKOFFICES;
    case TYPE_TASKS:
      return CollectionNames.COLLECTION_TASKS;
    case TYPE_WORKFLOWS:
      return CollectionNames.COLLECTION_WORKFLOWS;
    case TYPE_USERS:
      return CollectionNames.COLLECTION_USERS;
    case TYPE_PRODUCTS:
      return CollectionNames.COLLECTION_PRODUCTS;
    case TYPE_ORDERS:
      return CollectionNames.COLLECTION_ORDERS;
    case TYPE_EMAILS:
      return CollectionNames.COLLECTION_EMAILS;
    case TYPE_EMAIL_TEMPLATES:
      return CollectionNames.COLLECTION_EMAIL_TEMPLATES;
    case TYPE_CALENDARS:
      return CollectionNames.COLLECTION_CALENDARS;
    case TYPE_MESSAGES:
      return CollectionNames.COLLECTION_MESSAGES;
    case TYPE_MESSAGE_THREADS:
      return CollectionNames.COLLECTION_MESSAGE_THREADS;
    case TYPE_CALENDAR_ENTRIES:
      return CollectionNames.COLLECTION_CALENDAR_ENTRIES;
    case TYPE_FILES:
      return CollectionNames.COLLECTION_FILES;
    case TYPE_CONTACTS:
      return CollectionNames.COLLECTION_CONTACTS;
    case TYPE_MAILING_LISTS:
      return CollectionNames.COLLECTION_MAILING_LISTS;
    case TYPE_MAILING_CHANNELS:
      return CollectionNames.COLLECTION_MAILING_CHANNELS;
    case TYPE_SUPPLIERS:
      return CollectionNames.COLLECTION_SUPPLIERS;
    case TYPE_PRICE_ZONES:
      return CollectionNames.COLLECTION_PRICEZONES;
    case TYPE_SHOPPING_CARTS:
      return CollectionNames.COLLECTION_SHOPPING_CARTS;
    case TYPE_SUBSCRIPTIONS:
      return CollectionNames.COLLECTION_SUBSCRIPTIONS;
    case TYPE_CUSTOMERS:
      return CollectionNames.COLLECTION_USERS;
    case TYPE_CRONJOBS:
      return CollectionNames.COLLECTION_CRONJOBS;
    case TYPE_LANGUAGES:
      return CollectionNames.COLLECTION_LANGUAGES;
    case TYPE_LOCALES:
      return CollectionNames.COLLECTION_LOCALES;
    case TYPE_TRANSLATIONS:
      return CollectionNames.COLLECTION_TRANSLATIONS;
    case TYPE_DEVICES:
      return CollectionNames.COLLECTION_DEVICES;
    case TYPE_AUDIT_LOGS:
      return CollectionNames.COLLECTION_AUDIT_LOG_RECORDS;
    case TYPE_WORKLOGS:
      return CollectionNames.COLLECTION_WORKLOGS;
    case TYPE_TODOS:
      return CollectionNames.COLLECTION_TODOS;
    case TYPE_MY_TODOS:
      return CollectionNames.COLLECTION_TODOS;
    case TYPE_MY_WORKLOGS:
      return CollectionNames.COLLECTION_WORKLOGS;
    case TYPE_APPLICATIONS:
      return CollectionNames.COLLECTION_APPLICATIONS;
    case TYPE_CONFIGURATIONS:
      return CollectionNames.COLLECTION_CONFIGURATIONS;
    case TYPE_PDF_TEMPLATES:
      return CollectionNames.COLLECTION_PDF_TEMPLATES;
    case TYPE_CHECKIN_VISITORS:
      throw new Error("This is a custom data type!");
    case TYPE_TODO_SURCHARGES:
      return CollectionNames.COLLECTION_TODO_SURCHARGES;
    case TYPE_CONTACTS_CUSTOM_FIELDS:
      return CollectionNames.COLLECTION_CONTACT_CUSTOM_FIELDS;
    default:
      return typePath;
  }
}

export function convertClassNameToTypePath(className: string): string {
  if (!className) return undefined;
  className = dasherize(className);
  return pluralize(className);
}

/** Some paths are not directly linkable to schema names, e.g. 'todos/mine' should still use the 'todos' schema */
export function convertTypePathToSchemaName(typePath: string): string {
  switch (typePath) {
    case TYPE_MY_TODOS:
      return TYPE_TODOS;
    case TYPE_MY_WORKLOGS:
      return TYPE_WORKLOGS;
    case TYPE_CUSTOMERS:
      return TYPE_USERS;
    case TYPE_AUDIT_LOGS:
      return "audit-log-records";
    case "custom/backoffices":
      return TYPE_CUSTOM_BACKOFFICES;
    default:
      return typePath;
  }
}
