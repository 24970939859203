import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
import { replaceVariables } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "ModelToString",
  icon: "show-property",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      type: {
        type: "string",
        title: "Datentyp",
        "x-schema-form": { widget: "types" }
      },
      id: {
        type: "string",
        title: "Id",
        description: "Unterstützt Kontext Variablen"
      }
    }
  },
  templateName: "Datensatz Anzeige",
  templateDescription:
    "Zeigt eine Text basierte Präsentation der gegebenen Entität an, z.B. die E-Mail Adresse eines Benutzers oder den Titel eines Produktes"
})
@Component({
  selector: "smallstack-model-to-string-widget",
  templateUrl: "./model-to-string-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelToStringWidgetComponent extends BaseWidgetComponent {
  private dataTypePath = computed(() => this.data().type);
  private store = injectStore({ typePath: this.dataTypePath });
  private typeService = inject(TypeService);
  protected representation = computed(() => {
    const data = this.data();
    const context = this.context();
    if (!data || !context || !data.id) return;
    const id = replaceVariables(data.id, context);
    if (id) {
      const model = this.store.getById(id);
      const type = this.typeService.getByPath(data.type);
      if (model && type) return this.typeService.getRepresentation(type, model);
    }
  });
}
