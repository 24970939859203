<div class="dialog">
  <div class="dialog-title">Verknüpfung hinzufügen</div>
  <div class="dialog-content">
    <smallstack-type-select
      [(selectedTypePath)]="link.type"
      (selectedTypePathChange)="updateSchema($event)"
      [typeFilter]="{ tags: typeTags, linkableTypes: data.linkableTypes }"
    ></smallstack-type-select>
    @if (link?.type) {
      <smallstack-form [schema]="schema" [(value)]="link" [options]="{ compactMode: true, showLabel: false }">
        <smallstack-form-table></smallstack-form-table>
      </smallstack-form>
    }
  </div>
  <div class="dialog-actions">
    <button [mat-dialog-close]="link" class="btn btn-primary">
      <smallstack-i18n data="@@actions.add"></smallstack-i18n>
    </button>
  </div>
</div>
