import { registerLocaleData } from "@angular/common";
import { setDefaultOptions } from "date-fns";

export async function loadAngularLocaleDataAsync(locale: string): Promise<void> {
  switch (true) {
    case locale.startsWith("de_"):
      // import angular locale data for de
      await import("@angular/common/locales/de").then((angularLocaleData) => {
        registerLocaleData(angularLocaleData.default);
      });
      // import date-fns locale data for de
      await import("date-fns/locale/de").then((dateFnsLocaleData) => {
        setDefaultOptions({ locale: dateFnsLocaleData.de });
      });
      break;
    case locale.startsWith("fr_"):
      // import angular locale data for fr
      await import("@angular/common/locales/fr").then((angularLocaleData) => {
        registerLocaleData(angularLocaleData);
      });
      // import date-fns locale data for fr
      await import("date-fns/locale/fr").then((dateFnsLocaleData) => {
        setDefaultOptions({ locale: dateFnsLocaleData.fr });
      });
      break;
    case locale.startsWith("es_"):
      // import angular locale data for es
      await import("@angular/common/locales/es").then((angularLocaleData) => {
        registerLocaleData(angularLocaleData.default);
      });
      // import date-fns locale data for es
      await import("date-fns/locale/es").then((dateFnsLocaleData) => {
        setDefaultOptions({ locale: dateFnsLocaleData.es });
      });
      break;
    case locale.startsWith("it_"):
      // import angular locale data for it
      await import("@angular/common/locales/it").then((angularLocaleData) => {
        registerLocaleData(angularLocaleData.default);
      });
      // import date-fns locale data for it
      await import("date-fns/locale/it").then((dateFnsLocaleData) => {
        setDefaultOptions({ locale: dateFnsLocaleData.it });
      });
      break;
    case locale.startsWith("en_"):
    default:
      // import angular locale data for en
      await import("@angular/common/locales/en").then((angularLocaleData) => {
        registerLocaleData(angularLocaleData.default);
      });
      // import date-fns locale data for en
      await import("date-fns/locale/en-US").then((dateFnsLocaleData) => {
        setDefaultOptions({ locale: dateFnsLocaleData.enUS });
      });
  }
}
