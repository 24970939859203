import { Injector, Signal, WritableSignal, signal } from "@angular/core";
import { createObjectId } from "@smallstack/client-common";
import { Logger } from "@smallstack/core-common";
import { cloneObject, getJsonByPath, objectsEqual } from "@smallstack/utils";
import { EntityStore, StoreEntity, StoreInitOptions } from "./entity.store";
import { LoadingState } from "./loading-state";

export class InMemoryStore<Entity extends StoreEntity> implements EntityStore<Entity> {
  #loadingState: WritableSignal<LoadingState> = signal("init");
  public readonly loadingState: Signal<LoadingState> = this.#loadingState.asReadonly();

  #entities: WritableSignal<Entity[]> = signal([], { equal: objectsEqual });
  public readonly entities: Signal<Entity[]> = this.#entities.asReadonly();

  constructor(protected injector: Injector) {}

  public async sync(): Promise<void> {
    // nothing to do
  }

  public initialize(options: StoreInitOptions): Promise<void> {
    throw new Error("Method not implemented.");
  }

  /**
   * Get entity by id
   */
  public getById(id: string): Entity {
    return this.#entities()?.find((entity) => entity.id === id);
  }

  /**
   * Get entities by property, supports dot notation
   */
  public getByProperty(property: string, value: unknown): Entity[] {
    return this.#entities()?.filter((entity) => getJsonByPath(entity, property) === value);
  }

  /**
   * Get one entity by property, supports dot notation
   */
  public getOneByProperty(property: string, value: string): Entity {
    return this.#entities()?.find((entity) => getJsonByPath(entity, property) === value);
  }

  /**
   * Returns all entities that match all properties
   */
  public getMany(properties?: { [key: string]: unknown }): Entity[] {
    if (!properties) return this.#entities();
    return this.#entities()?.filter((entity) => {
      for (const key in properties) {
        if (getJsonByPath(entity, key) !== properties[key]) return false;
      }
      return true;
    });
  }

  /** sets the entities of the store, also sets the loading state to `loaded` */
  public setEntities(entities: Entity[]): void {
    if (!Array.isArray(entities)) {
      Logger.warning("InMemoryStore", "entities must be an array! Got: ", entities);
      return;
    }
    this.#entities.set(cloneObject(entities));
    this.#loadingState.set("loaded");
  }

  public setLoadingState(state: LoadingState): void {
    this.#loadingState.set(state);
  }

  public async updateEntity(entity: Entity): Promise<Entity> {
    this.#entities.update((entities) => {
      if (entities === undefined)
        throw new Error(
          "Entities are not set, you must call setEntities(entities: Entity[]) before calling updateEntity(entity: Entity)"
        );
      if (entity.id === undefined) throw new Error("Entity must have an id to be updated");
      const index = entities.findIndex((e) => e.id === entity.id);
      if (index === -1) {
        Logger.error("InMemoryStore", "Entity not found in store", entity);
        return entities;
      }
      entities[index] = entity;
      return entities;
    });
    return entity;
  }

  public async createEntity(entity: Entity): Promise<Entity> {
    if (entity.id === undefined) entity.id = createObjectId();
    this.#entities.update((entities) => {
      if (entities === undefined) entities = [];
      entities.push(entity);
      return entities;
    });
    return entity;
  }

  public async deleteEntity(id: string): Promise<void> {
    this.#entities.update((entities) => {
      if (entities === undefined)
        throw new Error(
          "Entities are not set, you must call setEntities(entities: Entity[]) before calling deleteEntity(entity: Entity)"
        );
      if (id === undefined) throw new Error("no id given to deleteEntity");
      return entities.filter((e) => e.id !== id);
    });
  }
}
