<div class="dialog">
  <div class="dialog-title flex flex-row gap-1 justify-start items-center place-items-center">
    @if (icon) {
      <smallstack-icon [name]="icon" />
    }
    <div class="flex flex-col">
      <smallstack-i18n [data]="data.title" />
      @if (data.subTitle) {
        <p class="dialog-sub-title"><smallstack-i18n [data]="data.subTitle" /></p>
      }
    </div>
  </div>
  <div class="dialog-content">
    <smallstack-type-editor
      #typeEditor
      [type]="data.type"
      [typeDescriptor]="data.typeDescriptor"
      [schemaOverride]="data.schema"
      [dialogRef]="dialogRef"
      [(model)]="data.model"
    />
  </div>
  <div class="dialog-actions">
    @if (data.showDeleteButton === true && data?.model?.id !== undefined) {
      <button class="btn btn-error" data-test="form-dialog-cancel-btn" [loadingFn]="remove()">
        <i class="fa-regular fa-trash"></i>
      </button>
    }
    <button class="btn" data-test="form-dialog-cancel-btn" (click)="dialogRef.close()">
      <smallstack-i18n data="@@actions.cancel" />
    </button>
    <button class="btn btn-primary" data-test="form-dialog-save-btn" [loadingFn]="validateAndSave()">
      <smallstack-i18n [data]="data?.model?.id === undefined ? '@@actions.create' : '@@actions.save'" />
    </button>
  </div>
</div>
