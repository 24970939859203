import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { BreakpointService } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "GridView",
  templateName: "Raster Layout",
  icon: "grid",
  templateDescription: "Eine Layout Komponente, welche weitere Widgets in einer Gitteransicht (responsive) darstellt.",
  dataSchema: {
    type: "object",
    properties: {
      gridCellWidth: {
        type: "string",
        title: "Breite des Elements",
        default: "500px",
        description: "Geben Sie hier an, wie breit jedes Element sein soll.",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
      },
      gridCellWidthMobile: {
        type: "string",
        title: "Breite des Elements auf Mobilgeräten",
        default: "250px",
        description: "Geben Sie hier an, wie breit jedes Element sein soll, wenn es auf Mobilgeräten angezeigt wird.",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
      },
      gridCellMaxHeight: {
        type: "string",
        title: "Maximale Höhe des Elements",
        default: "500px",
        description: "Geben Sie hier an, wie hoch jedes Element maximal sein darf.",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
      },
      children: {
        type: "array",
        items: {
          type: "object",
          "x-schema-form": { widget: "widget" }
        } as SchemaFormPropertyOptions
      }
    }
  },
  tags: AllWidgetTags
})
@Component({
  selector: "smallstack-grid-view-widget",
  templateUrl: "./grid-view-widget.component.html",
  styleUrls: ["./grid-view-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridViewWidgetComponent extends BaseWidgetComponent {
  protected breakpointService = inject(BreakpointService);

  protected gridCellWidth = computed(() => {
    const isMobile = this.breakpointService.isMobile();
    const data = this.data();
    if (isMobile === true && data.gridCellWidthMobile) return data.gridCellWidthMobile;
    else return data.gridCellWidth;
  });
  protected gridCellMaxHeight = computed(() => {
    const isMobile = this.breakpointService.isMobile();
    const data = this.data();
    if (isMobile === true) return undefined;
    else return data.gridCellMaxHeight;
  });
}
