import { Injectable } from "@angular/core";
import { IOC, Logger } from "@smallstack/core-common";
import { Store } from "@smallstack/store";
import { CUSTOM_TYPE_PATH_PREFIX, F_FORM, TYPE_FORMS, normalizeTypePath } from "@smallstack/typesystem";
import { dasherize } from "@smallstack/utils";
import { DataStore } from "./data.store";

/**
 * @deprecated Stores are deprecated, please use the Store from `@smallstack/typesystem` instead
 */
@Injectable({ providedIn: "root" })
export class StoreRegistry {
  private registeredStores: { [name: string]: Store } = {};
  private storeRegistrationCallbacks: { [name: string]: Array<(store: any) => any> } = {};

  constructor(private dataStore: DataStore) {
    IOC.register("storeRegistry", this);
  }

  public registerStore(typePath: string, store: Store): void {
    if (typePath.endsWith("Store")) {
      Logger.warning(
        "StoreRegistry",
        "Store name automatically corrected. Please do not register stores as 'localeStore', rather name it 'locales'!"
      );
      typePath = typePath.replace("Store", "");
    }
    typePath = normalizeTypePath(typePath);
    typePath = dasherize(typePath);
    this.registeredStores[typePath] = store;
    if (this.storeRegistrationCallbacks[typePath] instanceof Array)
      for (const callback of this.storeRegistrationCallbacks[typePath]) callback(store);
  }

  public removeStore(name: string): void {
    name = normalizeTypePath(name);
    delete this.registeredStores[name];
  }

  public getStore<STORE_TYPE = Store>(typePath: string): STORE_TYPE {
    if (!typePath) throw new Error("No typePath given!");
    typePath = normalizeTypePath(typePath);
    typePath = dasherize(typePath);
    if (typePath === "forms") return this.dataStore.for(F_FORM, TYPE_FORMS) as any;
    if (typePath.startsWith(CUSTOM_TYPE_PATH_PREFIX)) {
      const storeName = typePath.substring(CUSTOM_TYPE_PATH_PREFIX.length);
      return this.dataStore.for("custom", storeName) as any;
    }

    const store = this.registeredStores[typePath] as any;
    if (!store)
      Logger.warning(
        "[StoreRegistry]",
        "Store '" +
          typePath +
          "' not found. Currently these stores are registered: " +
          Object.keys(this.registeredStores).sort()
      );
    return store;
  }

  public getAllStores(): { [name: string]: Store } {
    return this.registeredStores;
  }

  public waitForStoreRegistration<T extends Store>(storeName: string, callback: (store: T) => any): void {
    storeName = normalizeTypePath(storeName);
    const registeredStore = this.getStore(storeName);
    if (registeredStore !== undefined) return callback(registeredStore as unknown as T);
    if (!this.storeRegistrationCallbacks[storeName]) this.storeRegistrationCallbacks[storeName] = [];
    this.storeRegistrationCallbacks[storeName].push(callback);
  }
}
