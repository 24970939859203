<div mat-dialog-title class="flex flex-row justify-end">
  <div class="close-btn" (click)="close()">
    <smallstack-icon alias="close" size="24" color="444444" theme="ios-filled"></smallstack-icon>
  </div>
</div>
<div mat-dialog-content>
  <smallstack-widget-tree
    [(widget)]="data.dialogComponent"
    [widgetRendererOptions]="data.widgetRendererOptions"
    [context]="data.context"
  ></smallstack-widget-tree>
</div>
