import { Injectable } from "@angular/core";
import { Logger } from "@smallstack/core-common";
import { TranslationPack } from "@smallstack/language-packs";
import { LocaleService } from "../services/locale.service";
import { TranslationStore } from "../stores/translation.store";

@Injectable({ providedIn: "root" })
export class TranslationLoader {
  #translationPackFns: Array<(locale: string) => Promise<TranslationPack>>;

  constructor(
    private translationStore: TranslationStore,
    localeService: LocaleService
  ) {
    void localeService.currentLocale$.subscribe((locale) => {
      void this.load(locale);
    });
  }

  public register(translationPackFns: Array<(locale: string) => Promise<TranslationPack>>): void {
    this.#translationPackFns = translationPackFns;
  }

  public async load(locale: string): Promise<void> {
    if (!this.#translationPackFns) {
      Logger.warning("TranslationLoader", "Please call register() before calling load().");
      return;
    }
    for (const translationPackFn of this.#translationPackFns) {
      const translationPack: TranslationPack = await translationPackFn(locale);
      this.translationStore.addTranslationPack(locale, translationPack);
    }
  }
}
