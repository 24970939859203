<div class="linked-models" [ngClass]="width()">
  @for (link of representations(); track link; let i = $index) {
    <div class="linked-model clickable" (click)="openDetail(link)">
      @if (width() !== "tiny" && width() !== "list") {
        <smallstack-i18n [data]="link.dataType.title"></smallstack-i18n>
      }
      <div>
        <smallstack-icon [name]="link.dataType.icon" [size]="iconSize()"></smallstack-icon>
      </div>
      <div
        class="flex flex-col gap-0"
        [ngClass]="{
          'justify-start items-start content-start': width() === 'list',
          'justify-center items-center content-center': width() !== 'list'
        }"
      >
        @if (width() === "list") {
          <smallstack-i18n [data]="link.dataType.title" class="text-muted no-wrap"></smallstack-i18n>
        }
        <smallstack-rx-model [modelId]="link.modelId" [type]="link.dataType.path">
          <ng-template let-model="model">
            <smallstack-model-to-string
              [modelId]="model.id"
              [typePath]="link.dataType.path"
              class="model-string border-b border-dashed"
            ></smallstack-model-to-string>
          </ng-template>
        </smallstack-rx-model>
      </div>
      @if (editable()) {
        <button
          (click)="removeLink($event, i)"
          class="btn btn-error btn-outline"
          [ngClass]="{
            'fullsize-button': width() !== 'list',
            'btn-sm ml-4': width() === 'list'
          }"
        >
          <i class="fa-regular fa-trash"></i>
        </button>
      }
    </div>
  }
  @if (editable()) {
    <div (click)="addNewLink()" class="linked-model clickable h-full w-full !justify-center">
      <i class="fa-regular fa-plus-large text-secondary" [ngStyle]="{ fontSize: iconSize() + 'px' }"></i>
    </div>
  }
</div>
