import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AxiosApiClient, OrderDto, OrdersApi } from "@smallstack/axios-api-client";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "latest-orders-widget",
  templateUrl: "./latest-orders-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestOrdersWidgetComponent extends BaseWidgetComponent implements OnInit {
  public orders: OrderDto[];

  public displayedColumns = ["orderDate", "user"];

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        amount: {
          type: "number",
          title: "Wie viele Bestellungen sollen angezeigt werden?"
        }
      }
    };
  }

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public ngOnInit(): void {
    void this.getOrders();
  }

  public async getOrders(): Promise<void> {
    const searchOptions: any = {};

    // display the latest orders first
    searchOptions.sort = "-orderDate";

    // amount
    if (this.data().amount) searchOptions.size = this.data().amount;

    await this.axiosApiClient
      .get(OrdersApi)
      .getOrders(searchOptions)
      .then((response) => {
        this.orders = response.data.elements;
        this.cdr.markForCheck();
      });
  }
}
