<smallstack-form [schema]="schema" [(value)]="value">
  <smallstack-form-table></smallstack-form-table>
</smallstack-form>

<div class="flex flex-row justify-end gap-3 mt-3">
  @if (widgetTreeService.dialogRef) {
    <button class="btn btn-outline btn-sm" (click)="widgetTreeService.dialogRef.close()">Schließen</button>
  }
  <button class="btn btn-primary btn-sm" [loadingFn]="saveEntities()">
    {{ value?.useTimer ? "Starten" : "Speichern" }}
  </button>
</div>
