/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { InnerTranslationDto } from './inner-translation-dto';
import { ModelContextObjectDto } from './model-context-object-dto';

/**
 * 
 * @export
 * @interface ContactCustomFieldDto
 */
export interface ContactCustomFieldDto {
    /**
     * Describes the date of the last update
     * @type {number}
     * @memberof ContactCustomFieldDto
     */
    lastUpdatedAt?: number;
    /**
     * Describe the date when the entity was deleted
     * @type {number}
     * @memberof ContactCustomFieldDto
     */
    _deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactCustomFieldDto
     */
    createdAt?: number;
    /**
     * 
     * @type {ModelContextObjectDto}
     * @memberof ContactCustomFieldDto
     */
    context?: ModelContextObjectDto;
    /**
     * 
     * @type {Array<InnerTranslationDto>}
     * @memberof ContactCustomFieldDto
     */
    title: Array<InnerTranslationDto>;
    /**
     * 
     * @type {string}
     * @memberof ContactCustomFieldDto
     */
    type: ContactCustomFieldDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactCustomFieldDtoTypeEnum {
    String = 'string',
    Number = 'number',
    Boolean = 'boolean',
    Date = 'date',
    Datestring = 'datestring'
}



