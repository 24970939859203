<div class="dialog">
  <div class="dialog-title">
    <div class="flex flex-row justify-between items-center w-full">
      <div>Datei auswählen</div>
      <smallstack-file-upload-button
        className="mat-flat-button"
        data-test="file-upload-button"
        [fileStore]="data.fileStore"
      />
    </div>
  </div>

  <div class="dialog-content">
    <smallstack-file-list
      data-test="file-upload-display"
      [selectable]="true"
      [displayViewAs]="'thumbnails'"
      [fileStore]="data.fileStore"
      (selected)="selected($event)"
    />
  </div>
</div>
