import { filterNullOrUndefined, getValueFromStringProviderSync } from "@smallstack/utils";
import { Context } from "../services/context.service";

export function createAxiosHeaders(context: Context): any {
  const resellerId = getValueFromStringProviderSync(context?.resellerId);
  const tenantId = context?.tenantId;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-tenant-id": tenantId ? tenantId : resellerId,
    "x-reseller-id": resellerId,
    "x-auth-tenant-id": getValueFromStringProviderSync(context?.authTenantId),
    Authorization: "Bearer " + getValueFromStringProviderSync(context?.token)
  };
  return filterNullOrUndefined(headers);
}
