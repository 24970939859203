/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Signal, WritableSignal, computed, signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { StringProvider, getValueFromStringProviderSync } from "@smallstack/utils";
import { Observable } from "rxjs";

export interface Context {
  tenantId: StringProvider;
  resellerId: StringProvider;
  authTenantId?: StringProvider;
  userId?: StringProvider;
  token?: StringProvider;
}

export interface EvaluatedContext {
  tenantId: string;
  resellerId: string;
  authTenantId?: string;
  userId?: string;
  token?: string;
}

@Injectable({ providedIn: "root" })
export class ContextService {
  #context: WritableSignal<Context> = signal(undefined);
  public context: Signal<EvaluatedContext> = computed(() => {
    const internalContext = this.#context();
    if (!internalContext)
      return {
        authTenantId: undefined,
        resellerId: undefined,
        tenantId: undefined,
        token: undefined,
        userId: undefined
      };
    return {
      resellerId: getValueFromStringProviderSync(internalContext.resellerId),
      tenantId: getValueFromStringProviderSync(internalContext.tenantId),
      authTenantId: getValueFromStringProviderSync(internalContext.authTenantId),
      userId: getValueFromStringProviderSync(internalContext.userId),
      token: getValueFromStringProviderSync(internalContext.token)
    };
  });
  /** @deprecated use signals */
  public context$: Observable<Context> = toObservable(this.#context);
  /** @deprecated use signals */
  public evaluatedContext$ = toObservable(this.context);

  public setContext(context: Context): Context {
    this.#context.set(context);
    return this.#context();
  }

  public updateContext(context: Partial<Context>): Context {
    this.#context.set({ ...this.#context(), ...context });
    return this.#context();
  }

  /** @deprecated use signals */
  public getEvaluatedContext(): EvaluatedContext {
    return this.context();
  }

  public getContextString(): string {
    const context = this.context();
    return `tenantId: ${context.tenantId}, resellerId: ${context.resellerId}, authTenantId: ${context.authTenantId}, userId: ${context.userId}, token: ${context.token}`;
  }
}
