import { TypeSchema } from "../typesystem/type-schema";
import { BillingParty, BillingPartySchema } from "./billing-party";
import { ConfigurableProperty, ConfigurablePropertySchema } from "./configurable-property";
import { CustomFieldValue, CustomFieldValueSchema } from "./custom-field-value";
import { InnerTranslation, createInlineTranslationSchema } from "./inner-translation";

export enum BillingPositionType {
  Fixed = "fixed",
  HourlyRate = "hourlyRate"
}

export interface BillingPosition {
  label: InnerTranslation[];
  type: BillingPositionType;
  amount?: number;
  price: ConfigurableProperty;
  billingParties?: BillingParty[];
  addSurcharges?: boolean;
  showSubTasksOnBill?: boolean;
  customFields?: CustomFieldValue[];
}

export const BillingPositionSchema: TypeSchema = {
  type: "object",
  title: "BillingPosition",
  properties: {
    label: createInlineTranslationSchema({ title: "Bezeichnung" }),
    type: {
      type: "string",
      enum: Object.values(BillingPositionType)
    },
    amount: {
      type: "number"
    },
    price: ConfigurablePropertySchema,
    billingParties: {
      type: "array",
      items: BillingPartySchema
    },
    addSurcharges: {
      type: "boolean"
    },
    showSubTasksOnBill: {
      type: "boolean"
    },
    customFields: {
      type: "array",
      items: CustomFieldValueSchema
    }
  },
  required: ["label", "type", "price"],
  additionalProperties: false
};
