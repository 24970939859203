import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { humanizeDuration } from "@smallstack/utils";

@Component({
  selector: "smallstack-duration",
  templateUrl: "./smallstack-duration.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class DurationComponent {
  public durationString: string;

  #start: number;
  #end: number;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input()
  public set start(start: string | number) {
    this.#start = parseInt("" + start);
    this.evalDuration();
  }

  @Input()
  public set end(end: string | number) {
    this.#end = parseInt("" + end);
    this.evalDuration();
  }

  @Input()
  public set duration(durationInMs: number) {
    this.setDurationString(durationInMs);
  }

  public evalDuration(): void {
    if (this.#start && this.#end) this.setDurationString(this.#end - this.#start);
  }

  private setDurationString(durationInMs: number): void {
    this.durationString = humanizeDuration(durationInMs, { shortFormat: true });
    this.cdr.markForCheck();
  }
}
