import { Injectable } from "@angular/core";
import { BasePathService } from "./base-path.service";

export const UrlResolverKeys = {
  /** @deprecated use BASE_PATH */
  CURRENT_PROJECT_URL: "currentProjectUrl",
  BASE_PATH: "basePath",
  API_URL: "apiUrl"
};

/**
 * Resolves urls from given keys. Can be used to resolve backoffice urls like 'current project url' inside
 * a non backoffice environment.
 */
@Injectable({ providedIn: "root" })
export class UrlResolverService {
  #resolvers: { [key: string]: () => Promise<string> } = {
    [UrlResolverKeys.BASE_PATH]: async () => this.basePathService.basePath$.value
  };

  constructor(private basePathService: BasePathService) {}

  public get(key: string): Promise<string> {
    if (this.#resolvers[key] !== undefined) return this.#resolvers[key]();
    throw new Error("No url resolver found for " + key);
  }

  /** Returns a map of all current urls */
  public async getMap(): Promise<{ [key: string]: string }> {
    const map: { [key: string]: string } = {};
    for (const key in this.#resolvers) map[key] = await this.#resolvers[key]();
    return map;
  }

  public addResolver(key: string, callback: () => Promise<string>): void {
    this.#resolvers[key] = callback;
  }
}
