import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { AbstractGroupedSchemaFormTable } from "../schema-form-table/abstract-grouped-schema-form-table";
import { SchemaFormTableComponent } from "../schema-form-table/schema-form-table.component";

@Component({
  selector: "smallstack-grouped-form-table",
  templateUrl: "./grouped-schema-form-table.component.html",
  styleUrls: ["./grouped-schema-form-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [I18nComponent, SchemaFormTableComponent]
})
export class GroupedSchemaFormTableComponent extends AbstractGroupedSchemaFormTable {}
