<smallstack-loading-state [state]="store()?.loadingState()">
  <ng-template #loading>
    <div class="flex flex-col gap-4 w-full p-4">
      <div class="skeleton h-3 w-full"></div>
      <div class="skeleton h-3 w-full"></div>
    </div>
  </ng-template>
  <ng-template #error>
    <div class="center-center p-4 text-help">{{ message() }}</div>
  </ng-template>
  <ng-template #loaded>
    <template
      [ngTemplateOutlet]="templateModel"
      [ngTemplateOutletContext]="{ model: model(), $implicit: model() }"
    ></template>
  </ng-template>
</smallstack-loading-state>
