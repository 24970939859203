import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SchedulingRuleDto } from "@smallstack/axios-api-client";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { generateNextDatesUntil } from "../todo-template-list-entry/due-date-generator";
import { DateComponent } from "@smallstack/i18n-components";

@Component({
  selector: "smallstack-scheduled-times",
  standalone: true,
  imports: [CommonModule, DateComponent],
  templateUrl: "./scheduled-times.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledTimesComponent {
  private start$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private end$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private schedulingRule$: BehaviorSubject<SchedulingRuleDto> = new BehaviorSubject<SchedulingRuleDto>(undefined);

  @Input()
  public set start(start: number) {
    this.start$.next(start);
  }

  @Input()
  public set end(end: number) {
    this.end$.next(end);
  }

  @Input()
  public set schedulingRule(schedulingRule: SchedulingRuleDto) {
    this.schedulingRule$.next(schedulingRule);
  }

  protected dates$ = combineLatest([this.start$, this.end$, this.schedulingRule$]).pipe(
    map(([start, end, schedulingRule]) => {
      if (!start || !end || !schedulingRule) return [];
      return generateNextDatesUntil(schedulingRule, { from: start, until: end, includeOld: true });
    })
  );
}
