import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AxiosApiClient, MyPermissionDto, UsersApi } from "@smallstack/axios-api-client";
import { ContextService } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { Store } from "@smallstack/store";
import { Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";
import { PermissionService } from "../services/permission.service";
import { UserService } from "../services/user.service";
import { CurrentUserDto } from "@smallstack/axios-api-client";

@Injectable({ providedIn: "root" })
export class MyPermissionsStore extends Store<MyPermissionDto[]> {
  private notificationService: NotificationService;
  private router: Router;
  private permissionService: PermissionService;
  private contextService: ContextService;

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.notificationService = inject(NotificationService);
    this.router = inject(Router);
    this.permissionService = inject(PermissionService);
    this.contextService = inject(ContextService);
    const userService: UserService = inject(UserService);
    void userService.currentUser$.subscribe((currentUser: CurrentUserDto) => {
      if (currentUser) void this.reload();
      else void this.reset();
    });
  }

  public hasPermission$(permission: string, targetId?: string): Observable<boolean> {
    if (targetId === undefined) targetId = this.contextService.getEvaluatedContext().tenantId;
    if (targetId === undefined) return of(false);
    if (permission === undefined) return of(true);
    return this.value$.pipe(
      first((permissions) => permissions !== undefined),
      map((permissions) => this.permissionService.hasPermission(permissions, permission, targetId))
    );
  }

  public hasCompanyPermission$(permission: string, companyId?: string): Observable<boolean> {
    if (companyId === undefined) companyId = this.contextService.getEvaluatedContext().tenantId;
    if (companyId === undefined) return of(false);
    return this.hasPermission$(permission, companyId);
  }

  public hasProjectPermission$(permission: string, projectId?: string): Observable<boolean> {
    return this.hasPermission$(permission, projectId);
  }

  public hasResellerPermission$(permission: string): Observable<boolean> {
    if (this.contextService.getEvaluatedContext().resellerId === undefined) return of(false);
    return this.hasPermission$(permission, this.contextService.getEvaluatedContext().resellerId);
  }

  public async reload(): Promise<void> {
    try {
      this.setLoading();
      const myPermissions: MyPermissionDto[] = (await this.axiosApiClient.get(UsersApi).getMyPermissions()).data;
      this.setValue(myPermissions);
    } catch (e) {
      if (e.statusCode === 401) {
        this.notificationService.notification.error("@@errors.user.invalid_token");
        if (this.router.url !== "/login") await this.router.navigate(["/login"]);
      } else this.notificationService.showStandardErrorPopup(e);
    }
  }
}
