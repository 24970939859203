import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EnvironmentKeys, EnvironmentService, ErrorMessageHandler } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import axios from "axios";

@Component({
  templateUrl: "./double-opt-confirmation-widget.component.html",
  styleUrls: ["./double-opt-confirmation-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DoubleOptConfirmationWidgetComponent extends BaseWidgetComponent implements OnInit {
  public isLoading = false;
  public message: string;
  public successful: boolean = undefined;

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        handleOptIn: {
          title: "Listen Eintragungen behandeln",
          type: "boolean",
          default: true,
          description:
            "Aktivieren Sie diese Option, wenn Sie möchten, dass dieses Widget Listen Eintragungen Ihrer Kunden behandeln soll"
        },
        handleOptOut: {
          title: "Listen Austragungen behandeln",
          type: "boolean",
          default: true,
          description:
            "Aktivieren Sie diese Option, wenn Sie möchten, dass dieses Widget Austragungen Ihrer Kunden aus Ihren Listen behandeln soll"
        },
        optInLoadingMessage: {
          type: "array",
          title: "Nachricht während Opt-In",
          default: "Ihre Eintragung wird bestätigt. Bitte haben Sie einen Moment Geduld!",
          description:
            "Zeigen Sie Ihren Kunden diese Nachricht an, solange der Opt-In Prozess (die Eintragung in Ihre Liste) im Gange ist",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N }
        },
        optInDoneMessage: {
          type: "array",
          title: "Erfolgsnachricht Opt-In",
          default: "Sie haben sich erfolgreich in unsere Liste eingetragen!",
          description:
            "Diese Nachricht wird angezeigt, sobald der Opt-In (die Eintragung in Ihre Liste) erfolgreich bestätigt wurde",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N }
        },
        optOutLoadingMessage: {
          type: "array",
          title: "Nachricht während Opt-Out",
          default: "Ihre Austragung wird bestätigt. Bitte haben Sie einen Moment Geduld!",
          description:
            "Zeigen Sie Ihren Kunden diese Nachricht an, solange der Opt-Out Prozess (die Austragung aus Ihre Liste) im Gange ist",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N }
        },
        optOutDoneMessage: {
          type: "array",
          title: "Erfolgsnachricht Opt-Out",
          default: "Sie haben sich erfolgreich aus der Liste ausgetragen!",
          description:
            "Diese Nachricht wird angezeigt, sobald der Opt-Out (die Austragung aus Ihrer Liste) erfolgreich bestätigt wurde",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N }
        }
      }
    };
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorMessageHandler: ErrorMessageHandler,
    private environmentService: EnvironmentService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(async (params: Params) => {
        if (params.optInToken && this.data().handleOptIn === true)
          await this.handleToken(params.optInToken, "opt-in", "optIn");
        if (params.optOutToken && this.data().handleOptOut === true)
          await this.handleToken(params.optOutToken, "opt-out", "optOut");
      })
    );
  }

  public async acknowledge(): Promise<void> {
    this.message = undefined;
    this.isLoading = false;
    await this.router.navigate([], {
      queryParams: {
        optInToken: null,
        optOutToken: null
      },
      queryParamsHandling: "merge"
    });
  }

  private async handleToken(token: string, apiPath: string, i18nPrefix: string): Promise<void> {
    const loadingKey = i18nPrefix + "LoadingMessage";
    const doneKey = i18nPrefix + "DoneMessage";

    this.isLoading = true;
    this.successful = undefined;
    this.message = this.getData(loadingKey);
    this.cdr.markForCheck();
    try {
      await axios({
        baseURL: this.environmentService.get(EnvironmentKeys.API_URL) + "/mailing/" + apiPath,
        method: "post",
        data: {
          token
        }
      });
      this.successful = true;
      this.message = this.getData(doneKey);
    } catch (e) {
      this.successful = false;
      this.message = "@@notifications.error.title : " + this.errorMessageHandler.handleMessage(e);
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }
}
