import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ThemeDto } from "@smallstack/axios-api-client";
import { dasherize, flattenJson } from "@smallstack/utils";
import { hex } from "color-convert";
import Color from "colorjs.io";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ThemeService {
  public $currentTheme: BehaviorSubject<ThemeDto> = new BehaviorSubject(undefined);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.$currentTheme.subscribe((theme) => {
      try {
        this.applyTheme(theme);
      } catch (e) {
        console.error(e);
      }
    });
  }

  public getDefaultTheme(): ThemeDto {
    // Backoffice
    return {
      daisyTheme: {
        primary: {
          bgColor: "#4b6bfb",
          textColor: "#ffffff",
          focusColor: "#3655df"
        },
        secondary: {
          bgColor: "#7b92b2",
          textColor: "#ffffff",
          focusColor: "#6683ac"
        },
        accent: {
          bgColor: "#67cba0",
          textColor: "#1f2937",
          focusColor: "#45b987"
        },
        neutral: {
          bgColor: "#ffffff",
          textColor: "#1f2937",
          focusColor: "#ebebeb"
        },
        base100: "#ffffff",
        base200: "#f2f2f2",
        base300: "#e5e6e6",
        baseContent: "#1f2937",
        info: {
          bgColor: "#3abff8",
          textColor: "#1f2937"
        },
        success: {
          textColor: "#1f2937",
          bgColor: "#36d399"
        },
        warning: {
          bgColor: "#fbbd23",
          textColor: "#1f2937"
        },
        error: {
          bgColor: "#f87272",
          textColor: "#1f2937"
        }
      }
    };
  }

  // eslint-disable-next-line max-lines-per-function
  private applyTheme(theme: ThemeDto) {
    if (theme) {
      // check for deprecated material colors
      if (theme.colors) {
        // material theme colors
        // this.materialCssVarsService.setAutoContrastEnabled(true);
        // this.materialCssVarsService.setPrimaryColor(theme.colors.primary);
        // this.materialCssVarsService.setAccentColor(theme.colors.accent);
        // this.materialCssVarsService.setWarnColor(theme.colors.warn);

        // add colors
        this.setThemeVars(theme, "colors");
        this.setThemeVars(theme, "toolbar");
        this.setThemeVars(theme, "header");

        // all others
        if (theme.header?.gradient) {
          const colors = theme.header.gradient.colors
            .map((color) => color.color + " " + color.percentage + "%")
            .join(",");
          this.document.documentElement.style.setProperty(
            "--header-gradient",
            `linear-gradient(${theme.header.gradient.angle}deg, ${colors})`
          );
        } else this.document.documentElement.style.removeProperty("--header-gradient");
      } else {
        // use daisy colors for deprecated material crab
        if (theme.daisyTheme) {
          // this.materialCssVarsService.setPrimaryColor(theme.daisyTheme.primary.bgColor);
          // this.materialCssVarsService.setAccentColor(theme.daisyTheme.accent.bgColor);
          // this.materialCssVarsService.setWarnColor(theme.daisyTheme.warning.bgColor);
        }
      }

      // daisyUi
      if (theme.daisyTheme) {
        // primary
        this.document.documentElement.style.setProperty(
          "--p",
          this.convertToOklchValueString(theme.daisyTheme.primary.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--pf",
          this.convertToOklchValueString(theme.daisyTheme.primary.focusColor)
        );
        this.document.documentElement.style.setProperty(
          "--pc",
          this.convertToOklchValueString(theme.daisyTheme.primary.textColor)
        );
        // secondary
        this.document.documentElement.style.setProperty(
          "--s",
          this.convertToOklchValueString(theme.daisyTheme.secondary.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--sf",
          this.convertToOklchValueString(theme.daisyTheme.secondary.focusColor)
        );
        this.document.documentElement.style.setProperty(
          "--sc",
          this.convertToOklchValueString(theme.daisyTheme.secondary.textColor)
        );
        // accent
        this.document.documentElement.style.setProperty(
          "--a",
          this.convertToOklchValueString(theme.daisyTheme.accent.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--af",
          this.convertToOklchValueString(theme.daisyTheme.accent.focusColor)
        );
        this.document.documentElement.style.setProperty(
          "--ac",
          this.convertToOklchValueString(theme.daisyTheme.accent.textColor)
        );
        // default
        this.document.documentElement.style.setProperty(
          "--n",
          this.convertToOklchValueString(theme.daisyTheme.neutral.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--nf",
          this.convertToOklchValueString(theme.daisyTheme.neutral.focusColor)
        );
        this.document.documentElement.style.setProperty(
          "--nc",
          this.convertToOklchValueString(theme.daisyTheme.neutral.textColor)
        );
        // info
        this.document.documentElement.style.setProperty(
          "--in",
          this.convertToOklchValueString(theme.daisyTheme.info.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--inc",
          this.convertToOklchValueString(theme.daisyTheme.info.textColor)
        );
        // success
        this.document.documentElement.style.setProperty(
          "--su",
          this.convertToOklchValueString(theme.daisyTheme.success.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--suc",
          this.convertToOklchValueString(theme.daisyTheme.success.textColor)
        );
        // warning
        this.document.documentElement.style.setProperty(
          "--wa",
          this.convertToOklchValueString(theme.daisyTheme.warning.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--wac",
          this.convertToOklchValueString(theme.daisyTheme.warning.textColor)
        );
        // error
        this.document.documentElement.style.setProperty(
          "--er",
          this.convertToOklchValueString(theme.daisyTheme.error.bgColor)
        );
        this.document.documentElement.style.setProperty(
          "--erc",
          this.convertToOklchValueString(theme.daisyTheme.error.textColor)
        );
      }

      // custom style
      if (theme.customStyles) {
        const sheet = this.document.createElement("style");
        sheet.innerHTML = theme.customStyles;
        this.document.body.appendChild(sheet);
      }
    }
  }

  private convertToOklchValueString(hexColor: string): string {
    return new Color(hexColor).to("oklch").toString().replace("oklch(", "").replace(")", "");
  }

  private setThemeVars(theme: ThemeDto, path: string) {
    const compact = flattenJson(theme);
    for (const key in compact)
      if (key.startsWith(path)) {
        const cssVarName = "--" + dasherize(key).replace(/\./g, "-");
        this.document.documentElement.style.setProperty(cssVarName, compact[key]);
        if (key.includes("colors.") && !key.includes("gradient.colors")) {
          // create darker and lighter versions
          const hsl = hex.hsl(compact[key]);
          this.document.documentElement.style.setProperty(
            cssVarName + "-lighter",
            `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2] * 1.15}%)`
          );
          this.document.documentElement.style.setProperty(
            cssVarName + "-darker",
            `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2] * 0.85}%)`
          );
        }
      }
  }
}
