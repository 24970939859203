<div class="w-full h-full">
  <div class="form-control my-2">
    <div class="join">
      <input
        #input
        type="text"
        placeholder="Volltext Suche"
        class="input input-bordered w-full shadow-sm join-item"
        data-testid="full-text-search"
        [ngModel]="searchTextInput()"
        (keyup)="searchTextInput.set($any($event.target).value)"
      />
      @if (searchTextInput()) {
        <button
          class="btn btn-square btn-warning shadow-sm border-r border-r-gray-300 join-item"
          (click)="searchTextInput.set(undefined)"
        >
          <i class="fa-regular fa-trash-can fa-lg"></i>
        </button>
      }
      <button
        class="btn btn-square btn-primary shadow-sm join-item"
        data-test="full-text-search-btn"
        (click)="searchTextInput.set(input.value)"
      >
        <i class="fa-regular fa-magnifying-glass fa-lg"></i>
      </button>
      @if (showFilterBtn()) {
        <button
          class="btn btn-square btn-primary shadow-sm border-l border-l-gray-300 join-item"
          data-test="full-text-search-filter-btn"
          (click)="filtersShown.set(!filtersShown())"
        >
          <i class="fa-regular fa-filter fa-lg"></i>
        </button>
      }
    </div>
  </div>
  @if (filtersShown()) {
    <div class="card card-bordered rounded-lg bordered bg-white overflow-y-auto p-2 my-2">
      @if (filterSchema()) {
        <smallstack-form
          [schemaWithOptions]="{ schema: filterSchema(), options: { useDefaultsAsValue: false } }"
          [value]="filterSearch()"
          [options]="{ compactMode: true, showLabel: false, showHint: false }"
          (valueChange)="filterSearch.set($event)"
        >
          <div class="flex flex-col gap-1 w-full">
            @for (combinedSearchableTypeField of combinedSearchableTypeFields(); track combinedSearchableTypeField) {
              <smallstack-form-field-title class="font-semibold" [path]="combinedSearchableTypeField.name" />
              <smallstack-widget-tree
                [widget]="{
                  name: 'FilterField',
                  data: { path: combinedSearchableTypeField.name, searchableTypeField: combinedSearchableTypeField }
                }"
              />
            }
            <button class="btn btn-warning btn-sm btn-block md:btn-wide mt-2" (click)="filterSearch.set(undefined)"
              >löschen</button
            >
          </div>
        </smallstack-form>
      }
    </div>
  }

  <!-- show the template outlet if provided as content and pass search results -->
  @if (template()) {
    @if (modelState().isLoading) {
      <smallstack-skeleton shape="card-with-title" count="2" />
    } @else {
      <div class="w-full" style="height: calc(100% - 48px)">
        <ng-template
          [ngTemplateOutlet]="template()"
          [ngTemplateOutletContext]="{ $implicit: modelState().models }"
        ></ng-template>
      </div>
    }
  }
</div>
