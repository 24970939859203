import { Injector, computed, inject } from "@angular/core";
import { ContextService, getSignalOrRawValue } from "@smallstack/client-common";
import { Model } from "@smallstack/typesystem";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { Query } from "mingo";
import { CrudService, CrudServiceFactory, CrudServiceOptions } from "../services/crud.service";

export interface StoreOptions extends CrudServiceOptions {
  eagerRefetch?: boolean;
}

export interface FindOptions {
  selector?: any;
  sort?: any;
}

export function injectStore<TModel extends Model = Model>(options: StoreOptions): Store<TModel> {
  if (options.typePath === undefined || options.typePath === null) throw new Error("Please provide a typePath.");
  if (!options.contextService) options.contextService = inject(ContextService);
  if (!options.injector) options.injector = inject(Injector);
  if (!options.tenantId) options.tenantId = computed(() => options.contextService.context().tenantId);
  return new Store<TModel>(options, inject(CrudServiceFactory).getCrudService(options));
}

export class Store<TModel extends Model = Model> {
  constructor(
    protected options: StoreOptions,
    private crudService: CrudService<TModel>
  ) {
    if (!options.typePath) throw new Error("Please provide a typePath.");
    if (!options.tenantId) throw new Error("Please provide a tenantId.");
  }

  public async reload(): Promise<void> {
    await this.query.refetch({ throwOnError: true });
  }

  public query = injectQuery(() => {
    const tenantId = getSignalOrRawValue(this.options.tenantId);
    if (!tenantId) throw new Error("cannot create query instance: tenantId cannot be empty");
    const typePath = getSignalOrRawValue(this.options.typePath);
    if (!typePath) throw new Error("cannot create query instance: typePath cannot be empty");
    return {
      queryKey: [tenantId, typePath],
      queryFn: async () => this.crudService.getAll(),
      refetchOnMount: this.options.eagerRefetch === true,
      refetchOnWindowFocus: this.options.eagerRefetch === true
    };
  });

  public getAll(): TModel[] {
    if (this.query.isSuccess()) {
      return this.query.data();
    }
    return [];
  }

  public getById(id: string): TModel | undefined {
    return this.query.data()?.find((model) => model.id === id);
  }

  public getOneByProperty(prop: string, value: string): TModel | undefined {
    return this.find({ selector: { [prop]: value } })[0];
  }

  public async create(model: TModel): Promise<TModel> {
    model = await this.crudService.post(model);
    await this.query.refetch({ throwOnError: true });
    return model;
  }

  public async deleteById(id: string): Promise<void> {
    await this.crudService.del(id);
    await this.query.refetch({ throwOnError: true });
  }

  public async deleteByIds(ids: string[]): Promise<void> {
    await Promise.all(ids.map((id) => this.deleteById(id)));
    await this.query.refetch({ throwOnError: true });
  }

  public async patch(id: string, patch: Partial<TModel>): Promise<TModel> {
    const model = await this.crudService.patch(id, patch);
    await this.query.refetch({ throwOnError: true });
    return model;
  }

  public async update(model: TModel): Promise<TModel> {
    model = await this.crudService.put(model);
    await this.query.refetch({ throwOnError: true });
    return model;
  }

  public async createOrUpdate(model: TModel): Promise<TModel> {
    model = await this.crudService.postOrPut(model);
    await this.query.refetch({ throwOnError: true });
    return model;
  }

  public find(options: FindOptions): TModel[] {
    if (this.query.isSuccess()) {
      if (!options.selector) options.selector = {};
      const query = new Query(options.selector);
      const cursor = query.find<TModel>(this.query.data());
      if (options.sort) cursor.sort(options.sort);
      return cursor.all();
    }
    return [];
  }

  public count = computed(() => this.query.data()?.length);
}
