import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { TranslationDto } from "@smallstack/axios-api-client";
import { TranslationHelper } from "@smallstack/common-components";
import { IOC } from "@smallstack/core-common";
import {
  AsyncTranslator,
  InlineTranslation,
  Translatable,
  TranslationOptions,
  isInlineTranslation,
  isTranslatable
} from "@smallstack/i18n-shared";
import { TranslationPack } from "@smallstack/language-packs";
import { PageableCrudStore } from "@smallstack/store";
import { flattenJson, replaceVariables } from "@smallstack/utils";
import { createNotifier } from "ngxtension/create-notifier";
import { ReadonlyDeep } from "type-fest";
import { LocaleService } from "../services/locale.service";

export const TRANSLATION_KEY_REGEX = /(@@[a-zA-Z0-9._-]+)/gi;

@Injectable({ providedIn: "root" })
export class TranslationStore extends PageableCrudStore<TranslationDto> implements TranslationHelper {
  protected fallbackStore: TranslationStore;

  public translationsUpdated = createNotifier();

  constructor(private localeService: LocaleService) {
    super();
    IOC.register("translator", this);
    IOC.register("asyncTranslator", {
      translate: async (data: string | InlineTranslation, options?: TranslationOptions) => {
        return this.translate(data, options);
      }
    } as AsyncTranslator);
  }

  public getByKey(key: string): TranslationDto {
    if (!key) return undefined;
    const valueFromStore: TranslationDto = this.value?.find((t) => t.key.toLowerCase() === key);
    if (valueFromStore) return valueFromStore;
    if (this.fallbackStore) return this.fallbackStore.getByKey(key);
    return undefined;
  }

  public translate(
    data: string | InlineTranslation | Translatable | ReadonlyDeep<InlineTranslation>,
    options: TranslationOptions = {}
  ): string {
    if (data === undefined) return;
    if (!options.locale) options.locale = this.localeService.currentLocale$.value;
    if (!options.locale) throw new Error("no currentLocale set in localeService and no locale given");
    if (typeof data === "string" && data.includes("@@")) {
      return data.replace(TRANSLATION_KEY_REGEX, (substr: string, args: any[]) => {
        return this.translateByKey(substr.substring(2), options);
      });
    } else if (isInlineTranslation(data)) return this.translateInline(data, options);
    else if (isTranslatable(data)) return this.translateTranslatable(data, options.locale);
    else return "" + data;
  }

  public translateByKey(key: string, options: TranslationOptions = {}): string {
    const clearedKey = key.toLowerCase().replace(/[^a-z0-9._-]/gi, "");
    const translation = this.getByKey(clearedKey);
    if (!translation) {
      if (options.showMissingKey === true) return `_${options.locale}:${clearedKey}_`;
      else if (options.showMissingKey === null) return clearedKey;
      else return "";
    }

    if (!options.locale) options.locale = this.localeService.currentLocale$.value;
    if (!options.locale) throw new Error("no currentLocale set in localeService and no locale given");

    // translate text replacers
    if (options.replacers)
      for (const key in options.replacers) {
        options.replacers[key] = this.translate(options.replacers[key]);
      }

    if (translation.values[options.locale])
      return replaceVariables(translation.values[options.locale], options.replacers);

    // fallback chain
    if (options.showFallback !== false) {
      const fallbackChain = this.localeService.fallbackChains[options.locale];
      if (fallbackChain)
        for (const fallback of fallbackChain)
          if (translation.values[fallback]) return replaceVariables(translation.values[fallback], options.replacers);
    }

    // last way out, return first element
    if (Object.values(translation.values)[0])
      return replaceVariables(Object.values(translation.values)[0] as any, options.replacers);

    return key;
  }

  public translateInline(inline: string | InlineTranslation, options: TranslationOptions = {}): string {
    // inline translations coming from the api are mostly typeof string | InlineTranslation
    if (typeof inline === "string") return replaceVariables(inline, options.replacers);
    if (!(inline instanceof Array)) throw new Error("inline translation must be an array");
    let translation = inline.find((t) => t.locale === options.locale);
    if (translation) return replaceVariables(translation.value, options.replacers);
    else {
      // locale fallback
      const fallbackLocales: string[] = this.localeService.fallbackChains[options.locale];

      if (fallbackLocales instanceof Array && fallbackLocales.length > 0) {
        for (const fallbackLocale of fallbackLocales) {
          translation = inline.find((t) => t.locale === fallbackLocale);
          if (translation) return replaceVariables(translation.value, options.replacers);
        }
      }
    }
    // last way out
    return replaceVariables(inline[0]?.value, options.replacers);
  }

  public translateTranslatable(data: Translatable, locale: string): string {
    return this.translateByKey(data.key, { locale, replacers: data.replacers });
  }

  public addJsonTranslations(locales: string[], translations: any): any {
    const flattened: any = flattenJson(translations, { flattenArrays: false });
    this.addValues(
      Object.keys(flattened).map((key) => {
        // console.log("Inserting " + key);
        const values: any = {};
        for (const [index, locale] of locales.entries()) {
          values[locale] = flattened[key][index];
        }
        return { key: key.toLowerCase(), values };
      })
    );
    // console.log(this.value.map((v) => v.key).sort());
    this.translationsUpdated.notify();
  }

  public addTranslationPack(locale: string, pack: TranslationPack): void {
    this.updateValue((translations: TranslationDto[]) => {
      if (!translations) translations = [];
      for (const key in pack) {
        const translationIndex: number = translations.findIndex((translation) => translation.key === key);
        if (translationIndex !== -1) translations[translationIndex].values[locale] = pack[key];
        else translations.push({ key, values: { [locale]: pack[key] } });
      }
      return translations;
    });
    this.translationsUpdated.notify();
  }

  public sortByTranslation(
    translationA: string | InlineTranslation | Translatable | ReadonlyDeep<InlineTranslation>,
    translationB: string | InlineTranslation | Translatable | ReadonlyDeep<InlineTranslation>
  ): number {
    return this.translate(translationA)?.localeCompare(this.translate(translationB));
  }

  protected loadModelById(id: string): Promise<TranslationDto> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected deleteModelById(id: string): Promise<any> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected createModel(model: TranslationDto): Promise<TranslationDto> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected patchModel(id: string, model: Partial<TranslationDto>): Promise<TranslationDto> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected putModel(model: TranslationDto): Promise<TranslationDto> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
  protected loadModels(query: ApiQueryRequest): Promise<Page<TranslationDto>> {
    throw new Error("Please implement this method in case you need CRUD functionality in your current context!");
  }
}
