@if (widgetTreeService.editMode()) {
  <smallstack-widget-editor-renderer
    [widget]="widgetTreeService.rootWidget()"
    [context]="context()"
    [isRootWidget]="true"
    (widgetChange)="widgetChange.emit($event)"
    (openConnectionsDialog)="openConnectionScreen()"
  />
} @else {
  <smallstack-widget-renderer
    [widget]="widgetTreeService.rootWidget()"
    [context]="context()"
    (widgetChange)="widgetChange.emit($event)"
  />
}
