import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const CONFIG_LINKED_ID_FILTER = "linkedIdFilter";
export const CONFIG_BILLING_PARTY_ID_FILTER = "billingPartyIdFilter";

export const AnytimeTodosWidget: WidgetRegistryEntry = {
  name: "AnytimeTodos",
  configuration: {
    templateName: '"Jederzeit" Aufgaben',
    templateDescription: "Zeigt alle Aufgaben an, die jederzeit ausgeführt und nicht an ein Datum gebunden sind.",
    icon: "task",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIG_LINKED_ID_FILTER]: {
          type: "string",
          title: "Filter nach verlinkter ID",
          description: "Wenn gesetzt, werden nur Aufgaben angezeigt, die mit dieser ID verlinkt sind."
        },
        [CONFIG_BILLING_PARTY_ID_FILTER]: {
          type: "string",
          title: "Filter nach verlinkter Rechnungspartei",
          description: "Wenn gesetzt, werden nur Aufgaben angezeigt, die mit dieser Rechnungspartei verlinkt sind."
        }
      }
    }
  },
  component: () => import("./anytime-todos-widget.component").then((m) => m.AnytimeTodosWidgetComponent)
};
