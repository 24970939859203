/** Eliminates doubled entries  */
export function onlyUnique<T>(value: Array<T>): Array<T> {
  return value.filter((v, i, a) => a.indexOf(v) === i) as any;
}

export function toDictionary(arr: any[], keyProperty: string): any {
  const obj: any = {};
  for (const entry of arr) {
    const key = entry[keyProperty];
    if (key !== undefined) {
      delete entry[keyProperty];
      obj[key] = entry;
    }
  }
  return obj;
}

export function toObject(arr: any[], keyProperty: string, valueProperty: string): any {
  const obj: any = {};
  for (const entry of arr) {
    if (entry[keyProperty] !== undefined) obj[entry[keyProperty]] = entry[valueProperty];
  }
  return obj;
}

/** Adds values to an array if they don't exist yet */
export function addToArrayIfNotExists<T>(arr: Array<T>, values: Array<T>): Array<T> {
  if (!(arr instanceof Array)) arr = [];
  for (const value of values) {
    if (arr.indexOf(value) === -1) arr.push(value);
  }
  return arr;
}

/** Removes all occurrences from an array */
export function removeFromArray<T>(arr: Array<T>, values: Array<T>): Array<T> {
  if (!(arr instanceof Array)) arr = [];
  return arr.filter((v) => !values.includes(v));
}

export function sortBySortString(data: any[], sort: string): any[] {
  if (!(data instanceof Array)) return data;
  const desc = sort.startsWith("-");
  if (sort.startsWith("-")) sort = sort.substring(1);
  return data.sort((a, b) => {
    if (desc) return ("" + b[sort]).localeCompare("" + a[sort]);
    else return ("" + a[sort]).localeCompare("" + b[sort]);
  });
}

/** Splits a string into chunks, but ignores escaped passages (surrounded by " or ') */
export function splitWithoutEscaped(str: string, splitChar: string): string[] {
  const result: string[] = [];
  let current = "";
  let escaped = false;
  for (const char of str) {
    if (char === splitChar && !escaped) {
      result.push(current);
      current = "";
    } else if (char === '"' || char === "'") escaped = !escaped;
    else current += char;
  }
  result.push(current);
  return result;
}
