import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  input
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DialogPopupButtons, I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { cloneObject } from "@smallstack/utils";
import { v4 } from "uuid";
import { WidgetTreeService } from "../../services/widget-tree.service";
import { LazyLoadingWidgetConfiguration, WidgetRegistry, WidgetRegistryEntry } from "../../services/widget.registry";
import { DeprecatedAction } from "../../widgets/widget-configuration";
import { AddWidgetComponent } from "../add-widget/add-widget.component";
import { WidgetConfigurationDialogComponent } from "../widget-configuration-dialog/widget-configuration-dialog.component";
import { WidgetRendererComponent } from "../widget-renderer/widget-renderer.component";

@Component({
  selector: "smallstack-widget-editor-renderer",
  templateUrl: "./widget-editor-renderer.component.html",
  styleUrls: ["./widget-editor-renderer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nComponent,
    IconComponent,
    LoadingElementDirective,
    WidgetRendererComponent,
    AddWidgetComponent
  ]
})
export class WidgetEditorRendererComponent implements OnChanges {
  @Input()
  public widget: WidgetDto;

  @Output()
  public readonly widgetChange: EventEmitter<WidgetDto> = new EventEmitter();

  @Input()
  public additionalProviders: any[] = [];

  protected context = input<any>(undefined);

  @Input()
  public isRootWidget: boolean = false;

  @Output()
  public readonly openConnectionsDialog: EventEmitter<void> = new EventEmitter();

  public registryEntry: WidgetRegistryEntry;
  public widgetConfiguration: LazyLoadingWidgetConfiguration;

  protected showDeprecation = false;
  protected isLoading = false;

  constructor(
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private widgetRegistry: WidgetRegistry,
    private widgetTreeService: WidgetTreeService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget?.currentValue) {
      void this.loadWidgetEntry(changes);
    }
  }

  public editWidget(): void {
    this.matDialog.open(WidgetConfigurationDialogComponent, {
      data: {
        widget: this.widget,
        context: this.context(),
        globals: this.widgetTreeService.globals(),
        saveFn: async (widgetInstance?: WidgetDto) => {
          if (widgetInstance) {
            this.widget = cloneObject({
              ...this.widget,
              data: widgetInstance.data,
              meta: widgetInstance.meta,
              styles: widgetInstance.styles
            });
            this.widgetChange.emit(this.widget);
            this.cdr.detectChanges();
          }
        }
      },
      minWidth: "800px",
      disableClose: true,
      closeOnNavigation: false
    });
  }

  public setWidget(widgetInstance: WidgetDto): void {
    this.widget = {
      ...widgetInstance,
      id: v4()
    };
    this.widgetChange.emit(this.widget);
  }

  public migrateWidget(action: DeprecatedAction) {
    return async (): Promise<void> => {
      const newData = await action.migrationFn(this.widget.data);
      this.setWidget({
        name: newData.newWidgetName,
        data: newData.newWidgetData,
        id: this.widget.id,
        styles: this.widget.styles,
        meta: this.widget.meta
      });
    };
  }

  public async removeRootComponent(): Promise<void> {
    const answer = await this.notificationService.popup.confirmation(
      "@@components.cms.editor.widgets.removetitle",
      "@@components.cms.editor.widgets.removemessage",
      DialogPopupButtons.cancelDelete
    );
    if (answer === true) {
      this.widgetChange.emit(undefined);
    }
  }

  private async loadWidgetEntry(changes: SimpleChanges) {
    this.isLoading = true;
    this.cdr.markForCheck();
    this.registryEntry = this.widgetRegistry.getWidgetByName(changes.widget.currentValue.name);
    if (this.registryEntry) {
      if (typeof this.registryEntry.configuration === "function")
        this.widgetConfiguration = await this.registryEntry.configuration();
      else this.widgetConfiguration = this.registryEntry.configuration;
      if (this.widget.id === undefined) {
        // if no id was set, set one and emit changes
        this.widget.id = v4();
        this.widgetChange.emit(this.widget);
      }
    }
    this.isLoading = false;
    this.cdr.markForCheck();
  }
}
