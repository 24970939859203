@if (context(); as link) {
  @if (link.typeName | smallstackType | async; as type) {
    <div
      class="link-view-container flex flex-{{ data().layout }} justify-between items-center content-center"
      [ngStyle]="{ minWidth: data().layout === 'row' ? '150px' : '80px' }"
      style="cursor: pointer"
    >
      <div class="flex flex-{{ data().layout }} justify-start items-center content-center gap-1">
        @if (data().showIcon === true) {
          <smallstack-icon [name]="link.typeName | typeIcon | async" [size]="data().iconSize || 16"></smallstack-icon>
        }
        <div class="flex flex-col underline underline-offset-2 decoration-dashed decoration-1 decoration-gray-200">
          @if (data().showType === true) {
            <smallstack-i18n
              class="text-muted"
              [data]="type.title"
              class="text-muted no-wrap"
              style="top: 5px; position: relative; font-size: 10px"
            ></smallstack-i18n>
          }
          <smallstack-model-to-string
            [modelId]="link.model.id"
            [typePath]="type.path"
            (click)="openDetails(type, link.model)"
          ></smallstack-model-to-string>
        </div>
      </div>
    </div>
  }
}
<!--
<smallstack-overlay #overlay>
  <div class="card card-compact card-bordered shadow bg-white">
    <div class="card-body">
      <smallstack-widget-tree></smallstack-widget-tree>
    </div>
  </div>
</smallstack-overlay> -->
