<h1 mat-dialog-title>Widget Connections</h1>

<div mat-dialog-content>
  <div class="box-warning"> This is a very experimental feature. Do not use in production! </div>
  @if (isLoading) {
    <div class="component-loading"></div>
  } @else {
    @if (!data.connections || data.connections.length === 0) {
      <div>
        <i>No Connections defined yet</i>
      </div>
    }
    @for (connection of data.connections; track connection; let i = $index) {
      <div class="flex flex-row">
        {{ connection.sourceComponentId }}.{{ connection.sourceSocketName }} --> {{ connection.targetComponentId }}.{{
          connection.targetSocketName
        }}
        <smallstack-icon alias="delete" style="cursor: pointer" (click)="removeConnection(i)" />
      </div>
    }
    <h3>Create New Connection</h3>
    <div class="flex flex-col gap-2">
      <mat-form-field>
        <mat-label>Select Source Socket</mat-label>
        <mat-select (selectionChange)="changeSourceSocket($event.value)">
          @for (socket of availableOutSockets; track socket) {
            <mat-option class="whitespace-nowrap" [value]="socket">
              {{ socket.widgetName }}.{{ socket.socketName }}<br />
              ID: {{ socket.widgetId }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Target Socket</mat-label>
        <mat-select (selectionChange)="changeTargetSocket($event.value)">
          @for (socket of availableInSockets; track socket) {
            <mat-option class="whitespace-nowrap" [value]="socket">
              {{ socket.widgetName }}.{{ socket.socketName }}<br />ID: {{ socket.widgetId }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button class="btn btn-primary btn-block" (click)="createConnection()">Create</button>
    </div>
  }
</div>
<div mat-dialog-actions class="justify-end">
  <button class="btn btn-primary" (click)="close()">
    <smallstack-i18n data="@@actions.close" />
  </button>
</div>
