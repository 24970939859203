import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { WorklogDto } from "@smallstack/axios-api-client";
import { TranslationHelper, TRANSLATION_HELPER } from "@smallstack/common-components";
import { DialogPopupButtons, NotificationService } from "@smallstack/i18n-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { WorklogStore } from "../../../stores/worklog.store";

@Widget({
  name: "WorklogList",
  icon: "work",
  tags: AllWidgetTags,
  templateName: "Alle Arbeitsprotokolle",
  templateDescription: "Zeigt alle Arbeitsprotokolle "
})
@Component({
  selector: "smallstack-worklog-list-widget",
  templateUrl: "./worklog-list-widget.component.html",
  styleUrls: ["./worklog-list-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogListWidgetComponent extends BaseWidgetComponent {
  protected worklogs: WorklogDto[];

  constructor(
    protected worklogStore: WorklogStore,
    private notificationService: NotificationService,
    @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper
  ) {
    super();
    worklogStore.sortBy("-startTime");
  }

  public deleteWorklog(worklog: WorklogDto) {
    return async (): Promise<void> => {
      const label = this.translationHelper.translate(worklog.label);
      const confirmed = await this.notificationService.popup.confirmation(
        "Protokoll löschen",
        `Möchten Sie das Arbeitsprotokoll "${label}" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`,
        DialogPopupButtons.cancelDelete
      );
      if (confirmed === true) await this.worklogStore.delete(worklog.id);
    };
  }
}
