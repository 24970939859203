import { diff } from "deep-diff";
import { cloneObject } from "./json-utils";

export function objectsEqual(obj1: any, obj2: any): boolean {
  return diff(obj1, obj2) === undefined;
}

/** @deprecated use objectsEqual */
export function compareObjects(obj1: any, obj2: any): boolean {
  return objectsEqual(obj1, obj2);
}

/** Removes a property from an object, and also recursivly for all sub objects */
export function removePropertyRecursively(obj: any, propertyName: string): any {
  obj = cloneObject(obj);
  if (typeof obj !== "object") return obj;
  delete obj[propertyName];
  for (const key in obj) {
    obj[key] = removePropertyRecursively(obj[key], propertyName);
  }
  return obj;
}

export function sortByKey(unordered: any): any {
  return Object.keys(unordered)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
}

/** converts an object to a string */
export function objectToString(obj: any): string {
  if (typeof obj === "object")
    return JSON.stringify(obj)
      .replace(/"/g, "")
      .replace(/\{/g, "")
      .replace(/\}/g, "")
      .replace(/,/g, ", ")
      .replace(/:/g, ": ");
  return obj;
}

/** mutates an object with the given callback */
export function walkObject(obj: any, callback: (key: string, value: any, parent: any) => any): void {
  if (typeof obj !== "object") return;
  for (const key in obj) {
    if (typeof obj[key] === "object") obj[key] = walkObject(obj[key], callback);
    else obj[key] = callback(key, obj[key], obj);
  }
  return obj;
}

export async function walkObjectAsync(
  obj: any,
  callback: (key: string, value: any, parent: any) => Promise<any>
): Promise<void> {
  if (typeof obj !== "object") return;
  for (const key in obj) {
    if (typeof obj[key] === "object") obj[key] = await walkObjectAsync(obj[key], callback);
    else obj[key] = await callback(key, obj[key], obj);
  }
  return obj;
}
