@if (widgetTreeService?.editMode()) {
  @if (child()?.name) {
    <smallstack-widget-editor-renderer
      [widget]="child()"
      [context]="context()"
      (widgetChange)="componentChange($event)"
    />
  } @else {
    <smallstack-add-widget [widgetTags]="widgetTags" (addWidget)="addWidget($event)" />
  }
} @else {
  <smallstack-widget-renderer
    [widget]="child()"
    [context]="context()"
    (widgetChange)="componentChange($event)"
    (dataChange)="dataChanged($event)"
  />
}
