import { Injectable, computed, effect, inject, signal } from "@angular/core";
import { cloneObject, removeJsonByPath, setJsonProperty } from "@smallstack/utils";
import { diff } from "deep-diff";
import { RxEntityStore } from "../stores/rx-entity.store";
import { RxEntityStoreService } from "./rx-entity-store.service";

export class Draft<Model = unknown> {
  /** holds the modified model, will be used to persist a new version by putting. */
  #changedModel = signal<Model>(undefined);

  /** The original model. Should be reloaded on each page reload */
  #originalModel = signal<Model>(undefined);
  public originalModel = this.#originalModel.asReadonly();

  /** Provides access to the current model */
  public model = this.#changedModel.asReadonly();

  public changes = computed(() => {
    if (this.model() !== undefined && this.originalModel() !== undefined)
      return diff(this.originalModel(), this.model());
    return undefined;
  });

  public changesCount = computed(() => {
    const changes = this.changes();
    if (changes) return changes.length;
    return 0;
  });

  constructor(
    private identifier: string,
    private persistFn: (entity: Model) => Promise<Model>,
    private store: RxEntityStore<any>,
    private id: string
  ) {
    // try {
    //   const localStorageItem = localStorage.getItem(identifier);
    //   if (localStorageItem !== null) this.#changes.next(JSON.parse(localStorageItem));
    // } catch (e) {
    //   // eslint-disable-next-line no-console
    //   console.error(e);
    //   localStorage.removeItem(identifier);
    // }
    effect(
      async () => {
        await this.resetOriginalModel();
      },
      { allowSignalWrites: true }
    );
  }

  /** @deprecated use model() signal! */
  public getSnapshot(): Model {
    return this.model();
  }

  /** Persists the current draft via api */
  public async persist(): Promise<Model> {
    if (this.#changedModel()) {
      const savedModel = await this.persistFn(this.#changedModel());
      this.set(cloneObject(savedModel));
      this.#originalModel.set(cloneObject(savedModel));
      // localStorage.setItem(this.identifier, JSON.stringify(savedModel));
      return savedModel;
    }
  }

  /** Completely overwrites the changes model */
  public set(delta: Model): void {
    this.#changedModel.set(cloneObject(delta));
    // localStorage.setItem(this.identifier, JSON.stringify(this.#changes.value));
  }

  /** Overwrites a sub property of the changed model, supports dot notation */
  public setProperty(propertyName: string, delta: any): void {
    this.#changedModel.update((current) => {
      const changed = setJsonProperty(current, propertyName, delta);
      return changed;
    });
    // localStorage.setItem(this.identifier, JSON.stringify(this.#changes .value));
  }

  /** Overwrites a sub property of the original model, supports dot notation */
  public setOriginalProperty(propertyName: string, value: any): void {
    let currentValue = this.#originalModel();
    currentValue = setJsonProperty(currentValue, propertyName, value);
    this.#originalModel.set(currentValue);
  }

  /** Removes a sub property of the changes model, supports dot notation */
  public removeProperty(propertyName: string): void {
    this.#changedModel.update((current) => {
      return removeJsonByPath(current, propertyName);
    });
  }

  /** Resets the current draft */
  public reset(): void {
    this.#changedModel.set(this.#originalModel());
    // localStorage.removeItem(this.identifier);
  }

  public async resetOriginalModel(): Promise<void> {
    const model = this.store.getById(this.id);
    if (model) {
      this.#originalModel.set(cloneObject(model));
      this.#changedModel.set(cloneObject(model));
    }
  }
}

/**
 * The DraftService stores one or more entities grouped by an identifier in the browsers local storage.
 * It can be used for synchronizing an entities state across several ui components or to persist a (ui) state across
 * browser reloads.
 */
@Injectable({ providedIn: "root" })
export class DraftService {
  #persistingFunctions: { [typeName: string]: (model: any) => Promise<any> } = {};
  #configuredDrafts: { [identifier: string]: Draft } = {};

  protected rxEntityStoreService = inject(RxEntityStoreService);

  /**
   * Returns a draft for the given typePath. TypePath might get prefixed by various implementations,
   * and postfixed if id is given.
   */
  public getDraftFor<Model>(typePath: string, id: string): Draft<Model> {
    const identifier = this.getDraftPrefix() + typePath;
    if (!this.#persistingFunctions[identifier])
      this.#persistingFunctions[identifier] = (model) =>
        this.rxEntityStoreService.forType(typePath).createOrUpdateEntity(model);
    const configuredDraft = id ? identifier + "/" + id : identifier;
    if (!this.#configuredDrafts[configuredDraft])
      this.#configuredDrafts[configuredDraft] = new Draft(
        configuredDraft,
        this.#persistingFunctions[identifier],
        this.rxEntityStoreService.forType(typePath),
        id
      );
    return this.#configuredDrafts[configuredDraft] as Draft<Model>;
  }

  /**
   * Configures drafts
   *
   * @param typeName type name, something like "application"
   * @param saveFn the function to call when the draft needs to get persisted
   */
  public configureDraft<Model>(typeName: string, saveFn: (model: Model) => Promise<Model>): void {
    if (!typeName) throw new Error("Please provide a typeName when configuring a draft");
    typeName = this.getDraftPrefix() + typeName;
    if (this.#persistingFunctions[typeName])
      throw new Error(`There's already a draft configuration for typeName "${typeName}"!`);
    this.#persistingFunctions[typeName] = saveFn;
  }

  protected getDraftPrefix(): string {
    return "drafts/";
  }
}
