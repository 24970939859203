<table>
  <thead>
    <tr>
      <th>Schlüssel</th>
      <th>Wert</th>
    </tr>
  </thead>
  <tbody>
    @for (contentEntry of content$ | async | flatten | keyvalue; track contentEntry) {
      <tr>
        <td>
          {{ contentEntry.key }}
        </td>
        <td>
          {{ contentEntry.value }}
        </td>
      </tr>
    }
  </tbody>
</table>
