import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TodoTemplateDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { TYPE_TODO_TEMPLATES } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { isNonEmptyString, replaceVariables } from "@smallstack/utils";
import { BaseWidgetComponent, WidgetChildComponent } from "@smallstack/widget-core";
import { RxDocument } from "rxdb";
import { combineLatest, map, switchMap } from "rxjs";
import { generateNextDates } from "../../todo-template-list-entry/due-date-generator";

@Component({
  selector: "smallstack-upcoming-todo-templates-widget",
  templateUrl: "./upcoming-todo-templates-widget.component.html",
  styleUrls: ["./upcoming-todo-templates-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, WidgetChildComponent, I18nComponent]
})
export class UpcomingTodoTemplatesWidgetComponent extends BaseWidgetComponent {
  protected todoTemplates$ = combineLatest([this.data$, this.context$]).pipe(
    switchMap(async ([data, context]) => {
      const collection = await this.typeService.getCollection(TYPE_TODO_TEMPLATES);
      return [collection, data, context];
    }),
    switchMap(([collection, data, context]) => {
      const selector: any = {};
      if (data.onlyMine) selector.ownerIds = this.userService.getCurrentUserSnapshot()?.id;
      if (isNonEmptyString(data.billingPartyFilter))
        selector["billingPositions.billingParties.linkedId"] = replaceVariables(data.billingPartyFilter, context);
      return collection.find({ selector }).$;
    }),
    map((result: RxDocument<TodoTemplateDto>[]) => {
      if (result.length === 0) return undefined;

      let todoTemplates: Array<TodoTemplateDto & { nextDate?: number }> = result.map((r) => r.toMutableJSON()) as any[];

      todoTemplates = todoTemplates.map((tt) => {
        if (tt.schedulingRule) {
          const nextDates = generateNextDates(tt.schedulingRule, { resultCount: 1 });
          if (Array.isArray(nextDates) && nextDates.length > 0) tt.nextDate = nextDates[0];
        }
        return tt;
      });
      todoTemplates = todoTemplates.sort((a, b) => a.nextDate - b.nextDate);
      return todoTemplates;
    })
  );

  constructor(
    private typeService: TypeService,
    private userService: UserService
  ) {
    super();
  }
}
