import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { FormService } from "../../services/form.service";
import { SchemaFormWidgetComponent } from "../schema-form-widget/schema-form-widget.component";
import { SchemaFormCustomLayoutService } from "./schema-form-custom-layout.service";
import { SchemaFormPlaceholderDirective } from "./schema-form-placeholder.directive";

@Component({
  selector: "schema-form-custom-layout,smallstack-schema-form-custom-layout,smallstack-form-custom-layout",
  templateUrl: "./schema-form-custom-layout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SchemaFormCustomLayoutService],
  standalone: true
})
export class SchemaFormLayoutCustomComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private schemaFormCustomLayoutService: SchemaFormCustomLayoutService,
    protected formService: FormService
  ) {
    this.subscription.add(
      this.schemaFormCustomLayoutService.schemaFormPlaceholders$.subscribe(
        (directive: SchemaFormPlaceholderDirective) => {
          if (directive.schemaFormPlaceholder === undefined || directive.schemaFormPlaceholder === "")
            throw new Error(
              'Please provide a value in the schemaFormPlaceholder directive, e.g. <div schemaFormPlaceholder="firstname"></div>'
            );
          directive.viewContainerRef.clear();
          const createdComponent = directive.viewContainerRef.createComponent(SchemaFormWidgetComponent);
          // const schema = this.formService.getSchema();
          // if (!schema || !schema.properties)
          //   throw new Error('Please provide a schema with properties for <schema-form-custom-layout schema="...">');
          // const propertySchema = getDataByJsonPath(
          //   schema,
          //   convertDotNotationPathToJsonSchemaPath(directive.schemaFormPlaceholder)
          // );
          // if (!propertySchema)
          //   throw new Error(
          //     "Please provide a schema for property '" +
          //       directive.schemaFormPlaceholder +
          //       '\' in <schema-form-custom-layout schema="...">'
          //   );
          createdComponent.setInput("path", directive.schemaFormPlaceholder);
          createdComponent.changeDetectorRef.detectChanges();
          this.cdr.markForCheck();
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
