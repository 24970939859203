import { Injectable } from "@angular/core";
import { MarkdownService } from "./markdown.service";

export interface ToolbarIcon {
  name: string;
  action: string | ((editor: EasyMDE) => void);
  className: string;
  title: string;
}

export interface MarkdownEditorServiceOptions {
  extraToolbarButtons?: Array<ToolbarIcon>;
}

@Injectable({ providedIn: "root" })
export class MarkdownEditorService {
  public async togglePreview(markdownEditor: any) {
    const EasyMde = await import("easymde");
    EasyMde.togglePreview(markdownEditor);
  }
  constructor(private markdownService: MarkdownService) {}
  public async getMarkdownEditor(element: HTMLElement, options?: MarkdownEditorServiceOptions): Promise<EasyMDE> {
    const parseFn = await this.markdownService.getParseFn();
    const EasyMDE = await import("easymde");
    return new EasyMDE.default({
      element,
      spellChecker: false,
      toolbar: this.getToolbarActions(options) as any,
      autoDownloadFontAwesome: true,
      maxHeight: "500px",
      previewRender: (text) => {
        return parseFn(text);
      }
    });
  }

  public getToolbarActions(
    options: MarkdownEditorServiceOptions = { extraToolbarButtons: [] }
  ): Array<string | ToolbarIcon> {
    if (options?.extraToolbarButtons)
      return [
        ...options.extraToolbarButtons,
        "bold",
        "italic",
        "heading",
        "|",
        "unordered-list",
        "ordered-list",
        "|",
        "link",
        "code",
        "|",
        "guide",
        "preview"
      ];
    else
      return [
        "bold",
        "italic",
        "heading",
        "|",
        "unordered-list",
        "ordered-list",
        "|",
        "link",
        "code",
        "|",
        "guide",
        "preview"
      ];
  }
}
