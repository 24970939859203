import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router, UrlTree } from "@angular/router";
import { RouterUtilService, StoreRegistry } from "@smallstack/common-components";
import { ICON_THEMES } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { PageableStore } from "@smallstack/store";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { BaseWidgetComponent, Widget, WidgetConfigurationSchema, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "GridListViewV2",
  templateName: "Raster Ansicht",
  icon: "grid",
  templateDescription:
    "Eine Layout Komponente, welche Daten aus einer Datenquelle in einer Gitteransicht (responsive) darstellt.",
  dataSchema: async () => GridListViewWidgetV2Component.getConfiguration(),
  tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD, WidgetTag.CMS]
})
@Component({
  selector: "smallstack-grid-list-view-widget-v2",
  templateUrl: "./grid-list-view-widget-v2.component.html",
  styleUrls: ["./grid-list-view-widget-v2.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListViewWidgetV2Component extends BaseWidgetComponent implements OnInit {
  public store: PageableStore;

  // eslint-disable-next-line max-lines-per-function
  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        store: {
          type: "string",
          title: "Store",
          "x-schema-form": {
            widget: "stores"
          }
        },
        template: {
          type: "object",
          title: "Vorlage",
          "x-schema-form": {
            widget: "widget"
          }
        },
        link: {
          type: "string",
          title: "Verlinkung",
          description:
            "Falls gesetzt, dann leitet ein Mausklick auf die angegebene Seite weiter. Verwenden Sie den Platzhalter ${id} für die ID des aktuellen Elementes."
        },
        gridCellWidth: {
          type: "string",
          title: "Breite des Elements",
          default: "150px",
          description: "Geben Sie hier an, wie breit jedes Element sein soll.",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        showPager: {
          type: "boolean",
          title: "Seitennavigation anzeigen",
          description: "Stellen Sie hier ein, ob und wann der Pager am unteren Rand angezeigt werden soll."
        },
        showMoreLink: {
          title: "Link zur Übesicht aller Daten zeigen",
          description:
            "Falls aktiviert, wird ein Link unterhalb der Daten angezeigt, der zu einer Unterseite führen kann, in dem alle Daten angezeigt werden.",
          default: true,
          type: "boolean"
        },
        pageSize: {
          type: "number",
          title: "Anzahl Elemente pro Seite",
          description: "Geben Sie hier an, wie viele Elemente pro Seite angezeigt werden sollen.",
          default: 10
        },
        moreLink: {
          type: "string",
          title: "Link",
          description: "Geben Sie hier den Pfad der Seite an, auf die Link verweisen soll."
        },
        label: {
          type: "string",
          title: "Link Label",
          description: "Geben Sie hier den Text an, der als Link dargestellt werden soll."
        },
        icon: {
          type: "string",
          title: "Icon",
          "x-schema-form": {
            widget: "icon"
          }
        } as SchemaFormSchema,
        iconTheme: {
          type: "string",
          title: "Icon Theme",
          enum: ICON_THEMES,
          default: "color"
        },
        iconSize: {
          type: "string",
          title: "Icon Größe",
          description: "Größe der Tab Icons"
        },
        iconColor: {
          type: "string",
          title: "Icon Farbe",
          description: "Farbe der Tab Icons, falls als Theme nicht 'color' oder 'fluency' ausgewählt ist.",
          "x-schema-form": {
            widget: "color"
          },
          default: "#FFFFFF"
        } as SchemaFormSchema
      }
    };
  }

  constructor(
    private storeRegistry: StoreRegistry,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerUtilService: RouterUtilService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.data().store) {
      this.store = this.storeRegistry.getStore(this.data().store);
      if (this.store) void this.store.load();
    }
  }

  public getLink(id: string): void {
    if (this.widgetTreeService.editMode() === true) return;
    if (this.data().link) {
      const realUrl: string = this.data().link.replace("${id}", id);
      const urlTree: UrlTree = this.router.parseUrl(realUrl);
      const params: Params = urlTree?.queryParams;
      const urlWithoutParams: string = realUrl.split("?")[0];
      if (realUrl.includes("?"))
        void this.router.navigate([urlWithoutParams], {
          queryParams: params,
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge"
        });
      else void this.router.navigate([urlWithoutParams]);
    }
  }

  public openMoreLink(): void {
    if (this.data().moreLink) {
      void this.routerUtilService.navigateFullUrl(this.data().moreLink);
    }
  }
}
