import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { WidgetDto } from "@smallstack/axios-api-client";
import { TypeSchema } from "@smallstack/typesystem";
import { cloneObject } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "Container",
  templateName: "Container",
  icon: "table-1",
  templateDescription: "Ein Container für weitere Widgets.",
  dataSchema: async () => ContainerWidgetComponent.getConfiguration(),
  tags: AllWidgetTags,
  deprecated: true,
  deprecatedMessage: [
    {
      value: `Bitte verwenden Sie ab sofort das "Horizontaler Container" oder das "Vertikaler Container" Widget. Sollten Sie kompliziertere Container bauen wollen, so verwenden Sie bitte das "Flexbox Container" Widget.`
    }
  ],
  deprecatedActions: [
    {
      buttonTitle: [{ value: "Zu horizontalem Container migrieren" }],
      migrationFn: async (oldData: any) => {
        delete oldData.direction;
        delete oldData.classes;
        return { newWidgetName: "HorizontalContainer", newWidgetData: oldData };
      }
    },
    {
      buttonTitle: [{ value: "Zu vertikalem Container migrieren" }],
      migrationFn: async (oldData: any) => {
        delete oldData.direction;
        delete oldData.classes;
        return { newWidgetName: "VerticalContainer", newWidgetData: oldData };
      }
    }
  ]
})
@Component({
  templateUrl: "./container-widget.component.html",
  styleUrls: ["./container-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerWidgetComponent extends BaseWidgetComponent {
  @HostBinding("style.display")
  public containerDisplay: string = "flex";

  public static getConfiguration(): TypeSchema {
    return {
      type: "object",
      properties: {
        children: {
          title: "Elemente",
          type: "array",
          description: "Alle Elemente, die in diesem Container angezeigt werden",
          items: {
            type: "object",
            "x-schema-form": {
              widget: "cms"
            }
          } as TypeSchema
        },
        direction: {
          title: "Richtung",
          type: "string",
          default: "vertical",
          enum: ["vertical", "horizontal"],
          description: "Bestimmt, ob Elemente innerhalb des Containers horizontal oder vertikal angereiht werden"
        },
        placeContent: {
          type: "string",
          default: "flex-start",
          enum: ["flex-start", "flex-end", "space-around", "space-between", "space-evenly"],
          description: "Bestimmt, wie Elemente innerhalb des Containers angereiht werden"
        },
        placeItems: {
          type: "string",
          default: "stretch",
          enum: ["flex-start", "flex-end", "center", "stretch"],
          description: "Bestimmt, wie Inhalte in Elementen angereiht werden"
        },
        childrenFlex: {
          title: "Elementgröße",
          type: "string",
          description: "Geben Sie hier an, wie sich die Größe eines Elementes basierend auf dem Inhalt anpassen kann."
        },
        padding: {
          type: "string",
          title: "Außenabstand",
          description: "Abstand des Containers zu anderen Elementen auf der Seite"
        },
        height: {
          type: "string",
          title: "Höhe",
          description: "Die Höhe des Containers, in px, vh oder Prozent"
        },
        width: {
          type: "string",
          title: "Breite",
          description: "Die Breite des Containers, in px, vh oder Prozent"
        },
        gap: {
          type: "string",
          title: "Zwischenabstände",
          description: "Abstände zwischen allen Elementen im Container"
        },
        classes: {
          type: "string",
          title: "CSS Klasse"
        }
      }
    };
  }

  @HostBinding("class")
  public get classes(): string {
    const classes: string[] = ["widget-container"];
    if (this.data()?.classes)
      classes.push(
        ...this.data()
          .classes.split(" ")
          .map((c: string) => c.trim())
      );
    return classes.join(" ");
  }

  @HostBinding("style")
  public get style(): CSSStyleDeclaration {
    const obj: CSSStyleDeclaration = {} as any;
    if (this.data()?.padding) obj.padding = this.data().padding;
    if (this.data()?.direction) obj.flexDirection = this.data().direction === "vertical" ? "column" : "row";
    else obj.flexDirection = "column";
    if (this.data()?.placeContent) obj.placeContent = this.data().placeContent;
    else obj.placeContent = "flex-start";
    if (this.data()?.placeItems) obj.placeItems = this.data().placeItems;
    else obj.placeItems = "stretch";
    if (this.data()?.gap) obj.gap = this.data().gap;
    else obj.gap = "10px";
    if (this.data()?.height) obj.height = this.data().height;
    if (this.data()?.width) obj.width = this.data().width;
    return obj;
  }

  public addWidget(widget: WidgetDto): void {
    const data = this.data() || {};
    if (!(data.children instanceof Array)) data.children = [];
    data.children.push(cloneObject(widget));
    this.dataChange.emit(data);
  }

  public childDataChanged(index: number, cmsComponent: WidgetDto): void {
    if (!cmsComponent) this.data().children.splice(index, 1);
    else {
      this.data().children[index] = cmsComponent;
    }
    this.dataChange.emit(this.data);
  }
}
