import { TYPE_MESSAGES } from "../typesystem/type-names";
import { TypeSchema } from "../typesystem/type-schema";
import { DataType } from "./data-type";
import { DataTypeStorageTarget } from "./data-type-storage";
import { Model } from "./model";

export interface MessageReadBy {
  date: number;
  userId: string;
}

export interface MessageLink {
  type: string;
  data: string;
  text?: string;
}

export interface MessageSender {
  id?: string;
  scope: "user" | "project" | "system";
  name?: string;
}

export interface Message extends Model {
  from: MessageSender;
  links?: Array<MessageLink>;
  title?: string;
  text: string;
  readBy?: Array<MessageReadBy>;
  type?: string;
  threadId: string;
}

export const MessageSchema: TypeSchema = {
  type: "object",
  properties: {
    from: {
      type: "object",
      properties: {
        id: { type: "string" },
        scope: { type: "string", enum: ["user", "project", "system"] },
        name: { type: "string" }
      },
      required: ["id", "scope"]
    },
    links: {
      type: "array",
      items: {
        type: "object",
        properties: {
          type: { type: "string" },
          data: { type: "string" },
          text: { type: "string" }
        },
        required: ["type", "data"]
      }
    },
    title: { type: "string" },
    text: { type: "string" },
    readBy: {
      type: "array",
      items: {
        type: "object",
        properties: {
          date: { type: "number" },
          id: { type: "string" }
        },
        required: ["date", "id"]
      }
    },
    type: { type: "string" },
    threadId: { type: "string" }
  },
  required: ["text"]
};

export interface SendMessage {
  toId: string;
  text: string;
  title?: string;
  links?: Array<MessageLink>;
}
export interface SendThreadMessage {
  text: string;
  title?: string;
  links?: Array<MessageLink>;
}

export interface SendSystemMessage {
  title?: string;
  text: string;
  links?: Array<MessageLink>;
  toIds: string[];
  targetTenantId?: string;
}

export interface SendSystemMessageResponse {
  errors: string[];
  successfulMessages: number;
}

export const SendSystemMessageSchema = {
  type: "object",
  properties: {
    text: { type: "string" },
    links: {
      type: "array",
      items: {
        type: "object",
        properties: {
          type: { type: "string" },
          data: { type: "string" },
          text: { type: "string" }
        },
        required: ["type", "data"]
      }
    },
    toIds: {
      type: "array",
      items: { type: "string" }
    },
    targetTenantId: { type: "string" },
    type: { type: "string" }
  },
  required: ["text", "toIds"]
};

export const MessageType: DataType = {
  icon: "chat--v1",
  path: TYPE_MESSAGES,
  representation: "${text}",
  searchableFields: [],
  title: [
    {
      value: "Nachricht",
      locale: "de_de"
    },
    {
      value: "Message",
      locale: "en_us"
    }
  ],
  titlePlural: [
    {
      value: "Nachrichten",
      locale: "de_de"
    },
    {
      value: "Messages",
      locale: "en_us"
    }
  ],
  storage: {
    pull: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: TYPE_MESSAGES
      }
    }
  },
  schema: MessageSchema
};
