import { CellRendererDefinitionParamsMode, TypeSchema } from "../typesystem/type-schema";
import { DataType } from "./data-type";
import { GeoJson, GeoJsonSchema } from "./geo-json";

export interface Address {
  street: string;
  streetNumber: string;
  name?: string;
  addition?: string;
  country: string;
  state?: string;
  county?: string;
  zip: string;
  city: string;
  geoJson?: GeoJson;
}

export const AddressSchema: TypeSchema = {
  type: "object",
  title: "Address",
  properties: {
    street: {
      title: "Straße",
      type: "string"
    },
    streetNumber: {
      title: "Hausnummer",
      type: "string"
    },
    name: {
      title: "Bezeichnung",
      type: "string"
    },
    addition: {
      title: "Zusatz",
      type: "string"
    },
    country: {
      title: "Land",
      type: "string"
    },
    state: {
      title: "Bundesland",
      type: "string"
    },
    county: {
      title: "Landkreis",
      type: "string"
    },
    zip: {
      title: "Postleitzahl",
      type: "string"
    },
    city: {
      title: "Stadt",
      type: "string"
    },
    geoJson: GeoJsonSchema
  },
  required: ["street", "streetNumber", "country", "zip", "city"],
  additionalProperties: false,
  "x-schema-form": {
    cellRendererDefinition: {
      renderMode: CellRendererDefinitionParamsMode.text,
      options: { text: "${street} ${streetNumber}, ${zip} ${city}" }
    }
  }
};

export const DATA_TYPE_ADDRESS = "address";

export const AddressDataType: DataType = {
  title: [
    { locale: "en_us", value: "Address" },
    { locale: "de_de", value: "Adresse" }
  ],
  titlePlural: [
    { locale: "en_us", value: "Addresses" },
    { locale: "de_de", value: "Adressen" }
  ],
  path: DATA_TYPE_ADDRESS,
  schema: AddressSchema,
  isSystemType: true
};
