<div mat-dialog-content>
  @if (!schema()) {
    <smallstack-loader></smallstack-loader>
  } @else {
    <smallstack-form [schema]="schema()" [value]="todo()" (valueChange)="todo.set($event)">
      <smallstack-grouped-form-table></smallstack-grouped-form-table>
    </smallstack-form>
  }
</div>
@if (data()?.showSaveBtn) {
  <div mat-dialog-actions class="flex flex-row justify-end">
    <button class="btn btn-primary" [loadingFn]="saveTodo()">
      <smallstack-i18n data="@@actions.save"></smallstack-i18n>
    </button>
  </div>
}
