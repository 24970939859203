import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FlattenPipe } from "@smallstack/common-components";
import { SmallstackFormModule } from "@smallstack/form-components";
import { SchemaFormInputsRegistry, SmallstackFormCoreModule } from "@smallstack/widget-core";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { SmallstackWidgetsModule, WidgetRegistry } from "@smallstack/widget-core";
import { AddNewLinkComponent } from "./components/add-new-link/add-new-link.component";
import { LinkedModelsComponent } from "./components/linked-models/linked-models.component";
import { ModelToStringComponent } from "./components/model-to-string/model-to-string.component";
import { ReverseLinkedModelsComponent } from "./components/reverse-linked-models/reverse-linked-models.component";
import { LinkViewWidget } from "./components/widgets/link-view-widget";
import { LinksFormInputComponent } from "./components/widgets/linked-models-form-input/links-form-input.component";
import { LinkedModelsWidgetComponent } from "./components/widgets/linked-models-widget/linked-models-widget.component";
import { LinksWidget } from "./components/widgets/links-widget";
import { ModelToStringWidgetComponent } from "./components/widgets/model-to-string-widget/model-to-string-widget.component";
import { ReverseLinkedModelsWidgetComponent } from "./components/widgets/reverse-linked-models-widget/reverse-linked-models-widget.component";

let alreadyImported = false;

@NgModule({
  imports: [
    CommonModule,
    SmallstackThemeModule,
    SmallstackI18nModule,
    SmallstackFormModule,
    SmallstackFormCoreModule,
    SmallstackWidgetsModule,
    LinkedModelsComponent,
    ModelToStringComponent,
    FlattenPipe,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    RouterModule
  ],
  declarations: [
    AddNewLinkComponent,
    ReverseLinkedModelsComponent,
    LinkedModelsWidgetComponent,
    ReverseLinkedModelsWidgetComponent,
    LinksFormInputComponent,
    ModelToStringWidgetComponent
  ],
  exports: [
    LinkedModelsComponent,
    AddNewLinkComponent,
    ReverseLinkedModelsComponent,
    LinkedModelsWidgetComponent,
    ReverseLinkedModelsWidgetComponent,
    LinksFormInputComponent,
    ModelToStringComponent,
    ModelToStringWidgetComponent
  ]
})
export class SmallstackLinkModule {
  constructor(widgetRegistry: WidgetRegistry, schemaFormInputsRegistry: SchemaFormInputsRegistry) {
    if (!alreadyImported) {
      widgetRegistry.registerWidget(LinkedModelsWidgetComponent);
      widgetRegistry.registerWidget(ReverseLinkedModelsWidgetComponent);
      widgetRegistry.registerWidget(ModelToStringWidgetComponent);
      widgetRegistry.addWidget(LinksWidget);
      widgetRegistry.addWidget(LinkViewWidget);
      schemaFormInputsRegistry.addWidget("links", LinksFormInputComponent);
      alreadyImported = true;
    }
  }
}
