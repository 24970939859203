@if (user$ | async; as user) {
  <div class="flex flex-col gap-1">
    @for (week of weeks$ | async; track week) {
      <div>
        <div (click)="showTimelineFor(user.id, week.weekDescription)" class="cursor-pointer">
          <div class="text-sm font-semibold">{{ week.weekDescription }}</div>
          <progress
            class="progress progress-info w-full bg-slate-300"
            [ngClass]="{ 'progress-warning': week.percentage >= 80, 'progress-error': week.percentage >= 100 }"
            [value]="week.percentage"
            max="100"
          ></progress>
          <div class="flex flex-row justify-end gap-2 text-xs">
            <span>{{ week.totalDuration }}/{{ week.workingHours }}h</span>
            <!-- <span>({{ week.percentage }}%)</span> -->
          </div>
        </div>
        @if ((userStatsShownStore$$.value$ | async) === user.id + "-" + week.weekDescription) {
          <div>
            <div class="w-full flex flex-row">
              <div class="flex flex-col w-8 place-items-center">
                <div class="h-5"></div>
                @for (day of week.days; track day) {
                  <div class="h-8 w-8 even:bg-slate-100 flex place-items-center p-1">{{ day.dayName }}</div>
                }
              </div>
              <div class="overflow-x-scroll w-full">
                <div class="flex flex-row h-5 text-xs" [ngStyle]="{ width: (zoom$ | async) * 100 + '%' }">
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">0</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">2</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">4</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">6</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">8</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">10</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">12</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">14</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">16</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">18</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">20</div>
                  <div [ngStyle]="{ width: (zoom$ | async) * (100 / 12) + '%' }">22</div>
                </div>
                <div class="flex flex-col" [ngStyle]="{ width: (zoom$ | async) * 100 + '%' }">
                  @for (day of week.days; track day) {
                    <div
                      class="flex flex-row h-8 w-full odd:bg-slate-100 place-items-center cursor-pointer"
                      (click)="createNewTask(day)"
                    >
                      @for (task of day.tasks; track task) {
                        <div
                          (click)="showTaskDetails(task); $event.stopPropagation()"
                          class="cursor-pointer relative bg-info text-info-content rounded-lg shadow text-xs whitespace-nowrap overflow-ellipsis p-1 h-6 flex flex-row justify-between place-items-center"
                          [ngClass]="{
                            'rounded-l-none': task.startedYesterday,
                            'rounded-r-none': task.continuesTomorrow
                          }"
                          [ngStyle]="{
                            left: task.startPercentage + '%',
                            width: task.endPercentage - task.startPercentage + '%'
                          }"
                        >
                          <span>{{ task.startedYesterday ? "🠈" : "" }}</span>
                          <span>{{ task.title }}</span>
                          <span>{{ task.continuesTomorrow ? "🠊" : "" }}</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-end gap-2 m-2">
              <smallstack-icon name="zoom-out--v1" class="cursor-pointer" (click)="zoomOut()"></smallstack-icon>
              <smallstack-icon name="zoom-in--v1" class="cursor-pointer" (click)="zoomIn()"></smallstack-icon>
            </div>
          </div>
        }
      </div>
    }
  </div>
}
