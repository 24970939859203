<smallstack-store-container [store]="worklogStore">
  <ng-template #empty>
    <div class="list-container-empty">
      <smallstack-icon name="time"></smallstack-icon>
      Keine Arbeitsprotokolle vorhanden.</div
    ></ng-template
  >
  <div class="flex flex-col gap-4">
    @for (worklog of worklogStore.value$ | async; track worklog) {
      <smallstack-icon-card icon="">
        <div class="flex flex-col space-y-2">
          <span class="title"><smallstack-i18n [data]="worklog.label"></smallstack-i18n></span>
          <div class="date text-muted flex flex-row space-x-1 items-center">
            <smallstack-icon name="time-machine--v1" size="20"></smallstack-icon>
            <smallstack-date [timestamp]="worklog.startTime" format="dd.MM.yyyy, hh:mm"></smallstack-date>
            <span>&nbsp;-&nbsp;</span>
            <smallstack-date [timestamp]="worklog.endTime" format="dd.MM.yyyy, hh:mm"></smallstack-date>
          </div>
          @if (worklog.endTime) {
            <div class="text-muted flex flex-row space-x-1 items-center">
              <smallstack-icon name="coming-soon" size="20"></smallstack-icon>
              <smallstack-humanized-time
                [start]="worklog.startTime"
                [end]="worklog.endTime"
              ></smallstack-humanized-time>
            </div>
          }
          <div fxFlexAlign="end">
            <button [loadingFn]="deleteWorklog(worklog)" class="delete-button btn btn-outline btn-primary">
              <smallstack-icon name="delete"></smallstack-icon>
            </button>
          </div>
        </div>
      </smallstack-icon-card>
    }
  </div>
</smallstack-store-container>
