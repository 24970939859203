<div class="worklog-entry flex flex-col space-y-2 items-start">
  @for (price of prices(); track price) {
    <div>
      <div [matTooltip]="price.calculationText" style="cursor: help" class="flex flex-col">
        <smallstack-i18n class="text-muted" [data]="price.billingPosition?.label"></smallstack-i18n>
        <div style="white-space: nowrap; font-size: bigger"
          >{{ price.value / 100 | currency: "EUR" }} {{ price.suffix }}
          @if (price.billingPosition?.billingParties) {
            <div>
              @for (billingParty of price.billingPosition?.billingParties; track billingParty) {
                <div style="padding-left: 10px; display: flex" class="text-muted">
                  {{ billingParty.factor | decimalJs: "multiply" : 100 }}% gezahlt von "<smallstack-model-to-string
                    [typePath]="billingParty.linkedType"
                    [modelId]="billingParty.linkedId"
                  ></smallstack-model-to-string
                  >"
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
