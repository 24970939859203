<div class="grid" [style.grid-template-columns]="'repeat(auto-fill, minmax(' + gridCellWidth() + ', 1fr))'">
  @for (child of data().children; track child; let i = $index) {
    <smallstack-widget-child
      [style.max-height]="gridCellMaxHeight()"
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      property="children.{{ i }}"
      [context]="context()"
    ></smallstack-widget-child>
  }
</div>
