import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { UserDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { CustomerStore } from "@smallstack/user-components";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { tap } from "rxjs";

@Widget({
  name: "ResourcePlanningUserConfiguration",
  templateName: "Ressourcen Planung Benutzer Konfiguration",
  templateDescription:
    "Hier können Benutzern neue Aufgaben hinzugefügt oder das Arbeitspensum pro Woche angepasst werden.",
  icon: "statistics"
})
@Component({
  selector: "smallstack-resource-planning-user-configuration",
  standalone: true,
  imports: [FormsModule, LoadingElementDirective],
  templateUrl: "./resource-planning-user-configuration.component.html",
  styleUrls: ["./resource-planning-user-configuration.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcePlanningUserConfigurationComponent extends BaseWidgetComponent {
  protected user: UserDto;
  protected workingHours: number;

  private user$ = toObservable(this.getContextProperty(""));

  constructor(
    cdr: ChangeDetectorRef,
    private customerStore: CustomerStore
  ) {
    super();
    this.subscription.add(
      this.user$
        .pipe(
          tap((user: UserDto) => {
            if (!user.configuration) {
              user.configuration = [{ key: "workingHoursPerWeek", value: 40 }];
            }
            this.user = user;
            this.workingHours = user.configuration.find((config) => config.key === "workingHoursPerWeek")?.value || 40;
            cdr.markForCheck();
          })
        )
        .subscribe()
    );
  }

  protected saveWorkingHours() {
    return async (): Promise<void> => {
      const index = this.user.configuration.findIndex((config) => config.key === "workingHoursPerWeek");
      if (index === -1) this.user.configuration.push({ key: "workingHoursPerWeek", value: this.workingHours });
      else this.user.configuration[index].value = this.workingHours;
      await this.customerStore.put(this.user);
      await this.customerStore.preload();
      if (this.widgetTreeService.dialogRef) this.widgetTreeService.dialogRef.close();
    };
  }
}
