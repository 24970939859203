import { MatButtonConfiguration } from "@smallstack/common-components";
import { Notifier } from "./notifier";

export class ConsoleNotifier implements Notifier {
  public info(title: string, message?: string): void {
    this.log(title, message, "info");
  }

  public success(title: string, message?: string): void {
    this.log(title, message, "success");
  }

  public debug(title: string, message?: string): void {
    this.log(title, message, "debug");
  }

  public warn(title: string, message?: string): void {
    this.log(title, message, "warn");
  }

  public error(title: string, message?: string): void {
    this.log(title, message, "error");
  }

  public confirmation(
    title: string,
    message: string,
    buttons: MatButtonConfiguration[],
    callback?: (answer: any) => void
  ): any {
    throw new Error("Console Notifier cannot ask questions!");
  }

  private log(title: string, message: string, level: string) {
    switch (level) {
      case "debug":
        // eslint-disable-next-line no-restricted-syntax
        console.debug(title + " - " + message);
        break;
      case "error":
        console.error(title + " - " + message);
        break;
      case "warn":
        console.warn(title + " - " + message);
        break;
      case "info":
      case "success":
      default:
        console.log(title + " - " + message);
    }
  }
}
