<div class="dialog">
  @if (data.title) {
    <div class="dialog-title">
      {{ data.title }}
    </div>
  }
  <div class="dialog-content">
    <smallstack-widget-tree
      [(widget)]="data.cmsComponent"
      [widgetRendererOptions]="data.widgetRendererOptions"
      [context]="data.context"
      [globals]="data.globals"
    ></smallstack-widget-tree>
  </div>
  @if (data.showCloseBtn) {
    <div class="dialog-actions flex justify-end">
      <button class="btn btn-primary btn-sm" (click)="close()">
        <smallstack-i18n data="@@actions.close"></smallstack-i18n>
      </button>
    </div>
  }
</div>
