export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_E164_REGEX = /^\+?[1-9]\d{1,14}$/;

export function isValidEmail(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  return PHONE_E164_REGEX.test(phoneNumber);
}

export function createFakeEmailAddress(username: string, tenantId: string): string {
  if (!username || !tenantId) throw new Error("Username and tenantId must be provided!");
  return username.toLowerCase() + "@noreply-app-user." + tenantId + ".smallstack.app";
}

export function isFakeEmailAddress(email: string): boolean {
  return email?.includes("@noreply-app-user.") && email?.endsWith(".smallstack.app");
}

export function extractUsernameFromFakeEmailAddress(email: string): string {
  if (!isFakeEmailAddress(email)) throw new Error("Not a fake email address!");
  return email?.split("@")[0];
}
