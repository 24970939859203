@if (state().state === "loading") {
  <div class="skeleton h-6 w-32 opacity-50"></div>
} @else {
  @if (state().value) {
    <smallstack-i18n data-test="store-property-value" [data]="state().value"></smallstack-i18n>
  }

  @if (notFound && !notFoundTemplate && showNotFound) {
    {{ notFoundText }}
  }

  @if (notFound && notFoundTemplate) {
    <template [ngTemplateOutlet]="notFoundTemplate" [ngTemplateOutletContext]="{ modelId: modelId() }"></template>
  }
}
