import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, TemplateRef, contentChild, input } from "@angular/core";
import { LoadingState } from "@smallstack/typesystem-client";

@Component({
  selector: "smallstack-loading-state",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./loading-state.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingStateComponent {
  protected state = input.required<LoadingState>();
  protected initTemplate = contentChild<TemplateRef<unknown>>("init");
  protected loadingTemplate = contentChild<TemplateRef<unknown>>("loading");
  protected loadedTemplate = contentChild<TemplateRef<unknown>>("loaded");
  protected errorTemplate = contentChild<TemplateRef<unknown>>("error");
}
