@if (property && property?.length) {
  <div class="flex flex-row space-x-3 justify-between items-center">
    @if (showData && !multiple) {
      <smallstack-store-property
        [store]="store"
        [property]="property"
        [modelId]="selectedId"
        [loadingFn]="openMenuSync()"
      >
      </smallstack-store-property>
    }
    @if (!selectedId) {
      <i
        [loadingFn]="openMenuSync()"
        data-test="store-select-create-or-select"
        data-testid="store-select-create-or-select"
      >
        Auswählen</i
      >
    }
    @if (showData && multiple) {
      <div [loadingFn]="openMenuSync()" class="flex flex-row space-x-1 justify-center items-center">
        @for (modelId of selectedIds; track modelId; let index = $index) {
          <smallstack-store-property [store]="store" [property]="property" [modelId]="modelId">
            <ng-template #notFoundTemplate>
              <span class="not-found-error" matTooltip="Model could not be found by ID: {{ modelId }}">Error</span>
              <button mat-icon-button color="warn" (click)="removeEntry(modelId, $event)">X</button>
            </ng-template>
          </smallstack-store-property>
          @if (index < selectedIds?.length - 1) {
            <span>,&nbsp;</span>
          }
        }
      </div>
    }
    <div class="flex flex-row">
      @if (showCreationButton) {
        <button class="btn-action" [loadingFn]="openCreationDialog()">
          <smallstack-icon name="add"></smallstack-icon>
        </button>
      }
      @if (showSelectButton) {
        <button
          class="btn-action"
          [loadingFn]="openMenuSync()"
          style="padding-right: -10px"
          data-test="store-select-open-btn"
        >
          <smallstack-icon alias="search"></smallstack-icon>
        </button>
      }
    </div>
  </div>
  <button [matMenuTriggerFor]="belowMenu" #menuTrigger style="visibility: hidden; position: absolute"></button>
  <mat-menu class="store-select-menu" #belowMenu="matMenu" yPosition="below" (closed)="onOverlayClose($event)">
    <smallstack-store-container
      [store]="store"
      (click)="$event.stopImmediatePropagation()"
      attr.data-test="store-select-menu{{ uniqueId }}"
    >
      <div>
        <smallstack-store-search
          [store]="store"
          mode="simple"
          [showModeSwitcher]="false"
          [filterNames]="filterNames"
          [syncSearchParams]="false"
          (click)="$event.stopImmediatePropagation()"
          class="no-bottom-rounded-corners"
          [menuMode]="true"
        >
        </smallstack-store-search>
      </div>
      @if (multiple) {
        <!-- BROKEN!!! <mat-checkbox
          class="mat-menu-item"
          color="primary"
          [checked]="currentPageSelected"
          (change)="onToggleCurrentPage()"
          (click)="$event.stopImmediatePropagation()"
          >
          <ng-container *ngIf="!currentPageSelected; else unselect">
            <i>
              <smallstack-i18n data="@@components.storeselect.selectcurrentpage"></smallstack-i18n>
            </i>
          </ng-container>
        </mat-checkbox> -->
        @if (store.isLoaded$ | async) {
          <div class="flex flex-col">
            @for (option of store.currentPage$ | async; track option) {
              <mat-checkbox
                class="mat-menu-item"
                color="primary"
                data-test="store-select-content"
                [checked]="isSelected(option?.id)"
                (change)="onToggleSelection($event.checked, option?.id)"
                (click)="$event.stopImmediatePropagation()"
              >
                <!-- <span *ngIf="option[property] && option[property].length; else notDefined"> -->
                <!-- {{ option[property].length < propertyLength ? option[property] : option[property].substring(0,
                propertyLength) + '...' }}  -->
                <smallstack-i18n [data]="getStringRepresentation(option)" [replacers]="option"></smallstack-i18n>
                <!-- </span> -->
              </mat-checkbox>
            }
          </div>
        }
      } @else {
        @for (option of store?.currentPage$ | async; track option) {
          <button mat-menu-item (click)="onSubmitSingle(option.id)" data-test="store-select-content">
            <!-- <span *ngIf="option[property] && option[property].length; else notDefined">
          {{ option[property].length < propertyLength ? option[property] : option[property].substring(0, propertyLength)
        + '...' }} </span> -->
            <smallstack-i18n [data]="getStringRepresentation(option)" [replacers]="option"></smallstack-i18n>
          </button>
        }
      }
      <div class="flex flex-row justify-between items-center">
        @if (isPageable && 10 < (totalElements$ | async)) {
          <div>
            <mat-paginator
              [length]="totalElements$ | async"
              [disabled]="store?.isLoading$ | async"
              class="marginless-paginator-range-label"
              [pageSize]="size$ | async"
              [pageIndex]="pageIndex$ | async"
              [hidePageSize]="true"
              (page)="onChangePage($event)"
              (click)="$event.stopImmediatePropagation()"
            >
            </mat-paginator>
          </div>
        }
        @if (multiple) {
          <button class="submit-btn btn btn-primary" (click)="onSubmitMultiple()" data-test="store-select-ok-btn">
            <smallstack-i18n data="@@actions.ok"></smallstack-i18n>
          </button>
        }
      </div>
    </smallstack-store-container>
  </mat-menu>
} @else {
  <span>Property not defined</span>
}
<ng-template #notDefined>
  <span>Property not defined</span>
</ng-template>
<ng-template #unselect>
  <i>
    <smallstack-i18n data="@@components.storeselect.unselectcurrentpage"></smallstack-i18n>
  </i>
</ng-template>
