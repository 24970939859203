import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "backoffice-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  public loading = signal(true);

  constructor(router: Router) {
    void router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && this.loading() === true) {
        document.getElementById("backoffice-loader")?.remove();
        this.loading.set(false);
      }
    });
  }
}
