import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LinkedModelDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { RxModelComponent } from "@smallstack/rx-components";
import { StoreModelComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { DataType, SmallstackTypeTag } from "@smallstack/typesystem";
import { TypeDialogService } from "@smallstack/widget-core";
import { computedAsync } from "ngxtension/computed-async";
import { LinkedModelService } from "../../services/linked-models.service";
import { AddNewLinkComponent, AddNewLinkDialogData } from "../add-new-link/add-new-link.component";
import { ModelToStringComponent } from "../model-to-string/model-to-string.component";

export interface Links {
  modelId: string;
  dataType: DataType;
}

@Component({
  selector: "smallstack-linked-models",
  templateUrl: "./linked-models.component.html",
  styleUrls: ["./linked-models.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    I18nComponent,
    StoreModelComponent,
    ModelToStringComponent,
    NgClass,
    NgStyle,
    RxModelComponent
  ]
})
export class LinkedModelsComponent {
  public width = input<"list" | "tiny" | "small" | "medium" | "large">("medium");
  public iconSize = computed<string>(() => {
    switch (this.width()) {
      case "tiny":
        return "16";
      case "list":
      case "small":
        return "32";
      case "medium":
        return "48";
      case "large":
        return "64";
    }
  });
  public editable = input(false);
  public typeTags = input<SmallstackTypeTag[]>(undefined);
  public linkableTypes = input<string[]>(undefined);
  public links = input<LinkedModelDto[]>(undefined);
  protected currentLinks = signal<LinkedModelDto[]>([]);
  public readonly linksChange = output<LinkedModelDto[]>();
  public representations = computedAsync<Links[]>(
    async () => {
      const representations = [];
      if (Array.isArray(this.currentLinks())) {
        for (const l of this.currentLinks()) {
          representations.push({
            modelId: l.id,
            dataType: await this.linkedModelService.getRepresentation(l)
          });
        }
      }
      return representations;
    },
    { initialValue: [] }
  );

  constructor(
    private linkedModelService: LinkedModelService,
    private matDialog: MatDialog,
    private typeDialogService: TypeDialogService
  ) {
    effect(
      () => {
        this.currentLinks.set(this.links());
      },
      { allowSignalWrites: true }
    );
  }

  public addNewLink(): void {
    void this.matDialog
      .open(AddNewLinkComponent, {
        data: { tags: this.typeTags(), linkableTypes: this.linkableTypes() } as AddNewLinkDialogData
      })
      .afterClosed()
      .subscribe(async (model: LinkedModelDto) => {
        if (model?.id && model?.type) {
          this.currentLinks.update((currentLinks) => {
            if (!currentLinks) currentLinks = [];
            currentLinks.push(model);
            return currentLinks;
          });
          this.linksChange.emit(this.currentLinks());
        }
      });
  }

  public async openDetail(link: Links): Promise<void> {
    if (link) await this.typeDialogService.openDetail({ typePath: link.dataType.path }, { modelId: link.modelId });
  }

  public removeLink($event: Event, index: number): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.currentLinks.update((currentLinks) => {
      currentLinks.splice(index, 1);
      return currentLinks;
    });
    this.linksChange.emit(this.currentLinks());
  }
}
