import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { CopyButtonComponent } from "./components/copy-button/copy-button.component";
import { HelpIconComponent } from "./components/help-icon/help-icon.component";
import { MarkdownEditorComponent } from "./components/markdown-editor/markdown-editor.component";
import { MarkdownViewerComponent } from "./components/markdown-viewer/markdown-viewer.component";
import { FitTextDirective } from "./directives/fit-text.directive";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    SmallstackI18nModule,
    SmallstackThemeModule,
    CopyButtonComponent,
    FitTextDirective,
    HelpIconComponent,
    MarkdownViewerComponent
  ],
  declarations: [MarkdownEditorComponent],
  exports: [MarkdownViewerComponent, MarkdownEditorComponent, CopyButtonComponent, FitTextDirective, HelpIconComponent]
})
export class SmallstackTextModule {}
