import { Injectable } from "@angular/core";
import { AxiosApiClient, TodoApi, TodoTemplateDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class TodoTemplateStore extends PageableCrudStore<TodoTemplateDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super({ loadOnDeletion: true });
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<TodoTemplateDto>> {
    const res = await this.axiosApiClient.get(TodoApi).getTodoTemplates(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<TodoTemplateDto> {
    const res = await this.axiosApiClient.get(TodoApi).getTodoTemplate({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(TodoApi).deleteTodoTemplate({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(TodoApi).deleteManyTodoTemplates({ ids });
    return res.data;
  }
  protected async createModel(model: TodoTemplateDto): Promise<TodoTemplateDto> {
    const res = await this.axiosApiClient.get(TodoApi).createTodoTemplate({ todoTemplate: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<TodoTemplateDto>): Promise<TodoTemplateDto> {
    const res = await this.axiosApiClient.get(TodoApi).patchTodoTemplate({ id, todoTemplate: model });
    return res.data;
  }
  protected async putModel(model: TodoTemplateDto): Promise<TodoTemplateDto> {
    const res = await this.axiosApiClient.get(TodoApi).putTodoTemplate({ id: model.id, todoTemplate: model });
    return res.data;
  }
}
