import { DaisyTheme, DaisyThemeSchema } from "./daisy-theme";

export interface Theme {
  daisyTheme: DaisyTheme;
  customStyles?: string;
}

export const ThemeSchema = {
  type: "object",
  properties: {
    daisyTheme: {
      additionalProperties: false,
      title: "Farben",
      ...DaisyThemeSchema
    },
    customStyles: {
      type: "string",
      title: "Eigene Styles",
      "x-schema-form": {
        widget: "monaco",
        monacoLanguage: "css"
      }
    }
  },
  required: ["daisyTheme"]
};
