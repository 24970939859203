/**
 * Formats the given number of cents as a string representing a currency value.
 *
 * @param cents - The number of cents to format.
 * @param currency - The currency code (e.g., "EUR").
 * @param currencyLocale - The locale code for formatting the currency (e.g., "de-DE").
 * @returns The formatted currency value as a string.
 */
export function formatCents(cents: number, currency = "EUR", currencyLocale: string = "de-DE"): string {
  return new Intl.NumberFormat(currencyLocale, { style: "currency", currency })
    .format(cents / 100)
    .replace("\xa0", " ");
}

/**
 * Formats the given number with the specified decimal places.
 *
 * @param value - The number to format.
 * @param decimalPlaces - The number of decimal places to round to (default is 2).
 * @returns The formatted number with the specified decimal places.
 */
export function formatDecimalPlaces(value: number, decimalPlaces: number = 2): number {
  if (isNaN(value)) return value;
  return parseFloat(value.toFixed(decimalPlaces));
}

/**
 * Represents a number format with a specified number of digits and a letter.
 */
export class TMNumberFormat {
  /**
   * Creates a new instance of TMNumberFormat.
   *
   * @param digits - The number of digits.
   * @param letter - The letter associated with the number format.
   */
  constructor(
    public digits: number,
    public letter: string
  ) {}

  /**
   * Returns a string representation of the TMNumberFormat.
   *
   * @returns The string representation of the TMNumberFormat.
   */
  public toString(): string {
    return `${this.digits}${this.letter}`;
  }
}

/**
 * Converts the given number to a TMNumberFormat object based on its magnitude.
 *
 * @param num - The number to convert.
 * @returns The TMNumberFormat object representing the converted number.
 */
export function convertNumberFormat(num: number): TMNumberFormat {
  if (Math.abs(num) > 99999999) return new TMNumberFormat(calculateNumber(num, 1000000), "M");
  else if (Math.abs(num) > 99999) return new TMNumberFormat(calculateNumber(num, 1000), "T");
  else return new TMNumberFormat(calculateNumber(num, 1), "");
}

/**
 * Calculates the number by dividing it with the specified divider.
 *
 * @param num - The number to calculate.
 * @param divider - The divider to use for the calculation.
 * @returns The calculated number.
 */
export function calculateNumber(num: number, divider: number): number {
  return Math.floor((Math.abs(num) / divider) * 10) / 10;
}

export function isNumberString(s: any): boolean {
  return typeof s === "string" && s.length && !isNaN(s as any);
}

export function leadingZeros(value: number, leadingZeros: number): string {
  return ("" + value).padStart(leadingZeros, "0");
}
