import { ChangeDetectionStrategy, Component, Inject, ViewChild, effect, signal } from "@angular/core";
import { TodoDto } from "@smallstack/axios-api-client";
import {
  ContextService,
  LoadingElementDirective,
  TRANSLATION_HELPER,
  TranslationHelper
} from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { LoaderComponent } from "@smallstack/store-components";
import { TodoConfigurationService } from "@smallstack/todo-shared";
import { TYPE_TODOS, TYPE_USERS, TodoType, TypeSchema, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { TypeService, injectStore } from "@smallstack/typesystem-client";
import { getJsonByPath, removeJsonByPath } from "@smallstack/utils";
import {
  AllWidgetTags,
  BaseWidgetComponent,
  FormComponent,
  GroupedSchemaFormTableComponent,
  SocketAwareWidget,
  Widget
} from "@smallstack/widget-core";
import { computedAsync } from "ngxtension/computed-async";
import { TodoTemplateStore } from "../../../stores/todo-template.store";

@Widget({
  name: "CreateTodo",
  templateName: "Aufgaben Editor",
  templateDescription:
    "Aufgaben Editor, welcher sich an die Aufgaben Konfiguration anlehnt und nur Felder anzeigt, die für das Projekt wichtig sind.",
  icon: "task",
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        title: "Kontext Variable der Aufgabe",
        type: "string"
      },
      showSaveBtn: {
        title: "Speichern Button anzeigen",
        type: "boolean"
      }
    }
  },
  tags: AllWidgetTags
})
@Component({
  selector: "smallstack-create-todo-widget",
  templateUrl: "./create-todo-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LoadingElementDirective, LoaderComponent, I18nComponent, FormComponent, GroupedSchemaFormTableComponent]
})
export class CreateTodoWidgetComponent extends BaseWidgetComponent implements SocketAwareWidget {
  private store = injectStore({ typePath: TYPE_TODOS });

  protected schema = computedAsync<TypeSchema>(async () => {
    const type = this.typeService.getByPath(TodoType.path);
    if (!type) return;

    const todoConfiguration = await this.todoConfigurationService.getConfiguration();

    let baseSchema = type.schema;

    if (todoConfiguration.customTodoGenerationAllowed === false) {
      await this.todoTemplateStore.preload();

      // show label selector or input field?
      baseSchema.properties.label = {
        title: baseSchema.properties.label.title,
        description: baseSchema.properties.label.description,
        type: "array",
        "x-schema-form": {
          ...baseSchema.properties.label["x-schema-form"],
          widget: "Select",
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          values: this.todoTemplateStore.value?.map((p) => {
            return { value: p.label, label: this.translationHelper.translate(p.label) };
          })
        }
      };
    }

    // use users or customers for ownerIds?
    if (!baseSchema.properties.ownerIds["x-schema-form"]) baseSchema.properties.ownerIds["x-schema-form"] = {};
    baseSchema.properties.ownerIds["x-schema-form"].type = TYPE_USERS;
    baseSchema.properties.ownerIds["x-schema-form"].typeTenantId =
      todoConfiguration.ownerType === TYPE_USERS
        ? this.contextService.context().resellerId
        : this.contextService.context().tenantId;

    // use configured priorities
    baseSchema.properties.priority = {
      title: "Priorität",
      type: "number",
      "x-schema-form": {
        ...baseSchema.properties.priority["x-schema-form"],
        widget: "Select",
        inputWidget: WIDGET_FORM_INPUT_SELECT,
        values: todoConfiguration.priorities?.map((p) => {
          return { value: p.priority, label: p.label };
        })
      }
    } as TypeSchema;

    // use configured status
    baseSchema.properties.status = {
      type: "string",
      "x-schema-form": {
        ...baseSchema.properties.priority["x-schema-form"],
        widget: "Select",
        inputWidget: WIDGET_FORM_INPUT_SELECT,
        values: todoConfiguration.statuses?.map((s) => {
          return { value: s.code, label: this.translationHelper.translate(s.label) };
        })
      }
    } as TypeSchema;
    if (todoConfiguration.visibleFields)
      for (const key in baseSchema.properties)
        if (!todoConfiguration.visibleFields.includes(key))
          baseSchema = removeJsonByPath(baseSchema, "properties." + key);

    // the dates
    if (todoConfiguration.fixedTimes === true) {
      baseSchema.properties.startTime["x-schema-form"] = {
        ...baseSchema.properties.startTime["x-schema-form"],
        fixedTime: "startOfDay"
      };
      baseSchema.properties.dueDate["x-schema-form"] = {
        ...baseSchema.properties.dueDate["x-schema-form"],
        fixedTime: "endOfDay"
      };
    }
    if (todoConfiguration.onlyFutureDates === true) {
      baseSchema.properties.startTime["x-schema-form"] = {
        ...baseSchema.properties.startTime["x-schema-form"],
        onlyDateInFuture: true
      };
      baseSchema.properties.dueDate["x-schema-form"] = {
        ...baseSchema.properties.dueDate["x-schema-form"],
        onlyDateInFuture: true
      };
    }

    return baseSchema;
  });

  protected todo = signal<TodoDto>(undefined);

  @ViewChild(FormComponent)
  protected formComponent: FormComponent;

  constructor(
    private todoConfigurationService: TodoConfigurationService,
    private todoTemplateStore: TodoTemplateStore,
    @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper,
    private contextService: ContextService,
    private typeService: TypeService
  ) {
    super();

    effect(
      () => {
        const data = this.data();
        const context = this.context();
        if (!data || !context) return;
        this.todo.set(getJsonByPath(context, data.contextVariable));
      },
      { allowSignalWrites: true }
    );
  }

  public handleSocketData(socketName: string, data?: unknown): Promise<any> {
    if (socketName === "save") return this.saveTodo()();
  }

  protected saveTodo() {
    return async (): Promise<any> => {
      return this.formComponent.validateFormAndSave(async () => {
        const savedTodo = await this.store.createOrUpdate(this.todo());
        if (this.widgetTreeService.dialogRef) this.widgetTreeService.dialogRef.close(savedTodo);
        return savedTodo;
      })();
    };
  }
}
