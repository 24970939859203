import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AxiosApiClient, LinkedModelDto, TodoApi, TodoDto } from "@smallstack/axios-api-client";
import { IsArrayPipe, LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, InlineTranslationEditorComponent } from "@smallstack/i18n-components";
import { LoaderComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { SQBuilder, SearchByFieldMatcher, T_TODOS } from "@smallstack/typesystem";
@Component({
  selector: "smallstack-todo-list",
  templateUrl: "./todo-list.component.html",
  styleUrls: ["./todo-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    LoadingElementDirective,
    IconComponent,
    IsArrayPipe,
    I18nComponent,
    InlineTranslationEditorComponent,
    MatFormFieldModule,
    FormsModule,
    MatInputModule
  ]
})
export class TodoListComponent implements OnChanges {
  public readonly T_TODOS = T_TODOS;

  @Input()
  public set forLink(link: LinkedModelDto) {
    this.#link = link;
    void this.loadTodos();
  }

  @Input()
  public todos: TodoDto[];

  @Input()
  public showTenant = false;

  @Input()
  public editingEnabled = true;

  @Input()
  public checkingEnabled = true;

  public newTodoLabel: string;

  public isLoading = false;

  public currentEditIndex: number;

  #link: LinkedModelDto;

  constructor(
    private axiosApiClient: AxiosApiClient,
    private cdr: ChangeDetectorRef
  ) {}
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.todos) this.sortTodos();
  }

  public createTodo() {
    return async (): Promise<void> => {
      const newTodo: TodoDto = { label: [{ value: this.newTodoLabel }] };
      if (this.#link) newTodo.links = [this.#link];
      await this.axiosApiClient
        .get(TodoApi)
        .createTodo({ todo: newTodo })
        .then((res) => res.data);
      this.currentEditIndex = undefined;
      this.newTodoLabel = "";
      await this.loadTodos();
      this.cdr.markForCheck();
    };
  }

  public toggleTodo(todo: TodoDto) {
    return async (): Promise<void> => {
      if (this.checkingEnabled === false) return;
      const updatedTodo = await this.axiosApiClient
        .withConfigFromContext(todo)
        .get(TodoApi)
        .patchTodo({ id: todo.id, todo: { done: !todo.done } });
      todo.done = updatedTodo.data.done;
      this.currentEditIndex = undefined;
      this.sortTodos();
      this.cdr.markForCheck();
    };
  }

  public saveTodo(todo: TodoDto) {
    return async (): Promise<void> => {
      const axiosApiClient = this.axiosApiClient.withConfigFromContext(todo);
      const updatedTodo = await axiosApiClient.get(TodoApi).putTodo({ id: todo.id, todo });
      todo = updatedTodo.data;
      this.currentEditIndex = undefined;
      this.cdr.markForCheck();
    };
  }

  public deleteTodo(index: number) {
    return async (): Promise<void> => {
      const todo = this.todos[index];
      await this.axiosApiClient.withConfigFromContext(todo).get(TodoApi).deleteTodo({ id: todo.id });
      this.todos.splice(index, 1);
      this.currentEditIndex = undefined;
      this.cdr.markForCheck();
    };
  }

  private sortTodos() {
    if (this.todos instanceof Array)
      this.todos.sort((todoA, todoB) => (todoA.done === todoB.done ? 1 : todoA.done ? 1 : -1));
  }

  private async loadTodos(): Promise<void> {
    this.isLoading = true;
    this.cdr.markForCheck();
    const query: any = {};
    if (this.#link)
      query.search = SQBuilder.asString([
        { fieldname: "links.id", value: this.#link.id, matcher: SearchByFieldMatcher.EQUALS }
      ]);
    await this.axiosApiClient
      .get(TodoApi)
      .getTodos(query)
      .then((res) => {
        this.todos = res.data.elements;
        this.sortTodos();
      })
      .finally(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }
}
