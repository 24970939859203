import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from "@angular/core";

import { MultiDialogService } from "../../services/multi-dialog.service";
import { Subscription } from "rxjs";

@Component({
  selector: "smallstack-multi-dialog-root",
  standalone: true,
  imports: [],
  templateUrl: "./multi-dialog-root.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiDialogRootComponent implements AfterViewInit, OnDestroy {
  @ViewChild("componentHost", { static: true, read: ViewContainerRef })
  public componentHost: ViewContainerRef;

  #subscription = new Subscription();

  constructor(private multiDialogService: MultiDialogService) {}

  public ngAfterViewInit(): void {
    this.#subscription.add(
      this.multiDialogService.currentDialogs$.subscribe((dialogComponents) => {
        if (dialogComponents.length > 0) {
          this.componentHost.clear();
          for (const dialogComponent of dialogComponents) this.componentHost.createComponent(dialogComponent);
        } else {
          this.componentHost.clear();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }
}
