import { Injectable, Injector, effect, inject } from "@angular/core";
import {
  ContextService,
  EnvironmentKeys,
  EnvironmentService,
  PushNotificationService
} from "@smallstack/common-components";
import { Message, SendMessage, TYPE_MESSAGES } from "@smallstack/typesystem";
import { RxEntityStore, createAxiosHeaders } from "@smallstack/typesystem-client";
import axios from "axios";
import { longPollingFallback } from "./long-polling-fallback";

@Injectable()
export class MyMessagesStore extends RxEntityStore<Message> {
  private contextService = inject(ContextService);
  private environmentService = inject(EnvironmentService);
  private pushNotificationService = inject(PushNotificationService);

  constructor(injector: Injector) {
    super(injector, { typeDescriptor: { typePath: TYPE_MESSAGES } });

    // listen to push notifications
    effect(async () => {
      const latestPushNotification = this.pushNotificationService.latestPushNotification();
      console.log("Received push notification in message store: ", latestPushNotification, "refreshing messages");
      await this.sync();
    });

    // do long polling if push notifications are disabled
    longPollingFallback(this, this.pushNotificationService);
  }

  public messagesForThread(threadId: string): Message[] {
    return this.getMany().filter((msg) => msg.threadId === threadId);
  }

  public async sendMessage(text: string, toId: string): Promise<void> {
    await axios.post(
      this.environmentService.get(EnvironmentKeys.API_URL) + "/" + TYPE_MESSAGES,
      {
        toId,
        text
      } as SendMessage,
      { headers: createAxiosHeaders(this.contextService.context()) }
    );
    await this.sync();
  }
}
