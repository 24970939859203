import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef
} from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PageableStore, Store, StoreState } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { LoaderComponent } from "../loader/loader.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-store-container",
  templateUrl: "./store-container.component.html",
  styleUrls: ["./store-container.component.scss"],
  standalone: true,
  imports: [CommonModule, LoaderComponent, IconComponent, MatTooltipModule]
})
export class StoreContainerComponent implements OnInit {
  @Input()
  public store: Store | PageableStore;

  @Input()
  public loadingText: string;

  @Input()
  public showErrorIcon: boolean = true;

  @Input()
  public showErrorText: boolean = true;

  @ContentChild("content")
  public content: TemplateRef<unknown>;

  @ContentChild("empty")
  public empty: TemplateRef<unknown>;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.store.state === StoreState.INITIAL && this.store instanceof PageableStore)
      void this.store.load().then(() => this.cdr.markForCheck());
  }
}
