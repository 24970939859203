import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { ActivatedRoute, Params, Router, UrlTree } from "@angular/router";
import { BreakpointService, RouterUtilService } from "@smallstack/common-components";
import { ICON_THEMES } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { getJsonByPath } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "GridListViewV3",
  templateName: "Raster Ansicht",
  icon: "grid",
  templateDescription:
    "Eine Layout Komponente, welche Daten vom Kontext in einer Gitteransicht (responsive) darstellt.",
  dataSchema: {
    type: "object",
    properties: {
      contextProperty: {
        type: "string",
        title: "Kontext Variable",
        description: "Geben Sie hier an, wo die Daten zu finden sind"
      },
      template: {
        type: "object",
        title: "Vorlage",
        "x-schema-form": {
          widget: "widget"
        }
      },
      link: {
        type: "string",
        title: "Verlinkung",
        description:
          "Falls gesetzt, dann leitet ein Mausklick auf die angegebene Seite weiter. Verwenden Sie den Platzhalter ${id} für die ID des aktuellen Elementes."
      },
      gridCellWidth: {
        type: "string",
        title: "Breite des Elements",
        default: "150px",
        description: "Geben Sie hier an, wie breit jedes Element sein soll.",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
      },
      gridCellWidthMobile: {
        type: "string",
        title: "Breite des Elements auf kleinen Displays",
        default: "150px",
        description: "Geben Sie hier an, wie breit jedes Element auf mobilen Endgeräten sein soll.",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
      },
      showPager: {
        type: "boolean",
        title: "Seitennavigation anzeigen",
        description: "Stellen Sie hier ein, ob und wann der Pager am unteren Rand angezeigt werden soll."
      },
      showMoreLink: {
        title: "Link zur Übesicht aller Daten zeigen",
        description:
          "Falls aktiviert, wird ein Link unterhalb der Daten angezeigt, der zu einer Unterseite führen kann, in dem alle Daten angezeigt werden.",
        default: true,
        type: "boolean"
      },
      moreLink: {
        type: "string",
        title: "Link",
        description: "Geben Sie hier den Pfad der Seite an, auf die Link verweisen soll."
      },
      label: {
        type: "string",
        title: "Link Label",
        description: "Geben Sie hier den Text an, der als Link dargestellt werden soll."
      },
      icon: {
        type: "string",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        }
      } as SchemaFormSchema,
      iconTheme: {
        type: "string",
        title: "Icon Theme",
        enum: ICON_THEMES,
        default: "color"
      },
      iconSize: {
        type: "string",
        title: "Icon Größe",
        description: "Größe der Tab Icons"
      },
      iconColor: {
        type: "string",
        title: "Icon Farbe",
        description: "Farbe der Tab Icons, falls als Theme nicht 'color' oder 'fluency' ausgewählt ist.",
        "x-schema-form": {
          widget: "color"
        },
        default: "#FFFFFF"
      } as SchemaFormSchema
    }
  },
  tags: AllWidgetTags
})
@Component({
  selector: "smallstack-grid-list-view-widget-v3",
  templateUrl: "./grid-list-view-widget-v3.component.html",
  styleUrls: ["./grid-list-view-widget-v3.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListViewWidgetV3Component extends BaseWidgetComponent {
  protected elements = computed(() => {
    const data = this.data();
    const context = this.context();
    if (data?.contextProperty) return getJsonByPath(context, data.contextProperty);
    else return context;
  });
  protected gridCellWidth = computed(() => {
    const data = this.data();
    const isMobile = this.breakpointService.isMobile();
    return isMobile === true && data.gridCellWidthMobile ? data.gridCellWidthMobile : data.gridCellWidth;
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerUtilService: RouterUtilService,
    private breakpointService: BreakpointService
  ) {
    super();
  }

  public getLink(id: string): void {
    if (this.widgetTreeService.editMode() === true) return;
    if (this.data().link) {
      const realUrl: string = this.data().link.replace("${id}", id);
      const urlTree: UrlTree = this.router.parseUrl(realUrl);
      const params: Params = urlTree?.queryParams;
      const urlWithoutParams: string = realUrl.split("?")[0];
      if (realUrl.includes("?"))
        void this.router.navigate([urlWithoutParams], {
          queryParams: params,
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge"
        });
      else void this.router.navigate([urlWithoutParams]);
    }
  }

  public openMoreLink(): void {
    if (this.data().moreLink) {
      void this.routerUtilService.navigateFullUrl(this.data().moreLink);
    }
  }
}
