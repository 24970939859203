import { JSONSchema7 } from "json-schema";
import { TypeSchema } from "../typesystem/type-schema";

export type InlineTranslation = InnerTranslation[];

export interface InnerTranslation {
  locale?: string;
  value: string;
}

export function isInnerTranslation(obj: any): obj is InnerTranslation {
  return (
    typeof obj === "object" &&
    typeof obj.value === "string" &&
    (obj.locale === undefined || typeof obj.locale === "string")
  );
}
export function isInlineTranslation(obj: any): obj is InlineTranslation {
  if (!(obj instanceof Array)) return false;
  for (const elem of obj) if (!isInnerTranslation(elem)) return false;
  return true;
}

/** Use this one for a complete property schema for dealing with inline translations */
export function createInlineTranslationSchema(options?: {
  title?: string;
  description?: string;
  minLength?: number;
  maxLength?: number;
}): TypeSchema {
  return {
    type: "array",
    title: options?.title,
    description: options?.description,
    items: createInnerTranslationSchema(options),
    "x-schema-form": {
      inputWidget: "I18nInput",
      filterWidget: "I18nFilter"
    }
  };
}

/** Use this one for only the sub part of the property schema related to the inline translation */
export function createInnerTranslationSchema(options?: { minLength?: number; maxLength?: number }): JSONSchema7 {
  const base: JSONSchema7 = {
    type: "object",
    properties: {
      locale: {
        type: "string",
        pattern: "^[a-z]{2}_[a-z]{2}$"
      },
      value: {
        type: "string"
      }
    },
    required: ["value"]
  };
  if (options?.minLength !== undefined) (base.properties.value as JSONSchema7).minLength = options.minLength;
  if (options?.maxLength !== undefined) (base.properties.value as JSONSchema7).maxLength = options.maxLength;
  return base;
}

/**
 * @param translation The inner translation object
 * @param locale The locale, like "en_us"
 * @returns The translation value for the given locale. If locale is missing in the inner translation, the default one is returned. If that one is also missing, en_us will be returned.
 */
export function extractInlineTranslation(translation: InlineTranslation, locale?: string): string {
  const foundTranslation = translation.find((trans) => trans.locale === locale);
  if (foundTranslation) return foundTranslation.value;

  // find default
  const defaultTranslation = translation.find((trans) => trans.locale === undefined);
  if (defaultTranslation) return defaultTranslation.value;

  // find for en_us
  const enUs = translation.find((trans) => trans.locale === "en_us");
  if (enUs) return enUs.value;

  return undefined;
}

/** removes all locales and returns a default translation only */
export function extractToDefaultInlineTranslation(translation: InlineTranslation, locale: string): InlineTranslation {
  const currentValue = extractInlineTranslation(translation, locale);
  return [{ locale: undefined, value: currentValue }];
}

/** converts the default translation into a translated one */
export function convertDefaultInlineTranslation(translation: InlineTranslation, locale: string): InlineTranslation {
  const currentValue = extractInlineTranslation(translation, undefined);
  return [{ locale, value: currentValue }];
}
