import { EffectRef, Injector, Signal, WritableSignal, effect, isSignal, signal, untracked } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Observable, concat, of } from "rxjs";

export interface ToWritableSignalOptions<T> {
  initialValue?: T;
  equal?: (a: T, b: T) => boolean;
}

export function toWritableSignal<T>(source$: Observable<T>, options?: ToWritableSignalOptions<T>): WritableSignal<T> {
  const writableSignal = signal<T>(options?.initialValue, { equal: options?.equal });

  const readonlySignal = toSignal<T>(concat(of(options?.initialValue), source$), {
    initialValue: options?.initialValue as any,
    requireSync: true,
    rejectErrors: true
  });

  effect(
    () => {
      writableSignal.set(readonlySignal());
    },
    { allowSignalWrites: true }
  );

  return writableSignal;
}

export function debugSignal<T>(signal: Signal<T>, name: string): void {
  let counter = 0;
  effect(() => {
    console.log("signal with prop name " + name + " changed " + ++counter + " times, current value: ", signal());
  });
}

export function waitForSignalValue<T>(signal: Signal<T>, value: T, injector: Injector, timeout = 10000): Promise<void> {
  if (!injector) throw new Error("waitForSignalValue requires an injector");
  return new Promise<void>((resolve, reject) => {
    untracked(() => {
      let effectRef: EffectRef = undefined;
      const timer = setTimeout(() => {
        if (effectRef) effectRef.destroy();
        reject(new Error(`waitForSignalValue timed out after ${timeout}ms`));
      }, timeout);
      effectRef = effect(
        () => {
          if (signal() === value) {
            clearTimeout(timer);
            resolve();
            effectRef.destroy();
          }
        },
        { injector }
      );
    });
  });
}

export function waitForSignal<T>(signal: Signal<T>, predicate: (value: T) => boolean, injector: Injector): Promise<T> {
  if (!injector) throw new Error("waitForSignal requires an injector");
  return new Promise<T>((resolve) => {
    untracked(() => {
      const ref = effect(
        () => {
          if (predicate(signal())) {
            resolve(signal());
            ref.destroy();
          }
        },
        { injector }
      );
    });
  });
}

export function waitFor<T>(reactiveFn: () => T, predicate: (value: T) => boolean, injector: Injector): Promise<T> {
  if (!injector) throw new Error("waitForSignal requires an injector");
  return new Promise<T>((resolve) => {
    untracked(() => {
      const ref = effect(
        () => {
          const value = reactiveFn();
          if (predicate(value)) {
            resolve(value);
            ref.destroy();
          }
        },
        { injector }
      );
    });
  });
}

export function debouncedSignal<T>(sourceSignal: Signal<T>, debounceTimeInMs = 800): Signal<T> {
  const debounceSignal = signal(sourceSignal());
  effect(
    (onCleanup) => {
      const value = sourceSignal();
      const timeout = setTimeout(() => debounceSignal.set(value), debounceTimeInMs);
      onCleanup(() => clearTimeout(timeout));
    },
    { allowSignalWrites: true }
  );
  return debounceSignal;
}

export type SignalOrRaw<T> = T | Signal<T>;

/**
 * Either returns the value of a signal or the primitive value, depending on the type of the input.
 */
export function getSignalOrRawValue<T>(value: SignalOrRaw<T>): T {
  return isSignal(value) ? value() : value;
}
