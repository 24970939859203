import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoadingElementDirective, StoreRegistry } from "@smallstack/common-components";
import { TypeDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { PageableCrudStore, PageableStore } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { hasTypeSupport } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { cloneObject } from "@smallstack/utils";
import { TypeDialogService } from "@smallstack/widget-core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { StoreContainerComponent } from "../store-container/store-container.component";
import { StorePropertyComponent } from "../store-property/store-property.component";
import { FilterName, StoreSearchComponent } from "../store-search/store-search.component";

/**
 * @example
 * <smallstack-store-select [store]="myStore" [multiple]="true" property="name" label="Select from My Store" [(selectedIds)]="selectedIds"></smallstack-store-select>
 */
@Component({
  selector: "smallstack-store-select",
  templateUrl: "./store-select.component.html",
  styleUrls: ["./store-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    StorePropertyComponent,
    LoadingElementDirective,
    IconComponent,
    MatMenuModule,
    MatTooltipModule,
    StoreContainerComponent,
    forwardRef(() => StoreSearchComponent),
    MatCheckboxModule,
    MatPaginatorModule,
    I18nComponent
  ]
})
export class StoreSelectComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger)
  public menuTrigger: MatMenuTrigger;

  @Input()
  public store: PageableStore | PageableCrudStore;
  @Input()
  public set storeName(storeName: string) {
    this.store = this.storeRegistry.getStore(storeName);
    if (!this.store) throw new Error("No store registered for name " + storeName);
  }
  @Input()
  public multiple: boolean;
  @Input()
  public property: string;
  @Input()
  public label = "";
  @Input()
  public propertyLength = 30;
  @Input("class")
  public classes: string = "btn btn-primary";
  @Input()
  public selectedIds: string[];
  @Input()
  public selectedId: string;
  @Input()
  public showData: boolean = false;
  @Input()
  public showSelectButton: boolean = true;
  @Input()
  public showCreationButton: boolean = true;
  @Input()
  public startWithOpenMenu: boolean = false;
  @Input()
  public uniqueId: string;
  @Output()
  public readonly selectedIdsChange = new EventEmitter<string[]>();
  @Output()
  public readonly selectedIdChange = new EventEmitter<string>();
  @Input()
  public filterNames: FilterName[] = [];
  @Output()
  public readonly overLayClose = new EventEmitter<void | "click" | "keydown" | "tab">();

  public isPageable: boolean;
  public totalElements$: Observable<number>;
  public size$: Observable<number>;
  public pageIndex$: Observable<number>;
  public storeError$: Observable<string>;
  public type: TypeDto;

  public currentPage: any[];
  public currentPageSelected = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private storeRegistry: StoreRegistry,
    private cdr: ChangeDetectorRef,
    private typeService: TypeService,
    private typeDialogService: TypeDialogService
  ) {}

  @HostListener("click", ["$event"])
  public onClick(e: MouseEvent): boolean {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  public ngOnInit(): void {
    // eval multiple
    if (this.multiple === undefined) {
      if (this.selectedIds !== undefined) this.multiple = true;
      else if (this.selectedId !== undefined) this.multiple = false;
      else this.multiple = false;
    }

    if (this.filterNames.length === 0) this.filterNames.push({ label: this.property, value: this.property });
    this.checkCurrentPageSelectionStatus();
    if (this.startWithOpenMenu) void this.openMenu();
    void this.initStore();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async resetStore(): Promise<void> {
    this.store.query$.next({ page: 1, size: 10 });
    await this.store.load({ silently: true });
    this.checkCurrentPageSelectionStatus();
  }

  /**
   * Can be used to open the menu
   */
  public async openMenu(): Promise<void> {
    await this.resetStore();
    this.menuTrigger.openMenu();
  }

  /**
   * can be used in loadingFn directives
   */
  public openMenuSync() {
    return async (): Promise<void> => {
      await this.openMenu();
    };
  }

  public openCreationDialog() {
    return async (): Promise<void> => {
      const model = await this.typeDialogService.openEditor({ typePath: this.type.path });
      if (model) {
        if (this.multiple) {
          this.selectedIds.push(model.id);
          this.selectedIdsChange.emit(this.selectedIds);
        } else {
          this.selectedId = model.id;
          this.selectedIdChange.emit(this.selectedId);
        }
      }
      void this.store.load();
    };
  }

  public onSubmitMultiple(): void {
    this.selectedIds = cloneObject(this.selectedIds);
    this.selectedIdsChange.emit(this.selectedIds);
    this.menuTrigger.closeMenu();
    this.cdr.markForCheck();
  }

  public onSubmitSingle(selection: string): void {
    this.selectedIdChange.emit(selection);
    this.selectedId = selection;
    this.menuTrigger.closeMenu();
    this.cdr.markForCheck();
  }

  public onOverlayClose(event: void | "click" | "keydown" | "tab"): void {
    this.overLayClose.emit(event);
  }

  public isSelected(id: string): boolean {
    if (this.multiple) return this.selectedIds?.includes(id);
    else return this.selectedId === id;
  }

  public onToggleSelection(checked: boolean, id: string): void {
    if (this.selectedIds === undefined) this.selectedIds = [];
    if (checked) {
      if (this.multiple)
        if (!this.selectedIds?.includes(id)) this.selectedIds = [...this.selectedIds, id];
        else this.selectedId = id;
    } else {
      if (this.multiple) this.selectedIds = this.selectedIds.filter((e) => e !== id);
      else this.selectedId = undefined;
    }
    this.checkCurrentPageSelectionStatus();
  }

  public async onChangePage(page: PageEvent): Promise<void> {
    if (this.store instanceof PageableCrudStore || this.store instanceof PageableStore) {
      const currentQuery = this.store?.query$?.value;
      this.store.query$.next({
        page: page.pageIndex + 1,
        size: page.pageSize,
        search: currentQuery?.search,
        sort: currentQuery?.sort
      });
      await this.store.load();
    }
    this.checkCurrentPageSelectionStatus();
  }

  public checkCurrentPageSelectionStatus(): void {
    // check if all items are checked on page
    if (this.currentPage) {
      let allSelected = true;
      this.currentPage.forEach((o) => {
        if (!this.isSelected(o.id)) allSelected = false;
      });
      this.currentPageSelected = allSelected;
    }
  }

  public onSelectCurrentPage(): void {
    if (this.currentPage) {
      this.currentPage.forEach((o) => {
        if (!this.isSelected(o.id)) this.selectedIds = [...this.selectedIds, o.id];
      });
    }
  }

  public onToggleCurrentPage(): void {
    if (this.currentPage && this.multiple) {
      this.currentPageSelected = !this.currentPageSelected;
      if (this.currentPageSelected) {
        if (this.currentPage instanceof Array && this.selectedIds instanceof Array) {
          this.currentPage.forEach((o) => {
            if (!this.isSelected(o.id)) this.selectedIds = [...this.selectedIds, o.id];
          });
        }
      } else {
        if (this.currentPage instanceof Array && this.selectedIds instanceof Array)
          this.currentPage.forEach((o) => {
            this.selectedIds = this.selectedIds.filter((e) => e !== o.id);
          });
      }
    }
    this.cdr.markForCheck();
  }

  public getStringRepresentation(model: any): string | InlineTranslation {
    if (!model) return "<model nicht definiert>";
    if (!this.type) return model[this.property];
    return this.typeService.getRepresentation(this.type, model);
  }

  public removeEntry(modelId: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (this.multiple) {
      this.selectedIds = this.selectedIds.filter((si) => si !== modelId);
      this.selectedIdsChange.emit(this.selectedIds);
    } else {
      this.selectedId = undefined;
      this.selectedIdChange.emit(this.selectedId);
    }
  }

  private async initStore() {
    if (hasTypeSupport<TypeDto>(this.store)) {
      this.type = await this.store.getType();
      // set property if not given
      if (!this.property) {
        if (typeof this.type?.representation === "string") this.property = this.type.representation;
      }
    } else {
      console.warn("Store has no type support: ", this.store);
    }
    await this.resetStore();
    this.subscription.add(this.store.currentPage$.subscribe((v) => (this.currentPage = v)));
    this.isPageable = this.store instanceof PageableCrudStore || this.store instanceof PageableStore;
    this.totalElements$ = this.store?.pagination$.pipe(map((v) => v.totalElements));
    this.size$ = this.store?.query$.pipe(map((v) => v.size));
    this.pageIndex$ = this.store?.query$.pipe(map((v) => v.page - 1));
  }
}
