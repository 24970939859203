import { WidgetDto } from "@smallstack/axios-api-client";

export const CustomFieldsCompoundWidgetDefinition: WidgetDto = {
  name: "VerticalContainer",
  data: {
    height: "calc(100vh - 295px)",
    width: "100%",
    padding: "12px",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: "10px",
    invertedDirection: false,
    wrap: true,
    children: [
      {
        name: "DataTableV3",
        data: {
          typeName: "custom-fields",
          listProperties: [
            { property: "title", sortable: true, title: [{ value: "Bezeichnung" }], renderer: "i18n" },
            { property: "key", sortable: true, title: [{ value: "Schlüssel" }], renderer: "raw" },
            { property: "type", sortable: true, title: [{ value: "Datentyp" }], renderer: "raw" },
            {
              property: "predefinedValue.title",
              sortable: true,
              title: [{ value: "Vordefinierter Titel" }],
              renderer: "i18n"
            },
            {
              property: "predefinedValue.value",
              sortable: true,
              title: [{ value: "Vordefinierter Wert" }],
              renderer: "raw"
            }
          ],
          showEditButton: true,
          showDeleteButton: true,
          allowMultiDelete: true,
          showCreateButton: true,
          showCheckboxes: true,
          initialSortProperty: "key"
        }
      }
    ]
  }
};
