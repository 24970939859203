import { TypeSchema } from "../typesystem/type-schema";
import { DataTypeStorage, DataTypeStorageSchema } from "./data-type-storage";

export interface DataTypeStorageDefinition {
  pull?: DataTypeStorage;
  push?: DataTypeStorage;
}

export const DataTypeStorageDefinitionSchema: TypeSchema = {
  type: "object",
  properties: {
    pull: DataTypeStorageSchema,
    push: DataTypeStorageSchema
  }
};
