import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { WidgetRendererOptions } from "@smallstack/widget-core";

export interface ComponentDialogData {
  cmsComponent: WidgetDto;
  showCloseBtn?: boolean;
  widgetRendererOptions?: WidgetRendererOptions;
}

@Component({
  templateUrl: "./component-dialog.component.html",
  styleUrls: ["./component-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialogData,
    public dialogRef: MatDialogRef<ComponentDialogComponent>
  ) {
    if (!data.widgetRendererOptions) data.widgetRendererOptions = {};
    data.widgetRendererOptions.dialogRef = dialogRef;
  }

  public close(): void {
    if (this.data?.widgetRendererOptions?.editMode === true) this.dialogRef.close(this.data.cmsComponent);
    else this.dialogRef.close();
  }
}
