/**
 * Map an array exactly like Array.prototype.map(), but async
 */
export function asyncMap<T, U>(
  array: T[],
  callbackFn: (value: T, index: number, array: T[]) => Promise<U>
): Promise<U[]> {
  return Promise.all(array.map(callbackFn));
}

/**
 * Filter an array exactly like Array.prototype.filter(), but async
 */
export async function asyncFilter<T>(
  array: T[],
  callbackFn: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<T[]> {
  const filterMap = await asyncMap(array, callbackFn);
  return array.filter((value, index) => filterMap[index]);
}
