/* eslint-disable max-lines-per-function */
import { ClipboardModule } from "@angular/cdk/clipboard";
import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import {
  ConfigurationStore,
  ConvertToSubjectPipe,
  CountUpDirective,
  DataStore,
  FlattenPipe,
  LoadingElementDirective,
  NonEmptyObjectPipe,
  NonEmptyStringPipe,
  ObjectArrayValuePipe,
  SortPipe,
  StoreRegistry,
  TextReplacerPipe
} from "@smallstack/common-components";
import { SmallstackCrmModule } from "@smallstack/crm-components";
import { SmallstackFormModule } from "@smallstack/form-components";
import { DurationComponent, NotificationService, SmallstackI18nModule } from "@smallstack/i18n-components";
import { RxModelsComponent } from "@smallstack/rx-components";
import {
  ListContainerComponent,
  LoaderComponent,
  MatSortStoreDirective,
  StoreModelComponent,
  StorePropertyComponent,
  StoreSearchComponent
} from "@smallstack/store-components";
import { SmallstackTextModule } from "@smallstack/text-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { TYPE_COUNTERS } from "@smallstack/typesystem";
import { TypeIconPipe } from "@smallstack/typesystem-client";
import { SmallstackUserModule } from "@smallstack/user-components";
import {
  AllWidgetTags,
  SmallstackFormCoreModule,
  SmallstackWidgetsModule,
  WidgetRegistry,
  WidgetTag
} from "@smallstack/widget-core";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  CompareValueContainerWidgetComponent,
  ComponentDialogComponent,
  CompoundWidgetWidgetComponent,
  ConfigurablePropertyInputComponent,
  CreateDataWidgetComponent,
  CurrentUserWidgetComponent,
  DashboardGridWidgetComponent,
  DataWidgetComponent,
  DefinedContainerWidgetComponent,
  DialogActionButtonDialogComponent,
  DialogActionButtonWidgetComponent,
  DividerComponent,
  FlexboxContainerWidgetComponent,
  FloatingActionButtonDialogComponent,
  FloatingActionButtonWidgetComponent,
  FormTableWidgetComponent,
  FullscreenLayoutComponent,
  GridListViewWidgetV3Component,
  GridViewWidgetComponent,
  HorizontalContainerWidgetComponent,
  IconCardComponent,
  IconTabsWidgetComponent,
  KeyValueTableWidgetComponent,
  NavigationButtonWidgetComponent,
  PromotionListComponent,
  RandomChuckNorrisJokeWidgetComponent,
  RedirectWidgetComponent,
  RegistrationComponent,
  ShrinkingHeaderWidgetComponent,
  TextSearchWidgetComponent,
  ThemeEditorComponent,
  VerticalContainerWidgetComponent,
  WebComponentWidgetComponent
} from "./components";
import { BlogPostOverviewComponent } from "./components/blog/blog-post-overview/blog-post-overview.component";
import { BlogPostComponent } from "./components/blog/blog-post/blog-post.component";
import { CmsNavigationComponent } from "./components/cms-navigation/cms-navigation.component";
import { CmsPageAddWidgetComponent } from "./components/cms-page-add-widget/cms-page-add-widget.component";
import { CmsPageComponent } from "./components/cms-page/cms-page.component";
import { CmsFormInputComponent } from "./components/form-inputs/cms-form-input/cms-form-input.component";
import { SimpleCmsComponent } from "./components/simple-cms/simple-cms.component";
import { ContactDetailDialogComponent } from "./components/widgets/contacts-list-widget/contact-detail-dialog/contact-detail-dialog.component";
import { ContactsListWidgetComponent } from "./components/widgets/contacts-list-widget/contacts-list-widget.component";
import { ContainerWidgetComponent } from "./components/widgets/container-widget/container-widget.component";
import { CountWidgetComponent } from "./components/widgets/count-widget/count-widget.component";
import { CustomerProfileWidgetComponent } from "./components/widgets/customer-profile-widget/customer-profile-widget.component";
import { DataProducerWidgetComponent } from "./components/widgets/data-producer-widget/data-producer-widget";
import { DeleteEntityBtnWidgetComponent } from "./components/widgets/delete-entity-btn-widget/delete-entity-btn-widget.component";
import { DndBoardWidget } from "./components/widgets/dnd-board-widget";
import { DoubleOptConfirmationWidgetComponent } from "./components/widgets/double-opt-confirmation-widget/double-opt-confirmation-widget.component";
import { PeriodOfTimeInputWidget } from "./components/widgets/duration-input-widget";
import { EventsWidgetComponent } from "./components/widgets/events-widget/events-widget.component";
import { ExternalCountWidgetComponent } from "./components/widgets/external-count-widget/external-count-widget.component";
import { FilterBarWidget } from "./components/widgets/filter-bar-widget";
import { GenericListWidgetComponent } from "./components/widgets/generic-list-widget/generic-list-widget.component";
import { GlobalStoreSaveButtonWidgetComponent } from "./components/widgets/global-store-save-button-widget/global-store-save-button-widget.component";
import { GlobalsDebuggerWidgetComponent } from "./components/widgets/globals-debugger-widget/globals-debugger-widget.component";
import { GoogleMapsListWidget } from "./components/widgets/google-maps-list-widget";
import { GridListViewWidgetV2Component } from "./components/widgets/grid-list-view-widget-v2/grid-list-view-widget-v2.component";
import { GridListViewWidgetComponent } from "./components/widgets/grid-list-view-widget/grid-list-view-widget.component";
import { HtmlWidgetComponent } from "./components/widgets/html-widget/html-widget.component";
import { IFrameWidgetComponent } from "./components/widgets/iframe-widget/iframe-widget.component";
import { ImmoScout24WidgetComponent } from "./components/widgets/immoscout24-widget/immoscout24-widget.component";
import { LastNewUserRegistrationsWidgetComponent } from "./components/widgets/last-new-user-registrations-widget/last-new-user-registrations-widget.component";
import { LatestOrdersWidgetComponent } from "./components/widgets/latest-orders-widget/latest-orders-widget.component";
import { LinkButtonWidgetComponent } from "./components/widgets/link-button-widget/link-button-widget.component";
import { LinkWidgetComponent } from "./components/widgets/link-widget/link-widget.component";
import { LoginStatusWidgetComponent } from "./components/widgets/login-status-widget/login-status-widget.component";
import { MarkdownWidgetComponent } from "./components/widgets/markdown-widget/markdown-widget.component";
import { MaxWidthContainerWidgetComponent } from "./components/widgets/max-width-container-widget/max-width-container-widget.component";
import { Microsoft365FilesWidget } from "./components/widgets/microsoft365-files-widget";
import { MyCalendarEventsComponent } from "./components/widgets/my-calendar-events/my-calendar-events.component";
import { PageTitleWidgetComponent } from "./components/widgets/page-title-widget/page-title-widget.component";
import { PdfExporterWidget } from "./components/widgets/pdf-exporter-widget";
import { PercentageBarChartWidgetComponent } from "./components/widgets/percentage-bar-chart-widget/percentage-bar-chart-widget.component";
import { PrintButtonWidget } from "./components/widgets/print-button-widget";
import { ResourcePlanningUserConfigurationComponent } from "./components/widgets/resource-planning-user-configuration/resource-planning-user-configuration.component";
import { ResourcePlanningUserStatsComponent } from "./components/widgets/resource-planning-user-stats/resource-planning-user-stats.component";
import { ResourcePlanningUserTableWidget } from "./components/widgets/resource-planning-user-table";
import { RouterOutletWidgetComponent } from "./components/widgets/router-outlet-widget/router-outlet-widget.component";
import { SliderWidgetComponent } from "./components/widgets/slider-widget/slider-widget.component";
import { StatsWidgetComponent } from "./components/widgets/stats-widget/stats-widget.component";
import { StorePropertyWidgetComponent } from "./components/widgets/store-property-widget/store-property-widget.component";
import { StoreSearchWidgetComponent } from "./components/widgets/store-search-widget/store-search-widget.component";
import { TextInputWidgetComponent } from "./components/widgets/text-input-widget/text-input-widget.component";
import { TextWidget } from "./components/widgets/text-widget";
import { TimelineWidgetComponent } from "./components/widgets/timeline-widget/timeline-widget.component";
import { VideoPlayerWidgetComponent } from "./components/widgets/video-player-widget/video-player-widget.component";
import { SmallstackCustomElementsSchemaModule } from "./custom-elements-schema.module";
import { CmsPagesStore } from "./store/cms-pages.store";
import { CounterStore } from "./store/counter.store";

const components = [
  BlogPostComponent,
  BlogPostOverviewComponent,
  CmsFormInputComponent,
  CmsNavigationComponent,
  CmsPageAddWidgetComponent,
  CmsPageComponent,
  ComponentDialogComponent,
  ContactDetailDialogComponent,
  ContactsListWidgetComponent,
  ContainerWidgetComponent,
  CountWidgetComponent,
  CreateDataWidgetComponent,
  CustomerProfileWidgetComponent,
  DoubleOptConfirmationWidgetComponent,
  EventsWidgetComponent,
  ExternalCountWidgetComponent,
  FlexboxContainerWidgetComponent,
  FormTableWidgetComponent,
  FullscreenLayoutComponent,
  GenericListWidgetComponent,
  GridListViewWidgetComponent,
  HorizontalContainerWidgetComponent,
  HtmlWidgetComponent,
  ImmoScout24WidgetComponent,
  LastNewUserRegistrationsWidgetComponent,
  LatestOrdersWidgetComponent,
  LinkWidgetComponent,
  LoginStatusWidgetComponent,
  MarkdownWidgetComponent,
  PromotionListComponent,
  RouterOutletWidgetComponent,
  SimpleCmsComponent,
  SliderWidgetComponent,
  StorePropertyWidgetComponent,
  StoreSearchWidgetComponent,
  TextInputWidgetComponent,
  VerticalContainerWidgetComponent,
  VideoPlayerWidgetComponent,
  DefinedContainerWidgetComponent,
  MyCalendarEventsComponent,
  PageTitleWidgetComponent,
  GridListViewWidgetV2Component,
  CurrentUserWidgetComponent,
  DashboardGridWidgetComponent,
  DialogActionButtonWidgetComponent,
  DialogActionButtonDialogComponent,
  RandomChuckNorrisJokeWidgetComponent,
  TimelineWidgetComponent,
  TextSearchWidgetComponent,
  FloatingActionButtonWidgetComponent,
  FloatingActionButtonDialogComponent,
  IconTabsWidgetComponent,
  CompoundWidgetWidgetComponent,
  DataWidgetComponent,
  GridListViewWidgetV3Component,
  DataProducerWidgetComponent,
  GridViewWidgetComponent,
  CompareValueContainerWidgetComponent,
  KeyValueTableWidgetComponent,
  NavigationButtonWidgetComponent,
  ShrinkingHeaderWidgetComponent,
  ConfigurablePropertyInputComponent
];

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    FormsModule,
    FormsModule,
    MatBottomSheetModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSelectModule,
    OverlayModule,
    SmallstackThemeModule,
    ReactiveFormsModule,
    RouterModule,
    CurrencyMaskModule,
    SmallstackCustomElementsSchemaModule,
    SmallstackFormModule,
    SmallstackI18nModule,
    SmallstackTextModule,
    SmallstackThemeModule,
    SmallstackUserModule,
    SmallstackWidgetsModule,
    SmallstackFormCoreModule,
    SmallstackCrmModule,
    ThemeEditorComponent,
    IconCardComponent,
    LoadingElementDirective,
    FlattenPipe,
    CountUpDirective,
    ObjectArrayValuePipe,
    NonEmptyObjectPipe,
    NonEmptyStringPipe,
    MatSortStoreDirective,
    TextReplacerPipe,
    SortPipe,
    ConvertToSubjectPipe,
    DurationComponent,
    LoaderComponent,
    StoreSearchComponent,
    StorePropertyComponent,
    ListContainerComponent,
    StoreModelComponent,
    TypeIconPipe,
    RegistrationComponent,
    DividerComponent,
    RxModelsComponent
  ],
  declarations: components,
  exports: [...components, RegistrationComponent, DividerComponent],
  providers: [CurrencyPipe, DataStore, CmsPagesStore, ConfigurationStore, CounterStore, NotificationService]
})
export class SmallstackCmsModule {
  // eslint-disable-next-line max-lines-per-function
  constructor(widgetRegistry: WidgetRegistry, storeRegistry: StoreRegistry, counterStore: CounterStore) {
    // add some stores
    storeRegistry.registerStore(TYPE_COUNTERS, counterStore);

    // add widgets
    widgetRegistry.registerWidget(MyCalendarEventsComponent);
    widgetRegistry.registerWidget(IFrameWidgetComponent);
    widgetRegistry.registerWidget(PageTitleWidgetComponent);
    widgetRegistry.registerWidget(ContainerWidgetComponent);
    widgetRegistry.registerWidget(GridListViewWidgetComponent);
    widgetRegistry.registerWidget(GridListViewWidgetV2Component);
    widgetRegistry.registerWidget(CurrentUserWidgetComponent);
    widgetRegistry.registerWidget(DashboardGridWidgetComponent);
    widgetRegistry.registerWidget(LinkWidgetComponent);
    widgetRegistry.registerWidget(DialogActionButtonWidgetComponent);
    widgetRegistry.registerWidget(RandomChuckNorrisJokeWidgetComponent);
    widgetRegistry.registerWidget(TimelineWidgetComponent);
    widgetRegistry.registerWidget(TextSearchWidgetComponent);
    widgetRegistry.registerWidget(FloatingActionButtonWidgetComponent);
    widgetRegistry.registerWidget(CompoundWidgetWidgetComponent);
    widgetRegistry.registerWidget(IconTabsWidgetComponent);
    widgetRegistry.registerWidget(DataWidgetComponent);
    widgetRegistry.registerWidget(GridListViewWidgetV3Component);
    widgetRegistry.registerWidget(DataProducerWidgetComponent);
    widgetRegistry.registerWidget(GridViewWidgetComponent);
    widgetRegistry.registerWidget(CompareValueContainerWidgetComponent);
    widgetRegistry.registerWidget(KeyValueTableWidgetComponent);
    widgetRegistry.registerWidget(NavigationButtonWidgetComponent);
    widgetRegistry.registerWidget(ShrinkingHeaderWidgetComponent);
    widgetRegistry.registerWidget(DeleteEntityBtnWidgetComponent);
    widgetRegistry.registerWidget(StatsWidgetComponent);
    widgetRegistry.registerWidget(LinkButtonWidgetComponent);
    widgetRegistry.registerWidget(GlobalsDebuggerWidgetComponent);
    widgetRegistry.registerWidget(GlobalStoreSaveButtonWidgetComponent);
    widgetRegistry.registerWidget(ResourcePlanningUserStatsComponent);
    widgetRegistry.registerWidget(ResourcePlanningUserConfigurationComponent);
    widgetRegistry.registerWidget(PercentageBarChartWidgetComponent);
    widgetRegistry.registerWidget(RedirectWidgetComponent);
    widgetRegistry.registerWidget(MaxWidthContainerWidgetComponent);
    widgetRegistry.registerWidget(RouterOutletWidgetComponent);
    widgetRegistry.addWidget(PdfExporterWidget);
    widgetRegistry.addWidget(TextWidget);
    widgetRegistry.addWidget(ResourcePlanningUserTableWidget);
    widgetRegistry.addWidget(PeriodOfTimeInputWidget);
    widgetRegistry.addWidget(PrintButtonWidget);
    widgetRegistry.addWidget(DndBoardWidget);
    widgetRegistry.addWidget(FilterBarWidget);
    widgetRegistry.addWidget(Microsoft365FilesWidget);
    widgetRegistry.addWidget(GoogleMapsListWidget);

    widgetRegistry.addDeprecatedWidget({
      name: "ExternalCount",
      templateName: "Externe Zahlenstatistik",
      icon: "area-chart",
      templateDescription: "Zeigt Zahlen von externen Statistiken an.",
      data: { widgetIcon: "area-chart", widgetTitle: [{ value: "Statistik" }] },
      component: ExternalCountWidgetComponent,
      configuration: ExternalCountWidgetComponent.configuration,
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "Count",
      templateName: "Zahlenstatistik",
      icon: "statistics",
      templateDescription:
        "Zeigt Zahlenstatistiken an, beispielsweise die Anzahl der Kunden oder offenen Bestellungen.",
      data: { widgetIcon: "statistics", widgetTitle: [{ value: "Statistik" }] },
      component: CountWidgetComponent,
      configuration: CountWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "Events",
      templateName: "Termine eines Kalenders",
      icon: "event",
      templateDescription: "Zeigt die heutigen Termine eines beliebigen Kalenders an.",
      data: { widgetIcon: "event", widgetTitle: [{ value: "Kalender-Termine" }] },
      component: EventsWidgetComponent,
      configuration: EventsWidgetComponent.getConfiguration(),
      tags: [WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD]
    });

    widgetRegistry.addDeprecatedWidget({ name: "LegacyMarkdown", component: MarkdownWidgetComponent });

    widgetRegistry.addDeprecatedWidget({
      name: "Markdown",
      templateName: "Markdown Text",
      icon: "markdown",
      templateDescription: "Schreiben Sie Texte im plattform-unabhängigen Markdown Stil.",
      data: [],
      component: MarkdownWidgetComponent,
      tags: [WidgetTag.CMS, WidgetTag.CBO, WidgetTag.APPLICATION]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "LastNewUserRegistrations",
      templateName: "Neueste Registrierungen",
      icon: "user-group-man-man",
      templateDescription: "Zeigt die letzten Neuregistrierungen der Benutzer an.",
      data: { widgetIcon: "user", widgetTitle: [{ value: "Neueste Registrierungen" }] },
      component: LastNewUserRegistrationsWidgetComponent,
      configuration: LastNewUserRegistrationsWidgetComponent.getConfiguration(),
      tags: [WidgetTag.DASHBOARD]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "FlexboxContainer",
      templateName: "Flexbox Container",
      icon: "select-row",
      templateDescription: "Ein Flexbox basierter Container für weitere Widgets.",
      component: FlexboxContainerWidgetComponent,
      configuration: FlexboxContainerWidgetComponent.getConfiguration(),
      tags: [...AllWidgetTags, WidgetTag.CONTAINER]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "HorizontalContainer",
      templateName: "Horizontaler Container",
      icon: "row",
      templateDescription: "Ein Container für weitere Widgets, welche horizontal angeordnet werden.",
      component: HorizontalContainerWidgetComponent,
      configuration: HorizontalContainerWidgetComponent.getConfiguration(),
      tags: [...AllWidgetTags, WidgetTag.CONTAINER]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "VerticalContainer",
      templateName: "Vertikaler Container",
      icon: "column",
      templateDescription: "Ein Container für weitere Widgets, welche vertikal angeordnet werden.",
      component: VerticalContainerWidgetComponent,
      configuration: VerticalContainerWidgetComponent.getConfiguration(),
      tags: [...AllWidgetTags, WidgetTag.CONTAINER]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "WebComponent",
      templateName: "Web Component",
      icon: "web-design",
      templateDescription: "Zeigt eine Web Component an.",
      component: WebComponentWidgetComponent,
      configuration: WebComponentWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "StoreSearch",
      templateName: "Store Search",
      icon: "search",
      templateDescription: "Zeigt eine Store Search Component an.",
      component: StoreSearchWidgetComponent,
      configuration: StoreSearchWidgetComponent.getConfiguration(),
      tags: [WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO],
      sockets: StoreSearchWidgetComponent.sockets
    });

    widgetRegistry.addDeprecatedWidget({
      name: "Html",
      templateName: "HTML Komponente",
      icon: "html-5--v1",
      templateDescription:
        "Eine Komponente, die HTML rendert. Kann zusammen mit einem Store Model dynamisch gestaltet werden.",
      component: HtmlWidgetComponent,
      configuration: HtmlWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "LoginStatus",
      templateName: "Login Status",
      icon: "login-rounded-down",
      templateDescription:
        "Zeigt den Anmeldestatus an und bietet Benutzern die Möglichkeit, sich anzumelden oder einen neuen Account zu registrieren.",
      component: LoginStatusWidgetComponent,
      configuration: LoginStatusWidgetComponent.getConfiguration(),
      tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CMS]
    });

    widgetRegistry.addDeprecatedWidget({
      name: "Slider",
      templateName: "Slider",
      icon: "image-gallery",
      templateDescription: "Zeigt Bilder in vorgegebenen Intervallen nacheinander an.",
      component: SliderWidgetComponent,
      configuration: SliderWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "PromotionList",
      templateName: "Promotion Liste",
      icon: "commercial",
      templateDescription: "Preisen Sie Produkte oder Features in einer Liste an.",
      component: PromotionListComponent,
      configuration: PromotionListComponent.getConfiguration(),
      tags: AllWidgetTags
    });

    widgetRegistry.addDeprecatedWidget({
      name: "GenericList",
      templateName: "Generische Liste",
      icon: "list",
      templateDescription: "Eine konfigurierbare Liste",
      component: GenericListWidgetComponent,
      configuration: GenericListWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "FormTable",
      templateName: "Formular als Tabelle",
      icon: "text-input-form",
      templateDescription: "Formular wird tabellarisch angezeigt",
      component: FormTableWidgetComponent,
      configuration: FormTableWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "VideoPlayer",
      templateName: "Video Player",
      icon: "video",
      templateDescription: "Zeige ein Video an",
      component: VideoPlayerWidgetComponent,
      configuration: VideoPlayerWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "CustomerProfile",
      templateName: "Kunden-Profilseite",
      icon: "client-company",
      templateDescription: "Zeigt eine Kunden-Profilseite an, bei welcher die Daten editiert werden können.",
      component: CustomerProfileWidgetComponent,
      tags: [WidgetTag.APPLICATION, WidgetTag.CMS]
    });
    widgetRegistry.addDeprecatedWidget({
      name: "LatestOrders",
      templateName: "Letzte Bestellungen",
      icon: "purchase-order",
      templateDescription: "Zeigt die letzten Bestellungen in einer Liste an.",
      component: LatestOrdersWidgetComponent,
      configuration: LatestOrdersWidgetComponent.getConfiguration(),
      tags: [WidgetTag.DASHBOARD]
    });
    widgetRegistry.addDeprecatedWidget({
      name: "StoreProperty",
      templateName: "Store Property",
      icon: "web-design",
      templateDescription: "Zeigt eine Store Property an.",
      component: StorePropertyWidgetComponent,
      configuration: StorePropertyWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "CreateData",
      templateName: "Daten erstellen",
      icon: "add",
      templateDescription: "Erstelle Daten für Datensätze",
      component: CreateDataWidgetComponent,
      configuration: CreateDataWidgetComponent.getConfiguration(),
      tags: [WidgetTag.APPLICATION, WidgetTag.CMS, WidgetTag.CBO]
    });
    widgetRegistry.addDeprecatedWidget({
      name: "ImmoScout24",
      templateName: "ImmoScout24",
      icon: "home",
      templateDescription: "Zeigt eine Auswahl meiner Immobilien an.",
      component: ImmoScout24WidgetComponent,
      configuration: ImmoScout24WidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "ContactsList",
      templateName: "Liste aller Kontakte",
      icon: "contacts",
      templateDescription: "Zeigt eine durchsuchbare Liste der Kontakte an.",
      component: ContactsListWidgetComponent,
      configuration: ContactsListWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "DoubleOptConfirmation",
      templateName: "Opt-in / Opt-out KundenBestätigung",
      icon: "checked-checkbox",
      templateDescription:
        "Ein unsichtbares Widget, welches ggf. Opt-In bzw. Opt-Out Tokens von Kunden bestätigen kann",
      component: DoubleOptConfirmationWidgetComponent,
      configuration: DoubleOptConfirmationWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
    widgetRegistry.addDeprecatedWidget({
      name: "TextInput",
      templateName: "Text Eingabe",
      icon: "rename",
      templateDescription: "Ein Text Feld, welches den Inhalt an andere Widgets senden kann",
      component: TextInputWidgetComponent,
      configuration: TextInputWidgetComponent.getConfiguration(),
      tags: AllWidgetTags,

      sockets: TextInputWidgetComponent.sockets
    });

    widgetRegistry.addDeprecatedWidget({
      name: "DefinedContainer",
      templateName: "Objekt existiert ja/nein Container",
      icon: "question-mark",
      templateDescription:
        "Ein Widget, welches zwei unterschiedliche Container anzeigt, je nachdem ob das angegebene Objekt definiert ist oder nicht.",
      component: DefinedContainerWidgetComponent,
      configuration: DefinedContainerWidgetComponent.getConfiguration(),
      tags: AllWidgetTags
    });
  }
}
