import { Injectable, Injector, inject } from "@angular/core";
import { Page } from "@smallstack/api-shared";
import { ContextService, EnvironmentService, SignalOrRaw } from "@smallstack/client-common";
import { Model } from "@smallstack/typesystem";
import { FetchCrudService } from "./fetch-crud.service";

export interface CrudServiceOptions {
  typePath: SignalOrRaw<string>;
  /** defaults to `inject(ContextService)` */
  contextService?: ContextService;
  /** defaults to `contextService.context().tenantId` */
  tenantId?: SignalOrRaw<string>;
  /** defaults to `inject(Injector)` */
  injector?: Injector;
}

export function injectCrudService<TModel extends Model>(options: CrudServiceOptions): CrudService<TModel> {
  if (!options.contextService) options.contextService = inject(ContextService);
  if (!options.injector) options.injector = inject(Injector);
  return inject(CrudServiceFactory).getCrudService(options);
}

@Injectable({ providedIn: "root" })
export class CrudServiceFactory<TModel extends Model = Model> {
  private environmentService = inject(EnvironmentService);

  public getCrudService(options: CrudServiceOptions): CrudService<TModel> {
    return new FetchCrudService(options, this.environmentService, options.contextService);
  }
}

export interface CrudService<TModel extends Model = Model> {
  /** Gets a model by id */
  get(id: string): Promise<TModel>;

  /** Gets all models */
  getAll(): Promise<TModel[]>;

  getPage(page: number, pageSize: number): Promise<Page<TModel>>;

  /** Creates a model */
  post(model: TModel): Promise<TModel>;

  /** Deletes a model */
  del(id: string): Promise<void>;
  /** Patches a model */
  patch(id: string, model: Partial<TModel>): Promise<TModel>;

  /** Replaces a model. Please consider using a patch instead. */
  put(model: TModel): Promise<TModel>;

  /** If model has an id, then the model is updated via put, otherwise created via post */
  postOrPut(model: TModel): Promise<TModel>;
}
