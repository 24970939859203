import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  selector: "smallstack-pdf-viewer",
  standalone: true,
  imports: [],
  templateUrl: "./pdf-viewer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements AfterViewInit {
  @Input() public src: string;
  @Input() public showToolbar = true;
  @Input() public showBorders = true;
  @Input() public height: string;

  @ViewChild("componentHost", { static: true, read: ViewContainerRef })
  public componentHost: ViewContainerRef;

  public ngAfterViewInit(): void {
    void import("./real-pdf-viewer/real-pdf-viewer.component").then((m) => {
      const componentRef = this.componentHost.createComponent(m.RealPdfViewerComponent);
      componentRef.instance.src = this.src;
      componentRef.instance.showToolbar = this.showToolbar;
      componentRef.instance.showBorders = this.showBorders;
      componentRef.instance.height = this.height;
      componentRef.changeDetectorRef.detectChanges();
    });
  }
}
