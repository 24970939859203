export enum WidgetTag {
  /**Widgets for dashboards */
  DASHBOARD = "dashboard",
  /** CMS Pages, Blog etc. */
  CMS = "cms",
  /** smallstack Applications */
  APPLICATION = "application",
  /** Custom Backoffice Module */
  CBO = "cbo",
  /** For icon slots, e.g. in the Backoffice navigation, must react to context.iconSize and context.labelStyle = "popover" | "right" */
  ICON = "icon",
  /** All widgets that represent a container */
  CONTAINER = "container",
  /** Widgets that can be used in forms */
  FORM_INPUT = "form-input",
  /** Widgets that can be used in filter forms */
  FILTER_INPUT = "filter-input"
}

export const AllWidgetTags: WidgetTag[] = [WidgetTag.DASHBOARD, WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO];
