import { Injectable } from "@angular/core";
import { StringProvider } from "@smallstack/utils";
import { BehaviorSubject } from "rxjs";

export interface ApiConfiguration {
  apiUrl?: StringProvider;
}

/** Service that provides the current api configuration */
@Injectable({ providedIn: "root" })
export class ApiConfigurationService {
  public configuration$: BehaviorSubject<ApiConfiguration> = new BehaviorSubject<ApiConfiguration>({
    apiUrl: "http://localhost:8080"
  });
}
