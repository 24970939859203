import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AxiosApiClient, ExternalApi } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "immoscout24-widget",
  templateUrl: "./immoscout24-widget.component.html",
  styleUrls: ["./immoscout24-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoScout24WidgetComponent extends BaseWidgetComponent implements OnInit {
  public listings: any[];
  public errorMessage: string;
  private readonly SANDBOX_URL = "https://www.sandbox-immobilienscout24.de/expose/";
  private readonly PROD_URL = "https://www.immobilienscout24.de/expose/";

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        group: {
          type: "string",
          title: "Immobiliengruppe (Wichtig!)",
          description: "Muss der Untergruppe in der ImmoScout24 Konfiguration im Backoffice entsprechen!"
        },
        prod: {
          type: "boolean",
          title: "Zugriff auf produktive ImmoScout24 API",
          description:
            "Zu Testzwecken wird auf ImmoScout24 Sandbox Daten zugegriffen. Legen Sie dazu eine entsprechende Sandbox Account bei ImmoScout24 an und übertragen Sie die Konfiguration im Backoffice.",
          default: false
        },
        header: {
          type: "array",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_I18N
          },
          title: "Titel"
        },
        noListings: {
          type: "array",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_I18N
          },
          title: "Hilfetext: Keine Immobilien",
          description: "Text welcher angezeigt wird, falls aktuell keine Immobilien angeboten werden."
        }
      }
    };
  }

  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public ngOnInit(): void {
    void this.fetchListings();
  }

  public async fetchListings(): Promise<void> {
    let realEstatePages: any;
    try {
      realEstatePages = (
        await this.axiosApiClient
          .get(ExternalApi)
          .getMyListings({ configGroup: this.data().group, useProd: Boolean(this.data().prod) })
      )?.data["realestates.realEstates"];
    } catch (e) {
      this.errorMessage = e.response?.data?.message;
    }
    if (realEstatePages) {
      this.errorMessage = undefined;
      if (
        !realEstatePages?.Paging?.numberOfHits ||
        realEstatePages?.Paging?.numberOfHits === 0 ||
        !realEstatePages?.realEstateList?.realEstateElement
      )
        this.listings = [];
      // single elements are returned as objects, not in an array
      else
        this.listings =
          realEstatePages?.realEstateList?.realEstateElement instanceof Array
            ? realEstatePages?.realEstateList.realEstateElement
            : [realEstatePages.realEstateList.realEstateElement];
    }
    this.cdr.markForCheck();
  }

  public onOpenListing(listingId: string): void {
    const url = this.data().prod ? this.PROD_URL : this.SANDBOX_URL;
    window.open(`${url}${listingId}`, "_blank");
  }
}
