import { Injectable } from "@angular/core";
import { AxiosApiClient, WorklogDto, WorklogsApi } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class WorklogStore extends PageableCrudStore<WorklogDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<WorklogDto>> {
    const res = await this.axiosApiClient.get(WorklogsApi).getWorklogs(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<WorklogDto> {
    const res = await this.axiosApiClient.get(WorklogsApi).getWorklog({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(WorklogsApi).deleteWorklog({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(WorklogsApi).deleteManyWorklogs({ ids });
    return res.data;
  }
  protected async createModel(worklog: WorklogDto): Promise<WorklogDto> {
    const res = await this.axiosApiClient.get(WorklogsApi).createWorklog({ worklog });
    return res.data;
  }
  protected async patchModel(id: string, worklog: Partial<WorklogDto>): Promise<WorklogDto> {
    const res = await this.axiosApiClient.get(WorklogsApi).patchWorklog({ id, worklog });
    return res.data;
  }
  protected async putModel(worklog: WorklogDto): Promise<WorklogDto> {
    const res = await this.axiosApiClient.get(WorklogsApi).putWorklog({ id: worklog.id, worklog });
    return res.data;
  }
}
