import { JsonPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TypeDescriptor } from "@smallstack/typesystem";
import { TypeDetailsComponent } from "../type-details/type-details.component";
import { NonEmptyStringPipe } from "@smallstack/common-components";

export interface TypeDetailsDialogData {
  typeDescriptor: TypeDescriptor;
  modelId: string;
  title: string | InlineTranslation;
  url?: string;
  openEditor: () => Promise<void>;
}

@Component({
  templateUrl: "./type-details-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-type-details-dialog",
  standalone: true,
  imports: [TypeDetailsComponent, MatDialogModule, I18nComponent, JsonPipe, NonEmptyStringPipe]
})
export class TypeDetailsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: TypeDetailsDialogData,
    private matDialogRef: MatDialogRef<TypeDetailsDialogComponent>,
    private router: Router
  ) {}

  protected async open(newWindow: boolean): Promise<void> {
    if (newWindow === true) window.open(this.data.url, "_blank");
    else {
      await this.router.navigateByUrl(this.data.url);
      if (this.matDialogRef) this.matDialogRef.close();
    }
  }

  protected async openEditor(): Promise<any> {
    return this.data.openEditor();
  }
}
