import {
  ChangeDetectorRef,
  computed,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  input,
  Input,
  OnDestroy,
  Output,
  Renderer2
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { Logger } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { getJsonByPath, replaceVariablesInObject, safeStringify } from "@smallstack/utils";
import { Subscription } from "rxjs";
import { WidgetComponent } from "../interfaces/widget-component";
import { WidgetTreeService } from "../services/widget-tree.service";

@Directive()
export abstract class BaseWidgetComponent<DATA_TYPE = any> implements WidgetComponent, OnDestroy {
  /**
   * Will be set by the {@link WidgetRendererComponent}
   */
  public set styles(styles: any) {
    if (styles) {
      const styleClassElement: HTMLStyleElement = this.renderer.createElement("style");
      styleClassElement.textContent = styles.replace(/\.component/g, ".component-" + this.id);
      this.renderer.appendChild(this.element.nativeElement, styleClassElement);
    }
  }

  @HostBinding("class")
  public cssClass: string;

  /**
   * The ID of the widget inside a WidgetTree, will be set by the {@link WidgetTreeService}
   */
  public set id(id: string) {
    if (this.widgetId) throw new Error("Widget already has an id!");
    this.widgetId = id;
    if (this.widgetTreeService) this.widgetTreeService.registerWidget(this.id, this);
  }
  public get id(): string {
    return this.widgetId;
  }

  /**
   * The name of the widget, e.g. "Text" or "Markdown"
   */
  @Input() public name: string;

  /**
   * The meta data of the widget, contains context related configuration, e.g. on a dashboard the current x, y, col and row
   */
  @Input() public meta: any = {};

  public readonly data = input<DATA_TYPE>({} as DATA_TYPE);
  public readonly context = input<any>({});

  public getData(key: string, defaultValue?: any): any {
    return getJsonByPath(this.data(), key) || defaultValue;
  }

  /** Same as data, but all ${VAR}s are replaced with context variables */
  public contextReplacedData = computed(() => {
    const context = this.context();
    const data = this.data();
    return replaceVariablesInObject(data, context);
  });

  public getContextReplacedData(dataVariableName: string): any {
    const data = this.data();
    const context = this.context();
    if (!data || !(data as any)[dataVariableName] || (data as any)[dataVariableName] === "") return context;
    if (context) return getJsonByPath(context, (data as any)[dataVariableName]);
    return (data as any)[dataVariableName];
  }

  public getContextProperty<T = any>(contextProperty: string): T {
    return getJsonByPath(this.context(), contextProperty);
  }

  public getGlobalByDataKey(dataKey: string): any {
    const data = this.data();
    const globals = this.widgetTreeService.globals();
    if (!data || !globals || !dataKey) return undefined;
    return getJsonByPath(globals, getJsonByPath(data, dataKey));
  }

  /**
   * DataChange emitter, can be used to notify the outer world about data changes
   */
  @Output() public readonly dataChange: EventEmitter<any> = new EventEmitter();

  // print details about the widget when clicked with shift + click and dev mode is enabled
  @HostListener("click", ["$event"])
  public showDebug(event: MouseEvent): void {
    if (event.shiftKey && event.altKey && event.ctrlKey) {
      Logger.info(
        "Widget Debug",
        safeStringify({
          id: this.id,
          name: this.name,
          meta: this.meta,
          context: this.context(),
          data: this.data()
        })
      );
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public widgetTreeService: WidgetTreeService = inject(WidgetTreeService, { optional: true });

  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private widgetId: string;
  private element: ElementRef = inject(ElementRef);
  private renderer: Renderer2 = inject(Renderer2);

  /**
   *  @deprecated use widgetEvents instead
   *
   * Send data via a socket to other connected widgets, returns a promise which should be awaited if possible
   *
   * @param socketName The out socket of your sending widget you wish to communicate over, e.g. "text"
   * @param data The data to send via that socket. Can be empty.
   */
  protected sendSocketData(socketName: string, data?: unknown): Promise<void> {
    if (!this.widgetTreeService) throw new Error("No WidgetTreeService found on current context!");
    return this.widgetTreeService.sendSocketData(this.id, socketName, data);
  }

  ///////////// LEGACY ////////////
  /** @deprecated */
  protected readonly data$ = toObservable(this.data);
  /** @deprecated */
  protected readonly context$ = toObservable(this.context);
  /** @deprecated */
  protected subscription = new Subscription();

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export type WidgetConfigurationSchema = SchemaFormSchema;
