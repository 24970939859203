import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { LoadingStateComponent } from "../loading-state/loading-state.component";
import { RxModelsComponent } from "../rx-models/rx-models.component";

/**
 * Displays reactive data within a template.
 * Usage:
 *  ```
 *  <smallstack-rx-model type="customers" [modelId]="customerId">
 *    <ng-template let-model="model">
 *      {{ model.displayName }}
 *    </ng-template>
 *  </smallstack-rx-model>
 *  ```
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-rx-model",
  templateUrl: "./rx-model.component.html",
  standalone: true,
  imports: [CommonModule, LoadingStateComponent]
})
export class RxModelComponent extends RxModelsComponent {
  public modelId = input.required<string>();
  public model = computed(() => this.store()?.getById(this.modelId()));
}
