<ng-container [ngSwitch]="type">
  <ng-container *ngIf="file; else noFile">
    <div
      style="text-align: center; cursor: pointer"
      class="flex flex-col space-y-1 justify-center items-center"
      (click)="openFile(file)"
    >
      <div *ngSwitchCase="'picture'" class="preview-image">
        <img class="image" [src]="file.url" [alt]="altText" (error)="retry($event, file)" (load)="complete($event)" />
      </div>
      <smallstack-icon *ngSwitchCase="'directory'" size="64" name="folder-invoices" />
      <smallstack-icon *ngSwitchCase="'pdf'" size="64" name="pdf" />
      <smallstack-icon *ngSwitchCase="'doc'" size="64" name="google-docs--v2" />
      <smallstack-icon *ngSwitchDefault size="64" name="file" />
      <mat-label *ngIf="showName" class="text-muted file-label">{{ file.name }}</mat-label>
      <mat-label *ngIf="showSize" class="text-muted">{{ file.size | fileSize }}</mat-label>
    </div>
  </ng-container>
</ng-container>
<ng-template #noFile>
  <mat-label class="text-muted">Datei nicht gefunden</mat-label>
</ng-template>
