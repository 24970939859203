/* eslint-disable max-lines-per-function */
import { Injectable, Injector, effect, inject, runInInjectionContext, untracked } from "@angular/core";
import { deleteAllIndexDbData } from "@smallstack/client-common";
import { ApiConfigurationService, ContextService, UrlResolverService } from "@smallstack/common-components";
import { AxiosInterceptorService, IOC, Logger } from "@smallstack/core-common";
import { InlineTranslation } from "@smallstack/i18n-shared";
import {
  CUSTOM_TYPE_PATH_PREFIX,
  DataType,
  Model,
  MultiTenancyOptions,
  SchemaService,
  SmallstackTypeFilterOptions,
  StaticSchemas,
  TYPE_TYPES,
  TypeDescriptor,
  TypeSchema,
  convertTypePathToApiPath,
  convertTypePathToSchemaName,
  getSystemType,
  isSystemType,
  isTypeDescriptor,
  normalizeTypePath
} from "@smallstack/typesystem";
import {
  ReplaceVariablesContext,
  cloneObject,
  createRxCollectionName,
  extractTextReplacerKeys,
  getJsonByPath,
  getValueFromStringProviderSync,
  removePropertyRecursively,
  replaceVariables
} from "@smallstack/utils";
import axios, { AxiosResponse } from "axios";
import { ReplicationPullOptions, ReplicationPushOptions } from "rxdb";
import { BehaviorSubject, Observable, from } from "rxjs";
import { MutexLibrary } from "synchronized-ts";
import { OfflineCollectionModel } from "../collection/offline-collection-model";
import { OfflineCollection } from "../collection/offline.collection";
import { InMemoryStore } from "../stores/in-memory.store";
import { LoadingState } from "../stores/loading-state";
import { CrudServiceFactory } from "./crud.service";
import { RestCheckpointType } from "./rx-collection.common";
import { RxDatabaseService } from "./rx-database.service";

export interface CollectionCreationOptions<Model> {
  /** the class for instantiation, useful for testing */
  collectionClass?: any;

  /** Defaults to `id`, should be truly unique */
  primaryKey?: string;

  /** if not set, the current tenant from the contextService will be used for getting the data */
  tenantId?: string;

  /** overrides default push handler */
  pushOptions?: ReplicationPushOptions<Model>;

  /** overrides default pull handler */
  pullOptions?: ReplicationPullOptions<Model, RestCheckpointType>;
}

const collectionCreationMutexLibrary = new MutexLibrary<OfflineCollection>();
const schemaByNameMutexLibrary = new MutexLibrary<TypeSchema>();

@Injectable({ providedIn: "root" })
export class TypeService extends SchemaService {
  private crudServiceFactory = inject(CrudServiceFactory);
  #collections$ = new BehaviorSubject<{ [name: string]: OfflineCollection }>({});

  // we can't use injectRxEntityStore here, because that method needs the TypeService,
  // hence, this would result in a circular dependency
  #typesStore: { [tenantId: string]: InMemoryStore<DataType> } = {};

  public awaitLoaded(tenantId?: string): Promise<void> {
    return new Promise<void>((resolve) => {
      untracked(() => {
        effect(
          () => {
            const evaluatedTenantId = tenantId || this.contextService.context().tenantId;
            if (evaluatedTenantId) {
              const state = this.getTypeStore(evaluatedTenantId).loadingState();
              if (state === "loaded") resolve();
            }
          },
          { injector: this.injector }
        );
      });
    });
  }

  public getLoadingState(tenantId?: string): LoadingState {
    return this.getTypeStore(tenantId || this.contextService.context().tenantId).loadingState();
  }

  public isLoaded(tenantId?: string): boolean {
    return this.getLoadingState(tenantId) === "loaded";
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public offlineCollections$ = this.#collections$.asObservable();

  private rxDatabaseService = inject(RxDatabaseService);
  private apiConfigurationService = inject(ApiConfigurationService);
  private contextService = inject(ContextService);
  private injector = inject(Injector);
  private urlResolverService = inject(UrlResolverService);

  constructor() {
    super();
    IOC.register("typeService", this);
  }

  public async getUrl(typeDescriptor: TypeDescriptor, contentFrameOnly: boolean, context: any = {}): Promise<string> {
    const variant = this.getVariant(typeDescriptor);
    if (variant?.url === undefined) return undefined;
    const contextWithUrls = { ...context, ...(await this.urlResolverService.getMap()) };
    if (contentFrameOnly === true) {
      if (variant.url.includes("?")) variant.url += "&showContentFrameOnly=true";
      else variant.url += "?showContentFrameOnly=true";
    }
    return replaceVariables("/" + variant.url, contextWithUrls);
  }

  public async getSchemaByName(name: string, options?: { tenantId?: string }): Promise<TypeSchema> {
    const typePath = normalizeTypePath(name);
    const schemaName = convertTypePathToSchemaName(name);

    return schemaByNameMutexLibrary.by(schemaName as any).sync(async () => {
      // to prevent endless loops when bootstrapping application the first time
      if (typePath === TYPE_TYPES) return this.evalRefSchema(StaticSchemas.types as TypeSchema);

      // await typeStore to be loaded
      await this.awaitLoaded(options?.tenantId);

      const type = this.getByPath(typePath, options);
      if (type) {
        const schema = type?.schema;
        if (!schema)
          Logger.error("TypeService", "No schema found for type " + typePath + ", defaulting to static schemas...");
        else return this.evalRefSchema(schema);
      }

      // schema requested for inner type, or another problem... falling back to static schemas
      const staticSchema = StaticSchemas[schemaName as keyof typeof StaticSchemas];
      if (!staticSchema) throw new Error("No schema and no static schema found for type " + typePath);
      if (staticSchema) return this.evalRefSchema(staticSchema as TypeSchema);
      throw new Error("No schema found for name " + schemaName);
    });
  }

  public async getAllSchemas(): Promise<{ [name: string]: TypeSchema }> {
    const staticSchemas: any = {};
    for (const key in StaticSchemas)
      staticSchemas[normalizeTypePath(key)] = StaticSchemas[key as keyof typeof StaticSchemas];

    const types = this.getAll();
    const schemas = types?.map((type) => ({ [type.path]: type.schema })).flat();
    const combinedSchemas: any = schemas?.reduce((acc, schema) => ({ ...acc, ...schema }), {});
    if (!combinedSchemas) return staticSchemas;
    for (const key in staticSchemas) {
      if (combinedSchemas[key] === undefined) combinedSchemas[key] = staticSchemas[key];
    }
    return combinedSchemas;
  }

  /** @deprecated use an entity store or RxEntityStoreDatabase.createLegacyCollection  */
  public async getCollection<Model extends OfflineCollectionModel>(
    path: string,
    options?: CollectionCreationOptions<Model>
  ): Promise<OfflineCollection<Model>> {
    if (path === undefined) throw new Error("collection path is undefined");
    path = normalizeTypePath(path);
    const tenantId =
      options?.tenantId || this.contextService.context().tenantId || this.contextService.context().resellerId;
    const collectionName = createRxCollectionName(tenantId, path);
    // Logger.debug("TypeService", "Getting collection " + collectionName);
    return collectionCreationMutexLibrary.by(collectionName as any).sync(async () => {
      if (this.#collections$.value[collectionName]) {
        return this.#collections$.value[collectionName];
      }
      const db = await this.rxDatabaseService.getDatabase();
      if (db[collectionName] === undefined) {
        try {
          // Logger.debug("TypeService", "Getting schema by name: " + schemaName + " for collection " + collectionName);
          const schema: any = await this.getSchemaByName(path, { tenantId });
          // Logger.debug("TypeService", "  |-> Got schema by name: " + schemaName);
          if (!schema) throw new Error("Schema for " + path + " not found!");
          let schemaProps = removePropertyRecursively(schema.properties, "x-schema-form");
          schemaProps = removePropertyRecursively(schemaProps, "x-schema-type");
          schemaProps = removePropertyRecursively(schemaProps, "x-type-info");
          schemaProps = removePropertyRecursively(schemaProps, "$ref");
          await db.addCollections({
            [collectionName]: {
              schema: {
                primaryKey: options?.primaryKey || "id",
                version: 0,
                type: "object",
                properties: {
                  ...schemaProps,
                  id: { type: "string", maxLength: 100 }
                },
                required: schema?.required
              }
            }
          });
        } catch (e) {
          if (e.message?.includes("DB6") || e.message?.includes("DM5")) {
            await db.remove();
            await deleteAllIndexDbData();
            window.location.reload();
          }
          throw e;
        }
      }
      let apiPath = path;
      if (path.startsWith(CUSTOM_TYPE_PATH_PREFIX)) apiPath = "data/" + path;
      else {
        apiPath = convertTypePathToApiPath(apiPath);
      }

      const collectionClass: typeof OfflineCollection = options?.collectionClass
        ? options.collectionClass
        : OfflineCollection;

      let offlineCollection: OfflineCollection;
      await runInInjectionContext(this.injector, async () => {
        offlineCollection = await collectionClass.create<Model>({
          apiConfiguration: this.apiConfigurationService.configuration$.value,
          apiPath,
          rxCollection: db[collectionName],
          tenantId,
          pullOptions: options?.pullOptions,
          pushOptions: options?.pushOptions,
          crud: this.crudServiceFactory.getCrudService({ typePath: path }) as any
        });
      });

      const currentCollections = this.#collections$.value;
      currentCollections[collectionName] = offlineCollection;
      this.#collections$.next(currentCollections);
      return offlineCollection;
    });
  }

  /** @deprecated use an entity store or RxEntityStoreDatabase.createLegacyCollection  */
  public getCollection$<T = any>(
    path: string,
    options?: CollectionCreationOptions<T>
  ): Observable<OfflineCollection<T>> {
    return from(this.getCollection(path, options)) as unknown as Observable<OfflineCollection<T>>;
  }

  /** @deprecated use getByPath inside a reactive context */
  public async getTypeByPath(path: string): Promise<DataType> {
    return this.getByPath(path);
  }

  /** @deprecated use getRepresentation */
  public override toString(type: DataType, model: { id: string; [key: string]: unknown }): string | InlineTranslation {
    return this.getRepresentation(type, model);
  }

  public getRepresentation(type: DataType | TypeDescriptor, model: Model): InlineTranslation {
    if (!type) throw new Error("No type given!");
    if (!model) return undefined;
    if (isTypeDescriptor(type)) type = this.getVariant(type);
    if (type.representation === undefined) return [{ value: model.id }];
    if (!type.representation.includes(" ")) {
      const extractedKeys = extractTextReplacerKeys(type.representation);
      if (extractedKeys?.length > 0) return getJsonByPath(model, extractedKeys[0]);
      return [{ value: type.representation }];
    }
    return [{ value: replaceVariables(type.representation, model as ReplaceVariablesContext, true) }];
  }

  public getLongRepresentation(type: DataType | TypeDescriptor, model: Model): string | InlineTranslation {
    if (!type) throw new Error("No type given!");
    if (!model) return undefined;
    if (isTypeDescriptor(type)) type = this.getVariant(type);
    if (type.longRepresentation === undefined) return model.id;
    if (!type.longRepresentation.includes(" ")) {
      const extractedKeys = extractTextReplacerKeys(type.longRepresentation);
      if (extractedKeys?.length > 0) return getJsonByPath(model, extractedKeys[0]);
      return type.longRepresentation;
    }
    return replaceVariables(type.longRepresentation, model as ReplaceVariablesContext, true);
  }

  public getVariant(typeDescriptor: TypeDescriptor): DataType {
    if (!typeDescriptor.typeVariantName) return this.getByPath(typeDescriptor.typePath);

    const typeByPath = this.getByPath(typeDescriptor.typePath);
    if (!typeByPath) return undefined;

    const variant = typeByPath.variants?.find((variant) => variant.name === typeDescriptor.typeVariantName);
    if (!variant) return undefined;
    const combinedType = cloneObject(typeByPath);
    if (variant.schema) combinedType.schema = variant.schema;
    if (variant.title) combinedType.title = variant.title;
    if (variant.url) combinedType.url = variant.url;
    if (variant.storage) combinedType.storage = variant.storage;
    return combinedType;
  }

  /** This function should be called in a reactive context */
  public getByPath(typePath: string, options?: MultiTenancyOptions): DataType {
    typePath = normalizeTypePath(typePath);

    if (isSystemType(typePath)) return getSystemType(typePath);

    // check store state
    const tenantId = options?.tenantId || this.contextService.context().tenantId;
    const typeStore = this.getTypeStore(tenantId);
    if (typeStore?.loadingState() === "loaded") {
      Logger.debug("TypeService", "Getting type by path " + typePath + " in tenant " + tenantId);
      const typeByPath = typeStore.getOneByProperty("path", typePath);
      if (!typeByPath) throw Error("Type not found for path " + typePath + " in tenant " + tenantId);
      Logger.debug("TypeService", "Returning: ", typeByPath);
      return typeByPath;
    } else
      Logger.warning(
        "TypeService",
        "TypeStore(tenantId:" + tenantId + ") not loaded yet, returning undefined for type " + typePath
      );
  }

  public getAll(): DataType[] {
    return this.getTypeStore(this.contextService.context().tenantId)?.getMany();
  }

  public filterTypes(options?: SmallstackTypeFilterOptions): DataType[] {
    const allTypes = this.getAll();
    return allTypes.filter((type) => {
      if (
        options?.searchable === true &&
        (!(type.searchableFields instanceof Array) || type.representation === undefined)
      )
        return false;
      // TODO: implement tags
      // if (options?.tags instanceof Array && options.tags.length > 0) {
      //   let foundOneTag = false;
      //   for (const tag of options.tags)
      //     if (type.tags?.includes(tag)) {
      //       foundOneTag = true;
      //       break;
      //     }
      //   if (!foundOneTag) return false;
      // }

      // TODO: implement linkableTypes
      // if (options?.linkableTypes instanceof Array && options.linkableTypes.length > 0) {
      //   let foundOneLinkableType = false;
      //   for (const linkableType of options.linkableTypes)
      //     if (type.linkableTypes?.includes(linkableType)) {
      //       foundOneLinkableType = true;
      //       break;
      //     }
      //   if (!foundOneLinkableType) return false;
      // }
      return true;
    });
  }

  private createAxiosHeaders(): any {
    const resellerId = getValueFromStringProviderSync(this.contextService.context().resellerId);
    const tenantId = getValueFromStringProviderSync(this.contextService.context().tenantId);
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-tenant-id": tenantId ? tenantId : resellerId,
      "x-reseller-id": resellerId,
      "x-auth-tenant-id": getValueFromStringProviderSync(this.contextService.context().authTenantId),
      Authorization: "Bearer " + getValueFromStringProviderSync(this.contextService.context().token)
    };
  }

  private async reloadTypesStore(typeStore: InMemoryStore<DataType>, tenantId: string): Promise<void> {
    Logger.debug("TypeService", "========> Reloading types store for tenant " + tenantId);
    untracked(() => {
      typeStore.setLoadingState("loading");
    });
    const resellerId = getValueFromStringProviderSync(this.contextService.context().resellerId);

    const types: DataType[] = [];

    // add static types
    for (const key in StaticSchemas) {
      const staticType = StaticSchemas[key as keyof typeof StaticSchemas];
      types.push({
        path: key,
        schema: staticType,
        title: [{ value: key }],
        id: key
      });
    }

    try {
      const typesResponse: AxiosResponse = await axios({
        method: "GET",
        url:
          getValueFromStringProviderSync(this.apiConfigurationService.configuration$.value?.apiUrl) +
          "/types?size=1000",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-tenant-id": tenantId ? tenantId : resellerId,
          "x-reseller-id": resellerId
        },
        signal: AxiosInterceptorService.getAbortSignal()
      });

      if (typesResponse.status !== 200) {
        Logger.error("TypeService", "Error loading types: " + typesResponse.status);
        // typeStore.setLoadingState("error");
        // return;
      } else if (typesResponse.data?.elements instanceof Array) {
        for (const type of typesResponse.data.elements) {
          // check if type with same path already exists. if so, replace it
          const existingType = types.find((t) => t.path === type.path);
          if (existingType) {
            const index = types.indexOf(existingType);
            types[index] = type;
          } else {
            types.push(type);
          }
        }
      }
    } catch (e) {
      Logger.error("TypeService", "Error loading types: " + e);
    }
    typeStore.setEntities(types);
    Logger.debug("TypeService", "========> Types LOADED for tenant " + tenantId);
  }

  private getTypeStore(tenantId: string): InMemoryStore<DataType> {
    if (!this.#typesStore[tenantId]) {
      this.#typesStore[tenantId] = new InMemoryStore<DataType>(this.injector);
      void this.reloadTypesStore(this.#typesStore[tenantId], tenantId);
    }
    return this.#typesStore[tenantId];
  }
}
