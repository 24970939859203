import { CommonModule, NgOptimizedImage } from "@angular/common";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  signal
} from "@angular/core";
import { ThemeDto } from "@smallstack/axios-api-client";
import { convertRgbStringToRgb, flattenJson, isNonEmptyString } from "@smallstack/utils";
import convert from "color-convert";
import { Subscription } from "rxjs";
import { IconThemeService } from "../../services/icon-theme.service";
import { ThemeService } from "../../services/theme.service";

/**
 * This component renders icons from icon8. Please make sure that the usage does not validate our license. How-to find your next icon:
 *  - goto <a href="https://icons8.de">icons8.de</a>
 *  - search for your icon
 *  - make sure it has great theme support (at least ios and ios-filled should be available)
 *  - click on the icon and goto "embed html"
 *  - copy the name of the icon (filename without extension). Example: `<img src="https://img.icons8.com/color/48/000000/edit-property.png"/>` would be "edit-property"
 *
 * @example
 * <smallstack-icon name="edit-property">
 * <smallstack-icon name="edit-property" theme="fluent">
 * <smallstack-icon name="edit-property" theme="dotty" size="128">
 *
 * @param {string} [theme=ios] - If you want to set or a theme explicitly, you can use the `theme` input of the component. Otherwise, these will be filled by the IconThemeService: `<smallstack-icon name="edit-property" theme="dotty">`
 * @param {number} [size=48] - If you want to set a size (in px) explicitly, you can use the `size` inputs of the component. Otherwise, these will be filled by the IconThemeService: `<smallstack-icon name="edit-property" size="128">`
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnDestroy, OnChanges, AfterContentInit {
  protected iconName = signal(undefined);

  public realSize: string;

  @ViewChild("iconDiv")
  protected iconDiv: ElementRef;

  /**
   * icons8 theme: dotty, ios, material, color, fluent etc, see icons8.de
   */
  @Input()
  public theme: string;

  protected showOnlineIcon: boolean = false;

  protected isFontAwesomeIcon = signal(false);

  /**
   * If you want to set a size (in px) explicitly, you can use the `size` inputs of the component.
   * You can also use daisy sizes like "md" or "xs"
   * Otherwise, these will be filled by the IconThemeService: `<smallstack-icon name="edit-property" size="128">`
   */
  @Input()
  public set size(size: string) {
    // daisy sizes
    switch (size) {
      case "xs":
        size = "12";
        break;
      case "sm":
        size = "20";
        break;
      case "md":
        size = "24";
        break;
      case "lg":
        size = "36";
        break;
    }
    this.iconSize = size;
    this.realSize = size;
  }

  public get size(): string {
    return "" + Math.floor(parseInt(this.iconSize) * this.devicePixelRatio);
  }
  private iconSize: string = "24";

  public get badgeFontSize(): number {
    const calculatedSize = Math.floor(parseInt(this.iconSize) / 4);
    if (!calculatedSize || calculatedSize < 10) return 10;
    return calculatedSize;
  }

  @HostBinding("style.height.px")
  public get hostHeight(): number {
    return Math.floor(parseInt(this.iconSize));
  }

  @HostBinding("style.width.px")
  public get hostWidth(): number {
    return Math.floor(parseInt(this.iconSize));
  }

  /**
   * name of icon
   */
  @Input()
  public set name(name: string) {
    if (name?.startsWith("fa-")) {
      if (!name.includes("fa-kit")) name = "fa-regular " + name;
      this.isFontAwesomeIcon.set(true);
      if (this.iconName()) {
        // there was an icon before, and the name now changed. Since fontawesome replaces the <i> tags with svgs, we need to change the svg instead
        const elem = this.elementRef.nativeElement.querySelector(".svg-font-awesome");
        if (elem) {
          const nameSplit = name.split(" ");
          elem.setAttribute("data-icon", nameSplit[nameSplit.length - 1].replace("fa-", ""));
        }
      }
    } else this.isFontAwesomeIcon.set(false);
    this.iconName.set(name);
  }

  /**
   * use predefined alias like "project" or "company". The IconThemeService will be asked to convert aliases. If not found, the alias will be used as name
   */
  @Input()
  public set alias(alias: string) {
    this.name = this.iconThemeService.getAlias(alias);
  }

  /**
   * Action Icon, will be displayed at the bottom right, with 33% of the icon size.
   */
  @Input()
  public action: string;

  /** Relative size to the big one, defaults to 0.3 */
  @Input()
  public actionSize: number = 0.3;

  /** Shall the icon spin ? */
  @Input()
  public spinning: boolean = false;

  /** Shall the action item spin ? */
  @Input()
  public actionSpinning: boolean = false;

  /**
   * @param [color=000000] - defaults to black (000000), can be any hex color (no alpha channel) or the string "primary" which will be the primary color of the current material theme, see IconThemeService
   */
  @Input()
  public set color(color: string) {
    if (color === "primary") {
      this.themeColor = "daisyTheme.neutral.textColor";
      return;
    }
    if (color === "warn") {
      this.themeColor = "daisyTheme.warning.textColor";
      return;
    }
    if (color) {
      this.convertedColor = color; //?.replace("#", "").toLowerCase();
      if (
        this.convertedColor.charAt(0) !== "#" &&
        !this.convertedColor.startsWith("hsl") &&
        !this.convertedColor.startsWith("var")
      )
        this.convertedColor = "#" + this.convertedColor;
    }
  }

  public get color(): string {
    if (isNonEmptyString(this.convertedColor)) return this.convertedColor;
  }

  private convertedColor: string;

  /**
   * Set the color to one from a smallstack Theme, e.g. "toolbar.colors.text"
   */
  @Input()
  public themeColor: string;

  /**
   * If the action icon shall have another color than the icon
   */
  @Input()
  public actionColor: string;

  /**
   * If set, a badge will be displayed at the top right
   */
  @Input()
  public badge: number;

  public get calculatedBadge(): string {
    if (typeof this.badge !== "number") this.badge = parseInt(this.badge);
    if (this.badge === undefined) return undefined;
    if (this.badge < 1000) return "" + this.badge;
    return "999+";
  }

  /** set to true if the icon is most likely not available in the assets folder */
  @Input()
  public set dynamic(dynamic: boolean) {
    this.showOnlineIcon = true;
  }

  /** Sets the color of the badge */
  @Input()
  public badgeColor: "primary" | "secondary" | "error" | "warning" | "success" | "info" | "accent" = "error";

  @Output()
  public readonly iconError = new EventEmitter<boolean>();

  private devicePixelRatio = typeof window !== "undefined" ? window?.devicePixelRatio : 1;

  public hasError: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private iconThemeService: IconThemeService,
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  public ngAfterContentInit(): void {
    try {
      if (this.color === undefined) {
        const hexColor = convert.rgb.hex(
          convertRgbStringToRgb(window.getComputedStyle(this.elementRef.nativeElement).color)
        );
        if (hexColor) {
          // hexColor = hexColor.substring(1);
          if (hexColor !== this.color) {
            this.color = hexColor;
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    // set actionColor if not set manually
    if (this.actionColor === undefined) this.actionColor = this.color;

    // set named colors
    this.color = this.iconThemeService.getColor(this.color);
    this.actionColor = this.iconThemeService.getColor(this.actionColor);

    if (!this.theme)
      this.subscription.add(
        this.iconThemeService.theme$.subscribe((theme) => {
          this.theme = theme;
          this.cdr.markForCheck();
        })
      );
    if (!this.size) this.subscription.add(this.iconThemeService.size$.subscribe((size) => (this.size = "" + size)));
    if (this.themeColor) {
      this.subscription.add(
        this.themeService.$currentTheme.subscribe((theme) => {
          this.applyTheme(theme);
        })
      );
    }

    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.name) {
      this.hasError = false;
      this.iconError.emit(this.hasError);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onError(event: any): void {
    this.showOnlineIcon = true;
    // if (this.hasError === false) {
    //   if (!this.size) this.size = "48";
    //   event.target.src = `${this.baseUrl}/fluency-systems-regular/${this.size}/${this.color}/${this.name}.png`;
    // } else {
    //   event.target.src = `${this.baseUrl}/fluency-systems-regular/${this.size}/${this.color}/error.png`;
    // }
    // this.hasError = true;
    // this.iconError.emit(this.hasError);
    // captureMessage(
    //   "Icon not available! name: " + this.name + ", theme: " + this.theme + ", color: " + this.color,
    //   "warning"
    // );
  }

  private applyTheme(theme: ThemeDto) {
    if (theme) {
      const flatTheme = flattenJson(theme);
      const newColor = flatTheme[this.themeColor]?.replace("#", "");
      if (this.color !== newColor) {
        this.color = newColor;
        this.cdr.markForCheck();
      }
    }
  }
}
