<div class="beta-border-container">
  <div class="beta-border">
    <div
      class="beta top"
      matTooltip="Diese Komponente befindet sich noch in der Entwicklung. Einige Funktionen sind möglicherweise fehlerhaft oder noch nicht fertig entwickelt."
      >PREVIEW</div
    >
    <!-- <div class="beta bottom" matTooltip="Diese Komponente befindet sich noch in der Entwicklung.">PREVIEW</div>
    <div class="beta left" matTooltip="Diese Komponente befindet sich noch in der Entwicklung.">PREVIEW</div>
    <div class="beta right" matTooltip="Diese Komponente befindet sich noch in der Entwicklung.">PREVIEW</div> -->
    <ng-content></ng-content>
  </div>
</div>
