import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FileDto } from "@smallstack/axios-api-client";
import { FileStore } from "../../stores/file.store";
import { FileListComponent } from "../file-list/file-list.component";
import { FileUploadButtonComponent } from "../file-upload/file-upload-button.component";

export interface FileExplorerDialogData {
  fileStore?: FileStore;
}
export type FileExplorerDialogResult = FileDto;

@Component({
  selector: "smallstack-file-explorer-dialog",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./file-explorer-dialog.component.html",
  standalone: true,
  imports: [FileUploadButtonComponent, FileListComponent]
})
export class FileExplorerDialogComponent {
  constructor(
    private ref: MatDialogRef<FileExplorerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileExplorerDialogData,
    @Optional() projectFileStore: FileStore
  ) {
    if (!data.fileStore) data.fileStore = projectFileStore;
  }

  public selected(file: FileDto): void {
    this.ref.close(file);
  }
}
