import { AsyncPipe, JsonPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Output,
  computed,
  inject,
  input,
  signal
} from "@angular/core";
import { debouncedSignal } from "@smallstack/client-common";
import { CustomizationModeStore, HighlightDirective } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { I18nComponent } from "@smallstack/i18n-components";
import { DuplicateDetection, SearchByFieldMatcher } from "@smallstack/typesystem";
import { RxEntityStore } from "@smallstack/typesystem-client";
import { isEmptyString } from "@smallstack/utils";
import { ExtensionSlotComponent, FormService, TypeDialogService } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-duplicates-search, smallstack-form-duplicates-search",
  templateUrl: "./duplicates-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [I18nComponent, ExtensionSlotComponent, HighlightDirective, AsyncPipe, JsonPipe]
})
export class DuplicatesSearchComponent {
  private injector = inject(Injector);

  public path = input<string>(undefined);

  protected schema = computed(() => {
    const path = this.path();
    if (!path) return undefined;
    return this.formService.getSchemaForPath(path);
  });

  protected hide = signal(false);

  protected duplicateDetection = computed(() => {
    const schema = this.schema();
    return schema?.["x-schema-form"]?.duplicateDetection;
  });

  protected extensionSlotName = computed(() => {
    const duplicateDetection = this.duplicateDetection();
    return duplicateDetection?.extensionSlotName;
  });

  private store = computed(() => {
    const duplicateDetection = this.duplicateDetection();
    if (!duplicateDetection) return undefined;
    if (duplicateDetection?.store) {
      Logger.warning(
        "DuplicatesSearchComponent",
        "Deprecation notice: Use type instead of store property in duplicate detection schema!"
      );
      return new RxEntityStore(this.injector, { typeDescriptor: { typePath: duplicateDetection.store } });
    } else if (!duplicateDetection.type) return undefined;
    else return new RxEntityStore(this.injector, { typeDescriptor: { typePath: duplicateDetection.type } });
  });

  private comparator = computed(() => {
    const duplicateDetection = this.duplicateDetection();
    return duplicateDetection?.comparator || SearchByFieldMatcher.INCLUDES;
  });

  private value = debouncedSignal(
    computed(() => {
      const path = this.path();
      return this.formService.getValueByPath(path);
    }),
    500
  );

  protected duplicates = computed(() => {
    const store = this.store();
    const comparator = this.comparator();
    const path = this.path();
    const value = this.value();
    if (document.activeElement?.tagName !== "INPUT") return undefined;
    if (!store || !path || !value || isEmptyString(value)) return undefined;

    Logger.info("DuplicatesSearchComponent", "Searching store for value:", value);

    if (typeof value === "object") {
      const selector: any = {};
      for (const key in value) {
        if (value[key] !== undefined && value[key] !== null && value[key] !== "") {
          if (comparator !== SearchByFieldMatcher.INCLUDES)
            Logger.warning(
              "DuplicatesSearchComponent",
              "Only includes comparator supported for object values! If you need another one, please create a support issue!"
            );
          selector[path + "." + key] = { $regex: value[key], $options: "im" };
        }
      }
      return store.query({ selector, limit: 10 });
    }
  });

  protected currentSearchValues = computed(() => {
    const value = this.value();
    if (typeof value === "object") return Object.values(value).filter((v) => v !== undefined && v !== null && v !== "");
    return value;
  });

  @Output()
  public readonly valueChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private typeDialogService: TypeDialogService,
    public customizationModeStore: CustomizationModeStore,
    public formService: FormService
  ) {}

  public fillIn(duplicate: any): void {
    this.valueChange.emit((duplicate as any)[this.path()]);
  }

  public openLink(duplicateDetection: DuplicateDetection, duplicate: any): Promise<void> {
    return this.typeDialogService.openDetail(duplicateDetection.type || duplicateDetection.store, { model: duplicate });
  }

  public close(): void {
    this.hide.set(true);
  }
}
