// TODO: Remove after switch from webpack to esbuild, use "bson" afterwards. "bson" from MongoDB cannot be used right now since it uses top level awaits and webpack does not support it (ootb)
export function createObjectId(): string {
  let increment = Math.floor(Math.random() * 16777216);
  const pid = Math.floor(Math.random() * 65536);
  let machine = Math.floor(Math.random() * 16777216);

  const setMachineCookie = function () {
    const cookieList = document.cookie.split("; ");
    for (const i in cookieList) {
      const cookie = cookieList[i].split("=");
      const cookieMachineId = parseInt(cookie[1], 10);
      if (cookie[0] == "mongoMachineId" && cookieMachineId && cookieMachineId >= 0 && cookieMachineId <= 16777215) {
        machine = cookieMachineId;
        break;
      }
    }
    document.cookie = "mongoMachineId=" + machine + ";expires=Tue, 19 Jan 2038 05:00:00 GMT;path=/";
  };

  if (typeof localStorage !== "undefined") {
    try {
      const mongoMachineId = parseInt(localStorage["mongoMachineId"]);
      if (mongoMachineId >= 0 && mongoMachineId <= 16777215) {
        machine = Math.floor(localStorage["mongoMachineId"]);
      }
      // Just always stick the value in.
      localStorage["mongoMachineId"] = machine;
    } catch (e) {
      setMachineCookie();
    }
  } else {
    setMachineCookie();
  }

  const timestamp = Math.floor(new Date().valueOf() / 1000);
  increment++;
  if (increment > 0xffffff) {
    increment = 0;
  }

  const timestampStr = timestamp.toString(16);
  const machineStr = machine.toString(16);
  const pidStr = pid.toString(16);
  const incrementStr = increment.toString(16);
  return (
    "00000000".substr(0, 8 - timestampStr.length) +
    timestampStr +
    "000000".substr(0, 6 - machineStr.length) +
    machineStr +
    "0000".substr(0, 4 - pidStr.length) +
    pidStr +
    "000000".substr(0, 6 - incrementStr.length) +
    incrementStr
  );
}
