import { TypeSchema } from "../typesystem/type-schema";

/** describes a type with a variant */
export interface TypeDescriptor {
  /** the path/name of the type */
  typePath: string;
  /** the name of the variant, or empty if base variant should be used */
  typeVariantName?: string;
}

export const TypeDescriptorSchema: TypeSchema = {
  type: "object",
  properties: {
    typePath: {
      title: "Typ Name/Pfad",
      type: "string"
    },
    typeVariantName: {
      title: "Variante",
      type: "string"
    }
  },
  required: ["typePath"]
};

export function isTypeDescriptor(obj: any): obj is TypeDescriptor {
  return obj && typeof obj.typePath === "string";
}
