/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosInstance, AxiosPromise } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequestArgs, RequiredError } from '../base';
// @ts-ignore
import { AzureLoginRequestDto } from '../models';
// @ts-ignore
import { AzureMergeRequestDto } from '../models';
// @ts-ignore
import { CreateUserDto } from '../models';
// @ts-ignore
import { CurrentUserDto } from '../models';
// @ts-ignore
import { EmailPasswordLoginDto } from '../models';
// @ts-ignore
import { EmailVerificationDtoDto } from '../models';
// @ts-ignore
import { FriendDto } from '../models';
// @ts-ignore
import { InvitationsPageDto } from '../models';
// @ts-ignore
import { LoginUserDtoDto } from '../models';
// @ts-ignore
import { MyPermissionDto } from '../models';
// @ts-ignore
import { NewPasswordDtoDto } from '../models';
// @ts-ignore
import { PatchUserDtoDto } from '../models';
// @ts-ignore
import { RegisterUserDto } from '../models';
// @ts-ignore
import { ResetPasswordBodyDto } from '../models';
// @ts-ignore
import { ResetPasswordWithTokenBodyDto } from '../models';
// @ts-ignore
import { ResetPasswordWithTokenResponseDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
// @ts-ignore
import { SetPasswordBodyDto } from '../models';
// @ts-ignore
import { UserDto } from '../models';
// @ts-ignore
import { UserEmailDto } from '../models';
// @ts-ignore
import { UserGroupDto } from '../models';
// @ts-ignore
import { UserGroupPageDto } from '../models';
// @ts-ignore
import { UserGroupSearchResultPageDto } from '../models';
// @ts-ignore
import { UserJwtDto } from '../models';
// @ts-ignore
import { UserPageDto } from '../models';
// @ts-ignore
import { UserSearchResultPageDto } from '../models';
// @ts-ignore
import { UsernamePasswordLoginDto } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * Set the user property \'active\' to true. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activateUser.');
            }
            const localVarPath = `/users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate user with email and password
         * @param {EmailPasswordLoginDto} emailPasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithEmailAndPassword: async (emailPasswordLogin: EmailPasswordLoginDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailPasswordLogin' is not null or undefined
            if (emailPasswordLogin === null || emailPasswordLogin === undefined) {
                throw new RequiredError('emailPasswordLogin','Required parameter emailPasswordLogin was null or undefined when calling authenticateUserWithEmailAndPassword.');
            }
            const localVarPath = `/me/auth/email-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof emailPasswordLogin !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(emailPasswordLogin !== undefined ? emailPasswordLogin : {})
                : (emailPasswordLogin || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deprecated: Please use auth/email-password
         * @param {LoginUserDtoDto} loginUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithPassword: async (loginUserDto: LoginUserDtoDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginUserDto' is not null or undefined
            if (loginUserDto === null || loginUserDto === undefined) {
                throw new RequiredError('loginUserDto','Required parameter loginUserDto was null or undefined when calling authenticateUserWithPassword.');
            }
            const localVarPath = `/me/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginUserDto !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginUserDto !== undefined ? loginUserDto : {})
                : (loginUserDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate user with username and password
         * @param {UsernamePasswordLoginDto} usernamePasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithUsernameAndPassword: async (usernamePasswordLogin: UsernamePasswordLoginDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usernamePasswordLogin' is not null or undefined
            if (usernamePasswordLogin === null || usernamePasswordLogin === undefined) {
                throw new RequiredError('usernamePasswordLogin','Required parameter usernamePasswordLogin was null or undefined when calling authenticateUserWithUsernameAndPassword.');
            }
            const localVarPath = `/me/auth/username-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof usernamePasswordLogin !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(usernamePasswordLogin !== undefined ? usernamePasswordLogin : {})
                : (usernamePasswordLogin || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update users password
         * @param {NewPasswordDtoDto} newPasswordDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (newPasswordDto: NewPasswordDtoDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPasswordDto' is not null or undefined
            if (newPasswordDto === null || newPasswordDto === undefined) {
                throw new RequiredError('newPasswordDto','Required parameter newPasswordDto was null or undefined when calling changePassword.');
            }
            const localVarPath = `/me/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newPasswordDto !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newPasswordDto !== undefined ? newPasswordDto : {})
                : (newPasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify email confirmation token.
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (token: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling confirmEmail.');
            }
            const localVarPath = `/verify/email/confirm/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates (and returns) a new access token for the currently logged in user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/auth/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserDto} createUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUser: CreateUserDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUser' is not null or undefined
            if (createUser === null || createUser === undefined) {
                throw new RequiredError('createUser','Required parameter createUser was null or undefined when calling createUser.');
            }
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createUser !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createUser !== undefined ? createUser : {})
                : (createUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroup: async (userGroup: UserGroupDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            if (userGroup === null || userGroup === undefined) {
                throw new RequiredError('userGroup','Required parameter userGroup was null or undefined when calling createUserGroup.');
            }
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userGroup !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userGroup !== undefined ? userGroup : {})
                : (userGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the user property \'active\' to false and remove the users access tokens. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivateUser.');
            }
            const localVarPath = `/users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an access token
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessToken: async (token: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteAccessToken.');
            }
            const localVarPath = `/me/auth/tokens/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUserGroups: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyUserGroups.');
            }
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsers: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyUsers.');
            }
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserGroup.');
            }
            const localVarPath = `/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all access tokens for the currently logged in user (not service or login tokens)
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokens: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/auth/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all usernames
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsernames: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/all-usernames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyFriends: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/friends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInvitation: async (inviteId: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling getMyInvitation.');
            }
            const localVarPath = `/me/invitations/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all information that you\'ve received
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInvitations: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPermissions: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUser: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGroup.');
            }
            const localVarPath = `/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs in with a Microsoft Login Token
         * @param {AzureLoginRequestDto} azureLoginRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithMicrosoftToken: async (azureLoginRequest: AzureLoginRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'azureLoginRequest' is not null or undefined
            if (azureLoginRequest === null || azureLoginRequest === undefined) {
                throw new RequiredError('azureLoginRequest','Required parameter azureLoginRequest was null or undefined when calling loginWithMicrosoftToken.');
            }
            const localVarPath = `/me/auth/azure-ad/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof azureLoginRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(azureLoginRequest !== undefined ? azureLoginRequest : {})
                : (azureLoginRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Afterwards, the current logged in user will (also) be able to login with the given MS AzureAD account.
         * @summary Merges an already authenticated account with Microsoft Azure AD login
         * @param {AzureMergeRequestDto} azureMergeRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeAccountWithMicrosoft: async (azureMergeRequest: AzureMergeRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'azureMergeRequest' is not null or undefined
            if (azureMergeRequest === null || azureMergeRequest === undefined) {
                throw new RequiredError('azureMergeRequest','Required parameter azureMergeRequest was null or undefined when calling mergeAccountWithMicrosoft.');
            }
            const localVarPath = `/me/auth/azure-ad/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof azureMergeRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(azureMergeRequest !== undefined ? azureMergeRequest : {})
                : (azureMergeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates your own user profile by replacing specific properties. Does not work for arrays.
         * @param {PatchUserDtoDto} patchUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMyUser: async (patchUserDto: PatchUserDtoDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserDto' is not null or undefined
            if (patchUserDto === null || patchUserDto === undefined) {
                throw new RequiredError('patchUserDto','Required parameter patchUserDto was null or undefined when calling patchMyUser.');
            }
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof patchUserDto !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(patchUserDto !== undefined ? patchUserDto : {})
                : (patchUserDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (id: string, user: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchUser.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling patchUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserGroup: async (id: string, userGroup: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchUserGroup.');
            }
            // verify required parameter 'userGroup' is not null or undefined
            if (userGroup === null || userGroup === undefined) {
                throw new RequiredError('userGroup','Required parameter userGroup was null or undefined when calling patchUserGroup.');
            }
            const localVarPath = `/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userGroup !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userGroup !== undefined ? userGroup : {})
                : (userGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performUserGroupSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performUserGroupSearch.');
            }
            const localVarPath = `/user-groups/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performUserSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performUserSearch.');
            }
            const localVarPath = `/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (id: string, user: UserDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putUser.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling putUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserGroup: async (id: string, userGroup: UserGroupDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putUserGroup.');
            }
            // verify required parameter 'userGroup' is not null or undefined
            if (userGroup === null || userGroup === undefined) {
                throw new RequiredError('userGroup','Required parameter userGroup was null or undefined when calling putUserGroup.');
            }
            const localVarPath = `/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userGroup !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userGroup !== undefined ? userGroup : {})
                : (userGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a new user
         * @param {RegisterUserDto} registerUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (registerUser: RegisterUserDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUser' is not null or undefined
            if (registerUser === null || registerUser === undefined) {
                throw new RequiredError('registerUser','Required parameter registerUser was null or undefined when calling registerUser.');
            }
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof registerUser !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(registerUser !== undefined ? registerUser : {})
                : (registerUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the personal avatar image
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAvatar: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes all connected Microsoft Azure AD logins from the account
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMicrosoftLogin: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/auth/azure-ad/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a user an email verification link
         * @param {string} userId 
         * @param {EmailVerificationDtoDto} emailVerificationDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerification: async (userId: string, emailVerificationDto: EmailVerificationDtoDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling sendEmailVerification.');
            }
            // verify required parameter 'emailVerificationDto' is not null or undefined
            if (emailVerificationDto === null || emailVerificationDto === undefined) {
                throw new RequiredError('emailVerificationDto','Required parameter emailVerificationDto was null or undefined when calling sendEmailVerification.');
            }
            const localVarPath = `/verify/{userId}/email/send`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof emailVerificationDto !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(emailVerificationDto !== undefined ? emailVerificationDto : {})
                : (emailVerificationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMail: async (resetPasswordBody: ResetPasswordBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordBody' is not null or undefined
            if (resetPasswordBody === null || resetPasswordBody === undefined) {
                throw new RequiredError('resetPasswordBody','Required parameter resetPasswordBody was null or undefined when calling sendResetPasswordMail.');
            }
            const localVarPath = `/me/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof resetPasswordBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(resetPasswordBody !== undefined ? resetPasswordBody : {})
                : (resetPasswordBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMailForUser: async (resetPasswordBody: ResetPasswordBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordBody' is not null or undefined
            if (resetPasswordBody === null || resetPasswordBody === undefined) {
                throw new RequiredError('resetPasswordBody','Required parameter resetPasswordBody was null or undefined when calling sendResetPasswordMailForUser.');
            }
            const localVarPath = `/users/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof resetPasswordBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(resetPasswordBody !== undefined ? resetPasswordBody : {})
                : (resetPasswordBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the password for the given user
         * @param {SetPasswordBodyDto} setPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordForUser: async (setPasswordBody: SetPasswordBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordBody' is not null or undefined
            if (setPasswordBody === null || setPasswordBody === undefined) {
                throw new RequiredError('setPasswordBody','Required parameter setPasswordBody was null or undefined when calling setPasswordForUser.');
            }
            const localVarPath = `/users/auth/password/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setPasswordBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setPasswordBody !== undefined ? setPasswordBody : {})
                : (setPasswordBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a password with a reset password token and returns a login token
         * @param {ResetPasswordWithTokenBodyDto} resetPasswordWithTokenBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordWithResetToken: async (resetPasswordWithTokenBody: ResetPasswordWithTokenBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordWithTokenBody' is not null or undefined
            if (resetPasswordWithTokenBody === null || resetPasswordWithTokenBody === undefined) {
                throw new RequiredError('resetPasswordWithTokenBody','Required parameter resetPasswordWithTokenBody was null or undefined when calling setPasswordWithResetToken.');
            }
            const localVarPath = `/me/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof resetPasswordWithTokenBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(resetPasswordWithTokenBody !== undefined ? resetPasswordWithTokenBody : {})
                : (resetPasswordWithTokenBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates your own user profile by replacing the entire profile.
         * @param {any} profile The own users profile
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile: async (profile: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            if (profile === null || profile === undefined) {
                throw new RequiredError('profile','Required parameter profile was null or undefined when calling updateMyProfile.');
            }
            const localVarPath = `/me/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof profile !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(profile !== undefined ? profile : {})
                : (profile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a signed url where users can upload their avatar image to
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a User exists.
         * @summary Check if User exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userExists.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a UserGroup exists.
         * @summary Check if UserGroup exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userGroupExists.');
            }
            const localVarPath = `/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * Set the user property \'active\' to true. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).activateUser(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticate user with email and password
         * @param {EmailPasswordLoginDto} emailPasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserWithEmailAndPassword(emailPasswordLogin: EmailPasswordLoginDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).authenticateUserWithEmailAndPassword(emailPasswordLogin, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deprecated: Please use auth/email-password
         * @param {LoginUserDtoDto} loginUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserWithPassword(loginUserDto: LoginUserDtoDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).authenticateUserWithPassword(loginUserDto, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticate user with username and password
         * @param {UsernamePasswordLoginDto} usernamePasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserWithUsernameAndPassword(usernamePasswordLogin: UsernamePasswordLoginDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).authenticateUserWithUsernameAndPassword(usernamePasswordLogin, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update users password
         * @param {NewPasswordDtoDto} newPasswordDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(newPasswordDto: NewPasswordDtoDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).changePassword(newPasswordDto, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify email confirmation token.
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(token: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmailDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).confirmEmail(token, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates (and returns) a new access token for the currently logged in user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessToken(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).createAccessToken(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateUserDto} createUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUser: CreateUserDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).createUser(createUser, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserGroup(userGroup: UserGroupDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).createUserGroup(userGroup, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set the user property \'active\' to false and remove the users access tokens. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUser(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deactivateUser(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes an access token
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessToken(token: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deleteAccessToken(token, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyUserGroups(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deleteManyUserGroups(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyUsers(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deleteManyUsers(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deleteUser(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroup(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).deleteUserGroup(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all access tokens for the currently logged in user (not service or login tokens)
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessTokens(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserJwtDto>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getAccessTokens(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all usernames
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsernames(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getAllUsernames(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyFriends(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FriendDto>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getMyFriends(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyInvitation(inviteId: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationsPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getMyInvitation(inviteId, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all information that you\'ve received
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyInvitations(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationsPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getMyInvitations(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPermissions(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MyPermissionDto>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getMyPermissions(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get my user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUser(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getMyUser(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUser(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroup(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUserGroup(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroups(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUserGroups(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUserGroupsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUsers(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).getUsersCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logs in with a Microsoft Login Token
         * @param {AzureLoginRequestDto} azureLoginRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithMicrosoftToken(azureLoginRequest: AzureLoginRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).loginWithMicrosoftToken(azureLoginRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Afterwards, the current logged in user will (also) be able to login with the given MS AzureAD account.
         * @summary Merges an already authenticated account with Microsoft Azure AD login
         * @param {AzureMergeRequestDto} azureMergeRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeAccountWithMicrosoft(azureMergeRequest: AzureMergeRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).mergeAccountWithMicrosoft(azureMergeRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates your own user profile by replacing specific properties. Does not work for arrays.
         * @param {PatchUserDtoDto} patchUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMyUser(patchUserDto: PatchUserDtoDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).patchMyUser(patchUserDto, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(id: string, user: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).patchUser(id, user, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserGroup(id: string, userGroup: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).patchUserGroup(id, userGroup, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performUserGroupSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupSearchResultPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).performUserGroupSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performUserSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResultPageDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).performUserSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(id: string, user: UserDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).putUser(id, user, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserGroup(id: string, userGroup: UserGroupDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).putUserGroup(id, userGroup, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Register a new user
         * @param {RegisterUserDto} registerUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(registerUser: RegisterUserDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).registerUser(registerUser, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Removes the personal avatar image
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAvatar(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).removeAvatar(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Removes all connected Microsoft Azure AD logins from the account
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMicrosoftLogin(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).removeMicrosoftLogin(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sends a user an email verification link
         * @param {string} userId 
         * @param {EmailVerificationDtoDto} emailVerificationDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailVerification(userId: string, emailVerificationDto: EmailVerificationDtoDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmailDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).sendEmailVerification(userId, emailVerificationDto, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetPasswordMail(resetPasswordBody: ResetPasswordBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).sendResetPasswordMail(resetPasswordBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetPasswordMailForUser(resetPasswordBody: ResetPasswordBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).sendResetPasswordMailForUser(resetPasswordBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the password for the given user
         * @param {SetPasswordBodyDto} setPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPasswordForUser(setPasswordBody: SetPasswordBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).setPasswordForUser(setPasswordBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a password with a reset password token and returns a login token
         * @param {ResetPasswordWithTokenBodyDto} resetPasswordWithTokenBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPasswordWithResetToken(resetPasswordWithTokenBody: ResetPasswordWithTokenBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordWithTokenResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).setPasswordWithResetToken(resetPasswordWithTokenBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates your own user profile by replacing the entire profile.
         * @param {any} profile The own users profile
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyProfile(profile: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).updateMyProfile(profile, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a signed url where users can upload their avatar image to
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatar(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).uploadAvatar(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a User exists.
         * @summary Check if User exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).userExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a UserGroup exists.
         * @summary Check if UserGroup exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(axiosClientConfiguration).userGroupExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Set the user property \'active\' to true. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(id: string, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).activateUser(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate user with email and password
         * @param {EmailPasswordLoginDto} emailPasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithEmailAndPassword(emailPasswordLogin: EmailPasswordLoginDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).authenticateUserWithEmailAndPassword(emailPasswordLogin, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deprecated: Please use auth/email-password
         * @param {LoginUserDtoDto} loginUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithPassword(loginUserDto: LoginUserDtoDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).authenticateUserWithPassword(loginUserDto, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate user with username and password
         * @param {UsernamePasswordLoginDto} usernamePasswordLogin 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithUsernameAndPassword(usernamePasswordLogin: UsernamePasswordLoginDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).authenticateUserWithUsernameAndPassword(usernamePasswordLogin, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update users password
         * @param {NewPasswordDtoDto} newPasswordDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(newPasswordDto: NewPasswordDtoDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).changePassword(newPasswordDto, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify email confirmation token.
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(token: string, translate?: string, options?: any): AxiosPromise<UserEmailDto> {
            return UsersApiFp(axiosClientConfiguration).confirmEmail(token, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates (and returns) a new access token for the currently logged in user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(translate?: string, options?: any): AxiosPromise<UserJwtDto> {
            return UsersApiFp(axiosClientConfiguration).createAccessToken(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserDto} createUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUser: CreateUserDto, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).createUser(createUser, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroup(userGroup: UserGroupDto, translate?: string, options?: any): AxiosPromise<UserGroupDto> {
            return UsersApiFp(axiosClientConfiguration).createUserGroup(userGroup, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the user property \'active\' to false and remove the users access tokens. Trigger a workflow event.
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(id: string, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).deactivateUser(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an access token
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessToken(token: string, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).deleteAccessToken(token, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUserGroups(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).deleteManyUserGroups(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsers(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).deleteManyUsers(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).deleteUser(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).deleteUserGroup(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all access tokens for the currently logged in user (not service or login tokens)
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokens(translate?: string, options?: any): AxiosPromise<Array<UserJwtDto>> {
            return UsersApiFp(axiosClientConfiguration).getAccessTokens(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all usernames
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsernames(translate?: string, options?: any): AxiosPromise<Array<string>> {
            return UsersApiFp(axiosClientConfiguration).getAllUsernames(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyFriends(translate?: string, options?: any): AxiosPromise<Array<FriendDto>> {
            return UsersApiFp(axiosClientConfiguration).getMyFriends(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInvitation(inviteId: string, translate?: string, options?: any): AxiosPromise<InvitationsPageDto> {
            return UsersApiFp(axiosClientConfiguration).getMyInvitation(inviteId, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all information that you\'ve received
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInvitations(translate?: string, options?: any): AxiosPromise<InvitationsPageDto> {
            return UsersApiFp(axiosClientConfiguration).getMyInvitations(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPermissions(translate?: string, options?: any): AxiosPromise<Array<MyPermissionDto>> {
            return UsersApiFp(axiosClientConfiguration).getMyPermissions(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my user
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUser(translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).getMyUser(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).getUser(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup(id: string, translate?: string, options?: any): AxiosPromise<UserGroupDto> {
            return UsersApiFp(axiosClientConfiguration).getUserGroup(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<UserGroupPageDto> {
            return UsersApiFp(axiosClientConfiguration).getUserGroups(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return UsersApiFp(axiosClientConfiguration).getUserGroupsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<UserPageDto> {
            return UsersApiFp(axiosClientConfiguration).getUsers(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return UsersApiFp(axiosClientConfiguration).getUsersCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs in with a Microsoft Login Token
         * @param {AzureLoginRequestDto} azureLoginRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithMicrosoftToken(azureLoginRequest: AzureLoginRequestDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).loginWithMicrosoftToken(azureLoginRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Afterwards, the current logged in user will (also) be able to login with the given MS AzureAD account.
         * @summary Merges an already authenticated account with Microsoft Azure AD login
         * @param {AzureMergeRequestDto} azureMergeRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeAccountWithMicrosoft(azureMergeRequest: AzureMergeRequestDto, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).mergeAccountWithMicrosoft(azureMergeRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates your own user profile by replacing specific properties. Does not work for arrays.
         * @param {PatchUserDtoDto} patchUserDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMyUser(patchUserDto: PatchUserDtoDto, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).patchMyUser(patchUserDto, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(id: string, user: any, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).patchUser(id, user, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserGroup(id: string, userGroup: any, translate?: string, options?: any): AxiosPromise<UserGroupDto> {
            return UsersApiFp(axiosClientConfiguration).patchUserGroup(id, userGroup, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performUserGroupSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<UserGroupSearchResultPageDto> {
            return UsersApiFp(axiosClientConfiguration).performUserGroupSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performUserSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<UserSearchResultPageDto> {
            return UsersApiFp(axiosClientConfiguration).performUserSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} user 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(id: string, user: UserDto, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).putUser(id, user, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupDto} userGroup 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserGroup(id: string, userGroup: UserGroupDto, translate?: string, options?: any): AxiosPromise<UserGroupDto> {
            return UsersApiFp(axiosClientConfiguration).putUserGroup(id, userGroup, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a new user
         * @param {RegisterUserDto} registerUser 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(registerUser: RegisterUserDto, translate?: string, options?: any): AxiosPromise<CurrentUserDto> {
            return UsersApiFp(axiosClientConfiguration).registerUser(registerUser, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the personal avatar image
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAvatar(translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).removeAvatar(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes all connected Microsoft Azure AD logins from the account
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMicrosoftLogin(translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).removeMicrosoftLogin(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a user an email verification link
         * @param {string} userId 
         * @param {EmailVerificationDtoDto} emailVerificationDto 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerification(userId: string, emailVerificationDto: EmailVerificationDtoDto, translate?: string, options?: any): AxiosPromise<UserEmailDto> {
            return UsersApiFp(axiosClientConfiguration).sendEmailVerification(userId, emailVerificationDto, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMail(resetPasswordBody: ResetPasswordBodyDto, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).sendResetPasswordMail(resetPasswordBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a password reset mail to the given mail address
         * @param {ResetPasswordBodyDto} resetPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMailForUser(resetPasswordBody: ResetPasswordBodyDto, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).sendResetPasswordMailForUser(resetPasswordBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the password for the given user
         * @param {SetPasswordBodyDto} setPasswordBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordForUser(setPasswordBody: SetPasswordBodyDto, translate?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).setPasswordForUser(setPasswordBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a password with a reset password token and returns a login token
         * @param {ResetPasswordWithTokenBodyDto} resetPasswordWithTokenBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordWithResetToken(resetPasswordWithTokenBody: ResetPasswordWithTokenBodyDto, translate?: string, options?: any): AxiosPromise<ResetPasswordWithTokenResponseDto> {
            return UsersApiFp(axiosClientConfiguration).setPasswordWithResetToken(resetPasswordWithTokenBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates your own user profile by replacing the entire profile.
         * @param {any} profile The own users profile
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile(profile: any, translate?: string, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(axiosClientConfiguration).updateMyProfile(profile, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a signed url where users can upload their avatar image to
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar(translate?: string, options?: any): AxiosPromise<string> {
            return UsersApiFp(axiosClientConfiguration).uploadAvatar(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a User exists.
         * @summary Check if User exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userExists(id: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).userExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a UserGroup exists.
         * @summary Check if UserGroup exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupExists(id: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(axiosClientConfiguration).userGroupExists(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateUser operation in UsersApi.
 * @export
 * @interface UsersApiActivateUserRequest
 */
export interface UsersApiActivateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiActivateUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiActivateUser
     */
    readonly translate?: string
}

/**
 * Request parameters for authenticateUserWithEmailAndPassword operation in UsersApi.
 * @export
 * @interface UsersApiAuthenticateUserWithEmailAndPasswordRequest
 */
export interface UsersApiAuthenticateUserWithEmailAndPasswordRequest {
    /**
     * 
     * @type {EmailPasswordLoginDto}
     * @memberof UsersApiAuthenticateUserWithEmailAndPassword
     */
    readonly emailPasswordLogin: EmailPasswordLoginDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiAuthenticateUserWithEmailAndPassword
     */
    readonly translate?: string
}

/**
 * Request parameters for authenticateUserWithPassword operation in UsersApi.
 * @export
 * @interface UsersApiAuthenticateUserWithPasswordRequest
 */
export interface UsersApiAuthenticateUserWithPasswordRequest {
    /**
     * 
     * @type {LoginUserDtoDto}
     * @memberof UsersApiAuthenticateUserWithPassword
     */
    readonly loginUserDto: LoginUserDtoDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiAuthenticateUserWithPassword
     */
    readonly translate?: string
}

/**
 * Request parameters for authenticateUserWithUsernameAndPassword operation in UsersApi.
 * @export
 * @interface UsersApiAuthenticateUserWithUsernameAndPasswordRequest
 */
export interface UsersApiAuthenticateUserWithUsernameAndPasswordRequest {
    /**
     * 
     * @type {UsernamePasswordLoginDto}
     * @memberof UsersApiAuthenticateUserWithUsernameAndPassword
     */
    readonly usernamePasswordLogin: UsernamePasswordLoginDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiAuthenticateUserWithUsernameAndPassword
     */
    readonly translate?: string
}

/**
 * Request parameters for changePassword operation in UsersApi.
 * @export
 * @interface UsersApiChangePasswordRequest
 */
export interface UsersApiChangePasswordRequest {
    /**
     * 
     * @type {NewPasswordDtoDto}
     * @memberof UsersApiChangePassword
     */
    readonly newPasswordDto: NewPasswordDtoDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiChangePassword
     */
    readonly translate?: string
}

/**
 * Request parameters for confirmEmail operation in UsersApi.
 * @export
 * @interface UsersApiConfirmEmailRequest
 */
export interface UsersApiConfirmEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiConfirmEmail
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiConfirmEmail
     */
    readonly translate?: string
}

/**
 * Request parameters for createAccessToken operation in UsersApi.
 * @export
 * @interface UsersApiCreateAccessTokenRequest
 */
export interface UsersApiCreateAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiCreateAccessToken
     */
    readonly translate?: string
}

/**
 * Request parameters for createUser operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserRequest
 */
export interface UsersApiCreateUserRequest {
    /**
     * 
     * @type {CreateUserDto}
     * @memberof UsersApiCreateUser
     */
    readonly createUser: CreateUserDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiCreateUser
     */
    readonly translate?: string
}

/**
 * Request parameters for createUserGroup operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserGroupRequest
 */
export interface UsersApiCreateUserGroupRequest {
    /**
     * 
     * @type {UserGroupDto}
     * @memberof UsersApiCreateUserGroup
     */
    readonly userGroup: UserGroupDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiCreateUserGroup
     */
    readonly translate?: string
}

/**
 * Request parameters for deactivateUser operation in UsersApi.
 * @export
 * @interface UsersApiDeactivateUserRequest
 */
export interface UsersApiDeactivateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeactivateUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeactivateUser
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteAccessToken operation in UsersApi.
 * @export
 * @interface UsersApiDeleteAccessTokenRequest
 */
export interface UsersApiDeleteAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteAccessToken
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteAccessToken
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyUserGroups operation in UsersApi.
 * @export
 * @interface UsersApiDeleteManyUserGroupsRequest
 */
export interface UsersApiDeleteManyUserGroupsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersApiDeleteManyUserGroups
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteManyUserGroups
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyUsers operation in UsersApi.
 * @export
 * @interface UsersApiDeleteManyUsersRequest
 */
export interface UsersApiDeleteManyUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersApiDeleteManyUsers
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteManyUsers
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteUser operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRequest
 */
export interface UsersApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUser
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteUserGroup operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserGroupRequest
 */
export interface UsersApiDeleteUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserGroup
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserGroup
     */
    readonly translate?: string
}

/**
 * Request parameters for getAccessTokens operation in UsersApi.
 * @export
 * @interface UsersApiGetAccessTokensRequest
 */
export interface UsersApiGetAccessTokensRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetAccessTokens
     */
    readonly translate?: string
}

/**
 * Request parameters for getAllUsernames operation in UsersApi.
 * @export
 * @interface UsersApiGetAllUsernamesRequest
 */
export interface UsersApiGetAllUsernamesRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetAllUsernames
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyFriends operation in UsersApi.
 * @export
 * @interface UsersApiGetMyFriendsRequest
 */
export interface UsersApiGetMyFriendsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyFriends
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyInvitation operation in UsersApi.
 * @export
 * @interface UsersApiGetMyInvitationRequest
 */
export interface UsersApiGetMyInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyInvitation
     */
    readonly inviteId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyInvitation
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyInvitations operation in UsersApi.
 * @export
 * @interface UsersApiGetMyInvitationsRequest
 */
export interface UsersApiGetMyInvitationsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyInvitations
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyPermissions operation in UsersApi.
 * @export
 * @interface UsersApiGetMyPermissionsRequest
 */
export interface UsersApiGetMyPermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyPermissions
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyUser operation in UsersApi.
 * @export
 * @interface UsersApiGetMyUserRequest
 */
export interface UsersApiGetMyUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetMyUser
     */
    readonly translate?: string
}

/**
 * Request parameters for getUser operation in UsersApi.
 * @export
 * @interface UsersApiGetUserRequest
 */
export interface UsersApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUser
     */
    readonly translate?: string
}

/**
 * Request parameters for getUserGroup operation in UsersApi.
 * @export
 * @interface UsersApiGetUserGroupRequest
 */
export interface UsersApiGetUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroup
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroup
     */
    readonly translate?: string
}

/**
 * Request parameters for getUserGroups operation in UsersApi.
 * @export
 * @interface UsersApiGetUserGroupsRequest
 */
export interface UsersApiGetUserGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserGroups
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserGroups
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroups
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiGetUserGroups
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroups
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroups
     */
    readonly search?: string
}

/**
 * Request parameters for getUserGroupsCount operation in UsersApi.
 * @export
 * @interface UsersApiGetUserGroupsCountRequest
 */
export interface UsersApiGetUserGroupsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserGroupsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getUsers operation in UsersApi.
 * @export
 * @interface UsersApiGetUsersRequest
 */
export interface UsersApiGetUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUsers
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsers
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiGetUsers
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsers
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsers
     */
    readonly search?: string
}

/**
 * Request parameters for getUsersCount operation in UsersApi.
 * @export
 * @interface UsersApiGetUsersCountRequest
 */
export interface UsersApiGetUsersCountRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUsersCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUsersCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsersCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsersCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiGetUsersCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUsersCount
     */
    readonly translate?: string
}

/**
 * Request parameters for loginWithMicrosoftToken operation in UsersApi.
 * @export
 * @interface UsersApiLoginWithMicrosoftTokenRequest
 */
export interface UsersApiLoginWithMicrosoftTokenRequest {
    /**
     * 
     * @type {AzureLoginRequestDto}
     * @memberof UsersApiLoginWithMicrosoftToken
     */
    readonly azureLoginRequest: AzureLoginRequestDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiLoginWithMicrosoftToken
     */
    readonly translate?: string
}

/**
 * Request parameters for mergeAccountWithMicrosoft operation in UsersApi.
 * @export
 * @interface UsersApiMergeAccountWithMicrosoftRequest
 */
export interface UsersApiMergeAccountWithMicrosoftRequest {
    /**
     * 
     * @type {AzureMergeRequestDto}
     * @memberof UsersApiMergeAccountWithMicrosoft
     */
    readonly azureMergeRequest: AzureMergeRequestDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiMergeAccountWithMicrosoft
     */
    readonly translate?: string
}

/**
 * Request parameters for patchMyUser operation in UsersApi.
 * @export
 * @interface UsersApiPatchMyUserRequest
 */
export interface UsersApiPatchMyUserRequest {
    /**
     * 
     * @type {PatchUserDtoDto}
     * @memberof UsersApiPatchMyUser
     */
    readonly patchUserDto: PatchUserDtoDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchMyUser
     */
    readonly translate?: string
}

/**
 * Request parameters for patchUser operation in UsersApi.
 * @export
 * @interface UsersApiPatchUserRequest
 */
export interface UsersApiPatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUser
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof UsersApiPatchUser
     */
    readonly user: any

    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUser
     */
    readonly translate?: string
}

/**
 * Request parameters for patchUserGroup operation in UsersApi.
 * @export
 * @interface UsersApiPatchUserGroupRequest
 */
export interface UsersApiPatchUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserGroup
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof UsersApiPatchUserGroup
     */
    readonly userGroup: any

    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserGroup
     */
    readonly translate?: string
}

/**
 * Request parameters for performUserGroupSearch operation in UsersApi.
 * @export
 * @interface UsersApiPerformUserGroupSearchRequest
 */
export interface UsersApiPerformUserGroupSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof UsersApiPerformUserGroupSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiPerformUserGroupSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performUserSearch operation in UsersApi.
 * @export
 * @interface UsersApiPerformUserSearchRequest
 */
export interface UsersApiPerformUserSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof UsersApiPerformUserSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiPerformUserSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putUser operation in UsersApi.
 * @export
 * @interface UsersApiPutUserRequest
 */
export interface UsersApiPutUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPutUser
     */
    readonly id: string

    /**
     * 
     * @type {UserDto}
     * @memberof UsersApiPutUser
     */
    readonly user: UserDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiPutUser
     */
    readonly translate?: string
}

/**
 * Request parameters for putUserGroup operation in UsersApi.
 * @export
 * @interface UsersApiPutUserGroupRequest
 */
export interface UsersApiPutUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPutUserGroup
     */
    readonly id: string

    /**
     * 
     * @type {UserGroupDto}
     * @memberof UsersApiPutUserGroup
     */
    readonly userGroup: UserGroupDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiPutUserGroup
     */
    readonly translate?: string
}

/**
 * Request parameters for registerUser operation in UsersApi.
 * @export
 * @interface UsersApiRegisterUserRequest
 */
export interface UsersApiRegisterUserRequest {
    /**
     * 
     * @type {RegisterUserDto}
     * @memberof UsersApiRegisterUser
     */
    readonly registerUser: RegisterUserDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiRegisterUser
     */
    readonly translate?: string
}

/**
 * Request parameters for removeAvatar operation in UsersApi.
 * @export
 * @interface UsersApiRemoveAvatarRequest
 */
export interface UsersApiRemoveAvatarRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiRemoveAvatar
     */
    readonly translate?: string
}

/**
 * Request parameters for removeMicrosoftLogin operation in UsersApi.
 * @export
 * @interface UsersApiRemoveMicrosoftLoginRequest
 */
export interface UsersApiRemoveMicrosoftLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiRemoveMicrosoftLogin
     */
    readonly translate?: string
}

/**
 * Request parameters for sendEmailVerification operation in UsersApi.
 * @export
 * @interface UsersApiSendEmailVerificationRequest
 */
export interface UsersApiSendEmailVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiSendEmailVerification
     */
    readonly userId: string

    /**
     * 
     * @type {EmailVerificationDtoDto}
     * @memberof UsersApiSendEmailVerification
     */
    readonly emailVerificationDto: EmailVerificationDtoDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiSendEmailVerification
     */
    readonly translate?: string
}

/**
 * Request parameters for sendResetPasswordMail operation in UsersApi.
 * @export
 * @interface UsersApiSendResetPasswordMailRequest
 */
export interface UsersApiSendResetPasswordMailRequest {
    /**
     * 
     * @type {ResetPasswordBodyDto}
     * @memberof UsersApiSendResetPasswordMail
     */
    readonly resetPasswordBody: ResetPasswordBodyDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiSendResetPasswordMail
     */
    readonly translate?: string
}

/**
 * Request parameters for sendResetPasswordMailForUser operation in UsersApi.
 * @export
 * @interface UsersApiSendResetPasswordMailForUserRequest
 */
export interface UsersApiSendResetPasswordMailForUserRequest {
    /**
     * 
     * @type {ResetPasswordBodyDto}
     * @memberof UsersApiSendResetPasswordMailForUser
     */
    readonly resetPasswordBody: ResetPasswordBodyDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiSendResetPasswordMailForUser
     */
    readonly translate?: string
}

/**
 * Request parameters for setPasswordForUser operation in UsersApi.
 * @export
 * @interface UsersApiSetPasswordForUserRequest
 */
export interface UsersApiSetPasswordForUserRequest {
    /**
     * 
     * @type {SetPasswordBodyDto}
     * @memberof UsersApiSetPasswordForUser
     */
    readonly setPasswordBody: SetPasswordBodyDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiSetPasswordForUser
     */
    readonly translate?: string
}

/**
 * Request parameters for setPasswordWithResetToken operation in UsersApi.
 * @export
 * @interface UsersApiSetPasswordWithResetTokenRequest
 */
export interface UsersApiSetPasswordWithResetTokenRequest {
    /**
     * 
     * @type {ResetPasswordWithTokenBodyDto}
     * @memberof UsersApiSetPasswordWithResetToken
     */
    readonly resetPasswordWithTokenBody: ResetPasswordWithTokenBodyDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiSetPasswordWithResetToken
     */
    readonly translate?: string
}

/**
 * Request parameters for updateMyProfile operation in UsersApi.
 * @export
 * @interface UsersApiUpdateMyProfileRequest
 */
export interface UsersApiUpdateMyProfileRequest {
    /**
     * The own users profile
     * @type {any}
     * @memberof UsersApiUpdateMyProfile
     */
    readonly profile: any

    /**
     * 
     * @type {string}
     * @memberof UsersApiUpdateMyProfile
     */
    readonly translate?: string
}

/**
 * Request parameters for uploadAvatar operation in UsersApi.
 * @export
 * @interface UsersApiUploadAvatarRequest
 */
export interface UsersApiUploadAvatarRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUploadAvatar
     */
    readonly translate?: string
}

/**
 * Request parameters for userExists operation in UsersApi.
 * @export
 * @interface UsersApiUserExistsRequest
 */
export interface UsersApiUserExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUserExists
     */
    readonly id: string
}

/**
 * Request parameters for userGroupExists operation in UsersApi.
 * @export
 * @interface UsersApiUserGroupExistsRequest
 */
export interface UsersApiUserGroupExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUserGroupExists
     */
    readonly id: string
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Set the user property \'active\' to true. Trigger a workflow event.
     * @param {UsersApiActivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public activateUser(requestParameters: UsersApiActivateUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).activateUser(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate user with email and password
     * @param {UsersApiAuthenticateUserWithEmailAndPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authenticateUserWithEmailAndPassword(requestParameters: UsersApiAuthenticateUserWithEmailAndPasswordRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).authenticateUserWithEmailAndPassword(requestParameters.emailPasswordLogin, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deprecated: Please use auth/email-password
     * @param {UsersApiAuthenticateUserWithPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authenticateUserWithPassword(requestParameters: UsersApiAuthenticateUserWithPasswordRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).authenticateUserWithPassword(requestParameters.loginUserDto, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate user with username and password
     * @param {UsersApiAuthenticateUserWithUsernameAndPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authenticateUserWithUsernameAndPassword(requestParameters: UsersApiAuthenticateUserWithUsernameAndPasswordRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).authenticateUserWithUsernameAndPassword(requestParameters.usernamePasswordLogin, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update users password
     * @param {UsersApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(requestParameters: UsersApiChangePasswordRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).changePassword(requestParameters.newPasswordDto, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify email confirmation token.
     * @param {UsersApiConfirmEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public confirmEmail(requestParameters: UsersApiConfirmEmailRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).confirmEmail(requestParameters.token, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates (and returns) a new access token for the currently logged in user
     * @param {UsersApiCreateAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createAccessToken(requestParameters: UsersApiCreateAccessTokenRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).createAccessToken(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(requestParameters: UsersApiCreateUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).createUser(requestParameters.createUser, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiCreateUserGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserGroup(requestParameters: UsersApiCreateUserGroupRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).createUserGroup(requestParameters.userGroup, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the user property \'active\' to false and remove the users access tokens. Trigger a workflow event.
     * @param {UsersApiDeactivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deactivateUser(requestParameters: UsersApiDeactivateUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deactivateUser(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an access token
     * @param {UsersApiDeleteAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteAccessToken(requestParameters: UsersApiDeleteAccessTokenRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deleteAccessToken(requestParameters.token, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiDeleteManyUserGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteManyUserGroups(requestParameters: UsersApiDeleteManyUserGroupsRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deleteManyUserGroups(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiDeleteManyUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteManyUsers(requestParameters: UsersApiDeleteManyUsersRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deleteManyUsers(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deleteUser(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiDeleteUserGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserGroup(requestParameters: UsersApiDeleteUserGroupRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).deleteUserGroup(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all access tokens for the currently logged in user (not service or login tokens)
     * @param {UsersApiGetAccessTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAccessTokens(requestParameters: UsersApiGetAccessTokensRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getAccessTokens(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all usernames
     * @param {UsersApiGetAllUsernamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsernames(requestParameters: UsersApiGetAllUsernamesRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getAllUsernames(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetMyFriendsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyFriends(requestParameters: UsersApiGetMyFriendsRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getMyFriends(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetMyInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyInvitation(requestParameters: UsersApiGetMyInvitationRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getMyInvitation(requestParameters.inviteId, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all information that you\'ve received
     * @param {UsersApiGetMyInvitationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyInvitations(requestParameters: UsersApiGetMyInvitationsRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getMyInvitations(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetMyPermissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyPermissions(requestParameters: UsersApiGetMyPermissionsRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getMyPermissions(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my user
     * @param {UsersApiGetMyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyUser(requestParameters: UsersApiGetMyUserRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getMyUser(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(requestParameters: UsersApiGetUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUser(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserGroup(requestParameters: UsersApiGetUserGroupRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUserGroup(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserGroups(requestParameters: UsersApiGetUserGroupsRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUserGroups(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserGroupsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserGroupsCount(requestParameters: UsersApiGetUserGroupsCountRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUserGroupsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(requestParameters: UsersApiGetUsersRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUsers(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUsersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersCount(requestParameters: UsersApiGetUsersCountRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).getUsersCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs in with a Microsoft Login Token
     * @param {UsersApiLoginWithMicrosoftTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loginWithMicrosoftToken(requestParameters: UsersApiLoginWithMicrosoftTokenRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).loginWithMicrosoftToken(requestParameters.azureLoginRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Afterwards, the current logged in user will (also) be able to login with the given MS AzureAD account.
     * @summary Merges an already authenticated account with Microsoft Azure AD login
     * @param {UsersApiMergeAccountWithMicrosoftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public mergeAccountWithMicrosoft(requestParameters: UsersApiMergeAccountWithMicrosoftRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).mergeAccountWithMicrosoft(requestParameters.azureMergeRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates your own user profile by replacing specific properties. Does not work for arrays.
     * @param {UsersApiPatchMyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchMyUser(requestParameters: UsersApiPatchMyUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).patchMyUser(requestParameters.patchUserDto, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPatchUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUser(requestParameters: UsersApiPatchUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).patchUser(requestParameters.id, requestParameters.user, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPatchUserGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUserGroup(requestParameters: UsersApiPatchUserGroupRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).patchUserGroup(requestParameters.id, requestParameters.userGroup, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPerformUserGroupSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public performUserGroupSearch(requestParameters: UsersApiPerformUserGroupSearchRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).performUserGroupSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPerformUserSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public performUserSearch(requestParameters: UsersApiPerformUserSearchRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).performUserSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPutUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUser(requestParameters: UsersApiPutUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).putUser(requestParameters.id, requestParameters.user, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPutUserGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserGroup(requestParameters: UsersApiPutUserGroupRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).putUserGroup(requestParameters.id, requestParameters.userGroup, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a new user
     * @param {UsersApiRegisterUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public registerUser(requestParameters: UsersApiRegisterUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).registerUser(requestParameters.registerUser, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the personal avatar image
     * @param {UsersApiRemoveAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeAvatar(requestParameters: UsersApiRemoveAvatarRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).removeAvatar(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes all connected Microsoft Azure AD logins from the account
     * @param {UsersApiRemoveMicrosoftLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeMicrosoftLogin(requestParameters: UsersApiRemoveMicrosoftLoginRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).removeMicrosoftLogin(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a user an email verification link
     * @param {UsersApiSendEmailVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendEmailVerification(requestParameters: UsersApiSendEmailVerificationRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).sendEmailVerification(requestParameters.userId, requestParameters.emailVerificationDto, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a password reset mail to the given mail address
     * @param {UsersApiSendResetPasswordMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendResetPasswordMail(requestParameters: UsersApiSendResetPasswordMailRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).sendResetPasswordMail(requestParameters.resetPasswordBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a password reset mail to the given mail address
     * @param {UsersApiSendResetPasswordMailForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendResetPasswordMailForUser(requestParameters: UsersApiSendResetPasswordMailForUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).sendResetPasswordMailForUser(requestParameters.resetPasswordBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the password for the given user
     * @param {UsersApiSetPasswordForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setPasswordForUser(requestParameters: UsersApiSetPasswordForUserRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).setPasswordForUser(requestParameters.setPasswordBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a password with a reset password token and returns a login token
     * @param {UsersApiSetPasswordWithResetTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setPasswordWithResetToken(requestParameters: UsersApiSetPasswordWithResetTokenRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).setPasswordWithResetToken(requestParameters.resetPasswordWithTokenBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates your own user profile by replacing the entire profile.
     * @param {UsersApiUpdateMyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateMyProfile(requestParameters: UsersApiUpdateMyProfileRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).updateMyProfile(requestParameters.profile, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a signed url where users can upload their avatar image to
     * @param {UsersApiUploadAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public uploadAvatar(requestParameters: UsersApiUploadAvatarRequest = {}, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).uploadAvatar(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a User exists.
     * @summary Check if User exists
     * @param {UsersApiUserExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userExists(requestParameters: UsersApiUserExistsRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).userExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a UserGroup exists.
     * @summary Check if UserGroup exists
     * @param {UsersApiUserGroupExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userGroupExists(requestParameters: UsersApiUserGroupExistsRequest, options?: any) {
        return UsersApiFp(this.axiosClientConfiguration).userGroupExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
