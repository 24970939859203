import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { WorklogDto } from "@smallstack/axios-api-client";
import { ConfigurationStore } from "@smallstack/common-components";
import { StoreState } from "@smallstack/store";
import { TodoBillingPrice, TodoBillingService } from "@smallstack/todo-shared";
import { TodoStore } from "../../stores/todo.store";

@Component({
  selector: "smallstack-worklog-billing",
  templateUrl: "./worklog-billing.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogBillingComponent {
  protected prices: TodoBillingPrice[] = [];

  @Input()
  protected set worklog(worklog: WorklogDto) {
    if (this.configurationStore.state !== StoreState.LOADED) void this.configurationStore.load();
    void this.configurationStore.waitForLoaded().then(async () => {
      if (worklog?.todoRefId) {
        const todoRef = await this.todoStore.get(worklog.todoRefId);
        this.prices = [];
        if (worklog.duration !== undefined && todoRef)
          this.prices = this.todoBillingService.getBilling(
            todoRef.billingPositions,
            this.configurationStore.value,
            worklog.duration
          );
        this.cdr.markForCheck();
      }
    });
  }

  constructor(
    protected configurationStore: ConfigurationStore,
    private cdr: ChangeDetectorRef,
    private todoBillingService: TodoBillingService,
    private todoStore: TodoStore
  ) {}
}
