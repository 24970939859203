/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ModelContextObjectDto } from './model-context-object-dto';

/**
 * 
 * @export
 * @interface ConfigurationDto
 */
export interface ConfigurationDto {
    /**
     * Describes the date of the last update
     * @type {number}
     * @memberof ConfigurationDto
     */
    lastUpdatedAt?: number;
    /**
     * Describe the date when the entity was deleted
     * @type {number}
     * @memberof ConfigurationDto
     */
    _deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationDto
     */
    createdAt?: number;
    /**
     * 
     * @type {ModelContextObjectDto}
     * @memberof ConfigurationDto
     */
    context?: ModelContextObjectDto;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    scope: ConfigurationDtoScopeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConfigurationDtoScopeEnum {
    Public = 'public',
    Server = 'server',
    System = 'system'
}



