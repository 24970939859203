<smallstack-form-input-container
  [options]="options()"
  [schema]="schema()"
  [path]="path()"
  [validationErrorText]="validationErrorText()"
>
  <div class="join">
    <ng-container [ngSwitch]="value()?.type">
      <ng-container *ngSwitchCase="'inline'">
        <ng-container [ngSwitch]="displayFormat()">
          <ng-container *ngSwitchCase="'currency'">
            <input
              type="text"
              class="input input-bordered w-full join-item"
              [ngModel]="inlineCurrencyValue()"
              (ngModelChange)="updateInlineCurrency($event)"
              currencyMask
              [options]="{
                prefix: '',
                suffix: ' €',
                thousands: '.',
                decimal: ',',
                allowNegative: false,
                align: 'left'
              }"
              attr.data-testid="schema-form-widget-input-{{ path() }}-value"
            />
          </ng-container>
          <ng-container *ngSwitchDefault>
            <input
              type="text"
              class="input input-bordered w-full join-item"
              [ngModel]="value().inlineValue"
              (ngModelChange)="updateInlineValue($event)"
              attr.data-testid="schema-form-widget-input-{{ path() }}-value"
            />
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'projectConfiguration'">
        <select
          class="select select-bordered w-full max-w-xs join-item"
          [ngModel]="currentConfiguration()?.key"
          (ngModelChange)="selectConfigurationKey($event)"
          attr.data-testid="schema-form-widget-configurable-property-{{ path() }}-select"
        >
          <ng-container *ngFor="let configuration of configurations()">
            <option
              [value]="configuration.key"
              attr.data-testid="schema-form-widget-configurable-property-{{ path() }}-value-{{ configuration.key }}"
              >{{ configuration.description }} ({{ configuration.formattedKey }}={{
                configuration.formattedValue
              }})</option
            >
          </ng-container>
        </select>
      </ng-container>
      <select
        class="select select-bordered bg-secondary text-secondary-content join-item"
        [ngModel]="value()?.type"
        (ngModelChange)="updateType($event)"
        attr.data-testid="schema-form-widget-configurable-property-{{ path() }}-type"
      >
        <option value="inline" attr.data-testid="schema-form-widget-configurable-property-{{ path() }}-type-inline"
          >Direkte Eingabe</option
        >
        <option
          value="projectConfiguration"
          attr.data-testid="schema-form-widget-configurable-property-{{ path() }}-type-project-configuration"
          >Globale Konfiguration</option
        >
      </select>
    </ng-container>
  </div>
</smallstack-form-input-container>
