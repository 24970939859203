@if (userContext().currentUser) {
  <smallstack-widget-child
    [data]="data()"
    (dataChange)="dataChange.emit($event)"
    [context]="userContext()"
    property="loggedInComponent"
  ></smallstack-widget-child>
} @else {
  <smallstack-widget-child
    [data]="data()"
    (dataChange)="dataChange.emit($event)"
    [context]="context()"
    property="loggedOutComponent"
  ></smallstack-widget-child>
}
