import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { RouterUtilService } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_HTML_SIZING, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { distinctUntilChangedObj, filterNullish, getJsonByPath } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget, WidgetRendererOptions } from "@smallstack/widget-core";
import { combineLatest } from "rxjs";
import {
  DialogActionButtonDialogComponent,
  DialogActionButtonDialogComponentData
} from "./dialog-action-button-dialog/dialog-action-button-dialog.component";

@Widget({
  name: "DialogActionButton",
  templateName: "Dialog Button",
  templateDescription: "Zeigt einen Button an, welcher einen Dialog öffnet",
  tags: AllWidgetTags,
  icon: "plus-2-math",
  dataSchema: {
    type: "object",
    properties: {
      icon: {
        type: "string",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        },
        default: "plus-2-math"
      },
      text: {
        type: "string",
        title: "Text"
      },
      tooltip: {
        type: "string",
        title: "Tooltip",
        description: "Tooltip für den Dialog Button"
      },
      width: {
        type: "string",
        title: "Breite",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          values: [
            {
              value: "",
              label: [{ value: "Normal" }]
            },
            {
              value: "btn-wide",
              label: [{ value: "Breiter" }]
            },
            {
              value: "btn-block",
              label: [{ value: "Volle Breite" }]
            }
          ]
        }
      },
      dialogTitle: {
        type: "string",
        title: "Dialog Titel"
      },
      dialogWidth: {
        type: "string",
        title: "Dialog Breite",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      dialogHeight: {
        type: "string",
        title: "Dialog Höhe",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      showCloseBtn: {
        type: "boolean",
        title: "Schliessen Button anzeigen"
      },
      dialogMaxWidth: {
        type: "string",
        title: "Maximale Dialog Breite",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      dialogMaxHeight: {
        type: "string",
        title: "Maximale Dialog Höhe",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      dialogComponent: {
        type: "object",
        title: "Dialog Widget",
        description: "Definieren Sie hier das Widget, welches im sich öffnenden Dialog angezeigt wird",
        "x-schema-form": {
          widget: "cms"
        }
      },
      dialogUrlSyncParameter: {
        type: "string",
        title: "Url Sync Parameter",
        description: "Synchronisiert den geöffneten Dialog über den angegebenen URL Parameter"
      },
      dialogUrlSyncContextVariable: {
        type: "string",
        title: "Url Sync Inhalt",
        description: "Kontext Variablenname, welcher mit dem Url Parameter verglichen wird",
        "x-schema-form": {
          widget: "ContextVariable"
        }
      }
    }
  },
  sockets: [{ direction: SocketDtoDirectionEnum.Out, name: "afterDialogClosed", type: SocketDtoTypeEnum.Undefined }]
})
@Component({
  selector: "smallstack-dialog-action-button-widget",
  templateUrl: "./dialog-action-button-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogActionButtonWidgetComponent extends BaseWidgetComponent {
  constructor(
    private matDialog: MatDialog,
    activatedRoute: ActivatedRoute,
    private routerUtilService: RouterUtilService
  ) {
    super();
    this.subscription.add(
      combineLatest([activatedRoute.queryParams, this.data$, this.context$])
        .pipe(filterNullish({ filterEmptyObjects: true }), distinctUntilChangedObj())
        .subscribe(([params, data, context]) => {
          if (data.dialogUrlSyncParameter !== undefined && data.dialogUrlSyncContextVariable !== undefined) {
            if (params[data.dialogUrlSyncParameter] === getJsonByPath(context, data.dialogUrlSyncContextVariable))
              if (this.matDialog.openDialogs.length === 0) void this.openDialog()();
          }
        })
    );
  }

  public openDialog() {
    return async (): Promise<void> => {
      return new Promise<void>((resolve) => {
        this.matDialog.closeAll();
        const dialog = this.matDialog.open(DialogActionButtonDialogComponent, {
          width: this.data()?.dialogWidth,
          height: this.data()?.dialogHeight,
          maxWidth: this.data()?.dialogMaxWidth || "95vw",
          maxHeight: this.data()?.dialogMaxHeight || "95vh",
          autoFocus: false,
          data: {
            cmsComponent: this.data()?.dialogComponent,
            widgetRendererOptions: {
              editMode: this.widgetTreeService.editMode()
            } as WidgetRendererOptions,
            context: this.context(),
            globals: this.context(),
            title: this.data()?.dialogTitle,
            showCloseBtn: this.data()?.showCloseBtn
          } as DialogActionButtonDialogComponentData
        });

        this.widgetTreeService.dialogRef = dialog;

        void dialog.afterOpened().subscribe(async () => {
          if (this.data()?.dialogUrlSyncParameter)
            await this.routerUtilService.addSearchParameter(
              this.data()?.dialogUrlSyncParameter,
              getJsonByPath(this.context(), this.data()?.dialogUrlSyncContextVariable)
            );
        });

        void dialog.beforeClosed().subscribe(async (component) => {
          // remove sync parameter
          if (this.data()?.dialogUrlSyncParameter)
            await this.routerUtilService.removeQueryParameter(this.data().dialogUrlSyncParameter);

          // if in edit mode, forward (changed) component
          if (this.widgetTreeService.editMode() === true && component) {
            const data = this.data() || {};
            data.dialogComponent = component;
            this.dataChange.emit(data);
          }

          resolve();
        });

        void dialog.afterClosed().subscribe(() => {
          void this.sendSocketData("afterDialogClosed", undefined);
        });
      });
    };
  }
}
