<div class="flex flex-col items-stretch">
  <div style="padding: 10px" class="w-full">
    <smallstack-store-search
      [store]="fileStore"
      [filterNames]="filterNames"
      [filterValues]="filterValues"
      [showModeSwitcher]="true"
      [syncSearchParams]="false"
    />
  </div>
  <div [ngClass]="selectable ? 'selectable' : ''">
    <smallstack-list-container [store]="fileStore">
      <ng-template #content>
        @if (displayViewAs === "list") {
          <table
            mat-table
            matSort
            matSortDisableClear
            class="w-full"
            [dataSource]="fileStore.currentPage$ | async"
            [smallstackMatSortStore]="fileStore"
          >
            <ng-container matColumnDef="icon">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let file" mat-cell data-test="file-list-cell-icon">
                @if (file?.type === "application/pdf") {
                  <smallstack-icon alias="pdf" size="24" />
                } @else {
                  <smallstack-icon alias="imagefile" size="24" />
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell mat-sort-header
                ><span class="overflow-triple-points">
                  <smallstack-i18n data="@@backoffice.files.filename" />
                </span>
              </th>
              <td *matCellDef="let file" mat-cell data-test="file-list-cell-name"
                ><span class="overflow-triple-points">{{ file.name }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                <smallstack-i18n data="@@backoffice.files.filesize" />
              </th>
              <td *matCellDef="let file" mat-cell data-test="file-list-cell-size"> {{ file.size | fileSize }}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                <smallstack-i18n data="@@backoffice.files.filetype" />
              </th>
              <td *matCellDef="let file" mat-cell data-test="file-list-cell-type"> {{ file.type }}</td>
            </ng-container>
            <ng-container matColumnDef="id">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                <smallstack-i18n data="@@backoffice.files.createdat" />
              </th>
              <td *matCellDef="let file" mat-cell> <smallstack-date [timestamp]="file.createdAt" /></td>
            </ng-container>
            <!-- <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef class="lg"  >Tags</mat-header-cell>
          <td mat-cell *matCellDef="let item" class="lg"  >
            <tag-list [tags]="item.tags"></tag-list>
          </td>
        </ng-container> -->
            <ng-container matColumnDef="actions">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" mat-cell class="justify-center items-center basis-full">
                @if (!selectable && isUploading(item.id) | async; as uploadStatus) {
                  <div class="flex flex-col justify-center items-center h-full w-full min-h-full min-w-full">
                    <mat-progress-bar mode="determinate" [value]="uploadStatus.progress" />
                    {{ uploadStatus.progress }}%
                  </div>
                }
                @if (!selectable && (isUploading(item.id) | async) === undefined) {
                  <div class="flex flex-row space-x-2">
                    <button class="btn-action" (click)="openDetail(item)">
                      <smallstack-icon alias="edit" />
                    </button>
                    <button
                      class="btn-action"
                      message="@@components.deletedialog.message.files"
                      [loadingFn]="remove(item)"
                    >
                      <smallstack-icon alias="delete" />
                    </button>
                  </div>
                }
                @if (selectable && !(isUploading(item.id) | async)) {
                  <div class="flex flex-row space-x-2">
                    <button class="select-button btn btn-outline btn-primary" (click)="openDetail(item)">
                      <smallstack-i18n data="@@actions.select" />
                    </button>
                  </div>
                }
              </td>
            </ng-container>
            <tr *matHeaderRowDef="columns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: columns" mat-row></tr>
          </table>
        }

        @if (displayViewAs === "thumbnails") {
          <div class="m-4">
            <div class="smallstack-grid">
              @for (item of fileStore.currentPage$ | async; track item) {
                <smallstack-file-explorer-thumbnail
                  [file]="item"
                  [buttonLabelKey]="selectable ? '@@actions.select' : '@@nouns.details'"
                  (clicked)="openDetail(item)"
                >
                  @if (!selectable && isUploading(item.id) | async; as uploadStatus) {
                    <div class="flex flex-col justify-center items-center h-full w-full min-h-full min-w-full">
                      <mat-progress-bar mode="determinate" [value]="uploadStatus.progress" />
                      {{ uploadStatus.progress }}%
                    </div>
                  }
                </smallstack-file-explorer-thumbnail>
              }
            </div>
          </div>
        }
      </ng-template>
      <ng-template #empty> No files loaded... </ng-template>
    </smallstack-list-container>
  </div>
</div>
