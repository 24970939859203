import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const UpcomingTodoTemplatesWidget: WidgetRegistryEntry = {
  name: "UpcomingTodoTemplates",
  component: () =>
    import("./upcoming-todo-templates-widget.component").then((m) => m.UpcomingTodoTemplatesWidgetComponent),
  configuration: {
    icon: "to-do",
    tags: AllWidgetTags,
    templateName: "Anstehende Aufgaben Vorlagen",
    templateDescription: "Zeigt anstehende Aufgaben Vorlagen",
    dataSchema: {
      type: "object",
      properties: {
        howMany: {
          title: "Anzahl",
          description: "Wie viele Aufgaben Vorlagen sollen angezeigt werden?",
          type: "number"
        },
        onlyMine: {
          title: "Nur Eigene?",
          type: "boolean",
          description:
            "Falls ja, dann werden nur Aufgaben Vorlagen angezeigt, die an den angemeldeten Benutzer zugewiesen sind."
        },
        showDescription: {
          type: "boolean",
          title: "Beschreibung anzeigen?",
          default: true
        },
        billingPartyFilter: {
          type: "string",
          title: "Billing Party Filter"
        },
        emptyMessage: {
          type: "string",
          title: "Keine gefunden Text",
          default: "Derzeit keine Aufgaben geplant"
        },
        template: {
          type: "object",
          title: "Vorlage",
          "x-schema-form": { widget: "widget" }
        }
      }
    }
  }
};
