/** @deprecated Please use the one from `@smallstack/client-common` */
export function replaceQueryParam(parameterName: string, value: string): void {
  const regex = new RegExp("([?;&])" + parameterName + "[^&;]*[;&]?");
  const query = window.location.search.replace(regex, "$1").replace(/&$/, "");
  window.location.search = (query.length > 2 ? query + "&" : "?") + (value ? parameterName + "=" + value : "");
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function removeRelativePaths(path: string): string {
  const split: string[] = path.split("/");
  for (let i = 0; i < split.length; i++) {
    if (split[i] === "..") {
      // count depth
      let depth = 1;
      for (let j = i + 1; j < split.length; j++)
        if (split[j] === "..") depth++;
        else break;
      split.splice(i - depth, depth * 2);
    }
  }
  return split.join("/");
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function convertQueryStringToMap(queryString: string): { [key: string]: string } {
  if (!queryString) return {};
  const params = new URLSearchParams(queryString);
  const map: { [key: string]: string } = {};
  params.forEach((value: string, key: string) => {
    map[key] = value;
  });
  return map;
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function convertMapToQueryString(map: { [key: string]: string }): string {
  if (!map) return undefined;
  return Object.keys(map)
    .map((key) => {
      return key + "=" + map[key];
    })
    .join("&");
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function removeQueryString(url: string): string {
  return url.split("?")[0];
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function extractQueryString(url: string): string {
  return url.split("?")[1];
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function splitQueryString(url: string): { url: string; queryString: string } {
  return { url: removeQueryString(url), queryString: extractQueryString(url) };
}

/** @deprecated Please use the one from `@smallstack/client-common` */
export function getUrlParameterByName(name: string, url: string): string {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(url);
  return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, " "));
}
