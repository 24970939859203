<div class="form-control">
  <label class="label">
    <span class="label-text">E-Mail</span>
  </label>
  <input
    class="input input-bordered w-full"
    data-test="backoffice-login-email"
    name="email"
    type="email"
    required
    (keydown.enter)="progressBarBtn.click()"
    [(ngModel)]="newEmail"
  />
</div>
<smallstack-user-password-field
  [(password)]="newPassword"
  label="Passwort"
  data-test="backoffice-registration-password-field"
></smallstack-user-password-field>

<button
  class="btn btn-block btn-primary mt-4"
  [loadingFn]="register()"
  #progressBarBtn="loadingFn"
  data-test="backoffice-registration-submit-btn"
  [ngClass]="{ 'btn-disabled': !isFormValid() }"
>
  Konto erstellen
</button>
