import { ObjectId } from "mongodb";

/**
 * Checks if a given array of ObjectIds contains a certain ObjectId.
 */
export function includesObjectId(array: ObjectId[], objectId: ObjectId): boolean {
  return array.findIndex((id) => id.equals(objectId)) >= 0;
}

export function isObjectIdString(s: any): boolean {
  return typeof s === "string" && s.length && s.match(/^[0-9a-fA-F]{24}$/)?.length > 0;
}
