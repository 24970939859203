import { ChangeDetectionStrategy, Component, OnInit, computed, effect } from "@angular/core";
import { SchedulingRuleDto, SchedulingRuleDtoPatternEnum } from "@smallstack/axios-api-client";
import { DEFAULT_HTML5_DATE_TIME_FORMAT } from "@smallstack/utils";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { format, parseISO } from "date-fns";
import { generateNextDates, generateNextDatesUntil } from "../todo-template-list-entry/due-date-generator";

@Component({
  selector: "smallstack-scheduling-rule-input",
  templateUrl: "./scheduling-rule-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulingRuleInputComponent extends SchemaFormBaseWidget<SchedulingRuleDto> implements OnInit {
  protected SchedulingRulePattern = Object.values(SchedulingRuleDtoPatternEnum);
  protected startTime = computed(() =>
    this.value()?.startTime ? format(this.value().startTime, DEFAULT_HTML5_DATE_TIME_FORMAT) : undefined
  );
  protected endTime = computed(() =>
    this.value()?.endTime ? format(this.value().endTime, DEFAULT_HTML5_DATE_TIME_FORMAT) : undefined
  );

  protected nextDates = computed<number[]>(() => {
    if (this.value()?.endTime)
      return generateNextDatesUntil(this.value(), {
        from: this.value().startTime,
        until: this.value().endTime
      });
    else return generateNextDates(this.value(), { resultCount: 10 });
  });

  constructor() {
    super();
    effect(
      () => {
        const schedulingRule = this.value();
        if (!schedulingRule) return;

        // set some values when others change
        if (
          schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Once ||
          schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Always
        ) {
          schedulingRule.each = undefined;
          schedulingRule.hour = undefined;
          schedulingRule.minute = undefined;
          schedulingRule.patternIncludes = undefined;
          this.setValue(schedulingRule);
        }
        if (schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Daily) {
          schedulingRule.each = undefined;
          schedulingRule.patternIncludes = undefined;
          this.setValue(schedulingRule);
        }
        if (schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Businessdays) {
          schedulingRule.patternIncludes = undefined;
          this.setValue(schedulingRule);
        }
      },
      { allowSignalWrites: true }
    );
  }

  public setDate(propName: "startTime" | "endTime", date: string): void {
    const value = this.value();
    value[propName] = parseISO(date).valueOf();
    this.setValue(value);
  }

  protected setProp(propName: string, value: any): void {
    const schedulingRule: any = this.value();
    schedulingRule[propName] = value;
    this.setValue(schedulingRule);
  }
}
