import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Renderer2,
  ViewContainerRef
} from "@angular/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, from } from "rxjs";
import { first } from "rxjs/operators";
import { ErrorMessageHandler } from "../services/error-message-handler";
import { TRANSLATION_HELPER, TranslationHelper } from "../services/translation-helper.service";

/**
 * This directive accepts a promise or observable as input. If clicked, it sets the host element to disabled and executes the input. Please keep in mind that the execution context of the observable/promise is the loading-element directive. If you need to access `this` in your code, please bind it manually or provide an error function instead.
 */

@Directive({
  selector: "[loadingFn]",
  exportAs: "loadingFn",
  standalone: true
})
export class LoadingElementDirective implements OnInit {
  @Input()
  public loadingFn: () => Observable<unknown> | Promise<unknown>;

  @Input()
  public set isLoading(isLoading: boolean) {
    if (isLoading !== this.#isLoading) this.setLoading(isLoading);
  }

  #isLoading: boolean = false;
  private spinnerNode: any;
  private originalStyles: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private matSnackBar: MatSnackBar,
    private errorMessageHandler: ErrorMessageHandler,
    private viewContainerRef: ViewContainerRef,
    @Optional() @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper
  ) {}

  public ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, "cursor", "pointer");
    this.renderer.setStyle(this.elementRef.nativeElement, "position", "relative");
  }

  @HostListener("click", ["$event"])
  public click(event?: Event): boolean {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    if (this.elementRef?.nativeElement?.classList?.contains("btn-disabled")) return false;

    if (typeof this.loadingFn !== "function")
      throw new Error(
        "loadingFn should be a function that returns a Promise or an Observable, got typeof " +
          typeof this.loadingFn +
          " instead!"
      );

    if (this.#isLoading) return false;

    this.setLoading(true);

    void from(this.loadingFn())
      .pipe(first())
      .subscribe(
        () => {
          this.setLoading(false);
        },
        (error) => {
          // if exception did not get handled property, show default snack bar
          const errorMessage = this.translationHelper
            ? this.translationHelper.translate(this.errorMessageHandler.handleMessage(error), { showMissingKey: true })
            : this.errorMessageHandler.handleMessage(error);
          this.matSnackBar.open(errorMessage, "Dismiss", {
            panelClass: "error",
            duration: 2500
          });
          this.setLoading(false);
          throw error;
        }
      );

    return false;
  }

  private setLoading(loading: boolean) {
    this.#isLoading = loading;
    const isDaisyButton =
      this.elementRef.nativeElement.classList.contains("btn") ||
      this.elementRef.nativeElement.classList.contains("btn-action");
    if (isDaisyButton) {
      if (loading) {
        this.elementRef.nativeElement.classList.add("btn-disabled");
        this.spinnerNode = this.renderer.createElement("span");
        this.spinnerNode.classList.add("loading", "loading-bars", "absolute");
        this.renderer.insertBefore(
          this.elementRef.nativeElement,
          this.spinnerNode,
          this.elementRef.nativeElement.firstChild
        );
        this.renderer.setAttribute(this.elementRef.nativeElement, "data-loading", "true");
      } else {
        this.elementRef.nativeElement.classList.remove("btn-disabled");
        this.renderer.removeChild(this.elementRef.nativeElement, this.spinnerNode);
        this.renderer.removeAttribute(this.elementRef.nativeElement, "data-loading");
      }
    } else console.warn("loadingFn directive is only supported on daisy buttons");
  }

  private createSpinner(): MatProgressSpinner {
    const componentRef = this.viewContainerRef.createComponent(MatProgressSpinner);
    const spinner = componentRef.instance;
    spinner.mode = "indeterminate";
    spinner.strokeWidth = 3;
    spinner.diameter = 24;
    this.renderer.setStyle(spinner._elementRef.nativeElement, "display", "none");
    this.renderer.setStyle(spinner._elementRef.nativeElement, "position", "absolute");
    this.renderer.setStyle(spinner._elementRef.nativeElement, "top", "calc(50% - 12px)");
    this.renderer.setStyle(spinner._elementRef.nativeElement, "left", "calc(50% - 12px)");
    this.renderer.setAttribute(spinner._elementRef.nativeElement, "data-loading", "true");
    return spinner;
  }
}
