import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { ConfigurationDto, TodoDto } from "@smallstack/axios-api-client";
import { TodoBillingPrice, TodoBillingService } from "@smallstack/todo-shared";
import { TYPE_CONFIGURATIONS } from "@smallstack/typesystem";
import { injectRxEntityStore } from "@smallstack/typesystem-client";

@Component({
  selector: "smallstack-todo-billing",
  templateUrl: "./todo-billing.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoBillingComponent {
  protected configurationStore = injectRxEntityStore<ConfigurationDto>({
    typeDescriptor: { typePath: TYPE_CONFIGURATIONS }
  });

  protected prices = computed<TodoBillingPrice[]>(() => {
    const configurations = this.configurationStore.getMany();
    const todo = this.todo();
    if (todo) return this.todoBillingService.getBilling(todo.billingPositions, configurations);
    else return [];
  });

  public todo = input<TodoDto>();

  constructor(private todoBillingService: TodoBillingService) {}
}
