/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SocketDto
 */
export interface SocketDto {
    /**
     * 
     * @type {string}
     * @memberof SocketDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SocketDto
     */
    type: SocketDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SocketDto
     */
    direction: SocketDtoDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SocketDtoTypeEnum {
    Number = 'number',
    Numberarray = 'numberarray',
    String = 'string',
    Stringarray = 'stringarray',
    Boolean = 'boolean',
    Booleanarray = 'booleanarray',
    Object = 'object',
    Objectarray = 'objectarray',
    Undefined = 'undefined'
}
/**
    * @export
    * @enum {string}
    */
export enum SocketDtoDirectionEnum {
    In = 'in',
    Out = 'out'
}



