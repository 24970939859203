import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const Microsoft365FilesWidget: WidgetRegistryEntry = {
  name: "MicrosoftFiles",
  configuration: {
    templateName: "Microsoft 365 Dateien",
    icon: "skydrive",
    templateDescription: "Zeigt Microsoft 365 / Sharepoint / OneDrive Dateien an",
    dataSchema: {
      type: "object",
      properties: {
        drivePath: {
          type: "string",
          title: "Microsoft 365 Drive",
          description:
            "Geben Sie hier ein Sharepoint Drive oder MS 365 Gruppe an. Bitte stellen Sie sicher, dass alle Beteiligten Zugriff auf diesen Ordner haben. Der Zugriff erfolgt mit dem jeweiligen eingeloggten Benutzerkonto."
        },
        itemId: {
          type: "string",
          title: "Microsoft 365 Unterordner ID",
          description:
            "Geben Sie hier eine Sharepoint Referenz an, welche als Unterordner geöffnet werden soll. Leer lassen für oberste Ebene!"
        },
        showAsList: {
          type: "boolean",
          title: "Als Liste anzeigen"
        }
      }
    },
    tags: [WidgetTag.CMS, WidgetTag.CBO, WidgetTag.APPLICATION, WidgetTag.DASHBOARD]
  },
  component: () => import("./microsoft365-files-widget.component").then((m) => m.Microsoft365FilesWidgetComponent)
};
