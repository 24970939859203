import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { BooleanEquationOperator, getDataByJsonPath } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "CompareValueContainer",
  templateName: "Eigenschaftsvergleich",
  templateDescription:
    "Vergleicht eine Eigenschaft mit einem festen Wert und zeigt basierend auf dem Ergebnis ein weiteres Widget an",
  icon: "compare",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      contextProperty: {
        type: "string",
        title: "Kontext Variable",
        description:
          "Kontext Variable, auf deren Basis entschieden wird, welche der unten aufgelisteten Komponenten angezeigt wird."
      },
      trueComponent: {
        title: "Eigenschaft stimmt überein",
        description: "Komponente welche angezeigt wird, falls die Eigenschaft mit dem Vergleichswert übereinstimmt.",
        type: "object",
        "x-schema-form": { widget: "cms" }
      },
      falseComponent: {
        title: "Eigenschaft stimmt nicht überein",
        description: "Komponente welche angezeigt wird, falls die Eigenschaft nicht übereinstimmt.",
        type: "object",
        "x-schema-form": { widget: "cms" }
      },
      compareToType: {
        type: "string",
        title: "Vergleichswert Typ",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          values: [
            {
              label: "Zeichenkette",
              value: "string"
            },
            {
              label: "Ja / Nein",
              value: "boolean"
            }
          ]
        }
      },
      compareToValue: {
        title: "Wert",
        type: "string",
        "x-schema-form": {
          rules: [
            {
              action: "extendSchema",
              schema: { type: "boolean" },
              if: {
                dataPath: "../compareToType",
                value: "boolean",
                operator: BooleanEquationOperator.EQUALS
              }
            }
          ]
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-compare-value-container-widget",
  templateUrl: "./compare-value-container-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareValueContainerWidgetComponent extends BaseWidgetComponent {
  public hasValue = computed(() => {
    const context = this.context();
    const data = this.data();
    if (context && data?.contextProperty && data?.compareToValue) {
      const contextProperty = getDataByJsonPath(context, data.contextProperty);
      return contextProperty === data.compareToValue;
    }
    return false;
  });
}
