/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */

import { decode, encode } from "base-64";
import { isNumberString } from "class-validator";
import merge from "deepmerge";
import { JSONSchema7 } from "json-schema";
import { isEmptyString } from "./string-utils";

/** returns true for `{}` */
export function isEmptyObject(obj: any): boolean {
  if (obj === null || obj === undefined || typeof obj !== "object" || Array.isArray(obj)) return false;
  return Object.keys(obj).length === 0;
}

export function getJsonByPath(json: any, path: string): any {
  if (!json) return undefined;
  if (typeof path !== "string" || isEmptyString(path)) return json;
  let currentJson = cloneObject(json);
  const singlePaths = path.split(".");
  for (const currentPath of singlePaths) {
    if (Array.isArray(currentJson)) {
      if (isNumberString(currentPath)) currentJson = currentJson[parseInt(currentPath)];
      else if (currentPath.startsWith("[") && currentPath.endsWith("]") && currentPath.includes("=")) {
        const arrayFind = currentPath.substring(1, currentPath.length - 1).split("=");
        currentJson = currentJson.find((entry) => "" + entry[arrayFind[0]] === arrayFind[1]);
      } else
        currentJson = currentJson
          .map((value) => {
            return getJsonByPath(value, currentPath);
          })
          .flat()
          .filter((v) => v !== undefined && currentJson !== null);
    } else if (currentJson !== undefined && currentJson !== null) currentJson = currentJson[currentPath];
    if (currentJson === undefined) return undefined;
  }
  return currentJson;
}

export function getParentJson(json: any, path: string, depth: number = 1): any {
  let parentJson: any;
  for (let i = 0; i < depth; i++) {
    if (!path) return json;
    if (!path.includes(".")) return json;
    path = path.substring(0, path.lastIndexOf("."));
    parentJson = getJsonByPath(json, path);
  }
  return parentJson;
}

export function getRelativePath(path: string, relativePath: string): string {
  if (relativePath.startsWith("./")) return path + "." + relativePath.substring(2);
  if (relativePath.startsWith("../")) {
    do {
      relativePath = relativePath.substring(3);
      path = path.substring(0, path.lastIndexOf("."));
    } while (relativePath.startsWith("../"));
    if (path) return path + "." + relativePath;
    return relativePath;
  }
  return path;
}

/** replaces the given property in the given json and returns it. You can use array.1.bla to manipulate an array */
export function setJsonProperty(json: any, path: string, value: any): any {
  if (!json) json = {};
  if (path === undefined || path === "") {
    return value;
  } else {
    json = cloneObject(json);
    let currentJson = json;
    const singlePaths = path.split(".");
    let index = 0;
    for (const currentPath of singlePaths.slice(0, singlePaths.length - 1)) {
      if (Array.isArray(currentJson)) {
        if (isNumberString(currentPath)) currentJson = currentJson[parseInt(currentPath)];
        else if (currentPath.startsWith("[") && currentPath.endsWith("]") && currentPath.includes("=")) {
          const arrayFind = currentPath.substring(1, currentPath.length - 1).split("=");
          const elem = currentJson.find((entry) => "" + entry[arrayFind[0]] === arrayFind[1]);
          if (elem) currentJson = elem;
          else {
            currentJson.push({ [arrayFind[0]]: arrayFind[1] });
            currentJson = currentJson[currentJson.length - 1];
          }
        } else
          currentJson = currentJson
            .map((value) => {
              return getJsonByPath(value, currentPath);
            })
            .flat()
            .filter((v) => v !== undefined);
      } else {
        if (currentJson !== undefined && currentJson[currentPath]) currentJson = currentJson[currentPath];
        else {
          const isNextPathAnArray = singlePaths[index + 1] && singlePaths[index + 1].startsWith("[");
          currentJson[currentPath] = isNextPathAnArray ? [] : {};
          currentJson = currentJson[currentPath];
        }
      }
      index++;
    }
    if (value === undefined) {
      if (Array.isArray(currentJson)) currentJson.splice(parseInt(singlePaths[singlePaths.length - 1]), 1);
      else delete currentJson[singlePaths[singlePaths.length - 1]];
    } else currentJson[singlePaths[singlePaths.length - 1]] = value;
    return json;
  }
}

export function removeJsonByPath(json: any, path: string): any {
  return setJsonProperty(json, path, undefined);
}

/** returns true if property is not undefined */
export function hasJsonProperty(json: any, path: string): boolean {
  const data = getJsonByPath(json, path);
  return !!data;
}

interface FlattenJsonOptions {
  /**
   * shall arrays be flattened as well?
   * @default true
   */
  flattenArrays?: boolean;
  /** will stop flattening when a key in the next object starts with $, this is useful for mongo queries that need dot notation (like mango/RxDB) */
  no$KeyFlattening?: boolean;
}

/**
 * Creates a very flat array by using dot notation instead of sub objects
 *
 * @param data Pass an object that you want to flatten
 * @param flattenArrays Defaults to true. If set to false, arrays will be kept as they are. Please notice, that flattened objects with unflattened arrays cannot use the function `unflattenJson`.
 * @returns
 */
export function flattenJson(data: any, options: FlattenJsonOptions = { flattenArrays: true }): any {
  if (!data) return data;
  const result: any = {};
  if (typeof data.toJSON === "function") data = data.toJSON();
  if (typeof data.toJson === "function") data = data.toJson();

  function recurse(current: any, prop: string) {
    if (Object(current) !== current) {
      result[prop] = current;
    } else if (Array.isArray(current)) {
      if (options?.flattenArrays === true) {
        for (let i = 0, l = current.length; i < l; i++) recurse(current[i], `${prop}.${i}`);
      } else {
        result[prop] = current;
      }
    } else {
      let isEmpty = true;
      for (const p in current) {
        if (current.hasOwnProperty(p)) {
          if (p.startsWith("$") && options?.no$KeyFlattening === true) {
            result[prop] = current;
            return;
          }
          isEmpty = false;
          recurse(current[p], prop ? `${prop}.${p}` : p);
        }
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
}

export function unFlattenJson(data: any): any {
  if (Object(data) !== data || Array.isArray(data)) return data;
  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g;
  const resultholder: any = {};
  for (const p in data) {
    if (data.hasOwnProperty(p)) {
      let cur: any = resultholder;
      let prop = "";
      let m;
      // eslint-disable-next-line no-cond-assign
      while ((m = regex.exec(p))) {
        cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
        prop = m[2] || m[1];
      }
      cur[prop] = data[p];
    }
  }
  return convertDictionaryArrays(resultholder[""] || resultholder);
}

export function searchJsonUpwards(json: any, currentPath: string, searchPath: string): any {
  const currentJson = getJsonByPath(json, currentPath);
  // root?
  if (!currentPath.includes(".")) {
    if (getJsonByPath(currentJson, searchPath) !== undefined) return json;
    else return undefined;
  }
  currentPath = currentPath.substring(0, currentPath.lastIndexOf("."));
  if (getJsonByPath(currentJson, searchPath)) return currentJson;
  return searchJsonUpwards(json, currentPath, searchPath);
}

export function getDataByJsonPath(
  data: any,
  path: string
): string | string[] | number | number[] | boolean | boolean[] {
  // normalize path
  path = path.replace(/properties\./g, "");
  return flattenJson(data)[path];
}

/**
 * Converts a schema path (properties.address.properties.street) to a data path (address.street)
 */
export function convertJsonSchemaPathToDataPath(schemaPath: string): string {
  return schemaPath?.replace(/properties\./g, "").replace(/#/g, ".");
}

/**
 * Converts a dot notation path (e.g. address.street) to a schema path (properties.address.properties.street)
 */
export function convertDotNotationPathToJsonSchemaPath(dataPath: string): string {
  if (!dataPath) return "";
  return dataPath
    .split(".")
    .map((split) => {
      // Array qualifier?
      if (isNumberString(split)) return `items`;
      return `properties.${split}`;
    })
    .join(".");
}

/**
 * returns the last portion of the schema path
 */
export function getLastPropertyName(schemaPath: string): string {
  if (!schemaPath) return schemaPath;
  if (!schemaPath.includes(".")) return schemaPath;
  return schemaPath.substring(schemaPath.lastIndexOf(".") + 1);
}

export function removeLastPropertyName(path: string): string {
  if (!path || !path.includes(".")) return undefined;
  return path.substring(0, path.lastIndexOf("."));
}

/**
 * removes keys that have an undefined or null value
 */
export function filterNullOrUndefined(obj: any): any {
  const clonedObj = cloneObject(obj);
  Object.keys(clonedObj).forEach((k) => {
    if (clonedObj[k] && typeof clonedObj[k] === "object") clonedObj[k] = filterNullOrUndefined(clonedObj[k]);
    else if (clonedObj[k] === null || clonedObj[k] === undefined) delete clonedObj[k];
  });
  return clonedObj;
}

/** converts dictionary arrays to normal arrays, also works for nested arrays */
export function convertDictionaryArrays(obj: any): any {
  obj = cloneObject(obj);
  for (const key of Object.keys(obj)) {
    if (typeof key === "string") {
      if (typeof obj[key] === "object") {
        // check if all sub keys are numbers
        let onlyNumbers = true;
        if (obj[key]) {
          for (const subKey of Object.keys(obj[key])) {
            if (!isNumberString(subKey)) onlyNumbers = false;
          }
          if (onlyNumbers) obj[key] = Object.values(obj[key]);
          obj[key] = convertDictionaryArrays(obj[key]);
        }
      }
    }
  }
  return obj;
}

/**
 * Clones an object via structuredClone
 */
export function cloneObject<T = any>(obj: T): T {
  if (obj === undefined || obj === null) return obj;
  try {
    return structuredClone(obj);
  } catch (e) {
    // structuredClone usually fails if objects contain proxies
  }
  if (obj !== undefined && obj !== null) return JSON.parse(safeStringify(obj));
  return obj;
}

/** Clones an object via JSON.parse(JSON.stringify(obj)) which removes all references, functions and other non-serializable objects */
export function cloneJson(obj: any): any {
  if (obj !== undefined && obj !== null) return JSON.parse(safeStringify(obj));
  return obj;
}

/** stringifies without circular references */
export function safeStringify(obj: any, indent: number = 2): string {
  let cache: any[] = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === "object" && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return retVal;
}

/** generates a list of all known schema properties */
export function getSchemaPropertiesKeys(
  schema: JSONSchema7,
  options?: { subPath?: string; includeObjectRoots?: boolean }
) {
  if (!schema) return [];
  schema = cloneObject(schema);
  if (schema.properties) {
    let properties = Object.keys(schema.properties).map((val) =>
      options?.subPath ? options.subPath + "." + val : val
    );

    for (const propName in schema.properties) {
      if (schema.properties[propName] && (schema.properties[propName] as JSONSchema7).properties)
        properties.push(
          ...getSchemaPropertiesKeys(schema.properties[propName] as JSONSchema7, {
            subPath: propName,
            includeObjectRoots: options?.includeObjectRoots
          })
        );
    }

    // filter properties of type object if includeObjectRoots is false
    if (options?.includeObjectRoots === false)
      properties = properties.filter((prop) => {
        return properties.find((p) => p.startsWith(prop + ".")) === undefined;
      });
    return properties;
  } else return [];
}
/** generates a list of all known schema properties for a given value */
export function getSchemaPropertiesKeysForValue(schema: JSONSchema7, value: any, subPath?: string) {
  if (!schema) return [];
  if (schema.properties) {
    const properties: string[] = [];
    for (const propName in schema.properties) {
      const currentPropName = subPath ? subPath + "." + propName : propName;
      properties.push(currentPropName);
      const subSchema = schema.properties[propName] as JSONSchema7;
      const data = getJsonByPath(value, propName);
      if (subSchema && subSchema.type === "object" && subSchema.properties)
        properties.push(...getSchemaPropertiesKeysForValue(subSchema, data, currentPropName));
      else if (subSchema.type === "array" && subSchema.items && Array.isArray(data))
        for (let i = 0; i < data.length; i++) {
          properties.push(
            ...getSchemaPropertiesKeysForValue(subSchema.items as JSONSchema7, data[i], currentPropName + "." + i)
          );
        }
    }
    return properties;
  } else return [];
}

/**
 * deeply merges two objects
 *
 * @param options combineArray: if true, arrays will be combined at their index instead concatenated, defaults to false
 * */
export function mergeObjects(
  obj1: any,
  obj2: any,
  options?: { mergeArrayMethod?: "combine" | "replace" | "concat" }
): any {
  if (obj1 === undefined || obj1 === null) return obj2;
  if (obj2 === undefined || obj2 === null) return obj1;
  const mergeArrayMethod = options?.mergeArrayMethod || "concat";
  if (mergeArrayMethod === "combine") {
    const combineMerge = (target: any, source: any, options: any) => {
      const destination = target.slice();

      source.forEach((item: any, index: number) => {
        if (typeof destination[index] === "undefined") {
          destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
        } else if (options.isMergeableObject(item)) {
          destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    };
    return merge(obj1, obj2, { arrayMerge: combineMerge });
  } else if (mergeArrayMethod === "replace") {
    const overwriteMerge = (destinationArray: any[], sourceArray: any[]) => sourceArray;
    return merge(obj1, obj2, { arrayMerge: overwriteMerge });
  }
  return merge(obj1, obj2);
}

/** serializes and encodes an object into an url safe string  */
export function serializeObjectToUrlParam(obj: any): string {
  return encodeURIComponent(encode(JSON.stringify(obj)));
}

/** deserializes and decodes a string into an object */
export function deserializeObjectFromUrlParam(str: string): any {
  return JSON.parse(decode(decodeURIComponent(str)));
}

export function isJsonString(s: string): boolean {
  if (!s || typeof s !== "string") return false;
  try {
    JSON.parse(s);
  } catch (e) {
    return false;
  }
  return true;
}

export function extractFromString(str: string, startingString: string): string {
  let currentlyOpeningBraces = 0;
  let currentlyClosingBraces = 0;
  let output = "";

  const start = str.indexOf(startingString) + startingString.length;

  const splitString = str.split("");
  for (let i = start; i < splitString.length; i++) {
    const char = splitString[i];
    if (char === "{") currentlyOpeningBraces++;
    if (char === "}") currentlyClosingBraces++;
    output += str.substring(i, i + 1);
    if (currentlyOpeningBraces === currentlyClosingBraces) break;
  }
  return output;
}

/** Finds a sub object by key=value and replaces that object with `replaceWith` */
export function replaceJsonSubObjectByKeyValue(json: any, key: string, value: any, replaceWith: any) {
  if (typeof json !== "object") return json;
  let newJson = cloneObject(json);
  for (const k in newJson) {
    if (newJson.hasOwnProperty(k)) {
      if (k === key && newJson[k] === value) {
        newJson = replaceWith;
        return newJson;
      } else if (typeof newJson[k] === "object")
        newJson[k] = replaceJsonSubObjectByKeyValue(newJson[k], key, value, replaceWith);
    }
  }
  return newJson;
}
