@if (!loading) {
  <div class="content">
    <smallstack-form [schema]="schema" [(value)]="model">
      <smallstack-schema-form-table [showHeader]="false" [showMissingFields]="false"></smallstack-schema-form-table>
    </smallstack-form>
    <div class="top-margin flex flex-row justify-center items-center">
      <button [loadingFn]="createNewData()" class="btn btn-primary"
        ><smallstack-i18n data="@@actions.create"></smallstack-i18n>
      </button>
    </div>
  </div>
} @else {
  <smallstack-loader></smallstack-loader>
}
