import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, computed, effect, input, signal } from "@angular/core";
import { ExtensionSlotDto, WidgetDto } from "@smallstack/axios-api-client";
import { CustomizationModeStore, LetDirective, LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { TYPE_EXTENSION_SLOTS } from "@smallstack/typesystem";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { Observable, map } from "rxjs";
import { WidgetTreeComponent } from "../widget-tree/widget-tree.component";

/**
 * @example
 * <smallstack-extension-slot name="product-detail-top" [context]="{product: product}"></smallstack-extension-slot>
 */
@Component({
  selector: "smallstack-extension-slot",
  templateUrl: "./extension-slot.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, WidgetTreeComponent, IconComponent, I18nComponent, LoadingElementDirective, LetDirective]
})
export class ExtensionSlotComponent {
  private extensionSlotStore = injectRxEntityStore<ExtensionSlotDto>({
    typeDescriptor: { typePath: TYPE_EXTENSION_SLOTS }
  });

  /**
   * This defines the name of the slot as which the extension slot entity will be saved. When the page loads, all saved extension slots with this name will be loaded into the component
   */
  public readonly slotName = input<string>(undefined, { alias: "name" });

  @Input()
  public widgetTags: string[];

  /**
   * Will be forwarded to each widget tree's context inside this slot. Should contain context relevant data like currentProduct or currentUser
   */
  public readonly context = input<any>(undefined);
  public readonly globals = input<any>(undefined);

  @Input()
  public editMode: boolean;

  @Input()
  public emptyMessage: string;

  public editMode$: Observable<boolean> = this.customizationModeStore.value$.pipe(
    map((customizationModeStoreValue) => {
      if (this.editMode !== undefined) return this.editMode;
      return customizationModeStoreValue;
    })
  );

  /** Deprecated slots will only be shown if they already have content */
  @Input()
  public deprecated: boolean = false;

  private readonly storedExtensions = computed(() => this.extensionSlotStore.getMany({ name: this.slotName() }));
  protected readonly extensions = signal<ExtensionSlotDto[]>([]);

  protected hasChanges = computed(() => {
    return this.storedExtensions().length !== this.extensions().length;
  });

  constructor(
    private notificationService: NotificationService,
    private customizationModeStore: CustomizationModeStore
  ) {
    effect(
      () => {
        if (this.slotName()) this.extensions.set(this.storedExtensions());
        else this.extensions.set([]);
      },
      { allowSignalWrites: true }
    );
  }

  public createNewSlot(): void {
    if (this.slotName()) {
      this.extensions.update((currentExtensions) => {
        currentExtensions.push({ component: undefined, name: this.slotName() });
        return currentExtensions;
      });
    }
  }

  public async cmsComponentChange(extension: ExtensionSlotDto, cmsComponentDto: WidgetDto): Promise<void> {
    // delete extension if no cmsComponentDto is given
    if (cmsComponentDto === undefined) {
      if (extension.id)
        this.extensions.update((current) => {
          return current.filter((ext) => ext.id !== extension.id);
        });
    }
    // add extension if it is a new one
    else {
      // add extension to the editing ones
      this.extensions.update((extensions) => {
        const existing = extensions.find((ext) => ext.id === extension.id);
        if (existing) {
          existing.component = cmsComponentDto;
          return extensions;
        }
        return [...extensions, { ...extension, component: cmsComponentDto }];
      });
    }

    // save everything immediately if editMode is false
    if (this.editMode === false) await this.saveSlot()();
  }

  public saveSlot() {
    return async (): Promise<void> => {
      const storedExtensions = this.storedExtensions();
      const extensions = this.extensions();

      // delete slots
      for (const storedExtension of storedExtensions) {
        if (!extensions.find((ext) => ext.id === storedExtension.id))
          await this.notificationService.handlePromise(this.extensionSlotStore.deleteEntity(storedExtension.id));
      }

      // save the rest
      for (const extension of extensions) {
        if (extension.id) await this.notificationService.handlePromise(this.extensionSlotStore.updateEntity(extension));
        else await this.notificationService.handlePromise(this.extensionSlotStore.createEntity(extension));
      }
    };
  }

  public discard() {
    return async (): Promise<void> => {
      this.extensions.set(this.storedExtensions());
    };
  }
}
