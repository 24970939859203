import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { DateComponent } from "./components/date/date.component";
import { FlagComponent } from "./components/flag/flag.component";
import { HumanizedDateRangeComponent } from "./components/humanized-date-range/humanized-date-range.component";
import { HumanizedTimeComponent } from "./components/humanized-time/humanized-time.component";
import { I18nComponent } from "./components/i18n/i18n.component";
import { InlineTranslationEditorComponent } from "./components/inline-translation-editor/inline-translation-editor.component";
import { LocaleSelectorComponent } from "./components/locale-selector/locale-selector.component";
import { NotificationDialogComponent } from "./components/notification-dialog/notification-dialog.component";
import { CurrencyComponent } from "./components/smallstack-currency/smallstack-currency.component";
import { TranslationsPopoverComponent } from "./components/translations-popover/translations-popover.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    SmallstackThemeModule,
    CurrencyComponent,
    FlagComponent,
    InlineTranslationEditorComponent,
    TranslationsPopoverComponent,
    I18nComponent,
    DateComponent,
    NotificationDialogComponent,
    LocaleSelectorComponent,
    HumanizedTimeComponent,
    HumanizedDateRangeComponent
  ],
  exports: [
    FlagComponent,
    DateComponent,
    I18nComponent,
    LocaleSelectorComponent,
    NotificationDialogComponent,
    TranslationsPopoverComponent,
    InlineTranslationEditorComponent,
    CurrencyComponent,
    HumanizedTimeComponent,
    HumanizedDateRangeComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "de" }]
})
export class SmallstackI18nModule {}
