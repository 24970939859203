@if (translations?.length > 1) {
  <smallstack-icon alias="translation" size="24" (mouseover)="overlay.openOverlay()"> </smallstack-icon>
}
<smallstack-overlay #overlay>
  <mat-card appearance="outlined" class="popover" style="max-width: 420px">
    @if (translations && localeStore) {
      <mat-card-content>
        <div class="flex flex-col items-start">
          @for (locale of localeStore.value$ | async; track locale) {
            <div>
              <div class="flex flex-row flex-wrap items-center">
                <div class="mb-4" style="display: inline-block">
                  <smallstack-flag class="mr-8" [locale]="locale.iso"> </smallstack-flag>
                  <span class="mat-caption">{{ getTranslatedString(locale.iso).substring(0, 100) }}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </mat-card-content>
    }
  </mat-card>
</smallstack-overlay>
