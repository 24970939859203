@if (data().showAsButton !== true) {
  <a
    (click)="openLink()"
    [ngStyle]="{
      cursor: data().link ? 'pointer' : 'unset',
      textDecoration: data().link && data().underlineLinks === true ? 'underline' : 'unset'
    }"
  >
    <smallstack-widget-child
      property="linkedWidget"
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
    >
    </smallstack-widget-child>
  </a>
} @else {
  <button (click)="openLink()" class="btn btn-outline btn-primary">
    <smallstack-widget-child
      property="linkedWidget"
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
    >
    </smallstack-widget-child>
  </button>
}
<ng-template #showAsButton>
  <button (click)="openLink()" class="btn btn-outline btn-primary">
    <smallstack-widget-child
      property="linkedWidget"
      [data]="data()"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
    >
    </smallstack-widget-child>
  </button>
</ng-template>
