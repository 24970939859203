import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { I18nComponent } from "@smallstack/i18n-components";

@Component({
  selector: "smallstack-divider",
  templateUrl: "./divider.component.html",
  styleUrls: ["./divider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatDividerModule, I18nComponent]
})
export class DividerComponent {
  /**
   * Can be a normal text or a `@@` prefixed translation key
   */
  @Input()
  public text: string;
}
