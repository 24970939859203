import { Inject, Optional, Pipe, PipeTransform } from "@angular/core";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TranslationHelper, TRANSLATION_HELPER } from "../services/translation-helper.service";

/**
 * This pipe can be used if the @smallstack/i18n packages cannot be used directly (e.g. due circular dependencies). It
 * translates values only once, so its not as great as the `<smallstack-i18n>` component since it does not react to
 * locale changes etc.
 */
@Pipe({ name: "translate", standalone: true })
export class TranslatePipe implements PipeTransform {
  constructor(@Optional() @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper) {}

  public transform(data: string | InlineTranslation): string {
    if (!this.translationHelper)
      throw new Error(
        "No TRANSLATION_HELPER provider found. If you want to use the translation pipe, please provide a translationHelper provider."
      );
    return this.translationHelper.translate(data);
  }
}
