import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { IconComponent } from "@smallstack/theme-components";
import { v4 } from "uuid";
import { WidgetTreeService } from "../../services/widget-tree.service";
import {
  AddNewWidgetDialogComponent,
  AddNewWidgetDialogData
} from "./add-new-widget-dialog/add-new-widget-dialog.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-add-widget",
  templateUrl: "./add-widget.component.html",
  styleUrls: ["./add-widget.component.scss"],
  standalone: true,
  imports: [IconComponent, MatDialogModule]
})
export class AddWidgetComponent {
  @Input()
  public widgetTags: string[];

  @Output()
  public readonly addWidget: EventEmitter<WidgetDto> = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    public widgetTreeService: WidgetTreeService
  ) {}

  public openWidgetLibrary(): void {
    this.matDialog.open(AddNewWidgetDialogComponent, {
      width: "70vw",
      data: {
        allowedWidgetTags: this.widgetTags || this.widgetTreeService.widgetTags,
        saveFn: async (newWidget: WidgetDto) => {
          if (newWidget) {
            if (!newWidget.id) newWidget.id = v4();
            this.addWidget.emit(newWidget);
          }
        }
      } as AddNewWidgetDialogData
    });
  }
}
