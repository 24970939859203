import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from "@angular/core";
import { TranslationStore } from "@smallstack/i18n-components";
import { DataType, SmallstackTypeFilterOptions } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { isNonEmptyString } from "@smallstack/utils";
import { BehaviorSubject, Observable, map, switchMap } from "rxjs";

@Component({
  selector: "smallstack-type-select",
  templateUrl: "./type-select.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeSelectComponent {
  private translationStore = inject(TranslationStore);
  public typeFilter$: BehaviorSubject<SmallstackTypeFilterOptions> = new BehaviorSubject(undefined);

  public availableTypes$: Observable<DataType[]> = this.typeFilter$.pipe(
    switchMap(async (typeFilter: SmallstackTypeFilterOptions) => {
      return typeFilter ? this.typeService.filterTypes(typeFilter) : this.typeService.getAll();
    }),
    map((types) => {
      // sort types by name
      return types.sort((a, b) =>
        this.translationStore.translate(a.title)?.localeCompare(this.translationStore.translate(b.title))
      );
    })
  );

  public allTypesShown: boolean = false;

  @Input()
  public set typeFilter(typeFilter: SmallstackTypeFilterOptions) {
    this.typeFilter$.next(typeFilter);
  }

  @Input()
  public usePlural: boolean = false;

  @Input()
  public set selectedTypePath(typePath: string) {
    if (typePath && isNonEmptyString(typePath) && typePath !== this.typePath)
      void this.typeService.getTypeByPath(typePath).then((type) => {
        this.typePath = type?.path;
        this.cdr.detectChanges();
      });
  }

  @Output()
  public readonly selectedTypePathChange: EventEmitter<string> = new EventEmitter();

  protected typePath: string;

  constructor(
    private typeService: TypeService,
    private cdr: ChangeDetectorRef
  ) {}

  public selectType(typePath: string): void {
    this.typePath = typePath;
    this.selectedTypePathChange.emit(typePath);
  }

  public showAllTypes(): void {
    this.typeFilter$.next({ ...this.typeFilter$.value, tags: undefined, linkableTypes: undefined });
    this.allTypesShown = true;
  }
}
