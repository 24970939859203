<div class="w-full flex justify-{{ data().align || 'center' }}">
  <div [ngStyle]="{ width: width$ | async, padding: data().padding }" class="flex justify-start">
    <smallstack-widget-child
      [data]="data()"
      property="child"
      (dataChange)="dataChange.emit($event)"
      [context]="context()"
    ></smallstack-widget-child>
  </div>
</div>
