import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslationHelper, TRANSLATION_HELPER } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { BaseWidgetComponent, Widget, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "PageTitle",
  templateName: "Seiten Titel",
  templateDescription: "Setzt den Seiten Titel auf den gegebenen Text",
  icon: "hydrogen",
  dataSchema: {
    type: "object",
    properties: {
      pageTitle: {
        title: "Seitentitel",
        description:
          "Sobald dieses (leere) Widget auf einer Seite platziert wird, ändert sich der Seiten Titel im Browser auf diesen Text",
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        }
      }
    }
  },
  tags: [WidgetTag.APPLICATION, WidgetTag.CBO]
})
@Component({
  selector: "smallstack-page-title-widget",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleWidgetComponent extends BaseWidgetComponent {
  constructor(
    private title: Title,
    @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper
  ) {
    super();
    this.subscription.add(
      this.data$.subscribe((data) => {
        if (data?.pageTitle) this.title.setTitle(this.translationHelper.translate(data.pageTitle));
      })
    );
  }
}
