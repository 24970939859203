import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  AutofocusDirective,
  DebugComponentComponent,
  DecodeUriComponentPipe,
  HighlightDirective,
  LoadingElementDirective,
  MonacoEditorComponent,
  OverlayComponent,
  RangePipe,
  SortPipe,
  TranslatePipe,
  UrlConformPipe
} from "@smallstack/common-components";
import { FileExplorerInputComponent, FilePreviewComponent } from "@smallstack/file-components";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import {
  LoaderComponent,
  StoreContainerComponent,
  StoreModelComponent,
  StoreSelectComponent
} from "@smallstack/store-components";
import { SmallstackTextModule } from "@smallstack/text-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { SmallstackFormCoreModule, SmallstackWidgetsModule } from "@smallstack/widget-core";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ColorPickerModule } from "ngx-color-picker";
import {
  DateEditorComponent,
  FormEditorDialogComponent,
  II18nEditorComponent,
  II18nEditorDialogComponent,
  NotificationConfigurationComponent,
  NotificationConfigurationsComponent,
  TimestampEditorComponent
} from "./components";
import { AutocompleteInputComponent } from "./components/autocomplete-input/autocomplete-input.component";
import { AutocompleteTypeSearchComponent } from "./components/autocomplete-type-search/autocomplete-type-search.component";
import { DatestringEditorComponent } from "./components/datestring-editor/datestring-editor.component";
import { DuplicatesSearchComponent } from "./components/duplicates-search/duplicates-search.component";
import {
  AutocompleteTypeSearchInputWidgetComponent,
  ContextVariableInputComponent,
  DateFormInputComponent,
  MarkdownFormInputComponent,
  PercentageFormInputComponent,
  PriceFormInputComponent,
  SchemaPropertySelectorFormInputComponent,
  StoresFormInputComponent,
  ThemeColorFormInputComponent,
  TimeFormInputComponent,
  TypesFormInputComponent
} from "./components/form-inputs";
import { ArrayFormInputComponent } from "./components/form-inputs/array-form-input/array-form-input.component";
import { AutoCompleteFormInputComponent } from "./components/form-inputs/auto-complete-form-input/auto-complete-form-input.component";
import { CommaSeparatedStringArrayFormInputComponent } from "./components/form-inputs/comma-separated-string-array-form-input/comma-separated-string-array-form-input.component";
import { DatestringFormInputComponent } from "./components/form-inputs/datestring-form-input/datestring-form-input.component";
import { DatetimeFormInputComponent } from "./components/form-inputs/datetime-form-input/datetime-form-input.component";
import { DrawingPadFormInputComponent } from "./components/form-inputs/drawing-pad-form-input/drawing-pad-form-input.component";
import { FileFormInputComponent } from "./components/form-inputs/file-form-input/file-form-input.component";
import { FileUrlFormInputComponent } from "./components/form-inputs/file-url-form-input/file-url-form-input.component";
import { ImageUploadFormInputComponent } from "./components/form-inputs/imageupload-form-input/imageupload-form-input.component";
import { IntegerFormInputComponent } from "./components/form-inputs/integer-form-input/integer-form-input.component";
import { Microsoft365FolderDialogComponent } from "./components/form-inputs/microsoft365-folder-input/microsoft365-folder-dialog/microsoft365-folder-dialog.component";
import { Microsoft365FolderInputComponent } from "./components/form-inputs/microsoft365-folder-input/microsoft365-folder-input.component";
import { MonacoFormComponent } from "./components/form-inputs/monaco-form/monaco-form.component";
import { MultiStringSelectFormInputComponent } from "./components/form-inputs/multi-string-select-form-input/multi-string-select-form-input.component";
import { ObjectFormInputComponent } from "./components/form-inputs/object-form-input/object-form-input.component";
import { SearchQueryFiltersInputComponent } from "./components/form-inputs/search-query-filters-input/search-query-filters-input.component";
import { SearchQueryFormInputComponent } from "./components/form-inputs/search-query-form-input/search-query-form-input.component";
import { SliderFormInputComponent } from "./components/form-inputs/slider-form-input/slider-form-input.component";
import { StoreSelectFormInputComponent } from "./components/form-inputs/store-select-form-input/store-select-form-input.component";
import { StringSelectFormInputComponent } from "./components/form-inputs/stringselect-form-input/stringselect-form-input.component";
import { ThemedIconFormInputComponent } from "./components/form-inputs/themed-icon-form-input/themed-icon-form-input.component";
import { HubspotFormComponent } from "./components/hubspot-form/hubspot-form.component";
import { HubspotMeetingComponent } from "./components/hubspot-meeting/hubspot-meeting.component";
import { TypeSelectComponent } from "./components/type-select/type-select.component";
@NgModule({
  declarations: [
    ArrayFormInputComponent,
    AutocompleteInputComponent,
    AutoCompleteFormInputComponent,
    CommaSeparatedStringArrayFormInputComponent,
    DateEditorComponent,
    DateFormInputComponent,
    DatetimeFormInputComponent,
    DrawingPadFormInputComponent,
    FileFormInputComponent,
    FileUrlFormInputComponent,
    FormEditorDialogComponent,
    HubspotFormComponent,
    HubspotMeetingComponent,
    II18nEditorComponent,
    II18nEditorDialogComponent,
    ImageUploadFormInputComponent,
    IntegerFormInputComponent,
    MonacoFormComponent,
    MultiStringSelectFormInputComponent,
    ObjectFormInputComponent,
    SearchQueryFormInputComponent,
    StoreSelectFormInputComponent,
    StringSelectFormInputComponent,
    TimestampEditorComponent,
    DatestringFormInputComponent,
    ThemedIconFormInputComponent,
    MarkdownFormInputComponent,
    Microsoft365FolderInputComponent,
    Microsoft365FolderDialogComponent,
    SearchQueryFiltersInputComponent,
    TypeSelectComponent,
    AutocompleteTypeSearchComponent,
    StoresFormInputComponent,
    TypesFormInputComponent,
    NotificationConfigurationComponent,
    NotificationConfigurationsComponent,
    SchemaPropertySelectorFormInputComponent,
    ContextVariableInputComponent,
    AutocompleteTypeSearchInputWidgetComponent,
    PriceFormInputComponent,
    TimeFormInputComponent,
    PercentageFormInputComponent,
    ThemeColorFormInputComponent,
    SliderFormInputComponent
  ],
  exports: [
    ArrayFormInputComponent,
    AutocompleteInputComponent,
    AutoCompleteFormInputComponent,
    CommaSeparatedStringArrayFormInputComponent,
    DateEditorComponent,
    DateFormInputComponent,
    DatetimeFormInputComponent,
    DrawingPadFormInputComponent,
    FileUrlFormInputComponent,
    FormEditorDialogComponent,
    HubspotFormComponent,
    HubspotMeetingComponent,
    II18nEditorComponent,
    II18nEditorDialogComponent,
    ImageUploadFormInputComponent,
    IntegerFormInputComponent,
    MultiStringSelectFormInputComponent,
    ObjectFormInputComponent,
    SearchQueryFormInputComponent,
    StoreSelectFormInputComponent,
    StringSelectFormInputComponent,
    TimestampEditorComponent,
    DatestringEditorComponent,
    ThemedIconFormInputComponent,
    MarkdownFormInputComponent,
    DuplicatesSearchComponent,
    SearchQueryFiltersInputComponent,
    TypeSelectComponent,
    AutocompleteTypeSearchComponent,
    StoresFormInputComponent,
    NotificationConfigurationComponent,
    NotificationConfigurationsComponent,
    SchemaPropertySelectorFormInputComponent,
    ContextVariableInputComponent,
    AutocompleteTypeSearchInputWidgetComponent,
    PriceFormInputComponent,
    TimeFormInputComponent,
    ThemeColorFormInputComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
    ColorPickerModule,
    CurrencyMaskModule,
    OverlayModule,
    SmallstackI18nModule,
    SmallstackTextModule,
    SmallstackThemeModule,
    SmallstackFormCoreModule,
    SmallstackWidgetsModule,
    AutofocusDirective,
    RangePipe,
    SortPipe,
    UrlConformPipe,
    LoadingElementDirective,
    HighlightDirective,
    MonacoEditorComponent,
    TranslatePipe,
    DecodeUriComponentPipe,
    FileExplorerInputComponent,
    FilePreviewComponent,
    StoreSelectComponent,
    LoaderComponent,
    StoreModelComponent,
    StoreContainerComponent,
    MatNativeDateModule,
    OverlayComponent,
    DatestringEditorComponent,
    DebugComponentComponent,
    DuplicatesSearchComponent
  ]
})
export class SmallstackFormModule {}
