import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostListener,
  Input,
  TemplateRef,
  computed,
  input
} from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { I18nComponent } from "@smallstack/i18n-components";
import { ObjectStore, PageableCrudStore, PageableStore } from "@smallstack/store";
import { hasTypeSupport } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { getJsonByPath } from "@smallstack/utils";
import { computedAsync } from "ngxtension/computed-async";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-store-property",
  templateUrl: "./store-property.component.html",
  standalone: true,
  imports: [CommonModule, I18nComponent]
})
export class StorePropertyComponent {
  public store = input<ObjectStore | PageableCrudStore>(undefined);
  public storeName = input<string>(undefined);
  public modelId = input<string>(undefined);
  public property = input<string>(undefined);

  @Input("notFound")
  public notFoundText: string;

  @Input()
  public showNotFound = true;

  public notFound: boolean = false;

  @ContentChild(TemplateRef)
  public notFoundTemplate: TemplateRef<any>;

  public state = computedAsync<{ state: "loading" | "loaded"; value?: undefined }>(
    async () => {
      const store = this.storeName()
        ? this.storeRegistry.getStore<PageableStore | PageableCrudStore>(this.storeName())
        : this.store();
      if (!store) return { state: "loaded", value: undefined };

      const modelId = this.modelId();
      if (!modelId) return { state: "loaded", value: undefined };

      const property = this.property();

      let model: any;
      if (store instanceof PageableCrudStore || store instanceof PageableStore) model = await store.get(modelId);
      else throw new Error("StorePropertyComponent: Store is not a PageableStore or PageableCrudStore!");

      if (model) {
        let value: any;
        if ((property === "" || property === undefined || property.includes("$")) && hasTypeSupport(store))
          value = this.typeService.toString(await store.getType(), model);
        else value = getJsonByPath(model, property);
        if (value === undefined) value = modelId + "." + property + " is undefined!";
        return { state: "loaded", value };
      } else {
        this.notFound = true;
        if (!this.notFoundTemplate && !this.notFoundText) this.notFoundText = modelId + " not found/loaded";
        return { state: "loaded", value: modelId + " not found/loaded" };
      }
    },
    { initialValue: { state: "loading" } }
  );

  protected isLoading = computed(() => {
    //
  });

  constructor(
    private storeRegistry: StoreRegistry,
    private typeService: TypeService
  ) {}

  @HostListener("click", ["$event"])
  public async shiftClick(e: MouseEvent): Promise<void> {
    if (e.shiftKey) {
      Logger.info("StorePropertyComponent", "Current State", {
        storeName: this.storeName(),
        store: this.store(),
        modelId: this.modelId(),
        value: this.state()?.value
      });
    }
  }
}
