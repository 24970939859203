import { Directive, computed, inject } from "@angular/core";
import { TypeSchema } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import { getSchemaPropertiesKeys, objectsEqual } from "@smallstack/utils";
import { computedAsync } from "ngxtension/computed-async";
import { Observable, map } from "rxjs";
import { AbstractSchemaFormTable } from "./abstract-schema-form-table";

export interface GroupedSchemaGroup {
  key: string;
  schema: TypeSchema;
  isValid$: Observable<boolean>;
}

@Directive({ standalone: true })
export class AbstractGroupedSchemaFormTable extends AbstractSchemaFormTable {
  protected typeService = inject(TypeService);

  private formGroups = computed(() => {
    return this.formService.getFormGroups();
  });

  protected groups = computedAsync(
    async () => {
      const formGroups = this.formGroups();
      const expandedGroups: GroupedSchemaGroup[] = [];
      for (const group of formGroups) {
        expandedGroups.push(this.getGroup(group));
      }
      const withoutGroup = this.formService.getFormGroup();
      if (withoutGroup && Object.keys(withoutGroup?.properties)?.length > 0) {
        const expandedWithoutGroup = this.getGroup(undefined);
        if (expandedGroups.length === 0) expandedGroups.push(expandedWithoutGroup);
        else
          expandedGroups.push({
            isValid$: expandedWithoutGroup.isValid$,
            key: "Sonstiges",
            schema: await this.typeService.evalRefSchema(expandedWithoutGroup.schema)
          });
      }
      return expandedGroups;
    },
    { initialValue: [], equal: objectsEqual }
  );

  private getGroup(group: string): GroupedSchemaGroup {
    const schema = this.formService.getFormGroup(group);
    const keys = getSchemaPropertiesKeys(schema);

    return {
      key: group,
      schema,
      isValid$: this.formService.validationErrors$.pipe(
        map((validationErrors) => {
          for (const validationKey of Object.keys(validationErrors)) if (keys.includes(validationKey)) return true;
          return false;
        })
      )
    };
  }
}
