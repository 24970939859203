export interface DaisyThemeColor {
  bgColor: string;
  textColor: string;
}

export const DaisyThemeColorSchema = {
  type: "object",
  properties: {
    bgColor: {
      type: "string",
      "x-schema-form": {
        widget: "color"
      },
      minLength: 4,
      maxLength: 9
    },
    textColor: {
      type: "string",
      "x-schema-form": {
        widget: "color"
      },
      minLength: 4,
      maxLength: 9
    }
  },
  required: ["bgColor", "textColor"]
};

export interface DaisyThemeFocusColor {
  bgColor: string;
  textColor: string;
  focusColor: string;
}

export const DaisyThemeFocusColorSchema = {
  type: "object",
  properties: {
    bgColor: {
      type: "string",
      "x-schema-form": {
        widget: "color"
      },
      minLength: 4,
      maxLength: 9
    },
    textColor: {
      type: "string",
      "x-schema-form": {
        widget: "color"
      },
      minLength: 4,
      maxLength: 9
    },
    focusColor: {
      type: "string",
      "x-schema-form": {
        widget: "color"
      },
      minLength: 4,
      maxLength: 9
    }
  },
  required: ["bgColor", "textColor", "focusColor"]
};

export interface DaisyTheme {
  primary: DaisyThemeFocusColor;
  secondary: DaisyThemeFocusColor;
  accent: DaisyThemeFocusColor;
  neutral: DaisyThemeFocusColor;
  base100: string;
  base200: string;
  base300: string;
  baseContent: string;
  info: DaisyThemeColor;
  success: DaisyThemeColor;
  warning: DaisyThemeColor;
  error: DaisyThemeColor;
}

export const DaisyThemeSchema = {
  type: "object",
  properties: {
    primary: {
      additionalProperties: false,
      title: "Primäre Farbe",
      ...DaisyThemeFocusColorSchema
    },
    secondary: {
      additionalProperties: false,
      title: "Sekundäre Farbe",
      ...DaisyThemeFocusColorSchema
    },
    accent: {
      additionalProperties: false,
      title: "Akzent Farbe",
      ...DaisyThemeFocusColorSchema
    },
    neutral: {
      additionalProperties: false,
      title: "Neutrale Farbe",
      ...DaisyThemeFocusColorSchema
    },
    base100: {
      type: "string",
      title: "Basis Farbe 100",
      "x-schema-form": {
        widget: "color"
      }
    },
    base200: {
      type: "string",
      title: "Basis Farbe 200",
      "x-schema-form": {
        widget: "color"
      }
    },
    base300: {
      type: "string",
      title: "Basis Farbe 300",
      "x-schema-form": {
        widget: "color"
      }
    },
    baseContent: {
      type: "string",
      title: "Basis Inhaltsfarbe",
      "x-schema-form": {
        widget: "color"
      }
    },
    info: {
      additionalProperties: false,
      title: "Infofarbe",
      ...DaisyThemeColorSchema
    },
    success: {
      additionalProperties: false,
      title: "Erfolgsfarbe",
      ...DaisyThemeColorSchema
    },
    warning: {
      additionalProperties: false,
      title: "Warnfarbe",
      ...DaisyThemeColorSchema
    },
    error: {
      additionalProperties: false,
      title: "Fehlerfarbe",
      ...DaisyThemeColorSchema
    }
  },
  required: [
    "primary",
    "secondary",
    "accent",
    "neutral",
    "base100",
    "base200",
    "base300",
    "baseContent",
    "info",
    "success",
    "warning",
    "error"
  ]
};
