<div class="smallstack-grid-card thumbnail h-full w-full">
  <div class="content overflow-triple-points mt-8">
    <span class="mat-caption">{{ file.name }}</span>
  </div>
  <div class="preview content">
    <smallstack-file-preview [file]="file" [showSize]="true" [showName]="false" />
  </div>
  <ng-content />
  <div class="actions">
    <button class="btn" data-test="select-btn" (click)="select()">
      <smallstack-i18n [data]="buttonLabelKey" />
    </button>
  </div>
</div>
