import { cloneObject, removeNullish } from "@smallstack/utils";
import { JSONSchema7 } from "json-schema";
import { TypeSchema } from "../typesystem/type-schema";
import { convertClassNameToTypePath } from "../typesystem/type-utils";

export abstract class SchemaService {
  /** interpolates all current $ref's recursively, does not support circular structures though */
  public async evalRefSchema(schema: TypeSchema, options = { recursive: false }): Promise<TypeSchema> {
    if (!schema) return;
    schema = this.tidySchema(schema);
    if (schema.$ref) {
      const refName = schema.$ref.substring(schema.$ref.lastIndexOf("/") + 1);
      const newSchema = cloneObject(schema);
      delete newSchema.$ref;
      const refSchema = await this.getSchemaByName(convertClassNameToTypePath(refName));
      schema = { ...newSchema, ...refSchema };
      if (options?.recursive === true) {
        schema = await this.evalRefSchema(schema, options);
      }
    }
    if ((schema.items as JSONSchema7)?.$ref) {
      const itemsSchema = schema.items as JSONSchema7;
      const refName = itemsSchema?.$ref.substring(itemsSchema?.$ref.lastIndexOf("/") + 1);
      const newSchema = cloneObject(itemsSchema);
      delete newSchema.$ref;
      const refSchema = await this.getSchemaByName(convertClassNameToTypePath(refName));
      schema.items = { ...newSchema, ...refSchema };
    }
    if (options?.recursive === true) {
      for (const key in schema.properties) {
        schema.properties[key] = await this.evalRefSchema(schema.properties[key], options);
      }
      if ((schema.items as JSONSchema7)?.properties)
        for (const key in (schema.items as JSONSchema7).properties) {
          (schema.items as JSONSchema7).properties[key] = await this.evalRefSchema(
            (schema.items as any).properties[key],
            options
          );
        }
    }
    return schema;
  }

  /** filters out unwanted properties and nestjs/swagger bugs like the useless allof[0] bug */
  public tidySchema(schema: TypeSchema): TypeSchema {
    if (!schema) return;
    if (schema.type === undefined && schema.allOf instanceof Array && schema.allOf.length === 1) {
      const newSchema = cloneObject(schema);
      delete newSchema.allOf;
      schema = { ...newSchema, ...(schema.allOf[0] as object) };
    }
    for (const key in schema.properties) {
      schema.properties[key] = this.tidySchema(schema.properties[key]);
    }
    schema = removeNullish(schema);
    return schema;
  }

  public abstract getSchemaByName(name: string): Promise<TypeSchema>;
  public abstract getAllSchemas(): Promise<{ [name: string]: TypeSchema }>;
}
