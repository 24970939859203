export const CollectionNames = {
  COLLECTION_APPLICATIONS: "applications",
  COLLECTION_AUDIT_LOG_RECORDS: "auditlog_records",
  COLLECTION_CORPORATIONS: "corporations",
  COLLECTION_CORPORATION_CUSTOM_FIELDS: "corporation_custom_fields",
  COLLECTION_BOOKED_SUBSCRIPTIONS: "subscriptions_booked",
  COLLECTION_CALENDAR_ENTRIES: "calendar_entries",
  COLLECTION_CALENDARS: "calendars",
  COLLECTION_CMSPAGES: "cmspages",
  COLLECTION_COMPANIES: "companies",
  COLLECTION_COMPOUND_WIDGETS: "compound_widgets",
  COLLECTION_CONFIGURATIONS: "configurations",
  COLLECTION_CONTACT_CUSTOM_FIELDS: "contact_customfields",
  COLLECTION_CONTACTS: "contacts",
  COLLECTION_COUNTERS: "counters",
  COLLECTION_COUNTRIES: "countries",
  COLLECTION_CRONJOBS: "cronjobs",
  COLLECTION_CUSTOM_BACKOFFICES: "custombackoffices",
  COLLECTION_CUSTOM_FIELDS: "custom_fields",
  COLLECTION_DASHBOARDS: "dashboards",
  /** @deprecated use TYPES */
  COLLECTION_DATA_TYPES: "datatypes",
  COLLECTION_DATA: "data",
  COLLECTION_DATA_TEMPLATES: "data_templates",
  COLLECTION_DEVICE_REGISTRATIONS: "device_registrations",
  COLLECTION_DEVICES: "devices",
  COLLECTION_EMAIL_LAYOUTS: "email_layouts",
  COLLECTION_EMAIL_TEMPLATES: "email_templates",
  COLLECTION_EMAILS: "emails",
  COLLECTION_EXTENSION_SLOTS: "extensionslots",
  COLLECTION_FILES: "files",
  COLLECTION_ICONS: "icons",
  COLLECTION_INVITATIONS: "invitations",
  COLLECTION_INVOICES: "invoices",
  COLLECTION_LANGUAGES: "languages",
  COLLECTION_LOCALES: "locales",
  COLLECTION_MAILING_CHANNELS: "mailing_channels",
  COLLECTION_MAILING_DELIVERABLE: "mailing_deliverable",
  COLLECTION_MAILING_LISTS: "mailing_lists",
  COLLECTION_MAILING_SENT: "mailing_sent",
  COLLECTION_MESSAGES: "messages",
  COLLECTION_MESSAGE_THREADS: "message_threads",
  COLLECTION_NOTES: "notes",
  COLLECTION_NOTIFICATIONS: "notifications",
  COLLECTION_OPTINS: "optins",
  COLLECTION_ORDERS: "orders",
  COLLECTION_PDF_TEMPLATES: "pdf_templates",
  COLLECTION_PERMISSIONS: "permissions",
  COLLECTION_PRICEZONES: "pricezones",
  COLLECTION_PRODUCTS: "products",
  COLLECTION_PROJECTS: "projects",
  COLLECTION_RESELLERS: "resellers",
  COLLECTION_REPLACEMENTS: "replacements",
  COLLECTION_ROLES: "roles",
  COLLECTION_SERVICE_TOKENS: "servicetokens",
  COLLECTION_SHOPPING_CARTS: "shoppingcarts",
  COLLECTION_SUBSCRIPTIONS: "subscriptions",
  COLLECTION_SUPPLIERS: "suppliers",
  COLLECTION_TASKS: "tasks",
  COLLECTION_TENANT_STATISTICS: "tenant_statistics",
  COLLECTION_TRANSLATIONS: "translations",
  COLLECTION_TODOS: "todos",
  COLLECTION_TODO_TEMPLATES: "todo_templates",
  COLLECTION_TODO_SURCHARGES: "todo_surcharges",
  COLLECTION_TYPES: "types",
  /** @deprecated use TYPES */
  COLLECTION_TYPE_CONFIGURATION: "type-configuration",
  COLLECTION_USERS: "users",
  COLLECTION_USER_GROUPS: "user-groups",
  COLLECTION_WORKFLOW_EXECUTIONS: "workflows_executions",
  COLLECTION_WORKFLOWS: "workflows",
  COLLECTION_WORKLOGS: "worklogs"
};
