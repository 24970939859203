import { EnvironmentProviders } from "@angular/core";
import { ONE_HOUR } from "@smallstack/utils";
import { provideAngularQuery, QueryClient } from "@tanstack/angular-query-experimental";

export const typesystemClientProviders: EnvironmentProviders[] = [
  provideAngularQuery(
    new QueryClient({
      defaultOptions: {
        queries: {
          gcTime: ONE_HOUR
        }
      }
    })
  )
];
