import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  forwardRef
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSelectModule, MatSelectChange } from "@angular/material/select";
import { ActivatedRoute, Params } from "@angular/router";
import {
  BreakpointService,
  LoadingElementDirective,
  NonEmptyStringPipe,
  WebComponentService
} from "@smallstack/common-components";
import { IOC } from "@smallstack/core-common";
import { I18nComponent, TranslationStore } from "@smallstack/i18n-components";
import { PageableStore } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import {
  SQBuilder,
  SearchByField,
  SearchByFieldMatcher,
  SearchByFieldValue,
  SearchQuery,
  StoreSearchFilter
} from "@smallstack/typesystem";
import { isNonEmptyString, isNumberString, isObjectIdString } from "@smallstack/utils";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { StoreSelectComponent } from "../store-select/store-select.component";

export const applicableMatchers: { [type: string]: SearchByFieldMatcher[] } = {
  string: [
    SearchByFieldMatcher.EXACT_MATCH,
    SearchByFieldMatcher.INCLUDES,
    SearchByFieldMatcher.NOT_INCLUDES,
    SearchByFieldMatcher.STARTS_WITH,
    SearchByFieldMatcher.ENDS_WITH,
    SearchByFieldMatcher.EXISTS
  ],
  number: [
    SearchByFieldMatcher.EQUALS,
    SearchByFieldMatcher.GREATER_THAN,
    SearchByFieldMatcher.GREATER_THAN_EQUALS,
    SearchByFieldMatcher.LESS_THAN,
    SearchByFieldMatcher.LESS_THAN_EQUALS,
    SearchByFieldMatcher.BEFORE_RELATIVE_TIME,
    SearchByFieldMatcher.AFTER_RELATIVE_TIME,
    SearchByFieldMatcher.IN_RELATIVE_MONTH,
    SearchByFieldMatcher.EXISTS
  ],
  boolean: [SearchByFieldMatcher.EQUALS, SearchByFieldMatcher.EXISTS],
  id: [SearchByFieldMatcher.EQUALS],
  date: [
    SearchByFieldMatcher.EQUALS,
    SearchByFieldMatcher.GREATER_THAN,
    SearchByFieldMatcher.GREATER_THAN_EQUALS,
    SearchByFieldMatcher.LESS_THAN,
    SearchByFieldMatcher.LESS_THAN_EQUALS,
    SearchByFieldMatcher.BEFORE_RELATIVE_TIME,
    SearchByFieldMatcher.AFTER_RELATIVE_TIME,
    SearchByFieldMatcher.IN_RELATIVE_MONTH,
    SearchByFieldMatcher.EXISTS
  ]
};

/** @deprecated use StoreSearchFilter */
export interface FilterName {
  label: string;
  value: string;
}

/** @deprecated use StoreSearchFilter */
export interface FilterValues {
  [filterName: string]: {
    config?: {
      allowCustomValues: boolean;
      dataType?: FilterDataType;
    };
    values?: {
      label: string;
      value: SearchByFieldValue;
    }[];
    matchers?: SearchByFieldMatcher[];
    storeValues?: {
      storeName: string;
      storeDisplayProperty: string;
    };
  };
}

export enum FilterDataType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
  ID = "id",
  FOREIGN_ID = "foreignId"
}

export function isNonEmptyStoreSearch(storeSearch: SearchQuery): boolean {
  return (
    typeof storeSearch === "object" &&
    storeSearch.fieldSearches instanceof Array &&
    storeSearch.fieldSearches.length >= 1
  );
}

@Component({
  selector: "smallstack-store-search",
  templateUrl: "./store-search.component.html",
  styleUrls: ["./store-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nComponent,
    FormsModule,
    LoadingElementDirective,
    forwardRef(() => StoreSelectComponent),
    NonEmptyStringPipe,
    IconComponent,
    MatSelectModule
  ]
})
export class StoreSearchComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  public showModeSwitcher: boolean = true;

  @Input()
  public showLogicalOperatorSwitcher: boolean = true;

  @Input()
  public fixedAdvancedSearchLogicalOperator: "and" | "or" | "none" = "none";

  @Input()
  public menuMode: boolean = false;

  @Input()
  public store: PageableStore;

  /** @deprecated use filters instead */
  @Input()
  public filterNames: FilterName[];

  /** @deprecated use filters instead */
  @Input()
  public filterValues: FilterValues;

  @Input()
  public set filters(filters: StoreSearchFilter[]) {
    if (filters instanceof Array && filters.length > 0) {
      this.filterNames = filters.map((f) => ({ label: f.label, value: f.property }));
      this.filterValues = {};
      filters.forEach((f) => {
        this.filterValues[f.property] = {
          config: { allowCustomValues: f.allowCustomValues, dataType: f.type ? f.type : FilterDataType.STRING },
          values: f.values as any,
          matchers: f.matchers
        };
      });
    }
  }

  @Input()
  public fullTextSearch: string = "";

  @Input()
  public mode: string = "simple";

  @Input()
  public loadDirectlyOnInput: boolean = false;

  /**
   * Set to false if you don't want to sync the current search to the url
   */
  @Input()
  public syncSearchParams: boolean = true;

  @Input()
  public searchQuery: SearchQuery = { fieldSearches: [], logicalOperator: "or" };

  @Output()
  public readonly searchQueryChange: EventEmitter<SearchQuery> = new EventEmitter();

  @Input()
  public openPopupsToTop: boolean = false;

  public currentFilterIndex: number;
  public currentPopup: string;
  public availableMatchers = [
    SearchByFieldMatcher.EXACT_MATCH,
    SearchByFieldMatcher.INCLUDES,
    SearchByFieldMatcher.NOT_INCLUDES,
    SearchByFieldMatcher.STARTS_WITH,
    SearchByFieldMatcher.ENDS_WITH
  ]; // matcher "equals" for boolean and number searches is set withing the `load` method
  public params: Params;
  public translationLabelFilterNames: string;

  protected SearchByFieldMatcher = SearchByFieldMatcher;
  protected FilterDataType = FilterDataType;

  private subscription = new Subscription();

  constructor(
    private eRef: ElementRef,
    public breakpointService: BreakpointService,
    @Optional() private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public translationStore: TranslationStore
  ) {}

  public ngOnInit(): void {
    if (this.filterNames?.length > 0) {
      const labelFilterNames = this.getLabelFilterNames();
      if (labelFilterNames)
        this.translationLabelFilterNames = this.translationStore.translateByKey("storesearch.placeholder", {
          replacers: { value: labelFilterNames }
        });
      else this.translationLabelFilterNames = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async ngAfterViewInit(): Promise<void> {
    if (!this.searchQuery) this.searchQuery = { fieldSearches: [], logicalOperator: "or" };
    if (this.syncSearchParams) {
      /**
       * Start advanced store search with advanced search params if specified in url.
       */
      if (IOC.isRegistered("webComponentService"))
        this.params = IOC.get<WebComponentService>("webComponentService").getNavigationParams();
      else this.params = this.activatedRoute.snapshot.queryParams;

      // Start simple store search with simple search params if specified in url.
      if (this.params?.simpleSearch) {
        const searchValue = this.params["simpleSearch"];
        this.fullTextSearch = searchValue;
        this.cdr.detectChanges();
        await this.load();
      }
      // start advanced search if specified in url
      else if (this.params?.advancedSearch) {
        const advancedSearchParam = SQBuilder.fromBase64String(this.params.advancedSearch);
        this.searchQuery.fieldSearches = advancedSearchParam.fieldSearches;
        this.searchQuery.logicalOperator = advancedSearchParam.logicalOperator;
        this.mode = "advanced";
        this.cdr.detectChanges();
        await this.load();
      }
    }
    if (this.store)
      this.subscription.add(
        this.store.currentSearch$
          .pipe(
            tap((search) => {
              this.searchQuery.fieldSearches = search;
            })
          )
          .subscribe()
      );
  }

  @HostListener("document:click", ["$event"])
  public clickout(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) this.currentPopup = undefined;
  }

  public async reset(): Promise<void> {
    this.fullTextSearch = "";
    this.searchQuery.fieldSearches = [];
    const labelFilterNames = this.getLabelFilterNames();
    if (labelFilterNames)
      this.translationLabelFilterNames = this.translationStore.translateByKey("storesearch.placeholder", {
        replacers: { value: labelFilterNames }
      });
    else this.translationLabelFilterNames = undefined;
    this.currentFilterIndex = undefined;
    this.currentPopup = undefined;
    if (this.store) this.store.query$.next({ page: 1, size: 10 }); // reload does not reset the search - has to be done separately

    /**
     * Remove query params on reset.
     */
    if (this.syncSearchParams && "URLSearchParams" in window) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("advancedSearch");
      searchParams.delete("simpleSearch");
      if (searchParams.toString() !== "")
        history.pushState(null, "", window.location.pathname + "?" + searchParams.toString());
      else history.pushState(null, "", window.location.pathname);
    }
    if (this.store) await this.store.reload();
    this.searchQueryChange.emit(this.searchQuery);
  }

  public addFilter(event: MatSelectChange): void {
    this.searchQuery.fieldSearches.push({
      fieldname: event.value,
      value: ""
    });
  }

  public removeFilter(index: number): void {
    this.searchQuery.fieldSearches.splice(index, 1);
    void this.load();
  }

  public createNewFilter(): void {
    const length = this.searchQuery.fieldSearches.push({} as any);
    this.triggerEditFieldName(length - 1);
  }

  public async setFilterValue(event: MouseEvent, val: SearchByFieldValue): Promise<void> {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.searchQuery.fieldSearches[this.currentFilterIndex].value = val;
    await this.inputChange();
    this.currentPopup = undefined;
  }

  public getFilterValue(filterFieldName: string, filterValue: SearchByFieldValue): SearchByFieldValue {
    if (this.filterValues && this.filterValues[filterFieldName]?.values instanceof Array) {
      const labeledValue = this.filterValues[filterFieldName].values.find((val) => val.value === filterValue);
      if (labeledValue) return labeledValue.label;
    }
    return filterValue;
  }

  public triggerEditFieldName(filterIndex: number): void {
    this.currentFilterIndex = filterIndex;
    this.currentPopup = "name";
  }

  public setCurrentFieldname(event: MouseEvent, name: string): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (this.currentFilterIndex === undefined) {
      const length = this.searchQuery.fieldSearches.push({} as any);
      this.currentFilterIndex = length - 1;
    }
    this.searchQuery.fieldSearches[this.currentFilterIndex].fieldname = name;
    if (this.searchQuery.fieldSearches[this.currentFilterIndex].matcher === undefined) this.currentPopup = "matcher";
    else this.currentPopup = undefined;
    this.searchQuery.fieldSearches[this.currentFilterIndex].value = undefined;
  }

  public getFieldName(fieldName: string): string {
    if (this.filterNames) {
      const labeledValue = this.filterNames.find((fn) => fn.value === fieldName);
      if (labeledValue) return labeledValue.label;
    }
    return fieldName;
  }

  public triggerEditFieldMatcher(filterIndex: number): void {
    this.currentFilterIndex = filterIndex;
    this.currentPopup = "matcher";
  }

  public setCurrentMatcher(event: MouseEvent, matcher: SearchByFieldMatcher): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.searchQuery.fieldSearches[this.currentFilterIndex].matcher = matcher;
    this.currentPopup = "value";
  }

  public changeMode(mode: string): void {
    this.searchQuery.fieldSearches = [];
    if (this.store) this.store.currentSearch$.next([]);
    this.mode = mode;
    this.cdr.detectChanges();
  }

  public async inputChange(): Promise<void> {
    if (this.loadDirectlyOnInput) await this.load();
  }

  // eslint-disable-next-line max-lines-per-function
  public async load(): Promise<void> {
    // Advanced search

    if (this.mode === "advanced") {
      if (this.searchQuery.fieldSearches instanceof Array && this.searchQuery.fieldSearches.length > 0) {
        this.searchQuery.fieldSearches.forEach((searchByField) => {
          if (
            this.filterValues &&
            this.shouldSearchForNumeric(searchByField?.fieldname) &&
            isNumberString(searchByField.value)
          )
            searchByField.value = Number(searchByField.value);
          if (
            this.filterValues &&
            this.shouldSearchForBoolean(searchByField?.fieldname) &&
            (searchByField.value === "true" || searchByField.value === "false")
          )
            searchByField.value = searchByField.value.toString().toLowerCase() === "true";
        });

        if (this.fixedAdvancedSearchLogicalOperator && this.fixedAdvancedSearchLogicalOperator !== "none")
          this.searchQuery.logicalOperator = this.fixedAdvancedSearchLogicalOperator;
        /**
         * Create advanced search query params and synchronize it with url.
         */
        const advancedSearchQueryParam = SQBuilder.toBase64String({
          fieldSearches: this.searchQuery.fieldSearches,
          logicalOperator: this.searchQuery.logicalOperator
        });

        if (this.syncSearchParams && "URLSearchParams" in window) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("advancedSearch", advancedSearchQueryParam);
          searchParams.delete("simpleSearch");
          if (searchParams.toString() !== "")
            history.pushState(null, "", window.location.pathname + "?" + searchParams.toString());
          else history.pushState(null, "", window.location.pathname);
        }
        if (this.store)
          await this.store.searchByFields(this.searchQuery.fieldSearches, this.searchQuery.logicalOperator);
      } else {
        // Reset on empty search
        if (this.store) {
          this.store.query$.next({ page: 1, size: 10 });
          await this.store.reload();
        }
      }
    }

    // Simple search
    else {
      if (this.fullTextSearch.length) {
        // Synchronize simple search params with url.
        if (this.syncSearchParams && "URLSearchParams" in window) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("simpleSearch", this.fullTextSearch);
          searchParams.delete("advancedSearch");
          if (searchParams.toString() !== "")
            history.pushState(null, "", window.location.pathname + "?" + searchParams.toString());
          else history.pushState(null, "", window.location.pathname);
        }

        // construct fulltext search
        this.searchQuery.fieldSearches = [];
        // create unique string token
        const tokens =
          this.fullTextSearch.length > 0 ? [...new Set(this.fullTextSearch.split(" ").filter(Boolean))] : [];
        tokens.forEach((token) => {
          // create a searchField for each token with respective matcher depending on the datatype
          if (this.filterNames instanceof Array)
            for (const fieldname of this.filterNames)
              switch (true) {
                // config.dataType: ObjectId
                case this.shouldSearchForObjectId(fieldname.value) && isObjectIdString(token): {
                  this.searchQuery.fieldSearches.push({
                    fieldname: fieldname.value,
                    value: token,
                    caseSensitive: true,
                    matcher: SearchByFieldMatcher.EQUALS
                  });
                  break;
                }
                // config.dataType: number
                case this.shouldSearchForNumeric(fieldname.value) && isNumberString(token):
                  this.searchQuery.fieldSearches.push({
                    fieldname: fieldname.value,
                    value: Number(token),
                    caseSensitive: false,
                    matcher: SearchByFieldMatcher.EQUALS
                  });
                  break;
                // config.dataType: boolean
                case this.shouldSearchForBoolean(fieldname.value) && (token === "true" || token === "false"):
                  this.searchQuery.fieldSearches.push({
                    fieldname: fieldname.value,
                    value: token.toLowerCase() === "true",
                    caseSensitive: false,
                    matcher: SearchByFieldMatcher.EQUALS
                  });
                  break;
                // config.dataType: string (excluding ObjectId string)
                case this.shouldSearchForString(fieldname.value) && isNonEmptyString(token): {
                  const name = fieldname.value.toLowerCase();
                  if (name !== "id" && name !== "_id")
                    this.searchQuery.fieldSearches.push({
                      fieldname: fieldname.value,
                      value: token,
                      caseSensitive: false,
                      matcher: SearchByFieldMatcher.INCLUDES
                    });
                  break;
                }
                default: {
                  if (!this.filterValues || this.filterValues[fieldname.value] === undefined) {
                    const name = fieldname.value.toLowerCase();
                    if (name !== "id" && name !== "_id")
                      this.searchQuery.fieldSearches.push({
                        fieldname: fieldname.value,
                        value: token,
                        caseSensitive: false,
                        matcher: SearchByFieldMatcher.INCLUDES
                      });
                  }
                }
              }
        });
        if (this.store) await this.store.searchByFields(this.searchQuery.fieldSearches, "or");
      }
      // Reset on empty search
      else await this.reset();
    }
    this.searchQueryChange.emit(this.searchQuery);
  }

  public applicableMatches(searchByField: SearchByField): SearchByFieldMatcher[] {
    if (this.filterValues) {
      if (this.filterValues[searchByField.fieldname]?.matchers)
        return this.filterValues[searchByField.fieldname]?.matchers;
      // number and boolean searches require the matcher "equals"
      const dataType = this.filterValues[searchByField.fieldname]?.config?.dataType;
      if (dataType) return applicableMatchers[dataType];
    }
    return this.availableMatchers;
  }

  public getLabelFilterNames(): string {
    if (this.filterNames) {
      const filteredFilterNames = this.filterNames.map((filter) => {
        if (this.filterValues && this.filterValues[filter.value] && this.filterValues[filter.value].storeValues) {
          return { label: filter.label + "-ID", value: filter.value };
        }
        return filter;
      });
      if (filteredFilterNames?.length > 1) {
        const array = filteredFilterNames.map((filterName) => filterName.label);
        return (
          array.slice(0, -1).join(", ") +
          " " +
          this.translationStore.translateByKey("common.and") +
          " " +
          array.slice(-1)
        );
      } else if (filteredFilterNames?.length === 1) {
        const array = filteredFilterNames.map((filterName) => filterName.label);
        return array[0];
      } else return undefined;
    }
  }

  private shouldSearchForNumeric(fieldName: string): boolean {
    return this.filterValues && this.filterValues[fieldName]?.config?.dataType === FilterDataType.NUMBER;
  }

  private shouldSearchForString(fieldName: string): boolean {
    return this.filterValues && this.filterValues[fieldName]?.config?.dataType === FilterDataType.STRING;
  }

  private shouldSearchForBoolean(fieldName: string): boolean {
    return this.filterValues && this.filterValues[fieldName]?.config?.dataType === FilterDataType.BOOLEAN;
  }

  private shouldSearchForObjectId(fieldName: string): boolean {
    return (
      this.filterValues &&
      (this.filterValues[fieldName]?.config?.dataType === FilterDataType.ID ||
        this.filterValues[fieldName]?.config?.dataType === FilterDataType.FOREIGN_ID)
    );
  }
}
