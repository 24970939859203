import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { InnerTranslationDto } from "@smallstack/axios-api-client";
import { OverlayComponent } from "@smallstack/common-components";
import { isInlineTranslation } from "@smallstack/i18n-shared";
import { IconComponent } from "@smallstack/theme-components";
import { LocaleStore } from "../../stores/locale.store";
import { TranslationStore } from "../../stores/translation.store";
import { FlagComponent } from "../flag/flag.component";

@Component({
  selector: "smallstack-translations-popover",
  templateUrl: "./translations-popover.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, OverlayComponent, MatCardModule, FlagComponent]
})
export class TranslationsPopoverComponent {
  @Input()
  public translations: string | Array<InnerTranslationDto>;

  constructor(
    public localeStore: LocaleStore,
    private translationStore: TranslationStore
  ) {
    void this.localeStore.load();
  }

  public getTranslatedString(localeString: string): string {
    if (isInlineTranslation(this.translations)) {
      const translation = this.translations.find((tl: InnerTranslationDto) => tl.locale === localeString);
      return translation?.value
        ? translation?.value
        : this.translationStore.translateByKey("backoffice.emails.templates.notranslations");
    } else return this.translations;
  }
}
