<div class="no-print join w-full p-1">
  <input
    [(ngModel)]="searchText"
    (keydown.enter)="searchText$.next(searchText)"
    data-test="store-search-simple-input"
    class="input input-bordered join-item w-full"
  />
  @if (searchText$ | async) {
    <button class="btn join-item" (click)="reset()" data-test="store-search-delete">
      <smallstack-icon alias="delete" size="24" color="546e7a"></smallstack-icon>
    </button>
  }
  <button (click)="searchText$.next(searchText)" class="btn btn-primary join-item" data-test="store-search-execute">
    @if ((isSearching$ | async) === false) {
      <smallstack-icon alias="search" size="24" color="ffffff"></smallstack-icon>
    } @else {
      <smallstack-loader [size]="'small'" [loadingText]="" color="ffffff"></smallstack-loader>
    }
  </button>
</div>
