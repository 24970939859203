import { BreakpointService } from "../services/breakpoint.service";

export interface ResponsiveValue<T> {
  lg: T;
  md: T;
  sm: T;
}

export function calculateResponsiveValue<T>(breakpointService: BreakpointService, value: ResponsiveValue<T>): T {
  if (breakpointService.isDesktop()) return value.lg;
  if (breakpointService.isTablet()) return value.md;
  return value.sm;
}
