@if (textarea !== true) {
  <mat-form-field class="w-full">
    @if (label) {
      <mat-label>
        <smallstack-i18n [data]="label"></smallstack-i18n>
      </mat-label>
    }
    <input
      data-test="inline-translation-editor-input"
      matInput
      [ngModel]="translation[currentLocaleIndex]?.value"
      (ngModelChange)="valueChanged($event)"
      [placeholder]="placeholder"
      [errorStateMatcher]="errorStateMatcher"
    />
    @if (isTranslatedField()) {
      <button mat-icon-button [matMenuTriggerFor]="menu" matPrefix tabindex="-1" matTooltipPosition="left">
        <smallstack-flag [locale]="currentEditorLocale"></smallstack-flag>
      </button>
    }
    <mat-menu #menu="matMenu">
      @for (locale of locales; track locale) {
        <button mat-menu-item (click)="setCurrentEditorLocale(locale)">
          <smallstack-flag [locale]="locale"></smallstack-flag> {{ locale }}
          @if (hasContent(locale)) {
            <smallstack-icon alias="point" size="15px" class="content-indicator" color="primary" theme="ios-filled">
            </smallstack-icon>
          }
        </button>
      }
    </mat-menu>
    @if (!disableConfigBtn) {
      <button
        mat-icon-button
        matSuffix
        (click)="toggleTranslationState()"
        tabindex="-1"
        data-test="inline-translation-input-activate-i18n"
        matTooltipPosition="right"
      >
        <smallstack-icon alias="translation" size="20"></smallstack-icon>
      </button>
    }
    @if (hint) {
      <mat-hint>
        <smallstack-i18n [data]="hint"></smallstack-i18n>
      </mat-hint>
    }
    @if (error) {
      <mat-error>
        {{ error }}
      </mat-error>
    }
  </mat-form-field>
} @else {
  <mat-form-field class="w-full">
    @if (label) {
      <mat-label>
        <smallstack-i18n [data]="label"></smallstack-i18n>
      </mat-label>
    }
    @if (isTranslatedField()) {
      <button mat-icon-button [matMenuTriggerFor]="menu" matPrefix tabindex="-1">
        <smallstack-flag [locale]="currentEditorLocale"></smallstack-flag>
      </button>
    }
    <textarea
      data-test="inline-translation-editor-textarea"
      cdkTextareaAutosize
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="15"
      matInput
      [ngModel]="translation[currentLocaleIndex]?.value"
      (ngModelChange)="valueChanged($event)"
      cdkTextareaAutosize
      [errorStateMatcher]="errorStateMatcher"
    ></textarea>
    <mat-menu #menu="matMenu">
      @for (locale of locales; track locale) {
        <button mat-menu-item (click)="setCurrentEditorLocale(locale)">
          <smallstack-flag [locale]="locale"></smallstack-flag> {{ locale }}
        </button>
      }
    </mat-menu>
    @if (!disableConfigBtn) {
      <button
        mat-icon-button
        matSuffix
        (click)="toggleTranslationState()"
        tabindex="-1"
        data-test="inline-tranlation-textarea-activate-i18n"
      >
        <smallstack-icon alias="translation"></smallstack-icon>
      </button>
    }
    @if (hint) {
      <mat-hint>
        <smallstack-i18n [data]="hint"></smallstack-i18n>
      </mat-hint>
    }
    @if (error) {
      <mat-error>
        {{ error }}
      </mat-error>
    }
  </mat-form-field>
}
