<div
  class="rounded border-2 text-center h-6 relative m-3"
  [ngStyle]="{ width: data().width, borderColor: data().borderColor }"
>
  @if (content$ | async) {
    <div class="absolute text-center w-full z-20">
      <span class="rounded-lg text-xs px-1 border" style="background-color: rgba(255, 255, 255, 0.75)">{{
        content$ | async
      }}</span>
    </div>
  }
  @for (subBar of values$ | async; track subBar) {
    <div
      class="absolute rounded-sm h-5 rounded-r-none"
      [ngStyle]="{ width: subBar.percentage + '%', backgroundColor: subBar.color }"
    >
      <div
        class="h-2 w-0 border-r-2 border-slate-300 right-0 absolute"
        [ngClass]="{ '-bottom-2': subBar.odd, '-top-2': !subBar.odd }"
        [ngStyle]="{ borderColor: data().borderColor }"
      >
        <div
          class="absolute text-slate-400 whitespace-nowrap bg-white"
          style="font-size: 9px"
          [ngClass]="{
            '-bottom-4': subBar.odd,
            '-top-4': !subBar.odd,
            'right-1': subBar.percentage > 50,
            'left-1': subBar.percentage <= 50
          }"
          >{{ subBar.label }}</div
        >
      </div>
    </div>
  }
</div>
