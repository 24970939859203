import { Widget, WidgetSchema } from "./widget";

export interface ApplicationLayout {
  name: string;
  widget: Widget;
}

export const ApplicationLayoutSchema = {
  type: "object",
  properties: {
    name: {
      type: "string"
    },
    widget: WidgetSchema
  },
  required: ["name", "widget"]
};
