export type PromiseStringProviderFn = () => string | Promise<string>;
export type PromiseStringProvider = string | Promise<string> | PromiseStringProviderFn;
export type StringProviderFn = () => string;
export type StringProvider = string | StringProviderFn;

export async function getValueFromStringProvider(provider: PromiseStringProvider): Promise<string> {
  if (typeof provider === "string") return provider;
  if (typeof provider === "function") return provider();
  return undefined;
}
export function getValueFromStringProviderSync(provider: StringProvider): string {
  if (typeof provider === "string") return provider;
  if (typeof provider === "function") return provider();
  return undefined;
}
