import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TypeSchema } from "@smallstack/typesystem";
import { getLastPropertyName, upperCaseFirst } from "@smallstack/utils";
import { FormService } from "../../services/form.service";

export function getSchemaPropertyTitle(schema: TypeSchema, pathAsFallback: string): string | InlineTranslation {
  if (schema && schema["x-schema-form"]?.i18nTitle) return schema["x-schema-form"].i18nTitle;
  else if (typeof schema?.title === "string") return schema.title;
  else return upperCaseFirst(getLastPropertyName(pathAsFallback));
}

@Component({
  selector: "smallstack-form-field-title",
  templateUrl: "./form-field-title.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, I18nComponent]
})
export class FormFieldTitleComponent {
  protected formService = inject(FormService);

  public path = input<string>();
  public showRequired = input<boolean>(false);

  protected data = computed<string | InlineTranslation>(() => {
    const path = this.path();
    if (!path) return undefined;
    const schema = this.formService.getSchemaForPath(path);
    if (!schema) return undefined;
    return getSchemaPropertyTitle(schema, path);
  });

  protected isRequired = computed<boolean>(() => {
    const path = this.path();
    if (!path) return false;
    return this.formService.isRequired(path);
  });

  protected isInvalid = computed<boolean>(() => {
    const path = this.path();
    if (!path) return false;
    if (!this.formService.hasBeenValidated(path)) return false;
    const errors = this.formService.getValidationStatusForPath(path);
    if (!errors || errors.length === 0) return false;
    return true;
  });
}
