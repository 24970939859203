import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseContainerWidgetComponent } from "./base-container-widget.component";

@Component({
  selector: "smallstack-vertical-container-widget",
  templateUrl: "./base-container-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalContainerWidgetComponent extends BaseContainerWidgetComponent {
  public static override direction: "row" | "column" = "column";
}
