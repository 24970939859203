<div class="filter-container no-print flex flex-row">
  <div class="filter-input-bar flex flex-row space-x-3 items-center basis-full">
    <div style="padding: 10px; width: 100%">
      <input matInput [(ngModel)]="text" (keydown.enter)="btn.click()" style="width: 100%" />
    </div>
  </div>
  <button class="filter-secondary-button basis-full btn" (click)="text = ''" data-test="store-search-delete">
    <smallstack-icon alias="delete" size="24" color="546e7a"></smallstack-icon>
  </button>
  <button
    #btn="loadingFn"
    [loadingFn]="sendData()"
    class="filter-button basis-full btn btn-primary"
    data-test="store-search-execute"
  >
    <smallstack-icon alias="search" size="24" color="ffffff"></smallstack-icon>
  </button>
</div>
