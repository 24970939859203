<button class="btn btn-primary" (click)="fileInput.click()">
  <input
    #fileInput
    type="file"
    style="display: none"
    data-test="upload-button-file-input"
    [disabled]="fileStore.isUploading$ | async"
    (change)="change($event)"
  />
  @if (fileStore.isUploading$ | async) {
    <span class="loading loading-spinner"></span>
    <smallstack-i18n data="@@files.uploading" /> ({{ fileStore.uploadProgress$ | async }}%)
  } @else {
    <smallstack-i18n data="@@actions.upload" />
  }
  <ng-template #upload>
    <smallstack-i18n data="@@actions.upload" />
  </ng-template>
</button>
