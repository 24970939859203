/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BillingPartyDto } from './billing-party-dto';
import { ConfigurablePropertyDto } from './configurable-property-dto';
import { CustomFieldValueDto } from './custom-field-value-dto';
import { InnerTranslationDto } from './inner-translation-dto';

/**
 * 
 * @export
 * @interface BillingPositionDto
 */
export interface BillingPositionDto {
    /**
     * 
     * @type {Array<InnerTranslationDto>}
     * @memberof BillingPositionDto
     */
    label: Array<InnerTranslationDto>;
    /**
     * 
     * @type {string}
     * @memberof BillingPositionDto
     */
    type: BillingPositionDtoTypeEnum;
    /**
     * Bei Fixkosten können Sie hier die Stückzahl definieren, beim Stundensatz den Faktor.
     * @type {number}
     * @memberof BillingPositionDto
     */
    amount?: number;
    /**
     * 
     * @type {ConfigurablePropertyDto}
     * @memberof BillingPositionDto
     */
    price: ConfigurablePropertyDto;
    /**
     * 
     * @type {Array<BillingPartyDto>}
     * @memberof BillingPositionDto
     */
    billingParties?: Array<BillingPartyDto>;
    /**
     * Falls ja, dann werden definierte Zuschläge wie Nacht- oder Feiertagszuschlag berechnet.
     * @type {boolean}
     * @memberof BillingPositionDto
     */
    addSurcharges?: boolean;
    /**
     * Falls ja, dann werden die Unteraufgaben dieser Aufgabe als Teil der Rechnungsposition auf der Rechnung angezeigt.
     * @type {boolean}
     * @memberof BillingPositionDto
     */
    showSubTasksOnBill?: boolean;
    /**
     * 
     * @type {Array<CustomFieldValueDto>}
     * @memberof BillingPositionDto
     */
    customFields?: Array<CustomFieldValueDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum BillingPositionDtoTypeEnum {
    Fixed = 'fixed',
    HourlyRate = 'hourlyRate'
}



