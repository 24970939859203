import { Injectable } from "@angular/core";
import { AxiosApiClient, TodoApi, TodoDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class TodoStore extends PageableCrudStore<TodoDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super({ loadOnDeletion: true });
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<TodoDto>> {
    const res = await this.axiosApiClient.get(TodoApi).getTodos(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<TodoDto> {
    const res = await this.axiosApiClient.get(TodoApi).getTodo({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(TodoApi).deleteTodo({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(TodoApi).deleteManyTodos({ ids });
    return res.data;
  }
  protected async createModel(model: TodoDto): Promise<TodoDto> {
    const res = await this.axiosApiClient.get(TodoApi).createTodo({ todo: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<TodoDto>): Promise<TodoDto> {
    const res = await this.axiosApiClient.get(TodoApi).patchTodo({ id, todo: model });
    return res.data;
  }
  protected async putModel(model: TodoDto): Promise<TodoDto> {
    const res = await this.axiosApiClient.get(TodoApi).putTodo({ id: model.id, todo: model });
    return res.data;
  }
}
