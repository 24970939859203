/* eslint-disable @angular-eslint/no-output-native */
import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { CurrentUserDto } from "@smallstack/axios-api-client";
import { NotificationService } from "@smallstack/i18n-components";
import { PasswordFieldComponent, UserService } from "@smallstack/user-components";
import { isValidEmail } from "@smallstack/utils";
import { AxiosResponse } from "axios";
@Component({
  selector: "smallstack-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, PasswordFieldComponent, NgClass, LoadingElementDirective]
})
export class RegistrationComponent {
  public newPassword: string;
  public newEmail: string;

  @Output()
  public readonly afterRegistration: EventEmitter<void> = new EventEmitter();

  constructor(
    private userService: UserService,
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService
  ) {}

  public register() {
    return async (): Promise<void> => {
      try {
        const registerResponse: AxiosResponse<CurrentUserDto> = await this.axiosApiClient.get(UsersApi).registerUser({
          registerUser: { email: this.newEmail, password: this.newPassword }
        });
        if (registerResponse.status === 201) {
          this.userService.setCurrentUser(registerResponse.data);
          this.afterRegistration.emit();
        } else this.notificationService.showStandardErrorPopup(registerResponse.data as any);
      } catch (e) {
        this.notificationService.showStandardErrorPopup(e);
      }
    };
  }

  public isFormValid(): boolean {
    if (!isValidEmail(this.newEmail)) return false;
    if (!this.newPassword || this.newPassword === "") return false;
    return true;
  }
}
