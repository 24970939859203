import { TYPE_FRIENDS } from "../typesystem/type-names";
import { TypeSchema } from "../typesystem/type-schema";
import { DataType } from "./data-type";
import { DataTypeStorageTarget } from "./data-type-storage";

export enum FriendStatus {
  Unknown = "unknown",
  ViaProject = "viaProject",
  ViaCompany = "viaCompany",
  Pending = "pending",
  Accepted = "accepted",
  Declined = "declined"
}

export interface Friend {
  id: string;
  email: string;
  displayName: string;
  avatarUrl?: string;
  status: FriendStatus;
}

export const FriendSchema: TypeSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    email: { type: "string" },
    displayName: { type: "string" },
    avatarUrl: { type: "string" },
    status: {
      type: "string",
      enum: Object.values(FriendStatus)
    }
  },
  required: ["id", "email", "displayName", "status"]
};

export const FriendType: DataType = {
  icon: "fa-user",
  path: TYPE_FRIENDS,
  title: [
    { value: "Freund", locale: "de_de" },
    { value: "Friend", locale: "en_us" }
  ],
  titlePlural: [
    { value: "Freunde", locale: "de_de" },
    { value: "Friends", locale: "en_us" }
  ],
  searchableFields: [
    { name: "id", weight: 10 },
    { name: "displayName", weight: 10 },
    { name: "status", weight: 5 },
    { name: "email", weight: 8 }
  ],
  representation: "${displayName}",
  longRepresentation: "Anzeigename: ${displayName}, E-Mail: ${email}, Freundschaftsstatus: ${status}",
  url: "${basePath}/friends?detailsId=${id}",
  storage: {
    pull: {
      target: DataTypeStorageTarget.RestEndpoint,
      options: {
        apiUrl: "friends"
      }
    }
  },
  schema: FriendSchema
};
