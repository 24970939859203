@for (child of data().children; track child; let index = $index) {
  <div style="position: relative" class="smallstack-widget-no-pointer-events" [ngStyle]="{ flex: data().childrenFlex }">
    @if (widgetTreeService.editMode()) {
      <smallstack-widget-editor-renderer [widget]="child" (widgetChange)="childDataChanged(index, $event)">
      </smallstack-widget-editor-renderer>
    } @else {
      <smallstack-widget-renderer
        [widget]="child"
        (widgetChange)="childDataChanged(index, $event)"
        class="smallstack-widget-all-pointer-events"
      >
      </smallstack-widget-renderer>
    }
  </div>
}
@if (widgetTreeService.editMode()) {
  <div style="text-align: center" class="smallstack-widget-all-pointer-events">
    <smallstack-cms-page-add-widget (addWidget)="addWidget($event)"> </smallstack-cms-page-add-widget>
  </div>
}
