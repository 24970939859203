import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { SchedulingRuleV2Dto, SchedulingRuleV2DtoPatternEnum } from "@smallstack/axios-api-client";
import { generateSchedulingRuleV2Events } from "@smallstack/todo-shared";
import { DATE_TIME_ISO_FORMAT, ONE_HOUR } from "@smallstack/utils";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { format, getISODay, parseISO, set } from "date-fns";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "smallstack-scheduling-rule-input-v2",
  templateUrl: "./scheduling-rule-input-v2.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulingRuleInputV2Component extends SchemaFormBaseWidget implements OnInit {
  protected startTime: string;
  protected endTime: string;
  protected scheduleStart: string;
  protected scheduleEnd: string;
  protected schedulingRulePattern = Object.values(SchedulingRuleV2DtoPatternEnum);

  protected schedulingRule: SchedulingRuleV2Dto = {
    pattern: SchedulingRuleV2DtoPatternEnum.Once,
    startTime: set(Date.now(), { minutes: 0, milliseconds: 0, seconds: 0 }).valueOf(),
    duration: ONE_HOUR
  };
  protected nextDates: number[];

  public override ngOnInit(): void {
    super.ngOnInit();

    // filter pattern
    void firstValueFrom(this.schema$).then((schema) => {
      if (schema && schema["x-schema-form"].alwaysSchedulingPatternAllowed === false)
        this.schedulingRulePattern = this.schedulingRulePattern.filter(
          (p) => p !== SchedulingRuleV2DtoPatternEnum.Always
        );
      this.cdr.markForCheck();
    });

    // set initial value
    const value = this.getValue();
    if (value) this.schedulingRule = value;
    // else send initial value
    else this.sendData();

    // generate preview dates
    this.updatePreviewDates();

    // update local fields
    this.startTime = format(this.schedulingRule.startTime, DATE_TIME_ISO_FORMAT);
    if (this.schedulingRule.duration && this.schedulingRule.startTime)
      this.endTime = format(this.schedulingRule.startTime + this.schedulingRule.duration, DATE_TIME_ISO_FORMAT);
  }

  protected updatePreviewDates(): void {
    this.nextDates = generateSchedulingRuleV2Events(this.schedulingRule, { maxEvents: 10 });
  }

  protected syncLocalToSchedulingRule(): void {
    // sync duration
    if (this.startTime && this.endTime)
      this.schedulingRule.duration = parseISO(this.endTime).valueOf() - parseISO(this.startTime).valueOf();
    else this.schedulingRule.duration = ONE_HOUR;

    // sync date fields
    if (this.startTime) this.schedulingRule.startTime = parseISO(this.startTime).valueOf();
    if (this.scheduleStart) this.schedulingRule.scheduleStart = parseISO(this.scheduleStart).valueOf();
    if (this.scheduleEnd) this.schedulingRule.scheduleEnd = parseISO(this.scheduleEnd).valueOf();

    this.updatePreviewDates();
    this.sendData();
  }

  protected patternUpdated(): void {
    switch (this.schedulingRule.pattern) {
      case SchedulingRuleV2DtoPatternEnum.Always:
        this.schedulingRule.startTime = undefined;
        this.schedulingRule.each = undefined;
        this.schedulingRule.patternIncludes = undefined;
        this.schedulingRule.scheduleStart = undefined;
        this.schedulingRule.scheduleEnd = undefined;
        break;
      case SchedulingRuleV2DtoPatternEnum.Once:
        this.schedulingRule.startTime = this.startTime ? parseISO(this.startTime).valueOf() : Date.now();
        this.schedulingRule.each = undefined;
        this.schedulingRule.patternIncludes = undefined;
        this.schedulingRule.scheduleStart = undefined;
        this.schedulingRule.scheduleEnd = undefined;
        break;
      case SchedulingRuleV2DtoPatternEnum.Daily:
        this.schedulingRule.startTime = parseISO(this.startTime).valueOf();
        if (!this.schedulingRule.scheduleStart)
          this.schedulingRule.scheduleStart = this.schedulingRule.startTime
            ? this.schedulingRule.startTime
            : Date.now();
        if (!this.schedulingRule.each) this.schedulingRule.each = 1;
        break;
      case SchedulingRuleV2DtoPatternEnum.Weekly:
        this.schedulingRule.startTime = parseISO(this.startTime).valueOf();
        if (!this.schedulingRule.scheduleStart)
          this.schedulingRule.scheduleStart = this.schedulingRule.startTime
            ? this.schedulingRule.startTime
            : Date.now();
        if (!this.schedulingRule.patternIncludes)
          this.schedulingRule.patternIncludes = [getISODay(this.schedulingRule.startTime)];
        if (!this.schedulingRule.each) this.schedulingRule.each = 1;
    }
    this.syncLocalToSchedulingRule();
  }

  protected sendData(): void {
    this.setValue(this.schedulingRule);
  }
}
