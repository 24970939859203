<div class="mat-outline no-hover" style="min-width: 650px">
  @if (label) {
    <mat-label>
      <smallstack-i18n [data]="label"></smallstack-i18n>
    </mat-label>
  }
  @if (isTranslatedField()) {
    <div class="small-flags-tab-container">
      @if (locales) {
        <mat-tab-group
          mat-align-tabs="start"
          class="page-by-locale"
          (selectedTabChange)="selectedTabChange($event)"
          animationDuration="0ms"
          [selectedIndex]="selectedLocaleTab"
        >
          @for (locale of locales; track locale) {
            <mat-tab matSuffix class="small">
              <ng-template mat-tab-label>
                <smallstack-flag [locale]="locale"></smallstack-flag>
                @if (hasContent(locale)) {
                  <smallstack-icon
                    alias="aperture"
                    size="24"
                    class="content-indicator"
                    color="accent"
                  ></smallstack-icon>
                }
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>
      }
    </div>
  }

  <textarea #markdownEditor></textarea>
</div>
