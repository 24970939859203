import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { EXTENSION_SLOT_COMPONENT } from "@smallstack/common-components";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackTextModule } from "@smallstack/text-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { AddNewWidgetDialogComponent } from "./components/add-widget/add-new-widget-dialog/add-new-widget-dialog.component";
import { AddWidgetComponent } from "./components/add-widget/add-widget.component";
import { ConnectionDialogComponent } from "./components/connection-dialog/connection-dialog.component";
import { ExtensionSlotComponent } from "./components/extension-slot/extension-slot.component";
import { WidgetChildComponent } from "./components/widget-child/widget-child.component";
import { WidgetConfigurationDialogComponent } from "./components/widget-configuration-dialog/widget-configuration-dialog.component";
import { WidgetDialogComponent } from "./components/widget-dialog/widget-dialog.component";
import { WidgetEditorRendererComponent } from "./components/widget-editor-renderer/widget-editor-renderer.component";
import { WidgetLoaderComponent } from "./components/widget-loader/widget-loader.component";
import { WidgetRendererComponent } from "./components/widget-renderer/widget-renderer.component";
import { WidgetTreeComponent } from "./components/widget-tree/widget-tree.component";

export const EXTENSION_SLOT_COMPONENT_PROVIDER = {
  provide: EXTENSION_SLOT_COMPONENT,
  useValue: ExtensionSlotComponent
};

/**
 * @deprecated All Components are standalone, please import them directly
 */
@NgModule({
  imports: [
    CommonModule,
    SmallstackThemeModule,
    SmallstackI18nModule,
    SmallstackTextModule,
    ClipboardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
    ConnectionDialogComponent,
    ExtensionSlotComponent,
    WidgetRendererComponent,
    WidgetEditorRendererComponent,
    AddNewWidgetDialogComponent,
    AddWidgetComponent,
    WidgetTreeComponent,
    WidgetChildComponent,
    WidgetLoaderComponent,
    WidgetDialogComponent,
    WidgetConfigurationDialogComponent
  ],
  exports: [
    WidgetTreeComponent,
    WidgetRendererComponent,
    WidgetEditorRendererComponent,
    AddWidgetComponent,
    AddNewWidgetDialogComponent,
    ExtensionSlotComponent,
    WidgetChildComponent,
    ConnectionDialogComponent,
    WidgetDialogComponent,
    WidgetConfigurationDialogComponent
  ],
  providers: [EXTENSION_SLOT_COMPONENT_PROVIDER]
})
export class SmallstackWidgetsModule {}
