import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { AddNewWidgetDialogComponent, AddNewWidgetDialogData, WidgetTreeService } from "@smallstack/widget-core";
import { v4 } from "uuid";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-cms-page-add-widget",
  templateUrl: "./cms-page-add-widget.component.html",
  styleUrls: ["./cms-page-add-widget.component.scss"]
})
export class CmsPageAddWidgetComponent {
  @Output()
  public readonly addWidget: EventEmitter<WidgetDto> = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    public widgetTreeService: WidgetTreeService
  ) {}

  public openWidgetLibrary(): void {
    this.matDialog.open(AddNewWidgetDialogComponent, {
      width: "70vw",
      data: {
        allowedWidgetTags: this.widgetTreeService.widgetTags,
        saveFn: async (newWidget: WidgetDto) => {
          if (newWidget) {
            if (!newWidget.id) newWidget.id = v4();
            this.addWidget.emit(newWidget);
          }
        }
      } as AddNewWidgetDialogData
    });
  }
}
