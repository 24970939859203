/* eslint-disable no-console */
import { SchedulingRuleDto, SchedulingRuleDtoPatternEnum } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { cloneJson } from "@smallstack/utils";
import { addDays, addWeeks, getISODay, isAfter, isBefore, set, startOfWeek } from "date-fns";

// eslint-disable-next-line complexity
export function generateNextDates(
  schedulingRule: SchedulingRuleDto,
  options?: { now?: number; resultCount?: number }
): number[] {
  if (!schedulingRule) return undefined;
  if (!options) options = {};

  // if now not set, use "now"
  if (!options.now) options.now = Date.now();
  if (!options.resultCount) options.resultCount = 10;

  let nowDate: Date = new Date(options.now);

  // if endTime is reached, return undefined
  if (schedulingRule.endTime && isAfter(nowDate, schedulingRule.endTime)) return undefined;

  // if startTime is greater, use startTime for "now"
  if (isAfter(schedulingRule.startTime, nowDate)) nowDate = new Date(schedulingRule.startTime);

  // set the time of the day
  if (!schedulingRule.hour || schedulingRule.hour < 0 || schedulingRule.hour > 23) schedulingRule.hour = 18;
  if (!schedulingRule.minute || schedulingRule.minute < 0 || schedulingRule.minute > 59) schedulingRule.minute = 0;

  if (!schedulingRule.each) schedulingRule.each = 1;

  nowDate = set(nowDate, { hours: schedulingRule.hour, minutes: schedulingRule.minute, seconds: 0, milliseconds: 0 });

  // eval patterns
  const results: number[] = [];
  switch (schedulingRule.pattern) {
    case SchedulingRuleDtoPatternEnum.Businessdays:
      for (let i = 0; i < options.resultCount; i++) {
        const isoWeekday = getISODay(nowDate);
        if (isoWeekday === 6 || isoWeekday === 7)
          nowDate = set(startOfWeek(addWeeks(nowDate, 1), { weekStartsOn: 1 }), {
            hours: schedulingRule.hour,
            minutes: schedulingRule.minute,
            seconds: 0,
            milliseconds: 0
          });
        results.push(nowDate.valueOf());
        nowDate = addDays(nowDate, schedulingRule.each);
      }
      break;
    case SchedulingRuleDtoPatternEnum.Weekly:
      if (!schedulingRule.each) schedulingRule.each = 1;
      if (!(schedulingRule.patternIncludes instanceof Array) || schedulingRule.patternIncludes.length === 0)
        schedulingRule.patternIncludes = [1];
      for (let i = 0; i < options.resultCount * 10; i++) {
        if (schedulingRule.patternIncludes instanceof Array && schedulingRule.patternIncludes.length > 0) {
          const nowDateWeekday = getISODay(nowDate);
          if (!schedulingRule.patternIncludes.includes(nowDateWeekday)) {
            nowDate = addDays(nowDate, 1);
            // add weeks if end of week
            if (getISODay(nowDate) === 7 && results.length !== 0) nowDate = addWeeks(nowDate, schedulingRule.each - 1);
            continue;
          }
        }
        results.push(nowDate.valueOf());
        nowDate = addDays(nowDate, 1);
        // add weeks if end of week
        if (getISODay(nowDate) === 7 && results.length !== 0) nowDate = addWeeks(nowDate, schedulingRule.each - 1);
      }
      break;
    case SchedulingRuleDtoPatternEnum.Daily:
      if (!schedulingRule.each) schedulingRule.each = 1;
      if (schedulingRule.patternIncludes)
        Logger.debug("DueDateGenerator", "schedulingRule.patternIncludes has no effect when 'daily' is used!");
      for (let i = 0; i < options.resultCount; i++) {
        results.push(nowDate.valueOf());
        nowDate = addDays(nowDate, schedulingRule.each);
      }
      break;
    // case SchedulingRuleDtoPatternEnum.Monthly:
    //   if (!schedulingRule.each) schedulingRule.each = 1;
    //   if (schedulingRule.patternIncludes)
    //     Logger.debug("DueDateGenerator","schedulingRule.patternIncludes has no effect when 'monthly' is used!");
    //   for (let i = 0; i < options.resultCount; i++) {
    //     results.push(nowDate.valueOf());
    //     nowDate.add(schedulingRule.each, "months");
    //   }
    //   break;
    // case SchedulingRuleDtoPatternEnum.Yearly:
    //   if (!schedulingRule.each) schedulingRule.each = 1;
    //   if (schedulingRule.patternIncludes)
    //     Logger.debug("DueDateGenerator","schedulingRule.patternIncludes has no effect when 'yearly' is used!");
    //   for (let i = 0; i < options.resultCount; i++) {
    //     results.push(nowDate.valueOf());
    //     nowDate.add(schedulingRule.each, "year");
    //   }
    //   break;
  }
  if (results.length > options.resultCount) return results.slice(0, options.resultCount);
  return results;
}

// eslint-disable-next-line complexity
export function generateNextDatesUntil(
  schedulingRule: SchedulingRuleDto,
  options: { from: number; until: number; includeOld?: boolean }
): number[] {
  if (!schedulingRule) return undefined;

  // prevent mutating original scheduling rule
  schedulingRule = cloneJson(schedulingRule);

  let start: Date = new Date(options.from);
  let end: Date = new Date(options.until);

  // if endTime is reached, return undefined
  if (schedulingRule.endTime && isAfter(start, schedulingRule.endTime)) return undefined;

  // if startTime is greater, use startTime for "start"
  if (options?.includeOld !== true && isAfter(schedulingRule.startTime, start))
    start = new Date(schedulingRule.startTime);
  // if endTime is lesser, use endTime for "end"
  if (schedulingRule.endTime && isBefore(schedulingRule.endTime, end)) end = new Date(schedulingRule.endTime);

  // set the time of the day
  if (!schedulingRule.hour || schedulingRule.hour < 0 || schedulingRule.hour > 23) schedulingRule.hour = 18;
  if (!schedulingRule.minute || schedulingRule.minute < 0 || schedulingRule.minute > 59) schedulingRule.minute = 0;

  if (!schedulingRule.each) schedulingRule.each = 1;

  start = set(start, { hours: schedulingRule.hour, minutes: schedulingRule.minute, seconds: 0, milliseconds: 0 });

  // eval patterns
  const results: number[] = [];
  switch (schedulingRule.pattern) {
    case SchedulingRuleDtoPatternEnum.Businessdays:
      do {
        // weekend?
        if (getISODay(start) === 6 || getISODay(start) === 7)
          start = set(startOfWeek(addWeeks(start, 1), { weekStartsOn: 1 }), {
            hours: schedulingRule.hour,
            minutes: schedulingRule.minute,
            seconds: 0,
            milliseconds: 0
          });
        results.push(start.valueOf());
        start = addDays(start, schedulingRule.each);
      } while (isBefore(start, end));

      break;
    case SchedulingRuleDtoPatternEnum.Weekly:
      if (!schedulingRule.each) schedulingRule.each = 1;
      if (!(schedulingRule.patternIncludes instanceof Array) || schedulingRule.patternIncludes.length === 0)
        schedulingRule.patternIncludes = [1];
      do {
        if (schedulingRule.patternIncludes instanceof Array && schedulingRule.patternIncludes.length > 0) {
          const startWeekday = getISODay(start);
          if (!schedulingRule.patternIncludes.includes(startWeekday)) {
            start = addDays(start, 1);
            // add weeks if end of week
            if (getISODay(start) === 7 && results.length !== 0) start = addWeeks(start, schedulingRule.each - 1);
            continue;
          }
        }
        results.push(start.valueOf());
        start = addDays(start, 1);
        // add weeks if end of week
        if (getISODay(start) === 7 && results.length !== 0) start = addWeeks(start, schedulingRule.each - 1);
      } while (isBefore(start, end));
      break;
    case SchedulingRuleDtoPatternEnum.Daily:
      if (!schedulingRule.each) schedulingRule.each = 1;
      if (schedulingRule.patternIncludes)
        Logger.debug("DueDateGenerator", "schedulingRule.patternIncludes has no effect when 'daily' is used!");
      do {
        results.push(start.valueOf());
        start = addDays(start, schedulingRule.each);
      } while (isBefore(start, end));
      break;
    // case SchedulingRuleDtoPatternEnum.Monthly:
    //   if (!schedulingRule.each) schedulingRule.each = 1;
    //   if (schedulingRule.patternIncludes)
    //     Logger.debug("DueDateGenerator","schedulingRule.patternIncludes has no effect when 'monthly' is used!");
    //   for (let i = 0; i < options.resultCount; i++) {
    //     results.push(start.valueOf());
    //     start.add(schedulingRule.each, "months");
    //   }
    //   break;
    // case SchedulingRuleDtoPatternEnum.Yearly:
    //   if (!schedulingRule.each) schedulingRule.each = 1;
    //   if (schedulingRule.patternIncludes)
    //     Logger.debug("DueDateGenerator","schedulingRule.patternIncludes has no effect when 'yearly' is used!");
    //   for (let i = 0; i < options.resultCount; i++) {
    //     results.push(start.valueOf());
    //     start.add(schedulingRule.each, "year");
    //   }
    //   break;
  }
  return results;
}
