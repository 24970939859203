import { Logger } from "@smallstack/core-common";
import { clone, waitForExpectedValue } from "@smallstack/utils";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

export enum StoreState {
  INITIAL = "initial",
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error"
}

export enum StoreHooks {
  BEFORE_RESET = "beforeReset",
  AFTER_RESET = "afterReset",
  BEFORE_UPDATE_VALUE = "beforeUpdateValue",
  AFTER_UPDATE_VALUE = "afterUpdateValue"
}

export type StoreHookFn = (...args: unknown[]) => void;

export class Store<T = unknown> {
  protected storeHooks: { [hookName: string]: StoreHookFn[] } = {};
  private readonly _state$ = new BehaviorSubject(StoreState.INITIAL);
  private readonly _error$: BehaviorSubject<Error> = new BehaviorSubject<Error>(undefined);
  private readonly _value$ = new BehaviorSubject<T>(undefined);

  constructor(protected initialValue?: T) {
    if (initialValue) this.setValue(initialValue, false);
  }

  public setState(state: StoreState): void {
    this._state$.next(state);
  }

  public setError(error: Error): void {
    Logger.error("Store", "Error in Store: ", error);
    this.setState(StoreState.ERROR);
    this._error$.next(error);
  }

  public setValue(value: T | Promise<T>, setLoaded: boolean = true): void {
    if (value instanceof Promise) {
      try {
        value.then((v) => this.setValue(v, setLoaded)).catch((e) => this.setError(e));
      } catch (e) {
        this.setError(e);
      }
    } else {
      // TODO: deactivated until we find a solution to make store data immutable
      // Only update if value is different
      // if (!objectsEqual(this._value$.value, value))
      this.executeHooks(StoreHooks.BEFORE_UPDATE_VALUE, value);
      this._value$.next(value);
      // else Logger.info("Store", "Not setting new value since values are equal!", { original: this._value$.value, new: value });
      if (setLoaded === true) this.setState(StoreState.LOADED);
      this.executeHooks(StoreHooks.AFTER_UPDATE_VALUE, value);
    }
  }

  public updateValue(updateFn: (previousValue: T) => T, setLoaded?: boolean): void {
    this.setValue(updateFn(clone(this._value$.getValue())), setLoaded);
  }

  public setLoading(): Store<T> {
    this.setState(StoreState.LOADING);
    return this;
  }

  public get state$(): Observable<StoreState> {
    return this._state$.asObservable();
  }

  public get state(): StoreState {
    return this._state$.getValue();
  }

  public get value$(): Observable<T> {
    return this._value$.asObservable();
  }

  public get value(): T {
    return this._value$.getValue();
  }

  public get error$(): Observable<Error> {
    return this._error$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state === StoreState.LOADING));
  }

  public get isLoaded$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state === StoreState.LOADED));
  }

  public async waitForLoaded(): Promise<void> {
    return waitForExpectedValue(this.state$, StoreState.LOADED);
  }

  public get hasError$(): Observable<boolean> {
    return this.error$.pipe(map((error) => error !== undefined));
  }

  public acknowledgeError(): void {
    this._error$.next(undefined);
  }

  public async reset(): Promise<void> {
    this.executeHooks(StoreHooks.BEFORE_RESET);
    this._error$.next(undefined);
    this._value$.next(this.initialValue);
    this.setState(StoreState.INITIAL);
    this.executeHooks(StoreHooks.AFTER_RESET);
  }

  public addHook(hookName: string, fn: StoreHookFn): void {
    if (this.storeHooks[hookName] === undefined) this.storeHooks[hookName] = [];
    this.storeHooks[hookName].push(fn);
  }

  public executeHooks(hookName: string, ...args: unknown[]): void {
    if (this.storeHooks[hookName] instanceof Array) {
      for (const hookFn of this.storeHooks[hookName]) {
        hookFn(...args);
      }
    }
  }
}
