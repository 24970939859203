import { Injectable } from "@angular/core";
import { Logger } from "@smallstack/core-common";
import { ONE_MINUTE, ONE_WEEK } from "@smallstack/utils";
import { RxDatabase, addRxPlugin, createRxDatabase, removeRxDatabase } from "rxdb";
import { RxDBCleanupPlugin } from "rxdb/plugins/cleanup";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { RxDBUpdatePlugin } from "rxdb/plugins/update";
import { synchronized } from "synchronized-ts";

addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBUpdatePlugin);

@Injectable({ providedIn: "root" })
export class RxDatabaseService {
  #database: RxDatabase;

  @synchronized
  public async getDatabase(): Promise<RxDatabase> {
    if (!this.#database) {
      this.#database = await createRxDatabase({
        name: "smallstack-cloud",
        storage: getRxStorageDexie(),
        multiInstance: true,
        eventReduce: true,
        cleanupPolicy: {
          awaitReplicationsInSync: true,
          minimumCollectionAge: ONE_MINUTE,
          minimumDeletedTime: 4 * ONE_WEEK,
          runEach: 5 * ONE_MINUTE,
          waitForLeadership: true
        }
      });

      this.#database
        .waitForLeadership()
        .then(async (isLeader: boolean) => {
          if (isLeader) {
            Logger.info("RxDatabaseService", " ♔ Browser tab selected as leader.");
          } else Logger.info("RxDatabaseService", " ♛ Browser tab selected as follower.");
        })
        .catch((error) => {
          Logger.error("RxDatabaseService", "Error while waiting for leadership", error);
        });
    }

    return this.#database;
  }

  public async removeDatabase(): Promise<void> {
    await removeRxDatabase("smallstack-cloud", getRxStorageDexie());
    this.#database = undefined;
  }
}
