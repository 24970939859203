import { Pipe, PipeTransform } from "@angular/core";

/**
 * Sorts a string or number array
 */
@Pipe({ name: "sort", standalone: true })
export class SortPipe implements PipeTransform {
  public transform(strArray: string[] | any[], ...args: any[]): any[] {
    if (strArray instanceof Array) {
      const sortBy = args[0];
      if (sortBy) {
        return strArray.sort((a: any, b: any) => {
          return a[sortBy]?.localeCompare(b[sortBy]);
        });
      } else return strArray.sort();
    }
    return strArray;
  }
}
