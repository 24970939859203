import { WidgetRegistryEntry } from "@smallstack/widget-core";

export enum CONFIGURATION_KEYS {
  CONTEXT_VARIABLE_NAME = "contextVariableName"
}

export const MarkAsReadWidget: WidgetRegistryEntry = {
  name: "MarkAsRead",
  configuration: {
    templateName: "Als gelesen setzen",
    templateDescription:
      "Dieses Widget setzt Einträge als gelesen, sobald es angezeigt wird. Das Widget selbst ist unsichtbar.",
    icon: "envelope-number",
    dataSchema: {
      type: "object",
      properties: {
        [CONFIGURATION_KEYS.CONTEXT_VARIABLE_NAME]: {
          type: "string",
          title: "Kontext Variable",
          description: "Geben Sie hier den Namen der Kontext Variable an, die das ungelesene Objekt beinhaltet."
        }
      }
    }
  },
  component: () => import("./mark-as-read-widget.component").then((m) => m.MarkAsReadWidgetComponent)
};
