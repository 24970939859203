import { WIDGET_FORM_INPUT_I18N, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { TypeSchema } from "@smallstack/typesystem";
import { BooleanEquationOperator } from "@smallstack/utils";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const LinksWidget: WidgetRegistryEntry = {
  name: "Links",
  configuration: {
    icon: "link--v1",
    templateName: "Verknüpfungen",
    templateDescription: "Zeigt Verknüpfungen von oder zur aktuellen Entität an.",
    tags: AllWidgetTags,
    dataSchema: {
      type: "object",
      properties: {
        widget: {
          title: "Inhaltsdarstellung",
          description: "Definieren Sie hier, welcher Inhalt angezeigt werden soll.",
          "x-schema-form": {
            widget: "widget"
          },
          default: {
            name: "GridListViewV3",
            data: {
              contextProperty: "",
              template: {
                name: "LinkView",
                data: {
                  showIcon: true,
                  showType: true,
                  iconSize: 32,
                  layout: "row"
                }
              },
              showPager: false,
              showMoreLink: false
            }
          }
        },
        linkType: {
          title: "Link Typ",
          type: "string",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            values: [
              {
                value: "outwards",
                label: "@@widgets.links.linkType.outwards"
              },
              {
                value: "inwards",
                label: "@@widgets.links.linkType.inwards"
              }
            ]
          },
          default: "outwards"
        },
        outwardsContextProperty: {
          type: "string",
          title: "Link Variable",
          description: "Geben Sie hier an, in welcher Kontext Variable sich die Links befinden",
          default: "links",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: { dataPath: "../linkType", operator: BooleanEquationOperator.NOT_EQUALS, value: "outwards" }
              }
            ]
          }
        },
        outwardsTypeFilter: {
          type: "array",
          title: "Typ Filter",
          description:
            "Falls Sie nicht alle Arten von ausgehenden Links anzeigen möchten, dann können Sie hier ausfiltern. Leer lassen für Alle!",
          items: {
            type: "string",
            "x-schema-form": { widget: "types" }
          } as TypeSchema,
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: { dataPath: "../linkType", operator: BooleanEquationOperator.NOT_EQUALS, value: "outwards" }
              }
            ]
          }
        },
        inwardsContextProperty: {
          type: "string",
          title: "Fremdschlüssel Variable",
          description:
            "Geben Sie hier an, in welcher Kontext Variable sich der Fremdschlüssel (meist die eigene ID) befindet",
          default: "id",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: { dataPath: "../linkType", operator: BooleanEquationOperator.NOT_EQUALS, value: "inwards" }
              }
            ]
          }
        },
        foreignTypes: {
          type: "array",
          title: "Entitäten für Fremdschlüssel Suche",
          description:
            "Geben Sie hier an, bei welchen anderen Entitäten nach Links zur gegebenen Entität gesucht werden soll",
          items: {
            type: "object",
            properties: {
              type: {
                title: "Typ",
                "x-schema-form": { widget: "types" }
              },
              foreignKey: {
                title: "Link Schlüssel",
                type: "string",
                description:
                  "Geben Sie hier an, in welcher Variable des fremden Typs sich der eigene Fremdschlüssel befindet",
                default: "links.id"
              }
            }
          } as TypeSchema,
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: { dataPath: "../linkType", operator: BooleanEquationOperator.NOT_EQUALS, value: "inwards" }
              }
            ]
          }
        },
        noneFoundText: {
          title: "Keine Ergebnisse Text",
          description: "Dieser Text wird angezeigt, falls keine Verknüpfungen gefunden wurden.",
          type: "array",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N }
        }
      }
    }
  },
  component: () => import("./links-widget.component").then((m) => m.LinksWidgetComponent)
};
