<div class="flex flex-col">
  <mat-form-field>
    <mat-label><smallstack-i18n data="@@components.table.key" /></mat-label>
    <input matInput type="text" data-test="metadata-key-input" [(ngModel)]="key" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select [(ngModel)]="schema.type">
      <mat-option value="string"><smallstack-i18n data="@@components.table.addentry.text" /> </mat-option>
      <mat-option value="number"><smallstack-i18n data="@@components.table.addentry.number" /> </mat-option>
      <mat-option value="boolean"><smallstack-i18n data="@@components.table.addentry.boolean" /> </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="btn btn-primary" (click)="addProperty()">
    <smallstack-i18n data="@@actions.add" />
  </button>
</div>
