import { ChangeDetectionStrategy, Component, Inject, computed, input, output } from "@angular/core";
import { AddressDto, SchedulingRuleDtoPatternEnum, TodoDto, TodoTemplateDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective, TRANSLATION_HELPER, TranslationHelper } from "@smallstack/common-components";
import { DateComponent, NotificationService } from "@smallstack/i18n-components";
import { LinkedModelsComponent } from "@smallstack/link-components";
import { IconComponent } from "@smallstack/theme-components";
import { TYPE_TODOS, TYPE_USERS } from "@smallstack/typesystem";
import { injectRxEntityStore } from "@smallstack/typesystem-client";
import { UserService } from "@smallstack/user-components";
import { cloneJson, cloneObject, getGoogleMapsNavigationUrl } from "@smallstack/utils";
import { TypeModelComponent } from "@smallstack/widget-core";
import { MyWorklogStore } from "../../stores/my-worklog.store";
import { generateNextDates } from "./due-date-generator";

@Component({
  selector: "smallstack-todo-template-list-entry",
  templateUrl: "./todo-template-list-entry.component.html",
  styleUrls: ["./todo-template-list-entry.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TypeModelComponent, IconComponent, LoadingElementDirective, DateComponent, LinkedModelsComponent]
})
export class TodoTemplateListEntryComponent {
  protected todoStore = injectRxEntityStore<TodoDto>({ typeDescriptor: { typePath: TYPE_TODOS } });

  public todoTemplate = input<TodoTemplateDto>();

  /** If you already have a time in mind and don't want to show the selection */
  public fixedTime = input<number>();

  protected readonly createdTodo = output<TodoDto>();

  protected showAlways = computed<boolean>(() => {
    if (
      !this.todoTemplate()?.schedulingRule ||
      this.todoTemplate()?.schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Always
    )
      return true;
    else return false;
  });
  protected nextDates = computed<number[]>(() => {
    let nextDates = generateNextDates(cloneJson(this.todoTemplate().schedulingRule), { resultCount: 3 });
    if (
      !this.todoTemplate().schedulingRule ||
      this.todoTemplate().schedulingRule.pattern === SchedulingRuleDtoPatternEnum.Always
    ) {
      nextDates = [];
    }

    // filter the ones that have been used for existing todos already
    const existingTodos = this.todoStore.getMany();
    if (existingTodos) {
      const todoDueDateReferences = existingTodos
        .filter((todo) => todo.todoTemplateRefId === this.todoTemplate().id && todo.startTime !== undefined)
        .map((todo) => todo.startTime);
      if (todoDueDateReferences.length > 0 && nextDates instanceof Array)
        nextDates = nextDates.filter((nd) => !todoDueDateReferences.includes(nd));
    }
    return nextDates;
  });

  constructor(
    private notificationService: NotificationService,
    @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper,
    private myWorklogStore: MyWorklogStore,
    private userService: UserService
  ) {}

  public openGoogleMaps(address: AddressDto): void {
    window.open(getGoogleMapsNavigationUrl(address), "_blank");
  }

  public createTodo(startTime?: number) {
    return async (): Promise<void> => {
      const answer: "cancel" | "assign" | "start" = await this.notificationService.popup.confirmation(
        "Aufgabe starten",
        `Möchten Sie die Aufgabe "${this.translationHelper.translate(this.todoTemplate().label)}" starten?`,
        [
          {
            label: "Abbrechen",
            value: "cancel"
          },
          // {
          //   label: "Zuweisen",
          //   color: "secondary",
          //   value: "assign"
          // },
          {
            label: "Starten",
            color: "primary",
            value: "start"
          }
        ]
      );
      const todoTemplateClone: TodoTemplateDto & { nextDate?: number } = cloneObject(this.todoTemplate());
      delete todoTemplateClone.schedulingRule;
      delete todoTemplateClone.id;
      delete todoTemplateClone.nextDate;
      const todo: TodoDto = todoTemplateClone;
      todo.startTime = startTime;
      todo.todoTemplateRefId = this.todoTemplate().id;

      const newlyTodo = await this.todoStore.createEntity(todo);
      this.createdTodo.emit(newlyTodo);
      if (answer === "start") await this.createWorklog(newlyTodo);
    };
  }

  private async createWorklog(todo: TodoDto) {
    await this.myWorklogStore.stopAllWorklogs();
    await this.myWorklogStore.createWorklog({
      label: todo.label,
      owner: {
        updater: "query",
        id: this.userService.currentUserId(),
        cachedLabel: [{ value: this.userService.currentUser().user.displayName }],
        type: TYPE_USERS
      },
      startTime: Date.now(),
      todoRefId: todo.id
    });
  }
}
