import { TypeSchema } from "../typesystem/type-schema";
import { InnerTranslation, createInnerTranslationSchema } from "./inner-translation";

export interface CustomFieldValue {
  value?: string;
  title?: Array<InnerTranslation>;
  customFieldId: string;
}

export const CustomFieldValueSchema: TypeSchema = {
  type: "object",
  title: "CustomFieldValue",
  properties: {
    value: {
      type: "string"
    },
    title: {
      type: "array",
      items: createInnerTranslationSchema()
    },
    customFieldId: {
      type: "string"
    }
  },
  required: ["customFieldId"],
  additionalProperties: false
};
