/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary get all my ImmoScout24 listings
         * @param {string} configGroup 
         * @param {boolean} useProd 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyListings: async (configGroup: string, useProd: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'configGroup' is not null or undefined
            if (configGroup === null || configGroup === undefined) {
                throw new RequiredError('configGroup','Required parameter configGroup was null or undefined when calling getMyListings.');
            }
            // verify required parameter 'useProd' is not null or undefined
            if (useProd === null || useProd === undefined) {
                throw new RequiredError('useProd','Required parameter useProd was null or undefined when calling getMyListings.');
            }
            const localVarPath = `/external/immoscout24/mylistings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (configGroup !== undefined) {
                localVarQueryParameter['configGroup'] = configGroup;
            }

            if (useProd !== undefined) {
                localVarQueryParameter['useProd'] = useProd;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary get all my ImmoScout24 listings
         * @param {string} configGroup 
         * @param {boolean} useProd 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyListings(configGroup: string, useProd: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ExternalApiAxiosParamCreator(axiosClientConfiguration).getMyListings(configGroup, useProd, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get all my ImmoScout24 listings
         * @param {string} configGroup 
         * @param {boolean} useProd 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyListings(configGroup: string, useProd: boolean, translate?: string, options?: any): AxiosPromise<any> {
            return ExternalApiFp(axiosClientConfiguration).getMyListings(configGroup, useProd, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMyListings operation in ExternalApi.
 * @export
 * @interface ExternalApiGetMyListingsRequest
 */
export interface ExternalApiGetMyListingsRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalApiGetMyListings
     */
    readonly configGroup: string

    /**
     * 
     * @type {boolean}
     * @memberof ExternalApiGetMyListings
     */
    readonly useProd: boolean

    /**
     * 
     * @type {string}
     * @memberof ExternalApiGetMyListings
     */
    readonly translate?: string
}

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * 
     * @summary get all my ImmoScout24 listings
     * @param {ExternalApiGetMyListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getMyListings(requestParameters: ExternalApiGetMyListingsRequest, options?: any) {
        return ExternalApiFp(this.axiosClientConfiguration).getMyListings(requestParameters.configGroup, requestParameters.useProd, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
