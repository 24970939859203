<smallstack-store-search
  [store]="store"
  [loadDirectlyOnInput]="data().isAllowedToLoadOnInput !== undefined ? data().isAllowedToLoadOnInput : false"
  [mode]="data().initialMode || 'simple'"
  [showLogicalOperatorSwitcher]="
    data().showLogicalOperatorSwitcher !== undefined ? data().showLogicalOperatorSwitcher : true
  "
  [fixedAdvancedSearchLogicalOperator]="data().fixedAdvancedSearchLogicalOperator || 'none'"
  [filterNames]="filterNames"
  [showModeSwitcher]="data().advancedMode !== undefined ? data().advancedMode : true"
  [filterValues]="filterValues"
  (searchQueryChange)="sendSearchQuery($event)"
>
</smallstack-store-search>
