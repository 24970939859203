<button [matMenuTriggerFor]="langMenu" class="toolbar-menu-button btn" data-test="locale-selector-menu-trigger">
  <smallstack-flag [locale]="localeService.currentLocale$ | async" style="padding: 10px"></smallstack-flag>
</button>
<mat-menu class="{{ menuClass }}" yPosition="below" xPosition="after" #langMenu="matMenu" [overlapTrigger]="false">
  @for (split of splitLocales; track split) {
    <button
      (click)="selectLocale(split.locale)"
      mat-menu-item
      [attr.data-test]="'locale-selector-btn-' + split.locale"
      class="flex flex-row space-x-2 items-center"
    >
      <smallstack-flag [attr.data-test]="'locale-selector-flag-' + split.locale" [locale]="split.locale">
      </smallstack-flag>
      @if (displayMode === "full") {
        <span>
          <smallstack-i18n data="@@languages.{{ split.language }}" [locale]="split.locale"></smallstack-i18n>
        </span>
        @if (showCountry === true) {
          <span
            >(<smallstack-i18n data="@@countries.{{ split.country }}" [locale]="split.locale"></smallstack-i18n>)</span
          >
        }
      }
    </button>
  }
</mat-menu>
