<div class="worklog-entry flex flex-col space-y-2 items-start">
  @for (price of prices; track price) {
    <div>
      <div [matTooltip]="price.calculationText" style="cursor: help" class="flex flex-col">
        <smallstack-i18n class="text-muted" [data]="price.billingPosition?.label"></smallstack-i18n>
        <div style="white-space: nowrap">{{ price.value / 100 | currency: "EUR" }} {{ price.suffix }}</div>
      </div>
    </div>
  }
</div>
