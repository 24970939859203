import { addDays, endOfDay, format, getWeek, isAfter, isBefore, parse, parseISO, setWeek, startOfDay } from "date-fns";
import { de } from "date-fns/locale/de";

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 1000 * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const DEFAULT_GERMAN_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const DEFAULT_HTML5_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const DEFAULT_HTML5_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_GERMAN_DATE_STRING_FORMAT = "yyyy-MM-dd";
export const DEFAULT_GERMAN_HUMAN_DATE_TIME_FORMAT = "dd.MM.yyyy HH:mm";
export const DEFAULT_GERMAN_HUMAN_DATE_TIME_FORMAT_WITH_SECONDS = "dd.MM.yyyy HH:mm:ss";
export const DEFAULT_GERMAN_HUMAN_DATE_FORMAT = "dd.MM.yyyy";
export const DATE_TIME_ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_TIME_ISO_FORMAT_URL_CONFORM = "yyyy-MM-dd-HH-mm-ss";
export const DATE_TIME_ISO_FORMAT_FILENAME_CONFORM = "yyyy-MM-dd-HH mm-ss";

export interface IntlFormat {
  weekday?: "narrow" | "short" | "long";
  era?: "narrow" | "short" | "long";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
}

export const INTL_SHORT: IntlFormat = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric"
};

export const INTL_MEDIUM: IntlFormat = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
};

export const INTL_SHORT_TIME: IntlFormat = {
  hour: "numeric",
  minute: "numeric"
};

export const INTL_SHORT_DATE: IntlFormat = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit"
};

export const INTL_LONG_DATE: IntlFormat = {
  year: "numeric",
  month: "2-digit",
  day: "numeric"
};

export const INTL_FULL_DATE_TIME: IntlFormat = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric"
};

export const INTL_FULL_DATE: IntlFormat = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric"
};

// converts angular date pipe names to intl formats
export const INTL_FORMATS: { [key: string]: IntlFormat } = {
  short: INTL_SHORT,
  shortDate: INTL_SHORT_DATE,
  longDate: INTL_LONG_DATE,
  fullDate: INTL_FULL_DATE,
  fullDateTime: INTL_FULL_DATE_TIME,
  medium: INTL_MEDIUM,
  shortTime: INTL_SHORT_TIME
};
export type NamedIntlFormat = keyof typeof INTL_FORMATS;
export interface HumanizeDurationOptions {
  /** given number is treated as timestamp and subtracted from Date.now() */
  relativeToNow?: boolean;

  /** shall the duration be negated? */
  negate?: boolean;

  /** show seconds or not, default is false */
  showSeconds?: boolean;

  /** show duration as short format (1h33m), default is false */
  shortFormat?: boolean;
}

// eslint-disable-next-line complexity
export function humanizeDuration(durationInMs: number, options: HumanizeDurationOptions = {}): string {
  if (options.relativeToNow === true) durationInMs -= Date.now();
  if (options.negate === true) durationInMs *= -1;
  if (!options.showSeconds) options.showSeconds = false;
  if (!options.shortFormat) options.shortFormat = false;
  const result: any = {};
  const s: { [time: string]: number } = {
    year: 31536000000,
    month: 2419200000,
    week: 604800000,
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000
  };
  if (durationInMs > 0)
    Object.keys(s).forEach((key) => {
      result[key] = Math.floor(durationInMs / s[key]);
      durationInMs -= result[key] * s[key];
    });
  else
    Object.keys(s).forEach((key) => {
      result[key] = Math.ceil(durationInMs / s[key]);
      durationInMs -= result[key] * s[key];
    });

  const str: string[] = [];
  if (!options.shortFormat) {
    if (result.month && result.month > 0) str.push(`${result.month} Monat${result.month === 1 ? "" : "e"}`);
    if (result.week && result.week > 0) str.push(`${result.week} Woche${result.week === 1 ? "" : "n"}`);
    if (result.day && result.day > 0) str.push(`${result.day} Tag${result.day === 1 ? "" : "e"}`);
    if (result.hour && result.hour > 0) str.push(`${result.hour} Stunde${result.hour === 1 ? "" : "n"}`);
    if (result.minute && result.minute > 0) str.push(`${result.minute} Minute${result.minute === 1 ? "" : "n"}`);
    if (options.showSeconds && result.second && result.second > 0)
      str.push(`${result.second} Sekunde${result.second === 1 ? "" : "n"}`);
    if (str.length === 0) return "-";
    return str.join(", ");
  } else {
    if (result.month && result.month > 0) str.push(`${result.month}M`);
    if (result.week && result.week > 0) str.push(`${result.week}W`);
    if (result.day && result.day > 0) str.push(`${result.day}T`);
    if (result.hour && result.hour > 0) str.push(`${result.hour}h`);
    if (result.minute && result.minute > 0) str.push(`${result.minute}m`);
    if (options.showSeconds && result.second && result.second > 0) str.push(`${result.second}s`);
    if (str.length === 0) return "-";
    return str.join(" ");
  }
}

/** Returns a list of dateStrings (yyyy-MM-dd) of days that are in between the given two dateStrings (yyyy-MM-dd or timestamp) */
export function getAllRelatedDays(startDateString: string | number, endDateString: string | number): string[] {
  let startDate = startOfDay(
    typeof startDateString === "number"
      ? new Date(startDateString)
      : parse(startDateString, DEFAULT_GERMAN_DATE_STRING_FORMAT, new Date())
  );
  const endDate = startOfDay(
    typeof endDateString === "number"
      ? new Date(endDateString)
      : parse(endDateString, DEFAULT_GERMAN_DATE_STRING_FORMAT, new Date())
  );
  const relatedDays = [];
  while (startDate <= endDate) {
    relatedDays.push(toDateString(startDate));
    startDate = addDays(startDate, 1);
  }
  return relatedDays;
}

/** Converts a timestamp to the smallstack common date string format yyyy-MM-dd */
export function toDateString(timestamp: number | Date): string {
  if (typeof timestamp === "string") timestamp = parseISO(timestamp);
  return format(timestamp, DEFAULT_GERMAN_DATE_STRING_FORMAT);
}

export interface OverlappingTime {
  startTime: number;
  endTime: number;
}

export function getOverlappingTime(
  startTimestamp: number,
  endTimestamp: number,
  dateRangeStartTimestamp: number,
  dateRangeEndTimestamp: number
): OverlappingTime {
  // if one is empty, return undefined
  if (
    startTimestamp === undefined ||
    endTimestamp === undefined ||
    dateRangeStartTimestamp === undefined ||
    dateRangeEndTimestamp === undefined
  )
    return undefined;
  // switch them around if start is after end
  if (startTimestamp > endTimestamp) [startTimestamp, endTimestamp] = [endTimestamp, startTimestamp];
  if (dateRangeStartTimestamp > dateRangeEndTimestamp)
    [dateRangeEndTimestamp, dateRangeStartTimestamp] = [dateRangeStartTimestamp, dateRangeEndTimestamp];

  // calculate
  const startTime = new Date(startTimestamp);
  const endTime = new Date(endTimestamp);
  const dateRangeStart = new Date(dateRangeStartTimestamp);
  const dateRangeEnd = new Date(dateRangeEndTimestamp);
  let start: number;
  let end: number;

  // out of range
  if (isAfter(startTime, dateRangeEnd) || isBefore(endTime, dateRangeStart)) return undefined;

  // check dates
  if (isBefore(startTime, dateRangeStart)) start = dateRangeStart.valueOf();
  else start = startTime.valueOf();
  if (isAfter(endTime, dateRangeEnd)) end = dateRangeEnd.valueOf();
  else end = endTime.valueOf();
  return { startTime: start, endTime: end };
}

export function getOverlappingTimeForDay(
  startTimestamp: number,
  endTimestamp: number,
  onDayDateString: string,
  inclusiveEnd: boolean = true
): OverlappingTime {
  if (startTimestamp > endTimestamp) return undefined;
  const onDayStart = startOfDay(parseISO(onDayDateString));
  const onDayEnd = inclusiveEnd ? startOfDay(addDays(onDayStart, 1)) : endOfDay(onDayStart);
  return getOverlappingTime(startTimestamp, endTimestamp, onDayStart.valueOf(), onDayEnd.valueOf());
}

/** Will display the date only once if it is the same */
export function humanizeDateRange(startTime: number, endTime: number): string {
  if (!startTime || !endTime) return "???";
  if (endTime < startTime) [startTime, endTime] = [endTime, startTime];
  if (toDateString(startTime) === toDateString(endTime))
    return (
      format(startTime, DEFAULT_GERMAN_HUMAN_DATE_TIME_FORMAT) + " - " + (endTime ? format(endTime, "HH:mm") : "???")
    );
  else
    return (
      format(startTime, DEFAULT_GERMAN_HUMAN_DATE_TIME_FORMAT) +
      " - " +
      (endTime ? format(endTime, DEFAULT_GERMAN_HUMAN_DATE_TIME_FORMAT) : "???")
    );
}

/** Moves the `date` to the week of `week` */
export function moveDateToWeek(date: number, week: number): number {
  return setWeek(date, getWeek(week, { locale: de }), { locale: de }).valueOf();
}

/** converts de_de to de-DE */
export function convertLocaleToIso(locale: string): string {
  return locale.split("_")[0] + "-" + locale.split("_")[1].toUpperCase();
}
