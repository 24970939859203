@if (!orders) {
  <div>
    <smallstack-loader></smallstack-loader>
  </div>
}
@if (orders) {
  <div>
    <table mat-table [dataSource]="orders" style="width: 100%">
      <ng-container matColumnDef="orderDate">
        <th mat-header-cell *matHeaderCellDef>
          <smallstack-i18n data="@@components.orders.orderdate"></smallstack-i18n>
        </th>
        <td mat-cell *matCellDef="let order">
          <smallstack-date [timestamp]="order.orderDate"></smallstack-date>
        </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>
          <smallstack-i18n data="@@components.orders.orderer"></smallstack-i18n>
        </th>
        <td mat-cell *matCellDef="let order">{{ order.billingAddress.name }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
}
@if (orders?.length === 0) {
  <p class="text-muted" align="center" style="padding: 20px">
    <smallstack-i18n data="@@components.orders.empty"></smallstack-i18n>
  </p>
}
