@if (calendarEntries?.length > 0) {
  <div>
    <table mat-table [dataSource]="calendarEntries" style="width: 100%">
      <ng-container matColumnDef="date">
        <td mat-cell *matCellDef="let event" style="width: 150px">
          <smallstack-date [timestamp]="event.start"></smallstack-date>
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <td mat-cell *matCellDef="let event">
          <smallstack-i18n [data]="event.title"></smallstack-i18n>
          @if (data()?.showDetails && event.content[0].value !== "") {
            / <smallstack-i18n [data]="event.content"></smallstack-i18n>
          }
          @if (data()?.showLocation && event.location[0].value !== "") {
            / <smallstack-i18n [data]="event.location"></smallstack-i18n>
          }
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
} @else {
  @if (!loadingDone) {
    <smallstack-loader></smallstack-loader>
  }
}

@if (calendarEntries?.length === 0) {
  <div style="height: 100%" class="flex flex-col justify-center items-center">
    <p class="text-muted">
      <smallstack-i18n data="@@dashboard.widgets.event.noappointmentsavailable"></smallstack-i18n>
    </p>
  </div>
}

@if (totalCalendarEntriesCount > 1) {
  <p class="text-muted" align="center">
    <smallstack-i18n
      data="@@dashboard.widgets.event.appointmentcounter"
      [replacers]="{ count: totalCalendarEntriesCount }"
    >
    </smallstack-i18n>
  </p>
}

@if (totalCalendarEntriesCount === 1) {
  <p class="text-muted" align="center">
    <smallstack-i18n
      data="@@dashboard.widgets.event.appointmentcountersingular"
      [replacers]="{ count: totalCalendarEntriesCount }"
    >
    </smallstack-i18n>
  </p>
}
