import { Injectable, RendererFactory2, inject } from "@angular/core";
import { SessionStorageStore } from "@smallstack/store";

export const CUSTOMIZATION_MODE = "@smallstack/customization-mode";

@Injectable({ providedIn: "root" })
export class CustomizationModeStore extends SessionStorageStore<boolean> {
  private rendererFactory2 = inject(RendererFactory2);

  constructor() {
    super({ key: CUSTOMIZATION_MODE, parser: "boolean" });
    const renderer = this.rendererFactory2.createRenderer(null, null);
    renderer.listen("window", "keydown.f8", (evt) => {
      event.preventDefault();
      this.setValue(!this.value);
    });
  }
}
