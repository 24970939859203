import { addDays, getISODay, set, subDays } from "date-fns";

export function msToTime(duration: number): string {
  const portions: string[] = [];
  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(duration / msInHour);
  if (hours > 0) {
    portions.push(hours + "h");
    duration = duration - hours * msInHour;
  }
  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(duration / msInMinute);
  if (minutes > 0) {
    portions.push(minutes + "m");
    duration = duration - minutes * msInMinute;
  }
  const seconds = Math.trunc(duration / 1000);
  if (seconds > 0) {
    portions.push(seconds + "s");
  }
  return portions.join(" ");
}

/** Returns `num` business days in the past as timestamps, time will be 8:00 */
export function getLastBusinessDays(num: number): number[] {
  const businessDays = [];
  let lastBusinessDay = set(Date.now(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
  for (let i = 1; i <= num; i++) {
    lastBusinessDay = subDays(lastBusinessDay, 1);
    // remove weekends
    if (getISODay(lastBusinessDay) === 7) lastBusinessDay = subDays(lastBusinessDay, 2);
    else if (getISODay(lastBusinessDay) === 6) lastBusinessDay = subDays(lastBusinessDay, 1);

    businessDays.push(lastBusinessDay.valueOf());
  }
  return businessDays;
}

/** Returns `num` business days in the past as timestamps, time will be 8:00 */
export function getNextBusinessDays(options: { skip?: number; total: number }): number[] {
  if (!options.skip) options.skip = 1;
  const businessDays = [];
  let lastBusinessDay = set(Date.now(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
  for (let i = options.skip; i < options.skip + options.total; i++) {
    lastBusinessDay = addDays(lastBusinessDay, 1);
    // remove weekends
    if (getISODay(lastBusinessDay) === 6) lastBusinessDay = addDays(lastBusinessDay, 2);
    else if (getISODay(lastBusinessDay) === 7) lastBusinessDay = addDays(lastBusinessDay, 1);

    businessDays.push(lastBusinessDay.valueOf());
  }
  return businessDays;
}
