/**
 * Returns the combined schemas without losing type information
 */
export function mergeSchemas<A, B>(schemaA: A, schemaB: B): A & B {
  return {
    ...schemaA,
    ...schemaB,
    properties: {
      ...(schemaA as any).properties,
      ...(schemaB as any).properties
    },
    required: [...((schemaA as any).required || []), ...((schemaB as any).required || [])]
  };
}
