import { TypeSchema } from "../typesystem/type-schema";
import { DataTypeStorageDefinition, DataTypeStorageDefinitionSchema } from "./data-type-storage-definition";
import { InnerTranslation, createInnerTranslationSchema } from "./inner-translation";

export interface DataTypeVariant {
  name: string;
  title: Array<InnerTranslation>;
  titlePlural?: Array<InnerTranslation>;
  schema?: any;
  url?: string;
  storage?: DataTypeStorageDefinition;
}

export const DataTypeVariantSchema: TypeSchema = {
  type: "object",
  properties: {
    name: { type: "string" },
    title: { type: "array", items: createInnerTranslationSchema({ minLength: 3, maxLength: 256 }) },
    titlePlural: { type: "array", items: createInnerTranslationSchema({ minLength: 3, maxLength: 256 }) },
    schema: { type: "object" },
    url: { type: "string" },
    storage: DataTypeStorageDefinitionSchema
  },
  required: ["name", "title"]
};
