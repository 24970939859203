@if (model) {
  <template
    [ngTemplateOutlet]="templateModel"
    [ngTemplateOutletContext]="{ model: model, $implicit: model }"
  ></template>
}
<div>
  @if (!model && notFoundMsg) {
    <div>{{ notFoundMsg }}</div>
  }
  @if (!model && !notFoundMsg) {
    <smallstack-loader loadingText="" style="zoom: 0.5"></smallstack-loader>
  }
</div>
