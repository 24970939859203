import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FileDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";
import { getType } from "../preview.utils";

export interface FilePreviewDialogData {
  file: FileDto;
}

@Component({
  templateUrl: "./file-preview-dialog.component.html",
  styleUrls: ["./file-preview-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, IconComponent, I18nComponent, MatDialogModule, PdfViewerComponent]
})
export class FilePreviewDialogComponent {
  public type: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FilePreviewDialogData) {
    if (data?.file) this.type = getType(data.file);
  }
}
