/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LinkedModelDto } from './linked-model-dto';
import { ModelContextObjectDto } from './model-context-object-dto';

/**
 * 
 * @export
 * @interface NoteDto
 */
export interface NoteDto {
    /**
     * Describes the date of the last update
     * @type {number}
     * @memberof NoteDto
     */
    lastUpdatedAt?: number;
    /**
     * Describe the date when the entity was deleted
     * @type {number}
     * @memberof NoteDto
     */
    _deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteDto
     */
    createdAt?: number;
    /**
     * 
     * @type {ModelContextObjectDto}
     * @memberof NoteDto
     */
    context?: ModelContextObjectDto;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    visibility: NoteDtoVisibilityEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    ownerId: string;
    /**
     * 
     * @type {LinkedModelDto}
     * @memberof NoteDto
     */
    target?: LinkedModelDto;
}

/**
    * @export
    * @enum {string}
    */
export enum NoteDtoVisibilityEnum {
    Public = 'public',
    Private = 'private'
}



