import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule, KeyValuePipe } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { WidgetDto } from "@smallstack/axios-api-client";
import {
  FlattenPipe,
  LoadingElementDirective,
  MonacoEditorComponent,
  StringifyPipe
} from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { DialogPopupButtons, I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { CopyButtonComponent } from "@smallstack/text-components";
import { IconComponent } from "@smallstack/theme-components";
import { ViewWidgetConfigurationSchema, mergeSchemas } from "@smallstack/typesystem";
import { cloneObject, getPromiseProviderValue } from "@smallstack/utils";
import { WidgetRegistry } from "../../services/widget.registry";
import { WidgetConfigurationSchema } from "../../widgets/base-widget.component";
import { FormComponent } from "../form/form.component";
import { GroupedSchemaFormTableComponent } from "../grouped-schema-form-table/grouped-schema-form-table.component";
import { SchemaFormTableComponent } from "../schema-form-table/schema-form-table.component";

export interface WidgetConfigurationDialogData {
  widget: WidgetDto;
  context?: any;
  globals?: any;
  type?: string;
  /**
   * will be used to save the widget
   */
  saveFn: (widgetInstance: WidgetDto) => Promise<void>;
}

@Component({
  selector: "smallstack-widget-configuration-dialog",
  templateUrl: "./widget-configuration-dialog.component.html",
  styleUrls: ["./widget-configuration-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nComponent,
    IconComponent,
    MatTabsModule,
    MatDialogModule,
    MonacoEditorComponent,
    StringifyPipe,
    FlattenPipe,
    KeyValuePipe,
    CopyButtonComponent,
    ClipboardModule,
    LoadingElementDirective,
    FormComponent,
    GroupedSchemaFormTableComponent,
    SchemaFormTableComponent,
    LoadingElementDirective
  ]
})
export class WidgetConfigurationDialogComponent implements AfterViewInit {
  public isLoading: boolean = true;
  public widget: WidgetDto;
  public widgetStyles: string;
  public widgetMeta: any;
  public dashboard: boolean;
  public schema: WidgetConfigurationSchema;
  public widgetConfigurationVisualSchema: SchemaFormPropertyOptions = {
    type: "object",
    properties: {
      frameless: {
        type: "boolean",
        default: false,
        title: "Rahmenlos",
        description: "Zeigt das Widget ohne Rahmen und Hintergrund an"
      },
      widgetIcon: {
        type: "string",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        }
      },
      widgetTitle: {
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        },
        title: "Titel"
      }
    }
  };
  public widgetConfigurationButtonSchema: SchemaFormPropertyOptions = {
    type: "object",
    properties: {
      widgetLink: {
        type: "string",
        title: "Widget Url",
        description: "Wenn auf das Widget geklickt wird, wird diese absolute oder relative URL aufgerufen"
      },
      widgetLinksLeftTitle: {
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        },
        title: "Button Links Title",
        description: "Text auf dem linken Button"
      },
      widgetLinksLeftUrl: {
        type: "string",
        title: "Absolute oder relative Url für linken Button",
        description: "Wenn auf den linken Button geklickt wird, wird URL aufgerufen"
      },
      widgetLinksRightTitle: {
        type: "array",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_I18N
        },
        title: "Button Rechts Title",
        description: "Text auf dem rechten Button"
      },
      widgetLinksRightUrl: {
        type: "string",
        title: "Absolute oder relative Url für rechten Button",
        description: "Wenn auf den rechten Button geklickt wird, wird URL aufgerufen"
      }
    }
  };

  public openedTab = "table";

  constructor(
    public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetConfigurationDialogData,
    private widgetRegistry: WidgetRegistry,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
    void this.load();
    this.widget = cloneObject(data?.widget);
    this.widgetStyles = data?.widget?.styles ? cloneObject(data?.widget?.styles) : "";
    this.widgetMeta = cloneObject(data?.widget?.meta);

    // only display advanced configuration if widget is on dashboard
    if (this.data.type === "dashboard") this.dashboard = true;
  }

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  public disableAnimation = true;
  public ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  protected isObject(obj: unknown): boolean {
    return typeof obj === "object" && obj !== null && obj.toString() === {}.toString();
  }

  public save() {
    return async (): Promise<void> => {
      await this.data.saveFn({
        name: this.widget?.name,
        data: this.widget?.data,
        meta: this.widgetMeta,
        styles: this.widgetStyles
      } as WidgetDto);
      this.matDialogRef.close();
    };
  }

  public setWidget(widget: string): void {
    this.widget = widget ? JSON.parse(widget) : {};
    this.cdr.markForCheck();
  }

  public setWidgetStyles(widgetStyles: string): void {
    this.widgetStyles = widgetStyles;
    this.cdr.markForCheck();
  }

  public resetToDefaults() {
    return async (): Promise<void> => {
      const answer = await this.notificationService.popup.confirmation(
        "Widget zurücksetzen?",
        "Das Widget wird auf den Ausgangszustand zurück gesetzt. Wollen Sie fortfahren?",
        DialogPopupButtons.yesNo
      );
      if (answer === true) {
        const widgetConfiguration = await this.widgetRegistry.getWidgetConfigurationByName(this.data?.widget?.name);
        if (!widgetConfiguration) throw new Error("No widget found for " + JSON.stringify(this.data?.widget?.name));
        this.widget.data = widgetConfiguration.data;
        this.widgetStyles = "";
        this.cdr.markForCheck();
      }
    };
  }

  private async load() {
    const widgetConfiguration = await this.widgetRegistry.getWidgetConfigurationByName(this.data?.widget?.name);
    if (!widgetConfiguration)
      throw new Error("No widget configuration found for " + JSON.stringify(this.data?.widget?.name));
    this.schema = await getPromiseProviderValue(widgetConfiguration.dataSchema);
    if (!this.schema || Object.keys(this.schema).length === 0) this.schema = null;
    if (this.schema && widgetConfiguration.isViewWidget)
      this.schema = mergeSchemas(this.schema, { properties: { viewConfiguration: ViewWidgetConfigurationSchema } });
    this.cdr.markForCheck();
    this.isLoading = false;
  }
}
