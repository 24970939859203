import { Component, InjectionToken } from "@angular/core";
import { AxiosApiClient } from "@smallstack/axios-api-client";

export enum PAGINATOR_POSITION {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  BOTH = "BOTH"
}

export const RESELLER_API_CLIENT: InjectionToken<AxiosApiClient> = new InjectionToken("RESELLER_API_CLIENT");
export const EXTENSION_SLOT_COMPONENT: InjectionToken<Component> = new InjectionToken<Component>(
  "EXTENSION_SLOT_COMPONENT"
);

export const CONFIGURABLE_PROPERTY_VARIABLES_PREFIX = "variables.";
