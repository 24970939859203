import { SchedulingRuleV2Dto, SchedulingRuleV2DtoPatternEnum } from "@smallstack/axios-api-client";
import { cloneJson } from "@smallstack/utils";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  getHours,
  getISODay,
  getMinutes,
  isAfter,
  isBefore,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds
} from "date-fns";

interface GenerateSchedulingRuleV2EventsOptions {
  timeFrameStart?: number;
  timeFrameEnd?: number;
  /** used if no scheduleEnd is defined */
  maxEvents?: number;
}

// eslint-disable-next-line complexity
export function generateSchedulingRuleV2Events(
  schedulingRule: SchedulingRuleV2Dto,
  options: GenerateSchedulingRuleV2EventsOptions = {}
): number[] {
  schedulingRule = cloneJson(schedulingRule);
  // return easy cases
  if (!schedulingRule) return undefined;
  if (schedulingRule.pattern === undefined) return undefined;
  if (schedulingRule.pattern === SchedulingRuleV2DtoPatternEnum.Always) return undefined;
  if (schedulingRule.pattern === SchedulingRuleV2DtoPatternEnum.Once) return [schedulingRule.startTime];

  // set max events to 50 if scheduleEnd is not set
  if (!schedulingRule.scheduleEnd && !options?.maxEvents) options.maxEvents = 50;

  // check if timeFrame actually overlaps with scheduling rule
  if (options?.timeFrameEnd && isBefore(options.timeFrameEnd, schedulingRule.scheduleStart)) return undefined;
  if (options?.timeFrameStart && isAfter(options.timeFrameStart, schedulingRule.scheduleEnd)) return undefined;

  // set schedule start to timeFrameStart if it is after scheduleStart
  if (options?.timeFrameStart && isAfter(options.timeFrameStart, schedulingRule.scheduleStart))
    schedulingRule.scheduleStart = options.timeFrameStart;

  // set schedule end to timeFrameEnd if it is before scheduleEnd
  if (
    options?.timeFrameEnd &&
    (!schedulingRule.scheduleEnd || isBefore(options.timeFrameEnd, schedulingRule.scheduleEnd))
  )
    schedulingRule.scheduleEnd = options.timeFrameEnd;

  // set some defaults
  let start: Date = schedulingRule.scheduleStart ? new Date(schedulingRule.scheduleStart) : new Date();
  if (isAfter(schedulingRule.startTime, start)) start = new Date(schedulingRule.startTime);
  const end: Date = schedulingRule.scheduleEnd ? new Date(schedulingRule.scheduleEnd) : undefined;
  const schedulingHour = getHours(schedulingRule.startTime) || 18;
  const schedulingMinute = getMinutes(schedulingRule.startTime) || 0;
  if (!schedulingRule.each) schedulingRule.each = 1;

  start = setHours(start, schedulingHour);
  start = setMinutes(start, schedulingMinute);
  start = setSeconds(start, 0);
  start = setMilliseconds(start, 0);

  function endNotYetReached(): boolean {
    // no end and maxEvents set
    if (!end && options?.maxEvents && results.length < options.maxEvents) return true;
    // end set and start before end
    if (end && isBefore(start, end)) return true;
    return false;
  }

  // eval patterns
  const results: number[] = [];
  switch (schedulingRule.pattern) {
    case SchedulingRuleV2DtoPatternEnum.Daily:
      if (!schedulingRule.each) schedulingRule.each = 1;
      do {
        results.push(start.valueOf());
        start = addDays(start, schedulingRule.each);
      } while (endNotYetReached());
      break;
    case SchedulingRuleV2DtoPatternEnum.Weekly:
      if (!schedulingRule.each) schedulingRule.each = 1;
      if (!(schedulingRule.patternIncludes instanceof Array) || schedulingRule.patternIncludes.length === 0)
        schedulingRule.patternIncludes = [getISODay(start)];
      do {
        if (schedulingRule.patternIncludes instanceof Array && schedulingRule.patternIncludes.length > 0) {
          const startWeekday = getISODay(start);
          if (!schedulingRule.patternIncludes.includes(startWeekday)) {
            start = addDays(start, 1);
            // add weeks if end of week
            if (getISODay(start) === 7 && results.length !== 0) start = addWeeks(start, schedulingRule.each - 1);
            continue;
          }
        }
        results.push(start.valueOf());
        start = addDays(start, 1);
        // add weeks if end of week
        if (getISODay(start) === 7 && results.length !== 0) start = addWeeks(start, schedulingRule.each - 1);
      } while (endNotYetReached());
      break;
    case SchedulingRuleV2DtoPatternEnum.Monthly:
      if (!schedulingRule.each) schedulingRule.each = 1;
      do {
        results.push(start.valueOf());
        start = addMonths(start, schedulingRule.each);
      } while (endNotYetReached());
      break;
    case SchedulingRuleV2DtoPatternEnum.Yearly:
      if (!schedulingRule.each) schedulingRule.each = 1;
      do {
        results.push(start.valueOf());
        start = addYears(start, schedulingRule.each);
      } while (endNotYetReached());
      break;
  }
  return results;
}
