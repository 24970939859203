import { Injector, inject, signal, untracked } from "@angular/core";
import { createObjectId, waitForSignalValue } from "@smallstack/client-common";
import { ContextService, EnvironmentKeys, EnvironmentService } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { NotificationService } from "@smallstack/i18n-components";
import { convertTypePathToApiPath } from "@smallstack/typesystem";
import axios from "axios";
import { MangoQuery, RxCollection } from "rxdb";
import { RxEntityStoreService } from "../services/rx-entity-store.service";
import { TypeService } from "../services/type.service";
import { createAxiosHeaders } from "../utils/axios-header.utils";
import { EntityStore, StoreEntity, StoreInitOptions } from "./entity.store";
import { LoadingState } from "./loading-state";
import { RxEntityStoreDatabase } from "./rx-entity-store-database";

export function injectRxEntityStore<Entity extends StoreEntity>(
  options: StoreInitOptions,
  injector = inject(Injector)
): RxEntityStore<Entity> {
  return injector.get(RxEntityStoreService).getStore(options);
}

export class RxEntityStore<Entity extends StoreEntity> implements EntityStore<Entity> {
  #database: RxEntityStoreDatabase;
  #collection = signal<RxCollection>(undefined);
  #typeService = this.injector.get(TypeService);
  #environmentService = this.injector.get(EnvironmentService);
  #contextService = this.injector.get(ContextService);

  public loadingState = signal<LoadingState>("init");

  public awaitLoaded$ = (): Promise<void> => waitForSignalValue(this.loadingState, "loaded", this.injector);

  constructor(
    private injector: Injector,
    protected options?: StoreInitOptions
  ) {
    this.#database = injector.get(RxEntityStoreDatabase);
    if (options) void this.initialize(options);
  }

  public query(query: MangoQuery): Entity[] {
    if (query && query.selector && query.selector.selector) {
      Logger.warning(this.getLoggerName(), "Query selector is nested twice, extracting it");
      query.selector = query.selector.selector as any;
    }
    if (this.#collection()) {
      const models: any[] = this.#collection()?.find(query).$$();
      return models?.map((doc: any) => doc?.toMutableJSON());
    }
    return [];
  }

  public async query$(query: MangoQuery): Promise<Entity[]> {
    await this.awaitLoaded$();
    if (query && query.selector && query.selector.selector) {
      Logger.warning(this.getLoggerName(), "Query selector is nested twice, extracting it");
      query.selector = query.selector.selector as any;
    }
    if (this.#collection()) {
      const models: any[] = await this.#collection()?.find(query).exec();
      return models?.map((doc: any) => doc?.toMutableJSON());
    }
    return [];
  }

  public getMany(properties?: { [key: string]: unknown }): Entity[] | undefined {
    if (properties?.selector)
      Logger.warning(
        this.getLoggerName(),
        "getMany got properties that contain 'selector', this might be a mistake. If you want to query, please use `query`"
      );
    if (this.#collection()) {
      const data = this.#collection().find({ selector: properties }).$$();
      if (data instanceof Array) return data.map((doc) => doc.toMutableJSON());
    }
    return [];
  }

  public async getMany$(properties?: { [key: string]: unknown }): Promise<Entity[] | undefined> {
    if (properties?.selector)
      Logger.warning(
        this.getLoggerName(),
        "getMany got properties that contain 'selector', this might be a mistake. If you want to query, please use `query`"
      );
    await this.awaitLoaded$();
    if (this.#collection()) {
      const data = await this.#collection().find({ selector: properties }).exec();
      if (data instanceof Array) return data.map((doc) => doc.toMutableJSON());
    }
    return [];
  }

  public getById(id: string): Entity {
    if (this.#collection()) {
      if (id === undefined) return undefined;
      const model = this.#collection().findOne({ selector: { id } }).$$();
      return model?.toMutableJSON() as Entity;
    }
  }

  /** Returns the entity asynchronously, but, in comparison to the signal version, with an initial value */
  public async getById$(id: string): Promise<Entity> {
    await this.awaitLoaded$();
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    if (id === undefined) throw new Error("id is undefined");
    const model = await this.#collection().findOne({ selector: { id } }).exec();
    return model?.toMutableJSON() as Entity;
  }

  public getByProperty(property: string, value: unknown): Entity[] {
    if (this.#collection()) {
      const selector = { [property]: value };
      const models = this.#collection().find({ selector }).$$();
      if (models instanceof Array) return models.map((doc) => doc.toMutableJSON());
    }
  }

  public getOneByProperty(property: string, value: string): Entity {
    if (this.#collection()) {
      const entity = this.#collection()
        .findOne({ selector: { [property]: value } })
        .$$();
      return entity?.toMutableJSON();
    }
  }

  public async getOneByProperty$(property: string, value: string): Promise<Entity> {
    await this.awaitLoaded$();
    if (this.#collection()) {
      const entity = await this.#collection()
        .findOne({ selector: { [property]: value } })
        .exec();
      return entity?.toMutableJSON();
    }
  }

  public getCount(query?: MangoQuery): number {
    return this.#collection()?.count(query).$$() || 0;
  }

  public async updateEntity(entity: Entity, options?: { direct: boolean }): Promise<Entity> {
    if (options?.direct) {
      const type = this.#typeService.getVariant(this.options.typeDescriptor);
      if (!type) throw new Error(this.getLoggerName() + "Type not found");
      if (!type.storage?.push?.options?.apiUrl)
        Logger.warning(this.getLoggerName(), "No push API URL found, falling back to rxdb upsert!");
      else {
        let url: string = type.storage.push?.options.apiUrl;
        if (!url)
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + "/" + convertTypePathToApiPath(type.path);
        if (!url.startsWith("http")) {
          if (!url.startsWith("/")) url = "/" + url;
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + url;
        }

        await axios
          .put(url + "/" + entity.id, entity, {
            headers: createAxiosHeaders(this.#contextService.context())
          })
          .then((response) => response.data);

        await this.sync();
        return this.getById(entity.id);
      }
    }

    // do update via rxdb upsert and hope, that the sync works
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    const doc = await this.#collection().upsert(entity);
    return doc.toMutableJSON();
  }

  public async patchEntity(id: string, changes: Partial<Entity>, options?: { direct: boolean }): Promise<Entity> {
    if (options?.direct) {
      const type = this.#typeService.getVariant(this.options.typeDescriptor);
      if (!type) throw new Error(this.getLoggerName() + "Type not found");
      if (!type.storage?.push?.options?.apiUrl)
        Logger.warning(this.getLoggerName(), "No push API URL found, falling back to rxdb upsert!");
      else {
        let url: string = type.storage.push?.options.apiUrl;
        if (!url)
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + "/" + convertTypePathToApiPath(type.path);
        if (!url.startsWith("http")) {
          if (!url.startsWith("/")) url = "/" + url;
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + url;
        }

        await axios
          .patch(url + "/" + id, changes, {
            headers: createAxiosHeaders(this.#contextService.context())
          })
          .then((response) => response.data);

        await this.sync();
        return this.getById(id);
      }
    }

    // do patch via rxdb and hope, that the sync works
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    const doc = await this.getById$(id);
    if (!doc) throw new Error(this.getLoggerName() + "Entity not found by id: " + id);
    const patchedEntity = { ...doc, ...changes };
    return this.updateEntity(patchedEntity);
  }

  public async createEntity(entity: Entity, options?: { direct: boolean }): Promise<Entity> {
    if (options?.direct) {
      const type = this.#typeService.getVariant(this.options.typeDescriptor);
      if (!type) throw new Error(this.getLoggerName() + "Type not found");
      if (!type.storage?.push?.options?.apiUrl)
        Logger.warning(this.getLoggerName(), "No push API URL found, falling back to rxdb upsert!");
      else {
        let url: string = type.storage.push?.options.apiUrl;
        if (!url)
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + "/" + convertTypePathToApiPath(type.path);
        if (!url.startsWith("http")) {
          if (!url.startsWith("/")) url = "/" + url;
          url = this.#environmentService.get(EnvironmentKeys.API_URL) + url;
        }

        const insertedDoc = await axios
          .post(url, entity, {
            headers: createAxiosHeaders(this.#contextService.context())
          })
          .then((response) => response.data);

        await this.sync();
        return this.getById(insertedDoc.id);
      }
    }

    // do update via rxdb upsert and hope, that the sync works
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    if (entity.id === undefined) entity.id = createObjectId();
    const doc = await this.#collection().insert(entity);
    return doc.toMutableJSON();
  }

  /** If model has an id, it will be updated, otherwise it will be created */
  public async createOrUpdateEntity(entity: Entity): Promise<Entity> {
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    if (entity.id === undefined) {
      entity.id = createObjectId();
      return this.createEntity(entity);
    }
    return this.updateEntity(entity);
  }

  public async deleteEntity(id: string): Promise<void> {
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");
    const savedEntity = await this.#collection().findOne({ selector: { id } }).exec(true);
    if (savedEntity) await savedEntity.remove();
    else throw new Error(this.getLoggerName() + "Entity for deletion not found by id: " + id);
  }

  public async deleteManyEntities(ids: string[]): Promise<void> {
    await waitForSignalValue(this.loadingState, "loaded", this.injector);
    if (!this.#collection()) throw new Error(this.getLoggerName() + "Collection not initialized yet!");

    const result = await this.#collection().bulkRemove(ids);
    this.injector
      .get(NotificationService)
      .notification.success(`${result.success.length}/${ids.length} Datensätze(n) gelöscht`);

    // } else
    //   throw new Error(
    //     this.getLoggerName() +
    //       "No entities for deletion found by ids: " +
    //       ids +
    //       ", found: " +
    //       safeStringify(savedEntities)
    //   );
  }

  public setLoadingState(state: LoadingState): void {
    untracked(() => {
      this.loadingState.set(state);
    });
  }

  public async initialize(options: StoreInitOptions): Promise<void> {
    this.setLoadingState("loading");
    this.options = options;
    if (!options.tenantId) options.tenantId = this.injector.get(ContextService).context()?.tenantId;
    if (!options.tenantId) {
      this.setLoadingState("error");
      throw new Error("tenantId is required to initialize store");
    }
    if (!options.typeDescriptor?.typePath) {
      this.setLoadingState("error");
      throw new Error("typeDescriptor.typePath is required to initialize store");
    }

    try {
      this.#collection.set(await this.#database.createCollection(options));
    } catch (e) {
      Logger.error(this.getLoggerName(), "Error while initializing store", e);
      this.setLoadingState("error");
      throw e;
    }

    this.setLoadingState("loaded");
    Logger.info(this.getLoggerName(), "Set state to loaded");
  }

  public async sync(): Promise<void> {
    const replicationState = this.#database.getReplicationState(this.options.typeDescriptor, this.options.tenantId);
    if (replicationState) {
      replicationState.reSync();
      await replicationState.awaitInSync();
    }
  }

  private getLoggerName(): string {
    return ("RxEntityStore::" + this.options?.typeDescriptor.typePath ?? "unknown") + " ";
  }
}
