import { ClipboardModule } from "@angular/cdk/clipboard";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormsModule, UntypedFormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AxiosApiClient, FileDto, FilesApi } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DateComponent, I18nComponent, NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { QRCodeDialogComponent } from "@smallstack/qrcode-components";
import { IconComponent } from "@smallstack/theme-components";
import { Observable } from "rxjs";
import { FileStore } from "../../stores/file.store";
import { FilePreviewComponent } from "../file-preview/file-preview.component";

export interface FileDetailsDialogData {
  id: string;
  fileStore: FileStore;
}

@Component({
  selector: "smallstack-file-details-dialog",
  templateUrl: "./file-details-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClipboardModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    FilePreviewComponent,
    LoadingElementDirective,
    I18nComponent,
    DateComponent,
    IconComponent
  ]
})
export class FileDetailsDialogComponent implements OnInit {
  public tagCtrl = new UntypedFormControl();
  public filteredTags$: Observable<string[]>;
  public file: FileDto;
  public tags: string[];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public license: string;
  public licenseUrl: string;
  public keyInputLock: boolean;

  @ViewChild("tagInput", { static: false }) public tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: false }) public matAutocomplete: MatAutocomplete;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FileDetailsDialogData,
    private matDialogRef: MatDialogRef<FileDetailsDialogComponent>,
    private matDialog: MatDialog,
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService,
    private translationStore: TranslationStore,
    private cdr: ChangeDetectorRef
  ) {}

  public async ngOnInit(): Promise<void> {
    this.file = await this.data.fileStore.get(this.data.id);
    this.tags = this.file.tags;

    this.license = this.file.license;
    this.licenseUrl = this.file.licenseUrl;

    // lock input if there is a registered value
    if (this.license || this.licenseUrl === null) this.keyInputLock = true;

    // this.filteredTags$ =
    //   combineLatest(this.tags$, files.tags$, this.tagCtrl.valueChanges)
    //     .pipe(map(([selected, all, query]) => all.filter((e) => !!selected && selected.indexOf(e) < 0).filter((item) => !query || item.toLowerCase().startsWith(query.toLowerCase()))));
    this.cdr.markForCheck();
  }

  public async selected($event: MatAutocompleteSelectedEvent): Promise<void> {
    const {
      option: { value }
    } = $event;
    await this.addTag(value);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }

  public async add($event: any): Promise<void> {
    const { input, value } = $event;

    await this.addTag(value);

    if (input) input.value = "";

    this.tagCtrl.setValue(null);
  }

  public async removeFile(id: string): Promise<void> {
    await this.data.fileStore.delete(id);
    this.matDialogRef.close();
  }

  public async remove(tag: string): Promise<void> {
    this.file.tags = this.file.tags.filter((t) => t !== tag);
    await this.data.fileStore.put(this.file);
    this.cdr.markForCheck();
  }

  public openQRCode(): void {
    this.matDialog.open(QRCodeDialogComponent, { data: this.file.url, autoFocus: false });
  }

  public save(id: string): () => Promise<void> {
    return async () => {
      this.file.license = this.license;
      this.file.licenseUrl = this.licenseUrl;

      await this.axiosApiClient
        .get(FilesApi)
        .patchFile({ id, file: { license: this.file.license, licenseUrl: this.file.licenseUrl } });
      await this.data.fileStore.load({ silently: true });
      this.matDialogRef.close();
      this.notificationService.notification.success(
        this.translationStore.translateByKey("notifications.success.title")
      );
    };
  }

  public unlockKeyInput(): void {
    this.keyInputLock = !this.keyInputLock;
  }

  private async addTag(tag: string) {
    if (!tag || tag.trim() === "") return;

    if (this.file.tags.find((t) => t === tag) === undefined) {
      this.file.tags.push(tag);
      await this.data.fileStore.put(this.file);
    }
  }
}
