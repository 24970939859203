import { Location } from "@angular/common";
import { Injectable, signal } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { PageableCrudStore, PageableStore } from "@smallstack/store";
import { convertQueryStringToMap } from "@smallstack/utils";
import { filter, firstValueFrom, pairwise } from "rxjs";
import { BasePathService } from "./base-path.service";
import { ScrollTopService } from "./scroll-top.service";

@Injectable({ providedIn: "root" })
export class RouterUtilService {
  /** returns the current route path without the basePath */
  public activatedRoutePath = signal("");

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private basePathService: BasePathService,
    private matDialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private scrollTopService: ScrollTopService
  ) {
    location.onUrlChange((url) => {
      this.activatedRoutePath.set(this.basePathService.removeBasePath(url));
    });
  }

  public enableDialogAutoClosing(): void {
    void this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        pairwise()
      )
      .subscribe(async ([routerEventA, routerEventB]: [NavigationStart, NavigationStart]) => {
        // check if path changed (excluding query params)
        const pathA = routerEventA.url.split("?")[0];
        const pathB = routerEventB.url.split("?")[0];
        if (pathA !== pathB) {
          this.scrollTopService.scrollTop();
          if (this.matDialog.openDialogs.length > 0) {
            for (const dialog of this.matDialog.openDialogs) {
              dialog.close();
              await firstValueFrom(dialog.afterClosed());
            }
          }
          this.bottomSheet.dismiss();
          if (this.bottomSheet._openedBottomSheetRef)
            await firstValueFrom(this.bottomSheet._openedBottomSheetRef.afterDismissed());
        }
      });
  }

  public async addSearchParameter(key: string, value: string): Promise<void> {
    await this.router.navigate([], {
      queryParams: { [key]: value },
      queryParamsHandling: "merge"
    });
  }

  public async removeQueryParameter(key: string): Promise<void> {
    await this.router.navigate([], {
      queryParams: { [key]: null },
      queryParamsHandling: "merge"
    });
  }

  public async addMultipleQueryParams(paramKeyArray: { key: string; value: string }[]): Promise<void> {
    const params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    paramKeyArray.forEach((item) => {
      params[item.key] = item.value;
    });
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: params }).toString();
    await this.router.navigateByUrl(url);
  }

  public async removeMultipleQueryParams(queryParamKeys: string[]): Promise<void> {
    const params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    queryParamKeys.forEach((item) => {
      delete params[item];
    });
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: params }).toString();
    await this.router.navigateByUrl(url);
  }

  public async removeMultipleQueryParamsStartingWith(startingWith: string): Promise<void> {
    const params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    Object.keys(params)
      .filter((key) => key.startsWith(startingWith))
      .forEach((key) => delete params[key]);
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: params }).toString();
    await this.router.navigateByUrl(url);
  }

  public initializeListRouting(store: PageableCrudStore | PageableStore): void {
    const params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    const pageSize = params["pageSize"];
    const pageIndex = params["pageIndex"];
    if (!pageSize || !pageIndex || store?.value?.length < pageIndex || pageIndex < 0) {
      params["pageSize"] = "10";
      params["pageIndex"] = "0";
      const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: params }).toString();
      this.location.go(url);
    }
  }

  /** Either navigates to another website when url starts with http or navigates internally with taking query params into account (which angular router doesn't) */
  public async navigateFullUrl(url: string, addBasePath = true): Promise<void> {
    if (!url) return;
    this.scrollTopService.scrollTop();
    if (this.matDialog.openDialogs.length > 0)
      for (const dialog of this.matDialog.openDialogs) {
        dialog.close();
        await firstValueFrom(dialog.afterClosed());
      }
    this.bottomSheet.dismiss();
    setTimeout(async () => {
      if (url.startsWith("http")) await this.router.navigateByUrl(url);
      else {
        const extractedUrl = url.split("?");
        let domainWithPath = extractedUrl[0];
        if (addBasePath) domainWithPath = this.basePathService.addBasePath(domainWithPath);
        await this.router.navigate([], { queryParams: null });
        await this.router.navigate([domainWithPath], {
          queryParams: convertQueryStringToMap(extractedUrl[1]),
          relativeTo: null,
          queryParamsHandling: "merge"
        });
      }
    });
  }
}
