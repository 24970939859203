<div class="flex flex-col space-y-3 flex-auto">
  <div class="text-base font-bold">Auftraggeber</div>
  @for (billingPosition of todoTemplate().billingPositions; track billingPosition) {
    @for (billingParty of billingPosition.billingParties; track billingParty) {
      <smallstack-type-model [modelId]="billingParty.linkedId" [typePath]="billingParty.linkedType">
        <ng-template let-model="model" let-long="long" let-short="short">
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col">
              <span class="short">{{ short }}</span
              ><span class="text-xs">{{ long }}</span>
            </div>
            @if (model?.address) {
              <button class="btn btn-square btn-primary" (click)="openGoogleMaps(model.address)">
                <smallstack-icon name="google-maps-new" size="24"></smallstack-icon>
              </button>
            }
          </div>
        </ng-template>
      </smallstack-type-model>
    }
  }
  @if (!fixedTime()) {
    <div class="text-base font-bold">Nächste Beauftragungen</div>
  }

  @if (showAlways()) {
    <button class="btn btn-block btn-primary" [loadingFn]="createTodo()">
      <div class="flex flex-row space-x-2 items-center">
        <smallstack-icon size="24" name="time"></smallstack-icon>
        <span>Jederzeit</span>
      </div>
    </button>
  } @else {
    @if (fixedTime()) {
      <button class="btn btn-block btn-primary" [loadingFn]="createTodo(fixedTime())">
        <div class="flex flex-row space-x-2 items-center">
          <smallstack-icon size="24" name="time"></smallstack-icon>
          <span>Aufgabe starten</span>
        </div>
      </button>
    } @else {
      @if (nextDates && nextDates.length > 0) {
        <div class="create-todo-btn-group">
          @for (dueDate of nextDates(); track dueDate) {
            <button class="btn btn-block btn-primary" [loadingFn]="createTodo(dueDate)">
              <div class="flex flex-row space-x-2 items-center">
                <smallstack-icon size="24" name="time"></smallstack-icon>
                <smallstack-date [timestamp]="dueDate" format="EE dd.MM.yyyy hh:mm"></smallstack-date>
              </div>
            </button>
          }
        </div>
      } @else {
        <i class="text-muted">Derzeit keine Beauftragungen</i>
      }
    }
  }
  @if (todoTemplate().links && todoTemplate().links.length > 0) {
    <div class="text-base font-bold">Verlinkungen</div>
    <smallstack-linked-models [links]="todoTemplate().links"></smallstack-linked-models>
  }
</div>
