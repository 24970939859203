/* eslint-disable @typescript-eslint/member-ordering */
import { AsyncPipe, KeyValuePipe, NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  computed,
  effect,
  inject
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BreakpointService, TranslatePipe } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { TypeSchema } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";
import {
  convertDotNotationPathToJsonSchemaPath,
  distinctUntilChangedObj,
  getSchemaPropertiesKeys,
  isNonEmptyString,
  removeJsonByPath
} from "@smallstack/utils";
import { BehaviorSubject, Observable, combineLatest, map, of, shareReplay, switchMap } from "rxjs";
import { AddSchemaEntryComponent } from "../add-schema-entry/add-schema-entry.component";
import { FormFieldTitleComponent } from "../form-field-title/form-field-title.component";
import { SchemaFormWidgetComponent } from "../schema-form-widget/schema-form-widget.component";
import { AbstractSchemaFormTable } from "./abstract-schema-form-table";

@Component({
  selector: "smallstack-form-table,schema-form-table,smallstack-schema-form-table",
  templateUrl: "./schema-form-table.component.html",
  styleUrls: ["./schema-form-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormFieldTitleComponent,
    I18nComponent,
    IconComponent,
    KeyValuePipe,
    MatFormFieldModule,
    MatTableModule,
    MatTooltipModule,
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    SchemaFormWidgetComponent,
    TranslatePipe,
    AsyncPipe
  ]
})
export class SchemaFormTableComponent extends AbstractSchemaFormTable implements OnInit, OnDestroy, OnChanges {
  public compactMode = computed(() => {
    const isMobile = this.breakpointService.isMobile();
    const manualCompactMode = this.manualCompactMode();
    if (isMobile) return true;
    if (manualCompactMode !== undefined) return manualCompactMode;
    return false;
  });

  public columns: string[] = ["path", "value"];

  protected typeService = inject(TypeService);

  #overrideSchema$: BehaviorSubject<TypeSchema> = new BehaviorSubject(undefined);

  @Input()
  public set schema(schema: TypeSchema) {
    this.#overrideSchema$.next(schema);
  }

  public tableSchema$: Observable<TypeSchema> = combineLatest([
    this.formService.schema$,
    this.schemaPath$,
    this.#overrideSchema$
  ]).pipe(
    switchMap(([schema, schemaPath, overriddenSchema]) => {
      if (overriddenSchema) return of(overriddenSchema);
      if (isNonEmptyString(schemaPath)) return this.formService.getSchemaForPath$(schemaPath);
      return this.formService.getSchemaForPath$("");
    }),
    map((schema) => {
      // filter out hidden fields
      if (schema?.properties)
        for (const propKey in schema.properties) {
          if (schema.properties[propKey]["x-schema-form"]?.hidden === true) delete schema.properties[propKey];
        }
      return schema;
    }),
    switchMap((schema) => {
      return combineLatest([of(schema), this.formService.hiddenFields$]);
    }),
    distinctUntilChangedObj(),
    map(([schema, hiddenFields]) => {
      for (const path of getSchemaPropertiesKeys(schema)) {
        const completePath = this.schemaPath$.value ? this.schemaPath$.value + "." + path : path;
        if (hiddenFields.includes(completePath))
          schema = removeJsonByPath(schema, convertDotNotationPathToJsonSchemaPath(path));
      }
      return schema;
    }),
    switchMap((schema) => {
      return this.typeService.evalRefSchema(schema);
    }),
    shareReplay(1)
  );

  protected tableSchema = toSignal(this.tableSchema$);

  private initializedFormTable = false;

  public originalOrder = (a: unknown, b: unknown): number => {
    return 0;
  };

  constructor(
    private matDialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private breakpointService: BreakpointService
  ) {
    super();

    effect(() => {
      this.formService.setGlobalOption("showLabel", !this.compactMode());
    });
  }

  public ngOnInit(): void {
    const options = this.formService.globalFormOptions();
    this.formService.setGlobalOption("showHint", true);
    if (!options.validateOn) this.formService.setGlobalOption("validateOn", "blur");

    // show actions or not
    if (this.showActions) this.columns.push("actions");

    // check for data elements which aren't in the schema
    void this.evaluateData().then(async () => {
      this.initializedFormTable = true;
      this.cdr.markForCheck();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.initializedFormTable) void this.evaluateData();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public trackBy(index: number, item: any): string {
    return JSON.stringify(item);
  }

  public openAddSchemaDialog(): void {
    void this.matDialog
      .open(AddSchemaEntryComponent)
      .afterClosed()
      .subscribe((data: { key: string; schema: TypeSchema }) => {
        if (data?.key !== undefined && data?.key !== "") {
          const schema = this.formService.getSchema();
          if (schema.properties === undefined) schema.properties = {};
          schema.properties[data.key] = data.schema;
          this.formService.setSchema(schema);
          this.cdr.markForCheck();
        }
      });
  }

  public resetForm(): void {
    this.formService.setValue(undefined);
    this.cdr.detectChanges();
  }

  private async evaluateData() {
    const value = this.formService.value();
    if (value && this.showMissingFields === true) {
      const schema = this.formService.schema();
      for (const key in value)
        if (value[key] && schema?.properties?.[key] === undefined) {
          const newEntry: TypeSchema = {
            type: typeof value[key] as any,
            title: key,
            "x-schema-form": {
              dataDrivenSchema: true
            }
          };
          if (schema.properties === undefined) schema.properties = {};
          schema.properties[key] = newEntry;
        }
      await this.formService.setSchemaWithRefs(schema);
    }
  }
}
