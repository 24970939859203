import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TodoConfigurationService } from "@smallstack/todo-shared";
import { getJsonByPath, isNonEmptyString } from "@smallstack/utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { combineLatest } from "rxjs";

@Widget({
  name: "TodoStatus",
  templateName: "Aufgaben Status",
  icon: "low-priority",
  templateDescription: "Zeigt den Status einer Aufgabe an",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      contextPropertyName: {
        type: "string",
        title: "Kontext Eigenschaft",
        description: "In welcher Eigenschaft befindet sich der Status?"
      }
    }
  }
})
@Component({
  selector: "smallstack-todo-status-widget",
  templateUrl: "./todo-status-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoStatusWidgetComponent extends BaseWidgetComponent {
  protected label: InlineTranslation;

  constructor(todoConfigurationService: TodoConfigurationService, cdr: ChangeDetectorRef) {
    super();
    this.subscription.add(
      combineLatest([this.context$, this.data$]).subscribe(async ([context, data]) => {
        const todoConfiguration = await todoConfigurationService.getConfiguration();
        if (isNonEmptyString(data.contextPropertyName))
          this.label = todoConfiguration.statuses.find(
            (status) => status.code === getJsonByPath(context, data.contextPropertyName)
          )?.label;
        else this.label = todoConfiguration.statuses.find((status) => status.code === context)?.label;
        cdr.markForCheck();
      })
    );
  }
}
